export const INSURANCE_APPLICATION_STATUS = {
  FROM_STATUS_ID: 1,
  TO_STATUS_ID: 2,
};

export const WORKBENCH_DATES = {
  FORMAT: 'dd MMM yyyy',
  ALL_FORMAT: ['d!/M!/yyyy', 'd!M!yyyy'],
  DATE_OPTIONS: {
    formatYear: 'yy',
    showWeeks: false,
  },
};

export const DEFERRED_STATUS_ID = {
  V3: 22,
  V1: 5,
};

export const NOT_PROCEEDED_ID = {
  V3: 7,
  V1: 3,
};

export const WEEKLY_ID = 52;
