import moment from 'moment';
import momentTimezone from 'moment-timezone';
import {
  DATE_PATTERN,
  ALT_DATE_INPUT_FORMATS,
  DD_MMM_YYYY_FORMAT,
} from 'Common/constants/dateConfigs';

export function isDateValid(date) {
  const currentDate = new Date(date);
  return parseInt(currentDate.getFullYear(), 0) > 1;
}

export function convertUtcToUserDate(date) {
  if (typeof date === 'string' && isDateValid(date)) {
    const dateConverted = date.substring(0, date.indexOf('.'));
    return new Date(dateConverted);
  }
  return '';
}

export function setCurrrentDate() {
  return new Date();
}

export function setCurrentYear() {
  const currentDate = new Date();
  return currentDate.getFullYear();
}

export function setPreviousYear() {
  const currentDate = new Date();
  return currentDate.getFullYear() - 1;
}

export function setDateFormat(format = 'dd-MM-yyyy') {
  return format;
}

export function validateDate(date) {
  return date && isDateValid(date) ? new Date(date) : null;
}

export function getShortDatePattern() {
  return '(0[1-9]|1[0-9]|2[0-9]|3[01])\\-(0[1-9]|1[0-2])\\-\\d{4}|\\d{1,2}\\/\\d{1,2}\\/\\d{4}|([0-9]{8})';
}

export function customStringShortDate(date, delimiter = '/') {
  if (!date) return;
  const dateObject = new Date(date);
  const currentDate = dateObject.getDate();
  const currentMonth = dateObject.getMonth() + 1;
  const currentYear = dateObject.getFullYear();

  return `${currentDate}${delimiter}${currentMonth}${delimiter}${currentYear}`;
}

export function getDatePickerConfig(customConfig) {
  const defaultConfig = {
    pattern: DATE_PATTERN,
    altInputFormats: ALT_DATE_INPUT_FORMATS,
    format: DD_MMM_YYYY_FORMAT,
    dateOptions: { showWeeks: false },
  };

  return customConfig ? { ...defaultConfig, ...customConfig } : defaultConfig;
}

export const formatDate = pattern => date => {
  return `${moment(date).format(pattern)}`;
};

export const formatUTCDate = pattern => date => {
  return `${moment.utc(date).format(pattern)}`;
};

export function formatDateFilter(dateStart, dateEnd, isToday = true) {
  if (!dateStart) return;

  if (isToday) {
    return `Today, ${formatDate('DD MMM YYYY')(dateStart)}`;
  }

  return `From ${formatDate('DD MMM')(dateStart)} to ${moment(dateEnd).format(
    'DD MMM YYYY'
  )}`;
}

export function formatDateWithTimeZone(
  date,
  dateFormat,
  timezone,
  timeZoneFormat = 'z Z'
) {
  if (!date) return;

  const formattedDate = formatUTCDate(dateFormat)(date);
  if (formattedDate === 'Invalid date') return;

  const formattedTZ = momentTimezone.tz(timezone).format(timeZoneFormat);
  return `${formattedDate} ${formattedTZ}`;
}

export function humanizeDate(date) {
  if (!date || !validateDate(date)) return '';
  return moment(moment.utc(date).format()).from(
    moment.utc(new Date()).format()
  );
}

export function dateLesserThan(date, unit = 4, frequency = 'week', startDate) {
  if (!date || !validateDate(date) || (startDate && !validateDate(startDate)))
    return '';
  if (startDate)
    return moment(date) < moment(startDate).subtract(unit, frequency);
  return moment(date) < moment().subtract(unit, frequency);
}
