import {
  typeIntBuilderForUI,
  financeOptionsBuilderForUI,
} from 'Common/mappers/sharedMappers';
import { SALE_TYPE_LIST } from 'Common/constants/iFinance';
import { range } from 'lodash';

class IFinanceAppService {
  constructor($q, httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.$q = $q;
    this.apiBaseUrl = 'ifinance-app';
    this.defaultOptions = [null, true, false];
  }

  vehicleFinder(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/vehicle-finder`, params);
  }

  getQuotePurposeType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/quote-purpose-type`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }

  getQuote(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/v2/quote`, params);
  }

  getSaleType() {
    return this.$q.resolve(SALE_TYPE_LIST);
  }

  getYearManualList(yearsAgo) {
    const backYear = yearsAgo || 50;
    const currentYear = new Date().getFullYear();
    const years = range(currentYear, currentYear - backYear);
    return this.$q.resolve(years);
  }

  getOptions() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/v2/select-options`)
      .then(({ data }) => financeOptionsBuilderForUI(data));
  }
}

export default IFinanceAppService;
