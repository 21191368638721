import { WORK_BENCH_STATES } from 'Common/constants/workbench';

const TASK_GROUP_INDEX = {
  OVERDUE: 0,
  TODAY: 1,
  TOMORROW: 2,
  THIS_WEEK: 3,
  LATER: 4,
};

export default class WorkbenchTaskCtrl {
  constructor(
    $window,
    $uibModal,
    $filter,
    loanScenarioService,
    tasksSharedData,
    contactService,
    tasksService
  ) {
    'ngInject';

    this.$window = $window;
    this.$uibModal = $uibModal;
    this.$filter = $filter;
    this.loanScenarioService = loanScenarioService;
    this.WORK_BENCH_STATES = WORK_BENCH_STATES;
    this.tasksSharedData = tasksSharedData;
    this.contactService = contactService;
    this.tasksService = tasksService;
  }

  taskCompletionSet(task) {
    this.tasksService
      .taskSingleCompletion(task.ActivityID, task.FamilyID, task.IsCompleted)
      .then(() => {
        this.getTaskList(this.showCompleted);
      });
  }

  toggleOneComplete(task) {
    if (!task.IsCompleted) {
      this.taskCompletionSet(task);
      return;
    }

    this.tasksSharedData.completionConfirmation(
      () => {
        this.taskCompletionSet(task);
      },
      () => {
        task.IsCompleted = false;
      }
    );
  }

  getTaskList(isShowCompleted = false) {
    this.isLoadingTask = true;
    const adviserFilter = this.adviserInfo.FamilyId;
    const dateFilter = 'all';
    const createdByFilter = '';
    const familyId = 0;
    const getParams = {
      adviserFilter,
      dateFilter,
      familyId,
      createdByFilter,
      isShowCompleted,
      loanId: this.loanId,
      quoteId: this.quoteId,
    };

    this.tasksService.getTaskList(getParams).then(res => {
      if (!res || !res.data) return;

      this.taskList = res.data.TaskList ? res.data.TaskList : [];
      this.isLoadingTask = false;
      if (!isShowCompleted) {
        if (this.taskGroup.length > 4) {
          this.taskGroup[0].tasks = this.taskList.filter(o => o.IsOverDue);
          this.taskGroup[1].tasks = this.taskList.filter(o => o.IsDueToday);
          this.taskGroup[2].tasks = this.taskList.filter(o => o.IsDueTomorrow);
          this.taskGroup[3].tasks = this.taskList.filter(o => o.IsDueThisWeek);
          this.taskGroup[4].tasks = this.taskList.filter(o => o.IsDueLater);
        }
      }
      this.checkNextTaskDue();
    });
  }

  checkNextTaskDue() {
    typeof this.onNewTask === 'function' && this.onNewTask();
  }

  toggleTaskDetails(task) {
    this.showDetails = {};
    task.showDetails = !task.showDetails;
  }

  getBrokerInfo() {
    this.contactService.getUserInfo().then(response => {
      if (response.data && response.data.FamilyId) {
        this.adviserInfo = response.data;
        this.getTaskList();
      }
    });
  }

  openTaskTemplatedModal(taskId = 0) {
    const propsCopy = {
      ...this.props,
      taskId,
      familyList: this.familyList,
      quoteId: this.quoteId,
    };
    const modalInstance = this.$uibModal.open({
      template: `<task-templated modal-instance="vm.modalInstance" quote-id="vm.props.quoteId" family-id="vm.props.familyId" family-list="vm.props.familyList" loan-id="vm.props.loanId" task-id="vm.props.taskId"></task-templated>`,
      size: 'lg',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'view-task-window',
      resolve: {
        props: () => propsCopy,
      },
    });
    this.taskResult(modalInstance);
  }

  openTaskFormModal(taskId = 0, taskData) {
    const propsCopy = {
      ...this.props,
      taskId,
      taskData,
      familyList: this.familyList,
      quoteId: this.quoteId,
    };
    const modalInstance = this.$uibModal.open({
      template: `<task-form-modal modal-instance="vm.modalInstance" completed-by="vm.props.taskData.CompletedByUserName" quote-id="vm.props.quoteId" family-id="vm.props.familyId" family-list="vm.props.familyList" loan-id="vm.props.loanId" task-id="vm.props.taskId"></task-form-modal>`,
      windowClass: 'edit-task-window',
      size: 'md',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => propsCopy,
      },
    });
    this.taskResult(modalInstance);
  }

  taskResult(modalInstance) {
    modalInstance.result.then(response => {
      if (response === 'saved') {
        this.getTaskList();
      }
    });
  }

  $onInit() {
    this.taskList = [];
    this.showCompleted = false;
    this.adviserInfo = {};
    this.isLoadingTask = false;
    this.taskGroup = [
      {
        name: 'Overdue',
        tableClass: 'table-overdue',
      },
      {
        name: 'Today',
        tableClass: 'table-today',
      },
      {
        name: 'Tomorrow',
        tableClass: 'table-tomorrow',
      },
      {
        name: 'This Week',
        tableClass: 'table-this-week',
      },
      {
        name: 'Later',
        tableClass: 'table-later',
      },
    ];

    this.TASK_GROUP_INDEX = TASK_GROUP_INDEX;

    this.props = {
      familyId: this.familyId,
      loanId: this.loanId,
    };

    this.getBrokerInfo();
  }
}
