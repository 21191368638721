import { openVideoModal } from 'Common/utilities/trainingVideoModal';

class IsFeelingStuck {
  constructor(userService, generalService, $cacheFactory, $uibModal) {
    'ngInject';

    this.userService = userService;
    this.generalService = generalService;
    this.isShowFeelingStuckPopOver = false;
    this.$uibModal = $uibModal;
    this.$cacheFactory = $cacheFactory;
  }

  $onInit() {
    this.cache =
      this.$cacheFactory.get('httpRequest') ||
      this.$cacheFactory('httpRequest');
    this.getUserInfoProcedure();
  }

  getUserInfoProcedure() {
    this.userService.GetUserInfo().then(userInfoResponse => {
      if (userInfoResponse && userInfoResponse.data) {
        this.isShowFeelingStuckPopOver = !userInfoResponse.data.GotItTicked;
      }
    });
  }

  openDemoVideo() {
    if (this.pageSource) {
      openVideoModal(this.$uibModal, this.pageSource);
      return false;
    } else if (this.openModalForDemoVideo) {
      this.openModalForDemoVideo();
    }
  }

  isGotItClicked() {
    this.generalService.gotItTickedSet().then(isPostSuccessful => {
      if (isPostSuccessful) {
        this.isShowFeelingStuckPopOver = false;
        this.cache.remove('GetUserInfo');
        this.getUserInfoProcedure();
      }
    });
  }
}

export default IsFeelingStuck;
