import angular from 'angular';
import { validateAndGetArrayData } from 'Common/utilities/response';
import { isThumbnailObjValid } from 'Common/utilities/document';

angular.module('app').directive('documentThumb', [
  'contactService',
  function documentThumb(contactService) {
    return {
      link(scope, elem, attr) {
        const isNotValidDocumentTab =
          !attr || !attr.documentThumb || !parseInt(attr.documentThumb, 10);
        if (isNotValidDocumentTab) return;
        contactService
          .documentThumbnailGet({
            documentID: attr.documentThumb,
            startPage: 1,
            pageNumber: 1,
            thumbWidth: attr.thumbWidth || 200,
          })
          .then(respond => {
            const thumbObj = validateAndGetArrayData(respond.data);
            if (isThumbnailObjValid(thumbObj)) {
              elem.attr(
                'src',
                `data:${thumbObj.ContentType};base64,${
                  thumbObj.ThumbnailContent
                }`
              );
              elem.attr('style', `width:${attr.thumbWidth}px;`);
            }
          });
      },
    };
  },
]);
