export const PROVIDERS = {
  CRESIDA: 'CressidaCapitol',
  FIDELITY: 'Fidelity Nib',
  SBS: 'Southland Building Society',
  NIB: 'NIB',
};

export const PROVIDER_LOGO = {
  [PROVIDERS.CRESIDA]: 'Cressida',
  [PROVIDERS.FIDELITY]: 'fidelity',
  [PROVIDERS.SBS]: 'SBS',
  [PROVIDERS.NIB]: 'nib',
};
