import { COLOR } from 'Common/constants/colors';

class OverviewDashboardDetailService {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }
  getOptions() {
    this.options = {};
    this.options = {
      // Sets the chart to be responsive
      responsive: true,

      // Boolean - Whether we should show a stroke on each segment
      segmentShowStroke: true,

      // String - The colour of each segment stroke
      segmentStrokeColor: COLOR.WHITE,

      // Number - The width of each segment stroke
      segmentStrokeWidth: 2,

      // Number - The percentage of the chart that we cut out of the middle
      percentageInnerCutout: 45, // This is 0 for Pie charts

      // Number - Amount of animation steps
      // animationSteps: 100,

      // String - Animation easing effect
      animationEasing: '',

      // Boolean - Whether we animate the rotation of the Doughnut
      animateRotate: false,

      // Boolean - Whether we animate scaling the Doughnut from the centre
      animateScale: false,

      cutoutPercentage: 45,
      borderColor: COLOR.WHITE,
      borderWidth: 2,
      animation: {
        animateRotate: false,
      },
    };
    return this.options;
  }

  goToReport(dateFilter, adviser) {
    this.$state.go('app.report', {
      dateFilter: dateFilter && dateFilter.OptionDisplayName,
      adviser: adviser && adviser.map(item => item.FamilyId),
    });
  }
}

export default OverviewDashboardDetailService;
