import {
  ENQUIRY_SOURCE,
  WIDGET_OPTION_VAL,
  ALL_VALUE,
  WIDGET_TITLE,
} from 'Common/constants/dashboardWidget';
import { BASIC_PIE_OPTION, COMMON_PIE_COLORS } from 'Common/config/chart';
import {
  getValueOption,
  getCurrentCarouselData,
  parseMultiSelectTicks,
} from 'Common/utilities/dashboardWidget';

export default class EnquirySourceTileCtrl {
  constructor(
    $q,
    currentUserService,
    overviewDashboardService,
    dashboardService,
    commonWidgetService
  ) {
    'ngInject';

    this.$q = $q;
    this.currentUserService = currentUserService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
    this.commonWidgetService = commonWidgetService;
  }

  $onInit() {
    this.ENQUIRY_SOURCE = ENQUIRY_SOURCE;
    this.chartOptions = BASIC_PIE_OPTION;
    this.timePeriodSelected = {};
    this.timePeriodList = [];
    this.advisersDropdown = [];
    this.showWidgetSettings = true;
    this.widgetTitle = WIDGET_TITLE.ENQUIRY_SOURCE;
    this.widgetSubtitle = 'Sources of New Leads';

    this.getWidgetOptionDropdown();
  }

  goToReport() {
    const params = {
      widgetId: this.myParam.WidgetId,
      widgetUserId: this.myParam.Widget_UserID,
    };
    this.commonWidgetService.goToReport(params);
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.commonWidgetService.getWidgetOptions(ENQUIRY_SOURCE.TIME_PERIOD),
      this.commonWidgetService.getAdviserDropdown(isLoanWriterOnly),
    ];

    this.$q.all(promises).then(responses => {
      if (!responses || !responses.length) return;
      const [timePeriodList, advisersDropdown] = responses;

      this.timePeriodList = timePeriodList;
      this.advisersDropdown = advisersDropdown;
      this.getWidgetUserOptions(true);
    });
  }

  getWidgetUserOptions(isLoadSplits) {
    return this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        isLoadSplits && this.getEnquirySource();
        if (!data || !data.length) return;

        const timePeriod = getValueOption(ENQUIRY_SOURCE.TIME_PERIOD, data);
        this.timePeriodSelected = timePeriod;
        const advisers = getValueOption(ENQUIRY_SOURCE.ADVISERS, data);

        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown
        );
      });
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = list.findIndex(
      item =>
        item.valueId ===
        (parseInt(currentPeriod.Widget_OptionValue, 10) ||
          parseInt(currentPeriod.valueId, 10))
    );

    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);

    this.setWidgetOptions({
      widgetOptionId: ENQUIRY_SOURCE.TIME_PERIOD,
      value:
        this.timePeriodSelected.Widget_OptionValue ||
        this.timePeriodSelected.valueId,
    });
  }

  setWidgetOptions(params) {
    const { widgetOptionId, value, selectedUser, needUpdate } = params;
    const payload = {
      WidgetOptionId: widgetOptionId,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: value || 0,
      SelectedUser: selectedUser || 0,
    };
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      if (needUpdate) {
        this.getWidgetUserOptions(true);
        return;
      }
      this.getEnquirySource();
    });
  }

  onItemSelect(itemClicked, variableName, inputData) {
    if (itemClicked.value === ALL_VALUE) {
      this.selectAll(variableName, inputData);
      return;
    }

    this[variableName] = inputData.map((item, index) => {
      if (item.value === ALL_VALUE && !index) {
        item.ticked = false;
      }
      return item;
    });
    this.setCustomWidgetOption(variableName);
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = this[variableName]
      .filter(item => item.ticked)
      .map(item => ({ PersonId: item.value }));

    const { ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId = ENQUIRY_SOURCE.ADVISERS;

    const value = isAll ? ADVISER.ALL : ADVISER.CUSTOM;
    const needUpdate = false;

    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
      needUpdate,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = inputData.map(item => ({
      ...item,
      ticked: item.value === ALL_VALUE,
    }));
    this.setCustomWidgetOption(varName, true);
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  getEnquirySource() {
    this.dashboardService
      .getEnquirySource({ userWidgetId: this.myParam.Widget_UserID })
      .then(data => {
        this.chartData = [];
        this.chartColors = [];
        this.chartLegend = [];
        this.chartLabels = [];
        if (!data || !data.length) return;

        const colorSplit = COMMON_PIE_COLORS.length + 1;
        const MAX_LABEL_INDEX = 4;

        data.forEach((item, index) => {
          const piePiece = item.count;
          this.chartData = [...this.chartData, piePiece];
          this.chartColors = [
            ...this.chartColors,
            COMMON_PIE_COLORS[index % colorSplit],
          ];
          this.chartLegend = [
            ...this.chartLegend,
            {
              label: item.category,
              color: COMMON_PIE_COLORS[index % colorSplit],
            },
          ];
          this.chartLabels = [...this.chartLabels, item.category];
        });

        this.chartLegend =
          this.chartLegend.length && this.chartLegend.slice(0, MAX_LABEL_INDEX);
      });
  }
}
