import { toastError, toastSuccess } from 'Common/utilities/alert';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';

export const updateImportantDateForEstimatedSettlement = loanScenarioModelService => params => {
  const { type, obj, dateFormat, message } = params;
  const validImportantDateList = [
    IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT,
  ];
  if (!validImportantDateList.includes(type)) return false;
  const dateParams = {
    loanApplicationId: 0,
    loanId: obj.LoanID,
    dateTypeId: IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT,
    date: dateFormat || '',
  };
  loanScenarioModelService.updateImportantDate(dateParams).then(res => {
    if (res.data) {
      if (message) {
        toastSuccess(message);
      }
    } else {
      toastError('Error saving important date.');
    }
  });
  return true;
};

export const getDateMessage = type => {
  switch (type) {
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.SETTLEMENT:
      return 'Settlement Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL:
      return 'Approval Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.SUBMITTED:
      return 'Submitted Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.FINANCE:
      return 'Finance Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVALEXPIRY:
      return 'Pre Approval Expiry Date successfully updated!';
    case IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT:
      return 'Estimated Settlement Date successfully updated!';
    default:
      return '';
  }
};

export const getCalcType = typeOfCalculation => {
  switch (typeOfCalculation) {
    case 'FundingCalculator':
      return 1;
    case 'Serviceability':
      return 2;
    case 'ProductFinder':
      return 3;
    default:
      toastError('Unable to delete unknown Calculation Type');
  }
};
