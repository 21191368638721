/**
 * Phone Icon Field is an text type input element
 * with the mobile or warning icon on the left side
 * and edit icon on the right side
 * Depending on the property combinations passed the view should adjust
 * This can be read-only (disabled) and editable
 * states: warning (required, empty value, read-only), edit invalid phone nummber, edit valid phone number, disabled
 */

import { KEY_CODE } from 'Common/constants/keyCode';
import { isMobileNumberValid } from 'Common/utilities/mobile';

export default class PhoneIconFieldCtrl {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  $onInit() {
    this.setWarningState(false);
    this.resetTimeout = this.$timeout(() => this.resetField(), 2000);
  }

  $onDestroy() {
    this.$timeout.cancel(this.resetTimeout);
    this.labelTimeout && this.$timeout.cancel(this.labelTimeout);
  }

  $onChanges(props) {
    if (props.phoneModel && props.id) {
      this.initPhoneModel = this.phoneModel;
      this.resetField();
    }
  }

  resetField(includeEditState = true) {
    includeEditState && this.setEditState(false);
    this.setSavingState(false);
    this.setInvalidState(false);
    this.showWarning();
    this.showDisabled();
    this.setConfirmPopover(false);
    this.updateParentState();
  }

  setDisabledState(state) {
    this.isDisabledState = state;
  }

  setWarningState(state) {
    this.isWarningState = state;
  }

  setInvalidState(state) {
    this.isInvalidState = state;
  }

  setSavingState(state) {
    this.isSavingState = state;
  }

  setConfirmPopover(state) {
    this.showConfirmPopover = state;
  }

  setEditState(state) {
    this.isEdit = state;
    this.setDisabledState(!state);
    this.updateParentState();
  }

  getOpenState() {
    return this.isEdit || this.isInvalidState || this.isWarningState;
  }

  showWarning() {
    this.setWarningState(!!this.isRequired && !this.phoneModel);
  }

  showDisabled() {
    this.setDisabledState(!this.isEdit && !this.isWarningState);
  }

  isValid(event, isKeyEvent = false) {
    if (event.keyCode === KEY_CODE.ESC) {
      this.cancel();
      return;
    }
    this.isKeyEvent = isKeyEvent;
    this.setEditState(true);
    this.setWarningState(
      !!this.isRequired && !this.phoneModel && !this.isInvalidState
    );

    if (this.phoneModel) {
      this.setInvalidState(!isMobileNumberValid(this.phoneModel));
    }

    this.updateParentState();
  }

  setDataSavedState() {
    this.dataIsSaved = !this.dataIsSaved;
  }

  editMode() {
    this.setDisabledState(false);
  }

  onRemoveFocus() {
    const isPristine =
      (this.phoneModel && this.phoneModel.trim()) !== this.initPhoneModel &&
      this.isEdit &&
      !this.isInvalidState &&
      !this.isWarningState;

    if (isPristine) {
      this.setConfirmPopover(true);
    } else {
      this.cancel(false);
    }
  }

  save() {
    this.setSavingState(true);
    return this.onSave()
      .then(() => {
        this.initPhoneModel = this.phoneModel;
        this.setDataSavedState();
        this.resetField(false);
        this.labelTimeout = this.$timeout(() => {
          this.setDataSavedState();
          this.setEditState(false);
        }, 1000);
        this.updateParentState();
      })
      .catch(() => this.cancel());
  }

  cancel(reset = true) {
    reset && (this.phoneModel = this.initPhoneModel);
    this.resetField();
    this.setConfirmPopover(false);
    this.updateParentState();
  }

  updateParentState() {
    this.onUpdate({ phone: this.phoneModel, isPhoneEdit: this.getOpenState() });
  }
}
