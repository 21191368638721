import { getLastStringCharacters } from 'Common/utilities/string';
import { VALID_EXTENSIONS } from 'Common/constants/document';

export function filenameValidation(filename, extensions) {
  const MAX_FILENAME_EXTENSTION_LENGTH = 5;
  const ext = extensions || VALID_EXTENSIONS.join(`|`);
  const filenameExtension = getLastStringCharacters(
    filename,
    MAX_FILENAME_EXTENSTION_LENGTH
  );
  const regEx = new RegExp(ext, 'gi');
  return !!filenameExtension.match(regEx);
}
