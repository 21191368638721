class StateService {
  constructor($localStorage, $state) {
    'ngInject';

    this.$localStorage = $localStorage;
    this.$state = $state;
  }
  savePrevState(self, parameters = null) {
    this.$localStorage.prevState = {
      self,
      param: parameters,
    };
  }

  getPreviousState() {
    return this.$localStorage.prevState;
  }
  destroyPrevState() {
    this.$localStorage.prevState = null;
  }

  transition(current, params, config) {
    const defaultConfig = {
      reload: false,
      inherit: false,
      notify: false,
      location: true,
    };
    this.$state.transitionTo(current, params, config || defaultConfig);
  }
}

export default StateService;
