import {
  POSSIBLE_ADVERSE_CHANGES,
  MAIN_QUESTIONS,
  LIST_OF_QUESTIONS,
  DEFAULT_ANTICIPATE_FIELDS,
} from 'Common/constants/possibleAdverseChanges';
import { splitString } from 'Common/utilities/string';
import { isEmpty } from 'Common/utilities/objectValidation';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { getDatePickerConfig } from 'Common/utilities/date';

import {
  filterClientList,
  getOptionListByQuestion,
  stringifyMultipleAnswers,
  getFormattedAnswer,
  isBinaryYes,
  hasOtherSelected,
  isCompleteField,
  getDataPerClient,
  isCompleteSection,
} from './util/possibleAdverseChangesCtrl';

export default class PossibleAdverseChangesCtrl {
  constructor($q, optionsService, loanApplicationServices, loanAppSharedData) {
    'ngInject';

    this.$q = $q;
    this.optionsService = optionsService;
    this.loanApplicationServices = loanApplicationServices;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.isBinaryYes = isBinaryYes;
    this.hasOtherSelected = hasOtherSelected;
    this.LIST_OF_QUESTIONS = LIST_OF_QUESTIONS;
    this.sectionBeenClicked = false;
    this.clientSelected = null;
    this.rawProposedRepaymentList = [];
    this.possibleAdverseParams = {
      loanApplicationId: parseInt(this.loanAppId, 10),
      familyId: parseInt(this.familyId, 10),
    };

    this.clientList = [];
    this.adverseQuestions = [];
    this.answersAdverse = [];
    this.answersPossibleAnticipated = [];
    this.answersPossiblePlanOrAnticipate = [];
    this.defaultAnticipated = DEFAULT_ANTICIPATE_FIELDS;
    this.datePickerConfig = getDatePickerConfig({
      dateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
    });
  }

  $onChanges(changes) {
    const { loanApplicants } = changes;
    if (!loanApplicants || !loanApplicants.currentValue) return;

    const { InvolvedPartyPerson: involvedPerson } = loanApplicants.currentValue;
    this.clientList = filterClientList(involvedPerson);
    if (this.clientSelected) {
      this.getPossibleAdverseAnswers();
    }
  }

  processAdverseSection() {
    if (this.sectionBeenClicked) {
      return;
    }
    this.sectionBeenClicked = true;
    this.getAdverseChangesQuestions();
  }

  getAdverseChangesQuestions() {
    this.optionsService
      .question(POSSIBLE_ADVERSE_CHANGES.TITLE)
      .then(({ data }) => {
        if (!data || !data.length) return;

        this.adverseQuestions = data.filter(question => {
          return MAIN_QUESTIONS.includes(question.QuestionId);
        });

        this.getDropdownOptions();
      });
  }

  getDropdownOptions() {
    const promises = [
      this.optionsService.getObjectivesOption(),
      this.optionsService.getAdverseImpactUnits(),
      this.optionsService.getAdverseImpactOperators(),
    ];

    this.$q.all(promises).then(responses => {
      this.setDropdownOptions(responses);
    });
  }
  prepareOptionList(id) {
    return getOptionListByQuestion(id, this.adverseOptions);
  }

  setDropdownOptions(dropdowns) {
    if (!dropdowns || !dropdowns.length) return;
    const [
      adverseOptions,
      anticipatedDurationList,
      anticipatedOperatorsList,
    ] = dropdowns;

    this.adverseOptions = adverseOptions;
    this.anticipatedDurationList =
      anticipatedDurationList && anticipatedDurationList.data;
    this.anticipatedOperatorsList =
      anticipatedOperatorsList && anticipatedOperatorsList.data;

    this.rawProposedRepaymentList = this.prepareOptionList(
      LIST_OF_QUESTIONS.APPLICANT_RETIRING_PROPOSED_PAYMENT
    );
    this.anticipatedDescList = this.prepareOptionList(
      LIST_OF_QUESTIONS.ANTICIPATE_CHANGES_NATURE
    );
    this.intendedRepaymentList = this.prepareOptionList(
      LIST_OF_QUESTIONS.LOAN_REPAYMENT
    );

    this.getPossibleAdverseAnswers();
  }

  getPossibleAdverseAnswers(questionId = 0) {
    this.isLoadingAnswer = true;
    const promises = [
      this.loanApplicationServices.getPossibleAdverseChanges({
        ...this.possibleAdverseParams,
        questionId,
      }),
      this.loanApplicationServices.getPossibleAnticipated(
        this.possibleAdverseParams
      ),
      this.loanApplicationServices.getPossiblePlanOrAnticipate(
        this.possibleAdverseParams
      ),
    ];

    this.$q.all(promises).then(responses => {
      this.setPossibleAdverseAnswers(responses);
      this.isCompletedSection = isCompleteSection(this.clientList);
      this.setCompletionStatus();
      this.isLoadingAnswer = false;
    });
  }

  setCompletionStatus() {
    const updatedCompletionStatus = this.isCompletedSection;
    const sectionObject = this.loanAppSharedData.getSectionObjectById(
      this.loanAppSharedData.completionListObject,
      SECTION_ID.POSSIBLE_ADVERSE
    );

    if (this.isCompleted === updatedCompletionStatus) return;
    this.isCompleted = updatedCompletionStatus;
    this.onChangeStatus({ updatedCompletionStatus });
    this.loanAppSharedData.completionStatusSet(
      SECTION_ID.POSSIBLE_ADVERSE,
      sectionObject.IsCompleted,
      updatedCompletionStatus
    );
  }

  setPossibleAdverseAnswers(answerLists) {
    if (!answerLists || !answerLists.length) return;
    const [
      answersAdverse,
      answersPossibleAnticipated,
      answersPossiblePlanOrAnticipate,
    ] = answerLists;

    this.answersAdverse = answersAdverse;
    this.answersPossibleAnticipated = answersPossibleAnticipated;
    this.answersPossiblePlanOrAnticipate = answersPossiblePlanOrAnticipate;
    this.mapAnswersPerClient();
  }

  mapAnswersPerClient() {
    if (isEmpty(this.clientList)) return;
    this.clientList = this.clientList.map(client => ({
      ...client,
      adverseQuestions: this.mapAnswerToQuestion(client),
    }));

    if (this.clientSelected) {
      this.changeSelectedClient(this.clientSelected);
      return;
    }
    this.clientSelected = this.clientList[0];
  }

  mapAnswerToQuestion(client) {
    const questionsWithAnswer = JSON.parse(
      JSON.stringify(this.adverseQuestions)
    );
    if (isEmpty(questionsWithAnswer)) return [];

    const { PersonId: clientId } = client;
    const clientAdverse = getDataPerClient(this.answersAdverse, clientId);
    const clientPossibleAnticipated = getDataPerClient(
      this.answersPossibleAnticipated,
      clientId
    );
    const clientPossiblePlanOrAnticipate = getDataPerClient(
      this.answersPossiblePlanOrAnticipate,
      clientId
    );

    questionsWithAnswer.forEach(question => {
      question.answer = getFormattedAnswer(question.QuestionId, [
        ...clientAdverse,
      ]);
      this.updateStarted(!!question.answer);
      question.SubQuestion &&
        question.SubQuestion.forEach(subQuestion => {
          subQuestion.answer = getFormattedAnswer(
            subQuestion.QuestionId,
            clientAdverse
          );
          subQuestion.answersPossibleAnticipated =
            clientPossibleAnticipated || [];
          subQuestion.answersPossiblePlanOrAnticipate =
            clientPossiblePlanOrAnticipate || [];
          subQuestion.proposedRepaymentList = this.rawProposedRepaymentList;
          question.hasPossibleAnticipated = !!clientPossibleAnticipated.length;
          question.hasPossiblePlanOrAnticipate = !!clientPossiblePlanOrAnticipate.length;
          this.parseMultipleAnswers(subQuestion);
          subQuestion.SubQuestion &&
            subQuestion.SubQuestion.forEach(nestedQuestion => {
              nestedQuestion.answer = getFormattedAnswer(
                nestedQuestion.QuestionId,
                clientAdverse
              );
            });
        });
      question.isCompleteField = isCompleteField(question);
    });
    return questionsWithAnswer;
  }

  parseMultipleAnswers(question) {
    if (!question) return;
    const { QuestionId: questionId, answer } = question;
    if (!(questionId === LIST_OF_QUESTIONS.APPLICANT_RETIRING_PROPOSED_PAYMENT))
      return;
    const splitAnswers = splitString(answer);
    question.proposedRepaymentAnswers = this.rawProposedRepaymentList.filter(
      item => splitAnswers.includes(item.value)
    );
    question.proposedRepaymentList = this.rawProposedRepaymentList.filter(
      item => !question.proposedRepaymentAnswers.includes(item)
    );
  }

  changeSelectedClient(person) {
    this.clientSelected = this.clientList.find(
      client => client.PersonId === person.PersonId
    );
  }

  addSelectedRepayment(data, question) {
    if (!data || !question) return;
    question.proposedRepaymentAnswers = [
      ...question.proposedRepaymentAnswers,
      data,
    ];
    question.proposedRepaymentList = question.proposedRepaymentList.filter(
      item => item.value !== data.value
    );

    this.prepareAnswers(question);
  }

  removeSelectedRepayment(data, question) {
    if (!data || !question) return;
    question.proposedRepaymentAnswers = question.proposedRepaymentAnswers.filter(
      item => item.value !== data.value
    );
    question.proposedRepaymentList = this.rawProposedRepaymentList.filter(
      item => !question.proposedRepaymentAnswers.includes(item)
    );
    this.prepareAnswers(question);
  }

  prepareAnswers(question) {
    const answer = stringifyMultipleAnswers(question.proposedRepaymentAnswers);
    this.setAnswer(question, answer);
  }

  setAnswer(question, data) {
    const params = {
      ...this.possibleAdverseParams,
      clientId: this.clientSelected.PersonId,
      questionId: question.QuestionId,
      answer: data,
    };

    this.loanApplicationServices.setPossibleAdverseChanges(params).then(() => {
      this.getPossibleAdverseAnswers();
    });
  }

  setPossibleAnticipatedChanges(data) {
    this.isShowNatureOfChanges = false;
    const params = {
      ...this.possibleAdverseParams,
      ...data,
      loanScenarioId: this.loanAppId,
      clientId: this.clientSelected.PersonId,
    };
    this.loanApplicationServices.setPossibleAnticipated(params).then(() => {
      this.defaultAnticipated = DEFAULT_ANTICIPATE_FIELDS;
      this.getPossibleAdverseAnswers();
    });
  }

  setPossiblePlanOrAnticipate(data) {
    this.isShowAnticipatedChanges = false;
    const params = {
      ...this.possibleAdverseParams,
      ...data,
      clientId: this.clientSelected.PersonId,
    };

    this.loanApplicationServices
      .setPossiblePlanOrAnticipate(params)
      .then(() => {
        this.getPossibleAdverseAnswers();
      });
  }

  updateStarted(isStarted) {
    this.isStarted = isStarted || this.isStarted;
  }
}
