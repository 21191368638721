import angular from 'angular';
import { filter, map } from 'lodash';

angular
  .module('app')
  .controller('GeneralSearchCtrl', function GeneralSearchCtrl(
    $scope,
    dashboardService,
    $stateParams,
    $window,
    $rootScope,
    $timeout,
    $sce,
    $state,
    uiService
  ) {
    $scope.generalSearchTextInMobile = $stateParams.searchText;
    $scope.isSearching = false;
    $scope.timeoutPromise = null;

    $scope.genralSearchInMobile = () => {
      $scope.generalSearchResult = [];
      if ($scope.generalSearchTextInMobile !== '') {
        dashboardService
          .getGenralSearchResult($scope.generalSearchTextInMobile)
          .then(
            response => {
              $scope.generalSearchedTextInMobile =
                $scope.generalSearchTextInMobile;
              $scope.generalSearchResult = response.data;
              $scope.defaultListLimit = 3;
              $scope.NameLimit = $scope.defaultListLimit;
              $scope.HomePhoneLimit = $scope.defaultListLimit;
              $scope.BusinessPhoneLimit = $scope.defaultListLimit;
              $scope.MobilePhoneLimit = $scope.defaultListLimit;
              $scope.EntityLimit = $scope.defaultListLimit;
              $scope.LoanReferenceNumberLimit = $scope.defaultListLimit;
              $scope.PolicyNumberLimit = $scope.defaultListLimit;
              $scope.EmailLimit = $scope.defaultListLimit;

              $scope.isSearching = false;
              $scope.generalSearchResult.NameResult = [];
              const data = $scope.generalSearchResult;

              if (data && Array.isArray(data)) {
                $scope.generalSearchResult.NameResult = data.reduce(
                  (accum, obj) => {
                    let newAccum = accum;
                    if (obj.Result) {
                      newAccum = accum.concat(obj.Result);
                    }
                    return newAccum;
                  },
                  []
                );
              }

              $scope.generalSearchResult.ClientList = [];
              $scope.generalSearchResult.AdviserList = [];
              $scope.generalSearchResult.AssistantList = [];
              $scope.generalSearchResult.AdviserOrgList = [];
              $scope.generalSearchResult.ReferrerList = [];
              $scope.generalSearchResult.ReferrerOrgList = [];

              const filterSearchResult = (searchResult, find) => {
                const { NameResult: nameResult } = $scope.generalSearchResult;
                if (!nameResult && !nameResult.length) return;
                return filter(
                  nameResult[0].Result,
                  obj => obj.ResultType === find
                );
              };

              const filterSearchResultMatch = (searchResult, find) => {
                const { NameResult: nameResult } = $scope.generalSearchResult;
                if (!nameResult && !nameResult.length) return;
                return filter(
                  nameResult[0].Result,
                  obj => obj.MatchResultType === find
                );
              };

              if ($scope.generalSearchResult.NameResult.length) {
                $timeout(() => {
                  const { NameResult: nameResult } = $scope.generalSearchResult;
                  const searchResult =
                    (nameResult && nameResult.length && nameResult[0].Result) ||
                    null;
                  map(
                    ($scope.generalSearchResult.ClientList = filterSearchResult(
                      searchResult,
                      'Client'
                    ))
                  );
                  map(
                    ($scope.generalSearchResult.AdviserList = filterSearchResult(
                      searchResult,
                      'Adviser'
                    ))
                  );
                  map(
                    ($scope.generalSearchResult.AssistantList = filterSearchResult(
                      searchResult,
                      'Assistant'
                    ))
                  );
                  map(
                    ($scope.generalSearchResult.AdviserOrgList = filterSearchResult(
                      searchResult,
                      'Adviser Organisation'
                    ))
                  );
                  map(
                    ($scope.generalSearchResult.ReferrerList = filterSearchResult(
                      searchResult,
                      'Referrer'
                    ))
                  );
                  map(
                    ($scope.generalSearchResult.ReferrerOrgList = filterSearchResult(
                      searchResult,
                      'Referrer Organisation'
                    ))
                  );
                });
              }
              map(
                ($scope.generalSearchResult.NameResult = filterSearchResultMatch(
                  data,
                  'Name'
                ))
              );
              map(
                ($scope.generalSearchResult.HomePhoneResult = filterSearchResultMatch(
                  data,
                  'HomePhone'
                ))
              );
              map(
                ($scope.generalSearchResult.BusinessPhoneResult = filterSearchResultMatch(
                  data,
                  'BusinessPhone'
                ))
              );
              map(
                ($scope.generalSearchResult.MobilePhoneResult = filterSearchResultMatch(
                  data,
                  'MobilePhone'
                ))
              );
              map(
                ($scope.generalSearchResult.EntityResult = filterSearchResultMatch(
                  data,
                  'Entity'
                ))
              );
              map(
                ($scope.generalSearchResult.LoanReferenceNumberResult = filterSearchResultMatch(
                  data,
                  'Loan Reference Number'
                ))
              );
              map(
                ($scope.generalSearchResult.PolicyNumberResult = filterSearchResultMatch(
                  data,
                  'Policy Number'
                ))
              );
              map(
                ($scope.generalSearchResult.EmailResult = filterSearchResultMatch(
                  data,
                  'Email'
                ))
              );
            },
            () => {
              $scope.generalSearchResult = [];
              $scope.generalSearchResult.Result = [];
              $scope.isSearching = false;
              return $scope.generalSearchResult;
            }
          );
      }
    };

    $scope.highlight = (text, search) => {
      if (!search) {
        return $sce.trustAsHtml(text);
      }
      return $sce.trustAsHtml(
        text.replace(new RegExp(search, 'gi'), '<strong>$&</strong>')
      );
    };

    $scope.goToCustomer = result => {
      $scope.openSearchResult = false;
      switch (result.ResultType.toLowerCase()) {
        case 'adviser organisation':
          $state.go('app.contactsAdviserOrg', {
            adviserOrgId: result.ResultID,
            adviserComplianceId: result.ClientId,
          });
          break;
        case 'adviser':
        case 'principal adviser':
          $state.go('app.contactsAdviser', {
            clientId: result.ClientId,
            familyId: result.ResultID,
          });
          $rootScope.searchMainObject.isGeneralSearchEnable = false;
          break;
        case 'assistant':
          $state.go('app.contactsAssistant', {
            clientId: result.ClientId,
            familyId: result.ResultID,
          });
          $rootScope.searchMainObject.isGeneralSearchEnable = false;
          break;
        case 'referrer organisation':
        case 'referrer org':
          $state.go('app.contactsReferrerOrg', {
            referrerComplianceId: result.ClientId,
            referrerOrgId: result.ResultID,
          });
          $rootScope.searchMainObject.isGeneralSearchEnable = false;
          break;
        case 'referrer':
          $state.go('app.contactsReferrer', {
            clientId: result.ClientId,
            familyId: result.ResultID,
          });
          $rootScope.searchMainObject.isGeneralSearchEnable = false;
          break;
        default:
          $state.go('app.contactsSingle', {
            familyId: result.ResultID,
            activeTab: 'summary',
          });
          $rootScope.searchMainObject.isGeneralSearchEnable = false;
          break;
      }
    };

    $scope.goToBusiness = id => {
      $scope.openSearchResult = false;
      $state.go('app.BusinessAccount', { familyId: id });
      $rootScope.searchMainObject.isGeneralSearchEnable = false;
    };

    $scope.toggleListLimit = result => {
      switch (result) {
        case 'Name':
          if ($scope.NameLimit === $scope.defaultListLimit) {
            $scope.NameLimit =
              $scope.generalSearchResult.NameResult[0].Result.length;
          } else {
            $scope.NameLimit = $scope.defaultListLimit;
          }
          break;

        case 'HomePhone':
          if ($scope.HomePhoneLimit === $scope.defaultListLimit) {
            $scope.HomePhoneLimit =
              $scope.generalSearchResult.HomePhoneResult[0].Result.length;
          } else {
            $scope.HomePhoneLimit = $scope.defaultListLimit;
          }
          break;

        case 'BusinessPhone':
          if ($scope.BusinessPhoneLimit === $scope.defaultListLimit) {
            $scope.BusinessPhoneLimit =
              $scope.generalSearchResult.BusinessPhoneResult[0].Result.length;
          } else {
            $scope.BusinessPhoneLimit = $scope.defaultListLimit;
          }
          break;

        case 'MobilePhone':
          if ($scope.MobilePhoneLimit === $scope.defaultListLimit) {
            $scope.MobilePhoneLimit =
              $scope.generalSearchResult.MobilePhoneResult[0].Result.length;
          } else {
            $scope.MobilePhoneLimit = $scope.defaultListLimit;
          }
          break;

        case 'Entity':
          if ($scope.EntityLimit === $scope.defaultListLimit) {
            $scope.EntityLimit =
              $scope.generalSearchResult.EntityResult[0].Result.length;
          } else {
            $scope.EntityLimit = $scope.defaultListLimit;
          }
          break;

        case 'LoanReferenceNumber':
          if ($scope.LoanReferenceNumberLimit === $scope.defaultListLimit) {
            $scope.LoanReferenceNumberLimit =
              $scope.generalSearchResult.LoanReferenceNumberResult[0].Result.length;
          } else {
            $scope.LoanReferenceNumberLimit = $scope.defaultListLimit;
          }
          break;
        case 'PolicyNumber':
          if ($scope.PolicyNumberLimit === $scope.defaultListLimit) {
            $scope.PolicyNumberLimit =
              $scope.generalSearchResult.PolicyNumberResult[0].Result.length;
          } else {
            $scope.PolicyNumberLimit = $scope.defaultListLimit;
          }
          break;
        case 'Email':
          if ($scope.EmailLimit === $scope.defaultListLimit) {
            $scope.EmailLimit =
              $scope.generalSearchResult.EmailResult[0].Result.length;
          } else {
            $scope.EmailLimit = $scope.defaultListLimit;
          }
          break;
        default:
      }
    };

    $scope.genralSearchInMobile();

    $scope.$watch(
      () => {
        return uiService.generalSearchText;
      },
      newValue => {
        $scope.generalSearchTextInMobile = newValue;
        $scope.genralSearchInMobile();
        $scope.isSearching = true;
      },
      true
    );

    $scope.reloadSearchInMobile = genralSearchText => {
      $window.location.href = `#/app/search/${genralSearchText}`;
    };
  });
