import { taskPdfBuilderForUI } from 'Common/mappers/tasks.js';
import {
  brokerBuilderForUI,
  dateRangeBuilderForUI,
  fixedRateExpiryListBuilderForUI,
} from 'Common/mappers/customerCare';

class CustomerCareService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'CustomerCare';
  }

  FixedRateExpiryListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted
  ) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FixedRateExpiryListGet`, {
        searchedAdvisorID,
        fromDate,
        toDate,
        pageNumber,
        pageSize,
        sortColumn,
        sortType,
        isCompleted,
      })
      .then(({ data }) => data && fixedRateExpiryListBuilderForUI(data));
  }

  PreApprovalExpiresListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/PreApprovalExpiresListGet`, {
      searchedAdvisorID,
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortColumn,
      sortType,
      isCompleted,
    });
  }

  BirthDayListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/BirthDayListGet`, {
      searchedAdvisorID,
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortColumn,
      sortType,
      isCompleted,
    });
  }

  ClientsAnnualReviewListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ClientsAnnualReviewListGet`,
      {
        searchedAdvisorID,
        fromDate,
        toDate,
        pageNumber,
        pageSize,
        sortColumn,
        sortType,
        isCompleted,
      }
    );
  }

  SettlementmentDateListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted,
    filterTab
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/SettlementmentDateListGet`, {
      searchedAdvisorID,
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortColumn,
      sortType,
      isCompleted,
      filterTab,
    });
  }

  getBrokers(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/brokers`, params, true)
      .then(response => response.data.map(brokerBuilderForUI));
  }

  birthdaySpreadsheet(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/birthday-spreadsheet`, params)
      .then(response => taskPdfBuilderForUI(response.data));
  }

  customerCareDateRange() {
    return this.httpClient
      .post(`${this.apiBaseUrl}/date-range`)
      .then(({ data }) => data && data.map(dateRangeBuilderForUI));
  }

  setCompeletion(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/CompeletionSet`, data);
  }

  CustomerCareEmailSendv2(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/CustomerCareEmailSendv2`,
      data
    );
  }

  CustomerCareSMSSend(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/CustomerCareSMSSend`, data);
  }

  CustomerCareMailSend(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/CustomerCareMailSend`,
      data
    );
  }
}

export default CustomerCareService;
