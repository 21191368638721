import template from './emailIconField.html';
import controller from './emailIconFieldCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    id: '<',
    emailModel: '<',
    name: '@',
    isRequired: '<',
    onSave: '&',
    onUpdate: '&',
  },
};
