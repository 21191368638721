import _ from 'lodash';
import { toastSaveSuccess, toastError } from 'Common/utilities/alert';
import {
  getFinanceCatIDsWithEmployer,
  getValueLoanTerm,
  isAssetFinance,
  isRateDiscountValid,
  getRepaymentFrequencyList,
} from 'Common/utilities/loanStructure';
import { appendPropertyToExistingObject } from 'Common/utilities/dataManipulation';

class NewLoanStructureCtrl {
  constructor(
    $state,
    optionsService,
    loanAppDetailsService,
    loanScenarioService,
    loanScenarioModelService,
    toaster
  ) {
    'ngInject';

    this.$state = $state;
    this.optionsService = optionsService;
    this.loanAppDetailsService = loanAppDetailsService;
    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.getFinanceCatIDsWithEmployer = getFinanceCatIDsWithEmployer;
    this.toaster = toaster;
  }

  $onInit() {
    this.loanStructure = { InterestTaxDeductible: false };
    this.buttonAction = 'Save';

    this.showEmployerField = false;
    this.showBaseLoanAmount = false;

    this.showAssetField = isAssetFinance(this.loanDetails.LendingCategoryId);

    if (this.useFirstLoanStructure) {
      this.getFirstLoanStructure();
      return;
    }

    if (this.loanStructureId) {
      this.getLoanStructureDetails();
    } else {
      this.getOptionsLists();
    }
  }

  getOptionsLists() {
    this.getFinanceTypes();
    this.getLoanTerms();
    this.getLeasingParties();
    this.getOwners();
    this.repaymentFrequencyListUtil = getRepaymentFrequencyList({
      thisCtrl: this,
    });
    this.repaymentFrequencyListUtil(this.optionsService);
    this.getArrearsTypes();
  }

  getFinanceTypes() {
    this.optionsService.getAssetFinanceCategory().then(
      response => {
        this.financeTypeList = response;
        this.financeCatIDsWithEmployer = this.getFinanceCatIDsWithEmployer(
          this.financeTypeList
        );

        if (!this.loanStructure || !this.loanStructure.FinanceCategoryId)
          return;
        this.setEmployerFieldDisplay(this.loanStructure.FinanceCategoryId);
      },
      () => {
        this.financeTypeList = [];
      }
    );
  }

  getLoanTerms() {
    this.optionsService.getAssetFinanceLoanTerms().then(
      response => {
        this.loanTermList = response;

        if (!this.loanStructure || !this.loanStructure.LoanTerm) return;
        this.formatLoanTerm(this.loanStructure.LoanTerm);
      },
      () => {
        this.loanTermList = [];
      }
    );
  }

  getLeasingParties() {
    this.loanScenarioModelService.getApplicantEmployment(this.loanAppId).then(
      response => {
        if (!response || !response.data) return;

        const applicantEmployment = response.data;
        const employments = _.flatMap(
          applicantEmployment,
          emp => emp.Employment
        );
        this.leasingPartyList = employments.map(emp => {
          return {
            employmentId: emp.EmploymentId,
            employerName: emp.EmployerName,
          };
        });
      },
      () => {
        this.leasingPartyList = [];
      }
    );
  }

  getOwners() {
    const getTickedStatus = borrowerId => {
      if (!this.loanStructureId) return true;

      return this.loanStructure.Owner.some(
        owner => owner.BorrowerID === borrowerId
      );
    };

    this.loanAppDetailsService.ownersGet(this.loanAppId).then(
      response => {
        this.ownersList = response.map(element => ({
          ...element,
          ticked: getTickedStatus(element.BorrowerID),
        }));
      },
      () => {
        this.ownersList = [];
      }
    );
  }

  getArrearsTypes() {
    this.optionsService.getArrearsType().then(response => {
      this.arrearsTypeList = response;
    });
  }

  setEmployerFieldDisplay(financeTypeId) {
    this.loanStructure.LeasingPartyId = null;
    this.showEmployerField = this.financeCatIDsWithEmployer.includes(
      financeTypeId
    );
  }

  calculateTotalLoanAmount() {
    this.loanStructure.TotalLoanAmount = this.loanStructure.BaseLoanAmount;
  }

  residualValuesChange(percentage, amount) {
    this.loanStructure.ResidualPercent = percentage;
    this.loanStructure.ResidualAmount = amount;
  }

  commissionValuesChange(percentage, amount) {
    this.loanStructure.BrokerCommissionPercent = percentage;
    this.loanStructure.BrokerCommissionAmount = amount;
  }

  documentValuesChange(percentage, amount) {
    this.loanStructure.DocumentFeePercent = percentage;
    this.loanStructure.DocumentFee = amount;
  }

  saveLoanStructure() {
    const {
      FinanceCategoryId,
      BaseLoanAmount,
      AssetFinance,
      ArrearTypeID,
      Owner,
    } = this.loanStructure;
    const isFormIncomplete =
      (!FinanceCategoryId && this.showAssetField) ||
      !BaseLoanAmount ||
      !AssetFinance.LoanTerm ||
      !ArrearTypeID ||
      !Owner.length;

    if (!isRateDiscountValid(AssetFinance.RateDiscount)) {
      toastError('Rate Discount must not exceed 2%');
      return;
    }

    if (isFormIncomplete) {
      this.toaster.pop('error', 'Please fill all required fields.');
      return;
    }
    this.isSaving = true;
    this.loanStructure.loanScenarioId = this.loanAppId;
    this.loanStructure = appendPropertyToExistingObject(
      this.loanStructure,
      'LoanTerm',
      getValueLoanTerm(AssetFinance.LoanTerm)
    );
    this.loanScenarioService.loanFacilitySet(this.loanStructure).then(() => {
      this.isSaving = false;
      toastSaveSuccess();
      if (this.modalInstance) this.modalInstance.close();
    });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  getLoanStructureDetails() {
    this.loanScenarioService
      .loanFacilityGet(this.loanAppId, this.loanStructureId)
      .then(response => {
        if (!response.data) return;
        this.buttonAction = 'Update';
        this.loanStructure = response.data;
        const { AssetFinance } = this.loanStructure;
        this.loanStructure.AssetFinance = {
          ...AssetFinance,
          LoanTerm: AssetFinance.LoanTerm.toString(),
          RepaymentFrequency: AssetFinance.RepaymentFrequency.toString(),
        };
        this.getOptionsLists();
      });
  }

  getFirstLoanStructure() {
    this.loanScenarioService.loanDetailsGet(this.loanAppId).then(({ data }) => {
      if (!data || !data.LoanFacility) return;
      if (data.LoanFacility.length > 0) {
        this.loanStructureId = data.LoanFacility[0].LoanStructureId;
        this.getLoanStructureDetails();
      } else {
        this.getOptionsLists();
      }
    });
  }

  formatLoanTerm(loanTerm) {
    if (!this.loanTermList) return;
    const selectedLoanTerm = this.loanTermList.find(
      term => loanTerm === Number(parseFloat(term.value).toFixed(2))
    );
    this.loanStructure.LoanTerm = selectedLoanTerm && selectedLoanTerm.value;
  }

  displayFrequencyOptions(flag) {
    this.showFrequencyOptions = flag;
  }

  selectFrequency(frequencyId) {
    this.showFrequencyOptions = false;
    this.selectedFrequency = this.repaymentFrequencyList.find(
      o => parseInt(o.Value, 10) === parseInt(frequencyId, 10)
    );

    if (!this.selectedFrequency) return;
    this.selectedFrequency.initial = this.selectedFrequency.Name.charAt(0);
    this.loanStructure.AssetFinance.RepaymentFrequency = this.selectedFrequency.Value;
  }
}

export default NewLoanStructureCtrl;
