import { LIST_OF_QUESTIONS } from 'Common/constants/possibleAdverseChanges';
import { isBoolean } from 'Common/utilities/objectValidation';

export function isFilledAnswer(question) {
  switch (question.QuestionId) {
    case LIST_OF_QUESTIONS.RETIREMENT_AGE:
      return !!(question.answerList && question.answerList.length);

    case LIST_OF_QUESTIONS.LOAN_REPAYMENT:
      return !!(
        question.possiblePlanOrAnticipate &&
        question.possiblePlanOrAnticipate.length
      );

    case LIST_OF_QUESTIONS.ANTICIPATE_CHANGES:
    case LIST_OF_QUESTIONS.APPLICANT_RETIRING:
      return isBoolean(question.radioAnswer);

    default:
  }
}

export function getGenericAnswer(answerList) {
  return (answerList && answerList.length && answerList[0].answer) || null;
}

export function getClientFromAnswerList(answerList, clientId) {
  return answerList.find(answer => answer.clientId === clientId) || {};
}

export function isCompleteSubQuestion(question, subQuestion) {
  let isValidAnswer = true;
  if (!question || !subQuestion) return !isValidAnswer;

  switch (question.QuestionId) {
    case LIST_OF_QUESTIONS.APPLICANT_RETIRING:
      subQuestion.forEach(item => {
        if (!item.answer) isValidAnswer = !isValidAnswer;
      });
      return isValidAnswer;

    case LIST_OF_QUESTIONS.ANTICIPATE_CHANGES:
      subQuestion.forEach(item => {
        const clientChangesQuestion =
          item.answerList &&
          item.answerList.length &&
          item.QuestionId === LIST_OF_QUESTIONS.CLIENT_CHANGES_APPLY;
        if (!clientChangesQuestion) return !isValidAnswer;

        item.answerList.forEach(ans => {
          const ansBool = !!+ans.answer;
          if (!ansBool) isValidAnswer = ansBool;
        });
      });

      return isValidAnswer;

    default:
  }
}

export function isCompleteMainQuestion(question) {
  const { SubQuestion: subQuestion } = question;
  const noSubQuestion = !subQuestion || !subQuestion.length;
  const validYesAnswer = !!(question.radioAnswer && noSubQuestion);
  const isValidAnswer = true;

  switch (question.QuestionId) {
    case LIST_OF_QUESTIONS.RETIREMENT_AGE:
      return !!(question.answerList && question.answerList.length);

    case LIST_OF_QUESTIONS.APPLICANT_RETIRING:
    case LIST_OF_QUESTIONS.ANTICIPATE_CHANGES:
      if (!isBoolean(question.radioAnswer)) return !isValidAnswer;
      if (!question.radioAnswer || validYesAnswer) return isValidAnswer;

      return isCompleteSubQuestion(question, subQuestion);
    default:
  }
}
