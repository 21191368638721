import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { forEach } from 'lodash';
import { setFeaturesData } from 'Common/utilities/features';

const COMMISSION_RECIEVED_FROM = 'ReceivedFrom';

class AccountService {
  constructor(
    configService,
    generalService,
    contactModelService,
    currentUserService
  ) {
    'ngInject';

    this.configService = configService;
    this.generalService = generalService;
    this.contactModelService = contactModelService;
    this.currentUserService = currentUserService;
    this.checkHasCommissionAccess();
  }

  saveCheck(list, form, accessType, allocatedAdviser) {
    this.list = list;
    let errKey = 0;
    forEach(list, (value, key) => {
      if (value.isIncluded) {
        if (!value.FirstName) {
          form.$invalid = true;
          errKey = key;
        } else if (!value.LastName) {
          form.$invalid = true;
          errKey = key;
        }
      }
      if (
        accessType === ACCESS_TYPE.CORPORATE &&
        !allocatedAdviser.adviserSelected.FullName.description
      ) {
        form.$invalid = true;
        errKey = key;
      }
    });
    return errKey;
  }

  accountDropletReadyOptions(inter) {
    if (inter) {
      inter.allowedExtensions(['jpg', 'png', 'gif']);
      inter.setRequestUrl(
        `${this.configService.resource}/contacts/DocumentUpload`
      );
      inter.setRequestHeaders({ Authorization: this.configService.token });
      inter.defineHTTPSuccess([/2.{2}/]);
      inter.useArray(false);
    }
    return inter;
  }

  copyReferral(familyDetails, contactInfoSetValue) {
    this.referral = {
      ReferralCategory: contactInfoSetValue.ReferralCategory,
      ReferralCategoryId: contactInfoSetValue.ReferralCategoryId,
      ReferralItem: contactInfoSetValue.ReferralItem,
      ReferralItemId: contactInfoSetValue.ReferralItemId,
      ReferrerOrgName: contactInfoSetValue.ReferrerOrgName,
      ReferrerName: contactInfoSetValue.ReferrerName,
      ReferrerID: contactInfoSetValue.ReferrerID,
      ReferrerOrgID: contactInfoSetValue.ReferrerOrgID,
      ReferrerAgreementTypeId: contactInfoSetValue.ReferrerAgreementTypeId,
    };
    return { ...familyDetails, ...this.referral };
  }

  refreshConfigFeature() {
    this.generalService.getFeaturesNoBuilder().then(features => {
      setFeaturesData(features);
    });
  }

  checkHasCommissionAccess() {
    this.contactModelService
      .commissionAccess(this.currentUserService.familyId)
      .then(data => {
        const hasRecievedCommissions =
          data &&
          data.find(
            o => o.hasCommissionAccess && o.access === COMMISSION_RECIEVED_FROM
          );
        this.hasUserCommissionAccess =
          !!hasRecievedCommissions ||
          (this.currentUserService.isNZ &&
            !this.currentUserService.isCorporate &&
            this.currentUserService.accessType !== ACCESS_TYPE.ASSISTANT);
      });
  }
}

export default AccountService;
