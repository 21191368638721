export const ADDRESS_FIELD = {
  UNIT: 'Unit',
  STREET_NUMBER: 'StreetNumber',
  STREET_NAME: 'StreetName',
  SUBURB: 'Suburb',
  STATE: 'State',
  POST_CODE: 'PostCode',
};

export const REGEX = {
  POST_CODE: '\\d+',
};
