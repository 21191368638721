import { featureListBuilderForUI } from 'Common/mappers/corporate';

app.factory('corporateService', [
  '$q',
  'corporateModel',
  function($q, corporateModel) {
    var factory = this;
    var corporate = new corporateModel();

    factory.getAllByFilter = function(
      startWith,
      contactType,
      page,
      searchCriteria,
      adviserStatus,
      reportingState,
      reportingRegion,
      businessUnitId,
      adviserFamilyId
    ) {
      var defer = $q.defer();
      corporate.ContactListGet(
        startWith,
        contactType,
        page,
        searchCriteria,
        adviserStatus,
        reportingState,
        reportingRegion,
        businessUnitId,
        adviserFamilyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.assignAssistantToClientSet = function(
      assistantId,
      adviserId,
      clientFamilyId
    ) {
      var defer = $q.defer();
      corporate.assignAssistantToClientSet(
        assistantId,
        adviserId,
        clientFamilyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.assignAssistantToClientGet = function(adviserId, clientFamilyId) {
      var defer = $q.defer();
      corporate.assignAssistantToClientGet(
        adviserId,
        clientFamilyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.helpSupportContactListGet = function(startWith, contactType) {
      var defer = $q.defer();
      corporate.HelpSupportContactListGet(
        startWith,
        contactType,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.adviserOrganizationGet = function(
      adviserOrgComplianceId,
      adviserOrganizationId
    ) {
      var defer = $q.defer();
      corporate.AdviserOrganizationGet(
        adviserOrgComplianceId,
        adviserOrganizationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.adviserOrganizationSet = function(saveType, data) {
      var defer = $q.defer();
      corporate.AdviserOrganizationSet(
        saveType,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.adviserOrgTypesGet = function() {
      var defer = $q.defer();
      corporate.AdviserOrgTypesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.contactsUnderOrganizationGet = function(
      adviserOrganizationId,
      page,
      size
    ) {
      var defer = $q.defer();
      corporate.ContactsUnderOrganizationGet(
        adviserOrganizationId,
        page,
        size,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.adviserOrganizationEntityTypesGet = function() {
      var defer = $q.defer();
      corporate.AdviserOrganizationEntityTypesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.clientUTMDetailsGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.ClientUTMDetailsGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.clientUTMDetailsSet = function(familyId, clientId, data) {
      var defer = $q.defer();
      corporate.ClientUTMDetailsSet(
        familyId,
        clientId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.brandingCategoryListGet = function(countryId) {
      var defer = $q.defer();
      corporate.BrandingCategoryListGet(
        countryId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.operatingCountriesGet = function() {
      var defer = $q.defer();
      corporate.OperatingCountriesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.adviserOrganizationStatusesGet = function() {
      var defer = $q.defer();
      corporate.AdviserOrganizationStatusesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.advisersGet = function(
      familyId,
      searchSaveAdviserOrg,
      excludeNoAddress,
      getAdviserDetail,
      withGeoCode,
      leadReceiveStatusIds,
      leadPriorities,
      corpLeadCampaignIDs,
      businessUnitIds
    ) {
      var defer = $q.defer();
      corporate.AdvisersGet(
        familyId,
        searchSaveAdviserOrg,
        excludeNoAddress,
        getAdviserDetail,
        withGeoCode,
        leadReceiveStatusIds,
        leadPriorities,
        corpLeadCampaignIDs,
        businessUnitIds,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.assignClientToAdviserSet = function(
      adviserFamilyId,
      familyId,
      clientId
    ) {
      var defer = $q.defer();
      corporate.AssignClientToAdviserSet(
        adviserFamilyId,
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.corporateContactGet = function(clientId, familyId) {
      var defer = $q.defer();
      corporate.CorporateContactGet(
        clientId,
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.adviserOrganizationListGet = function() {
      var defer = $q.defer();
      corporate.AdviserOrganizationListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.brokerStatusListGet = function() {
      var defer = $q.defer();
      corporate.BrokerStatusListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.operatingCountriesGet = function() {
      var defer = $q.defer();
      corporate.OperatingCountriesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.reportingRegionsGet = function(countryId) {
      var defer = $q.defer();
      corporate.reportingRegionsGet(
        countryId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.reportingStatesGet = function() {
      var defer = $q.defer();
      corporate.ReportingStatesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.commissionTiersGet = function(countryId) {
      var defer = $q.defer();
      corporate.CommissionTiersGet(
        countryId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.adviserAchievementsGet = function(fiscalYear) {
      var defer = $q.defer();
      corporate.AdviserAchievementsGet(
        fiscalYear,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.enquirySourcesGet = function() {
      var defer = $q.defer();
      corporate.EnquirySourcesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyAddressGet = function(familyId) {
      var defer = $q.defer();
      corporate.FamilyAddressGet(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyAddressSet = function(
      familyId,
      clientId,
      addressId,
      isMailing,
      isBusiness,
      overrideFlag,
      isCustomAddress
    ) {
      var defer = $q.defer();
      corporate.FamilyAddressSet(
        familyId,
        clientId,
        addressId,
        isMailing,
        isBusiness,
        overrideFlag,
        isCustomAddress,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.adviserPreviousNameGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.AdviserPreviousNameGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.adviserPreviousNameSet = function(
      previousName,
      familyId,
      clientId
    ) {
      var defer = $q.defer();
      corporate.AdviserPreviousNameSet(
        previousName,
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.organizationAddressGet = function(id) {
      var defer = $q.defer();
      corporate.OrganizationAddressGet(
        id,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.organizationAddressSet = function(
      organizationId,
      orgComplianceId,
      address,
      isMailing,
      overrideFlag,
      isCustomAddress
    ) {
      var defer = $q.defer();
      corporate.OrganizationAddressSet(
        organizationId,
        orgComplianceId,
        address,
        isMailing,
        overrideFlag,
        isCustomAddress,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.contactTypeRolesGet = function() {
      var defer = $q.defer();
      corporate.ContactTypeRolesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyMarketingDetailGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.FamilyMarketingDetailGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyNpsGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.FamilyNpsGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyMarketingDetailSet = function(familyId, clientId, data) {
      var defer = $q.defer();
      corporate.FamilyMarketingDetailSet(
        familyId,
        clientId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyBrokerSupportGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.FamilyBrokerSupportGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyBrokerSupportSet = function(familyId, clientId, data) {
      var defer = $q.defer();
      corporate.FamilyBrokerSupportSet(
        familyId,
        clientId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyComplianceGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.FamilyComplianceGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyComplianceSet = function(familyId, clientId, data) {
      var defer = $q.defer();
      corporate.FamilyComplianceSet(
        familyId,
        clientId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyWebManagementGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.FamilyWebManagementGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyWebManagementSet = function(familyId, clientId, data) {
      var defer = $q.defer();
      corporate.FamilyWebManagementSet(
        familyId,
        clientId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.contactSet = function(saveType, data) {
      var defer = $q.defer();
      corporate.ContactSet(
        saveType,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.sendPIExpiryNotifications = function(date) {
      var defer = $q.defer();
      corporate.SendPIExpiryNotifications(
        date,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.sendEDRSchemeExpiryNotifications = function(date) {
      var defer = $q.defer();
      corporate.SendEDRSchemeExpiryNotifications(
        date,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.sendExpiryNotifications = function(date) {
      var defer = $q.defer();
      corporate.SendExpiryNotifications(
        date,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.sendProfSchemeNotifications = function(date) {
      var defer = $q.defer();
      corporate.SendProfSchemeNotifications(
        date,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.leadsProfileGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.LeadsProfileGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.leadsProfileSet = function(familyId, clientId, data) {
      var defer = $q.defer();
      data.FamilyId = familyId;
      data.ClientId = clientId;

      corporate.LeadsProfileSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.referrerOrgGet = function(referrerComplianceId, referrerOrgId) {
      var defer = $q.defer();
      corporate.ReferrerOrgGet(
        referrerComplianceId,
        referrerOrgId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.organizationTypesGet = function() {
      var defer = $q.defer();
      corporate.OrganizationTypesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.referrerOrgSet = function(
      referrerComplianceId,
      referrerOrgId,
      data
    ) {
      var defer = $q.defer();
      data.ReferrerOrgComplianceId = referrerComplianceId;
      data.ReferrerOrgId = referrerOrgId;
      corporate.ReferrerOrgSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.referrerOrgDirectorSet = function(organizationId, data) {
      var defer = $q.defer();
      corporate.ReferrerOrgDirectorSet(
        organizationId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.referrerOrgDirectorDelete = function(data) {
      var defer = $q.defer();
      var organizationId = data.organizationId;
      var directorId = data.directorId;
      corporate.ReferrerOrgDirectorDelete(
        organizationId,
        directorId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getReferrerOrganization = function(data) {
      var defer = $q.defer();
      corporate.getReferrerOrganization(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getMembershipRoles = function(data) {
      var defer = $q.defer();
      corporate.getMembershipRoles(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getMembershipByReferrerOrg = function(referrerOrganizationId) {
      var defer = $q.defer();
      corporate.getMembershipByReferrerOrg(
        referrerOrganizationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getOrganizationWithAgreement = function(
      referrerOrganizationId,
      referrerFamilyId
    ) {
      var defer = $q.defer();
      corporate.getOrganizationWithAgreement(
        referrerOrganizationId,
        referrerFamilyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.setReferrerOrgMembership = function(data) {
      var defer = $q.defer();
      corporate.setReferrerOrgMembership(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.setReferrerOrgRelationship = function(data) {
      var defer = $q.defer();
      corporate.setReferrerOrgRelationship(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getAgreementType = function() {
      var defer = $q.defer();
      corporate.getAgreementType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getReferrer = function() {
      var defer = $q.defer();
      corporate.getReferrer(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.referrerOrganizationMembershipsByOrganizationGet = function(
      organizationId
    ) {
      var defer = $q.defer();
      corporate.ReferrerOrganizationMembershipsByOrganizationGet(
        organizationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setOrganizationAgreement = function(complianceId, data) {
      var defer = $q.defer();
      corporate.setOrganizationAgreement(
        complianceId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.referrerOrganizationRelationshipsGet = function(
      familyId,
      clientId
    ) {
      var defer = $q.defer();
      corporate.ReferrerOrganizationRelationshipsGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.referrerOrganizationMembershipsByReferrerGet = function(
      familyId,
      clientId
    ) {
      var defer = $q.defer();
      corporate.ReferrerOrganizationMembershipsByReferrerGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.advisersUnderOrganizationGet = function(
      adviserOrganizationId,
      fromLMWMAgreement
    ) {
      var defer = $q.defer();
      // Default to False if fromLMWMAgreement is undefined.
      var fromLMWMAgreementTemp = _.isUndefined(fromLMWMAgreement)
        ? false
        : fromLMWMAgreement;
      corporate.AdvisersUnderOrganizationGet(
        adviserOrganizationId,
        fromLMWMAgreementTemp,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.organizationAgreementGet = function(
      agreementId,
      referrerOrgId,
      adviserOrgId,
      adviser_familyId,
      agreementTypeId
    ) {
      var defer = $q.defer();
      corporate.OrganizationAgreementGet(
        agreementId,
        referrerOrgId,
        adviserOrgId,
        adviser_familyId,
        agreementTypeId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyRecruitmentDetailsGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.FamilyRecruitmentDetailsGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.inquirySourceListGet = function() {
      var defer = $q.defer();
      corporate.InquirySourceListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getPerceivedQualityList = function() {
      var defer = $q.defer();
      corporate.getPerceivedQualityList(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getProspectiveRatingList = function() {
      var defer = $q.defer();
      corporate.getProspectiveRatingList(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getRecruitmentStatusList = function() {
      var defer = $q.defer();
      corporate.getRecruitmentStatusList(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getRecruitmentNotProceedingReasonList = function() {
      var defer = $q.defer();
      corporate.getRecruitmentNotProceedingReasonList(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.setFamilyRecruitmentDetails = function(familyId, clientId, data) {
      var defer = $q.defer();
      corporate.setFamilyRecruitmentDetails(
        familyId,
        clientId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.perceivedQualityListGet = function() {
      var defer = $q.defer();
      corporate.PerceivedQualityListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.prospectiveRatingListGet = function() {
      var defer = $q.defer();
      corporate.ProspectiveRatingListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.recruitmentStatusListGet = function() {
      var defer = $q.defer();
      corporate.RecruitmentStatusListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.recruitmentNotProceedingReasonListGet = function() {
      var defer = $q.defer();
      corporate.RecruitmentNotProceedingReasonListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.recruitmentOnboardingStatusListGet = function() {
      var defer = $q.defer();
      corporate.RecruitmentOnboardingStatusListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.familyRecruitmentDetailsSet = function(familyId, clientId, data) {
      var defer = $q.defer();
      corporate.FamilyRecruitmentDetailsSet(
        familyId,
        clientId,
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.checkIfFamilyHasValidEmailGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.CheckIfFamilyHasValidEmailGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.leadsReceiveStatusDropdownGet = function() {
      var defer = $q.defer();
      corporate.LeadsReceiveStatusDropdownGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAccreditationFormPdf = function(familyId) {
      var defer = $q.defer();
      corporate.getAccreditationFormPdf(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getBrandingCategoryList = function(countryId) {
      var defer = $q.defer();
      corporate.getBrandingCategoryList(
        countryId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAgreementDocument = function(agreementId) {
      var defer = $q.defer();
      corporate.getAgreementDocument(
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setSendAccreditationEmail = function(familyId) {
      var defer = $q.defer();
      corporate.setSendAccreditationEmail(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setSendDocuSignAgreementToRecipients = function(agreementId) {
      var defer = $q.defer();
      corporate.setSendDocuSignAgreementToRecipients(
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getEmailTemplates = function(userTemplateId) {
      var defer = $q.defer();
      corporate.getEmailTemplates(
        userTemplateId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setEmailTemplates = function(data) {
      var defer = $q.defer();
      corporate.setEmailTemplates(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setEmailTemplateFolder = function(data) {
      var defer = $q.defer();
      corporate.setEmailTemplateFolder(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setTemplateSorting = function(data) {
      var defer = $q.defer();
      corporate.setTemplateSorting(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getRayWhiteAdvisers = function(size, from, orgId) {
      var defer = $q.defer();
      corporate.getRayWhiteAdvisers(
        size,
        from,
        orgId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getRayWhiteOrganisations = function(size, from, searchKey) {
      var defer = $q.defer();
      corporate.getRayWhiteOrganisations(
        size,
        from,
        searchKey,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getMergeFields = function(isSMS) {
      var defer = $q.defer();
      corporate.getMergeFields(
        isSMS,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.MergeFieldsGet = function(
      familyId,
      clientId,
      loanId,
      policyId,
      isSMS,
      loanStructureId
    ) {
      var defer = $q.defer();
      corporate.MergeFieldsGet(
        familyId,
        clientId,
        loanId,
        policyId,
        isSMS,
        loanStructureId || 0,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.loanMergeFields = function(familyId, loanId) {
      var defer = $q.defer();
      corporate.loanMergeFields(
        familyId,
        loanId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.getTargetManagement = function(
      targetManagementID,
      targetMonth,
      targetYear,
      teamId,
      targetOwnerID
    ) {
      var defer = $q.defer();
      corporate.getTargetManagement(
        targetManagementID,
        targetMonth,
        targetYear,
        teamId,
        targetOwnerID,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAdminUsers = function() {
      var defer = $q.defer();
      corporate.getAdminUsers(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getRelatedProductGroup = function() {
      var defer = $q.defer();
      corporate.getRelatedProductGroup(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getActiveCRMCountryCurrency = function() {
      var defer = $q.defer();
      corporate.getActiveCRMCountryCurrency(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getRelatedLoanStatus = function() {
      var defer = $q.defer();
      corporate.getRelatedLoanStatus(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setTargetManagement = function(data) {
      var defer = $q.defer();
      corporate.setTargetManagement(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.currentOnboardingTabGet = function(familyId) {
      var defer = $q.defer();
      corporate.CurrentOnboardingTabGet(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.currentOnboardingTabSet = function(
      familyId,
      currentOnboardingTab,
      onboardingStatusID
    ) {
      var defer = $q.defer();
      corporate.CurrentOnboardingTabSet(
        familyId,
        currentOnboardingTab,
        onboardingStatusID,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getTargetTracking = function(targetManagementId) {
      var defer = $q.defer();
      corporate.getTargetTracking(
        targetManagementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getTargetLoanSummaryStatus = function(
      relatedLoanStatusId,
      teamId,
      valueType,
      targetMonth,
      targetYear,
      widgetUserID,
      businessUnitId
    ) {
      var defer = $q.defer();
      corporate.getTargetLoanSummaryStatus(
        relatedLoanStatusId,
        teamId,
        valueType,
        targetMonth,
        targetYear,
        widgetUserID,
        businessUnitId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getWidgetOptionTimePeriodList6 = function() {
      var defer = $q.defer();
      corporate.getWidgetOptionTimePeriodList6(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loansSummaryLenderSplitGet = function(
      relatedLoanStatusId,
      teamId,
      targetMonth,
      targetYear
    ) {
      var defer = $q.defer();
      corporate.LoansSummaryLenderSplitGet(
        relatedLoanStatusId,
        teamId,
        targetMonth,
        targetYear,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.adviserLeaderBoardLoanSettlementGet = function(
      relatedLoanStatusId,
      valueType,
      teamId,
      referralSourceId,
      targetMonth,
      targetYear
    ) {
      var defer = $q.defer();
      corporate.AdviserLeaderBoardLoanSettlementGet(
        relatedLoanStatusId,
        valueType,
        teamId,
        referralSourceId,
        targetMonth,
        targetYear,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loansSummaryReferralSplitGet = function(
      relatedLoanStatusId,
      teamId,
      targetMonth,
      targetYear
    ) {
      var defer = $q.defer();
      corporate.LoansSummaryReferralSplitGet(
        relatedLoanStatusId,
        teamId,
        targetMonth,
        targetYear,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.lendersListPerAdviserGet = function(familyId) {
      var defer = $q.defer();
      corporate.LendersListPerAdviserGet(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.recruitmentPreferencesGet = function(familyId, clientId) {
      var defer = $q.defer();
      corporate.RecruitmentPreferencesGet(
        familyId,
        clientId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAdvisersDropdown = function(
      familyId,
      searchSaveAdviserOrg,
      excludeAddress,
      loanWritersOnly
    ) {
      var defer = $q.defer();
      corporate.getAdvisersDropdown(
        familyId,
        searchSaveAdviserOrg,
        excludeAddress,
        loanWritersOnly,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.recruitmentPreferencesSet = function(familyId, clientId, keepACL) {
      var defer = $q.defer();
      corporate.RecruitmentPreferencesSet(
        familyId,
        clientId,
        keepACL,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAgreementStatusLookup = function() {
      var defer = $q.defer();
      corporate.getAgreementStatusLookup(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAssistantList = function(familyId, searchSameAdviserOrg) {
      var defer = $q.defer();
      corporate.getAssistantList(
        familyId,
        searchSameAdviserOrg,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getClientType = function() {
      var defer = $q.defer();
      corporate.getClientType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.confSponsorship2017Get = function() {
      var defer = $q.defer();
      corporate.ConfSponsorship2017Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.businessUnitGet = function() {
      var defer = $q.defer();
      corporate.BusinessUnitGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.assignAssistantToAdvisersSet = function(assistId, advId, familyId) {
      var defer = $q.defer();
      corporate.assignAssistantToAdvisersSet(
        assistId,
        advId,
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.adviserAssistantsGet = function(advId, familyId) {
      var defer = $q.defer();
      corporate.adviserAssistantsGet(
        advId,
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.cessationReasonGet = function() {
      var defer = $q.defer();
      corporate.CessationReasonGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setRecruitmentReadyToProceedEmail = function(familyId) {
      var defer = $q.defer();
      corporate.setRecruitmentReadyToProceedEmail(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.corpLeadCampaignGet = function() {
      var defer = $q.defer();
      corporate.CorpLeadCampaignGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getGoogleGeoCodedAdress = function(address) {
      var defer = $q.defer();
      corporate.getGoogleGeoCodedAdress(
        address,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.googleGeoCodedAddressGet = function(address) {
      var defer = $q.defer();
      corporate.googleGeoCodedAddressGet(
        address,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.familyAchievementGet = function(familyId) {
      var defer = $q.defer();
      corporate.FamilyAchievementGet(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.familyAchievementSet = function(
      familyId,
      achievementID,
      fiscalYear,
      isDeleted,
      familyAchievementsId
    ) {
      var defer = $q.defer();
      corporate.FamilyAchievementSet(
        familyId,
        achievementID,
        fiscalYear,
        isDeleted,
        familyAchievementsId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteFamilyLenderAccreditation = function(familyId, lenderId) {
      var defer = $q.defer();
      corporate.deleteFamilyLenderAccreditation(
        familyId,
        lenderId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.checkLoanPipelineGet = function(familyId) {
      var defer = $q.defer();
      corporate.checkLoanPipelineGet(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.reallocateLoanPipelineSet = function(familyId, clientId, loanId) {
      var defer = $q.defer();
      corporate.reallocateLoanPipelineSet(
        familyId,
        clientId,
        loanId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.resetLoginTotalFailedAttemptsSet = function(familyId) {
      var defer = $q.defer();
      corporate.resetLoginTotalFailedAttemptsSet(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.activeLoanWritersGet = function(familyId) {
      var defer = $q.defer();
      corporate.activeLoanWritersGet(
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.complianceReviewGet = function(
      adviserFamilyId,
      complianceReviewId
    ) {
      var defer = $q.defer();
      corporate.complianceReviewGet(
        adviserFamilyId,
        complianceReviewId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.complianceReviewSet = function(complianceObj) {
      var defer = $q.defer();
      corporate.complianceReviewSet(
        complianceObj,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getFeatures = function() {
      var defer = $q.defer();
      corporate.getFeatures(
        response => {
          factory.data = response.data;
          return defer.resolve(response.data.map(featureListBuilderForUI));
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.postFeatureAccess = function(accessModel) {
      var defer = $q.defer();
      corporate.postFeatureAccess(
        accessModel,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteFeatureAccess = function(id) {
      var defer = $q.defer();
      corporate.deleteFeatureAccess(
        id,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAdviserOrganizationList = function(params = {}) {
      var defer = $q.defer();
      corporate.getAdviserOrganizationList(
        params,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    return factory;
  },
]);
