import angular from 'angular';
import _ from 'lodash';

class EmailTemplatesService {
  constructor($q, corporateService, templateService) {
    this.$q = $q;
    this.corporateService = corporateService;
    this.templateService = templateService;
  }

  getEmailTemplates() {
    const defer = this.$q.defer();

    this.corporateService.getEmailTemplates(0).then(response => {
      let blankCategory = _.remove(response.data, folder =>
        this.templateService.isBlankFolderOrTemplate(folder)
      )[0];
      let blankTemplate = this.templateService.getBlankTemplateIn(
        blankCategory
      );
      let emailTemplateListing = this.templateService.filterTemplatesByUserType(
        response.data
      );

      defer.resolve({ emailTemplateListing, blankCategory, blankTemplate });
    });

    return defer.promise;
  }
}

angular
  .module('app')
  .factory(
    'emailTemplatesService',
    ($q, corporateService, templateService) =>
      new EmailTemplatesService($q, corporateService, templateService)
  );
