import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import $ from 'jquery';
import { ACCESS_TYPE, CORP_ACCESS_TYPE } from 'Common/constants/accessTypes';
import { CONTACT_TYPE } from 'Common/constants/contactType';
import { brokerStatusFilterProspect } from 'Common/utilities/brokerStatusFilterProspect';
import { demoVideoForNZ } from './util/contactsCtrl';

const ADVISER_FILTER_INDEX = {
  ASSIGNED_ADVISER: 1,
  CONTACT_STATUS: 2,
  RECORD_TYPE: 3,
  CATEGORY: 5,
  RATING: 6,
};

const defaultReportingRegion = {
  ReportingRegionId: 0,
  ReportingRegionName: 'All Regions',
};

angular
  .module('app')
  .controller('ContactsCtrl', function ContactsCtrl(
    $rootScope,
    $scope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    $q,
    contactService,
    userService,
    corporateService,
    NgTableParams,
    $filter,
    $element,
    $window,
    SweetAlert,
    dashboardService,
    $anchorScroll,
    $location,
    optionsService,
    $state,
    $localStorage,
    DEMO_VIDEO,
    broadcastDataService,
    regionalizationService,
    configService,
    mergeContactsService,
    uiService,
    currentUserService
  ) {
    $scope.openModalForDemoVideo = function(size) {
      demoVideoForNZ({ $uibModal, currentUserService });
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId() {
            return DEMO_VIDEO.CONTACTS.Url;
          },
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    $scope.contacts = {
      ratings: '',
      categories: '',
    };
    $scope.contacts.isCorporateUser = false;

    $scope.clientMergingEnabled = uiService.showMergeContactsOption;

    let familyId = 0;
    let fullName = '';
    dashboardService.getBrokerBasicInfo().then(response => {
      familyId = response.data.BrokerId;
      fullName = response.data.FullName;
      $scope.currentBrokerId = response.data.BrokerId;
    });

    const getRecentViewed = () => {
      broadcastDataService.recentView.get().then(res => {
        broadcastDataService.recentView.list = res;
      });
    };
    getRecentViewed();

    const isOtherTicked = data => {
      let bool = false;
      data.forEach(val => {
        if (val.ContactTypeId > -1 && val.ticked) {
          bool = true;
        }
      });

      return bool;
    };

    $scope.showFilters = false;
    $scope.showFiltersIpadView = () => {
      $scope.showFilters = !$scope.showFilters;
    };

    $scope.contactModel = {};
    $scope.contactModel.searchContactText = '';

    $scope.screenSize = $window.innerWidth;
    $scope.tableWidthCheck = parseInt($window.innerWidth * 0.02, 10);
    if ($scope.screenSize < 992) {
      $scope.isNotesPanelDisplayed = false;
      $scope.isAdditionalPanelDisplayed = false;
    } else {
      $scope.isNotesPanelDisplayed = true;
      $scope.isAdditionalPanelDisplayed = true;
    }
    const viewport = () => {
      let e = $window;
      let a = 'inner';
      if (!('innerWidth' in $window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = () => {
      return {
        w: viewport().width,
      };
    };

    // Filters
    $scope.filterInitial = (...args) => {
      let passInitial = '';
      if (!args || !args[0]) {
        return;
      }
      for (let x = 0; x < args.length; x++) {
        if (!_.isNil(args[x]) && _.isString(args[x])) {
          passInitial = `${passInitial}${args[x].charAt(0).toUpperCase()}`;
        }
      }
      return passInitial;
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        $scope.screenSize = newValue.w;
        $scope.tableWidthCheck = parseInt($window.innerWidth * 0.02, 10);
        if (newValue.w < 992) {
          $scope.isNotesPanelDisplayed = false;
          $scope.isAdditionalPanelDisplayed = false;
        } else {
          $scope.isNotesPanelDisplayed = true;
          $scope.isAdditionalPanelDisplayed = true;
        }
      },
      true
    );

    // Get FamilyId

    // Filter Contact
    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel'>All Contacts</div>", // default-label is deprecated and replaced with this.
    };
    $scope.contactTypeList = [];
    let lastFilter = [];

    const getContactTypes = () => {
      contactService.getContactType().then(response => {
        const restrictedContactType = [
          CONTACT_TYPE.REFERRER,
          CONTACT_TYPE.REFERRER_ORG,
        ];
        const isReferrerExpose = configService.feature.exposeReferrerToAdviser;
        const { data } = response;
        if (!data || !data.length) return;
        const restrictedTypeList = data.filter(type => {
          const typeId = parseInt(type.ContactTypeId, 10);
          const isRestricted = restrictedContactType.indexOf(typeId) !== -1;
          return !isRestricted || (isReferrerExpose && isRestricted);
        });
        $scope.contactTypeList = $scope.contacts.isCorporateUser
          ? data
          : restrictedTypeList;
        const temp = $scope.contactTypeList.filter(type => {
          return (
            type.ContactTypeDesc.toLowerCase() === lastFilter.toLowerCase()
          );
        });

        if (temp.length > 0) {
          $scope.contacts.contactType = temp[0].ContactTypeDesc;
        } else if ($scope.contacts.isCorporateUser) {
          $scope.contacts.contactType =
            $scope.contactTypeList[1].ContactTypeDesc;
        } else {
          $scope.contacts.contactType =
            $scope.contactTypeList[0].ContactTypeDesc;
        }
        $scope.loadContactData();
      });
    };

    $scope.isFirstLoad = true;

    // Remember the Last Search Filter
    const getLastSearch = () => {
      contactService.getAdviserContactSearchOptions(familyId).then(response => {
        const { data } = response;
        $scope.lastSearchFilter = data;
        let _contactTypes = '';
        if (_.size(data[0]) > 0) {
          const temp = data[0].split(',');
          _contactTypes = temp.length > 0 ? temp[0] : '';
        }

        let contactTypes = _contactTypes || 'undefined';
        if (contactTypes === 'undefined' && $scope.contacts.isCorporateUser) {
          contactTypes = 'Adviser';
        } else if (contactTypes === 'undefined') {
          contactTypes = 'Client';
        }
        if (contactTypes.indexOf('Client') > -1) {
          const lastAdviserFamilyId = parseInt(data[1], 10);
          const temp =
            $scope.contacts.adviserList &&
            $scope.contacts.adviserList.filter(filtered => {
              return (
                parseInt(filtered.value, 10) ===
                parseInt(lastAdviserFamilyId, 10)
              );
            });

          if (!$scope.contacts.isCorporateUser) {
            const adviserList =
              $scope.contacts.adviserList &&
              $scope.contacts.adviserList.filter(filtered => {
                return parseInt(filtered.value, 10) === parseInt(familyId, 10);
              });

            if (adviserList.length > 0) {
              $scope.contacts.assignedAdviser = parseInt(familyId, 10);
            } else if ($scope.contacts.adviserList.length === 0) {
              const defaultAdviser = {
                value: familyId,
                name: fullName,
              };
              $scope.contacts.adviserList.push(defaultAdviser);
              $scope.contacts.assignedAdviser = familyId;
            } else {
              $scope.contacts.assignedAdviser =
                $scope.contacts.adviserList[0].value;
            }
          } else if (temp.length > 0) {
            $scope.contacts.assignedAdviser = lastAdviserFamilyId;
          } else if (
            $scope.contacts.isCorporateUser &&
            !_.isNil($scope.contacts.adviserList[0].value)
          ) {
            $scope.contacts.assignedAdviser =
              $scope.contacts.adviserList[0].value;
          } else {
            $scope.contacts.assignedAdviser = 0;
          }
          $scope.contacts.contactStatus =
            parseInt(data[2], 10) > 0 ? data[2] : '0';
          $scope.contacts.recordType =
            parseInt(data[3], 10) >= 0 ? data[3] : '-1';

          $scope.contacts.adviserStatus = -1;
          $scope.contacts.reportingState = 0;
          $scope.contacts.reportingRegion = 0;
          $scope.contacts.businessUnit = 0;
        } else {
          $scope.contacts.adviserStatus = parseInt(data[1], 10);
          $scope.contacts.reportingState = parseInt(data[2], 10);
          $scope.contacts.reportingRegion = parseInt(data[3], 10);
          $scope.contacts.businessUnit = parseInt(data[4], 10);
          if (!_.isNil($scope.contacts.adviserList[0].value)) {
            $scope.contacts.assignedAdviser =
              $scope.contacts.adviserList[0].value;
          } else {
            $scope.contacts.assignedAdviser = 0;
          }

          $scope.contacts.contactStatus = '0';
          $scope.contacts.recordType = '-1';
        }
        lastFilter = contactTypes;
        getContactTypes();
      });
    };

    // Contact Data
    //-------------------------------------------------------------

    $scope.contactData = {};
    $scope.totalContact = 0;
    $scope.searchText = '';
    $scope.activeFilterLatter = '*';
    $scope.activeFilterAlphaLatter = 'All';
    $scope.isProfileAdmin = false;
    $scope.isAdviserOnly = false;
    $scope.currentPage = 1;
    $scope.contacts.isContactLoading = true;
    $scope.contacts.isFilterLoading = true;
    $scope.changeContactType = function() {
      $scope.contacts.isFilterLoading = true;
      // getLastSearch();
      $scope.loadContactData();
    };

    $scope.loadContactData = function() {
      $scope.contacts.isLoaded = false;
      $scope.contacts.isContactLoading = true;
      $timeout(() => {
        $scope.contacts.isFilterLoading = false;
      }, 1000);
      if (
        $scope.contacts.isCorporateUser &&
        $scope.contacts.contactType !== 'Client'
      ) {
        $scope.itemPerPage = 20;
        const contactTypes = $scope.contacts.contactType;
        if (contactTypes === 'Adviser') {
          $scope.isAdviserOnly = true;
        } else {
          $scope.isAdviserOnly = false;
        }
        const adviserStatus = $scope.contacts.adviserStatus;
        const reportingState = $scope.contacts.reportingState;
        const reportingRegion = $scope.contacts.reportingRegion;
        const businessUnitId = $scope.contacts.businessUnit;
        const adviserFamilyId =
          contactTypes === 'Business' ? $scope.contacts.assignedAdviser : 0;
        $scope.contactData = [];

        corporateService
          .getAllByFilter(
            $scope.activeFilterLatter,
            contactTypes,
            $scope.currentPage,
            $scope.contactModel.searchContactText,
            adviserStatus,
            reportingState,
            reportingRegion,
            businessUnitId,
            adviserFamilyId
          )
          .then(result => {
            $scope.totalContact = 0;
            $scope.totalPagingCount = 0;
            if (result.data.length > 0) {
              $scope.totalContact = result.data[0].TotalCount;
              $scope.totalPagingCount = result.data[0].TotalCountStartsWith;
            }
            $scope.contactData = result.data;
            $timeout(() => {
              $scope.contacts.isContactLoading = false;
            }, 1200);

            $scope.contacts.isLoaded = true;
            $scope.tableParams = new NgTableParams(
              {
                page: 1,
                count: $scope.totalPagingCount,
                sorting: {
                  FamilyFullName: 'asc',
                },
              },
              {
                counts: [],
                total: $scope.totalPagingCount,
                getData($defer, params) {
                  const filterData = $scope.contactData;
                  const orderedData = params.sorting()
                    ? $filter('orderBy')(filterData, params.orderBy())
                    : filterData;
                  $defer.resolve(
                    orderedData.slice(
                      (params.page() - 1) * params.count(),
                      params.page() * params.count()
                    )
                  );

                  $location.hash('top-navbar');
                  $anchorScroll();
                },
              }
            );

            angular.forEach($scope.contactData, item => {
              $scope.selectedContactCheck[item.ContactId] = false;
            });
          });
      } else {
        const sortColumn = 'FamilyFullName';
        const sortMode = 'ASC';
        $scope.itemPerPage = 20;

        if (!$localStorage.assignedAdviser) {
          $localStorage.assignedAdviser = $scope.contacts.assignedAdviser;
          // $localStorage.setItem('assignedAdviser', $scope.contacts.assignedAdviser);
        } else {
          let assignedAdviser = 0;
          if (!$scope.contacts.changeAssignedAdviser) {
            // when selecting the adviser is not user input
            assignedAdviser = parseInt($localStorage.assignedAdviser, 10);
          } else {
            // $localStorage.setItem('assignedAdviser', $scope.contacts.assignedAdviser);
            $localStorage.assignedAdviser = $scope.contacts.assignedAdviser;
            assignedAdviser = $scope.contacts.assignedAdviser;
          }
          $scope.contacts.assignedAdviser = assignedAdviser;
        }

        if ($scope.firstInit) {
          const savedFilter = $scope.lastSearchFilter;
          const isValidCategories =
            savedFilter &&
            savedFilter[ADVISER_FILTER_INDEX.CATEGORY] &&
            savedFilter[ADVISER_FILTER_INDEX.CATEGORY].length;
          const isValidRatings =
            savedFilter &&
            savedFilter[ADVISER_FILTER_INDEX.RATING] &&
            savedFilter[ADVISER_FILTER_INDEX.RATING].length;

          if (isValidCategories) {
            const categoriesArray = savedFilter[
              ADVISER_FILTER_INDEX.CATEGORY
            ].split(',');
            $scope.categoryList.map(v => {
              if (!v.CategoryID) return v;
              if (categoriesArray.includes(v.CategoryID.toString())) {
                $scope.categoryList[0].ticked = false;
                v.ticked = true;
              }
              return v;
            });
          }

          if (isValidRatings) {
            const ratingsArray = savedFilter[ADVISER_FILTER_INDEX.RATING].split(
              ','
            );
            $scope.ratingList.map(v => {
              if (!v.RatingID) return v;
              if (ratingsArray.includes(v.RatingID.toString())) {
                $scope.ratingList[0].ticked = false;
                v.ticked = true;
              }
              return v;
            });
          }

          $scope.firstInit = false;
        }

        const adviserFamilyId = $scope.contacts.assignedAdviser;
        const clientContactStatusId = $scope.contacts.contactStatus || 0;
        const clientRecordType = $scope.contacts.recordType || -1;

        const ratingFilter = $scope.ratingList.filter(data => data.ticked);
        const selectedRatingsId =
          ratingFilter && ratingFilter.length
            ? ratingFilter.map(data => data.RatingID).join(',')
            : '-1';

        const categoryFilter = $scope.categoryList.filter(data => data.ticked);
        const selectedCategoriesId =
          categoryFilter && categoryFilter.length
            ? categoryFilter.map(data => data.CategoryID).join(',')
            : '-1';

        $scope.contactData = [];
        const contactText =
          $scope.contactModel.searchContactText.trim() || null;
        contactService
          .getAllByFilter(
            $scope.activeFilterLatter,
            $scope.currentPage,
            $scope.itemPerPage,
            sortColumn,
            sortMode,
            contactText,
            $scope.contacts.contactType,
            adviserFamilyId,
            clientContactStatusId,
            clientRecordType,
            selectedRatingsId,
            selectedCategoriesId
          )
          .then(result => {
            const { data } = result;
            $scope.totalContact =
              data && data.FamilyList && data.FamilyList.length > 0
                ? data.FamilyList[0].TotalCount
                : 0;
            $scope.totalPagingCount =
              data && data.FamilyList && data.FamilyList.length > 0
                ? data.FamilyList[0].TotalCountStartsWith
                : 0;

            $scope.contactData = data && data.FamilyList;
            $scope.contacts.isLoaded = true;
            $timeout(() => {
              $scope.contacts.isContactLoading = false;
            }, 1200);
            $scope.tableParams = new NgTableParams(
              {
                page: 1,
                count: $scope.totalContact,
                sorting: {
                  FamilyFullName: 'asc',
                },
              },

              {
                counts: [],
                total: $scope.totalContact,
                getData($defer, params) {
                  const filterData = $scope.contactData;
                  const orderedData = params.sorting()
                    ? $filter('orderBy')(filterData, params.orderBy())
                    : filterData;
                  $defer.resolve(
                    orderedData.slice(
                      (params.page() - 1) * params.count(),
                      params.page() * params.count()
                    )
                  );

                  $location.hash('top-navbar');
                  $anchorScroll();
                },
              }
            );
          });
      }
    };

    $scope.changeAdviser = isChanged => {
      $scope.contacts.changeAssignedAdviser = isChanged;
    };

    $scope.toggleRatingsFilter = isSelectAll => {
      if (isSelectAll) $scope.deselectAllRatings();
      const allRatingsFilter = {
        RatingName: 'All Ratings',
        RatingID: '',
        ticked: isSelectAll,
      };
      const ratingsRemovedFilterAll = $scope.ratingList.filter(
        rating => rating.RatingID
      );
      $scope.ratingList = [allRatingsFilter, ...ratingsRemovedFilterAll];
      if (!$scope.firstInit) $scope.loadContactData();
    };

    $scope.toggleCategoriesFilter = isSelectAll => {
      if (isSelectAll) $scope.deselectAllCategories();
      const allCategoriesFilter = {
        CategoryName: 'All Categories',
        CategoryID: '',
        ticked: isSelectAll,
      };
      const categoriesRemovedFilterAll = $scope.categoryList.filter(
        rating => rating.CategoryID
      );
      $scope.categoryList = [
        allCategoriesFilter,
        ...categoriesRemovedFilterAll,
      ];
      if (!$scope.firstInit) $scope.loadContactData();
    };

    $scope.updateRatingsFilter = data => {
      $scope.toggleRatingsFilter(!data.RatingID);
    };

    $scope.updateCategoriesFilter = data => {
      $scope.toggleCategoriesFilter(!data.CategoryID);
    };

    $scope.deselectAllAdvisers = () => {
      $scope.contacts.adviserList = $scope.contacts.adviserList.map(v => ({
        ...v,
        ticked: false,
      }));
    };

    $scope.deselectAllRatings = () => {
      $scope.ratingList = $scope.ratingList.map(v => ({ ...v, ticked: false }));
    };

    $scope.deselectAllCategories = () => {
      $scope.categoryList = $scope.categoryList.map(v => ({
        ...v,
        ticked: false,
      }));
    };

    $scope.setTitleType = isCorporateUser => {
      if (isCorporateUser) {
        return 'Contact Type';
      }
      return 'Client Type';
    };

    $scope.getRoute = contact => {
      if (!$scope.contacts.isContactLoading) {
        $window.sessionStorage.removeItem('currentTab');
        if (contact.ContactType === 'Adviser Organisation') {
          $state.go('app.contactsAdviserOrg', {
            adviserOrgId: contact.OrganizationId,
            adviserComplianceId: contact.OrgComplianceId,
          });
        } else if (
          contact.ContactType === 'Adviser' ||
          contact.ContactType === 'Principal Adviser'
        ) {
          $state.go('app.contactsAdviser', {
            clientId: contact.ClientId,
            familyId: contact.FamilyId,
          });
        } else if (contact.ContactType === 'Assistant') {
          $state.go('app.contactsAssistant', {
            clientId: contact.ClientId,
            familyId: contact.FamilyId,
          });
        } else if (
          contact.ContactType === 'Referrer Organisation' ||
          contact.ContactType === 'Referrer Org'
        ) {
          $state.go('app.contactsReferrerOrg', {
            referrerComplianceId: contact.OrgComplianceId,
            referrerOrgId: contact.OrganizationId,
          });
        } else if (contact.ContactType === 'Referrer') {
          $state.go('app.contactsReferrer', {
            clientId: contact.ClientId,
            familyId: contact.FamilyId,
          });
        } else if (contact.ContactType === 'Business') {
          $state.go('app.BusinessAccount', { familyId: contact.FamilyId });
        } else if (contact.ClientType === 'Referrer') {
          $state.go('app.contactsReferrer', {
            clientId: 0,
            familyId: contact.FamilyID,
          });
        } else if (contact.ClientType === 'Referrer Organisation') {
          $state.go('app.contactsReferrerOrg', {
            referrerComplianceId: 0,
            referrerOrgId: contact.FamilyID,
          });
        } else if (contact.ClientType === 'Business') {
          $state.go('app.BusinessAccount', { familyId: contact.FamilyID });
        } else {
          $state.go('app.contactsSingle', { familyId: contact.FamilyId });
        }
      }
    };

    $scope.showCreatedDateColumn = () => {
      let isShow = false;
      if (!$scope.contacts.contactType) return;
      if (
        $scope.contacts.contactType === CONTACT_TYPE.ADVISER_STR ||
        $scope.contacts.contactType === CONTACT_TYPE.PRINCIPAL_ADVISER
      ) {
        isShow = true;
      }
      return isShow;
    };

    $scope.getRouteClient = contact => {
      if (!$scope.contacts.isContactLoading) {
        if (contact.FamilyType === 'Entity') {
          $state.go('app.BusinessAccount', { familyId: contact.FamilyID });
        } else {
          $state.go('app.contactsSingle', { familyId: contact.FamilyID });
        }
      }
    };

    $scope.showDeleteButton = function(contact) {
      let isShow = false;
      if (contact) {
        if (contact.ContactType === 'Adviser Organisation') {
          isShow = true && $scope.isProfileAdmin;
        } else if (
          contact.ContactType === 'Adviser' ||
          contact.ContactType === 'Principal Adviser'
        ) {
          isShow = true && $scope.isProfileAdmin;
        } else if (contact.ContactType === 'Assistant') {
          isShow = true && $scope.isProfileAdmin;
        } else {
          isShow = true && $scope.contacts.isCorporateUser;
        }
      }
      return isShow;
    };

    $scope.getContactType = contactObj => {
      if (contactObj.ContactType === 'Adviser Organisation') {
        return contactObj.OrganizationId;
      } else if (contactObj.ContactType === 'Client') {
        return contactObj.FamilyId;
      }
    };
    // Filter Alphabets Latter
    //-------------------------------------------------------------

    $scope.filterLatters = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];

    $scope.filter = function(id) {
      $scope.currentPage = 1;
      $scope.searchText = '';
      $scope.contactModel.searchContactText = '';
      $scope.activeFilterLatter = id;
      $scope.activeFilterAlphaLatter = id;
      if (id === '*') {
        $scope.activeFilterAlphaLatter = 'All';
      }
      $scope.loadContactData();
    };

    // Search Contact
    //-------------------------------------------------------------
    $scope.searchContact = searchContactText => {
      $scope.searchText = searchContactText;
      $scope.contactModel.searchContactText = searchContactText;
      $scope.activeFilterLatter = '*';
      $scope.activeFilterAlphaLatter = 'All';
      $scope.currentPage = 1;
      $scope.loadContactData();
    };

    // Select Onr Or More Contact
    //-------------------------------------------------------------
    $scope.selectedContactCheck = {};
    $scope.selectAllContacts = false;
    $scope.selectedContactList = [];

    const toggleAll = (selectAllContacts, selectedItems) => {
      selectedItems &&
        Object.keys(selectedItems).forEach(id => {
          if (Object.prototype.hasOwnProperty.call(selectedItems, id)) {
            selectedItems[id] = selectAllContacts;
          }
        });
    };

    const toggleOne = (selectedItems, details) => {
      const contactIndex = _.findIndex($scope.selectedContactList, contact => {
        return contact.FamilyID === details.FamilyID;
      });

      if (contactIndex > -1) {
        _.pullAt($scope.selectedContactList, [contactIndex]);
      } else {
        $scope.selectedContactList.push(details);
      }

      $scope.selectAllContacts =
        _.findIndex(selectedItems, item => {
          return (
            Object.prototype.hasOwnProperty.call(selectedItems, item) &&
            !selectedItems[item]
          );
        }) !== -1;

      $scope.checkMergeability(selectedItems);
    };

    $scope.toggleAllContactsCheck = toggleAll;
    $scope.toggleOneContactCheck = toggleOne;

    // Delete Contact
    //-------------------------------------------------------------
    $scope.deleteContact = paramFamilyId => {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This contact will be removed from your list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        isConfirm => {
          if (isConfirm) {
            contactService.deleteContact(paramFamilyId).then(
              () => {
                $scope.loadContactData();
                swal('Deleted', 'Contact has been deleted.', 'success');
              },
              e => {
                if (!_.isNil(e.data.Message)) {
                  SweetAlert.swal('Delete Message', e.data.Message, 'error');
                } else {
                  SweetAlert.swal(
                    'Delete Message',
                    'Unable to delete contact',
                    'error'
                  );
                }
              }
            );
          }
        }
      );
    };

    // Add Client
    //-------------------------------------------------------------
    $scope.openAddClientModal = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/add_client_modal_v2.html',
        controller: 'ClientModalCtrl',
        size,
        resolve: {
          loadcontactdata() {
            return $scope.loadContactData;
          },
          contacts() {
            return $scope.contacts;
          },
        },
      });
    };

    // Merge Clients
    $scope.mergeability = {};
    $scope.checkMergeability = selectedItems => {
      $scope.doWhenAnyOfCountries(['NZD', 'AUD'], () => {
        const checkedRows = [];
        _.forOwn(selectedItems, (value, key) => {
          if (value) checkedRows.push(key);
        });

        const checkedRowsCount = checkedRows.length;

        if (checkedRowsCount === 2) {
          $scope.mergeability.contactsToMerge = [];
          _.each(checkedRows, rowKey => {
            const contact = _.find($scope.selectedContactList, contactData => {
              return String(contactData.FamilyID) === String(rowKey);
            });
            $scope.mergeability.contactsToMerge.push(contact);
          });

          mergeContactsService
            .checkClientsMergeability($scope.mergeability.contactsToMerge)
            .then(response => {
              if (!response) return;

              $scope.mergeability.mergeable = response.mergeable;
              $scope.mergeability.warning = response.warning;
              if (response.warning) {
                angular.element('html,body').animate({ scrollTop: 0 }, 1000);
              }
            });
        } else {
          $scope.mergeability.mergeable = false;
        }
      });
    };

    $scope.doWhenAnyOfCountries = (countries, action) => {
      if (!action || _.isEmpty(countries)) return;

      if ($scope.crmCountryCode) {
        if (countries.includes($scope.crmCountryCode)) {
          action();
        }
      } else {
        regionalizationService
          .getBrokerRegionalization($scope.currentBrokerId)
          .then(response => {
            $scope.crmCountryCode = response.data ? response.data.Code : null;
            if (countries.includes($scope.crmCountryCode)) {
              action();
            }
          });
      }
    };

    $scope.mergeContacts = function() {
      let countryCode;
      switch ($scope.crmCountryCode) {
        case 'AUD':
          countryCode = 'AU';
          break;

        case 'NZD':
          countryCode = 'NZ';
          break;
        default:
      }

      const props = {
        clientsToMerge: $scope.mergeability.contactsToMerge,
        countryCode,
      };

      $uibModal.open({
        template: `<select-merge-contact-type-modal
          modal-instance="vm.modalInstance"
          clients-to-merge="vm.props.clientsToMerge"
          country-code="vm.props.countryCode"
        ></select-merge-contact-type-modal>`,
        size: 'lg',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        windowClass: 'select-merge-contact-type-modal',
        resolve: {
          props: () => props,
        },
      });
    };

    $scope.contactTypeListSelected = [];
    $scope.onItemSelect = function(data) {
      if (parseInt(data.ContactTypeId, 10) === -1) {
        let i;
        for (i = 0; i < $scope.contactTypeList.length; i++) {
          if ($scope.contactTypeList[i].ContactTypeId > -1) {
            $scope.contactTypeList[i].ticked = false;
          } else {
            $scope.contactTypeList[i].ticked = true;
          }
        }
      } else {
        let i;
        for (i = 0; i < $scope.contactTypeList.length; i++) {
          if (
            parseInt($scope.contactTypeList[i].ContactTypeId, 10) === -1 &&
            !isOtherTicked($scope.contactTypeList)
          ) {
            $scope.contactTypeList[i].ticked = true;
            $scope.contactTypeListSelected.push($scope.contactTypeList[i]);
          } else if (
            parseInt($scope.contactTypeList[i].ContactTypeId, 10) === -1
          ) {
            $scope.contactTypeList[i].ticked = false;
          }
        }
      }

      $scope.loadContactData();
    };
    $scope.contactTypeListSelectedArray = [];
    $scope.onSelectAll = () => {
      for (let i = 0; i < $scope.contactTypeList.length; i++) {
        if ($scope.contactTypeList[i].ContactTypeId > -1) {
          $scope.contactTypeList[i].ticked = false;
        } else {
          $scope.contactTypeList[i].ticked = true;
        }
      }
    };
    $scope.$watch('contactTypeListSelected', () => {
      const vm = this;
      $('.hide-in-mobile .multiSelect')
        .nextAll('div.buttonLabel')
        .remove();
      if ($scope.contactTypeListSelected.length >= 3) {
        $('.multiSelect .buttonLabel:last-child()').html(
          `${$scope.contactTypeListSelected.length - 2} more ...` +
            `<span class="caret"></span>`
        );
      }
      $('.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))').each(
        () => {
          $(vm).html(
            `${$(
              vm
            ).text()}<i class="fa fa-close"></i> <span class="caret"></span>`
          );
        }
      );
    });

    // Adviser Status Buttons
    const btnClass = [
      'btn-success',
      'btn-light-blue',
      'btn-default',
      'btn-warning',
      'btn-info',
      'btn-danger',
    ];
    const fontClass = [
      'fa-paper-plane text-white',
      'fa-check text-white',
      'violet-color fa-times',
      'fa-search text-white',
      'fa-hand-paper-o text-white',
      'fa-ban text-white',
    ];

    $scope.setClasses = function(status, isbtn) {
      let retClass = '';
      switch (status) {
        case 'Onboarding':
          retClass = isbtn ? btnClass[0] : fontClass[0];
          break;
        case 'Active':
          retClass = isbtn ? btnClass[1] : fontClass[1];
          break;
        case 'Terminated by LM':
        case 'Terminated':
          retClass = isbtn ? btnClass[2] : fontClass[2];
          break;
        case 'Prospect':
          retClass = isbtn ? btnClass[3] : fontClass[3];
          break;
        case 'Ceased by Adviser':
          retClass = isbtn ? btnClass[4] : fontClass[4];
          break;
        case 'Suspended':
          retClass = isbtn ? btnClass[5] : fontClass[5];
          break;
        case 'Inactive':
          retClass = isbtn ? btnClass[5] : fontClass[5];
          break;
        default:
          retClass = isbtn ? btnClass[2] : fontClass[4];
      }
      return retClass;
    };

    $scope.categoryList = [];
    const getCategoryList = () => {
      contactService.categoryListsGet(0).then(response => {
        if (!response || !response.data) return;
        $scope.categoryList = response.data;
        $scope.toggleCategoriesFilter(true);
      });
    };

    $scope.ratingList = [];
    const getRatingList = () => {
      contactService.getRating().then(response => {
        if (!response || !response.data) return;
        $scope.ratingList = response.data;
        $scope.toggleRatingsFilter(true);
      });
    };

    const getBrokerStatusList = () => {
      corporateService.brokerStatusListGet().then(contactBrokerResponse => {
        $scope.contacts.brokerStatusLists = [
          {
            BrokerStatusId: -1,
            BrokerStatusName: 'All Status',
          },
        ];

        const brokerStatusFilterProspectData = brokerStatusFilterProspect(
          contactBrokerResponse.data
        );
        $scope.contacts.brokerStatusLists.push(
          ...brokerStatusFilterProspectData
        );
      });
    };

    const getReportingState = () => {
      $scope.contacts.reportingStateList = [];
      corporateService.reportingStatesGet().then(response => {
        response.data.unshift({
          ReportingStateId: 0,
          ReportingStateName: 'All State',
        });
        $scope.contacts.reportingStateList = response.data;
      });
    };

    const getReportingRegion = () => {
      $scope.contacts.reportingRegionList = [];
      corporateService
        .reportingRegionsGet(currentUserService.countryId)
        .then(response => {
          const { data } = response;
          if (!data || !data.length) return;
          $scope.contacts.reportingRegionList = [
            defaultReportingRegion,
            ...data,
          ];
        });
    };

    const getBrandingCategoryList = () => {
      corporateService.getBrandingCategoryList().then(response => {
        response.data.unshift({
          BrandingCategoryId: 0,
          BrandingCategory: 'All Types',
        });
        $scope.contacts.brandingCategoryList = response.data;
      });
    };

    const getBusinessUnit = () => {
      corporateService.businessUnitGet().then(response => {
        response.data.unshift({
          BusinessUnitID: 0,
          BusinessUnitName: 'All Business Unit',
        });
        $scope.contacts.businessUnitList = response.data;
      });
    };

    const getAdvisersDropdown = () => {
      optionsService.getAdvisers().then(adviserList => {
        $scope.contacts.adviserList = adviserList;
        getLastSearch();
      });
    };

    const getContactStatus = () => {
      optionsService.ContactStatusGet().then(response => {
        response.data.unshift({
          Name: 'All Status',
          Value: '0',
        });
        $scope.contacts.statusList = response.data;
        $scope.contacts.isFilterLoading = false;
      });
    };

    $scope.contactStatusColor = value => {
      let tempVal = '#9a86a8';
      if (typeof value !== 'undefined' && value !== '') {
        switch (value.toLowerCase()) {
          case 'lead':
            tempVal = '#9a86a8';
            break;
          case 'application':
            tempVal = '#42b4b4';
            break;
          case 'existing client':
            tempVal = '#579bcb';
            break;
          case 'professional partner':
            tempVal = '#54478e';
            break;
          case 'previous client':
            tempVal = '#cdc3d2';
            break;
          default:
        }
      }
      return tempVal;
    };

    $scope.showFiltersStatus = {
      clicked: false,
    };

    $scope.showFiltersStatusGet = () => {
      $scope.showFiltersStatus.clicked = !$scope.showFiltersStatus.clicked;
    };

    angular.element($window).bind('resize', () => {
      $scope.windowWidth = $window.innerWidth;
    });

    $scope.$watch(() => {
      $scope.windowWidth = $window.innerWidth;
      if ($scope.windowWidth > 991) {
        $scope.showFiltersStatus.clicked = true;
      }
      return $scope.windowWidth;
    });

    const init = () => {
      userService.GetUserInfo().then(response => {
        if (response.data) {
          $scope.isShowFeelingStuckPopOver = !response.data.GotItTicked;
          $scope.contacts.isCorporateUser =
            response.data.AccessType === ACCESS_TYPE.CORPORATE;
          $scope.isProfileAdmin =
            response.data.CorpAccessTypeID === CORP_ACCESS_TYPE.PROFILE_ADMIN;
        }
        $scope.firstInit = true;
        getBrokerStatusList();
        getReportingState();
        getReportingRegion();
        getBrandingCategoryList();
        getAdvisersDropdown();
        getContactStatus();
        getBusinessUnit();
        getCategoryList();
        getRatingList();
      });
    };
    init();
  });
