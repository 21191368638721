import {
  getPhoneNumberByType,
  getEmailByType,
  getFirstAddressId,
} from 'Common/utilities/contact.js';

export function manualIFinanceBuilderForUI(manualIFinance) {
  return (
    manualIFinance && {
      lenderName: manualIFinance.Lender,
      assetVariant: manualIFinance.ProductName,
      establishmentFee: manualIFinance.EstablishmentFee,
      accountKeepingFee: manualIFinance.AccountKeepingFee,
      repaymentAmount: manualIFinance.Repayment,
      productRepaymentFrequency: manualIFinance.ManualProdRepaymentFrequency,
      interestRate: manualIFinance.InterestRate,
      brokerageAmount: manualIFinance.BrokerageAmount,
      brokeragePercent: manualIFinance.BrokeragePercent,
    }
  );
}

export function manualIFinanceBuilderForMyCRM(manualIFinance) {
  return (
    manualIFinance && {
      Lender: manualIFinance.lenderName,
      ProductName: manualIFinance.assetVariant,
      EstablishmentFee: manualIFinance.establishmentFee,
      AccountKeepingFee: manualIFinance.accountKeepingFee,
      Repayment: manualIFinance.repaymentAmount,
      ManualProdRepaymentFrequency: manualIFinance.productRepaymentFrequency,
      InterestRate: manualIFinance.interestRate,
      BrokerageAmount: manualIFinance.brokerageAmount,
      BrokeragePercent: manualIFinance.brokeragePercent,
    }
  );
}

export function assetSupplierBuilderForMyCRM(assetSupplier) {
  return (
    assetSupplier && {
      AssetSupplierId: assetSupplier.assetSupplierId,
      FirstName: assetSupplier.firstname,
      LastName: assetSupplier.lastname,
      Title: assetSupplier.title,
      Gender: assetSupplier.gender,
      ACN: assetSupplier.abnacn,
      CompanyName: assetSupplier.companyName,
      DealerNumber: assetSupplier.dealerNumber,
      Phone: [
        {
          Type: 'Fax',
          Number: assetSupplier.fax,
        },
        {
          Type: 'Work',
          Number: assetSupplier.officePhone,
        },
        {
          Type: 'Mobile',
          Number: assetSupplier.mobile,
        },
      ],
      AddressId: assetSupplier.addressId,
      Email: [
        {
          Type: 'Email',
          EmailAddress: assetSupplier.email,
        },
      ],
    }
  );
}

export function assetSupplierBuilderForUI(assetSupplier) {
  return (
    assetSupplier && {
      assetSupplierId: assetSupplier.AssetSupplierId,
      firstname: assetSupplier.FirstName,
      lastname: assetSupplier.LastName,
      title: assetSupplier.Title,
      gender: assetSupplier.Gender,
      abnacn: assetSupplier.ACN,
      companyName: assetSupplier.CompanyName,
      dealerNumber: assetSupplier.DealerNumber,
      officePhone: getPhoneNumberByType(assetSupplier.Phone, 'Work'),
      mobile: getPhoneNumberByType(assetSupplier.Phone, 'Mobile'),
      fax: getPhoneNumberByType(assetSupplier.Phone, 'Fax'),
      addressId: assetSupplier.AddressId,
      email: getEmailByType(assetSupplier.Email, 'Email'),
    }
  );
}

export function possibleAdverseChangesBuilderForUI(possibleAdverseChanges) {
  return {
    clientId: possibleAdverseChanges.ClientId,
    clientFullName: possibleAdverseChanges.ClientFullName,
    familyId: possibleAdverseChanges.FamilyId,
    loanScenarioId: possibleAdverseChanges.LoanScenarioId,
    questionId: possibleAdverseChanges.QuestionId,
    answer: possibleAdverseChanges.Answer,
    natureOfChange: possibleAdverseChanges.NatureOfChange,
    periodOfImpact: possibleAdverseChanges.PeriodOfImpact,
    financialImpact: possibleAdverseChanges.FinancialImpact,
  };
}

export function possibleAnticipatedBuilderForUI(possibleAnticipated) {
  return {
    clientId: possibleAnticipated.ClientId,
    natureOfChangeId: possibleAnticipated.NatureOfChangeId,
    loanScenarioId: possibleAnticipated.LoanScenarioId,
    natureOfChange: possibleAnticipated.NatureOfChange,
    periodOfImpactDuration: possibleAnticipated.PeriodOfImpactDuration,
    startDate: possibleAnticipated.StartDate,
    periodOfImpactUnit: possibleAnticipated.PeriodOfImpactUnit,
    financialImpactOperator: possibleAnticipated.FinancialImpactOperator,
    financialImpactAmount: possibleAnticipated.FinancialImpactAmount,
    other: possibleAnticipated.Other,
  };
}

export function possiblePlanOrAnticipateBuilderForUI(possiblePlanOrAnticipate) {
  return {
    clientId: possiblePlanOrAnticipate.ClientId,
    optionId: possiblePlanOrAnticipate.OptionId,
    optionName: possiblePlanOrAnticipate.OptionName,
    details: possiblePlanOrAnticipate.Details,
  };
}
export function assetSupplierContactBuilderForUI(assetSupplier) {
  return {
    assetSupplierId: assetSupplier.PersonId,
    firstname: assetSupplier.FirstName,
    lastname: assetSupplier.LastName,
    title: assetSupplier.Title,
    gender: assetSupplier.Gender,
    abnacn: assetSupplier.ACN,
    companyName: assetSupplier.CompanyName,
    dealerNumber: assetSupplier.DealerNumber,
    officePhone: getPhoneNumberByType(assetSupplier.Phone, 'Work'),
    mobile: getPhoneNumberByType(assetSupplier.Phone, 'Mobile'),
    fax: getPhoneNumberByType(assetSupplier.Phone, 'Fax'),
    addressId: getFirstAddressId(assetSupplier.Address),
    email: getEmailByType(assetSupplier.Email, 'Email'),
  };
}

export function cqpDynamicFeeBuilderForUI(data) {
  return (
    data && {
      feeId: data.FeeId,
      loanApplicationID: data.LoanApplicationID,
      description: data.Description,
      amount: data.Amount,
      payableWhen: data.PayableWhen,
    }
  );
}

export function cqpDynamicFeeBuilderForMyCRM(data) {
  return (
    data && {
      FeeId: data.feeId,
      LoanApplicationID: data.loanApplicationID,
      Description: data.description,
      Amount: data.amount,
      PayableWhen: data.payableWhen,
    }
  );
}

export function documentZipBuilderForUI(data) {
  return (
    data && {
      name: data.Name,
      size: data.Size,
      contentType: data.ContentType,
      documentContent: data.DocumentContent,
      documentExists: data.DocumentExists,
      isSvg: data.IsSvg,
      imageUploadType: data.ImageUploadType,
    }
  );
}

export function loanScenarioFactFindBuilderForUI(data) {
  return (
    data && {
      IsLockable: data.IsLockable,
      IsLocked: data.IsLocked,
      SharedTo: data.SharedTo,
    }
  );
}

export function applicationStatusBuilderForUI(status) {
  return (
    status && {
      category: status.LoanStatusCategory,
      id: status.LoanStatusID,
      name: status.LoanStatusName,
    }
  );
}

export function lenderBuilderForUI(lender) {
  return (
    lender && {
      countryCode: lender.CountryCode,
      id: lender.LenderId,
      name: lender.LenderName,
    }
  );
}

export function loanBuilderForUI(data) {
  return (
    data && {
      applicants: data.Applictants && data.Applictants.trim(),
      borrower: data.Borrower,
      clientName: data.ClientName,
      referrerName: data.ClientReferrerName,
      createdDate: data.CreatedDate,
      familyList: data.FamilyList,
      fixedExpiryDescription: data.FixedExpiryDescription,
      hasBeenSentToNextGen: data.HasBeenSentToNextGen,
      isCurrent: data.IsCurrent,
      isOpportunity: data.IsOpportunity,
      isPipelineLinked: data.IsPipelineLinked,
      isReadOnly: data.IsReadOnly,
      lender: data.Lender && lenderBuilderForUI(data.Lender),
      loanAmount: data.LoanAmount,
      loanId: data.LoanID,
      loanScenarioId: data.LoanScenarioID,
      loanScenarioTitle: data.LoanScenarioTitle,
      status: data.LoanStatus && applicationStatusBuilderForUI(data.LoanStatus),
      loanStructureList: data.LoanStructureList,
      pipelineCardsId: data.PipelineCardsID,
      pipelineStatus: data.PipelineStatus,
      pipelineStatusId: data.PipelineStatusID,
      referenceNumber: data.ReferenceNumber,
    }
  );
}
