import { CLIENT_ROLE_TYPE } from 'Common/constants/roleType';

export const getClientInformationInContact = ({
  $scope,
  configService,
  clientInfoService,
}) => (apiService, clientsData) => {
  if (!$scope.familyId) return;
  const VALID_DIVORCED_ADULT_COUNT = 2;
  const { contactSplit } = configService.feature;
  apiService.clientInformGet($scope.familyId).then(response => {
    const { data } = response;
    if (!data) return;
    const clientsDataCopy = clientsData || data;

    if (contactSplit) {
      $scope.summaryData = clientInfoService.buildSummary(clientsDataCopy);
      const { Adults } = $scope.summaryData;

      $scope.showContactSplit =
        Adults && Adults.length === VALID_DIVORCED_ADULT_COUNT;
    }

    $scope.client = {
      information: data,
    };

    const primaryClient = $scope.client.information.filter(
      item => item.isPrimary
    );
    $scope.aLIQuoteEnabled = primaryClient.length
      ? primaryClient[0].IsAllocatedAdviserALIQuoteEnabled
      : false;

    clientInfoService.getClientHeader($scope.client);
    $scope.clientHeaderName = $scope.client.headerName;
    $scope.clientInitials = $scope.client.initials;

    $scope.contactModel.isSmsEnabled = false;
    if ($scope.client.information.length) {
      let mobileCount = 0;
      $scope.client.information.forEach(contact => {
        const filteredMobile = contact.Phone.filter(
          value => value.Type === 'Mobile'
        );
        mobileCount += filteredMobile.length;
      });
      $scope.contactModel.isSmsEnabled = mobileCount || false;
    }

    if (!$scope.showContactSplit || !data || !data.length) return;
    $scope.adults = data.filter(
      client => client.Role === CLIENT_ROLE_TYPE.ADULT
    );
  });
};
