import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { JASPER_FILTERS } from 'Common/constants/jasperReport';
import { reportValue, financialRange } from 'Common/default/jasperReport';
import {
  LENDER_SPLIT_PERIOD,
  LOAN_SIZE_PERIOD,
  SETTLEMENT_VOLUME_PERIOD,
  ENQUIRY_SOURCE_PERIOD,
} from 'Common/constants/dashboardWidget';

class JasperReportService {
  constructor(
    currentUserService,
    optionsService,
    reportService,
    jasperService,
    configService,
    $ocLazyLoad,
    $timeout,
    $window
  ) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.reportService = reportService;
    this.jasperService = jasperService;
    this.configService = configService;
    this.$ocLazyLoad = $ocLazyLoad;
    this.$timeout = $timeout;
    this.$window = $window;

    this.getJasperReportToken = this.getJasperReportToken.bind(this);
    this.visualizeGenerateReport = this.visualizeGenerateReport.bind(this);
    this.visualizeAuthError = this.visualizeAuthError.bind(this);
    this.reportCompleted = this.reportCompleted.bind(this);
    this.changeTotalPages = this.changeTotalPages.bind(this);
    this.error = this.error.bind(this);
  }
  getReportTypeSetting() {
    return this.jasperService.reportTypeSettings().then(data => {
      if (!data || !data.length) return [];
      const reportType = data.filter(type => {
        const userAccess =
          type.isEnabled &&
          type.accessTypes &&
          type.accessTypes.length &&
          type.accessTypes.includes(
            this.currentUserService.accessType.toString()
          );
        return userAccess;
      });
      return reportType;
    });
  }
  getAdviserForMultiSelect(loanWritersOnly) {
    return this.optionsService.getAdvisers(loanWritersOnly).then(data => {
      if (!data || !data.length) return [];
      if (data.length === 1) {
        data[0].ticked = true;
        return data;
      }
      const allAdviserObj = {
        value: -1,
        name: 'All Advisers',
        ticked: true,
      };
      return [allAdviserObj, ...data];
    });
  }
  onItemSelect(data, fieldName, source, selection) {
    this.selectionData = selection;
    if (!source || !source.length) return;
    source.map(row => {
      if (data[fieldName] === -1 || data[fieldName] === 0) {
        row.ticked = row[fieldName] === -1 || row[fieldName] === 0;
        if (row.ticked) {
          this.selectionData = row;
        }
      } else {
        const isDefaultSelection =
          row[fieldName] === -1 || row[fieldName] === 0;
        if (isDefaultSelection) {
          row.ticked = false;
        }
        if (this.selectionData.length === 0 && isDefaultSelection) {
          row.ticked = true;
        }
      }
      return row;
    });
  }
  onItemReset(fieldName) {
    const raw = this[`${fieldName}Raw`] || [];
    if (raw.length === 1) {
      raw[0].ticked = true;
      this[`${fieldName}List`] = raw;
      this[`${fieldName}Selected`] = raw[0];
      return;
    }
    const data = raw.map(item => {
      item.ticked = false;
      return item;
    });
    this[`${fieldName}List`] = data;
    this[`${fieldName}Selected`] = data && data.length ? data[0] : {};
    this[`${fieldName}Selected`].ticked = true;
  }
  displayFilters(filterId) {
    const isInvalid =
      !this.reportType ||
      !this.reportType.filters ||
      !this.reportType.filters.length ||
      !filterId;
    if (isInvalid) return;
    return this.reportType.filters.includes(filterId);
  }
  getLocalLang(value) {
    this.localLang = {
      selectAll: `<span>Select All</span>`,
      selectNone: `<span>Deselect All </span>`,
      reset: `<i class="fa fa-refresh"></i>`,
      search: `Search`,
      nothingSelected: `<div class="buttonLabel padding-left-10">All ${value}</div>`,
    };
    return this.localLang;
  }
  getJasperReportToken() {
    return this.reportService.token().then(response => {
      const { data } = response;
      if (!data) return null;
      const jasperToken = this.configService.allow_report_from_uat
        ? this.configService.jasper_token
        : data.token;
      const report = {
        token: encodeURIComponent(jasperToken),
        status: this.configService.allow_report_from_uat
          ? reportValue.status
          : data.status,
        url: this.configService.allow_report_from_uat
          ? reportValue.url
          : data.url,
      };
      return report;
    });
  }
  loadVisualizeJs(report) {
    const visualizeUrl = `${report.url}${reportValue.visualizeUrl}`;
    return this.$ocLazyLoad.load(visualizeUrl);
  }
  getJasperReportParameterArray(source, fieldName) {
    this.source = [...source];
    const arrayList =
      this.source &&
      this.source.length &&
      this.source.reduce((accum, value) => {
        const id =
          value && value[fieldName] ? parseInt(value[fieldName], 10) : null;
        if (id !== -1) return [...accum, id];
        return accum;
      }, []);
    return arrayList && arrayList.length ? arrayList : ['~NOTHING~'];
  }
  getJasperParameter() {
    this.report.parameter = {};
    if (
      !this.reportType ||
      !this.reportType.filters ||
      !this.reportType.filters.length
    )
      return;
    if (this.reportType.filters.includes(JASPER_FILTERS.DATE_RANGE_OPTION)) {
      this.report.parameter.Date_Range_Option = [this.dateRangeOption || 0];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.RELATIVE_DATE)) {
      this.report.parameter.Relative_Start_Date = [
        this.$filter('date')(this.date.start, 'yyyy-MM-dd'),
      ];
      this.report.parameter.Relative_End_Date = [
        this.$filter('date')(this.date.end, 'yyyy-MM-dd'),
      ];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.CUSTOM_DATE)) {
      this.report.parameter.Custom_Start_Date = [
        this.$filter('date')(this.date.start, 'yyyy-MM-dd'),
      ];
      this.report.parameter.Custom_End_Date = [
        this.$filter('date')(this.date.end, 'yyyy-MM-dd'),
      ];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.MONTH)) {
      const selectedMonth = this.monthSelected
        ? this.monthSelected
        : '~NOTHING~';
      this.report.parameter.Month_Of_Date = [selectedMonth];
    }
    if (
      this.reportType.filters.includes(JASPER_FILTERS.ADVISER_SINGLE) &&
      this.currentUserService.accessType !== ACCESS_TYPE.ADVISER
    ) {
      this.report.parameter.Broker_No = [this.adviserId || 0];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.ADVISER_MULTIPLE)) {
      this.report.parameter.Adviser_ID = this.getJasperReportParameterArray(
        this.adviserSelected,
        'value'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.REPORTING_STATE)) {
      this.report.parameter.State_List = this.getJasperReportParameterArray(
        this.reportingStateSelected,
        'ReportingStateId'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.LENDER)) {
      this.report.parameter.LenderName_InputControl = this.getJasperReportParameterArray(
        this.lenderSelected,
        'ProviderId'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.LENDER_ID)) {
      this.report.parameter.Lender_ID = this.getJasperReportParameterArray(
        this.lenderSelected,
        'ProviderId'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.BUSINESS_UNIT)) {
      this.report.parameter.BusinessUnit = this.getJasperReportParameterArray(
        this.businessUnitSelected,
        'BusinessUnitID'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.PIPELINE_STATUS_FULL)) {
      this.report.parameter.PipelineStatusID = this.getJasperReportParameterArray(
        this.pipelineStatusSelected,
        'statusId'
      );
    }
    if (
      this.reportType.filters.includes(JASPER_FILTERS.PIPELINE_STATUS_PARTIAL)
    ) {
      this.report.parameter.PipelineStatus = this.getJasperReportParameterArray(
        this.pipelineStatusSelected,
        'statusId'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.REFERRER_DETAILS)) {
      this.report.parameter.ReferrerOrgID = this.referrerOrgId
        ? [this.referrerOrgId]
        : ['~NOTHING~'];
      this.report.parameter.ReferrerID = this.referrerId
        ? [this.referrerId]
        : ['~NOTHING~'];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.INCLUDED_MARKETING)) {
      this.report.parameter.ShowOnlyClientsIncludedInMarketing = [
        this.isIncludedInMarketing,
      ];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.COUNTRY_ID)) {
      this.report.parameter.Country_Q = [this.currentUserService.countryId];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.DATE_FILTER_ON_LOAN)) {
      this.report.parameter.Loan_Date_Type_To_Filter = [
        this.dateFilteredOnLoan,
      ];
    }
    if (
      this.reportType.filters.includes(
        JASPER_FILTERS.DATE_FILTER_ON_OPPORTUNITY
      )
    ) {
      this.report.parameter.Opportunity_Date_Type_To_Filter = [
        this.dateFilteredOnOpportunity,
      ];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.LOAN_STATUS)) {
      this.report.parameter.Loan_Status_ID = this.getJasperReportParameterArray(
        this.loanStatusSelected,
        'loanStatusId'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.LENDER_CATEGORY)) {
      this.report.parameter.LenderCategory = this.getJasperReportParameterArray(
        this.lenderCategorySelected,
        'value'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.LENDER_SPLIT_BY)) {
      this.report.parameter.LoansSplitBy = [this.loansSplitBySelected];
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.SHOW_LOANS_DATA)) {
      this.report.parameter.ShowLoansData = [this.showLoansData];
    }

    if (this.reportType.filters.includes(JASPER_FILTERS.REFERRAL_TYPE)) {
      this.report.parameter.ReferralTypeID = this.getJasperReportParameterArray(
        this.referralTypeSelected,
        'ReferralTypeID'
      );
    }
    if (this.reportType.filters.includes(JASPER_FILTERS.DATA_TO_SHOW)) {
      this.report.parameter.DataToShow = [this.dataToShow];
    }
  }
  getJasperReportUrl(reportType) {
    switch (this.currentUserService.accessType) {
      case ACCESS_TYPE.ADVISER:
        this.reportUrl = reportType.adviserUserUrl;
        break;
      case ACCESS_TYPE.PRINCIPAL_ADVISER:
        this.reportUrl = reportType.principalAdviserUrl;
        break;
      case ACCESS_TYPE.ADMIN_ASSISTANT:
      case ACCESS_TYPE.ASSISTANT:
        this.reportUrl = reportType.assistantAdviserUrl;
        break;
      case ACCESS_TYPE.CORPORATE:
        this.reportUrl = reportType.corpUserUrl;
        break;
      default:
        this.reportUrl = '';
        break;
    }
    return this.reportUrl;
  }
  visualizeAuth(report) {
    this.auth = {
      auth: {
        token: report.token,
        preAuth: true,
        tokenName: 'pp',
      },
    };
    return this.auth;
  }
  visualizeAuthError(err) {
    if (this.report.isCancelled) return;
    swal('Authentication Error', `Server response: ${err.message}`, 'error');
    this.report.isLoading = false;
  }
  visualizeGenerateReport(v) {
    if (this.report.isCancelled) return;
    this.visualizeReport = v.report({
      resource: this.report.jasperUrl,
      params: this.report.parameter,
      container: '#report',
      events: {
        reportCompleted: this.reportCompleted,
        changeTotalPages: this.changeTotalPages,
        error: this.error,
      },
    });
    this.report.cancel = () => {
      this.visualizeReport.cancel = () => {};
      this.report.isCancelled = true;
      this.report.isLoading = false;
    };

    this.report.pageChange = currentPage => {
      this.report.isLoading = true;
      this.visualizeReport
        .pages(currentPage)
        .run()
        .done(() => {
          this.report.isLoading = false;
        })
        .fail(err => {
          swal('Error Message', err);
          this.report.isLoading = false;
        });
    };
  }
  reportCompleted() {
    if (this.report.isCancelled) return;
    this.reportTimeout = this.$timeout(() => {
      this.report.exportToPdf = () => {
        this.visualizeReport
          .export({
            outputFormat: 'pdf',
          })
          .done(link => {
            this.$window.open(link.href);
          })
          .fail(err => {
            swal('Report Error', err.message, 'error');
          });
        return true;
      };
      this.report.exportToExcel = () => {
        this.visualizeReport
          .export({
            outputFormat: 'xlsx',
          })
          .done(link => {
            this.$window.open(link.href);
          })
          .fail(err => {
            swal('Report Error', err.message, 'error');
          });
      };
      this.report.isLoading = false;
    }, 100);
  }
  changeTotalPages(pages) {
    this.report.totalPages = pages || 1;
  }
  error() {
    if (this.report.isCancelled) return;
    swal(
      'Error Message',
      'Report failed to load. Please try to generate again.',
      'error'
    );
    this.report.isLoading = false;
  }
  loadJasperReport(report) {
    this.report = report;
    if (!this.$window.visualize) return;
    this.$window.visualize(
      this.visualizeAuth(report),
      this.visualizeGenerateReport,
      this.visualizeAuthError
    );
  }

  preselectTimePeriod(data) {
    const { isAU, isNZ } = this.currentUserService;
    const customStart = isAU
      ? financialRange.AU.start
      : financialRange.NZ.start;
    const fullDate = new Date();
    const year = fullDate.getFullYear();
    const month = fullDate.getMonth();

    const customEnd = isAU ? financialRange.AU.end : financialRange.NZ.end;
    const customLastDay = new Date(year, customStart + 1, 0).getDate();

    const parsedPeriod = { start: null, end: null };

    switch (parseInt(data.Widget_OptionValue, 10)) {
      case ENQUIRY_SOURCE_PERIOD.THIS_CY:
      case LOAN_SIZE_PERIOD.THIS_CY:
      case LENDER_SPLIT_PERIOD.THIS_CY:
        parsedPeriod.start = new Date(year, 0, 1);
        parsedPeriod.end = new Date(year, 11, 31);
        break;

      case ENQUIRY_SOURCE_PERIOD.LAST_CY:
      case LOAN_SIZE_PERIOD.LAST_CY:
      case LENDER_SPLIT_PERIOD.LAST_CY:
        parsedPeriod.start = new Date(year - 1, 0, 1);
        parsedPeriod.end = new Date(year - 1, 12, 0);
        break;

      case ENQUIRY_SOURCE_PERIOD.THIS_FY:
      case LOAN_SIZE_PERIOD.THIS_FY:
      case LENDER_SPLIT_PERIOD.THIS_FY:
        if (isAU || isNZ) {
          parsedPeriod.start = new Date(year - 1, customStart, 1);
          parsedPeriod.end = new Date(year, customEnd, customLastDay - 1);
        } else {
          parsedPeriod.start = new Date(year, 0, 1);
          parsedPeriod.end = new Date(year, 11, 31);
        }
        break;

      case ENQUIRY_SOURCE_PERIOD.LAST_FY:
      case LOAN_SIZE_PERIOD.LAST_FY:
      case LENDER_SPLIT_PERIOD.LAST_FY:
        if (isAU || isNZ) {
          parsedPeriod.start = new Date(year - 2, customStart, 1);
          parsedPeriod.end = new Date(year - 1, customEnd, customLastDay - 1);
        } else {
          parsedPeriod.start = new Date(year - 1, 0, 1);
          parsedPeriod.end = new Date(year - 1, 12, 0);
        }
        break;

      case ENQUIRY_SOURCE_PERIOD.THIS_MONTH:
      case LOAN_SIZE_PERIOD.THIS_MONTH:
      case LENDER_SPLIT_PERIOD.THIS_MONTH:
        parsedPeriod.start = new Date(year, month, 1);
        parsedPeriod.end = new Date(year, month + 1, 0);
        break;

      case ENQUIRY_SOURCE_PERIOD.LAST_MONTH:
      case LOAN_SIZE_PERIOD.LAST_MONTH:
      case LENDER_SPLIT_PERIOD.LAST_MONTH:
        parsedPeriod.start = new Date(year, month - 1, 1);
        parsedPeriod.end = new Date(year, month, 0);
        break;

      case SETTLEMENT_VOLUME_PERIOD.CY:
        parsedPeriod.start = new Date(year - 2, 0, 1);
        parsedPeriod.end = new Date(year, 11, 31);
        break;

      case SETTLEMENT_VOLUME_PERIOD.FY:
        if (isAU || isNZ) {
          parsedPeriod.start = new Date(year - 3, customStart, 1);
          parsedPeriod.end = new Date(year, customEnd, customLastDay - 1);
        } else {
          parsedPeriod.start = new Date(year - 2, 0, 1);
          parsedPeriod.end = new Date(year, 11, 31);
        }
        break;

      default:
    }

    return parsedPeriod;
  }

  $onDestroy() {
    this.$timeout.cancel(this.reportTimeout);
  }
}

export default JasperReportService;
