import { flatMap } from 'lodash';
import {
  showActionConfirmation,
  alertSucess,
  toastError,
} from 'Common/utilities/alert';
import {
  getBenefitTypeNameById,
  getBenefitCover,
} from 'Common/utilities/insurance';

const MODULE_PROFILER = 2;

export function formatProfilerBenefit(benefit) {
  return {
    BenefitId: benefit.BenefitId,
    Benefit: getBenefitCover(benefit),
    DisplayName: getBenefitTypeNameById(benefit.BenefitId),
  };
}

export function getProfilerBenefits(profiler) {
  if (!profiler.PeopleEntity || !profiler.PeopleEntity.length) return [];
  return flatMap(profiler.PeopleEntity, entity => entity.BenefitList).reduce(
    (accum, benefit) => {
      const isInList = accum.some(item => item.BenefitId === benefit.BenefitId);
      return isInList ? accum : [...accum, formatProfilerBenefit(benefit)];
    },
    []
  );
}

export default class SavedInsuranceProfilersCtrl {
  constructor($state, insuranceQuoteService) {
    'ngInject';

    this.$state = $state;
    this.insuranceQuoteService = insuranceQuoteService;
  }

  $onInit() {
    this.loadInsuranceProfilers();
  }

  loadInsuranceProfilers() {
    this.insuranceQuoteService
      .insuranceQuoteGet(MODULE_PROFILER, 0, this.familyId)
      .then(response => {
        this.savedProfilerList =
          response.data &&
          response.data.map(profiler => {
            return {
              ...profiler,
              BenefitList: getProfilerBenefits(profiler),
            };
          });
      });
  }

  addNewProfiler() {
    this.$state.go('app.contactProfiler', { familyId: this.familyId });
  }

  openProfilerDetail(profilerQuoteId) {
    this.$state.go('app.insuranceProfilerDetails', {
      insProfilerId: profilerQuoteId,
      familyId: this.familyId,
    });
  }

  removeSavedProfiler(profilerQuoteId) {
    showActionConfirmation(
      'Are you sure?',
      'Insurance Profiler will be removed.',
      () => {
        this.insuranceQuoteService
          .deleteInsuranceProfilerByProfilerID(profilerQuoteId)
          .then(response => {
            if (response.data === 1) {
              alertSucess(
                'Success',
                'Insurance Profiler successfully removed',
                true
              );
              this.loadInsuranceProfilers();
            } else {
              toastError('Insurance Profiler was not removed');
            }
          });
      }
    );
  }
}
