/**
 * Generate color name using http://chir.ag/projects/name-that-color
 */

export const COLOR = {
  SALMON: '#f98169',
  SEA_BUCKTHORN: '#fda829',
  TURBO: '#fde000',
  CANNON_PINK: '#9c4b85',
  MULLED_WINE: '#694573',
  LOCHMARA: '#017cc9',
  AFFAIR: '#6b499d',
  BRIGHT_TURQUOISE: '#05d0ee',
  APPLE: '#58cd40',
  WHITE: '#ffffff',
  BRIGHT_TURQUOISE_DARKER: '#3D91CC',
  DARKER_BLUE: '#579BCB',
  BLUE_VIOLET: '#A098D2',
  TERRACOTTA: '#DD6B55',
  CK_EDITOR_BUTTONS: '#DFD6E4',
  BLUE_VIKING: '#42B4B4',
  HAITI: '#1B1439',
  CERULEAN: '#00AAE5' /* Australia LM */,
  SUSHI: '#9BB53B' /* Insurance Market */,
  LUCKY_POINT: '#1E246E' /* Wealth Market */,
  APRICOT: '#EC7777',
  SCOOTER: '#30C1C4',
  GERALDINE: '#FA8E91',
  TRENDY_PINK: '#87739B',
  DARKER_BLUER: '#3f2e85',
  BITTER_SWEET: '#FD7575',
  ROSE_BUD: '#F9B7B7',
  PIGEON_POST: '#ABC5DE',
  DANUBE: '#6297CB',
  BLUE_BELL: '#909EC8',
  DOWNY: '#60CECE',
  VICTORIA: '#54498E',
  BUTTER_CUP: '#F1C40F',
  MARINER: '#2980B9',
  RUM: '#79618D',
  MISCHKA: '#D6CDDB',
  JUNGLE_GREEN: '#26B599',
  CURIOUS_BLUE: '#3498DB',
};

export const RGBA = {
  ALTO: 'rgba(220,220,220,0)',
  ROCK_BLUE: 'rgba(151,187,205,0)',
  GREY: 'rgba(0,0,0,.05)',
  GRAY_SUIT_OPAQUE: 'rgba(153,79,182, 0.3)',
  GRAY_SUIT: 'rgba(153,79,182, 1)',
};

export const PIPELINE_COLORS = {
  SALMON: '#f98169',
  SEA_BUCKTHORN: '#fda829',
  TURBO: '#fde000',
  CANNON_PINK: '#9c4b85',
  MULLED_WINE: '#694573',
  LOCHMARA: '#017cc9',
  AFFAIR: '#6b499d',
  BRIGHT_TURQUOISE: '#05d0ee',
  APPLE: '#58cd40',
};

export const GMAP_COLOR = {
  DARK_SLATE_GRAY: '#444444',
  LANDSCAPE: '#f2f2f2',
  WATER: '#b4d4e1',
};
