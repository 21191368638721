export function widgetOptionBuilderForMyCRM(params, widgetUserId) {
  const { widgetOptionId, value, selectedUser } = params;
  return {
    WidgetOptionId: widgetOptionId,
    Widget_UserID: widgetUserId,
    Widget_OptionValue: value || 0,
    SelectedUser: selectedUser || 0,
  };
}

export function monthlySettlementBuilderForUI(settlement) {
  return {
    monthInt: settlement.MonthInt,
    monthStr: settlement.MonthStr,
    value: settlement.Value,
  };
}

export function yearlySettlementBuilderForUI(settlement) {
  return {
    year: settlement.Year,
    data:
      (settlement.Data && settlement.Data.map(monthlySettlementBuilderForUI)) ||
      [],
  };
}

export function enquirySourceBuilderForUI(settlement) {
  return {
    id: settlement.ReferralCategoryID,
    category: settlement.ReferralCategory,
    count: settlement.TotalCount,
  };
}
