import { pdfBuilderForUI } from 'Common/mappers/document';

export default class GamePlanService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'game-plan';
  }

  generate(loanScenarioId) {
    return this.httpClient
      .get(`${this.baseUrl}/${loanScenarioId}`)
      .then(({ data }) => pdfBuilderForUI(data));
  }
}
