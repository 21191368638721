import template from './loanInformationImportantDates.html';
import LoanInformationImportantDatesCtrl from './loanInformationImportantDatesCtrl';

export default {
  template,
  bindings: {
    loanDetails: '<',
    onDateChange: '&',
  },
  controller: LoanInformationImportantDatesCtrl,
  controllerAs: 'vm',
};
