export const employmentInstance = propertyOf => {
  return {
    getEmploymentNature: () => {
      return propertyOf.optionsService.getEmploymentNature().then(response => {
        propertyOf.employmentNature = [];
        if (response && response.length) {
          propertyOf.employmentNature = response;
        }
      });
    },

    validateNZBNcharacter: data => {
      propertyOf.invalidCharacters = false;
      const NZBNChar = data.toString() || '';
      if (
        NZBNChar &&
        NZBNChar.length !== propertyOf.NZBN_PROPERTY.NUMERICAL_LENGTH
      ) {
        propertyOf.invalidCharacters = !propertyOf.invalidCharacters;
      }
    },

    employmentNatureType: () => {
      const isSelfEmployed =
        propertyOf.employment.EmploymentTypeId ===
        propertyOf.NZBN_PROPERTY.SELF_EMPLOYED;
      const isRegCompany =
        propertyOf.employment.employmentNature ===
        propertyOf.NZBN_PROPERTY.REGISTERED_COMPANY;
      const isSoleTrader =
        propertyOf.employment.employmentNature ===
        propertyOf.NZBN_PROPERTY.SOLE_TRADER;
      propertyOf.formReference.employmentNameAndAddress =
        (isSelfEmployed && isRegCompany) || (isSoleTrader && isSelfEmployed);
      return isSelfEmployed && isRegCompany;
    },

    employmentType: () => {
      propertyOf.employmentDefaultType();
    },
  };
};
