import { removeFromArray } from 'Common/utilities/array';
import { OPPORTUNITY } from 'Common/constants/paramTypes';

const mapOpportunityCalculationValue = obj => {
  return (
    (obj && {
      NoOfSpouse: obj.assessmentDetail && obj.assessmentDetail.noOfSpouse,
      NoOfDependents:
        obj.assessmentDetail && obj.assessmentDetail.noOfDependents,
      LoanAmount: obj.assessmentDetail && obj.assessmentDetail.loanAmount,
      SecurityAmount:
        obj.assessmentDetail && obj.assessmentDetail.securityAmount,
      LoanTerm: (obj.assessmentDetail && obj.assessmentDetail.loanTerm) || 30,
      LVR: obj.assessmentDetail && obj.assessmentDetail.lVR,
    }) ||
    {}
  );
};

const filterIDsFromOpportunityCalculationData = data => {
  return (
    (data && {
      ...data,
      incomeList:
        data.incomeList &&
        data.incomeList.map(obj => ({
          ...obj,
          serviceabilityIncomeId: 0,
        })),
      liabilityList:
        data.liabilityList &&
        data.liabilityList.map(obj => ({
          ...obj,
          serviceabilityLiabilityId: 0,
        })),
    }) ||
    {}
  );
};

export const setLoanAppOnOpportunity = ({ $scope, $state, configService }) => {
  $scope.isServiceabilityFromLoanAppOnOpportunity =
    $state.params.paramType === OPPORTUNITY &&
    configService.feature.loanAppOnOpportunities;
  return $scope.isServiceabilityFromLoanAppOnOpportunity;
};

export const mapDataFromOpportunity = ({ $scope, $state, configService }) => ({
  opportunityCalculationData,
  loanOpportunityService,
}) => {
  if (
    $state.params.paramType !== OPPORTUNITY ||
    !configService.feature.loanAppOnOpportunities
  )
    return false;
  const data = filterIDsFromOpportunityCalculationData(
    opportunityCalculationData
  );
  $scope.familyMembersData = {
    ...$scope.familyMembersData,
    ...mapOpportunityCalculationValue(opportunityCalculationData),
  };
  $scope.disableFormControl = true;
  const hasContentData = Object.keys(data).length;
  if (!hasContentData) return;
  return loanOpportunityService
    .saveServiceabilityFinancials(
      $state.params.paramId,
      $scope.brokerEventId,
      data
    )
    .then(() => {
      $scope.getIncomeList(true);
      $scope.getIncomeDetails();
    });
};

export function deleteIncome(income, newIncomeList, loanProfilerService) {
  return new Promise(resolve => {
    if (Math.floor(income.IncomeID) === 0) {
      removeFromArray(income, newIncomeList);
      resolve(true);
    } else {
      loanProfilerService
        .deleteServiceabilityIncome(income.IncomeId)
        .then(res => {
          resolve(res);
        });
    }
  });
}
