import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import {
  displayError,
  toastError,
  toastInfo,
  showActionConfirmation,
} from 'Common/utilities/alert';
import atob from 'atob';
import moment from 'moment';
import {
  DEFAULT_FUND_NAMES,
  FUNDING_PURPOSE_IDS,
} from 'Common/constants/fundsConstants';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { LMI_PREDOMINANT_PURPOSE } from 'Common/constants/lmi';
import { loanOpportunities } from './util/fundingCalculatorCtrl';

angular
  .module('app')
  .controller('FundingCalculatorCtrl', function FundingCalculatorCtrl(
    $scope,
    $uibModal,
    SweetAlert,
    contactService,
    $window,
    $location,
    $timeout,
    fundingCalculatorService,
    lendingScenarioService,
    loanScenarioService,
    toaster,
    $state,
    $stateParams,
    $filter,
    cfpLoadingBar,
    loanProfilerService,
    $localStorage,
    DEMO_VIDEO,
    generalService,
    optionsService,
    commonFnService,
    fundingCalculatorDetailService,
    loanCalculatorLinking,
    loanOpportunityService,
    contactSharedDataService,
    currentUserService,
    downloadDataService,
    corporateService,
    configService
  ) {
    const contactFamilyId = $window.sessionStorage.getItem(
      'contactFamilyIdForFundingCalculator'
    );
    let innerFundingRequiredInputChangedPromise;
    let fundingRequiredInputChangedPromise;
    let fundsAvailableInputChangedPromise;
    let proposedLendingSettingsInputChangedPromise;
    let proposedLendingInputChangedPromise;
    let loamAmount = 0;
    const FUNDS_LMI_ID = 0;
    const LVR = 80;
    $scope.isUserAU = currentUserService.isAU;
    $scope.isUserNZ = currentUserService.isNZ;
    $scope.isFundingCalcFeatureOn =
      configService.feature.fundingCalculatorEnhancement;

    $scope.currentSourceLinker = loanCalculatorLinking.currentSourceLinker;
    $scope.isFromOpportunity =
      $scope.currentSourceLinker.linkerId ===
      loanCalculatorLinking.LINKERS.OPPORTUNITY;

    $scope.opportunityIdForFundingCalculator =
      $scope.currentSourceLinker.linkerId ===
      loanCalculatorLinking.LINKERS.OPPORTUNITY
        ? parseInt($scope.currentSourceLinker.id, 10)
        : 0;

    // For Feeling Stuck Popup
    $scope.fundingClcMainObj = {};
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId() {
            return DEMO_VIDEO.FUNDING_CALCULATOR.Url;
          },
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    $scope.brokerEventId = $stateParams.brokerEventId;
    $scope.isSaveContactEnable = true;
    $scope.isFromContact = false;

    $scope.contactFamilyId = 0;
    if (typeof contactFamilyId !== 'undefined' && contactFamilyId !== null) {
      $scope.contactFamilyId = contactFamilyId;
      $scope.isFromContact = true;
      $window.sessionStorage.removeItem('contactFamilyIdForFundingCalculator');
      loanCalculatorLinking.resetLinker();
    }

    $scope.checkLinkerIsFromContact = () => {
      loanProfilerService
        .getAssessmentDetailCalculations($scope.brokerEventId)
        .then(response => {
          const linkedData =
            response &&
            response.length &&
            _.find(
              response,
              item =>
                item.typeOfCalculation.toLowerCase() === 'fundingcalculator'
            );
          $scope.isSavedCalcIsEdit = !!linkedData;
          $scope.contactFamilyId = linkedData
            ? linkedData.familyId
            : $scope.contactFamilyId;
          if ($scope.contactFamilyId) {
            loanCalculatorLinking
              .getcontactFullName($scope.contactFamilyId)
              .then(contactResponse => {
                $scope.contactFullName = contactResponse.data || '';
              });
          }
        });
    };
    // Init Functions
    $scope.init = function() {
      $scope.propertyNameLabel = $scope.isFundingCalcFeatureOn
        ? 'Property Address'
        : 'Property Name';

      // Get Funding Calculator Funds
      //----------------------------------------------------------------------------
      $scope.fundingCalculatorFunds = [];
      $scope.getFundingCalculatorFunds = (isProposedLendingUpdated = false) => {
        fundingCalculatorService
          .getFundingCalculatorFunds($scope.brokerEventId)
          .then(response => {
            $scope.fundingCalculatorFunds = response;
            _.map($scope.fundingCalculatorFunds, item => {
              if (item.fundingValue === 0) item.fundingValue = '';
              return item;
            });
            if (currentUserService.countryId === COUNTRY_TYPE.NEW_ZEALAND) {
              $scope.fundingCalculatorFunds = $scope.fundingCalculatorFunds.filter(
                object => object.fundingParticulars !== 'Proposed Lending'
              );
            }
            $scope.getFundingCalculatorFundsSummary(
              true,
              isProposedLendingUpdated
            );
            $scope.getProposedLendingSummary();
          });
      };

      $scope.resetProsedLendingDetail = proposedLending => {
        proposedLending.rateTerm = '';
        proposedLending.ongoingRate = '';
      };
      if (
        $location.path() === `/app/fundingCalculator/${$scope.brokerEventId}`
      ) {
        // Get Funding Calculator Parameters
        //----------------------------------------------------------------------------
        $scope.fundingCalculatorParametersList = {};
        $scope.getFundingCalculatorParameters = function() {
          fundingCalculatorService
            .getFundingCalculatorParameters($scope.brokerEventId)
            .then(response => {
              $scope.fundingCalculatorParametersList = response;
              if (response.data === 0) {
                $scope.setBlankObject();
              }
              angular.forEach(
                $scope.fundingCalculatorParametersList,
                fundingCalculatorParametersList => {
                  angular.forEach(
                    $scope.propertyParametersList.purposeParamsStatus,
                    item => {
                      if (
                        item.value ===
                        fundingCalculatorParametersList.purchaseInfo
                          .propertyStatus
                      )
                        fundingCalculatorParametersList.purchaseInfo.propertyStatusName =
                          item.name;
                    }
                  );
                  angular.forEach(
                    $scope.propertyParametersList.purposeParams,
                    item => {
                      if (
                        item.value ===
                        fundingCalculatorParametersList.purchaseInfo.purpose
                      )
                        fundingCalculatorParametersList.purchaseInfo.purposeName =
                          item.name;
                    }
                  );
                  angular.forEach(
                    $scope.fundingCalculatorParametersList,
                    itemGet => {
                      if (itemGet.purchaseInfo.propertyValue) {
                        itemGet.purchaseInfo.propertyValue = Number(
                          itemGet.purchaseInfo.propertyValue
                        );
                      }
                      if (itemGet.purchaseInfo.propertyValue === 0)
                        itemGet.purchaseInfo.propertyValue = '';
                      itemGet.purchaseInfo.refinanceAmount = Number(
                        itemGet.purchaseInfo.refinanceAmount
                      );
                      if (itemGet.purchaseInfo.refinanceAmount === 0)
                        itemGet.purchaseInfo.refinanceAmount = '';
                      angular.forEach(itemGet.innerFundingRequired, item => {
                        if (item.value === 0) item.value = '';
                      });
                      itemGet.newInnerFundingRequiredList = [];
                    }
                  );
                }
              );
              if ($scope.fundingCalculatorParametersList.length !== 0) {
                $scope.getFundingCalculatorFunds();
              }
            });
        };

        // Get Property Parameters
        //----------------------------------------------------------------------------
        $scope.propertyParametersList = {};
        fundingCalculatorService
          .getPropertyParameters()
          .then(propertyParameters => {
            $scope.propertyParametersList = propertyParameters;
            $scope.getFundingCalculatorParameters(true);
          });
      }

      // Get Funding Calculator Funds Repayments Frequency
      $scope.fundingCalculatorRepaymentsFrequency = [];
      $scope.getFrequency = function() {
        fundingCalculatorService.getFrequency().then(frequencyList => {
          $scope.fundingCalculatorRepaymentsFrequency = frequencyList;
          fundingCalculatorService
            .getRepaymentFrequency($scope.brokerEventId)
            .then(frequencyResponse => {
              $scope.repaymentFrequency = frequencyResponse.data.toString();
            });
        });
      };
      $scope.getFrequency();

      // Get Funding Calculator LMI Details
      //----------------------------------------------------------------------------
      $scope.fundingCalculatorLMIDeatils = [];
      $scope.getFundingCalculatorLMIDeatils = function() {
        fundingCalculatorService
          .getFundingCalculatorFundsLMI($scope.brokerEventId)
          .then(response => {
            $scope.fundingCalculatorLMIDeatils = response;
            $scope.fundingCalculatorLMIDeatils.newLoanTerm = $scope.fundingCalculatorLMIDeatils.newLoanTerm.toString();
            if ($scope.fundingCalculatorLMIDeatils.newLoanAmount === 0)
              $scope.fundingCalculatorLMIDeatils.newLoanAmount = '';
            $scope.securityList = $scope.fundingCalculatorLMIDeatils.security;
            if (
              typeof $scope.fundingCalculatorLMIDeatils.security === 'undefined'
            )
              $scope.securityList = [];
            $scope.getLoanOption();
          });
      };
      $scope.getFundingCalculatorLMIDeatils();

      $scope.getFundingCalculatorFundsQuickLMIFavorite = function() {
        fundingCalculatorService
          .getFundingCalculatorFundsQuickLMIFavorite($scope.brokerEventId)
          .then(({ data }) => {
            $scope.quickLMIFavoriteLender = data && data.length && data[0];
          });
      };
      $scope.getFundingCalculatorFundsQuickLMIFavorite();

      const openLMICalculatorModal = (isEdit, quickLmiData) => {
        $scope.isLoadingQuickLMI = false;
        const fundingCalcData = {
          quickLmiData,
          funds: $scope.fundingCalculatorFunds,
          proposedLending: $scope.fundingCalculatorProposedLending,
        };
        const props = {
          brokerEventId: $scope.brokerEventId,
          isEdit,
          isModal: true,
          fundingCalcData,
        };

        $uibModal
          .open({
            template: `<lmi-calculator 
            modal-instance="vm.modalInstance"
            broker-event-id="vm.props.brokerEventId"
            is-edit="vm.props.isEdit"
            is-modal="vm.props.isModal"
            funding-calc-data="vm.props.fundingCalcData">
            </lmi-calculator>`,
            controller: 'CommonModalPlaceholderCtrl',
            controllerAs: 'vm',
            resolve: {
              props: () => props,
            },
            size: 'xl',
            windowClass: 'quick-lmi-page',
          })
          .result.then(() => {
            $scope.init();
          });
      };

      const goToQuickLMIPage = postData => {
        fundingCalculatorService
          .setFundingCalculatorFundsQuickLMI(postData)
          .then(() => {
            return fundingCalculatorService.setFundingCalculatorFundsIsQuickLMI(
              $scope.brokerEventId,
              false
            );
          })
          .then(({ data }) => {
            if (!data) return;
            $scope.isLoadingQuickLMI = false;

            const fundingRequiredData = $scope.fundingCalculatorFunds.find(
              item => {
                return (
                  item.fundingParticulars === 'Lenders Mortgage Insurance' &&
                  item.fundingType === 'FUNDING_REQUIRED'
                );
              }
            );

            fundingCalculatorDetailService.quickLmiFundingCalculatorFunds =
              fundingRequiredData || {};
            const validProposedLending =
              $scope.fundingCalculatorProposedLending &&
              $scope.fundingCalculatorProposedLending.length;
            fundingCalculatorDetailService.quickLmiProposedLending = validProposedLending
              ? $scope.fundingCalculatorProposedLending[0]
              : {};

            $state.go('app.quickLMI', { brokerEventId: $scope.brokerEventId });
          })
          .catch(() => {
            $scope.isLoadingQuickLMI = false;
          });
      };

      const getPredominantLoanPurpose = purposeId => {
        const isPurchaseOrConstruction = () => {
          const purchaseConstructionIds = [
            FUNDING_PURPOSE_IDS.PURCHASE_OWNER_OCCUPIED,
            FUNDING_PURPOSE_IDS.PURCHASE_INVESTMENT,
            FUNDING_PURPOSE_IDS.CONSTRUCTION_INVESTMENT,
            FUNDING_PURPOSE_IDS.CONSTRUCTION_OWNER_OCCUPIED,
          ];
          return purchaseConstructionIds.includes(parseInt(purposeId, 10));
        };

        return isPurchaseOrConstruction()
          ? LMI_PREDOMINANT_PURPOSE.PURCHASE_OR_CONSTRUCTION
          : LMI_PREDOMINANT_PURPOSE.INTERNAL_REFINANCE;
      };

      $scope.openQuickLMICalc = function(isEdit) {
        $scope.isLoadingQuickLMI = true;
        const { isFirstHomeBuyer, isSelfEmployed } = $scope.firstHomeBuyerData;
        const {
          tempTotalProposedLendingUserDefined,
        } = $scope.fundingClcMainObj;
        const firstFundingCalc =
          $scope.fundingCalculatorParametersList &&
          $scope.fundingCalculatorParametersList.length &&
          $scope.fundingCalculatorParametersList[0];
        const purposeId =
          firstFundingCalc &&
          firstFundingCalc.purchaseInfo &&
          firstFundingCalc.purchaseInfo.purpose;

        const postData = {
          BrokerEventID: $scope.brokerEventId,
          Lenders: ['ALL'],
          LVRValue: '0',
          NewLoanAmount: tempTotalProposedLendingUserDefined || 0,
          PredominantLoanPurpose: getPredominantLoanPurpose(purposeId),
          IsFirstHomeBuyer: isFirstHomeBuyer,
          IsSelfEmployed: isSelfEmployed,
          CapitalizeLMI: true,
          TargetLVR: 0,
          GoalType: 0,
          IsQuickCalc: false,
        };

        if (
          $scope.quickLMIFavoriteLender &&
          $scope.quickLMIFavoriteLender.LenderId
        ) {
          postData.Lenders = [`${$scope.quickLMIFavoriteLender.LenderId}`];
          postData.PredominantLoanPurpose =
            $scope.quickLMIFavoriteLender.PredominantLoanPurpose;
        }

        if ($scope.isFundingCalcFeatureOn && !$scope.isUserNZ) {
          openLMICalculatorModal(isEdit, postData);
        } else {
          goToQuickLMIPage(postData);
        }
      };

      const deleteLMICalculation = () => {
        fundingCalculatorService
          .deleteFundsLMI($scope.brokerEventId)
          .then(({ data }) => {
            if (!data) return;

            $scope.getFundingCalculatorFundsQuickLMIFavorite();
            $scope.getFundingCalculatorLMIDeatils();

            const fundsData = $scope.fundingCalculatorFunds.find(item => {
              return (
                item.fundingParticulars === 'Lenders Mortgage Insurance' &&
                item.fundingType === 'FUNDING_REQUIRED'
              );
            });
            if (fundsData) {
              fundsData.fundingValue = 0;
              fundsData.fundingCalParametersId = 0;
              $scope.saveFundingRequierd(fundsData, false);
            }
          });
      };

      $scope.removeLMICalculation = () => {
        showActionConfirmation(
          'This will remove the LMI Calculation',
          'Are you sure you want to proceed?',
          confirm => {
            if (confirm) deleteLMICalculation();
          }
        );
      };

      // Get Funding Calculator Proposed Lending
      //----------------------------------------------------------------------------
      $scope.fundingCalculatorProposedLending = [];
      $scope.getFundingCalculatorProposedLending = function() {
        fundingCalculatorService
          .getProposedLending($scope.brokerEventId)
          .then(response => {
            $scope.fundingCalculatorProposedLending = response;
            _.map($scope.fundingCalculatorProposedLending, item => {
              item.loanTermInYears = item.loanTermInYears.toString();
              item.rateTerm = item.rateTerm.toString();
              if (item.loanAmount === 0) item.loanAmount = '';
              return item;
            });
          });
      };
      $scope.getFundingCalculatorProposedLending();
      // Get Funding Calculator Proposed Lending Interest Type List
      //----------------------------------------------------------------------------
      $scope.proposedLendingInterestTypeList = [];
      $scope.getProposedLendingInterestType = function() {
        fundingCalculatorService
          .getProposedLendingInterestType()
          .then(response => {
            $scope.proposedLendingInterestTypeList = response;
          });
      };
      $scope.getProposedLendingInterestType();

      // Get Funding Calculator Proposed Lending Term List
      //----------------------------------------------------------------------------
      $scope.plTermList = [];
      $scope.getProposedLendingTerm = function() {
        optionsService.getLoanTerms().then(proposedLendingTerm => {
          $scope.proposedLendingTermList = proposedLendingTerm;
        });
      };
      $scope.getProposedLendingTerm();

      // Add New Proposed Lending Div
      //----------------------------------------------------------------------------
      $scope.newProposedLendingList = [];
      $scope.addProposedLending = function() {
        $scope.isProposedLendingVisible = true;
        const $newProposedLending = {
          proposedLendingId: Math.random(),
          loanAmount: '',
          rate: '',
          rateType: '',
          loanTermInYears: '30',
          isInterestOnly: false,
          rateTerm: '',
        };
        $scope.newProposedLendingList.push($newProposedLending);
      };

      // Save New Proposed Lending
      //----------------------------------------------------------------------------
      $scope.isProposedLendingLoader = false;
      $scope.saveProposedLending = function(proposedLending, isMsgDisplay) {
        const postData = {
          ...proposedLending,
          brokerEventId: $scope.brokerEventId,
        };
        if (isMsgDisplay) $scope.isProposedLendingLoader = true;
        if (!proposedLending.isEditMode) {
          if (Math.floor(postData.proposedLendingId) === 0)
            postData.proposedLendingId = 0;
        }

        fundingCalculatorService
          .setProposedLending(postData)
          .then(response => {
            if (response.data === 1) {
              const isSetLmi =
                proposedLending.isEditLoanAmount &&
                parseInt($scope.fundingCalculatorLMIDeatils.fundsLmiId, 10) !==
                  0;
              if (isSetLmi) {
                const isProposedLending =
                  $scope.fundingCalculatorFundsSummary
                    .isCalculatedProposedLending &&
                  $scope.fundingCalculatorProposedLending.length;
                if (isProposedLending) {
                  $scope.fundingCalculatorLMIDeatils.newLoanTerm =
                    $scope.fundingCalculatorProposedLending[0].loanTermInYears;
                  loamAmount = 0;
                  angular.forEach(
                    $scope.fundingCalculatorProposedLending,
                    item => {
                      loamAmount += parseInt(item.loanAmount, 10);
                    }
                  );
                }
                $scope.fundingCalculatorLMIDeatils.newLoanAmount = loamAmount;
                $scope.setLMI();
              }
              proposedLending.isEditLoanAmount = false;
              $scope.getFundingCalculatorProposedLending();
              if (postData.proposedLendingId === 0) {
                const $index = $scope.newProposedLendingList.indexOf(
                  proposedLending
                );
                if ($index > -1)
                  $scope.newProposedLendingList.splice($index, 1);
              } else {
                const $index1 = $scope.fundingCalculatorProposedLending.indexOf(
                  proposedLending
                );
                if ($index1 === 0) {
                  const $data = _.find($scope.fundingCalculatorFunds, item => {
                    return (
                      item.fundingParticulars ===
                        'Lenders Mortgage Insurance' &&
                      item.fundingType === 'FUNDING_REQUIRED'
                    );
                  });
                  if (typeof $data !== 'undefined') {
                    $data.fundingCalParametersId = 0;
                    $data.fundingValue = proposedLending.lmiAmount;
                    $scope.saveFundingRequierd($data, false);
                  }
                }
              }
              if (postData.proposedLendingId === 0) {
                if (isMsgDisplay)
                  toaster.pop(
                    'success',
                    'Added',
                    'Proposed Lending has been Added Successfully'
                  );
              } else if (isMsgDisplay)
                toaster.pop(
                  'success',
                  'Updated',
                  'Proposed Lending has been updated Successfully'
                );

              proposedLending.isEditMode = false;
              $scope.isProposedLendingLoader = false;
              if (isMsgDisplay) $scope.getFundingCalculatorProposedLending();
              $scope.getFundingCalculatorFunds();
              $scope.getFundingCalculatorLMIDeatils();
            } else {
              toastError();
            }
          })
          .catch(e => {
            toastError(e);
            $scope.isProposedLendingLoader = false;
          });
      };

      // Edit Loan Amount
      $scope.editLoanAmount = proposedLending => {
        proposedLending.isEditLoanAmount = true;
      };

      // Delete Proposed Lending
      $scope.deleteProposedLending = function(proposedLending) {
        if (!proposedLending.isEditMode) {
          if (Math.floor(proposedLending.proposedLendingId) === 0)
            proposedLending.proposedLendingId = 0;
        }

        if (proposedLending.proposedLendingId === 0) {
          const $index = $scope.newProposedLendingList.indexOf(proposedLending);
          if ($index > -1) $scope.newProposedLendingList.splice($index, 1);
        } else {
          SweetAlert.swal(
            {
              title: 'Are you sure?',
              text:
                'This record will be removed from your proposed lending list',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: false,
            },
            confirm => {
              if (confirm) {
                fundingCalculatorService
                  .deleteProposedLending(proposedLending.proposedLendingId)
                  .then(response => {
                    if (response.data === 1) {
                      $scope.getFundingCalculatorProposedLending();
                      $scope.getFundingCalculatorFundsSummary(false);
                      $scope.getProposedLendingSummary();
                      swal(
                        'Deleted',
                        'Proposed lending has been deleted.',
                        'success'
                      );
                    }
                  })
                  .catch(displayError);
              }
            }
          );
        }
      };

      $scope.changeStateOfSwitch = function(proposedLending) {
        proposedLending.isEditMode = true;
      };

      const parseValue = value => parseFloat(value).toFixed(3);

      // Get Funding Calculator Funds Summary
      //----------------------------------------------------------------------------
      $scope.fundingCalculatorFundsSummary = [];
      $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined = '';
      $scope.getFundingCalculatorFundsSummary = (
        isBindTotal,
        isProposedLendingUpdated
      ) => {
        fundingCalculatorService
          .getFundingCalculatorFundsSummary($scope.brokerEventId)
          .then(response => {
            if (!response) return;
            $scope.fundingCalculatorFundsSummary = {
              ...$scope.fundingCalculatorFundsSummary,
              ...response,
            };
            if (
              $scope.fundingCalculatorFundsSummary
                .totalProposedLendingUserDefined === 0
            ) {
              $scope.fundingCalculatorFundsSummary.totalProposedLendingUserDefined =
                '';
            }
            if (
              $scope.fundingCalculatorFundsSummary
                .totalProposedLendingUserDefined &&
              isBindTotal
            ) {
              $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined =
                $scope.fundingCalculatorFundsSummary.totalProposedLendingUserDefined;
            }
            $scope.fundingCalculatorFundsSummary.isFundingAvailable = true;
            $scope.fundingCalculatorFundsSummary.isProposedLending = true;
            $scope.fundingCalculatorFundsSummary.isFundingRequired = true;
            $scope.deleteLMI();

            if (!$scope.isFundingCalcFeatureOn) return;
            const proposedLendingValue = parseValue(
              $scope.getProposedLendingValue()
            );
            const totalProposedLending = parseValue(
              response.totalProposedLending
            );
            const totalProposedLendingUserDefined = parseValue(
              response.totalProposedLendingUserDefined
            );
            const isEscProposedLending =
              isProposedLendingUpdated ||
              proposedLendingValue === totalProposedLending ||
              proposedLendingValue === totalProposedLendingUserDefined;
            if (isEscProposedLending) return;
            $scope.setProposedLendingSettings();
          });
      };

      $scope.deleteLMI = () => {
        const isLmiCalculated =
          $scope.fundingCalculatorFundsSummary.lvr >= LVR ||
          !$scope.fundingCalculatorLMIDeatils.fundsLmiId;
        if (isLmiCalculated) return;

        fundingCalculatorService
          .deleteFundsLMI($scope.brokerEventId)
          .then(data => {
            if (data) {
              $scope.getFundingCalculatorFundsQuickLMIFavorite();
              $scope.getFundingCalculatorLMIDeatils();

              const fundsData = $scope.fundingCalculatorFunds.find(item => {
                return (
                  item.fundingParticulars === 'Lenders Mortgage Insurance' &&
                  item.fundingType === 'FUNDING_REQUIRED'
                );
              });
              if (fundsData) {
                fundsData.fundingValue = 0;
                fundsData.fundingCalParametersId = 0;
                $scope.saveFundingRequierd(fundsData, false);
              }
            }
          });
      };

      // Set ProposeLending Value
      //----------------------------------------------------------------------------
      $scope.setProposedLendingSettings = () => {
        $scope.getProposedLendingValue();
        if (
          typeof $scope.fundingClcMainObj
            .tempTotalProposedLendingUserDefined === 'undefined' ||
          $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined === ''
        ) {
          $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined = 0;
        }
        const totalProposedLending =
          parseFloat(
            $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined
          ) || 0;
        const $postData = {
          brokerEventId: $scope.brokerEventId,
          isCalculatedProposedLending:
            $scope.fundingCalculatorFundsSummary.isCalculatedProposedLending,
          totalProposedLending,
        };

        fundingCalculatorService
          .setProposedLendingSettings($postData)
          .then(() => {
            $scope.getFundingCalculatorFunds(true);
            if (
              parseInt($scope.fundingCalculatorLMIDeatils.fundsLmiId, 10) !== 0
            ) {
              $scope.fundingCalculatorLMIDeatils.newLoanAmount = totalProposedLending;
              $scope.setLMI();
            } else {
              $scope.getFundingCalculatorLMIDeatils();
            }
          });
      };

      // Get Proposed Lending Summary
      //----------------------------------------------------------------------------
      $scope.proposedLendingSummary = [];
      $scope.getProposedLendingSummary = function() {
        fundingCalculatorService
          .getProposedLendingSummary($scope.brokerEventId)
          .then(response => {
            $scope.proposedLendingSummary = response;
            $scope.totalRepayment = 0;
            if (response) {
              angular.forEach(response, item => {
                $scope.totalRepayment += Number(item.repaymentAmount);
              });
            }
          });
      };

      // Set Funding Calculator LMI
      $scope.setLMI = function() {
        let fundingData;
        const postData = $scope.fundingCalculatorLMIDeatils;
        fundingCalculatorService
          .setFundingCalculatorFundsLMI(postData)
          .then(() => {
            fundingCalculatorService
              .getLMICalculatorReport($scope.brokerEventId)
              .then(response => {
                if (parseInt(response.data, 10) === 0) {
                  toaster.pop(
                    'error',
                    'Error',
                    'No Results found, please refine your LMI search criteria.'
                  );
                } else if (response.data.Error) {
                  if (
                    response.data.Error ===
                    'No Results found, please refine your LMI search criteria.'
                  ) {
                    const setLmiErrorTimeout = $timeout(() => {
                      $scope.getFundingCalculatorFunds();
                      $scope.getFundingCalculatorProposedLending();
                      toaster.pop('warning', 'Try Again', response.data.Error);
                    }, 500);
                    $scope.$on('$destroy', () => {
                      $timeout.cancel(setLmiErrorTimeout);
                    });
                  } else toaster.pop('error', 'Error', response.data.Error);
                } else {
                  if (response.data.SymmetryLMISummaryReport) {
                    fundingData = $scope.fundingCalculatorFunds.find(item => {
                      return (
                        item.fundingParticulars ===
                          'Lenders Mortgage Insurance' &&
                        item.fundingType === 'FUNDING_REQUIRED'
                      );
                    });
                    if (fundingData) {
                      fundingData.fundingCalParametersId = 0;
                      fundingData.fundingValue =
                        response.data.SymmetryLMISummaryReport.TotalIncStampDuty;
                      $scope.saveFundingRequierd(fundingData, false);
                    }
                    const validProposedLending =
                      $scope.fundingCalculatorProposedLending &&
                      $scope.fundingCalculatorProposedLending[0];
                    if (validProposedLending) {
                      $scope.fundingCalculatorProposedLending[0].lmiAmount =
                        response.data.SymmetryLMISummaryReport.TotalIncStampDuty;
                      $scope.saveProposedLending(
                        $scope.fundingCalculatorProposedLending[0],
                        false
                      );
                    }
                  }
                  const setLmiTimeout = $timeout(() => {
                    $scope.getFundingCalculatorFunds();
                    $scope.getFundingCalculatorProposedLending();
                    toaster.pop(
                      'success',
                      'Updated',
                      'LMI has been successfully Updated'
                    );
                  }, 500);
                  $scope.$on('$destroy', () => {
                    $timeout.cancel(setLmiTimeout);
                  });
                }
              });
            $scope.getFundingCalculatorLMIDeatils();
          });
      };

      // Get Funding Calculator Funds Repayments
      //----------------------------------------------------------------------------
      $scope.fundingCalculatorFundsRepayments = [];
      $scope.getFundingCalculatorFundsRepayments = function() {
        fundingCalculatorService
          .getProposedLendingPropertyWise($scope.brokerEventId)
          .then(response => {
            $scope.fundingCalculatorFundsRepayments = response.data;
            $scope.totalRepayment = 0;
            angular.forEach(response.data, item => {
              let $subtotal = 0;
              angular.forEach(item.PropertyData, itemData => {
                $subtotal += Number(itemData.RepaymentAmount);
              });
              $scope.totalRepayment += $subtotal;
            });
          });
      };

      // Get Funding Calculator Funds Repayments Frequency
      $scope.setRepaymentFrequency = function(repaymentFrequency) {
        const $postdata = {
          brokerEventId: $scope.brokerEventId,
          frequencyId: repaymentFrequency,
        };
        fundingCalculatorService
          .setRepaymentFrequency($postdata)
          .then(response => {
            if (response.data === 1) $scope.getProposedLendingSummary();
          });
      };

      // Get Funding Calculator LMI Loan Option
      //----------------------------------------------------------------------------
      $scope.loanOptions = [
        {
          Name: 'Top-Up',
          Value: 'TopUp',
          IsCheked: false,
        },
        {
          Name: 'First Home Owner',
          Value: 'FirstHomeOwner',
          IsCheked: false,
        },
        {
          Name: 'Unregulated',
          Value: 'Unregulated',
          IsCheked: false,
        },
        {
          Name: 'Low doc',
          Value: 'Lowdoc',
          IsCheked: false,
        },
        {
          Name: 'Non-genuine Savings',
          Value: 'NongenuineSavings',
          IsCheked: false,
        },
        {
          Name: 'Limited Genuine Savings',
          Value: 'LimitedGenuineSavings',
          IsCheked: false,
        },
        {
          Name: 'Interest Only',
          Value: 'InterestOnly',
          IsCheked: false,
        },
      ];

      $scope.getLoanOption = function() {
        $scope.loanOptions = fundingCalculatorDetailService.getLoanOption(
          $scope.loanOptions,
          $scope.fundingCalculatorLMIDeatils.loanOption
        );
      };

      // Funding Calculator Coming from Opportunity
      //----------------------------------------------------------------------------
      loanOpportunities({
        $scope,
        $state,
        configService,
      })(loanOpportunityService);
    };

    // Init Function
    //----------------------------------------------------------------------------
    $scope.init();

    $scope.goBackToClientContact = familyId => {
      const activeTab = $scope.currentSourceLinker.isBusiness
        ? 'loans'
        : 'lending';
      contactSharedDataService.redirectToContactDetails(
        familyId || $scope.contactFamilyId,
        activeTab
      );
      loanCalculatorLinking.resetLinker();
    };

    $scope.isFundingRequireHide = (fundingParticulars, crmCountry) => {
      return (
        fundingParticulars === 'Lenders Mortgage Insurance' &&
        crmCountry === 'Australia' &&
        (($scope.fundingCalculatorFundsSummary.lvr > LVR &&
          $scope.fundingCalculatorLMIDeatils.fundsLmiId === FUNDS_LMI_ID) ||
          ($scope.fundingCalculatorFundsSummary.lvr < LVR &&
            $scope.fundingCalculatorLMIDeatils.fundsLmiId !== FUNDS_LMI_ID) ||
          ($scope.fundingCalculatorFundsSummary.lvr <= LVR &&
            $scope.fundingCalculatorLMIDeatils.fundsLmiId === FUNDS_LMI_ID))
      );
    };

    $scope.isLMICalculated = quickLMIFavoriteLender => {
      return (
        $scope.isUserAU &&
        $scope.fundingCalculatorFundsSummary &&
        $scope.fundingCalculatorFundsSummary.lvr > LVR &&
        quickLMIFavoriteLender
      );
    };
    // Search Address
    $scope.getSearchAddressSuggestion = function(fundingCalculatorParameters) {
      const text = fundingCalculatorParameters.address.formattedAddress;
      generalService.placeSearch(text).then(response => {
        fundingCalculatorParameters.searchAddressSuggestions = response.data;
      });
    };

    $scope.closeAutoHomeAddress = function(fundingCalculatorParameters) {
      fundingCalculatorParameters.searchAddressSuggestions = [];
    };

    const getGeoCodeState = (fundingCalculatorParameters, address) => {
      generalService.geocodeSearch(address).then(({ data }) => {
        fundingCalculatorParameters.address.state = data.stateShort_Name;
      });
    };

    $scope.selectAddress = (fundingCalcParams, property) => {
      fundingCalcParams.address.formattedAddress = property.formatted_address;
      if ($scope.isFundingCalcFeatureOn) {
        getGeoCodeState(fundingCalcParams, property.formatted_address);
      }
      $scope.closeAutoHomeAddress(fundingCalcParams);
    };

    // Save To Contact
    //----------------------------------------------------------------------------------
    $scope.showCreateNewContact = true;
    $scope.SearchClientList = [];
    $scope.mainSearchObject = {};

    $scope.resetSaveToContact = function() {
      $scope.selectedClientName = '';
      $scope.innerResetObject();
    };
    $scope.innerResetObject = function() {
      $scope.showCreateNewContact = true;
      $scope.SearchClientList = [];
      $scope.mainSearchObject = {};
      $scope.selectedClient = {};
    };

    // select client
    $scope.selectClient = function(client) {
      $scope.selectedClient = client;
      $scope.selectedClientName = `${client.FirstName} ${client.LastName}`;
      $scope.showCreateNewContact = false;
    };

    $scope.hideShowSearchDropDown = () => {
      $scope.showSelectClientDropown = !$scope.showSelectClientDropown;
      $scope.resetSaveToContact();
    };

    // Get Product Search
    $scope.getSearchClient = function(searchString) {
      if (searchString.length >= 3) {
        return loanProfilerService
          .getSearchClient(searchString, 2)
          .then(response => {
            $scope.SearchClientList = response.data;
            $scope.loanProfilerClientSearchList = [];
            angular.forEach(response.data, item => {
              item.FullName = `${item.FirstName} ${item.LastName}`;
              $scope.loanProfilerClientSearchList.push(item);
            });
            return $scope.loanProfilerClientSearchList;
          });
      }

      $scope.innerResetObject();
      $scope.loanProfilerClientSearchList = [];
      return $scope.loanProfilerClientSearchList;
    };

    // Create new contact
    $scope.createNewContact = function() {
      $localStorage.fundingCalculatorCreateNewContact = true;
      $localStorage.previousState = 'app.fundingCalculator';
      $localStorage.previousStateParams = {
        brokerEventId: $scope.brokerEventId,
      };
      $state.go('app.createContact');
    };

    // Save to contact
    $scope.saveToContact = function() {
      const postData = {
        BrokerEventID: $scope.brokerEventId,
        DateOfCalculation: moment().format(),
        TypeOfCalculation: 'FundingCalculator',
      };
      contactService
        .calculationsSet($scope.selectedClient.FamilyID, postData)
        .then(response => {
          if (response.data) {
            toastError();
            return;
          }
          toaster.pop(
            'success',
            'Saved',
            'Funding calculator successfully saved to contact'
          );
          $scope.showSelectClientDropown = false;
          $scope.resetSaveToContact();
          $scope.checkLinkerIsFromContact();
        });
    };

    // Save to contact From Family
    $scope.saveToContactFromFamily = function() {
      const $postData = {
        BrokerEventID: $scope.brokerEventId,
        DateOfCalculation: moment().format(),
        TypeOfCalculation: 'FundingCalculator',
      };
      contactService
        .calculationsSet($scope.contactFamilyId, $postData)
        .then(response => {
          if (response.data) {
            toastError();
            return;
          }
          toaster.pop(
            'success',
            'Saved',
            'Funding calculator successfully saved to contact'
          );
          $scope.showSelectClientDropown = false;
          $scope.checkLinkerIsFromContact();
          // Delete session after linked with family.
          loanCalculatorLinking.resetLinker();
          if (
            $window.sessionStorage.getItem(
              'contactFamilyIdForFundingCalculator'
            )
          ) {
            $window.sessionStorage.removeItem(
              'contactFamilyIdForFundingCalculator'
            );
          }
        });
    };

    const isContactWithoutFamilyID =
      $localStorage.fundingCalculatorCreateNewContact &&
      !$localStorage.fundingCalculatorFamilyId;
    if (isContactWithoutFamilyID) {
      $localStorage.fundingCalculatorCreateNewContact = '';
    }

    const validContactData =
      $localStorage.fundingCalculatorCreateNewContact &&
      $localStorage.fundingCalculatorFamilyId;
    if (validContactData) {
      $scope.contactFamilyId = angular.copy(
        $localStorage.fundingCalculatorFamilyId
      );
      const postData = {
        BrokerEventID: $scope.brokerEventId,
        DateOfCalculation: moment().format(),
        TypeOfCalculation: 'FundingCalculator',
      };
      contactService
        .calculationsSet($scope.contactFamilyId, postData)
        .then(response => {
          if (response.data) {
            toastError();
            return;
          }
          toaster.pop(
            'success',
            'Saved',
            'Funding calculator successfully saved to contact'
          );
          $scope.showSelectClientDropown = false;
          $scope.resetSaveToContact();
          $localStorage.fundingCalculatorFamilyId = '';
          $localStorage.fundingCalculatorCreateNewContact = '';
          $scope.checkLinkerIsFromContact();
        });
    } else {
      $scope.checkLinkerIsFromContact();
    }

    // Funding Required
    //----------------------------------------------------------------------------

    $scope.isFundingRequierdVisible = false;
    $scope.newFundingRequierdList = [];

    // Add Funding Requierd Div
    $scope.addFundingRequierd = function() {
      $scope.isFundingRequierdVisible = true;
      const $newFundingRequierd = {
        id: Math.random(),
        fundingCalParametersId: 0,
        fundingParticulars: '',
        fundingValue: '',
        isOnlyValueEditable: false,
        isEditMode: false,
      };
      $scope.newFundingRequierdList.push($newFundingRequierd);
    };

    // Add Or Update Funding Required
    $scope.isFundingRequiredLoader = true;
    $scope.saveFundingRequierd = function(fundingRequierd, ismsgdisply) {
      if (fundingRequierd.fundingParticulars === '') {
        toaster.pop('error', 'Error', 'Please enter funding required name');
        return;
      }
      if (ismsgdisply) $scope.isFundingRequiredLoader = true;

      const postData = {
        fundsId: fundingRequierd.id,
        fundingCalParametersId: fundingRequierd.fundingCalParametersId,
        fundingType: 'FUNDING_REQUIRED',
        fundingParticulars: fundingRequierd.fundingParticulars,
        fundingValue: fundingRequierd.fundingValue,
        isOnlyValueEditable: fundingRequierd.isOnlyValueEditable,
        brokerEventId: $scope.brokerEventId,
      };

      if (!fundingRequierd.isEditMode) {
        if (Math.floor(postData.fundsId) === 0) postData.fundsId = 0;
      }

      fundingCalculatorService
        .setFundingCalculatorFunds(postData)
        .then(response => {
          if (response.data !== 0) {
            if (postData.fundsId === 0) {
              const $index = $scope.newFundingRequierdList.indexOf(
                fundingRequierd
              );
              if ($index > -1) $scope.newFundingRequierdList.splice($index, 1);
            }
            if (
              fundingRequierd.fundingParticulars ===
              'Lenders Mortgage Insurance'
            ) {
              if (
                typeof $scope.fundingCalculatorProposedLending !==
                  'undefined' &&
                typeof $scope.fundingCalculatorProposedLending[0] !==
                  'undefined'
              ) {
                $scope.fundingCalculatorProposedLending[0].lmiAmount =
                  fundingRequierd.fundingValue;
                $scope.saveProposedLending(
                  $scope.fundingCalculatorProposedLending[0],
                  false
                );
              }
            }
            if (postData.fundsId === 0 && ismsgdisply)
              toaster.pop(
                'success',
                'Added',
                'Funding required has been Added Successfully'
              );
            else if (ismsgdisply)
              toaster.pop(
                'success',
                'Updated',
                'Funding required has been updated Successfully'
              );
            $scope.isFundingRequiredLoader = false;
            fundingRequierd.isEditMode = false;
            if (ismsgdisply) $scope.getFundingCalculatorFunds();
            else {
              $scope.getFundingCalculatorFundsSummary(false);
              $scope.getProposedLendingSummary();
            }
          } else {
            toastError();
          }
        })
        .catch(toastError);
    };

    // Delete Funding Required
    $scope.deleteFundingRequierd = function(fundingRequierd) {
      if (!fundingRequierd.isEditMode) {
        if (Math.floor(fundingRequierd.id) === 0) fundingRequierd.id = 0;
      }
      if (fundingRequierd.id === 0) {
        const $index = $scope.newFundingRequierdList.indexOf(fundingRequierd);
        if ($index > -1) $scope.newFundingRequierdList.splice($index, 1);
      } else {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your funding required list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          confirm => {
            if (confirm) {
              fundingCalculatorService
                .deleteFundingCalculatorFunds(fundingRequierd.id)
                .then(response => {
                  if (response.data === 1) {
                    $scope.getFundingCalculatorFunds();
                    swal(
                      'Deleted',
                      'Funding required has been deleted.',
                      'success'
                    );
                  }
                })
                .catch(displayError);
            }
          }
        );
      }
    };

    // Funds Available
    //----------------------------------------------------------------------------

    $scope.isFundsAvailableVisible = false;
    $scope.newFundsAvailableList = [];

    // Add Funds Availabled Div
    $scope.addFundsAvailable = function() {
      $scope.isFundsAvailableVisible = true;
      const $newFundsAvailable = {
        id: Math.random(),
        fundingCalParametersId: 0,
        fundingParticulars: '',
        fundingValue: '',
        isOnlyValueEditable: false,
        isEditMode: false,
      };
      $scope.newFundsAvailableList.push($newFundsAvailable);
    };

    // Add Or Update Funds Available
    $scope.isFundsAvailableLoader = false;
    $scope.saveFundsAvailable = function(fundsAvailable, ismsgdisply) {
      if (fundsAvailable.fundingParticulars === '') {
        toaster.pop('error', 'Error', 'Please enter funds available name');
        return;
      }
      if (ismsgdisply) $scope.isFundsAvailableLoader = true;

      const postData = {
        fundsId: fundsAvailable.id,
        fundingCalParametersId: fundsAvailable.fundingCalParametersId,
        fundingType: 'FUNDS_AVAILABLE',
        fundingParticulars: fundsAvailable.fundingParticulars,
        fundingValue: fundsAvailable.fundingValue,
        isOnlyValueEditable: fundsAvailable.isOnlyValueEditable,
        brokerEventId: $scope.brokerEventId,
      };

      if (!fundsAvailable.isEditMode) {
        postData.fundsId = 0;
      }

      fundingCalculatorService
        .setFundingCalculatorFunds(postData)
        .then(response => {
          if (response.data !== 0) {
            if (postData.fundsId === 0) {
              const $index = $scope.newFundsAvailableList.indexOf(
                fundsAvailable
              );
              if ($index > -1) $scope.newFundsAvailableList.splice($index, 1);
            }

            if (!fundsAvailable.isEditMode && ismsgdisply)
              toaster.pop(
                'success',
                'Added',
                'Funds available has been Added Successfully'
              );
            else if (ismsgdisply)
              toaster.pop(
                'success',
                'Updated',
                'Funds available has been updated Successfully'
              );
            fundsAvailable.isEditMode = false;
            $scope.isFundsAvailableLoader = false;
            if (ismsgdisply) $scope.getFundingCalculatorFunds();
            else {
              $scope.getFundingCalculatorFundsSummary(false);
              $scope.getProposedLendingSummary();
            }
          } else {
            toastError();
          }
        })
        .catch(toastError);
    };

    // Delete Funds Available
    $scope.deleteFundsAvailable = function(fundsAvailable) {
      if (!fundsAvailable.isEditMode) {
        if (Math.floor(fundsAvailable.id) === 0) fundsAvailable.id = 0;
      }

      if (fundsAvailable.id === 0) {
        const $index = $scope.newFundsAvailableList.indexOf(fundsAvailable);
        if ($index > -1) $scope.newFundsAvailableList.splice($index, 1);
      } else {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your funds available list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          confirm => {
            if (confirm) {
              fundingCalculatorService
                .deleteFundingCalculatorFunds(fundsAvailable.id)
                .then(response => {
                  if (response.data === 1) {
                    $scope.getFundingCalculatorFunds();
                    swal(
                      'Deleted',
                      'Funds available has been deleted.',
                      'success'
                    );
                  }
                })
                .catch(displayError);
            }
          }
        );
      }
    };

    // change funding value
    $scope.changeFundingValue = function(fundingRequierd) {
      if (fundingRequierd.fundingValue === 0) {
        fundingRequierd.fundingValue = '';
      }
    };

    // Inner Funding Required
    //----------------------------------------------------------------------------

    // Add Inner Funding Required
    $scope.addInnerFundingRequired = function(fundingCalculatorParameters) {
      const $newInnerFundingRequired = {
        fundsId: Math.random(),
        fundingCalParametersId:
          fundingCalculatorParameters.fundingCalParametersId,
        name: '',
        value: '',
        isOnlyValueEditable: false,
        IsEditMode: false,
      };
      fundingCalculatorParameters.newInnerFundingRequiredList.push(
        $newInnerFundingRequired
      );
    };

    // Add Or Update Inner Funding Required
    $scope.isFundingRequiredLoader = false;
    $scope.saveInnerFundingRequired = function(
      innerFundingRequired,
      fundingCalculatorParameters,
      isMsgDisplay
    ) {
      if (
        innerFundingRequired.name === DEFAULT_FUND_NAMES.TOTAL_PROPERTY_AMOUNT
      ) {
        fundingCalculatorParameters.purchaseInfo.propertyValue =
          innerFundingRequired.value;
        $scope.saveProperty(null, fundingCalculatorParameters, true);
        $scope.getFundingCalculatorProposedLending();
        $scope.getFundingCalculatorLMIDeatils();
        return true;
      }

      if (isMsgDisplay) $scope.isFundingRequiredLoader = true;

      if (innerFundingRequired.FundingParticulars === '') {
        toaster.pop(
          'error',
          'Error',
          'Please enter inner funding required name'
        );
        return;
      }

      const postData = {
        fundsId: innerFundingRequired.fundsId,
        fundingCalParametersId: innerFundingRequired.fundingCalParametersId,
        fundingType: 'FUNDING_REQUIRED',
        fundingParticulars: innerFundingRequired.name,
        fundingValue: innerFundingRequired.value,
        isOnlyValueEditable: innerFundingRequired.isOnlyValueEditable,
        brokerEventId: $scope.brokerEventId,
      };

      if (!innerFundingRequired.isEditMode) {
        if (Math.floor(postData.FundsID) === 0) postData.FundsID = 0;
      }

      fundingCalculatorService
        .setFundingCalculatorFunds(postData)
        .then(response => {
          if (response.data !== 0) {
            if (postData.FundsID === 0) {
              const $index = fundingCalculatorParameters.newInnerFundingRequiredList.indexOf(
                innerFundingRequired
              );
              if ($index > -1)
                fundingCalculatorParameters.newInnerFundingRequiredList.splice(
                  $index,
                  1
                );
            }

            if (postData.FundsID === 0) {
              if (isMsgDisplay)
                toaster.pop(
                  'success',
                  'Added',
                  'Inner funding required has been Added Successfully'
                );
            } else if (isMsgDisplay)
              toaster.pop(
                'success',
                'Updated',
                'Inner funding required has been updated Successfully'
              );
            $scope.isFundingRequiredLoader = false;
            innerFundingRequired.isEditMode = false;
            if (isMsgDisplay) {
              $scope.getFundingCalculatorParameters(true);
            } else {
              $scope.getFundingCalculatorFundsSummary(false);
            }
          } else {
            toastError();
          }
        })
        .catch(toastError);
    };

    // Delete Inner Funding Required
    $scope.deleteInnerFundingRequired = function(
      innerFundingRequired,
      fundingCalculatorParameters
    ) {
      if (!innerFundingRequired.isEditMode) {
        if (Math.floor(innerFundingRequired.fundsId) === 0)
          innerFundingRequired.fundsId = 0;
      }

      if (innerFundingRequired.fundsId === 0) {
        const $index = fundingCalculatorParameters.newInnerFundingRequiredList.indexOf(
          innerFundingRequired
        );
        if ($index > -1)
          fundingCalculatorParameters.newInnerFundingRequiredList.splice(
            $index,
            1
          );
      } else {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text:
              'This record will be removed from your inner funding required list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          confirm => {
            if (confirm) {
              fundingCalculatorService
                .deleteFundingCalculatorFunds(innerFundingRequired.fundsId)
                .then(response => {
                  if (response.data === 1) {
                    $scope.getFundingCalculatorParameters(true);
                    $scope.getFundingCalculatorFundsSummary(false);
                    swal(
                      'Deleted',
                      'Inner funding required has been deleted.',
                      'success'
                    );
                  }
                })
                .catch(displayError);
            }
          }
        );
      }
    };

    $scope.hideSummaryInMobile = function() {
      $scope.isSummaryVisibleInMobile = false;
    };

    // Delay Function For InnerFunding Required
    $scope.delayFunctionForInnerFundingRequired = function(
      innerFundingRequired,
      fundingCalculatorParameters,
      boolValue
    ) {
      if (innerFundingRequiredInputChangedPromise) {
        $timeout.cancel(innerFundingRequiredInputChangedPromise);
      }
      innerFundingRequiredInputChangedPromise = $timeout(() => {
        $scope.saveInnerFundingRequired(
          innerFundingRequired,
          fundingCalculatorParameters,
          boolValue
        );
      }, 1000);
    };

    // Delay Function For InnerFunding Required
    $scope.delayFunctionForFundingRequired = function(
      fundingRequierd,
      boolValue
    ) {
      if (fundingRequiredInputChangedPromise) {
        $timeout.cancel(fundingRequiredInputChangedPromise);
      }
      fundingRequiredInputChangedPromise = $timeout(() => {
        $scope.saveFundingRequierd(fundingRequierd, boolValue);
      }, 1000);
    };

    // Delay Function For InnerFunding Required
    $scope.delayFunctionForFundsAvailable = function(
      fundsAvailable,
      boolValue
    ) {
      if (fundsAvailableInputChangedPromise) {
        $timeout.cancel(fundsAvailableInputChangedPromise);
      }
      fundsAvailableInputChangedPromise = $timeout(() => {
        $scope.saveFundsAvailable(fundsAvailable, boolValue);
      }, 1000);
    };

    // Delay Function For Proposed Lending Settings
    $scope.delayFunctionForProposedLendingSettings = function() {
      if (proposedLendingSettingsInputChangedPromise) {
        $timeout.cancel(proposedLendingSettingsInputChangedPromise);
      }
      proposedLendingSettingsInputChangedPromise = $timeout(() => {
        $scope.setProposedLendingSettings();
      }, 1000);
    };

    // Delay Function For Proposed Lending
    $scope.delayFunctionForProposedLending = function(
      proposedLending,
      boolValue
    ) {
      if (proposedLendingInputChangedPromise) {
        $timeout.cancel(proposedLendingInputChangedPromise);
      }
      proposedLendingInputChangedPromise = $timeout(() => {
        proposedLending.isEditLoanAmount = true;
        $scope.saveProposedLending(proposedLending, boolValue);
      }, 1000);
      $scope.$on('$destroy', () => {
        $timeout.cancel(proposedLendingInputChangedPromise);
      });
    };

    $scope.getFirstHomeBuyer = function() {
      fundingCalculatorService
        .getFirstHomeBuyer($scope.brokerEventId)
        .then(firstHomeBuyer => {
          $scope.firstHomeBuyerData = firstHomeBuyer;
        });
    };
    $scope.getFirstHomeBuyer();

    $scope.setFirstHomeBuyer = function() {
      const postData = {
        brokerEventId: $scope.brokerEventId,
        isFirstHomeBuyer: $scope.firstHomeBuyerData.isFirstHomeBuyer,
        isSelfEmployed: $scope.firstHomeBuyerData.isSelfEmployed,
      };
      fundingCalculatorService.setFirstHomeBuyer(postData).then(response => {
        if (
          response.data === 1 &&
          $scope.fundingCalculatorParametersList.length > 0
        ) {
          $scope.getFundingCalculatorParameters(true);
        }
      });
    };

    /**
     * Start Lending Scenario
     * @author Francis dela Vega
     * */

    $scope.backToLendingScenario = function() {
      toaster.pop('info', 'Redirecting', 'Going back to Lending Scenario');
      $timeout(() => {
        $state.go('app.LendingScenarioSingle', {
          familyId: $window.sessionStorage.getItem('lendingScenarioContact'),
          lendingScenarioId: $window.sessionStorage.getItem('lendingScenario'),
        });
        $window.sessionStorage.removeItem('editFundsCalc');
        $window.sessionStorage.removeItem('lendingScenarioContact');
        $window.sessionStorage.removeItem('lendingScenario');
      }, 1000);
    };

    $scope.backToLoanApplication = function() {
      toaster.pop('info', 'Redirecting', 'Going back to Loan Application');
      $timeout(() => {
        $window.sessionStorage.setItem('loanApplicationSection', 'loandetails');
        $window.sessionStorage.setItem(
          'loanApplicationPanel',
          'fundingDetails'
        );

        $state.go('app.loanApplicationDetailsV3', {
          familyId: $window.sessionStorage.getItem('loanApplicationContact'),
          loanAppId: $window.sessionStorage.getItem('loanApplication'),
        });

        $window.sessionStorage.removeItem('editFundsCalcLA');
        $window.sessionStorage.removeItem('loanApplicationContact');
        $window.sessionStorage.removeItem('loanApplication');
      }, 1000);
    };

    $scope.editFundsCalcName = $window.sessionStorage.getItem('editFundsCalc');
    $scope.editFundsCalcLA = $window.sessionStorage.getItem('editFundsCalcLA');

    $scope.saveToLendingScenario = function() {
      if ($scope.editFundsCalcName) {
        $scope.openEditFunding();
      } else {
        $scope.openAddFunding();
      }
    };

    $scope.saveToLoanApplication = function() {
      if ($scope.editFundsCalcLA) {
        $scope.openEditFundingLA();
      } else {
        $scope.openAddFundingLA();
      }
    };

    $scope.saveLendingScenarioFunding = function() {
      lendingScenarioService
        .fundsRequiredNameSet({
          brokerEventId: $stateParams.brokerEventId,
          name: $scope.Title,
        })
        .then(() => {
          lendingScenarioService
            .fundsRequiredLinkSet({
              lendingScenarioId: $window.sessionStorage.getItem(
                'lendingScenario'
              ),
              brokerEventId: $stateParams.brokerEventId,
            })
            .then(() => {
              $scope.backToLendingScenario();
            });
        });
    };

    $scope.openEditFunding = function() {
      $uibModal.open({
        templateUrl:
          '/assets/views/contacts/client/lending/lendingScenario/editFunding.html',
        scope: $scope,
        controller($uibModalInstance, saveLendingScenarioFunding) {
          $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
          $scope.save = saveLendingScenarioFunding;
          // initialize title
          $scope.Title = $scope.editFundsCalcName;
        },
        resolve: {
          saveLendingScenarioFunding() {
            return $scope.saveLendingScenarioFunding;
          },
        },
        size: 'md',
      });
    };

    $scope.openAddFunding = function() {
      $uibModal.open({
        templateUrl:
          '/assets/views/contacts/client/lending/lendingScenario/addFunding.html',
        scope: $scope,
        controller($uibModalInstance, saveLendingScenarioFunding) {
          $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
          $scope.save = saveLendingScenarioFunding;
        },
        resolve: {
          saveLendingScenarioFunding() {
            return $scope.saveLendingScenarioFunding;
          },
        },
        size: 'md',
      });
    };

    $scope.openAddFundingLA = function() {
      $timeout(() => {
        loanScenarioService
          .linkedFundingCalculatorSet(
            $scope.loanAppId,
            $stateParams.brokerEventId
          )
          .then(() => {
            $scope.backToLoanApplication();
          });
      }, 500);
    };

    $scope.openEditFundingLA = function() {
      loanScenarioService
        .fundsRequiredLinkSet(
          $scope.loanAppId, // loanApplicationId
          $stateParams.brokerEventId // brokerEventId
        )
        .then(() => {
          $scope.backToLoanApplication();
        });
    };

    // Lending Scenario ID
    $scope.lendingScenarioId = $window.sessionStorage.getItem(
      'lendingScenario'
    );
    // Loan Application ID
    $scope.loanAppId = $window.sessionStorage.getItem('loanApplication');

    // END Lending Scenario

    // Open popup for Add Property in Mobile
    $scope.openModalForAddProperty = function(fundingCalculatorParameters) {
      $uibModal.open({
        templateUrl:
          '/assets/views/fundingCalculator/partials/add_property_modal.html',
        controller: 'AddPropertyModalCtrl',
        size: 'md',
        windowClass: 'add_property',
        resolve: {
          saveProperty() {
            return $scope.saveProperty;
          },
          fundingCalculatorParameters() {
            return fundingCalculatorParameters;
          },
          setBlankObject() {
            return $scope.setBlankObject;
          },
          getSearchAddressSuggestion() {
            return $scope.getSearchAddressSuggestion;
          },
          closeAutoHomeAddress() {
            return $scope.closeAutoHomeAddress;
          },
        },
      });
    };

    $scope.isPurposeConstruction = purposeId => {
      const constructionIds = [
        FUNDING_PURPOSE_IDS.CONSTRUCTION_INVESTMENT,
        FUNDING_PURPOSE_IDS.CONSTRUCTION_OWNER_OCCUPIED,
      ];
      const isConstruction = constructionIds.includes(parseInt(purposeId, 10));
      return isConstruction;
    };

    $scope.getPropertyTotalLabel = fundingCalculatorObj => {
      const defaultLabel = 'Total Property Amount';
      const constructionLabel = 'Build Amount';
      const purposeId =
        fundingCalculatorObj.purchaseInfo &&
        fundingCalculatorObj.purchaseInfo.purpose;

      const useConstructionLabel =
        !currentUserService.isNZ && $scope.isPurposeConstruction(purposeId);
      const propertyTotalLabel =
        $scope.isFundingCalcFeatureOn && useConstructionLabel
          ? constructionLabel
          : defaultLabel;
      return propertyTotalLabel;
    };

    $scope.resetFundingCalculatorData = fundingCalculatorObj => {
      if (!$scope.isFundingCalcFeatureOn) return;
      const purposeId = fundingCalculatorObj.purchaseInfo.purpose;
      if (!$scope.isPurposeConstruction(purposeId)) {
        $scope.clearLandValueSubDetails(fundingCalculatorObj);
      }
    };

    const setDefaultState = obj => {
      const adviserReportingState = currentUserService.reportingStateName;
      const returnEmptyStates = ['NZ', 'National'];

      if (
        !adviserReportingState ||
        returnEmptyStates.includes(adviserReportingState)
      )
        return;
      if (!adviserReportingState.includes('/')) {
        obj.address.state = adviserReportingState;
        return;
      }

      corporateService
        .familyAddressGet(currentUserService.familyId)
        .then(({ data }) => {
          if (!data.length) return;
          const businessAddress = data.find(address => address.isBusiness);
          obj.address.state =
            businessAddress && businessAddress.stateShort_Name;
        });
    };

    $scope.setBlankObject = function() {
      $scope.fundingCalculatorParameters = {
        fundingCalParametersId: 0,
        address: {
          country: '',
          state: '',
          latitude: 0,
          longitude: 0,
          formattedAddress: '',
        },
        purchaseInfo: {
          purpose: null,
          propertyStatus: null,
          propertyType: null,
          propertyValue: null,
        },
        clientInfo: {
          totalIncomeOfPurchasers: null,
          dependents: '',
          firstBuyer: false,
          eligiblePensioner: false,
          propertyLocation: 'northParallel',
          paymentMethod: 'electronicTransaction',
          foreignPurchaser: 'no',
          victoriaCities: 'Melbourne',
        },
        landValue: null,
        isAlreadyOwned: false,
        isEquityRelease: false,
        equityReleaseAmount: null,
      };

      if ($scope.isFundingCalcFeatureOn) {
        setDefaultState($scope.fundingCalculatorParameters);
      }
    };

    $scope.setBlankObject($scope.fundingCalculatorParameters);

    // Add Property
    //----------------------------------------------------------------------------
    // Open Add-Edit Property Div
    $scope.ShowPropertyDiv = function(fundingCalculatorParameters) {
      fundingCalculatorParameters.showPropertyMainDiv = true;
    };

    $scope.saveProperty = function(
      formdata,
      fundingCalculatorParameters,
      isFromFund
    ) {
      if (!isFromFund) {
        let firstError = null;
        if (formdata.$invalid) {
          _.each(formdata, field => {
            if (field && field[0] !== '$') {
              if (firstError === null && !field.$valid) {
                firstError = field.$name;
              }
              if (field.$pristine) {
                field.$dirty = true;
              }
            }
          });
          return 'Invalid';
        }
      }

      if (
        fundingCalculatorParameters.purchaseInfo.purpose === 6 ||
        fundingCalculatorParameters.purchaseInfo.purpose === 7
      ) {
        if (
          typeof fundingCalculatorParameters.purchaseInfo.refinanceAmount ===
            'undefined' ||
          fundingCalculatorParameters.purchaseInfo.refinanceAmount === 0 ||
          fundingCalculatorParameters.purchaseInfo.refinanceAmount === ''
        ) {
          toaster.pop('error', 'Invalid', 'Please add refinance amount');
          return;
        }
      }
      if (!fundingCalculatorParameters.clientInfo.totalIncomeOfPurchasers)
        fundingCalculatorParameters.clientInfo.totalIncomeOfPurchasers = 0;
      if (!fundingCalculatorParameters.clientInfo.dependents)
        fundingCalculatorParameters.clientInfo.dependents = 0;
      if (
        typeof fundingCalculatorParameters.landValue === 'undefined' ||
        fundingCalculatorParameters.landValue === ''
      )
        fundingCalculatorParameters.landValue = 0;
      if (fundingCalculatorParameters.purchaseInfo.propertyValue) {
        fundingCalculatorParameters.purchaseInfo.propertyValue = Number(
          fundingCalculatorParameters.purchaseInfo.propertyValue
        );
      }
      const postData = {
        brokerEventId: $scope.brokerEventId,
        selection: fundingCalculatorParameters,
      };
      fundingCalculatorService
        .setFundingCalculatorParameters(postData)
        .then(response => {
          if (response.data !== 0) {
            $scope.getFundingCalculatorParameters(true);
            $scope.getFundingCalculatorFunds();
            if (!isFromFund) {
              if (fundingCalculatorParameters.fundingCalParametersId === 0) {
                toaster.pop(
                  'success',
                  'Added',
                  'Property has been successfully added'
                );
              } else {
                toaster.pop(
                  'success',
                  'Updated',
                  'Property has been successfully updated'
                );
              }
              fundingCalculatorParameters.showPropertyMainDiv = false;
            }
          } else {
            toastError();
          }
        })
        .catch(toastError);
    };

    // Generate PDF Report
    $scope.getFundingCalculatorReport = function() {
      let byteCharacters;
      let byteNumbers;
      let i;
      let byteArray;
      let blob;
      cfpLoadingBar.start();
      cfpLoadingBar.inc();
      const familyId =
        $window.sessionStorage.getItem('loanApplicationContact') ||
        $scope.contactFamilyId ||
        0;
      toastInfo('Generating PDF Report This might take a few seconds');
      fundingCalculatorService
        .getFundingCalculatorReport($scope.brokerEventId, familyId)
        .then(response => {
          if (!response) return;
          const data = response;
          const currentDate = $filter('date')(new Date(), 'dd-MM-yy');
          if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
            // IE workaround
            byteCharacters = atob(data.documentContent);
            byteNumbers = new Array(byteCharacters.length);
            for (i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            byteArray = new Uint8Array(byteNumbers);
            blob = new $window.Blob([byteArray], { type: data.contentType });
            $window.navigator.msSaveOrOpenBlob(
              blob,
              `fundingCalculator_${currentDate}.pdf`
            );
          } else {
            const { contentType, documentContent } = data;
            downloadDataService.download(
              `data:${contentType};base64,${documentContent}`,
              `fundingCalculator_${currentDate}.pdf`,
              contentType
            );
          }
          $timeout(() => {
            cfpLoadingBar.complete();
          }, 750);
        });
    };

    // Delete Property
    $scope.deleteProperty = function(fundingCalculatorParameters) {
      if (fundingCalculatorParameters.fundingCalParametersId !== 0) {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This property will be removed from your property list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          confirm => {
            if (confirm) {
              fundingCalculatorService
                .deleteFundingCalculatorParameters(
                  fundingCalculatorParameters.fundingCalParametersId
                )
                .then(response => {
                  if (response.data === 1) {
                    $scope.getFundingCalculatorParameters(true);
                    $scope.getFundingCalculatorFundsSummary(false);
                    swal('Deleted', 'property has been deleted.', 'success');
                  }
                })
                .catch(displayError);
            }
          }
        );
      } else {
        fundingCalculatorParameters.showPropertyMainDiv = false;
      }
    };

    $scope.disableSaving = false;
    $scope.saveToOpportunity = () => {
      $scope.disableSaving = true;
      const loanScenarioId = $scope.currentSourceLinker.id;
      const params = {
        brokerEventID: $scope.brokerEventId,
        typeOfCalculation: 'FundingCalculator',
      };
      loanOpportunityService
        .setLoanOpportunityCalculation(loanScenarioId, params)
        .then(
          () => {
            $state.go(
              `app.opportunity`,
              $scope.currentSourceLinker.previousRouteStateParams
            );
          },
          () => {
            $scope.disableSaving = false;
          }
        );
    };

    $scope.goToOpportunityOrLoanapp = () => {
      if ($scope.currentSourceLinker.isOpportunityOrLoanapp) {
        $state.go(
          `app.opportunity`,
          $scope.currentSourceLinker.previousRouteStateParams
        );
      } else {
        $scope.goBackToClientContact($scope.currentSourceLinker.familyId);
      }
    };

    $scope.isHideTooltip = false;
    $scope.hideTooltip = () => {
      $scope.isHideTooltip = true;
    };

    $scope.showLoanSplitInfoTooltip = true;
    $scope.hideLoanSplitInfoTooltip = () => {
      $scope.hideLoanSplitInfoTooltip = false;
    };

    /** NZ Requirements By Elmer Datolayta */
    $scope.hiddenClientForNz = false;
    commonFnService.hiddenClientForNz().then(response => {
      $scope.hiddenClientForNz = response;
    });

    $scope.clearLandValueSubDetails = fundingCalculatorParams => {
      fundingCalculatorParams.equityReleaseAmount = null;
      fundingCalculatorParams.isEquityRelease = false;
      fundingCalculatorParams.isAlreadyOwned = false;
    };

    $scope.clearRefiEquityFields = fundingCalculatorParams => {
      if (!fundingCalculatorParams.isAlreadyOwned) {
        fundingCalculatorParams.isEquityRelease = false;
      }

      if (!fundingCalculatorParams.isEquityRelease) {
        fundingCalculatorParams.equityReleaseAmount = null;
      }
    };

    $scope.showAddAnotherPropertyButton = (
      fundingCalculatorFlag,
      fundingCalculatorFundsSummary,
      fundingCalculatorParam
    ) => {
      const showButton =
        fundingCalculatorFlag &&
        fundingCalculatorFundsSummary.isFundingRequired &&
        !fundingCalculatorParam.showPropertyMainDiv;
      return showButton;
    };

    $scope.getProposedLendingValue = () => {
      if (!$scope.isFundingCalcFeatureOn) {
        if ($scope.fundingCalculatorFundsSummary.isCalculatedProposedLending) {
          return $scope.fundingCalculatorFundsSummary.totalProposedLending || 0;
        }
        return (
          $scope.fundingCalculatorFundsSummary
            .totalProposedLendingUserDefined || 0
        );
      }

      if (!$scope.fundingCalculatorFundsSummary || !$scope.fundingClcMainObj)
        return 0;
      const isRequiredAvailable =
        $scope.fundingCalculatorFundsSummary.totalFundingRequired &&
        $scope.fundingCalculatorFundsSummary.totalFundingAvailable &&
        $scope.fundingCalculatorFundsSummary.totalFundingRequired >
          $scope.fundingCalculatorFundsSummary.totalFundingAvailable;
      const tempLendingValue = isRequiredAvailable
        ? $scope.fundingCalculatorFundsSummary.totalFundingRequired -
          $scope.fundingCalculatorFundsSummary.totalFundingAvailable
        : 0;
      const proposedLendingValue =
        ($scope.fundingCalculatorFundsSummary.isCalculatedProposedLending
          ? $scope.fundingCalculatorFundsSummary.totalProposedLending
          : tempLendingValue) -
        $scope.fundingCalculatorFundsSummary.fundingValue;
      const lendingValueConverted =
        proposedLendingValue > 0 ? proposedLendingValue : 0;
      $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined = lendingValueConverted;
      $scope.fundingCalculatorFundsSummary.totalProposedLendingUserDefined = lendingValueConverted;
      return lendingValueConverted;
    };

    $scope.displayTotalLending = () => {
      const isDisplay =
        $scope.isFundingCalcFeatureOn ||
        ($scope.fundingCalculatorFundsSummary.isCalculatedProposedLending &&
          $scope.fundingCalculatorProposedLending.length);
      return isDisplay;
    };
  });
