export default class ContactInfoLink {
  $onInit() {
    this.isWebsiteOrFax = this.type === 'fax' || this.type === 'web';
    this.initialize();
  }

  $onChanges(changes) {
    const { link } = changes;
    if (!link) return;

    if (link.currentValue !== link.previousValue) {
      this.initialize();
    }
  }

  initialize() {
    this.displayData = this.getDisplayData();
    const formattedDisplay = `${this.displayData.contactLink} ${
      this.displayData.additionalText
    }`;
    this.contactDisplayValue = this.isWebsiteOrFax
      ? this.link
      : formattedDisplay;

    const phoneTypes = ['phone', 'mobile', 'fax'];
    this.isPhoneType = !!phoneTypes.find(phone => phone === this.type);
    this.formattedLink = this.getFormattedLink(this.displayData.contactLink);
  }

  getFormattedLink(link) {
    switch (this.type) {
      case 'email':
        return `mailto:${link}`;
      case 'fax':
      case 'phone':
        return `tel:${link}`;
      default:
        return link;
    }
  }

  getDisplayData() {
    const splitData = this.link.split('|').map(str => str.trim());
    return {
      contactLink: splitData[0],
      additionalText: splitData.length > 1 ? `(${splitData[1]})` : '',
    };
  }
}
