export const WIDGET_COMPONENT = {
  1: '<fixed-rate-reviews my-param="testData"></fixed-rate-reviews>',
  3: '<upcoming-birthdays-small my-param="testData"></upcoming-birthdays-small>',
  5: '<commissions-received my-param="testData"></commissions-received>',
  8: '<outstanding-commissions-small my-param="testData"></outstanding-commissions-small>',
  10: '<client-reviews-small my-param="testData"></client-reviews-small>',
  12: '<tasks my-param="testData"></tasks>',
  6: '<commission-received-table my-param="testData"></commission-received-table>',
  11: '<client-reviews my-param="testData"></client-reviews>',
  9: '<outstanding-commission my-param="testData"></outstanding-commission>',
  7: '<commission-received-chart my-param="testData"></commission-received-chart>',
  13: '<tasks-table my-param="testData"></tasks-table>',
  2: '<fixed-rate-reviews-table my-param="testData"></fixed-rate-reviews-table>',
  4: '<upcoming-birthdays my-param="testData"></upcoming-birthdays>',
  14: '<upcoming-events-table my-param="testData"></upcoming-events-table>',
  15: '<system-updates my-param="testData"></system-updates>',
  16: '<provider-updates my-param="testData"></provider-updates>',
  17: '<interest-rate-updates-table my-param="testData"></interest-rate-updates-table>',
  22: '<target-tracking my-param="testData"></target-tracking>',
  29: '<nps-score-small my-param="testData"></nps-score-small>',
  27: '<lender-split my-param="testData"></lender-split>',
  26: '<adviser-leaderboard my-param="testData"></adviser-leaderboard>',
  28: '<referral-source my-param="testData"></referral-source>',
  24: '<settlement my-param="testData"></settlement>',
  31: '<customer-comment my-param="testData"></customer-comment>',
  30: '<customer-comment-table my-param="testData"></customer-comment-table>',
  32: '<pipeline-lodged my-param="testData"></pipeline-lodged>',
  34: '<pipeline-approved my-param="testData"></pipeline-approved>',
  42: '<pipeline-settled my-param="testData"></pipeline-settled>',
  36: '<pipeline-status-small my-param="testData"></pipeline-status-small>',
  41: '<pipeline-new-leads my-param="testData"></pipeline-new-leads>',
  38: '<pipeline-stats-chart my-param="testData"></pipeline-stats-chart>',
  39: '<pipeline-stats-table my-param="testData"></pipeline-stats-table>',
  47: '<pre-approval-expiries my-param="testData"></pre-approval-expiries>',
  44: '<pre-approval-expiries-small my-param="testData"></pre-approval-expiries-small>',
  50: '<interest-rates-table-directive my-param="testData"></interest-rates-table-directive>',
  51: '<lender-split-small my-param="testData" ng-if="showStatisticalDashboard"></lender-split-small>',
  53: '<average-loan-size my-param="testData" ng-if="showStatisticalDashboard"></average-loan-size>',
  54: '<settlement-volumes my-param="testData" ng-if="showStatisticalDashboard"></settlement-volumes>',
  55: '<enquiry-source-tile my-param="testData" ng-if="showStatisticalDashboard"></enquiry-source-tile>',
};
