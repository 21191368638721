import { objectLength } from 'Common/utilities/objectValidation';
import swal from 'sweetalert';
import { E_SIGN_ACOUNT_TYPE } from 'Common/constants/eSign';

class ESignUploadSignatureModalCtrl {
  constructor(
    $window,
    $timeout,
    configService,
    eSignService,
    crmConfirmation,
    corporateService,
    currentUserService
  ) {
    'ngInject';

    this.$window = $window;
    this.$timeout = $timeout;
    this.configService = configService;
    this.eSignService = eSignService;
    this.crmConfirmation = crmConfirmation;
    this.corporateService = corporateService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.canUserCheckSignature();
    this.sendSmsMessage = 'Send link via SMS';
    this.sendSmsText = 'Send';
    this.disableSendingSMS = false;
    this.setVariables();
    this.corporateContactGet();
  }

  $onDestroy() {
    this.showLoaderTimeOut && this.$timeout.cancel(this.showLoaderTimeOut);
  }

  canUserCheckSignature() {
    const showEsignSignature =
      this.configService.feature && this.configService.feature.esignSignature;
    if (!showEsignSignature) this.closeModal();
  }
  corporateContactGet() {
    if (!this.familyId) return;
    const clientId = 0;
    this.corporateService
      .corporateContactGet(clientId, this.familyId)
      .then(response => {
        if (!response || !response.data) return;
        const { Mobile } = response.data;
        this.userMobileNumber = Mobile;
      });
  }

  setVariables(onConfirm = false) {
    this.loadingMessage = 'Checking esign account status';
    this.isLoading = true;
    this.isShowSuccess = false;
    this.getAccountCheck(onConfirm);
  }

  hidePreloader() {
    this.showLoaderTimeOut = this.$timeout(() => {
      this.isLoading = false;
      this.showLoaderTimeOut && this.$timeout.cancel(this.showLoaderTimeOut);
    }, 2000);
  }

  openEsignSignatureLink() {
    if (!this.redirectURL) return;
    this.$window.open(this.redirectURL, 'noopener, noreferrer');
  }

  getAccountCheck(onConfirm = false) {
    if (!this.familyId) return;
    this.isLoading = true;
    this.eSignService.getAccountCheck(this.familyId).then(response => {
      if (!response || !objectLength(response)) return;
      const { hasExistingSignature, status, redirectURL } = response;
      this.hasExistingSignature = hasExistingSignature;
      this.redirectURL = redirectURL;
      if (status === E_SIGN_ACOUNT_TYPE.INVITED) {
        this.closeModal();
        this.crmConfirmation.open({
          type: 'success',
          title: 'Subscription Successful',
          description: `Your eSign account has now been created, please confirm your email address`,
          buttonText: 'Okay, got it!',
          modalSize: 'md',
        });
        return;
      }
      if (onConfirm && hasExistingSignature) {
        this.closeAndCheckEsignStatus();
        return;
      }
      this.addEditSignatureText = hasExistingSignature
        ? 'A while back you subscribed to E-Sign and added your signature successfully. To edit your signature, please click the link below'
        : 'A while back you subscribed to E-Sign but didn’t add your signature. To complete your subscription, please add the signature you would like to use in signing your documents by clicking the link below.';
      this.addEditSignatureText = onConfirm
        ? 'We check your account and find out that you still not added your signature yet. To complete your subscription, please add the signature you would like to use in signing your documents by clicking the link below.'
        : this.addEditSignatureText;
      this.addEditSignatureButton = hasExistingSignature
        ? 'Edit Signature'
        : 'Add Signature';
      this.hidePreloader();
    });
  }
  closeModal() {
    this.modalInstance.close(this.hasExistingSignature);
  }
  closeAndCheckEsignStatus(addLater = false) {
    const description = addLater
      ? 'Your eSign account does not contain your signature, you can add or update signature in the subscription in your profile page.'
      : 'Your eSign account is now set, you can update your signature in the subscription in your profile page.';
    const title = addLater
      ? 'Esign Settings Incomplete'
      : 'Esign Settings Complete';
    const type = addLater ? 'info' : 'success';
    this.closeModal();
    this.crmConfirmation.open({
      type,
      title,
      description,
      buttonText: 'Okay, got it!',
      modalSize: 'md',
    });
  }
  toggleContactBox() {
    this.showContactBox = !this.showContactBox;
    this.sendSmsMessage = this.showContactBox ? 'Hide' : 'Send link via SMS';
  }
  sendLinkViaSMS() {
    if (!this.userMobileNumber || this.disableSendingSMS) return;
    this.sendSmsText = 'Sending ...';
    this.disableSendingSMS = true;
    this.eSignService
      .postSendSmsLink({
        mobileNumber: this.userMobileNumber,
        code: this.currentUserService.countryId,
      })
      .then(response => {
        if (!response || !response.data) return;
        swal(
          'Success',
          `An SMS with the add signature link has been sent to ${
            this.userMobileNumber
          }.`,
          'success'
        );
      })
      .finally(() => {
        this.disableSendingSMS = false;
        this.sendSmsText = 'Send';
      });
  }
}
export default ESignUploadSignatureModalCtrl;
