app.factory('referralsService', [
  '$q',
  'referralsModel',
  function($q, referralsModel) {
    var factory = this;

    var referrals = new referralsModel();

    factory.getReferrerOrgOwnerGroup = function(
      fromLMWMAgreement,
      agreementId
    ) {
      var defer = $q.defer();

      referrals.getReferrerOrgOwnerGroup(
        fromLMWMAgreement,
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgMembers = function(
      adviserOrganizationId,
      agreementId
    ) {
      var defer = $q.defer();

      referrals.getReferrerOrgMembers(
        adviserOrganizationId,
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getAgreementTypes = function() {
      var defer = $q.defer();

      referrals.getAgreementTypes(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgAgreementsByReferrers = function(
      status,
      createDateFrom,
      createDateTO,
      mermberId,
      groupId,
      isVisible,
      isActive
    ) {
      var defer = $q.defer();

      referrals.getReferrerOrgAgreementsByReferrers(
        status,
        createDateFrom,
        createDateTO,
        mermberId,
        groupId,
        isVisible,
        isActive,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgAgreementsByGroup = function(
      status,
      createDateFrom,
      createDateTo,
      GroupTypeId,
      mermberId,
      groupId,
      isVisible,
      isActive
    ) {
      var defer = $q.defer();

      referrals.getReferrerOrgAgreementsByGroup(
        status,
        createDateFrom,
        createDateTo,
        GroupTypeId,
        mermberId,
        groupId,
        isVisible,
        isActive,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgAgreementsReferrerInfo = function(
      referrerId,
      agreementId
    ) {
      var defer = $q.defer();

      referrals.getReferrerOrgAgreementsReferrerInfo(
        referrerId,
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgAgreementsInfo = function(agreementId) {
      var defer = $q.defer();

      referrals.getReferrerOrgAgreementsInfo(
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgAgreementsWeeklyReportSettings = function(
      referrerId,
      agreementId
    ) {
      var defer = $q.defer();

      referrals.getReferrerOrgAgreementsWeeklyReportSettings(
        referrerId,
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgReferrerAgreement = function(agreementId) {
      var defer = $q.defer();

      referrals.getReferrerOrgReferrerAgreement(
        agreementId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getOrganizationTypes = function() {
      var defer = $q.defer();

      referrals.getOrganizationTypes(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.setReferrerOrgSummary = function($postData) {
      var defer = $q.defer();

      referrals.setReferrerOrgSummary(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.setReferrerOrgAgreementsWeeklyReportSettings = function($postData) {
      var defer = $q.defer();

      referrals.setReferrerOrgAgreementsWeeklyReportSettings(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgAgreementList = function() {
      var defer = $q.defer();

      referrals.getReferrerOrgAgreementList(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgSummary = function($postData) {
      var defer = $q.defer();

      referrals.getReferrerOrgSummary(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getAddressDetails = function(addressId) {
      var defer = $q.defer();

      referrals.getAddressDetails(
        addressId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.setAddressDetails = function($postData) {
      var defer = $q.defer();

      referrals.setAddressDetails(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.deleteReferrerOrgDirector = function($postData) {
      var defer = $q.defer();

      referrals.deleteReferrerOrgDirector(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.deleteReffererOrgReferrer = function($postData) {
      var defer = $q.defer();

      referrals.deleteReffererOrgReferrer(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferralList = function(
      page,
      size,
      searchText,
      status,
      processor,
      fromDate,
      toDate,
      adviserOrgId,
      adviserId,
      referrerOrgId,
      referrerId,
      isActive,
      showAllAdvisers,
      isAdviserActive,
      createdDateFrom,
      createdDateTo,
      businessUnit,
      referrerOrgName,
      referrerName,
      sortByColumnName,
      sortOrder
    ) {
      var defer = $q.defer();

      referrals.getReferralList(
        page,
        size,
        searchText,
        status,
        processor,
        fromDate,
        toDate,
        adviserOrgId,
        adviserId,
        referrerOrgId,
        referrerId,
        isActive,
        showAllAdvisers,
        isAdviserActive,
        createdDateFrom,
        createdDateTo,
        businessUnit,
        referrerOrgName,
        referrerName,
        sortByColumnName,
        sortOrder,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getAgreementTypes = function() {
      var defer = $q.defer();

      referrals.getAgreementTypes(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgList = function(searchText) {
      var defer = $q.defer();

      referrals.getReferrerOrgList(
        searchText,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferralReferrerList = function(searchText) {
      var defer = $q.defer();

      referrals.getReferralReferrerList(
        searchText,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getReferrerOrgMembership = function(referrerOrgId) {
      var defer = $q.defer();

      referrals.getReferrerOrgMembership(
        referrerOrgId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getCheckAgreementExist = function(adviserOrgId, referrerOrgId) {
      var defer = $q.defer();

      referrals.getCheckAgreementExist(
        adviserOrgId,
        referrerOrgId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getAdviserAgreementSearchOptions = function() {
      var defer = $q.defer();

      referrals.getAdviserAgreementSearchOptions(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    return factory;
  },
]);
