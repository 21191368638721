import {
  propertyParametersBuilderForUI,
  firstHomeBuyerBuilderForUI,
  frequencyBuilderForUI,
  proposedLendingSummaryBuilderForUI,
  fundingCalculatorParametersBuilderForUI,
  fundingCalculatorParametersBuilderForMyCRM,
  fundingCalculatorFundsBuilderForUI,
  fundingCalculatorFundsBuilderForMyCRM,
  proposedLendingBuilderForUI,
  proposedLendingBuilderForMyCRM,
  fundingCalculatorFundsLMIBuilderForUI,
  fundingCalculatorFundsLMIBuilderForMyCRM,
  firstHomeBuyerBuilderForMyCRM,
  proposedLendingSettingsBuilderForMyCRM,
  repaymentFrequencyBuilderForMyCRM,
  fundingCalculatorReportBuilderForUI,
  fundingCalculatorFundsSummaryBuilderForUI,
} from 'Common/mappers/fundingCalculator';
import { typeBuilderForUI } from 'Common/mappers/sharedMappers';

class FundingCalculatorService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'FundingCalculator';
  }

  getFundingCalculatorNewBrokerEvent() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/FundingCalculatorNewBrokerEventGet`
    );
  }

  getGeoCodeSearch(address) {
    return this.httpClient.get(`${this.apiBaseUrl}/GeoCodeSearch`, { address });
  }

  getPropertyParameters() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/PropertyParametersGet`)
      .then(response => propertyParametersBuilderForUI(response.data));
  }
  getFundingCalculatorParameters(brokerEventID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FundingCalculatorParametersGet`, {
        brokerEventID,
      })
      .then(response =>
        response.data.map(fundingCalculatorParametersBuilderForUI)
      );
  }

  setFundingCalculatorParameters(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorParametersSet`,
      fundingCalculatorParametersBuilderForMyCRM(data)
    );
  }

  getFundingCalculatorFunds(brokerEventID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FundingCalculatorFundsGet`, { brokerEventID })
      .then(response =>
        response.data
          ? response.data.map(fundingCalculatorFundsBuilderForUI)
          : []
      );
  }

  setFundingCalculatorFunds(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorFundsSet`,
      fundingCalculatorFundsBuilderForMyCRM(data)
    );
  }

  deleteFundingCalculatorFunds(FundsID) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/FundingCalculatorFundsDelete`,
      { FundsID }
    );
  }

  getFundingCalculatorFundsSummary(brokerEventID, loanAppId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FundingCalculatorFundsSummaryGet`, {
        brokerEventID,
        loanAppId,
      })
      .then(response =>
        fundingCalculatorFundsSummaryBuilderForUI(response.data)
      );
  }

  getFundingCalculatorFundsLMI(brokerEventID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FundingCalculatorFundsLMIGet`, { brokerEventID })
      .then(response => fundingCalculatorFundsLMIBuilderForUI(response.data));
  }

  setFundingCalculatorFundsLMI(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorFundsLMISet`,
      fundingCalculatorFundsLMIBuilderForMyCRM(data)
    );
  }

  getLenderList(IsCalculateLMI) {
    // TODO: move to servceabilityService
    return this.httpClient.get(`Serviceability/LenderListGet`, {
      IsCalculateLMI,
    });
  }

  getInsurerListForLenderId(lenderID) {
    // TODO: move to servceabilityService
    return this.httpClient.get(`Serviceability/InsurerListForLenderIdGet`, {
      lenderID,
    });
  }

  getLMICalculatorReport(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LMICalculatorReportGet`, {
      brokerEventID,
    });
  }

  getFundingCalculatorProperties(brokerEventID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/FundingCalculatorPropertiesGet`,
      { brokerEventID }
    );
  }

  getFundsPropertySummary(brokerEventID, protertyNo) {
    return this.httpClient.get(`${this.apiBaseUrl}/FundsPropertySummaryget`, {
      brokerEventID,
      protertyNo,
    });
  }

  getProposedLending(brokerEventID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ProposedLendingGet`, { brokerEventID })
      .then(response => response.data.map(proposedLendingBuilderForUI));
  }

  setProposedLending(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProposedLendingSet`,
      proposedLendingBuilderForMyCRM(data)
    );
  }

  deleteProposedLending(proposedLendingID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/ProposedLendingDelete`, {
      proposedLendingID,
    });
  }

  deleteFundingCalculatorProperties(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorPropertiesDelete`,
      data
    );
  }

  getProposedLendingInterestType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ProposedLendingInterestType`)
      .then(response =>
        response.data ? response.data.map(typeBuilderForUI) : []
      );
  }

  getProposedLendingTerm() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ProposedLendingTerm`)
      .then(response => response.data.map(typeBuilderForUI));
  }

  getFrequency() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FrequencyGet`)
      .then(
        response => response.data && response.data.map(frequencyBuilderForUI)
      );
  }

  getRepaymentFrequency(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/RepaymentFrequencyGet`, {
      brokerEventID,
    });
  }

  setRepaymentFrequency(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/RepaymentFrequencySet`,
      repaymentFrequencyBuilderForMyCRM(data)
    );
  }

  getProposedLendingPropertyWise(brokerEventID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ProposedLendingPropertyWiseGet`,
      { brokerEventID }
    );
  }

  getFundingCalculatorParametersByProperty(
    brokerEventID,
    FundingCalParametersID
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/FundingCalculatorParametersByPropertyGet`,
      { brokerEventID, FundingCalParametersID }
    );
  }

  deleteFundingCalculatorParameters(FundingCalParametersID) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/FundingCalculatorParametersDelete`,
      { FundingCalParametersID }
    );
  }

  getProposedLendingSummary(brokerEventID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ProposedLendingSummaryGet`, { brokerEventID })
      .then(response =>
        response.data
          ? response.data.PropertyData.map(proposedLendingSummaryBuilderForUI)
          : []
      );
  }

  getFirstHomeBuyer(brokerEventId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FirstHomeBuyerGet`, { brokerEventId })
      .then(response => firstHomeBuyerBuilderForUI(response.data));
  }

  setFirstHomeBuyer(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FirstHomeBuyerSet`,
      firstHomeBuyerBuilderForMyCRM(data)
    );
  }

  getFundingCalculatorReport(brokerEventID, familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FundingCalculatorReport`, {
        brokerEventID,
        familyId,
      })
      .then(response => fundingCalculatorReportBuilderForUI(response.data));
  }

  setProposedLendingSettings(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProposedLendingSettingsSet`,
      proposedLendingSettingsBuilderForMyCRM(data)
    );
  }

  getQuickLMICalculatorReport(brokerEventId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/QuickLMICalculatorReportGetv2`,
      { brokerEventId }
    );
  }

  setFundingCalculatorFundsQuickLMI(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorFundsQuickLMISet`,
      data
    );
  }

  getFundingCalculatorFundsQuickLMI(brokerEventID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/FundingCalculatorFundsQuickLMIGet`,
      { brokerEventID }
    );
  }

  setFundingCalculatorFundsIsQuickLMI(brokerEventID, IsQuickCalc) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorFundsIsQuickLMISet`,
      {},
      { brokerEventID, IsQuickCalc }
    );
  }

  getFundingCalculatorStates() {
    return this.httpClient.get(`${this.apiBaseUrl}/FundingCalculatorStatesGet`);
  }

  setFundingCalculatorFundsQuickLMIFavorite(
    brokerEventId,
    lenderId,
    isFavorite
  ) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorFundsQuickLMIFavoriteSet`,
      {},
      { brokerEventId, lenderId, isFavorite }
    );
  }

  getFundingCalculatorFundsQuickLMIFavorite(brokerEventId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FundingCalculatorFundsQuickLMIFavoriteGet`,
      {},
      { brokerEventId }
    );
  }

  setFundingCalculatorQuickLMIProductFinderBrokerEventId(
    quickLMIBrokerEventId,
    productFinderBrokerEventId
  ) {
    return this.httpClient.post(
      `${
        this.apiBaseUrl
      }/FundingCalculatorQuickLMIProductFinderBrokerEventIdSet`,
      {},
      { quickLMIBrokerEventId, productFinderBrokerEventId }
    );
  }

  getFundingCalculatorQuickLMIProductFinderBrokerEventId(
    quickLMIBrokerEventId,
    productFinderBrokerEventId
  ) {
    return this.httpClient.post(
      `${
        this.apiBaseUrl
      }/FundingCalculatorQuickLMIProductFinderBrokerEventIdGet`,
      {},
      { quickLMIBrokerEventId, productFinderBrokerEventId }
    );
  }

  getFundingCalculatorQuickLMILenders(brokerEventId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/FundingCalculatorQuickLMILendersGet`,
      { brokerEventId }
    );
  }

  setQuickLMISecurities(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/quick-lmi-securities`,
      data
    );
  }

  getQuickLMISecurities(brokerEventId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/${brokerEventId}/quick-lmi-securities`
    );
  }

  deleteFundsLMI(brokerEventId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/funds-lmi`, {
      brokerEventId,
    });
  }
}

export default FundingCalculatorService;
