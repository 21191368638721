export function adviserAssistantsBuilderForUI(assistant) {
  return {
    assistantId: parseInt(assistant.Value, 10),
    assistantName: assistant.Name,
  };
}
export function valuationBasisBuilderForUI(result) {
  return {
    name: result.Description,
    value: parseInt(result.ValuationBasisId, 10),
  };
}
export function lenderListBuilderForUI(result) {
  return {
    providerName: result.ProviderName,
    category: result.Category,
    providerId: parseInt(result.ProviderId, 10),
  };
}
export function lendingCategoriesBuilderForUI(category) {
  return {
    categoryName: category.Name,
    categoryId: parseInt(category.Value, 10),
  };
}
export function businessTypesBuilderForUI(type) {
  return {
    name: type.Name,
    value: parseInt(type.Value, 10),
  };
}
export function lenderCategoryBuilderForUI(category) {
  return (
    category && {
      order: category.Order,
      name: category.Name,
      value: parseInt(category.Value, 10),
      helpText: category.HelpText,
      ticked: false,
    }
  );
}
export function frequencyBuilderForUI(option) {
  return {
    name: option.Name,
    value: option.Value,
    helpText: option.HelpText,
  };
}

export function separationLetterGenerateStatusesBuilderForUI(status) {
  return (
    status && {
      id: parseInt(status.Name, 10),
      value: status.Value,
      helpText: status.HelpText,
    }
  );
}

export function separationLetterContentsBuilderForUI(content) {
  return (
    content && {
      id: parseInt(content.Name, 10),
      value: content.Value,
      helpText: content.HelpText,
    }
  );
}

export function employmentNatureForUI(option) {
  return {
    name: option.Name,
    value: option.Value,
  };
}
export function countryListBuilderForUI(country) {
  return (
    (country && {
      name: country.Name,
      region: country.Region,
      countryId: country.CountryID,
      countryCode: country.CountryCode,
      callingCode: country.CallingCode,
    }) ||
    []
  );
}
