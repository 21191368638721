import { LOAN_AND_PIPELINE_STATUS_MAPPING } from 'Common/constants/loanAndPipelineStatusMapping';
import {
  contactEmailBuilderForUI,
  contactEmailBuilderForMyCRM,
  contactPhoneBuilderForUI,
  contactPhoneBuilderForMyCRM,
} from 'Common/mappers/contact';

export function helloBookClientBuilderForUI(client) {
  return (
    client && {
      familyId: client.FamilyId,
      address: client.Address,
      allocatedAdviserId: client.AllocatedAdviserID,
      allocatedAdviserName: client.AllocatedAdviserName,
      email: client.Email && client.Email.map(contactEmailBuilderForUI),
      firstName: client.FirstName,
      fullName: client.FullName,
      dropdownName: client.isPrimary
        ? `${client.FullName} (primary)`
        : client.FullName,
      gender: client.Gender,
      isExistingCustomer: client.IsExistingCustomer,
      lastName: client.LastName,
      personId: client.PersonId,
      phone: client.Phone && client.Phone.map(contactPhoneBuilderForUI),
      isEntity: client.isEntity,
      isPrimary: client.isPrimary,
      role: client.Role,
    }
  );
}

export function helloBookClientBuilderForMyCRM(client) {
  return (
    client && {
      SourceFamilyID: client.familyId,
      FamilyID: client.familyId,
      Address: client.address,
      AllocatedAdviserID: client.allocatedAdviserId,
      AllocatedAdviserName: client.allocatedAdviserName,
      Email: client.email && client.email.map(contactEmailBuilderForMyCRM),
      FirstName: client.firstName,
      FullName: client.fullName,
      Gender: client.gender,
      IsExistingCustomer: client.isExistingCustomer,
      LastName: client.lastName,
      PersonId: client.personId,
      Phone: client.phone && client.phone.map(contactPhoneBuilderForMyCRM),
      IsEntity: client.isEntity,
      IsPrimary: client.isPrimary,
    }
  );
}

export function helloBookSendBuilderForMyCRM(form) {
  return (
    form && {
      FactFind: {
        ...form.factFindSections,
        LoanApplicationID: form.selectedApplicationId || 0,
        FamilyId: form.client.familyId,
        ClientId: form.client.personId,
        ClientMessage: form.model.editorMessage,
      },
      FactFindType: form.model.factFindType,
      IsFactFindEnabled: form.model.sections.onlineFactFind,
      IsHelloBookEnabled: form.model.sections.helloBook,
      IsCreditEnabled: form.model.sections.creditGuide,
      IsCalendlyInviteEnabled: form.model.sections.calendly,
      Message: form.model.editorMessage,
      AdviserId: form.adviser.personId,
      SendType: form.sendType,
      ClientFamilyId: form.client.familyId,
      Email: form.model.email,
      Mobile: form.model.phone,
    }
  );
}

export function helloBookDefaultSettingsForUI(settings) {
  return (
    settings && {
      factFindType: settings.FactFindType.toLowerCase(),
      isFactFindEnabled: settings.IsFactFindEnabled,
      isHelloBookEnabled: settings.IsHelloBookEnabled,
      isCreditEnabled: settings.IsCreditEnabled,
      isCalendlyInviteEnabled: settings.IsCalendlyInviteEnabled,
      calendlyLink: settings.CalendlyLink,
      message: settings.Message,
      factFindSections: {
        yourhousehold: settings.YourHouseHold,
        yourhome: settings.YourHome,
        topurchase: settings.ToPurchase,
        otherproperties: settings.OtherProperties,
        assets: settings.Assets,
        liabilities: settings.Liabilities,
        income: settings.Income,
        expenses: settings.Expenses,
        yourdetails: settings.YourDetails,
        coapplicants: settings.CoApplicants,
        addresshistory: settings.AddressHistory,
        yourgoals: settings.YourGoals,
        employment: settings.Employment,
        forseeablechanges: settings.ForseeableChanges,
        credit: settings.Credit,
        supportingdocuments: settings.SupportingDocuments,
      },
    }
  );
}

export function helloBookDefaultSettingsForMyCRM(settings) {
  return (
    settings && {
      AdviserId: settings.adviserId,
      FactFindType: settings.factFindType,
      IsFactFindEnabled: settings.onlineFactFind,
      IsHelloBookEnabled: settings.helloBook,
      IsCreditEnabled: settings.creditGuide,
      IsCalendlyInviteEnabled: settings.calendly,
      Message: settings.message,
      FactFind: { ...settings.factFindSections },
    }
  );
}

export function helloBookFamilyLoanForUI(loan) {
  const mappedLoanStatus = LOAN_AND_PIPELINE_STATUS_MAPPING.find(
    status => status.PIPELINE_STATUS === loan.pipelineStatusId
  );
  const lender = {
    id: loan.lenderId,
    name: loan.lenderName,
    countryCode: loan.countryCode,
  };
  const status = (mappedLoanStatus && {
    name: mappedLoanStatus.NAME,
    loanStatusId: mappedLoanStatus.LOAN_STATUS,
    pipelineStatusId: mappedLoanStatus.PIPELINE_STATUS,
  }) || { name: '' };
  const applicants =
    (loan.clients && loan.clients.trim()) || loan.clientFamilyName;
  return {
    ...loan,
    applicants,
    lender,
    status,
  };
}
