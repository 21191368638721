// Override the feature management properties
const feature = {
  brokerPedia: 'brokerPedia',
  maxlvr: 'maxLVR',
  newLoanApplicationv2: 'newLoanApplicationV2',
  lmiCalculator: 'lMICalculator',
  fundingCalculatorEnhancement: 'fundingCalculatorQuickWinEnhancements',
  intercept: 'interceptBankConnectExpenseAnalysis',
};

export default feature;
