import template from './widgetGeneralHeader.html';
import controller from './widgetGeneralHeaderCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    mainTitle: '<',
    subTitle: '<',
    showSettings: '<',
    isClicked: '<',
    onSettingsClick: '&',
    onTitleClick: '&',
  },
};
