import { WEEKLY_ID } from 'Common/constants/insuranceApplicationPipeline';

export const editDataSetUtil = ({ $scope, items }) => () => {
  if (items.data.benefitId > 0) {
    $scope.formData.isEditing = true;
    const insDetails = items.data;
    if (insDetails.clientId) {
      $scope.selectedInsuredPerson = insDetails.clientId.toString();
    }
    $scope.insuranceDetailsInput = insDetails;
    $scope.isFrequencyWeekly = insDetails.paymentFrequency === WEEKLY_ID;

    return insDetails.benefitTypeId;
  }
};
