export const POSSIBLE_ADVERSE_CHANGES = {
  FIELD: {
    RADIOBOX_ON_YES: 12,
    TEXT_DROPDOWN: 9,
    YES_NO: 1,
  },
  TITLE: 'Possible Adverse Changes',
};

export const LIST_OF_QUESTIONS = {
  RETIREMENT_AGE: 172,
  LOAN_REPAYMENT: 209,
  LOAN_REPAYMENT_DETAILS: 174,
  APPLICANT_RETIRING: 175,
  APPLICANT_RETIRING_PROPOSED_PAYMENT: 176,
  ANTICIPATE_CHANGES: 177,
  CLIENT_CHANGES_APPLY: 183,
  ANTICIPATE_CHANGES_NATURE: 186,
  DESCRIPTION: 186,
  REPAYMENT_OTHER: 208,
};

export const MAIN_QUESTIONS = [
  LIST_OF_QUESTIONS.RETIREMENT_AGE,
  LIST_OF_QUESTIONS.APPLICANT_RETIRING,
  LIST_OF_QUESTIONS.ANTICIPATE_CHANGES,
];

export const DEFAULT_ANTICIPATE_FIELDS = {
  financialImpactOperator: '=',
  startDate: new Date(),
};
