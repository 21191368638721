export default class CrmConfirmationCtrl {
  ok() {
    this.cOnConfirm && this.cOnConfirm({ dontShowAgain: this.dontShowAgain });
    this.cModalInstance.close({ isOk: true });
  }
  cancel() {
    this.cModalInstance.dismiss();
  }
  customButtonAction() {
    this.cModalInstance.close({ isCustom: true });
  }
}
