export const reportingState = {
  ReportingStateId: -1,
  ReportingStateName: 'All States',
  ticked: true,
};
export const lender = {
  ProviderId: -1,
  ProviderName: 'All Lenders',
  ticked: true,
};
export const businessUnit = {
  BusinessUnitID: -1,
  BusinessUnitName: 'All Business Units',
  ticked: true,
};
export const pipelineStatus = {
  statusId: -1,
  statusName: 'All Pipeline Status',
  ticked: true,
};
export const dateRange = {
  dateRangeId: 0,
  dateRangeDesc: 'Custom Date',
};
export const referrerOrgDefault = {
  referrerOrgId: 0,
  referrerOrgName: 'All Referrer Orgs',
};
export const referrerDefault = {
  referrerId: 0,
  referrerName: 'All Referrers',
};
export const reportValue = {
  status: 'active',
  url: 'https://uat-reports.loanmarket.com',
  visualizeUrl: '/jasperserver-pro/client/visualize.js',
};
export const loanStatus = {
  loanStatusId: -1,
  loanDisplayName: 'All Loan Status',
  ticked: true,
};

export const lenderCategory = {
  order: 0,
  name: 'All Categories',
  value: -1,
  helpText: '',
};

export const referralType = {
  ReferralTypeID: -1,
  ReferralType: 'All Referral Type',
  ticked: true,
};

export const financialRange = {
  AU: {
    start: 6,
    end: 5,
  },
  NZ: {
    start: 5,
    end: 4,
  },
};
