export function convertToLowerCase(str) {
  return str ? str.toLowerCase() : '';
}

export function isAlphaNumeric(str, minlength, maxlength) {
  if (!str) return false;

  let checker;
  if (minlength && maxlength) {
    checker = new RegExp(`^[A-Za-z0-9]{${minlength},${maxlength}}$`);
  } else if (minlength && !maxlength) {
    checker = new RegExp(`^[A-Za-z0-9]{${minlength},}$`);
  } else if (maxlength && !minlength) {
    checker = new RegExp(`^[A-Za-z0-9]{0,${maxlength}}$`);
  } else {
    checker = new RegExp(/^[A-Za-z0-9]*$/);
  }
  return checker.test(str);
}

export function grammaticallySeparateByComma(strings) {
  if (!strings || !strings.length) return '';

  const arrayLength = strings.length;
  if (arrayLength === 1) return strings[0].trim();

  const lastIndex = arrayLength - 1;
  return strings.reduce((accum, currentStr, index) => {
    if (index === 0) {
      return currentStr.trim();
    } else if (index < lastIndex) {
      return `${accum}, ${currentStr.trim()}`;
    }
    return `${accum} & ${currentStr.trim()}`;
  }, '');
}

export function formatRawNewLine(str) {
  return (str && str.replace(/\\n/g, '\n')) || '';
}

export function isInIPHealthDataList(dataList) {
  return dataList && dataList.length;
}

export function removeSpacesOnString(str) {
  return (str && str.replace(/\s+/g, '')) || '';
}

export function lowercaseFirstLetter(string) {
  return string && string[0].toLowerCase() + string.slice(1);
}

export function capitalizeFirstLetter(string) {
  if (!string) return '';
  return string && string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizeText(string, delimiter = ' ') {
  if (!string) return '';

  const capString = string.split(delimiter).reduce((accum, currentText) => {
    return `${accum}${capitalizeFirstLetter(currentText)} `;
  }, '');
  return capString.trim();
}

export function camelize(str) {
  if (!str) return '';
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function getFirstLetter(str) {
  return (str && str.replace(/\W/g, '')[0]) || '';
}

export function isValidEmailAddress(str) {
  if (!str) return false;

  const checker = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return checker.test(str);
}

export function splitByCapitalLetters(str) {
  if (typeof str !== 'string') return '';

  return str.split(/(?=[A-Z])/).join(' ');
}

export function pluralize(count, noun, suffix = 's') {
  if (!noun) return '';
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}

export function splitString(commaSeparatedString) {
  if (typeof commaSeparatedString !== 'string' || !commaSeparatedString)
    return [];

  return commaSeparatedString.split(',').map(str => str.trim());
}

export function textLimit(text, charLimit) {
  if (!text) return '';

  if (text.length <= charLimit) return text;

  return `${text.substr(0, charLimit)}...`;
}

export function hasReadMore(text, charLimit) {
  const textTrimmed = textLimit(text, charLimit);
  return textTrimmed
    ? textTrimmed.endsWith('...') && text.length > charLimit
    : false;
}

export function convertTextToKey(text) {
  if (!text) return '';
  return text.replace(/([.,-;:]|\s+)/g, '');
}

export function getLastStringCharacters(word, charLength) {
  return word.substr(word.length - charLength, word.length);
}
export const getTrustedHtml = ({ scope, sce }) => {
  if (!scope || !scope.emailDetail || !sce || !sce.trustAsHtml) return;
  const { IframeContent: iframeContent } = scope.emailDetail;
  scope.emailDetail.iframeContent = sce.trustAsHtml(iframeContent);
};
