import { insuranceClientInfoGetForUI } from 'Common/mappers/insuranceFireAndGeneral';
import { toastError } from 'Common/utilities/alert';
import moment from 'moment';
import {
  NZ_DATE_INPUT_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';
import { DEFERRED_STATUS_ID } from 'Common/constants/insuranceApplicationPipeline';
import { REQUIRED_FIELD } from './constants';

export default class InsuranceFireAndGeneralModalCtrl {
  constructor($state, contactService, insuranceFireAndGeneralService) {
    'ngInject';

    this.$state = $state;
    this.insuranceFireAndGeneralService = insuranceFireAndGeneralService;
    this.contactService = contactService;
    this.form = {
      overview: true,
      details: false,
      notes: false,
    };

    this.getInsuranceDetails = {};
    this.getBenefitNotes = {};
    this.getOveriew = {};
    this.isSaving = false;
    this.populating = false;
  }

  $onInit() {
    this.loadClientInfo();
    this.invalidDateText = 'Invalid date';
    this.DEFERRED_STATUS_ID = DEFERRED_STATUS_ID.V1;
  }

  loadClientInfo() {
    const familyId = this.$state.params.familyId;
    this.contactService.clientInformGet(familyId).then(({ data }) => {
      this.clientInfos = (data && data.map(insuranceClientInfoGetForUI)) || [];
      if (this.insuranceData) {
        this.loadInsuranceDetails();
      } else {
        this.populating = true;
      }
    });
  }

  loadInsuranceDetails() {
    const familyId = this.$state.params.familyId;
    this.insuranceFireAndGeneralService
      .insuranceDetailsGet(familyId, this.insuranceData.BenefitID)
      .then(({ clientBenefits, insuranceDetails }) => {
        const { fireAndGeneralCover } = clientBenefits || {};
        this.fireAndGeneralDetails = {
          ...fireAndGeneralCover,
          ...insuranceDetails,
        };
        this.populating = true;
      });
  }

  checkDate() {
    const dateChecker = {
      countInvalid: 0,
      message: 'Commencement Date is invalid.',
    };

    const formattedDate = moment(
      this.fireAndGeneralInputs.tempCommencementDate,
      NZ_DATE_INPUT_FORMAT
    ).format(TO_MYCRM_DATE_FORMAT);

    if (formattedDate === this.invalidDateText) {
      dateChecker.countInvalid++;
      dateChecker.message = 'Commencement Date is invalid.';
    }

    const deferralReviewDate = moment(
      this.fireAndGeneralInputs.tempDeferralReviewDate,
      NZ_DATE_INPUT_FORMAT
    ).format(TO_MYCRM_DATE_FORMAT);

    if (
      this.fireAndGeneralInputs.statusId === this.DEFERRED_STATUS_ID &&
      deferralReviewDate === this.invalidDateText
    ) {
      dateChecker.countInvalid++;
      dateChecker.message = 'Deferral Review Date is invalid.';
    }

    return dateChecker;
  }

  saveFireAndGeneral() {
    const { BenefitID = 0 } = this.insuranceData || {};
    this.fireAndGeneralInputs = {
      ...this.getInsuranceDetails,
      ...this.getBenefitNotes,
      ...this.getOveriew,
      benefitId: BenefitID,
      familyId: this.$state.params.familyId,
    };

    this.validInput = true;
    REQUIRED_FIELD.forEach(forms => {
      const isValid = !!this.fireAndGeneralInputs[forms.field];
      if (!isValid) {
        toastError(`${forms.name} is required.`);
        this.validInput = isValid;
      }
    });

    const dateChecker = this.checkDate();
    if (dateChecker.countInvalid) {
      this.isSaving = false;
      toastError(dateChecker.message);
      return;
    }

    if (this.validInput) {
      this.isSaving = this.validInput;
      this.insuranceFireAndGeneralService
        .insuranceDetailsSet(this.fireAndGeneralInputs)
        .then(({ status }) => {
          this.isSaving = !this.isSaving;
          this.modalInstance.close(status);
        });
    }
  }

  getInsuranceDetailsForm(formData) {
    this.getInsuranceDetails = formData;
  }

  getoverViewForm(formData) {
    this.getOveriew = formData;
  }

  getBenefitsNotesForm(formData) {
    this.getBenefitNotes = formData;
  }

  switchForm(formKey) {
    Object.keys(this.form).forEach(forms => {
      this.form[forms] = formKey === forms;
    });
  }

  closeFireGeneralModal() {
    this.modalInstance.dismiss();
  }
}
