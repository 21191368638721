export default class OnlineFactFindService {
  constructor(loanScenarioService) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
  }

  toggleLockStatus(app, callback) {
    const { LoanScenarioID, LoanScenarioId } = app;
    const loanScenarioId = LoanScenarioID || LoanScenarioId;
    app.IsLocked = !app.IsLocked;
    this.loanScenarioService
      .loanApplicationLockStatus(loanScenarioId, app.IsLocked)
      .then(response => callback && callback(response));
  }
}
