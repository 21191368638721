import feature from 'Common/config/feature';
import { camelize } from './string';

const featuresList = {};

const composeFeature = value => ({
  map: func => composeFeature(func(value)),
  output: () => value,
});

const constructFeatureList = (features = []) => {
  return features.reduce((accum, featureObj) => {
    accum[`${camelize(featureObj.Name)}`] = featureObj.IsEnabled;
    return accum;
  }, {});
};

const constructOverrideFeature = features => {
  const featureKey = Object.keys(feature);
  return featureKey.reduce((accum, value) => {
    accum[value] = features[feature[value]];
    return accum;
  }, {});
};

function featureObjectConstruct(features) {
  return {
    ...features,
    ...constructOverrideFeature(features),
  };
}

export function getFeaturesData() {
  return featuresList;
}

export function setFeaturesData(features) {
  const featureListData = composeFeature(features)
    .map(constructFeatureList)
    .map(featureObjectConstruct)
    .output();
  Object.assign(featuresList, featureListData);
}

export function setFeatureFlag(scope, config, variableName, flagName) {
  const isIncompleteParams =
    !scope || !config || !variableName || !flagName || !config.feature;
  if (isIncompleteParams) return;
  scope[variableName] = !!config.feature[flagName];
  return scope;
}
