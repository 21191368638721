import angular from 'angular';
import _ from 'lodash';
import { INSURANCE_LOAN_APP } from 'Common/constants/insuranceOptions';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { excludedInsuranceQuestions } from 'Common/config/loanAppQuestions';
import { getInsuranceTitleByCountryName } from './../util/clientAccess/insurance';

angular
  .module('app')
  .controller('LoanAppInsuranceCtrl', function LoanAppInsuranceCtrl(
    $scope,
    $state,
    $timeout,
    $stateParams,
    loanScenarioService,
    loanApplicationServices,
    optionsService,
    watcherService,
    timeoutService,
    loanAppSharedData,
    configService,
    loanAppDetailsService,
    currentUserService
  ) {
    // Property
    let clickCounter = 0;

    angular.extend($scope, {
      loanAppId: $stateParams.loanAppId,
      familyId: $stateParams.familyId,
      generalInsuranceOption: [],
      lifestyleInsuranceOption: [],
      riskInsuranceOption: [],
      insuranceQuestionList: [],
      insuranceObjSet: {},
      editExecCount: 0,
      uiSettings: {
        isTouched: false,
      },
      questions: INSURANCE_LOAN_APP.QUESTIONS,
    });

    $scope.excludedQuestions = excludedInsuranceQuestions;
    $scope.insuranceSectionTitle = getInsuranceTitleByCountryName(
      currentUserService.isAU
    );

    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    /* end of listeners */
    loanApplicationServices
      .loanSummaryGet($state.params.loanAppId)
      .then(res => {
        const { data: summaryData } = res;
        if (summaryData) {
          const { LoanDetails: loanDetails } = summaryData;
          const { LendingCategoryId: lendingCategoryId } = loanDetails;
          $scope.isAssetFinanceOrPersonal =
            lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
            lendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN;
        }
        $scope.processInsuranceSection = () => {
          if (clickCounter !== 0) return;

          clickCounter++;

          // Method
          angular.extend($scope, {
            init() {
              const isAssetFinanceFeatureOn =
                configService.feature.assetFinance;
              const { LoanDetails } = loanAppSharedData.loanAppSummary;
              const isAssetFinance =
                LoanDetails &&
                (LoanDetails.LendingCategoryId ===
                  LENDING_CATEGORY.ASSET_FINANCE ||
                  LoanDetails.LendingCategoryId ===
                    LENDING_CATEGORY.PERSONAL_LOAN);
              $scope.showAssetInsuranceDetails =
                isAssetFinanceFeatureOn && isAssetFinance;

              loanAppSharedData.insurance.section.excludeIds.push(
                INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
              );

              optionsService.GeneralInsuranceGet().then(response => {
                $scope.generalInsuranceOption = response.data;
              });
              optionsService.LifestyleInsuranceGet().then(response => {
                $scope.lifestyleInsuranceOption = response.data;
              });
              optionsService.RiskInsuranceGet().then(response => {
                $scope.riskInsuranceOption = response.data;
              });
              optionsService.question('insurance').then(response => {
                const { data } = response;
                if (!data || !data.length) return;
                const insuranceOrder = [
                  INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE,
                  INSURANCE_LOAN_APP.QUESTIONS.GENERAL_INSURANCE,
                  INSURANCE_LOAN_APP.QUESTIONS.CONCIERGE,
                  INSURANCE_LOAN_APP.QUESTIONS.HOMENOW,
                  INSURANCE_LOAN_APP.QUESTIONS.COMMENTS,
                ];
                const mappedQuestions = data.map(question => {
                  const questionOrder = insuranceOrder.indexOf(
                    question.QuestionId
                  );
                  question.forOrdering =
                    questionOrder !== -1
                      ? questionOrder
                      : insuranceOrder.length;
                  return question;
                });

                $scope.insuranceQuestionList =
                  mappedQuestions &&
                  mappedQuestions.filter(question => {
                    return (
                      question.QuestionId !==
                        INSURANCE_LOAN_APP.QUESTIONS.HOMENOW ||
                      (question.QuestionId ===
                        INSURANCE_LOAN_APP.QUESTIONS.HOMENOW &&
                        loanAppDetailsService.isHomeNowEnabled())
                    );
                  });
                $scope.orderByInsurance = 'forOrdering';
              });
              $scope.getInsuranceQuestionValue();

              $scope.$on('$destroy', () => {
                watcherService.destroyWatchers(
                  WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL
                );
                timeoutService.cancelTimeouts(
                  WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL
                );
              });
            },

            getInsuranceQuestionValue() {
              loanScenarioService
                .loanInsuranceGet($scope.loanAppId)
                .then(response => {
                  _.forEach(response.data, obj => {
                    let answerValue = null;
                    switch (true) {
                      case typeof obj.answerInt !== 'undefined':
                        answerValue = String(
                          obj.answerInt.length ? obj.answerInt[0] : ''
                        );
                        break;
                      case typeof obj.answerString !== 'undefined':
                        answerValue = obj.answerString.length
                          ? obj.answerString[0]
                          : '';
                        break;
                      case typeof obj.answerBool !== 'undefined':
                        answerValue = obj.answerBool.length
                          ? obj.answerBool[0]
                          : false;
                        break;
                      default:
                        break;
                    }
                    if (obj.QuestionId === $scope.questions.HOMENOW) {
                      $scope.insuranceObjSet[obj.QuestionId] = {};
                      $scope.insuranceObjSet[obj.QuestionId].checkbox =
                        obj.answerBool && obj.answerBool.length
                          ? obj.answerBool[0]
                          : false;
                      $scope.insuranceObjSet[obj.QuestionId].textbox =
                        $scope.insuranceObjSet[obj.QuestionId].checkbox &&
                        obj.answerString &&
                        obj.answerString.length
                          ? obj.answerString[obj.answerString.length - 1] || ''
                          : '';
                    } else {
                      $scope.insuranceObjSet[obj.QuestionId] = answerValue;
                    }
                  });
                });
            },

            recheckRequiredFields() {
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                o => o === INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
              );
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                o =>
                  o ===
                  INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER
              );
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                o => o === INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP
              );
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                o => o === INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
              );
              if (
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
                ]
              ) {
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                ] = false;
                loanAppSharedData.insurance.section.excludeIds.push(
                  INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                );
              } else {
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER
                ] = false;
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP
                ] = false;
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
                ] = false;
                loanAppSharedData.insurance.section.excludeIds.push(
                  INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER,
                  INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP,
                  INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
                );
              }
            },

            resetLifestyleQuestion() {
              const options = [
                INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE,
                INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER,
                INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP,
                INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER,
              ];
              _.each(options, o => {
                const insuranceSet = {
                  LoanScenarioId: $scope.loanAppId,
                  FamilyId: $stateParams.familyId,
                  QuestionId: o,
                  DisplayOptionId:
                    o !== INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                      ? INSURANCE_LOAN_APP.FIELD.RADIOBOX_ON_YES
                      : INSURANCE_LOAN_APP.FIELD.RADIOBOX_ON_NO,
                  answerBool: [false],
                };
                loanScenarioService.loanInsuranceSet(insuranceSet);
              });
            },

            updateInsuranceQuestion(
              questionId,
              displayType,
              displayTypeId,
              otherArgs
            ) {
              if (
                questionId === INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
              ) {
                $scope.resetLifestyleQuestion();
                $scope.recheckRequiredFields();
              }

              // if text is empty will return to avoid undefined
              if (
                typeof $scope.insuranceObjSet[questionId] === 'undefined' &&
                !otherArgs
              ) {
                return false;
              }
              $scope.toggleTouch();
              let insuranceSet = {};
              if (!otherArgs) {
                insuranceSet = {
                  LoanScenarioId: $scope.loanAppId,
                  FamilyId: $stateParams.familyId,
                  QuestionId: questionId,
                  DisplayOptionId: displayTypeId,
                };

                switch (displayType) {
                  case 'CheckBox':
                  case 'RadioButton':
                  case 'YesNoOnly':
                  case 'RadioBoxOnYes':
                  case 'RadioBoxOnNo':
                    insuranceSet.answerBool = [
                      $scope.insuranceObjSet[questionId],
                    ];
                    break;
                  case 'TextboxOnNo':
                  case 'TextboxOnYes':
                  case 'TextOnly':
                    insuranceSet.answerString = [
                      String($scope.insuranceObjSet[questionId]),
                    ];
                    break;
                  case 'MultiDropDown':
                  case 'SingleDropDown':
                  case 'SingleDropDownNoFrame':
                    insuranceSet.answerInt = [
                      parseInt($scope.insuranceObjSet[questionId], 10),
                    ];
                    break;
                  case 'TextBoxOnChecked': {
                    const checkboxValue =
                      ($scope.insuranceObjSet[questionId] &&
                        $scope.insuranceObjSet[questionId].checkbox) ||
                      false;
                    const textboxValue =
                      ($scope.insuranceObjSet[questionId] &&
                        $scope.insuranceObjSet[questionId].textbox) ||
                      '';
                    insuranceSet.answerBool = [checkboxValue];
                    insuranceSet.answerString = checkboxValue
                      ? [textboxValue]
                      : [''];
                    break;
                  }
                  default:
                    break;
                }
                loanScenarioService.loanInsuranceSet(insuranceSet);
              } else if (
                parseInt(otherArgs.mainObj.QuestionId, 10) ===
                INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
              ) {
                let subQuestions = angular.copy(otherArgs.mainObj.SubQuestion);
                const filterSubQuestions = o =>
                  o.QuestionDisplayType === 'RadioBoxOnYes' ||
                  o.QuestionDisplayType === 'RadioBoxOnNo';
                subQuestions = _.filter(subQuestions, filterSubQuestions);

                _.forEach(subQuestions, o => {
                  if (
                    o.QuestionDisplayType === 'RadioBoxOnYes' ||
                    o.QuestionDisplayType === 'RadioBoxOnNo'
                  ) {
                    // uncheck the rest
                    $scope.insuranceObjSet[o.QuestionId] =
                      parseInt(questionId, 10) === parseInt(o.QuestionId, 10);

                    insuranceSet = {
                      LoanScenarioId: $scope.loanAppId,
                      FamilyId: $stateParams.familyId,
                      QuestionId: o.QuestionId,
                      DisplayOptionId: o.QuestionDisplayTypeId,
                      answerBool: [
                        parseInt(questionId, 10) === parseInt(o.QuestionId, 10),
                      ],
                    };
                    loanScenarioService.loanInsuranceSet(insuranceSet);
                  }
                });
              }
              $scope.validateInsurance();
            },

            validateInsurance() {
              let validDeclarationProp = false;
              let countDeclarationPop = 0;

              _.each($scope.insuranceObjSet, (val, key) => {
                const touchedLifestyle =
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
                  ] ||
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
                  ] === false;
                if (touchedLifestyle) {
                  validDeclarationProp =
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                    ] ||
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS
                        .WEALTH_MARKET_FINANCIAL_ADVISER
                    ] ||
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP
                    ] ||
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
                    ];
                }

                if (val) validDeclarationProp = true;

                if (
                  validDeclarationProp &&
                  !loanAppSharedData.insurance.section.excludeIds.includes(
                    parseInt(key, 10)
                  )
                ) {
                  countDeclarationPop++;
                }
              });
              $scope.isStartedInsurance = countDeclarationPop >= 1;
              $scope.isValidInsurance =
                validDeclarationProp && countDeclarationPop >= 2;

              const sectionObject = loanAppSharedData.getSectionObjectById(
                loanAppSharedData.completionListObject,
                SECTION_ID.INSURANCE
              );
              loanAppSharedData.completionStatusSet(
                SECTION_ID.INSURANCE,
                sectionObject.IsCompleted,
                $scope.isValidInsurance
              );

              $scope.countValids($scope.isValidInsurance, 'insurance');
            },

            toggleTouch() {
              $scope.uiSettings.isTouched = true;
            },

            incEditCount() {
              const incEditCountTimeout = $timeout(() => {
                if ($scope.isValidInsurance) {
                  $scope.editExecCount++;
                }
              }, 200);
              timeoutService.addTimeout(
                incEditCountTimeout,
                WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL
              );
            },

            checkHasAnswer: (hasAnswer, questionId) => {
              if (
                parseInt(questionId, 10) ===
                INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
              ) {
                const discussLifestyle =
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER
                  ] ||
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP
                  ] ||
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
                  ];
                const isValid =
                  (hasAnswer && discussLifestyle) ||
                  (!hasAnswer &&
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                    ]);
                return isValid;
              }
              if (questionId === INSURANCE_LOAN_APP.QUESTIONS.HOMENOW) {
                return hasAnswer && hasAnswer.checkbox;
              }
              return hasAnswer;
            },
          });

          $scope.init();
        };

        const sectionObject = loanAppSharedData.getSectionObjectById(
          loanAppSharedData.completionListObject,
          SECTION_ID.INSURANCE
        );
        $scope.countValids(sectionObject.IsCompleted, 'insurance');

        if (typeof sectionObject.IsCompleted === 'undefined') {
          $scope.processInsuranceSection();
        } else {
          $scope.isValidInsurance = sectionObject.IsCompleted;
          $scope.isStartedInsurance = sectionObject.IsCompleted;
        }
      });
  });
