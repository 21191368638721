import _ from 'lodash';
import { toastSaveSuccess } from 'Common/utilities/alert';
import { postCompletionBuilderForMyCRM } from 'Common/mappers/customerCare';
import { FIRE_GENERAL_ID } from 'Common/constants/customerCareModules';

export default class CurrentClaimsCtrl {
  constructor(NgTableParams, customerCareInsuranceService, currentUserService) {
    'ngInject';

    this.NgTableParams = NgTableParams;
    this.userInfo = currentUserService;
    this.toastSaveSuccess = toastSaveSuccess;
    this.customerCareInsuranceService = customerCareInsuranceService;
    this.FIRE_GENERAL_ID = FIRE_GENERAL_ID;
  }

  $onInit() {
    this.isLoading = false;
    this.isFromCompleted = false;
    this.totalRecords = 0;
    this.currentPage = 1;
    this.tableData = [];

    this.defaultTableSettings = {
      page: 1,
      count: 10,
      sorting: {
        CommencementDate: 'asc',
      },
    };

    this.loadTableData();
  }

  $onChanges(changes) {
    if (changes.filterUpdateCount) {
      this.currentPage = 1;
      if (this.defaultTableSettings) this.defaultTableSettings.page = 1;
      if (this.tableParams) this.tableParams.reload();
    }
  }

  onActionSelected($event) {
    this.onSendModal({ $event });
  }

  gotoContact(familyId) {
    this.viewContact({ $event: { familyId } });
  }

  setCompletion(item) {
    const postData = [postCompletionBuilderForMyCRM(item, this.tab.type)];
    this.customerCareInsuranceService.postCompletion(postData).then(() => {
      this.toastSaveSuccess();
      this.isFromCompleted = true;
      this.tableParams.reload();
    });
  }

  onPageChange(event) {
    this.defaultTableSettings.page = event.$e.pageNumber;
    this.currentPage = event.$e.pageNumber;
    this.isFromCompleted = false;
    this.tableParams.reload();
  }

  loadTableData() {
    const tableCtrl = this;
    this.tableParams = new this.NgTableParams(tableCtrl.defaultTableSettings, {
      counts: [],
      total: 0,
      getData($defer, params) {
        const sortBy = params.orderBy()[0].substring(1);
        const sortOrder = _.values(params.sorting())[0].toUpperCase();
        if (!tableCtrl.isFromCompleted) tableCtrl.isLoading = true;

        const newFilter = {
          ...tableCtrl.filterData,
          sortBy,
          sortOrder,
          pageNumber: tableCtrl.currentPage,
        };

        tableCtrl.customerCareInsuranceService
          .getCurrentClaimList(newFilter)
          .then(response => {
            tableCtrl.isLoading = false;
            tableCtrl.tableData = response;
            tableCtrl.tableData.map(item => {
              item.isComplete = newFilter.isCompleted;
              return item;
            });
            tableCtrl.totalRecords =
              tableCtrl.tableData.length && tableCtrl.tableData[0].totalCount;
            tableCtrl.onTableDataLoaded({
              $event: { tableData: tableCtrl.tableData },
            });

            if (tableCtrl.totalRecords) params.total(tableCtrl.totalRecords);
            else params.total(0);
            $defer.resolve(tableCtrl.tableData);
          });
      },
    });
  }
}
