import { CONTENT_TYPE } from 'Common/constants/contentType';

export function getFilenameExtension(contentType) {
  if (typeof contentType !== 'string') return '';
  switch (contentType) {
    case CONTENT_TYPE.EXCEL:
      return '.xls';
    case CONTENT_TYPE.SPREAD_SHEET:
      return '.xlsx';
    default:
      return '';
  }
}
