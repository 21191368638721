import { validCustomAPIResponse } from '../utilities/response';

export function responseBuilderForUI(response) {
  if (!response || (!response.data && response.data !== 0))
    return { ...response, data: null };
  if (validCustomAPIResponse(response)) {
    return {
      ...response,
      data: response.data.Data,
      messages: response.data.Messages,
      succeeded: response.data.Succeeded,
      message: response.data.Message,
    };
  }
  return response;
}
