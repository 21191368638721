export function creditCheckPdfReportBuilderForUI(data) {
  return {
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    documentKey: data.DocumentKey,
    imageUploadType: data.ImageUploadType,
    isSvg: data.IsSvg,
    name: data.Name,
  };
}
