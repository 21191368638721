import angular from 'angular';
import { MODAL_SECTION } from 'Common/constants/generateReports';

angular
  .module('app')
  .controller('RecommendationReportCtrl', function RecommendationReportCtrl(
    $rootScope,
    $scope,
    $uibModal,
    uiService,
    loanAppSharedData
  ) {
    const activeModalSection = uiService.isCountry('New Zealand')
      ? MODAL_SECTION.CPA
      : MODAL_SECTION.OPTION_WINDOW;
    const openRecommendationModal = () => {
      if (!loanAppSharedData.adviserSubscription) return;

      const multiLoanCpaIds = null;
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/loanApplication/sidebar/modal/recommendationReport.html',
        controller: 'RecommendationModalCtrl',
        windowClass: 'mc-recommendation-report-modal',
        size: 'md',
        scope: $scope,
        resolve: {
          activeModalSection: () => activeModalSection,
          multiLoanCpaIds: () => multiLoanCpaIds,
        },
      });
      modalInstance.result.then(response => {
        if (!response || !response.closeAll) return;
        $rootScope.$broadcast('eSignPackageSent', response.closeAll);
      });
    };

    const isUserNZ = () => {
      return uiService.isCountry('New Zealand');
    };
    angular.extend($scope, {
      openRecommendationModal,
      isUserNZ,
    });
  });
