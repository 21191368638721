import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import {
  isCancelled,
  getModalName,
  videoNotFoundAlert,
} from 'Common/utilities/subscription';
import { objectLength } from 'Common/utilities/objectValidation';
import { E_SIGN_ACOUNT_TYPE } from 'Common/constants/eSign';

class UsersSubscriptionComponentCtrl {
  constructor(
    contactService,
    $uibModal,
    configService,
    usersSubscriptionService,
    eSignService,
    crmConfirmation,
    accountService,
    currentUserService
  ) {
    'ngInject';

    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.subscriptionType = SUBSCRIPTIONTYPE;
    this.configService = configService;
    this.usersSubscriptionService = usersSubscriptionService;
    this.isCancelledWithActiveDate = isCancelled;
    this.videoNotFoundAlert = videoNotFoundAlert;
    this.eSignService = eSignService;
    this.crmConfirmation = crmConfirmation;
    this.accountService = accountService;
    this.currentUserService = currentUserService;
  }
  $onInit() {
    this.checkUserSubsLength = 0;
    this.userSubscriptions = [];
    this.accountService.refreshConfigFeature();
    const {
      brokerPedia: featureBrokerPedia,
      creditChecks: featureCreditChecks,
      priceFinder: featurePriceFinder,
      marketingAutomation: featureMarketingAutomation,
      esign: featureEsign,
      bankConnectExpensesAnalysis: featureBankConnect,
      nurture: featureNurture,
      esignSignature,
      newSubscriptionModal,
    } = this.configService.feature;
    this.showEsignSignature = esignSignature;
    this.showUpdatedSubscriptions = featureMarketingAutomation || false;
    this.showNewSubscriptionModal = newSubscriptionModal || false;
    const {
      BROKERPEDIA,
      CREDIT_CHECK,
      PRICE_FINDER,
      MARKETING_AUTOMATION,
      E_SIGN,
      BANK_CONNECT_EXPENSES_ANALYSIS,
      BANK_CONNECT,
      NURTURE,
    } = this.subscriptionType;
    const { isAU } = this.currentUserService;
    const brokerPedia = featureBrokerPedia && BROKERPEDIA;
    const creditCheck = featureCreditChecks && CREDIT_CHECK;
    const eSign = featureEsign && E_SIGN;
    const priceFinder = featurePriceFinder && PRICE_FINDER;
    const marketingAutomation =
      featureMarketingAutomation && MARKETING_AUTOMATION;
    const bankConnect = isAU && featureBankConnect && BANK_CONNECT;
    const bankConnectExpensesAnalysis =
      isAU && featureBankConnect && BANK_CONNECT_EXPENSES_ANALYSIS;
    const nurture = isAU && featureNurture && NURTURE;
    this.subscriptionItems = [
      brokerPedia,
      creditCheck,
      priceFinder,
      marketingAutomation,
      eSign,
      bankConnectExpensesAnalysis,
      bankConnect,
      nurture,
    ];
    this.interceptModalItems = [bankConnectExpensesAnalysis, bankConnect];
  }
  $onChanges(changes) {
    const { adviserInfo, orderedById } = changes;
    this.adviserInfo = adviserInfo && adviserInfo.currentValue;
    this.orderedById =
      (orderedById && orderedById.currentValue) || this.orderedById;
    if (!this.adviserInfo || !this.adviserInfo.FamilyId) return;
    this.familyId = this.adviserInfo.FamilyId;
    this.getSubscription();
  }

  checkTypeValid(subscriptionProductId) {
    let subscriptionTypeValid = null;
    if (!this.interceptModal) {
      subscriptionTypeValid =
        this.subscriptionItems.indexOf(subscriptionProductId) !== -1;
    } else {
      subscriptionTypeValid =
        this.interceptModalItems.indexOf(subscriptionProductId) !== -1;
    }
    return subscriptionTypeValid;
  }

  getSubscription() {
    this.userSubscriptions = [];
    if (this.familyId) {
      this.contactService.subscriptionInfo(this.familyId).then(response => {
        if (!response || !response.length) return;
        this.userSubscriptions = response.reduce((initial, currentObj) => {
          const { subscriptionProductId } = currentObj;
          if (this.checkTypeValid(subscriptionProductId)) {
            initial.push(currentObj);
          }
          return initial;
        }, []);
        const brokerPedia = this.userSubscriptions.find(
          obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.BROKERPEDIA
        );
        const priceFinder = this.userSubscriptions.find(
          obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.PRICE_FINDER
        );
        this.usersSubscriptionService.setBrokerPediaData(brokerPedia);
        this.usersSubscriptionService.setPriceFinderData(priceFinder);
      });
    }
  }

  openESignModal() {
    const props = {
      familyId: this.familyId,
    };
    this.$uibModal.open({
      template: `<e-sign-upload-signature-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId">
                </e-sign-upload-signature-modal>`,
      size: 'md',
      backdrop: 'static',
      windowClass: 'mc-e-sign-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
  }

  openAutoMarketingModal(props) {
    const modalInstance = this.$uibModal.open({
      template: `<subscription-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData"
                  ordered-by-id="vm.props.orderedById">
                </subscription-modal>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'mc-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(response => {
      if (response && response.getAllSubscribe) {
        !this.interceptModal && this.getSubscription();
        this.interceptModal && this.openFactFindModal();
      }
    });
  }

  isValidForNewModal(subscriptionProductId) {
    if (this.showNewSubscriptionModal) {
      return this.subscriptionItems.indexOf(subscriptionProductId) !== -1;
    }
    return !!(
      subscriptionProductId === this.subscriptionType.MARKETING_AUTOMATION ||
      subscriptionProductId ===
        this.subscriptionType.BANK_CONNECT_EXPENSES_ANALYSIS ||
      subscriptionProductId === this.subscriptionType.BANK_CONNECT ||
      subscriptionProductId === this.subscriptionType.NURTURE
    );
  }

  openSubscribeModal(subs) {
    const { subscriptionProductId } = subs;
    if (!subscriptionProductId) return;
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) return;
    const isNewSubscriptionModal = this.isValidForNewModal(
      subscriptionProductId
    );
    if (isNewSubscriptionModal) {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      this.openAutoMarketingModal(props);
    } else {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      const componentName = getModalName(subscriptionProductId);
      if (!componentName) return;
      const modalInstance = this.$uibModal.open({
        template: `<${componentName}
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId"
                    subscription-data="vm.props.subscriptionData"
                    ordered-by-id="vm.props.orderedById">
                  </${componentName}>`,
        size: 'lg',
        backdrop: 'static',
        windowClass: 'mc-subscription-modal',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props,
        },
      });
      modalInstance.result.then(response => {
        if (!response) return;
        this.getSubscription();
        const validToCheckSignature =
          this.showEsignSignature &&
          subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN;
        if (validToCheckSignature) {
          this.openESignModal();
          return;
        }
        this.esignAccountCheck(subscriptionProductId);
      });
    }
  }

  esignAccountCheck(subscriptionProductId) {
    if (subscriptionProductId !== SUBSCRIPTIONTYPE.E_SIGN || !this.familyId)
      return;
    this.eSignService.getAccountCheck(this.familyId).then(response => {
      if (response && response.status === E_SIGN_ACOUNT_TYPE.INVITED) {
        this.crmConfirmation.open({
          type: 'success',
          title: 'Subscription Successful',
          description: `Your eSign account has now been created, please confirm your email address`,
          buttonText: 'Okay, got it!',
          modalSize: 'md',
        });
      }
    });
  }

  cancelSubscription(subs) {
    const { subscriptionProductId, isTrial } = subs;
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) return;
    const isCancelOptionsModal = this.isValidForNewModal(subscriptionProductId);
    if (isCancelOptionsModal && !isTrial) {
      this.cancelCampaignSubscription(subs);
      return;
    }
    subs.isImmediately = isTrial;
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-modal>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'cancel-subscription',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(response => {
      if (!response || !response.getSubscription) return;
      this.getSubscription();
    });
  }

  cancelCampaignSubscription(subs) {
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-now-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-now-modal>`,
      backdrop: 'static',
      windowClass: 'cancel-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(() => {
      this.getSubscription();
    });
  }
  isInActiveSubscription(subscripton) {
    return (
      this.subscriptionEnabled &&
      !subscripton.isActive &&
      !this.isCancelledWithActiveDate(subscripton)
    );
  }
  isCancelledSubscription(subscripton) {
    return (
      this.subscriptionEnabled && this.isCancelledWithActiveDate(subscripton)
    );
  }
  isActiveProduct(subscription, product) {
    return (
      this.subscriptionEnabled &&
      subscription.isActive &&
      subscription.subscriptionProductId === product
    );
  }
  updateSubscription(subscription) {
    const marketingAutomation = this.isActiveProduct(
      subscription,
      this.subscriptionType.MARKETING_AUTOMATION
    );

    if (this.showModifyEsignSubscription(subscription)) {
      this.openESignModal();
    } else if (marketingAutomation) {
      this.openSubscribeModal(subscription);
    }
  }
  isShowUpdateSubscription(subscription) {
    const marketingAutomation = this.isActiveProduct(
      subscription,
      this.subscriptionType.MARKETING_AUTOMATION
    );
    return (
      this.showUpdatedSubscriptions &&
      (this.showModifyEsignSubscription(subscription) || marketingAutomation)
    );
  }
  isShowTrialBanner(subscripton) {
    return (
      this.showUpdatedSubscriptions &&
      !subscripton.isTrial &&
      !subscripton.isActive &&
      subscripton.hasTrial &&
      !this.isCancelledSubscription(subscripton)
    );
  }
  subscriptionLearnMore(subscription) {
    if (this.showUpdatedSubscriptions) {
      this.openSubscribeModal(subscription);
    } else {
      this.videoNotFoundAlert();
    }
  }
  showModifyEsignSubscription(subscripton) {
    if (!subscripton || !objectLength(subscripton)) return false;
    return (
      this.showEsignSignature &&
      (this.isActiveSubscription(subscripton) ||
        this.isCancelledSubscription(subscripton)) &&
      subscripton.subscriptionProductId === this.subscriptionType.E_SIGN
    );
  }
  isActiveSubscription(subscription) {
    return (
      this.subscriptionEnabled && subscription.isActive && !subscription.isTrial
    );
  }
  isTrialSubscription(subscription) {
    return (
      this.subscriptionEnabled && subscription.isTrial && subscription.isActive
    );
  }
}

export default UsersSubscriptionComponentCtrl;
