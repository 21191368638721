export default class LoanCalculatorLinking {
  constructor(
    fundingCalculatorService,
    loanProfilerService,
    $localStorage,
    $state,
    contactService
  ) {
    'ngInject';

    this.fundingCalculatorService = fundingCalculatorService;
    this.loanProfilerService = loanProfilerService;
    this.contactService = contactService;
    this.$localStorage = $localStorage;
    this.$state = $state;

    this.LINKERS = {
      CONTACT: 1,
      OPPORTUNITY: 2,
    };
    /*
      @prop linkerId {int}
      @prop id {id}
    */
    if (!this.$localStorage.currentCalculatorSourceLinker) {
      this.resetLinker();
    } else {
      this.storeLinker(this.$localStorage.currentCalculatorSourceLinker);
    }
  }

  storeLinker(linker) {
    this.$localStorage.currentCalculatorSourceLinker = linker
      ? { ...linker }
      : null;
    this.currentSourceLinker = { ...linker };
  }

  setLinker(linkerData) {
    const {
      linkerId,
      id,
      title,
      previousRouteStateParams,
      isEdit,
      familyId,
      familyName,
      isBusiness,
      isOpportunityOrLoanapp,
    } = linkerData;
    const obj = Object.assign(
      {},
      {
        linkerId,
        id,
        title,
        isEdit,
        familyId,
        familyName,
        isBusiness,
        isOpportunityOrLoanapp,
        previousRouteStateParams: previousRouteStateParams
          ? { ...previousRouteStateParams }
          : {},
      }
    );

    this.storeLinker(obj);
  }

  resetLinker() {
    this.storeLinker(null);
  }

  redirectToLoanProfiler(linkerData) {
    const {
      id: paramId,
      tabName,
      eventId,
      familyId,
      previousRouteStateParams,
      proposedLoanAmount,
      securityAmount,
      paramType,
    } = linkerData;
    const loanId = previousRouteStateParams
      ? previousRouteStateParams.loanId
      : null;
    this.setLinker(linkerData);
    const stateParams = {
      brokerEventId: 0,
      loanScenarioId: 0,
      tab: tabName,
      familyId,
      loanId,
      proposedLoanAmount,
      securityAmount,
    };

    if (eventId) {
      this.$state.go('app.loanProfiler', {
        ...stateParams,
        brokerEventId: eventId,
        paramId,
        paramType,
      });
    } else {
      this.loanProfilerService
        .getServiceabilityNewBrokerEvent()
        .then(response => {
          const { data: brokerEventId } = response;
          if (!brokerEventId) return;

          this.$state.go('app.loanProfiler', {
            ...stateParams,
            brokerEventId,
            paramId,
            paramType,
          });
        });
    }
  }

  redirectToFundingCalculator(linkerObj) {
    const { eventId, id, paramType } = linkerObj;
    this.setLinker(linkerObj);
    if (eventId) {
      this.$state.go('app.fundingCalculator', {
        brokerEventId: eventId,
        loanScenarioId: id,
      });
    } else {
      // Get Broker Event Id
      this.fundingCalculatorService
        .getFundingCalculatorNewBrokerEvent()
        .then(response => {
          this.$state.go('app.fundingCalculator', {
            brokerEventId: response.data,
            paramId: id,
            paramType,
          });
        });
    }
  }

  getcontactFullName(id) {
    return this.contactService.clientInformGet(id).then(clientResponse => {
      if (clientResponse && clientResponse.data && clientResponse.data.length) {
        const contactDetails = clientResponse.data;
        const contactFullName = contactDetails
          .reduce((accum, item) => {
            if (item.Role === 'Adult') {
              accum.push(`${item.FirstName} ${item.LastName}`);
            }
            return accum;
          }, [])
          .join(' & ');
        return { data: contactFullName };
      }
    });
  }
}
