import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';

export const isLastPage = (currentTab, tabsCount) => {
  if (Number.isNaN(tabsCount)) return;
  return currentTab === tabsCount - 1;
};

export const updateModalStateAndLogout = (uiService, generalService, data) => {
  const incompleteArguments = !uiService || !generalService || !data;
  if (incompleteArguments) return;
  const { dontShowAgain } = data;
  if (!dontShowAgain) {
    uiService.logoutUser();
    return;
  }
  generalService
    .postTickableItems({ itemId: TICKABLE_ITEMS.ONBOARDING_SAVE_INFO })
    .then(() => {
      uiService.logoutUser();
    });
};

export const displayInfoModal = items => {
  if (!items || !items.length) return;
  const savedTickable = items.find(item => {
    return item.id === TICKABLE_ITEMS.ONBOARDING_SAVE_INFO;
  });
  return savedTickable && savedTickable.isTicked;
};
