import { REFERRAL_TYPE, REFERRAL_LABEL } from 'Common/constants/referralType';

export function getReferralRequestPayload(queryFilter) {
  const {
    status,
    sendTo,
    dateCreated,
    dateUpdated,
    pageNumber,
    pageSize,
    referralTypeId,
    adviserName,
    search,
  } = queryFilter;

  const sentToParam = status ? `&sendTo=${sendTo}` : `sendTo=${sendTo}`;

  let query = '';
  query += status ? `status=${status}` : '';
  query += sendTo ? sentToParam : '';
  query +=
    dateCreated && dateCreated.dateStart
      ? `&dateCreatedStart=${dateCreated.dateStart}`
      : '';
  query +=
    dateCreated && dateCreated.dateEnd
      ? `&dateCreatedEnd=${dateCreated.dateEnd}`
      : '';
  query +=
    dateUpdated && dateUpdated.dateStart
      ? `&lastUpdatedStart=${dateUpdated.dateStart}`
      : '';
  query +=
    dateUpdated && dateUpdated.dateEnd
      ? `&lastUpdatedEnd=${dateUpdated.dateEnd}`
      : '';
  query += search ? `&searchText=${search}` : '';
  query += `&pageNumber=${pageNumber}`;
  query += `&pageSize=${pageSize}`;
  query += `&referralTypeId=${referralTypeId}`;
  query += `&adviserName=${adviserName}`;

  return query;
}
export function getReferralTypeDetails(referral) {
  const referralValue = Number.isNaN(parseFloat(referral))
    ? referral
    : +referral;
  switch (referralValue) {
    case REFERRAL_LABEL.RAY_WHITE:
    case REFERRAL_TYPE.RAY_WHITE:
      return {
        ReferralType: REFERRAL_LABEL.RAY_WHITE,
        ReferralTypeID: REFERRAL_TYPE.RAY_WHITE,
        Logo: 'raywhite',
      };
    case REFERRAL_LABEL.UK_PENSION:
    case REFERRAL_TYPE.UK_PENSION:
      return {
        ReferralType: REFERRAL_LABEL.UK_PENSION,
        ReferralTypeID: REFERRAL_TYPE.UK_PENSION,
        Logo: 'ukpension',
      };
    case REFERRAL_LABEL.F_AND_G:
    case REFERRAL_TYPE.F_AND_G:
      return {
        ReferralType: REFERRAL_LABEL.F_AND_G,
        ReferralTypeID: REFERRAL_TYPE.F_AND_G,
        Logo: 'tower',
      };
    case REFERRAL_LABEL.OFX:
    case REFERRAL_TYPE.OFX:
      return {
        ReferralType: REFERRAL_LABEL.OFX,
        ReferralTypeID: REFERRAL_TYPE.OFX,
        Logo: 'ofx',
      };
    case REFERRAL_LABEL.WEALTH_MARKET:
    case REFERRAL_TYPE.WEALTH_MARKET:
      return {
        ReferralType: REFERRAL_LABEL.WEALTH_MARKET,
        ReferralTypeID: REFERRAL_TYPE.WEALTH_MARKET,
        Logo: 'wealthmarket',
      };
    case REFERRAL_LABEL.OTHER:
    case REFERRAL_TYPE.OTHER:
      return {
        ReferralType: REFERRAL_LABEL.OTHER,
        ReferralTypeID: REFERRAL_TYPE.OTHER,
        Logo: 'others',
      };
    case REFERRAL_LABEL.LOAN_MARKET:
    case REFERRAL_TYPE.LOAN_MARKET:
      return {
        ReferralType: REFERRAL_LABEL.LOAN_MARKET,
        ReferralTypeID: REFERRAL_TYPE.LOAN_MARKET,
        Logo: 'loanmarket',
      };
    case REFERRAL_LABEL.XE:
    case REFERRAL_TYPE.XE:
      return {
        ReferralType: REFERRAL_LABEL.XE,
        ReferralTypeID: REFERRAL_TYPE.XE,
        Logo: 'xe',
      };
    case REFERRAL_LABEL.ASSETS_FINANCE:
    case REFERRAL_TYPE.ASSETS_FINANCE:
      return {
        ReferralType: REFERRAL_LABEL.ASSETS_FINANCE,
        ReferralTypeID: REFERRAL_TYPE.ASSETS_FINANCE,
        Logo: 'assetfinance',
      };
    default:
      return {};
  }
}

export function getSendReferralFilter(method, filterObj, pageSize) {
  switch (method) {
    case 'status':
      return {
        pageNumber: 1,
        sendTo: filterObj.sendTo,
        status: filterObj.status,
        pageSize,
      };
    case 'type':
      return {
        pageNumber: 1,
        sendTo: '0',
        referralTypeId: filterObj.referralTypeId,
        pageSize,
      };
    case 'adviser':
      return {
        pageNumber: 1,
        sendTo: filterObj.sendTo,
        adviserName: filterObj.adviserName,
        pageSize,
      };
    default:
      return {
        status: '',
        sendTo: '0',
        referralTypeId: '0',
        adviserName: '',
        pageNumber: 1,
        pageSize,
      };
  }
}
