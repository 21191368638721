import {
  taskFilterBuilderForUI,
  taskFilterBuilderForMyCRM,
  taskAdviserListForUI,
  taskPdfBuilderForUI,
} from 'Common/mappers/tasks';

class TasksService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Tasks';
  }

  taskFilterGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/taskfilter`)
      .then(response => taskFilterBuilderForUI(response.data));
  }
  taskFilterUpdate(data) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/taskfilter`,
      taskFilterBuilderForMyCRM(data)
    );
  }
  taskAdviserListGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/TaskAdviserListGet`)
      .then(
        response => response.data && response.data.map(taskAdviserListForUI)
      );
  }

  getTaskList(params) {
    const defaultParams = {
      adviserFilter: 0,
      dateFilter: 'all',
      familyId: 0,
    };
    const paramsCopy = { ...defaultParams, ...params };
    return this.httpClient.get(`${this.apiBaseUrl}/TaskListGet`, paramsCopy);
  }

  taskCompletionSet(familyID, taskID, isComplete) {
    return this.httpClient.post(
      `${
        this.apiBaseUrl
      }/TaskCompletionSet?familyID=${familyID}&taskID=${taskID}&isComplete=${isComplete}`,
      {}
    );
  }

  taskDetailsGet(taskId) {
    return this.httpClient.get(`${this.apiBaseUrl}/${taskId}`);
  }

  taskTemplateListGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/TaskTemplateListGet`);
  }

  scenarioListGet(familyID) {
    return this.httpClient.get(
      `LoanScenario/ScenarioListGet?familyId=${familyID}`
    );
  }

  taskDelete(familyId, taskId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${taskId}?FamilyID=${familyId}`
    );
  }

  taskTemplateSettingsGet(taskTemplateId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/templates/${taskTemplateId}/settings`
    );
  }

  dueDateSubsequentlyUpdate(data, taskId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/due-estimate?taskSettingsId=${taskId}`,
      data
    );
  }

  taskSet(data) {
    return this.httpClient.post(this.apiBaseUrl, data);
  }

  taskUpdate(data) {
    return this.httpClient.put(`${this.apiBaseUrl}/${data.TaskID}`, data);
  }

  taskV3MultipleSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/templated`, data);
  }

  searchFamily(searchCriteria, contactType, assignToAdviserID) {
    let url = `${
      this.apiBaseUrl
    }/TaskFamilySearchGet?searchCriteria=${searchCriteria}`;
    if (contactType) url += `&contactType=${contactType}`;
    if (assignToAdviserID) url += `&assignToAdviserID=${assignToAdviserID}`;

    return this.httpClient.get(url);
  }

  taskColumnSettingsGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/TaskColumnSettingsGet`);
  }

  taskColumnSettingsSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/TaskColumnSettingsSet`,
      data
    );
  }

  taskBatchCompletion(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/batch/completion`, data);
  }

  taskSingleCompletion(taskId, familyID, isComplete) {
    return this.httpClient.put(
      `${
        this.apiBaseUrl
      }/${taskId}/completion?id=${taskId}&familyID=${familyID}&isComplete=${isComplete}`,
      {}
    );
  }

  taskPdf(
    adviserFilter = 0,
    dateFilter = 'all',
    createdbyFilter = 0,
    familyId = 0,
    isShowCompleted = false,
    sortColumn = 'ActivityDate',
    sortType = 'ASC',
    taskSearch = ''
  ) {
    return this.httpClient
      .get(
        `${
          this.apiBaseUrl
        }/pdf?adviserFilter=${adviserFilter}&dateFilter=${dateFilter}&createdbyFilter=${createdbyFilter}&familyId=${familyId}&isShowCompleted=${isShowCompleted}&sortColumn=${sortColumn}&sortType=${sortType}&taskSearch=${taskSearch}`
      )
      .then(response => taskPdfBuilderForUI(response.data));
  }
}

export default TasksService;
