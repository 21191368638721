import {
  INCOME_TAX,
  INCOME_FREQUENCY,
  INCOME_FREQUENCY_TEXT,
} from 'Common/constants/incomeTax';
import { getParsed } from 'Common/utilities/math';
import { getMoneyForm } from 'Common/utilities/currency';

const divideValueByFrequency = (value, frequency) => {
  return getMoneyForm(value / INCOME_FREQUENCY[frequency]);
};

const getSalaryBracket = (taxYear, taxableIncome) => {
  return INCOME_TAX[taxYear].taxBrackets.find(bracket => {
    return bracket.from <= taxableIncome && bracket.to >= taxableIncome;
  });
};

const getBracket = (brackets, taxableIncome) => {
  return brackets.find(bracket => {
    return bracket.from <= taxableIncome && bracket.to >= taxableIncome;
  });
};

export function getAnnualTaxableIncome(calculationObject, frequency) {
  const annualTaxableIncome = getMoneyForm(
    getParsed(calculationObject.taxableIncome)
  );

  switch (calculationObject.frequency) {
    case INCOME_FREQUENCY_TEXT.DAILY:
      if (frequency !== INCOME_FREQUENCY_TEXT.ANNUALLY)
        return (
          annualTaxableIncome *
          calculationObject.daysPerWeek *
          INCOME_FREQUENCY.WEEKLY
        );

      return annualTaxableIncome * calculationObject.daysPerYear;
    case INCOME_FREQUENCY_TEXT['HOURLY/WEEK']:
      if (frequency !== INCOME_FREQUENCY_TEXT.ANNUALLY)
        return (
          annualTaxableIncome *
          calculationObject.hoursPerWeek *
          INCOME_FREQUENCY.WEEKLY
        );

      return (
        annualTaxableIncome *
        calculationObject.hoursPerWeek *
        calculationObject.weeksPerYear
      );
    case INCOME_FREQUENCY_TEXT['HOURLY/DAY']:
      if (frequency !== INCOME_FREQUENCY_TEXT.ANNUALLY)
        return (
          annualTaxableIncome *
          calculationObject.hoursPerDay *
          calculationObject.daysPerWeek *
          INCOME_FREQUENCY.WEEKLY
        );

      return (
        annualTaxableIncome *
        calculationObject.hoursPerDay *
        calculationObject.daysPerYear
      );
    default:
      return (
        annualTaxableIncome * INCOME_FREQUENCY[calculationObject.frequency]
      );
  }
}

export function getLowIncomeTaxOffset(taxableIncome, taxData) {
  const lito = { ...taxData.lowIncomeTaxOffset };
  const isWithinOffsetRange =
    taxableIncome < lito.maxThreshold && taxableIncome;

  if (!isWithinOffsetRange) {
    return 0;
  }

  if (taxableIncome <= lito.minThreshold) {
    return lito.baseAmount;
  }

  return (
    lito.baseAmount -
    (lito.reductionRate / 100) * (taxableIncome - lito.minThreshold)
  );
}

export function getLowAndMiddleIncomeTaxOffset(taxableIncome, taxData) {
  const lamito = { ...taxData.lowAndMiddleIncomeTaxOffset };
  const offsetBracket = getBracket(lamito.brackets, taxableIncome);

  if (!offsetBracket) return 0;

  const bracketLowerLimit = offsetBracket.from - 1;
  return (
    offsetBracket.baseAmount +
    (taxableIncome - bracketLowerLimit) * (offsetBracket.multiplier / 100)
  );
}

const getTaxOffset = (calculationObject, frequency, taxableIncome, taxData) => {
  const isLamitoApplied =
    taxData.lowAndMiddleIncomeTaxOffset &&
    taxData.lowAndMiddleIncomeTaxOffset.minYearEffective <=
      calculationObject.taxYear &&
    taxData.lowAndMiddleIncomeTaxOffset.maxYearEffective >=
      calculationObject.taxYear;
  const lowIncomeTaxOffset = getLowIncomeTaxOffset(taxableIncome, taxData);
  const lowAndMiddleIncomeTaxOffset = isLamitoApplied
    ? getLowAndMiddleIncomeTaxOffset(taxableIncome, taxData)
    : 0;

  if (
    !calculationObject.withholdTaxOffset &&
    frequency !== INCOME_FREQUENCY_TEXT.ANNUALLY
  )
    return 0;

  return lowIncomeTaxOffset + lowAndMiddleIncomeTaxOffset;
};

export function calculatePay(calculationObject, frequency) {
  const taxableIncome = getAnnualTaxableIncome(calculationObject, frequency);
  const taxData = INCOME_TAX[calculationObject.taxYear];
  const taxFreeBracket = taxData.taxBrackets[0];
  const superannuation =
    taxableIncome * (calculationObject.superannuationRate / 100);
  const isTaxFree =
    taxableIncome >= taxFreeBracket.from && taxableIncome <= taxFreeBracket.to;

  if (isTaxFree) {
    return {
      frequency,
      superannuation: divideValueByFrequency(superannuation, frequency),
      pay: divideValueByFrequency(taxableIncome, frequency),
      taxableIncome: divideValueByFrequency(taxableIncome, frequency),
      totalTaxes: 0,
      incomeTax: 0,
      medicare: 0,
      taxOffset: 0,
    };
  }

  // calculate tax breakdown based on annual taxable income
  const taxBracket = getSalaryBracket(calculationObject.taxYear, taxableIncome);
  const bracketLowerLimit = taxBracket.from - 1;
  const incomeTax =
    taxBracket.baseTax +
    (taxableIncome - bracketLowerLimit) * (taxBracket.multiplier / 100);
  const medicareDeduction =
    taxableIncome * (taxData.medicareDeductionRate / 100);
  const taxOffset = getTaxOffset(
    calculationObject,
    frequency,
    taxableIncome,
    taxData
  );

  const totalTaxes = incomeTax + medicareDeduction - taxOffset;
  const pay = taxableIncome - totalTaxes;

  return {
    frequency,
    taxOffset: divideValueByFrequency(taxOffset, frequency),
    superannuation: divideValueByFrequency(superannuation, frequency),
    pay: divideValueByFrequency(pay, frequency),
    taxableIncome: divideValueByFrequency(taxableIncome, frequency),
    totalTaxes: divideValueByFrequency(totalTaxes, frequency),
    incomeTax: divideValueByFrequency(incomeTax, frequency),
    medicare: divideValueByFrequency(medicareDeduction, frequency),
  };
}
