export function operatingCountriesBuilderForUI(operatingCountry) {
  return {
    countryId: operatingCountry.CountryId,
    countryCode: operatingCountry.CountryCode,
    countryName: operatingCountry.CountryName,
    callingCode: operatingCountry.CallingCode,
  };
}

export function reportingRegionsBuilderForUI(reportingRegion) {
  return {
    reportingRegionId: reportingRegion.ReportingRegionId,
    reportingRegionName: reportingRegion.ReportingRegionName,
  };
}

export function reportingStatesBuilderForUI(reportingState) {
  return {
    reportingStateId: reportingState.ReportingStateId,
    reportingStateName: reportingState.ReportingStateName,
  };
}

export function regionalizationBuilderForUI(region) {
  return {
    countryId: region.CountryID,
    name: region.Name,
    code: region.Code,
    currencySign: region.CurrencySign,
  };
}
