export const VALID_EXTENSIONS = [
  'pdf',
  'docx',
  'doc',
  'odt',
  'xls',
  'xlsx',
  'ods',
  'odp',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'rtf',
  'odt',
  'txt',
  'tif',
  'bmp',
  'gif',
  'html',
];

export const INVALID_FILE_INFO = {
  title: 'Invalid',
  description: 'Invalid file NOT added',
};

export const DOC_TYPE_ID = {
  MARRIAGE_CERTIFICATE: 29,
  DRIVERS_LICENCE_AUSTRALIA: 12,
  DRIVERS_LICENCE_INTERNATIONAL: 13,
};
