import {
  POSSIBLE_ADVERSE_CHANGES,
  LIST_OF_QUESTIONS,
  MAIN_QUESTIONS,
} from 'Common/constants/possibleAdverseChanges';

import {
  isFilledAnswer,
  getGenericAnswer,
  getClientFromAnswerList,
  isCompleteMainQuestion,
} from 'Common/utilities/possibleAdverseQuestion';

import { SECTION_ID } from 'Common/constants/loanappSectionIds';

const DEFAULT_IMPACT_OPERATOR = '=';
class LoanAppPossibleAdverseChanges {
  constructor(
    $state,
    optionsService,
    contactService,
    loanApplicationServices,
    loanAppSharedData
  ) {
    'ngInject';

    this.$state = $state;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.loanApplicationServices = loanApplicationServices;
    this.POSSIBLE_ADVERSE_CHANGES = POSSIBLE_ADVERSE_CHANGES;
    this.LIST_OF_QUESTIONS = LIST_OF_QUESTIONS;
    this.MAIN_QUESTIONS = MAIN_QUESTIONS;
    this.familyId = this.$state.params.familyId;
    this.loanAppId = this.$state.params.loanAppId;
    this.possibleAdverseParams = {
      loanApplicationId: parseInt(this.loanAppId, 10),
      familyId: parseInt(this.familyId, 10),
    };
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.showAdverseContent = false;
    this.getClientInfo(this.familyId);
    this.getOptions();
    this.getImpactUnits();
    this.getImpactOperators();
    this.onChangeStatus({ updatedCompletionStatus: false });
  }

  isMainQuestion(question) {
    return this.MAIN_QUESTIONS.includes(question.QuestionId);
  }

  getOptions() {
    this.optionsService.getObjectivesOption().then(data => {
      this.adverseOptions = data;
      this.getAdverseChangesQuestions();
    });
  }

  getImpactUnits() {
    this.optionsService.getAdverseImpactUnits().then(({ data }) => {
      this.impactUnits = data;
    });
  }

  getImpactOperators() {
    this.optionsService.getAdverseImpactOperators().then(({ data }) => {
      this.impactOperators = data;
    });
  }

  getOptionListByQuestion(id) {
    return (
      (this.adverseOptions &&
        this.adverseOptions.find(option => option.questionId === id)) ||
      []
    );
  }

  getAdverseChangesQuestions() {
    this.optionsService
      .question(this.POSSIBLE_ADVERSE_CHANGES.TITLE)
      .then(({ data }) => {
        this.adverseQuestions = data;

        this.adverseQuestions.forEach(question => {
          this.getOptionList(question);
          question.isMain = this.isMainQuestion(question);
          question.SubQuestion &&
            question.SubQuestion.forEach(subQuestion => {
              this.getOptionList(subQuestion);
              subQuestion.SubQuestion &&
                subQuestion.SubQuestion.forEach(nestedQuestion => {
                  this.getOptionList(nestedQuestion);
                });
            });
        });
      });
  }

  getOptionList(question) {
    this.getPossibleAdverseChanges(question);
    question.optionList = this.getOptionListByQuestion(question.QuestionId);
  }

  getPossibleAdverseChanges(question) {
    const params = {
      ...this.possibleAdverseParams,
      questionId: question.QuestionId,
    };

    this.loanApplicationServices
      .getPossibleAdverseChanges(params)
      .then(data => {
        question.answerList = data;
        this.setPossibleAdverseChangesAnwers(question);
      });
  }

  setRepaymentAnswer(question) {
    const params = {
      ...this.possibleAdverseParams,
      optionId: question.answer,
      details: question.answerDetails,
    };

    this.loanApplicationServices
      .setPossiblePlanOrAnticipate(params)
      .then(() => {
        question.answer = null;
        question.answerDetails = null;
        this.getPossiblePlanOrAnticipate(question);
      });
  }

  setPossibleAdverseChangesAnwers(question) {
    switch (question.QuestionId) {
      case this.LIST_OF_QUESTIONS.RETIREMENT_AGE:
        this.clientDetails.forEach(client => {
          const clientQuestion = getClientFromAnswerList(
            question.answerList,
            client.PersonId
          );
          client.retirementAge = parseInt(clientQuestion.answer, 10);
        });
        break;

      case this.LIST_OF_QUESTIONS.CLIENT_CHANGES_APPLY:
        this.clientDetails.forEach(client => {
          const clientQuestion = getClientFromAnswerList(
            question.answerList,
            client.PersonId
          );
          client.changesApplied = !!parseInt(clientQuestion.answer, 10);
        });
        break;

      case this.LIST_OF_QUESTIONS.APPLICANT_RETIRING:
      case this.LIST_OF_QUESTIONS.ANTICIPATE_CHANGES:
        this.radioAnswer = getGenericAnswer(question.answerList);
        question.radioAnswer =
          this.radioAnswer && !!parseInt(this.radioAnswer, 10);
        this.isAnticipatedChanges =
          question.radioAnswer &&
          question.QuestionId === this.LIST_OF_QUESTIONS.ANTICIPATE_CHANGES;
        if (this.isAnticipatedChanges) this.getNatureAnticipatedChanges();
        break;

      case this.LIST_OF_QUESTIONS.REPAYMENT_OTHER:
      case this.LIST_OF_QUESTIONS.APPLICANT_RETIRING_PROPOSED_PAYMENT:
        question.answer = getGenericAnswer(question.answerList);
        break;

      case this.LIST_OF_QUESTIONS.ANTICIPATE_CHANGES_NATURE:
        question.answer = { financialImpactOperator: DEFAULT_IMPACT_OPERATOR };
        break;

      case this.LIST_OF_QUESTIONS.LOAN_REPAYMENT:
        this.getPossiblePlanOrAnticipate(question);
        break;

      default:
        break;
    }

    if (!question.isMain) return;
    this.updateStarted(isFilledAnswer(question));
  }

  updateStarted(isStarted) {
    this.isStarted = isStarted || this.isStarted;
  }

  setPossibleAnticipatedChanges(question) {
    const params = {
      ...this.possibleAdverseParams,
      ...question.answer,
      loanScenarioId: this.loanAppId,
    };
    this.loanApplicationServices.setPossibleAnticipated(params).then(() => {
      question.answer = { financialImpactOperator: DEFAULT_IMPACT_OPERATOR };
      this.getNatureAnticipatedChanges();
    });
  }

  getNatureAnticipatedChanges() {
    this.loanApplicationServices
      .getPossibleAnticipated(this.possibleAdverseParams)
      .then(data => {
        this.possibleAnticipated = data;
        this.hasPossibleAnticipated = !!(data && data.length);
      });
  }

  getPossiblePlanOrAnticipate(question) {
    this.loanApplicationServices
      .getPossiblePlanOrAnticipate({ loanApplicationId: this.loanAppId })
      .then(data => {
        question.possiblePlanOrAnticipate = data;
        this.hasPossiblePlanOrAnticipate = !!(data && data.length);
      });
  }

  getClientInfo(familyId) {
    this.contactService.clientInformGet(familyId).then(({ data }) => {
      this.clientDetails = data.filter(
        client => client.Role && client.Role.toLowerCase() === 'adult'
      );
    });
  }

  setRetirementAge(question, client) {
    const params = {
      ...this.possibleAdverseParams,
      clientId: client.PersonId,
      questionId: question.QuestionId,
      retirementAge: client.retirementAge,
    };

    this.setPossibleAdverseChanges(params).then(() => {
      this.getPossibleAdverseChanges(question);
    });
  }

  setAndUpdateAdverse(params, question) {
    this.setPossibleAdverseChanges(params).then(() => {
      this.getPossibleAdverseChanges(question);
      this.updateStarted(isFilledAnswer(question));
    });
  }

  setAnswerPerClient(question, client) {
    const params = {
      ...this.possibleAdverseParams,
      clientId: client.PersonId,
      questionId: question.QuestionId,
      answer: client.answer,
      retirementAge: client && client.retirementAge,
      changesApplied: client && client.changesApplied,
    };

    this.setAndUpdateAdverse(params, question);
  }

  setPossibleAdverseChangesAnswer(question, answer) {
    const params = {
      ...this.possibleAdverseParams,
      questionId: question.QuestionId,
      answer,
    };

    this.setAndUpdateAdverse(params, question);
  }

  setPossibleAdverseChanges(params) {
    return this.loanApplicationServices.setPossibleAdverseChanges(params);
  }

  toggleAdverseChange() {
    this.showAdverseContent = !this.showAdverseContent;
  }

  isCompletedQuestion(question) {
    let isComplete = isCompleteMainQuestion(question);
    const isAnticipateQuestion =
      question.QuestionId === LIST_OF_QUESTIONS.ANTICIPATE_CHANGES;

    if (isAnticipateQuestion && question.radioAnswer) {
      isComplete =
        isComplete &&
        this.hasPossibleAnticipated &&
        this.hasPossiblePlanOrAnticipate;
    }
    question.isComplete = isComplete;
    this.setCompletionStatus();
    return isComplete;
  }

  isCompleteAll() {
    let isComplete = true;

    this.adverseQuestions.forEach(item => {
      if (item.isMain && !item.isComplete) isComplete = false;
    });

    return isComplete;
  }

  setCompletionStatus() {
    const updatedCompletionStatus = this.isCompleteAll();
    const sectionObject = this.loanAppSharedData.getSectionObjectById(
      this.loanAppSharedData.completionListObject,
      SECTION_ID.POSSIBLE_ADVERSE
    );

    if (this.isCompleted === updatedCompletionStatus) return;
    this.isCompleted = updatedCompletionStatus;
    this.onChangeStatus({ updatedCompletionStatus });
    this.loanAppSharedData.completionStatusSet(
      SECTION_ID.POSSIBLE_ADVERSE,
      sectionObject.IsCompleted,
      this.isCompleteAll()
    );
  }
}
export default LoanAppPossibleAdverseChanges;
