export default class InsuranceListCtrl {
  constructor(uiService, insuranceModalApplicationService) {
    'ngInject';

    this.uiService = uiService;
    this.insuranceModalApplicationService = insuranceModalApplicationService;
  }

  $onInit() {
    this.insuranceSubTab = 'existing';
    this.changeCount = 0;
  }

  openInsuranceApplication() {
    this.insuranceModalApplicationService.onCreate({
      typeNew: true,
      quoteId: 0,
      familyId: parseInt(this.familyId, 10),
      fullName: this.familyFullName,
    });
  }

  onUpdated() {
    this.changeCount++;
  }
}
