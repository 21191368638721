import { COLOR } from 'Common/constants/colors';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { filterInitialOneString } from 'Common/utilities/contact';
import { notesBuilderForUI } from 'Common/mappers/loanOpportunity';

export default class ReferralModalCtrl {
  constructor($uibModal, configService, referralService, currentUserService) {
    'ngInject';

    this.uibModal = $uibModal;
    this.configService = configService;
    this.referralService = referralService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.isWealthMarketNotesOn = !!this.configService.feature.wealthMarketNotes;

    const { corporateTeamId, isCorporate } = this.currentUserService;
    const corpSupportTeams = [CORPORATE_TEAM.BROKER_SUPPORT];
    this.isAddNoteAllowed =
      ((isCorporate && corpSupportTeams.includes(corporateTeamId)) ||
        !isCorporate) &&
      this.isWealthMarketNotesOn;

    this.initialsIconColor = COLOR.CERULEAN;
    this.noteList = [];
    this.pageNumber = 1;
    this.noteListState = '';
    this.loadReferral();
    this.loadReferralNotes();
  }

  loadReferral() {
    this.referralService
      .getReferralWealthMarket(this.referral.ReferralID)
      .then(response => {
        if (!response) return;

        this.referral = {
          ...this.referral,
          ...response,
          contactInitials: filterInitialOneString(response.contact) || '',
          sendToInitials: filterInitialOneString(response.sendToName) || '',
        };

        this.setLogoPath();
      });
  }

  loadReferralNotes() {
    const PAGE_SIZE = 5;

    this.referralService
      .getReferralNoteList(this.referral.ReferralID, PAGE_SIZE, this.pageNumber)
      .then(({ noteList, totalCount }) => {
        if (!totalCount) {
          this.noteListState = 'No note added';
          return;
        }

        noteList.map(note => {
          note.ownerInitials = filterInitialOneString(note.owner) || '';

          return note;
        });

        const mergeNoteList = [...this.noteList, ...noteList];
        this.noteList = mergeNoteList;
        this.totalCount = totalCount;
        this.hasMoreNote =
          this.noteList && this.noteList.length < this.totalCount;
      });
  }

  showNoteModal(note) {
    const props = {
      note,
      closeModal: this.closeModal,
    };
    this.uibModal.open({
      template: `<note-modal modal-instance="vm.modalInstance" note="vm.props.note" close-modal="vm.props.closeModal"></note-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      resolve: {
        props: () => props,
      },
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  loadMoreNotes() {
    this.pageNumber++;
    this.loadReferralNotes();
  }

  setLogoPath() {
    this.logoPath = `assets/images/referrals/referrers/${
      this.referral.logo
    }.png`;
  }

  showNoteForm() {
    const notes = {
      FamilyID: this.referral.FamilyID || this.referral.familyId,
      ReferralID: this.referral.ReferralID,
      ActivityTitle: 'Wealth Market Referral Note',
      ActivityDate: new Date(),
      BrokerBrandingID: 0,
      IsReferrer: true,
    };
    const notesData = notesBuilderForUI(notes);
    const props = {
      isAdd: 'add',
      noteObj: notesData,
      isSmsEnabled: 0,
      emailBody: '',
      smsBody: '',
    };

    const modalInstance = this.uibModal.open({
      template: `<opportunity-notes-modal
        modal-instance="vm.modalInstance"
        note-obj="vm.props.noteObj"
        is-add="vm.props.isAdd"
        referral-id="vm.props.referralId"
        is-sms-enabled="vm.props.isSmsEnabled"
        is-not-opportunity-module="true">
      </opportunity-notes-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      windowClass: 'opportunity-notes-modal',
      resolve: {
        props: () => props,
      },
    });
    modalInstance.result.then(() => {
      this.resetReferralNotes();
    });
  }

  resetReferralNotes() {
    this.noteList = [];
    this.pageNumber = 1;
    this.noteListState = '';
    this.loadReferralNotes();
  }

  closeModal() {
    this.modalInstance.close();
  }
}
