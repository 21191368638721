import { QUESTION_DISPLAY } from 'Common/constants/questionAnswerType';

export const ASSET_INSURANCE_DETAILS = {
  INSURER: {
    QUESTION_ID: 160,
    QUESTION_ID_NZ: 156,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  INSURANCE_BROKER: {
    QUESTION_ID: 161,
    QUESTION_ID_NZ: 157,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  POLICY_NUMBER: {
    QUESTION_ID: 162,
    QUESTION_ID_NZ: 158,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  EXPIRY_DATE: {
    QUESTION_ID: 163,
    QUESTION_ID_NZ: 159,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
};

export const NO_FINANCIALS_QUESTIONS = {
  IS_NO_FINANCIALS: {
    QUESTION_ID: 143,
    DISPLAY_OPTION: QUESTION_DISPLAY.YES_NO,
  },
  CLIENT_CREDIT_WORTHINESS: {
    QUESTION_ID: 144,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  PROPOSAL_MEET_TERMS: {
    QUESTION_ID: 145,
    DISPLAY_OPTION: QUESTION_DISPLAY.YES_NO,
  },
};

export const BROKER_DECLARATIONS = {
  IDENTIFIED_CONFLICT_INTEREST: {
    QUESTION_ID: 113,
  },
  PRODUCT_NOT_SUITABLE: {
    QUESTION_ID: 115,
    DISPLAY_OPTION: QUESTION_DISPLAY.YES_NO,
  },
};
