export const getFormattedPartyData = party => {
  return (
    party && {
      clientId: party.ClientID,
      familyId: party.FamilyId,
      firstName: party.FirstName,
      middleName: party.MiddleName,
      lastName: party.LastName,
      mobile: party.Mobile,
      email: party.Email,
      contactType: party.ContactType,
      isBusinessPartner: party.isBusinessPartner,
      isCompanyDirector: party.isCompanyDirector,
      isShareHolder: party.isShareHolder,
      isWritingValue: party.isWriting,
      familyPartnershipId: party.FamilyPartnershipID,
      roleId: party.RoleId,
    }
  );
};

export const getPartyDetailsByClientId = (partyList, clientId) => {
  const invalidList = !partyList || !partyList.length;
  if (invalidList || !clientId) return {};
  const partyDetails = partyList.find(party => {
    return party.ClientID === clientId;
  });
  return partyDetails || {};
};

export const extractUsername = (email, domain) => {
  if (!email || !domain) return '';
  return email.includes(domain) ? email.replace(domain, '') : '';
};
