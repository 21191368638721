export const EMAIL_TYPES = {
  GMAIL: 'gmail',
  CRM_EMAIL: 'email',
  SMS: 'phone',
  CUSTOMERIO_SMS: 'customerIOSms',
};

export const EMAIL_CTYPES = {
  GMAIL: 'Gmail',
  CRM_EMAIL: 'Email',
  SMS: 'Phone',
  CUSTOMERIO_SMS: 'Marketing Automation',
};

export const EMAIL_VALUE = {
  GMAIL: 'Emails synced from Gmail',
  CRM_EMAIL: 'Emails sent from MyCRM',
  SMS: 'SMS sent from MyCRM',
  CUSTOMERIO_SMS: 'Automated Emails & SMS',
};
export const DEFAULT = {
  ALL: 0,
};

export const EMAIL = {
  LOANMARKET_DOMAIN: '@loanmarket.com.au',
};
