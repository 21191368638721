import { appendEmptySelect } from 'Common/utilities/options';
import { DEFAULT_VALUES } from './constants';

export default class InsuranceDetailsCtrl {
  constructor(insuranceFireAndGeneralService) {
    'ngInject';

    this.insuranceFireAndGeneralService = insuranceFireAndGeneralService;
    this.fireGeneralInput = {};
    this.copyClientInfo = this.clientInfo;
  }

  $onInit() {
    this.DEFAULT_VALUES = DEFAULT_VALUES;
    this.loadBenefitType();
    this.filterClientAdult();
  }

  otherToBottom(list) {
    const pos = list
      .map(item => {
        return item.providerName;
      })
      .indexOf('Other');
    this.insurerList = list.concat(list.splice(pos, 1));
  }
  loadBenefitType() {
    this.insuranceFireAndGeneralService
      .insuranceBenefitTypesGet()
      .then(response => {
        this.benefitList = appendEmptySelect(response, {
          benefitId: DEFAULT_VALUES.SELECTED,
          benefitName: 'Please Select',
        });
      });

    this.insuranceFireAndGeneralService
      .insuranceInsurerGet(DEFAULT_VALUES.FIRE_GENERAL)
      .then(response => {
        const newList = appendEmptySelect(response, {
          providerId: DEFAULT_VALUES.SELECTED,
          providerName: 'Please Select',
        });

        this.otherToBottom(newList);
      });

    this.insuranceFireAndGeneralService
      .insuranceProductGet(DEFAULT_VALUES.FIRE_GENERAL)
      .then(response => {
        this.productList = appendEmptySelect(response, {
          productId: DEFAULT_VALUES.SELECTED,
          productName: 'Please Select',
        });
      });

    this.loadDefault();
  }

  loadDefault() {
    if (!this.insuranceData) {
      this.insuranceData = {
        providerId: DEFAULT_VALUES.SELECTED,
        productId: DEFAULT_VALUES.SELECTED,
      };
    }
  }

  getInsuranceDetailsValues(isProviderIdChanged = false) {
    if (
      isProviderIdChanged &&
      this.insuranceData.providerId === DEFAULT_VALUES.OTHER_INSURER
    ) {
      this.insuranceData.productId = DEFAULT_VALUES.OTHER_PRODUCT;
    }
    const formDatas = {
      clientId: this.fireGeneralInput.clientId,
      ownerShipInput: this.copyClientInfo,
      ...this.insuranceData,
    };
    this.getInsuranceDetails({ $e: { insuranceDetailsForm: formDatas } });
  }

  filterClientAdult() {
    const firstAdult =
      this.copyClientInfo[DEFAULT_VALUES.SELECTED].personId || '';

    const policyOwnershipList = value => {
      return {
        clientId: parseInt(value.personId, 10),
        isDependent: true,
        isOwnership: value.role === 'Adult',
        fullName: value.fullName,
        isInsured: value.personId === firstAdult,
      };
    };
    const otherPolicy = {
      clientId: DEFAULT_VALUES.SELECTED,
      isDependent: false,
      isOwnership: false,
      fullName: 'Other',
      isInsured: false,
      other: '',
    };
    const clientPolicy = this.copyClientInfo
      .map(policyOwnershipList)
      .filter(client => client.isOwnership);

    const defaultPolicyList = [...clientPolicy, otherPolicy];
    const { policyOwnership = defaultPolicyList } = this.insuranceData || [];
    this.copyClientInfo = policyOwnership.map(client => {
      if (client.clientId === 0 && !client.isOwnership) {
        client.other = '';
      }
      return client;
    });

    this.clientDropSelect = clientPolicy;
    const { clientId = parseInt(firstAdult, 10) } = this.insuranceData || {};
    this.fireGeneralInput.clientId = clientId;
    this.getInsuranceDetailsValues();
  }
}
