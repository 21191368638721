import template from './addressModalField.html';
import AddressModalFieldCtrl from './addressModalFieldCtrl';

export default {
  template,
  controller: AddressModalFieldCtrl,
  controllerAs: 'vm',
  bindings: {
    addressType: '=',
    addressObject: '=',
    isOrgAddress: '=',
    onInputChange: '&',
  },
};
