export function isBoolean(value) {
  return typeof value === 'boolean' || value === 'true' || value === 'false';
}

export function objectLength(object = {}) {
  return !object ? 0 : Object.keys(object).length;
}

export function isValidObjectValue(fn) {
  try {
    return fn();
  } catch (e) {
    return false;
  }
}

export function isObject(object) {
  return !!(object && typeof object === 'object');
}

export function isEmpty(data) {
  if (!data) return true;

  if (Array.isArray(data)) return !data.length;
  if (typeof data === 'object') return !objectLength(data);

  return true;
}

export function isObjectEquals(obj1, obj2) {
  // Please use this function for small size JSON only
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
