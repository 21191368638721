import moment from 'moment';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';

export const setDatesValue = ({ $scope, loanDetails }) => {
  if (!loanDetails || !loanDetails.loan) return;
  const {
    NotProceedingDate,
    ConditionallyApprovedDate,
    PreApprovedDate,
    EstimatedSettlementDate,
  } = loanDetails.loan;
  $scope.setLoanDetails.NotProceedingDate = NotProceedingDate
    ? new Date(NotProceedingDate)
    : '';
  $scope.setLoanDetails.ConditionallyApprovedDate = ConditionallyApprovedDate
    ? new Date(ConditionallyApprovedDate)
    : '';
  $scope.setLoanDetails.PreApprovedDate = PreApprovedDate
    ? new Date(PreApprovedDate)
    : '';
  $scope.setLoanDetails.EstimatedSettlementDate = EstimatedSettlementDate
    ? new Date(EstimatedSettlementDate)
    : '';
};
export const updateNotProceedingDate = ({
  $scope,
  loanScenarioModelService,
  loanSelected,
  result,
}) => {
  if (!result || !result.notProceedingDate) return;
  const dateParams = {
    loanApplicationId: 0,
    loanId: loanSelected.LoanID,
    dateTypeId: IMPORTANT_DATE_TYPES.DELETE.NOT_PROCEEDED,
    date: result.notProceedingDate,
  };
  loanScenarioModelService.updateImportantDate(dateParams).then(() => {
    $scope.setLoanDetails.NotProceedingDate = moment.parseZone(
      result.notProceedingDate
    )._d;
  });
};
