export function featureListBuilderForUI(feature) {
  return {
    organizations:
      (feature.Organizations &&
        feature.Organizations.map(organization => {
          return {
            adviserOrganizationId: organization.AdviserOrganizationId,
            adviserOrganizatioName: organization.AdviserOrganizatioName,
            addressId: organization.AddressId,
            featureManagerId: organization.FeatureManagerId,
            phone: organization.Phone,
            fax: organization.Fax,
          };
        })) ||
      [],
    featureId: feature.FeatureId,
    name: feature.Name,
    isEnabled: feature.IsEnabled,
    settingTypeId: feature.SettingTypeId,
    modifiedBy: feature.ModifiedBy,
    lastDateModified: feature.LastDateModified,
  };
}

export function preferredAdvisersBuilderForUI(preferred) {
  return {
    agreementId: preferred.AgreementId,
    adviserId: preferred.AdviserId,
    adviserName: preferred.AdviserName,
    adviserOrganizationName: preferred.AdviserOrganizationName,
    referrerOrgPreferredAdviserId: preferred.ReferrerOrgPreferredAdviserId,
    preferredByReferrerIds: preferred.PreferredByReferrerIds,
  };
}

export function adviserProfileBuilderForUI(profile) {
  return {
    profileId: profile.ProfileId,
    adviserId: profile.AdviserId,
    bio: profile.Bio,
    otherInfo: profile.OtherInfo,
    displayTitle: profile.DisplayTitle,
  };
}

export function adviserProfileBuilderForMyCRM(profile) {
  return {
    ProfileId: profile.profileId,
    AdviserId: profile.adviserId,
    Bio: profile.bio,
    OtherInfo: profile.otherInfo,
    DisplayTitle: profile.displayTitle,
  };
}

export function workingDaysBuilderForUI(days) {
  return {
    label: days.Label,
    isChecked: days.IsChecked,
    limit: days.Limit,
  };
}

export function adviserDetailsBuilderForUI(details) {
  return {
    familyId: details.FamilyId,
    brandingCategoryId: details.BrandingCategoryId,
    clientId: details.ClientID,
    brokerStatusId: details.BrokerStatusId,
    adviserOrganizationId: details.AdviserOrganizationId,
  };
}

export function adviserOrgDetailsBuilderForUI(details) {
  return {
    brandingCategoryId: details.BrandingCategoryId,
  };
}

export function adviserBrokerSupportBuilderForUI(details) {
  return {
    aLIQuoteEnabled: details.ALIQuoteEnabled,
    accessTypeID: details.AccessTypeID,
    agreementSigned: details.AgreementSigned,
    autoReferralConcierge: details.AutoReferralConcierge,
    autoReferralHome: details.AutoReferralHome,
    autoReferralWM: details.AutoReferralWM,
    brokerFeeId: details.BrokerFeeId,
    brokerSupportId: details.BrokerSupportId,
    cS2017ID: details.CS2017ID,
    campaignBreezeEnabled: details.CampaignBreezeEnabled,
    campaignBreezeEnabledDate: details.CampaignBreezeEnabledDate,
    customerContact: details.CustomerContact,
    dataEntryNotes: details.DataEntryNotes,
    f1CodeLW: details.F1CodeLW,
    familyId: details.FamilyId,
    feesPaidById: details.FeesPaidById,
    feesPaidByOther: details.FeesPaidByOther,
    fnGInsurance: details.FnGInsurance,
    insuranceToolsAccess: details.InsuranceToolsAccess,
    mICAccess: details.MICAccess,
    mortgageToolAccess: details.MortgageToolAccess,
    nPSEnabled: details.NPSEnabled,
    officerFamilyID: details.OfficerFamilyID,
    officerFullName: details.OfficerFullName,
    offshoring: details.Offshoring,
    optOutofPepperSubmissions: details.OptOutofPepperSubmissions,
    preferredContactMethod: details.PreferredContactMethod,
    preferredDocLabeling: details.PreferredDocLabeling,
    sMSEnabled: details.SMSEnabled,
    sMSEnabledDate: details.SMSEnabledDate,
    serviceModel: details.ServiceModel,
    supportingDocPreference: details.SupportingDocPreference,
    totalFailedAttempt: details.TotalFailedAttempt,
    userTypeId: details.UserTypeId,
  };
}

export function adviserBrokerSupportBuilderForAPI(details) {
  return {
    ALIQuoteEnabled: details.aLIQuoteEnabled,
    AccessTypeID: details.accessTypeID,
    AgreementSigned: details.agreementSigned,
    AutoReferralConcierge: details.autoReferralConcierge,
    AutoReferralHome: details.autoReferralHome,
    AutoReferralWM: details.autoReferralWM,
    BrokerFeeId: details.brokerFeeId,
    BrokerSupportId: details.brokerSupportId,
    CS2017ID: details.cS2017ID,
    CampaignBreezeEnabled: details.campaignBreezeEnabled,
    CampaignBreezeEnabledDate: details.campaignBreezeEnabledDate,
    CustomerContact: details.customerContact,
    DataEntryNotes: details.dataEntryNotes,
    F1CodeLW: details.f1CodeLW,
    FamilyId: details.familyId,
    FeesPaidById: details.feesPaidById,
    FeesPaidByOther: details.feesPaidByOther,
    FnGInsurance: details.fnGInsurance,
    InsuranceToolsAccess: details.insuranceToolsAccess,
    MICAccess: details.mICAccess,
    MortgageToolAccess: details.mortgageToolAccess,
    NPSEnabled: details.nPSEnabled,
    OfficerFamilyID: details.officerFamilyID,
    OfficerFullName: details.officerFullName,
    Offshoring: details.offshoring,
    OptOutofPepperSubmissions: details.optOutofPepperSubmissions,
    PreferredContactMethod: details.preferredContactMethod,
    PreferredDocLabeling: details.preferredDocLabeling,
    SMSEnabled: details.sMSEnabled,
    SMSEnabledDate: details.sMSEnabledDate,
    ServiceModel: details.serviceModel,
    SupportingDocPreference: details.supportingDocPreference,
    TotalFailedAttempt: details.totalFailedAttempt,
    UserTypeId: details.userTypeId,
  };
}

export function adviserLeadsBuilderForUI(details) {
  return {
    allocationCapPerDay: details.AllocationCapPerDay,
    allocationCapPerMonth: details.AllocationCapPerMonth,
    allocationCountPerDay: details.AllocationCountPerDay,
    allocationCountPerMonth: details.AllocationCountPerMonth,
    allocationWorkingdays: details.AllocationWorkingdays,
    calendlyLink: details.CalendlyLink,
    comments: details.Comments,
    corpLeadCampaignDesc: details.CorpLeadCampaignDesc,
    corpLeadCampaignIDs: details.CorpLeadCampaignIDs,
    distance: details.Distance,
    familyId: details.FamilyId,
    includeCalendlyLinkInAutoEmails: details.IncludeCalendlyLinkInAutoEmails,
    loanType: details.LoanType,
    loanTypeDesc: details.LoanTypeDesc,
    priority: details.Priority,
    profileId: details.ProfileId,
    receiveStatus: details.ReceiveStatus,
    sendNotificationBroker: details.SendNotificationBroker,
    sendNotificationClient: details.SendNotificationClient,
    wMReferralAlloc: details.WMReferralAlloc,
  };
}

export function adviserLeadsBuilderForAPI(details) {
  return {
    AllocationCapPerDay: details.allocationCapPerDay,
    AllocationCapPerMonth: details.allocationCapPerMonth,
    AllocationCountPerDay: details.allocationCountPerDay,
    AllocationCountPerMonth: details.allocationCountPerMonth,
    AllocationWorkingdays: details.allocationWorkingdays,
    CalendlyLink: details.calendlyLink,
    Comments: details.comments,
    CorpLeadCampaignDesc: details.corpLeadCampaignDesc,
    CorpLeadCampaignIDs: details.corpLeadCampaignIDs,
    Distance: details.distance,
    FamilyId: details.familyId,
    IncludeCalendlyLinkInAutoEmails: details.includeCalendlyLinkInAutoEmails,
    LoanType: details.loanType,
    LoanTypeDesc: details.loanTypeDesc,
    Priority: details.priority,
    profileId: details.profileId,
    ReceiveStatus: details.receiveStatus,
    SendNotificationBroker: details.sendNotificationBroker,
    SendNotificationClient: details.sendNotificationClient,
    WMReferralAlloc: details.wMReferralAlloc,
  };
}
export function leadsProfileListBuilderForUI(leads) {
  return {
    adviserId: leads.AdviserId,
    distance: leads.Distance,
  };
}

export function onboardingDocumentChecklistBuilderForUI(list) {
  return (
    list && {
      recruitmentChecklistId: list.RecruitmentCheckListID,
      checklistName: list.CheckListName,
      showOnHeader: list.ShowOnHeader,
      orderBy: list.OrderBy,
      isUploaded: false,
    }
  );
}

export function onboardingDocumentChecklistHeaderBuilderForUI(list) {
  return (
    list && {
      checklistHeader: list.ChecklistHeader,
      hasUploaded: list.HasUploaded,
    }
  );
}

export function familyComplianceSeparationsBuilderForUI(list) {
  return {
    familyId: (list && list.FamilyId) || 0,
    generateStatusId: (list && list.GenerateStatusId) || 0,
    generateStatus: (list && list.GenerateStatus) || '',
    dateGenerated: (list && list.DateGenerated) || '',
    letterContentId: (list && list.LetterContentId) || 0,
    letterContent: (list && list.LetterContent) || '',
    knownIssues: (list && list.KnownIssues) || '',
  };
}

export function familyComplianceSeparationsBuilderForMyCRM(data) {
  return (
    data && {
      FamilyId: data.familyId,
      GenerateStatusId: data.generateStatusId,
      GenerateStatus: data.generateStatus,
      DateGenerated: data.dateGenerated,
      LetterContentId: data.letterContentId,
      LetterContent: data.letterContent,
      KnownIssues: data.knownIssues,
    }
  );
}
