import angular from 'angular';

angular.module('app').factory('onboardingModel', [
  'httpRequest',
  function(httpRequest) {
    var apiBaseUrl = 'corporate/';
    function onboardingModel() {}

    onboardingModel.prototype = {
      getOnboardingAdviserOrganisationDetails(familyId, clientId, callback) {
        httpRequest.get(
          `${apiBaseUrl}OnboardingAdviserOrganisationDetailsGet?familyId=${familyId}&clientId=${clientId}`,
          response => {
            return callback(response);
          }
        );
      },
      setRecruitmentAdviserOrganization(data, callback) {
        httpRequest.post(
          `${apiBaseUrl}RecruitmentAdviserOrganizationSet`,
          data,
          response => {
            return callback(response);
          }
        );
      },
      setRecruitmentOrganizationAddress(
        familyId,
        organizationId,
        orgComplianceId,
        addressId,
        isMailing,
        callback
      ) {
        httpRequest.post(
          `${apiBaseUrl}RecruitmentOrganizationAddressSet?familyId=${familyId}&organizationId=${organizationId}&orgComplianceId=${orgComplianceId}&addressId=${addressId}&isMailing=${isMailing}`,
          {},
          response => {
            return callback(response);
          }
        );
      },
      getRecruitmentContact(familyId, clientId, callback) {
        httpRequest.get(
          `${apiBaseUrl}RecruitmentContactGet?familyId=${familyId}&clientId=${clientId}`,
          response => {
            return callback(response);
          }
        );
      },
      getCheckIfEmailExists(email, familyId, callback) {
        httpRequest.post(
          `/CheckIfEmailExistsGet?email=${email}&familyId=${familyId}`,
          { header: 'Accept: application/json' },
          response => {
            return callback(response);
          }
        );
      },
      setRecruitmentContact(familyId, data, callback, errorCallback) {
        httpRequest.post(
          `${apiBaseUrl}RecruitmentContactSet?mainFamilyId=${familyId}`,
          data,
          response => {
            return callback(response);
          },
          errorCallback
        );
      },
      OnboardingAdviserOrganisationASICDetailsGet(
        familyId,
        clientId,
        callback
      ) {
        httpRequest.get(
          `${apiBaseUrl}OnboardingAdviserOrganisationASICDetailsGet?familyId=${familyId}&clientId=${clientId}`,
          response => {
            return callback(response);
          }
        );
      },
      AsicAppointmentDetailsSet(
        adviserOrganizationId,
        complianceId,
        AsicType,
        AsicId,
        appointmentEntity,
        callback
      ) {
        httpRequest.post(
          `${apiBaseUrl}AsicAppointmentDetailsSet?adviserOrganizationId=${adviserOrganizationId}&complianceId=${complianceId}&AsicType=${AsicType}&AsicId=${AsicId}&appointmentEntity=${appointmentEntity}`,
          { header: 'Accept: application/json' },
          response => {
            return callback(response);
          }
        );
      },
      BankAccountDetailsGet(familyId, callback) {
        httpRequest.get(
          `${apiBaseUrl}BankAccountDetailsGet?familyId=${familyId}`,
          response => {
            return callback(response);
          }
        );
      },
      BankAccountDetailsSet(familyId, data, callback) {
        httpRequest.post(
          `${apiBaseUrl}BankAccountDetailsSet?familyId=${familyId}`,
          data,
          response => {
            return callback(response);
          }
        );
      },
      FamilyPartnershipSet(
        familyId,
        partnerFamilyId,
        partnerClientId,
        isWriting,
        isBusinessPartner,
        isCompanyDirector,
        isShareHolder,
        isActive,
        familyPartnershipId,
        callback,
        errorCallback
      ) {
        httpRequest.post(
          `${apiBaseUrl}FamilyPartnershipSet?familyId=${familyId}&partnerFamilyId=${partnerFamilyId}&partnerClientId=${partnerClientId}&isWriting=${isWriting}&isBusinessPartner=${isBusinessPartner}&isCompanyDirector=${isCompanyDirector}&isShareHolder=${isShareHolder}&isActive=${isActive}&familyPartnershipId=${familyPartnershipId}`,
          { header: 'Accept: application/json' },
          response => {
            return callback(response);
          },
          errorCallback
        );
      },
      QuestionaireListGet(familyId, callback) {
        httpRequest.get(
          `${apiBaseUrl}QuestionaireListGet?familyId=${familyId}`,
          response => {
            return callback(response);
          }
        );
      },
      QuestionaireAnswersGet(familyId, callback) {
        httpRequest.get(
          `${apiBaseUrl}QuestionaireAnswersGet?familyId=${familyId}`,
          response => {
            return callback(response);
          }
        );
      },
      QuestionaireAnswersSet(
        familyId,
        questionNumbers,
        yesOrNoAnswers,
        additionalAnswers,
        callback
      ) {
        httpRequest.post(
          `${apiBaseUrl}QuestionaireAnswersSet?familyId=${familyId}&questionNumbers=${questionNumbers}&yesOrNoAnswers=${yesOrNoAnswers}&additionalAnswers=${additionalAnswers}`,
          { header: 'Accept: application/json' },
          response => {
            return callback(response);
          }
        );
      },
      SendProcessCompletedEmailSet(familyId, callback) {
        httpRequest.post(
          `${apiBaseUrl}SendProcessCompletedEmailSet?familyId=${familyId}`,
          { header: 'Accept: application/json' },
          response => {
            return callback(response);
          }
        );
      },
      getApplicationChecklist(familyId, callback) {
        httpRequest.get(
          `${apiBaseUrl}ApplicationChecklistGet?familyId=${familyId}`,
          response => {
            return callback(response);
          }
        );
      },
      setApplicationChecklist(
        familyId,
        recruitmentChecklistId,
        documentId,
        callback
      ) {
        httpRequest.post(
          `${apiBaseUrl}ApplicationChecklistSet?familyId=${familyId}&recruitmentChecklistId=${recruitmentChecklistId}&documentId=${documentId}`,
          { header: 'Accept: application/json' },
          response => {
            return callback(response);
          }
        );
      },
      uploadDocument(data, callback) {
        httpRequest.post('/contacts/DocumentUpload', data, response => {
          return callback(response);
        });
      },
      getAdviserAssistants(familyId, callback) {
        httpRequest.get(
          `${apiBaseUrl}AdviserAssistantsGet?familyId=${familyId}&clienFamilytId=0`,
          response => {
            return callback(response);
          }
        );
      },
      getFamilyLenderAccreditation(familyId, callback) {
        httpRequest.get(
          `${apiBaseUrl}FamilyLenderAccreditationGet?familyId=${familyId}`,
          response => {
            return callback(response);
          }
        );
      },
      setFamilyLenderAccreditation(familyId, data, callback) {
        httpRequest.post(
          `${apiBaseUrl}FamilyLenderAccreditationSet?familyId=${familyId}`,
          data,
          response => {
            return callback(response);
          }
        );
      },
      getFamilyBrandingCategory(familyId, clientId, callback) {
        httpRequest.get(
          `${apiBaseUrl}FamilyBrandingCategoryGet?familyId=${familyId}&clientId=${clientId}`,
          response => {
            return callback(response);
          }
        );
      },
    };
    return onboardingModel;
  },
]);
