import angular from 'angular';

export default angular
  .module('app.services.mathService', [])
  .factory('mathService', function mathService() {
    const addNumbers = (...numbers) =>
      numbers.reduce((accum, val) => accum + val, 0);

    const plusTwo = num => num + 2;

    return {
      addNumbers,
      plusTwo,
    };
  }).name;
