import template from './onboardingAddedParty.html';
import controller from './onboardingAddedPartyCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    partyIndex: '<',
    partyDetails: '<',
    isStaff: '<',
    editParty: '&',
    cancelEdit: '&',
    updateParty: '&',
    deleteParty: '&',
    saveOnlyNoInvite: '<',
  },
};
