export const ROLE_TYPE = {
  APPLICANT: '1',
  DEPENDENT: '2',
};

export const CLIENT_ROLE_TYPE = {
  ADULT: 'Adult',
  CHILD: 'Child',
  OTHER: 'Other',
  GUARANTOR: 'Guarantor',
  POLICY_OWNER: 'Policy Owner',
};
