import swal from 'sweetalert';
import { getToken } from 'Common/utilities/token';
import { responseBuilderForUI } from 'Common/mappers/response';
import { constructConfigRequest } from 'Common/utilities/request';

app.factory('httpRequest', function(
  $http,
  configService,
  $window,
  $timeout,
  sessionService
) {
  const updateSessionTime = function(isSessionAlreadyUpdated) {
    sessionService.resetSessionTime(isSessionAlreadyUpdated);
  };

  const updateSessionToken = function() {
    sessionService.updateSession();
  };

  const errorCallback = function(e, customErrorCallback) {
    const errorStatus = [404, 400, 500];
    let errorMessage;
    if (e.status === 401) updateSessionToken();
    switch (e.status) {
      case 400:
        errorMessage = e.messages || 'Session Expired';
        break;
      default:
        errorMessage = e.statusText;
        break;
    }
    if (errorStatus.indexOf(e.status) !== -1) {
      swal('Yikes! Something is wrong', 'Please contact support.', 'error');
    }
    customErrorCallback(e);
  };
  const getResourceUrl = function(url) {
    const splitUrl = url.split('?');

    if (
      typeof configService.exclude !== 'undefined' &&
      configService.exclude.indexOf(splitUrl[0]) !== -1
    ) {
      url = `${$window.location.protocol}//${
        $window.location.host
      }/json/${url.replace(/\?/, '.json?')}`;
    } else {
      url = `${configService.resource}/${url}`;
    }

    return url;
  };

  const getMethod = function(url, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .get(url, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(response, () => {
          customErrorCallback(response);
        });
      });
  };

  const postMethod = function(url, data, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .post(url, data, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(response, () => {
          customErrorCallback(response);
        });
      });
  };

  const putMethod = function(url, data, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .put(url, data, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(response, () => {
          customErrorCallback(response);
        });
      });
  };

  const patchMethod = function(
    url,
    data,
    successCallback,
    customErrorCallback
  ) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .patch(url, data, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(response, () => {
          customErrorCallback(response);
        });
      });
  };

  const deleteMethod = function(url, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .delete(url, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(response, () => {
          customErrorCallback(response);
        });
      });
  };

  return {
    get: getMethod,
    post: postMethod,
    put: putMethod,
    delete: deleteMethod,
    patch: patchMethod,
  };
});
