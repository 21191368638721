import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  BIRTHDAYS_ID,
  CUSTOMER_CARE_TYPES,
} from 'Common/constants/customerCareModules';
import {
  onActionSelectedUtil,
  sendingResultUtil,
  modalParamsUtil,
  isDateSentActive,
} from './util/customerCareTabsCtrl';

angular
  .module('app')
  .controller('BirthdaysCtrl', function birthdaysCtrlFun(
    $scope,
    $uibModal,
    SweetAlert,
    NgTableParams,
    $window,
    $location,
    $state,
    $stateParams,
    toaster,
    cfpLoadingBar,
    $timeout,
    $rootScope,
    $filter,
    $localStorage,
    customerCareService,
    contactService,
    optionsService,
    corporateService,
    dashboardService,
    userService,
    downloadDataService,
    currentUserService,
    configService
  ) {
    // Birthdays
    //--------------------------------------------------------------------------
    /* Model */
    angular.extend($scope, {
      checkedDataNumber: 0,
      mainObj: {
        dateRange: 60,
        ShowCompleted: false,
        checkAll: false,
      },
      toCompleteBatchList: [],
      birthdayBatchList: [],
      isNZUser: currentUserService.isNZ,
      isDateSentActive: isDateSentActive(configService),
      actionColumnTitle: isDateSentActive(configService)
        ? 'Communication Sent'
        : '',
    });
    /* Methods */
    angular.extend($scope, {
      unCheckAllCheckbox() {
        if ($scope.birthdayListTable && $scope.birthdayListTable.length > 0) {
          _.each($scope.birthdayListTable, item => {
            item.isCompleteTemp = false;
          });
          $scope.checkedDataNumber = 0;
          $scope.mainObj.checkAll = false;
        }
      },

      checkAllCheckbox() {
        if ($scope.birthdayListTable && $scope.birthdayListTable.length > 0) {
          _.each($scope.birthdayListTable, item => {
            item.isCompleteTemp = true;
          });
          $scope.checkedDataNumber = $scope.birthdayListTable.length;
          $scope.mainObj.checkAll = true;
        }
      },

      storeOrRemoveRowsForBatch(familyId, singleBirthday) {
        if (singleBirthday.isCompleteTemp) {
          $scope.toCompleteBatchList.push({
            CustomerCareType: 'BIRTH_DAY',
            FamilyID: familyId,
            LoanID: 0,
            IsComplete: singleBirthday.isComplete,
            isCompleteTemp: singleBirthday.isCompleteTemp,
          });
          $scope.birthdayBatchList.push({
            Age: singleBirthday.Age,
            Country: singleBirthday.Country,
            DateOfBirth: singleBirthday.DateOfBirth,
            Email: singleBirthday.Email,
            FamilyFullName: singleBirthday.FamilyFullName,
            FamilyID: singleBirthday.FamilyID,
            FullName: singleBirthday.FullName,
            IsDueToday: singleBirthday.IsDueToday,
            Mobile: singleBirthday.Mobile,
            TotalRecord: singleBirthday.TotalRecord,
            isComplete: singleBirthday.isComplete,
            SMSEnabled: singleBirthday.SMSEnabled,
          });

          $scope.checkedDataNumber++;
          if ($scope.checkedDataNumber === $scope.birthdayListTable.length) {
            $window.document.getElementById('checkall-id').checked = true;
          }
        } else {
          _.remove($scope.toCompleteBatchList, object => {
            return object.FamilyID === familyId;
          });
          _.remove($scope.birthdayBatchList, object => {
            return object.FamilyID === familyId;
          });
          $scope.checkedDataNumber--;
        }
      },

      storeAllRowsForBatch(checkAll) {
        $scope.mainObj.checkAll = checkAll;
        $scope.toCompleteBatchList = [];
        $scope.birthdayBatchList = [];
        if (checkAll === true) {
          $scope.updateBirthdayListForBatch();
          $scope.birthdayBatchList = angular.copy($scope.birthdayListTable);
          $scope.checkedDataNumber = $scope.birthdayListTable.length;
        } else {
          $scope.toCompleteBatchList = [];
          $scope.birthdayBatchList = [];
          $scope.unCheckAllCheckbox();
        }
      },
      updateBirthdayListForBatch() {
        _.each($scope.birthdayListTable, object => {
          $scope.toCompleteBatchList.push({
            CustomerCareType: 'BIRTH_DAY',
            FamilyID: object.FamilyID,
            LoanID: 0,
            IsComplete: object.isComplete,
            isCompleteTemp: true,
          });
        });
        $scope.checkAllCheckbox();
      },
      setBatchBirthdayAsComplete(isComplete) {
        _.each($scope.toCompleteBatchList, item => {
          item.isCompleteTemp = isComplete;
          item.IsComplete = isComplete;
        });
        if (!_.isEmpty($scope.toCompleteBatchList)) {
          customerCareService
            .setCompeletion($scope.toCompleteBatchList)
            .then(() => {
              $scope.birthdayTableParams.reload();
              toaster.pop(
                'success',
                'Completed',
                'Contact successfully set as completed.'
              );
              $scope.checkedDataNumber = 0;
            });
        }
      },

      openBatchActionMobileModal(size) {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/batch_action_mobile_modal.html',
          controller: 'BatchActionMobileModalCtrl',
          size,
          scope: $scope,
          windowClass: 'batch-action-mobile-modal-class',
          resolve: {},
        });
      },
    });
    $scope.loadBirthdaysTable = () => {
      $scope.birthdayTableParams = new NgTableParams(
        {
          page: 1,
          count: 10,
          sorting: {
            DateOfBirth: 'asc',
          },
        },
        {
          counts: [],
          total: 0,
          getData($defer, params) {
            $scope.isLoading = true;
            const sortColumn = params.orderBy()[0].substring(1);
            const sortType = _.values(params.sorting())[0].toUpperCase();
            customerCareService
              .BirthDayListGet(
                $scope.mainObj.adviserId,
                $scope.mainObj.formDate,
                $scope.mainObj.toDate,
                params.page(),
                params.count(),
                sortColumn,
                sortType,
                $scope.mainObj.ShowCompleted
              )
              .then(
                response => {
                  if (response && response.data && response.data.BirthDayList) {
                    $scope.checkedDataNumber = 0;
                    $scope.toCompleteBatchList = [];
                    $scope.birthdayBatchList = [];
                    $scope.birthdayListTable = response.data.BirthDayList;
                    $scope.mainObj.checkAll = false;
                    _.map($scope.birthdayListTable, item => {
                      item.isCompleteTemp = false;
                      const today = moment().format('D MMMM');
                      const exDate = moment(item.DateOfBirth).format('D MMMM');
                      if (today === exDate) item.IsDueToday = true;
                      else item.IsDueToday = false;
                    });
                    let tempStoreForAge = '';
                    _.map($scope.birthdayListTable, object => {
                      tempStoreForAge = object.Age;
                      object.agePlusOne =
                        _.parseInt(_.replace(tempStoreForAge, 'Turning ', '')) +
                        1;
                      return object.agePlusOne;
                    });

                    if (typeof response.data.TotalCount !== 'undefined')
                      params.total(response.data.TotalCount);
                    else params.total(0);
                    $defer.resolve($scope.birthdayListTable);
                  }
                  $scope.isLoading = false;
                },
                () => {}
              );
          },
        }
      );
    };

    $scope.onActionSelected = onActionSelectedUtil($scope);

    const getDateRange = () => {
      return {
        formDate: moment().format('D MMMM YYYY'),
        toDate: moment()
          .add(Number($scope.mainObj.dateRange), 'days')
          .format('D MMMM YYYY'),
      };
    };

    $scope.exportBirthdaySheet = () => {
      $scope.mainObj = { ...$scope.mainObj, ...getDateRange() };
      const params = {
        fromDate: $scope.mainObj.formDate,
        isCompleted: $scope.mainObj.ShowCompleted,
        searchedAdvisorID: $scope.mainObj.adviserId,
        toDate: $scope.mainObj.toDate,
      };
      customerCareService.birthdaySpreadsheet(params).then(response => {
        if (!response || !Object.keys(response).length) return;
        const { contentType, documentContent, name } = response;
        downloadDataService.download(
          `data:${contentType};base64,${documentContent}`,
          `${name}`,
          contentType
        );
        toaster.pop(
          'success',
          'Birthday List Ready',
          'Successfully generated birthday list'
        );
      });
    };

    // Date Range Filter
    $scope.dateRangeFilter = () => {
      $scope.mainObj = { ...$scope.mainObj, ...getDateRange() };
      $scope.loadBirthdaysTable();
    };

    // get the signed in broker
    $scope.getSignedInBrokerId = () => {
      userService.GetUserInfo().then(response => {
        const { data } = response;
        if (!data) return false;
        $scope.mainObj.adviserId = data.FamilyId.toString();
        $scope.mainObj.adviserFullName = data.FullName;
        $scope.mainObj.accessType = data.AccessType;
        $scope.dateRangeFilter();
      });
    };
    $scope.getSignedInBrokerId();

    // Open modal For Get Birthday Detail in Mobile
    $scope.openModalForGetBirthdayDetail = (birthday, size) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/birthdays_filter_modal.html',
        controller: 'GetBirthdayDetailModalCtrl',
        size,
        resolve: {
          birthday: () => birthday,
        },
      });
    };

    // Set Birthday as Complete
    $scope.setBirthdayCompilation = birthday => {
      if (!birthday || !Object.keys(birthday).length) return;
      const birthDate = moment(birthday.DateOfBirth).format('D MMM');
      const notificationHeader = `Birthdays (${birthDate}) Completed`;
      const $postData = [
        {
          CustomerCareType: 'BIRTH_DAY',
          FamilyID: birthday.FamilyID,
          LoanID: 0,
          IsComplete: true,
          NotificationHeader: notificationHeader,
        },
      ];
      customerCareService.setCompeletion($postData).then(
        () => {
          $scope.birthdayTableParams.reload();
        },
        () => {}
      );
    };

    $scope.openSendEmailModal = (size, birthdayObj, source) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_email_modal.html',
        resolve: {
          birthdayBatchList() {
            return $scope.birthdayBatchList;
          },
          loadBirthdaysTable() {
            return $scope.loadBirthdaysTable;
          },
          birthdayObj: () => birthdayObj,
          source: () => source,
        },
        controller: 'BirthdaysSendEmailModalCtrl',
        size,
      });
    };

    $scope.sendingResult = sendingResultUtil($scope);

    $scope.openSendMailModal = (size, birthdayObj, source) => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_mail_modal.html',
        resolve: {
          listTableData: () => $scope.birthdayBatchList,
          sendMailObj: () => birthdayObj,
          source: () => source,
          currentModuleId: () => BIRTHDAYS_ID,
          currentModuleType: modalParamsUtil.currentModuleType(
            CUSTOMER_CARE_TYPES.BIRTHDAYS
          ),
          currentModuleName: modalParamsUtil.currentModuleName('Birthdays'),
          isInsurance: modalParamsUtil.isInsurance(false),
          tab: modalParamsUtil.tab(null),
        },
        controller: 'OpenSendMailModalCtrl',
        size,
      });
      $scope.sendingResult(modalInstance, 'loadBirthdaysTable');
    };
    const getRestriction = () => {
      const restrictedAccessTypeList = [
        ACCESS_TYPE.ADMIN_ASSISTANT,
        ACCESS_TYPE.ASSISTANT,
      ];
      return restrictedAccessTypeList.indexOf($scope.mainObj.accessType) !== -1;
    };
    $scope.openSMSModal = (size, birthdayObj, source) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_sms_modal.html',
        resolve: {
          birthdayBatchList() {
            return $scope.birthdayBatchList;
          },
          loadBirthdaysTable() {
            return $scope.loadBirthdaysTable;
          },
          birthdayObj: () => birthdayObj,
          source: () => source,
          isRestricted: () => getRestriction(),
        },
        controller: 'BirthdaysSMSModalCtrl',
        size,
      });
    };
    $scope.displaySmsButton = birthdayObj => {
      if (!getRestriction()) return true;
      return birthdayObj.SMSEnabled || false;
    };
  });
