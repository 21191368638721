import template from './onboardingAdditionalParties.html';
import controller from './onboardingAdditionalPartiesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {},
};
