import angular from 'angular';
import lenderSplitSmall from './lenderSplitSmall';
import widgetGeneralHeader from './widgetGeneralHeader';
import widgetGeneralFooter from './widgetGeneralFooter';
import averageLoanSize from './averageLoanSize';
import settlementVolumes from './settlementVolumes';
import enquirySourceTile from './enquirySourceTile';

export default angular
  .module('dashboardWidget.components', [])
  .component('lenderSplitSmall', lenderSplitSmall)
  .component('widgetGeneralHeader', widgetGeneralHeader)
  .component('widgetGeneralFooter', widgetGeneralFooter)
  .component('averageLoanSize', averageLoanSize)
  .component('settlementVolumes', settlementVolumes)
  .component('enquirySourceTile', enquirySourceTile).name;
