import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  FIXED_RATE_EXPIRES_ID,
  CUSTOMER_CARE_TYPES,
} from 'Common/constants/customerCareModules';
import {
  onActionSelectedUtil,
  sendingResultUtil,
  modalParamsUtil,
  initValues,
} from './util/customerCareTabsCtrl';

angular
  .module('app')
  .controller('FixedRateCtrl', function fixedRateCtrlFun(
    $scope,
    $uibModal,
    SweetAlert,
    NgTableParams,
    $window,
    $location,
    $state,
    $stateParams,
    toaster,
    cfpLoadingBar,
    $timeout,
    $rootScope,
    $filter,
    $localStorage,
    customerCareService,
    currentUserService,
    configService
  ) {
    // Fixed Rate Expires
    //--------------------------------------------------------------------------
    $scope.mainObj = {};
    $scope.mainObj.adviserId = 0;
    $scope.mainObj.dateRange = 60;
    $scope.mainObj.ShowCompleted = false;
    $scope.currentUserService = currentUserService;

    // Table
    $scope.loadFixedRateTable = () => {
      $scope.fixedRateTableParams = new NgTableParams(
        {
          page: 1,
          count: 10,
          sorting: {
            ExpiryDate: 'asc',
          },
        },
        {
          counts: [],
          total: 0,
          getData($defer, params) {
            $scope.isLoading = true;
            const sortColumn = params.orderBy()[0].substring(1);
            const sortType = _.values(params.sorting())[0].toUpperCase();
            customerCareService
              .FixedRateExpiryListGet(
                $scope.mainObj.adviserId,
                $scope.mainObj.formDate,
                $scope.mainObj.toDate,
                params.page(),
                params.count(),
                sortColumn,
                sortType,
                $scope.mainObj.ShowCompleted
              )
              .then(
                response => {
                  if (response && response.fixedRateExpiryList) {
                    $scope.fixedRateListTable = response.fixedRateExpiryList;
                    _.map($scope.fixedRateListTable, item => {
                      const today = moment().format('D MMMM YYYY');
                      const exDate = moment(item.ExpiryDate).format(
                        'D MMMM YYYY'
                      );

                      if (today === exDate) item.IsDueToday = true;
                      else item.IsDueToday = false;
                    });
                    if (typeof response.totalCount !== 'undefined')
                      params.total(response.totalCount);
                    else params.total(0);
                    $defer.resolve($scope.fixedRateListTable);
                  }
                  $scope.isLoading = false;
                },
                () => {}
              );
          },
        }
      );
    };

    $scope.onActionSelected = onActionSelectedUtil($scope);

    // Date Range Filter
    $scope.dateRangeFilter = () => {
      $scope.mainObj.formDate = moment().format('D MMMM YYYY');
      $scope.mainObj.toDate = moment()
        .add(Number($scope.mainObj.dateRange), 'days')
        .format('D MMMM YYYY');
      $scope.loadFixedRateTable();
    };

    // Set Fixed Rate as Complete
    $scope.setFixedRateCompilation = fixedRate => {
      if (!fixedRate || !Object.keys(fixedRate).length) return;
      const expDate = moment(fixedRate.expiryDate).format('D MMM');
      const notificationHeader = `Fixed Rate Expires (${expDate}) Completed`;
      const $postData = [
        {
          CustomerCareType: 'FIXED_RATE_EXPIRY',
          FamilyID: 0,
          LoanID: fixedRate.loanID,
          IsComplete: fixedRate.isComplete,
          NotificationHeader: notificationHeader,
        },
      ];
      customerCareService.setCompeletion($postData).then(
        () => {
          $scope.fixedRateTableParams.reload();
        },
        () => {}
      );
    };

    // Open modal For Get Fixed Rate Detail in Mobile
    $scope.openModalForGetFixedRateDetail = (fixedRate, size) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/fixed_rate_filter_modal.html',
        controller: 'GetFixedRateDetailModalCtrl',
        size,
        resolve: {
          fixedRate: () => fixedRate,
        },
      });
    };
    // Manage Template

    $scope.sendingResult = sendingResultUtil($scope);

    // openEmail
    $scope.openSendEmailModal = (size, fixedRateObj, source) => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_email_modal.html',
        resolve: {
          fixedRateListTable() {
            return $scope.fixedRateListTable;
          },
          fixedRateObj: () => fixedRateObj,
          source: () => source,
          isInsurance: () => false,
          tab: () => null,
        },
        controller: 'FixedRateSendEmailModalCtrl',
        size,
      });
      $scope.sendingResult(modalInstance, 'loadFixedRateTable');
    };
    $scope.openSendMailModal = (size, fixedRateObj, source) => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_mail_modal.html',
        resolve: {
          listTableData() {
            return $scope.fixedRateListTable;
          },
          sendMailObj: () => fixedRateObj,
          source: () => source,
          currentModuleId: modalParamsUtil.currentModuleType(
            FIXED_RATE_EXPIRES_ID
          ),
          currentModuleType: modalParamsUtil.currentModuleType(
            CUSTOMER_CARE_TYPES.FIXED_RATE_EXPIRES
          ),
          currentModuleName: modalParamsUtil.currentModuleName(
            'Fixed Rate Expires'
          ),
          isInsurance: modalParamsUtil.isInsurance(false),
          tab: modalParamsUtil.tab(null),
        },
        controller: 'OpenSendMailModalCtrl',
        size,
      });

      $scope.sendingResult(modalInstance, 'loadFixedRateTable');
    };
    $scope.openSMSModal = (size, fixedRateObj, source) => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_sms_modal.html',
        resolve: {
          fixedRateListTable() {
            return $scope.fixedRateListTable;
          },
          fixedRateObj: () => fixedRateObj,
          source: () => source,
          isInsurance: () => false,
          tab: () => null,
        },
        controller: 'FixedRateSMSModalCtrl',
        size,
      });

      $scope.sendingResult(modalInstance, 'loadFixedRateTable');
    };
    const loadDdlFromDashboardWidget = () => {
      if (
        typeof $localStorage.dropdownVal === 'undefined' ||
        typeof $localStorage.dropdownVal === 'undefined' ||
        $localStorage.dropdownVal === ''
      ) {
        return;
      }
      $scope.dashboardBigDdlObj = $localStorage.dropdownVal;
      if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 90 Days') {
        $scope.mainObj.dateRange = '90';
      }
      if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 30 Days') {
        $scope.mainObj.dateRange = '30';
      }
      if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 60 Days') {
        $scope.mainObj.dateRange = '60';
      }
      if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 7 Days') {
        $scope.mainObj.dateRange = '60';
      }

      $localStorage.dropdownVal = '';
    };

    const initFixedRateAdviser = () => {
      customerCareService
        .getBrokers({ typeId: $scope.serviceId, loanWritersOnly: true })
        .then(response => {
          if (!response) return;
          const brokerListFixedRate = response;
          $scope.mainObj.adviserId =
            (brokerListFixedRate.length && brokerListFixedRate[0].BrokerID) ||
            0;
          $scope.loadFixedRateTable();
        });
    };

    const init = () => {
      $scope.dateRangeFilter();
      loadDdlFromDashboardWidget();
      initFixedRateAdviser();
      initValues($scope)(configService);
    };

    init();
  });
