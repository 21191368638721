import { BUSINESS_TYPES } from 'Common/constants/businessTypes';

export const getBusinessTypeName = (businessTypes, typeId) => {
  if (!businessTypes || !businessTypes.length || !typeId) return '';
  if (typeId === BUSINESS_TYPES.OTHERS) return 'Other';
  const businessType = businessTypes.find(type => type.value === typeId);
  return businessType ? businessType.name : '';
};

export const isBusinessTypeOthers = referrer => {
  if (!referrer) return false;
  return referrer.BusinessTypeID === BUSINESS_TYPES.OTHERS;
};

export const getBusinessDescriptionBasedOnType = referrer => {
  if (!referrer) return '';
  const desc =
    referrer.BusinessTypeID === BUSINESS_TYPES.OTHERS
      ? referrer.BusinessActivitiesDesc
      : '';
  return desc;
};

export const isValidBusinessDescription = referrer => {
  if (!referrer) return;
  if (referrer.BusinessTypeID !== BUSINESS_TYPES.OTHERS) return true;
  return !!referrer.BusinessActivitiesDesc;
};
