import { VALID_EXTENSIONS } from 'Common/constants/document';
import { toastSaveDelete, toastError } from 'Common/utilities/alert';

class OnboardingRelatedDocumentCtrl {
  constructor(corporateModelService, onboardingService) {
    'ngInject';

    this.corporateModelService = corporateModelService;
    this.onboardingService = onboardingService;
  }
  getOnlineOnboardingDocumentChecklist() {
    this.corporateModelService
      .getOnlineOnboardingDocumentChecklist()
      .then(checklist => {
        this.documentChecklist = checklist;
        this.getApplicationChecklist();
      });
  }
  getApplicationChecklist() {
    if (!this.familyId) return;
    this.onboardingService
      .getApplicationChecklist(this.familyId)
      .then(response => {
        const { data } = response;
        this.applicationChecklist = data || [];
      });
  }
  getOnlineOnboardingDocumentChecklistHeaders() {
    if (!this.familyId) return;
    this.corporateModelService
      .getOnlineOnboardingDocumentChecklistHeaders(this.familyId)
      .then(data => {
        if (!data || !data.length) return;
        const documentIndex = Math.round(data.length / 2);
        const firstColumn = data.slice(0, documentIndex);
        const secondColumn = data.slice(documentIndex);
        this.documentChecklistParsed = [firstColumn, secondColumn];
      });
  }
  uploadDocumentSuccess(files) {
    this.uploadedDocuments = [...this.uploadedDocuments, ...files];
  }
  removeUpload(documentId) {
    if (!this.uploadedDocuments || !this.uploadedDocuments.length) return;
    this.uploadedDocuments = this.uploadedDocuments.filter(
      x => x.DocumentId !== documentId
    );
  }
  finishUpload(doc) {
    if (!doc) return;
    const { categoryId, DocumentId } = doc;
    this.onboardingService
      .setApplicationChecklist(this.familyId, categoryId, DocumentId)
      .then(() => {
        this.getOnlineOnboardingDocumentChecklistHeaders();
        this.getOnlineOnboardingDocumentChecklist();
        this.removeUpload(DocumentId);
      });
  }
  deleteDocument(doc) {
    if (!doc || this.isDeletingDocument) return;
    const { DocumentID: documentId } = doc;
    const params = {
      familyId: this.familyId,
      documentId,
    };
    this.isDeletingDocument = true;
    const errorMessages = 'Unable to delete this document.';
    this.corporateModelService
      .deleteOnlineOnboardingDocumentChecklist(params)
      .then(response => {
        if (response) {
          this.getOnlineOnboardingDocumentChecklistHeaders();
          this.getOnlineOnboardingDocumentChecklist();
          toastSaveDelete();
        } else {
          toastError(errorMessages);
        }
        this.isDeletingDocument = false;
      })
      .catch(() => {
        toastError(errorMessages);
        this.isDeletingDocument = false;
      });
  }
  $onInit() {
    this.uploadedDocuments = [];
    this.getOnlineOnboardingDocumentChecklistHeaders();
    this.getOnlineOnboardingDocumentChecklist();
    this.validFileExtensions = VALID_EXTENSIONS;
  }
}

export default OnboardingRelatedDocumentCtrl;
