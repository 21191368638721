export const onActionSelectedUtil = $scope => $event => {
  $scope[$event.sendVia]('lg', $event.item, 'single');
  return $event.sendVia;
};

export const sendingResultUtil = $scope => (modalInstance, loadTable) => {
  return modalInstance.result.then(result => {
    if (result.success) $scope[loadTable]();
  });
};

export const isDateSentActive = configService => {
  return configService.feature.dateSentCustomerCare;
};

export const initValues = $scope => configService => {
  $scope.isDateSentActive = isDateSentActive(configService);
  $scope.actionColumnTitle = $scope.isDateSentActive
    ? 'Communication Sent'
    : '';
  return configService;
};
export const modalParamsUtil = {
  currentModuleType: type => () => type,
  currentModuleName: name => () => name,
  isInsurance: isInsurance => () => isInsurance,
  tab: tab => () => tab,
};
