/* eslint-disable no-return-assign */
export function filterCapped(filters) {
  return filters.filter(
    filter => filter.name.toLowerCase().trim() !== 'capped'
  );
}

export function lvrDropDownValue(defaultSelection) {
  const isDefaultSelection =
    defaultSelection &&
    defaultSelection.loanAmount &&
    defaultSelection.security;

  if (isDefaultSelection) {
    let lvrOptionvalue =
      (defaultSelection.loanAmount / defaultSelection.security) * 100;

    if (lvrOptionvalue > 100 || parseFloat(lvrOptionvalue, 2) === 0)
      lvrOptionvalue = 0;

    return lvrOptionvalue;
  }
}

export function getLoanAmount(loanStructure) {
  return loanStructure.LoanAmount === null || loanStructure.LoanAmount === ''
    ? 0
    : loanStructure.LoanAmount;
}

export function getTrimmedString(productFinderItem) {
  const length = 12;
  return productFinderItem.Name.length > length
    ? `${productFinderItem.Name.substring(0, length - 3)}...`
    : productFinderItem.Name;
}
export function getUpdateLvr(dropDownLvrValue) {
  if (dropDownLvrValue !== 0) {
    if (parseFloat(dropDownLvrValue, 2) === 80) {
      return '80.01';
    }
    return `+${parseFloat(dropDownLvrValue, 2).toFixed(2)}`;
  }
}

export function getProductFinderCol() {
  return [
    { field: 'LenderName', title: 'Lender', show: true },
    { field: 'ProductName', title: 'Product', show: true },
    { field: 'RateType', title: 'Rate Type', show: false },
    { field: 'InitialRate', title: 'Initial Rate', show: false },
    { field: 'OngoingRate', title: 'Ongoing Rate', show: false },
    { field: 'ComparisonRate', title: 'Comparison Rate', show: false },
    { field: 'MaxLVR', title: 'Max LVR', show: false },
    { field: 'MaxLoanAmount', title: 'Max Loan Amount', show: false },
    { field: 'UpFrontFee', title: 'UpFront Fees', show: false },
    {
      field: 'OngoingMonthlyFee',
      title: 'Ongoing Monthly Fees',
      show: false,
    },
    { field: 'AnnualFees', title: 'Annual Fees', show: false },
  ];
}
