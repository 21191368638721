import angular from 'angular';
import { DATA_RED, DATA_BLUE } from 'Common/constants/chartColors';
import { BORDER_RADIUS_NORMAL } from 'Common/constants/chartOptions';

angular
  .module('app')
  .controller('SavingGoalCalculatorCtrl', function SavingGoalCalculatorCtrl(
    $scope,
    $rootScope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanToolsService,
    $filter,
    $element,
    $window,
    SweetAlert,
    $localStorage,
    DEMO_VIDEO,
    toaster,
    loanCalculatorSharedData,
    uiService,
    formattingService,
    optionsService,
    utilitiesService
  ) {
    $scope.uiService = uiService;
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId() {
            return DEMO_VIDEO.SAVING_GOAL_CALCULATOR.Url;
          },
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };
    optionsService.getLoanTerms().then(loanTermResponse => {
      $scope.loanTermList = loanTermResponse;
    });
    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    const viewport = function() {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function() {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        $scope.screenSize = newValue.w;
      },
      true
    );

    $scope.series = ['Accumulated Interest', 'Accumulated Deposits'];
    $scope.colors = [DATA_RED, DATA_BLUE];
    $scope.datasetOverride = $scope.series.map(() => BORDER_RADIUS_NORMAL);
    $scope.options = utilitiesService.getLoanOptions();

    $scope.goalList = {
      goalTable: [],
      goalChart: [],
      goalTit: [],
    };
    $scope.goal = {
      years: 5,
      months: '0',
      initialDeposit: '0',
      frequency: 'Monthly',
      interestRate: 5,
      savingTarget: $scope.uiService.isCountry('Indonesia')
        ? '10000'
        : '10,000',
    };
    $scope.resultPanelData = {};

    $scope.showBottom = true;
    $scope.changeShowBottom = function(val) {
      $scope.showBottom = val;
      if (val) $scope.constructDataForGraph();
    };

    function getParsed(amount) {
      const replaced = amount.replace(/,/g, '');
      const parsed = parseFloat(replaced);
      return parsed;
    }
    $scope.formatToCurrency = formattingService.calculatorFormatCurrency;
    $scope.formatInterestRate = amount => {
      return formattingService.calculatorFormatInterestRate(amount);
    };

    $scope.loadTable = function() {
      const copiedObj = {};
      angular.copy($scope.goal, copiedObj);
      copiedObj.years = parseInt(copiedObj.years, 10);
      copiedObj.months = parseInt(copiedObj.months, 10);
      copiedObj.interestRate = parseFloat(copiedObj.interestRate);
      copiedObj.savingTarget = parseFloat(getParsed(copiedObj.savingTarget));
      copiedObj.initialDeposit = parseFloat(
        getParsed(copiedObj.initialDeposit)
      );
      loanToolsService.getSavingGoalCalculations(copiedObj).then(response => {
        $scope.calculationObject = response.data;
        if ($scope.calculationObject.Installment < 0) {
          toaster.pop(
            'warning',
            'Oops, you entered values causing a -ve deposit amount, Please check your data entry'
          );
        }
        $scope.constructDataForGraph();
        $scope.constructDataForXSTable();
        if ($scope.goal.frequency === 'Monthly') {
          $scope.resultPanelData.frequency = 'Monthly';
        } else if ($scope.goal.frequency === 'Weekly') {
          $scope.resultPanelData.frequency = 'Weekly';
        } else {
          $scope.resultPanelData.frequency = 'Fortnightly';
        }
      });
    };

    $scope.constructDataForGraph = function() {
      const arrInterestAccumulated = [];
      const arrDepositedAmount = [];
      $scope.labels = [];

      let firstRow = $scope.calculationObject.SavingTableArraryChart[0];
      arrDepositedAmount.push(firstRow[1]);
      arrInterestAccumulated.push(firstRow[2]);
      $scope.labels.push(0);
      let counter = 1;

      for (
        let i = 12;
        i < $scope.calculationObject.SavingTableArraryChart.length;
        i += 12
      ) {
        const data = $scope.calculationObject.SavingTableArraryChart[i];
        arrDepositedAmount.push(data[2]);
        arrInterestAccumulated.push(data[1]);
        $scope.labels.push(counter++);
      }

      $scope.data = [arrDepositedAmount, arrInterestAccumulated];
      $scope.graphData = $scope.data;
      firstRow = $scope.calculationObject.SavingTableArraryChart[1];
      $scope.totalRepaymentAmount = firstRow[1];
    };

    $scope.constructDataForXSTable = function() {
      let maxPeriod = 0;
      let yrCount = 1;
      let prdCount = 1;
      const splicedArray = [];
      switch ($scope.goal.frequency) {
        case 'Monthly':
          maxPeriod = 12;
          break;
        case 'Weekly':
          maxPeriod = 52;
          break;
        case 'Fortnightly':
          maxPeriod = 26;
          break;
        default:
          break;
      }

      $scope.savingGoalTableArrayXs = [];
      angular.copy($scope.calculationObject.SavingTableDataArray, splicedArray);
      splicedArray.splice(0, 1);

      for (let i = 0; i < splicedArray.length; i++) {
        if (prdCount > maxPeriod) {
          prdCount = 1;
          yrCount += 1;
        }

        const row = splicedArray[i];
        $scope.savingGoalTableArrayXs.push({
          id: i,
          selectText: `Yearly ${yrCount} Period ${prdCount}`,
          yearText: `Yearly ${yrCount}`,
          periodText: `Period ${prdCount}`,
          DepositedAmount: row[1],
          AccumulatedInterestAmount: row[2],
          MonthlySavingAmount: row[3],
          AmountSavedWithInterest: row[4],
        });

        prdCount += 1;
      }
      $scope.selectedSavingGoalXs = $scope.savingGoalTableArrayXs[0];
    };

    function getLendersList() {
      loanToolsService.getLendersList().then(response => {
        $scope.lenderList = response.data;
      });
    }
    $scope.openPdfPreviewModal = function(size) {
      $uibModal.open({
        templateUrl:
          '/assets/views/loanCalculator/partials/saving_goal_pdf.html',
        resolve: {
          graphData() {
            return $scope.graphData;
          },
          datasetOverride() {
            return $scope.datasetOverride;
          },
          options() {
            return $scope.options;
          },
          labels() {
            return $scope.labels;
          },
          colors() {
            return $scope.colors;
          },
          loanAmount() {
            return $scope.goal.savingTarget;
          },
          years() {
            return $scope.goal.years;
          },
          months() {
            return $scope.goal.months;
          },
          interestRate() {
            return $scope.goal.interestRate;
          },
          frequency() {
            return $scope.goal.frequency;
          },
          initialDeposit() {
            return $scope.goal.initialDeposit;
          },
          savingPerMonth() {
            return $scope.calculationObject.SavingPerMonth;
          },
          resultPanelFreq() {
            return $scope.resultPanelData.frequency;
          },
          installment() {
            return $scope.calculationObject.Installment;
          },
          totalInterest() {
            return $scope.calculationObject.TotalInterest;
          },
          targetYear() {
            return $scope.calculationObject.TargetYear;
          },
          series() {
            return $scope.series;
          },
        },
        controller: 'SavingGoalPdfPreviewModalCtrl',
        size,
      });
    };

    function init() {
      $scope.loadTable();
      getLendersList();
    }

    init();
  });
