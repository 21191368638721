import * as PRIMARY_PURPOSE from 'Common/constants/primaryPurpose';
import { FUNDING_PURPOSE_IDS } from 'Common/constants/fundsConstants';
import { OPPORTUNITY } from 'Common/constants/paramTypes';

export const getPurpose = purposeValue => {
  const purposeValueInt = parseInt(purposeValue, 10);
  switch (purposeValueInt) {
    case PRIMARY_PURPOSE.FIRST_HOME:
    case PRIMARY_PURPOSE.NEXT_HOME:
      return FUNDING_PURPOSE_IDS.PURCHASE_OWNER_OCCUPIED.toString();
    case PRIMARY_PURPOSE.INVESTMENT_PROPERTY:
      return FUNDING_PURPOSE_IDS.PURCHASE_INVESTMENT.toString();
    case PRIMARY_PURPOSE.GET_A_BETTER_DEAL:
    case PRIMARY_PURPOSE.RENOVATE:
    case PRIMARY_PURPOSE.CONSOLIDATE_DEBT:
    case PRIMARY_PURPOSE.BORROW_EXTRA_FUNDS:
    case PRIMARY_PURPOSE.REFINANCE:
      return FUNDING_PURPOSE_IDS.REFI_EQUITY_OWNER_OCCUPIED.toString();
    default:
      return null;
  }
};

export const loanOpportunities = ({
  $scope,
  $state,
  configService,
}) => apiService => {
  if (
    $state.params.paramType !== OPPORTUNITY ||
    !configService.feature.loanAppOnOpportunities
  )
    return;
  const ESTABLISHED = '14';
  apiService
    .getLoanOpportunityDetails($state.params.paramId)
    .then(({ data }) => {
      const validCalculatorDetailsPurpose =
        data.CalculatorDetails &&
        data.CalculatorDetails.Purpose &&
        Object.keys(data.CalculatorDetails.Purpose).length > 0;
      const validPrimaryPurpose =
        validCalculatorDetailsPurpose && data.CalculatorDetails.Purpose.Value;
      const validSecurityAmount = !!(
        data.Summary && data.Summary.SecurityAmount
      );
      const validState = !!(
        data.CalculatorDetails && data.CalculatorDetails.State
      );
      const validPurchasersTotalIncome = !!(
        data.CalculatorDetails && data.CalculatorDetails.PurchasersTotalIncome
      );
      const validPurchasersTotalDependents = !!(
        data.CalculatorDetails &&
        data.CalculatorDetails.PurchasersTotalDependents
      );

      const [primaryPurposeId] = validPrimaryPurpose
        ? data.Summary.PrimaryPurpose.answerInt
        : [null];
      const securityAmount = validSecurityAmount
        ? data.Summary.SecurityAmount
        : 0;
      const stateValue = validState ? data.CalculatorDetails.State : '';
      const purchasersTotalIncome = validPurchasersTotalIncome
        ? data.CalculatorDetails.PurchasersTotalIncome
        : 0;
      const purchasersTotalDependents = validPurchasersTotalDependents
        ? data.CalculatorDetails.PurchasersTotalDependents
        : 0;
      $scope.fundingCalculatorParameters.address.formattedAddress = 'TBA';
      if (validCalculatorDetailsPurpose)
        $scope.fundingCalculatorParameters.purchaseInfo.propertyStatus = ESTABLISHED;
      $scope.fundingCalculatorParameters.purchaseInfo.propertyValue = securityAmount;
      $scope.fundingCalculatorParameters.address.state = stateValue;
      if (stateValue === 'ACT') {
        $scope.fundingCalculatorParameters.clientInfo.totalIncomeOfPurchasers = purchasersTotalIncome;
        $scope.fundingCalculatorParameters.clientInfo.dependents = purchasersTotalDependents;
      }

      if (primaryPurposeId)
        $scope.fundingCalculatorParameters.purchaseInfo.purpose = getPurpose(
          primaryPurposeId
        );
    });
};
