import template from './opportunitySidebar.html';

export default {
  template,
  bindings: {
    requirementsList: '<',
    onCompleteRequirement: '&',
    onAddRequirementModal: '&',
    opportunityGeneral: '<',
    onChangeGeneralInfo: '&',
    opportunityId: '<',
    familyId: '<',
    currentUserService: '<',
    isShowOpportunityFactFind: '<',
    isLoanAppOnOpportunitiesEnabled: '<',
  },
};
