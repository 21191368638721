import { initializePopover } from 'Common/utilities/importantDates';

class ImportantDatesPopoverCtrl {
  constructor(
    $q,
    $timeout,
    timeoutService,
    importantDatesService,
    loanScenarioModelService
  ) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this.timeoutService = timeoutService;
    this.importantDatesService = importantDatesService;
    this.loanScenarioModelService = loanScenarioModelService;
  }

  openDate(key) {
    this.isOpenDate[key] = true;
  }
  confirmDates() {
    this.confirmDatesFunc(this.importantDatesService);
  }
  closeModal() {
    this.modalInstance.close();
  }

  $onInit() {
    initializePopover(this);
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('ImportantDatesPopoverCtrl');
  }
}

export default ImportantDatesPopoverCtrl;
