import { CONTACT_TYPE } from 'Common/constants/contactType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';

export function getPhoneNumberByType(phoneArray, type) {
  if (!phoneArray || !phoneArray.length) return;
  const phone = phoneArray.filter(obj => obj.Type === type);
  if (!phone || !phone.length) return;
  return phone[0].Number;
}

export function getEmailByType(emailArray, type) {
  if (!emailArray || !emailArray.length) return;
  const email = emailArray.filter(obj => obj.Type === type);
  if (!email || !email.length) return;
  return email[0].EmailAddress;
}

export function getFirstAddressId(addressArray) {
  return addressArray && addressArray.length && addressArray[0].AddressID;
}

export function getFullName(firstname, lastname, middlename = '') {
  const nameArr = [firstname, middlename, lastname];
  const fullname = nameArr.reduce((acc, cur) => {
    const name = cur || '';
    return `${acc} ${name.trim()}`.trim();
  }, '');
  return fullname;
}

export function hasFirstnameOrLastname(contact) {
  return !!(contact && (contact.FirstName || contact.LastName));
}

export function filterInitialOneString(str) {
  if (!str) return '';

  let initials = '';
  const stringArray = str.split(/(\s+)/);

  let i = 0; // first word
  initials = stringArray[i].charAt(0).toUpperCase();

  i = stringArray.length - 1; // last word
  if (i > 0) initials += stringArray[i].charAt(0).toUpperCase();
  return initials;
}

export const getRoleIdByContactType = contactType => {
  switch (contactType) {
    case CONTACT_TYPE.DIRECTOR:
      return ONBOARDING_ROLE_ID.DIRECTOR.toString();
    case CONTACT_TYPE.SHARE_HOLDER:
      return ONBOARDING_ROLE_ID.GUARANTOR.toString();
    case CONTACT_TYPE.LOAN_WRITER_STR:
      return ONBOARDING_ROLE_ID.LOAN_WRITER.toString();
    default:
      return '';
  }
};

export function splitFullName(fullname, separator) {
  if (!fullname || !separator) return;
  const split = fullname.split(separator);
  const isInvalidFullName = !split || !split.length || split.length !== 3;
  if (isInvalidFullName) return;
  const [firstName, middleName, lastName] = split;
  return { firstName, middleName, lastName };
}

export function concatenateName(nameObj, joinChar) {
  if (!nameObj) return '';
  const { firstName, middleName, lastName } = nameObj;
  const emptyNames = !firstName && !middleName && !lastName;
  if (emptyNames) return '';
  const nameArr = [firstName, middleName, lastName];
  return nameArr.join(joinChar);
}

export function getNamePartsObject(fullname, separator = '|') {
  if (!fullname) return {};
  const name = splitFullName(fullname, separator);
  if (!name) return {};
  const { firstName, middleName, lastName } = name;
  return {
    fullName: getFullName(firstName, lastName, middleName),
    firstName,
    middleName,
    lastName,
  };
}

export const autoFormatUsername = nameArr => {
  if (!nameArr || !nameArr.length) return '';
  const username = nameArr.reduce((acc, cur) => {
    const name = (cur || '') && cur.replace(/\s/g, '');
    const names = [acc, name].filter(str => str);
    return names.join('.').toLowerCase();
  }, '');
  return username;
};
