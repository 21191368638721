export function openDate(addressHistory, dateField) {
  if (addressHistory && dateField) {
    addressHistory.datePopUp[dateField] = !addressHistory.datePopUp[dateField];
    return addressHistory.datePopUp[dateField];
  }
}

export function getContactLivingIn(data = {}) {
  return {
    BorrowerID: +data.ClientEntityId,
    FirstName: data.FirstName,
    LastName: data.LastName,
    IsInclude: true,
    IsEntity: false,
    IncludeGuarantor: 0,
  };
}

export function formatCustomAddress(addressHistory) {
  if (
    addressHistory &&
    addressHistory.Address &&
    !addressHistory.Address.isCustomAddress
  )
    return addressHistory;

  const {
    street_address: streetAddress,
    locality,
    postal_code: postalCode,
    stateShort_Name: state,
  } = addressHistory.Address;
  const local = locality ? `, ${locality}` : '';
  const zip = postalCode ? `, ${postalCode}` : '';
  const stateShortName = state ? `, ${state}` : '';
  return {
    ...addressHistory,
    Address: {
      ...addressHistory.Address,
      formatted_address: `${streetAddress}${local}${stateShortName}${zip}`,
    },
  };
}
