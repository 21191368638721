import { BUSINESS_TYPES } from 'Common/constants/businessTypes';

export const isReferrerOrgFieldsValid = referrer => {
  if (!referrer) return false;
  const { legalName, businessTypeId, businessActivities } = referrer;
  const isValidLegalName = legalName && legalName.trim() !== '';
  const isValidBusinessDesc =
    businessTypeId !== BUSINESS_TYPES.OTHERS || businessActivities;
  const isValid = !!(isValidLegalName && businessTypeId && isValidBusinessDesc);
  return isValid;
};

export const isBusinessTypeOthers = businessType => {
  return businessType === BUSINESS_TYPES.OTHERS;
};

export const getCurrentScheme = (countryCode, edrScheme) => {
  switch (countryCode.toLowerCase()) {
    case 'au':
      return edrScheme.au;
    case 'nz':
      return edrScheme.nz;
    default:
      return [{ id: 1, value: 'No Scheme' }];
  }
};

export const getAccountOwnerLabel = isCountryNZ => {
  return isCountryNZ ? 'Sales Manager' : 'Account Owner';
};

export const getHasRole = role => {
  const isRoleId =
    role &&
    role
      .map(elem => {
        return elem.ContactTypeRoleId;
      })
      .join(',') !== '';
  return isRoleId;
};

export const hasValue = value => {
  if (value && value.trim() !== '') {
    return true;
  }
  return false;
};
