class CreditCheckHistoryCtrl {
  constructor(modalRenderService) {
    'ngInject';

    this.modalRenderService = modalRenderService;
  }

  setCreditCheckListFlag() {
    this.displayCreditCheckList = !this.displayCreditCheckList;
  }

  onCreditChecksListInit(api) {
    this.listApi = api;
  }

  openCreditCheck() {
    const contactForCreditCheck = {
      FamilyID: this.familyId,
      ClientFirstName: this.adviserDetails.FirstName,
      ClientLastName: this.adviserDetails.LastName,
      isAdviser: true,
    };
    const modalInstance = this.modalRenderService.openCreditCheckModal(
      contactForCreditCheck
    );
    modalInstance.result.then(() => {
      this.listApi.refresh();
    });
  }

  $onInit() {
    this.displayCreditCheckList = true;
  }
}

export default CreditCheckHistoryCtrl;
