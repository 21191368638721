export const populatePreferredEmail = (email, isOwnBrand) => {
  if (!email) return '';
  if (isOwnBrand) return email;
  const preferredEmailSplit = email.split('@');
  if (preferredEmailSplit.length) {
    return preferredEmailSplit[0];
  }
  return email;
};

export const setDefaultPreferredEmail = ({ $scope, value }) => {
  const emailDefault = $scope.isOwnBrand
    ? ''
    : `${value.FirstName}.${value.LastName}${$scope.emailLoanMarket}`;
  const emailTmp = value.SecondaryEmail || emailDefault;
  const emailLowerCase = (emailTmp && emailTmp.toLowerCase()) || '';
  value.EmailTmp = populatePreferredEmail(emailLowerCase, $scope.isOwnBrand);
};
