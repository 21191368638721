import angular from 'angular';
import { BIRTHDAYS_ID } from 'Common/constants/customerCareModules';

angular
  .module('app')
  .controller('BirthdaysSendMailModalCtrl', function birthdaysSendMailModalCtrl(
    $scope,
    $uibModalInstance,
    $state,
    $controller,
    contactService,
    mailTemplateService,
    birthdayBatchList,
    ckEditorConfigService,
    birthdayObj,
    source
  ) {
    const vm = this;
    angular.extend(
      vm,
      $controller('CustomerCareSendMailModalBaseCtrl', { $scope })
    );

    $scope.recipients = {};
    $scope.isComplete = true;
    $scope.recipients.email = [];
    $scope.birthdayBatchList = birthdayBatchList;
    $scope.currentModule = BIRTHDAYS_ID;
    if (source === 'selected') {
      $scope.all = true;
      $scope.noOfPeople = $scope.birthdayBatchList.length;
    }
    $scope.selectEmailList = [];

    const init = () => {
      $scope.familyId = birthdayObj ? birthdayObj.FamilyID : null;

      // Ckeditor Tollbar Configuration
      $scope.options = ckEditorConfigService.getCustomerCareEmailEditorConfig();

      $scope.subject = 'Happy Birthday!';
      $scope.content = '';
    };

    init();

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
