import { pluralize } from 'Common/utilities/string';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { ASSET_DEFAULT_FREQUENCY } from 'Common/constants/iFinance';

const MONTHS_IN_YEAR = 12;

export function getFinanceCatIDsWithEmployer(financeCategories) {
  if (!financeCategories) return [];
  const financeCatWithEmployer = ['Novated Lease'];
  const financeCatWithEmployerIDs = financeCategories
    .filter(elem => financeCatWithEmployer.includes(elem.name))
    .map(elem => elem.value);
  return financeCatWithEmployerIDs;
}

export function getLoanTermInMonths(termValue, showUnit = true) {
  const monthValue = termValue ? Math.round(termValue * MONTHS_IN_YEAR) : 0;
  return showUnit ? pluralize(monthValue, 'month') : monthValue;
}

export function getFrequencyText(term) {
  if (!term) return '';
  const yearEquivalent = term / MONTHS_IN_YEAR;
  const useYear = yearEquivalent % 1 === 0;
  const termNumber = useYear ? yearEquivalent : term;
  const termUnit = useYear ? 'year' : 'month';
  return `every ${pluralize(termNumber, termUnit)}`;
}

export function getValueLoanTerm(loanTerm) {
  return loanTerm / MONTHS_IN_YEAR;
}

export function isAssetFinance(lendingCategoryId) {
  return lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE;
}

export function hasAssetFinance(data) {
  return !!(data && data.AssetFinance);
}

export function isRateDiscountValid(rateDiscount) {
  return !(rateDiscount && rateDiscount > 2);
}

export const getRepaymentFrequencyList = ({ thisCtrl }) => apiService => {
  apiService.frequency().then(({ data }) => {
    if (!data || !data.length) return;
    thisCtrl.repaymentFrequencyList = data;
    if (!hasAssetFinance(thisCtrl.loanStructure)) {
      thisCtrl.loanStructure.AssetFinance = {
        ...thisCtrl.loanStructure.AssetFinance,
        RepaymentFrequency: ASSET_DEFAULT_FREQUENCY,
      };
      return;
    }
    thisCtrl.selectFrequency(
      thisCtrl.loanStructure.AssetFinance.RepaymentFrequency
    );
  });
};
