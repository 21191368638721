export const showRecalculationModal = (
  isCalculated,
  crmConfirmation,
  onConfirm
) => {
  if (!isCalculated) return false;
  return crmConfirmation.open({
    type: 'warning',
    title: 'Settings have changed!',
    description: `You have adjusted the settings - you should consider re-calculating to ensure the correct information is displayed.`,
    buttonText: `Re-Calculate`,
    cancelButtonText: 'Ignore',
    onConfirm,
    showCloseButton: true,
    showCancelButton: true,
    modalSize: 'md',
    cancelButtonClass: 'colored',
  });
};

export const settingsModal = ({
  $uibModal,
  isCalculated,
  crmConfirmation,
  recalculateWithChanges,
}) => {
  return $uibModal
    .open({
      templateUrl: 'assets/views/insuranceTools/modals/profilerSettings.html',
      controller: 'InsuranceProfilerSettingsCtrl as vm',
      windowClass: 'insuranceProfiler-settings-modal',
      backdrop: false,
      keyboard: false,
    })
    .result.then(isShowRecalculationModal => {
      if (!isShowRecalculationModal) return false;
      return showRecalculationModal(
        isCalculated,
        crmConfirmation,
        recalculateWithChanges
      );
    });
};
