import angular from 'angular';
import opportunity from './opportunity';
import opportunityMain from './opportunityMain';
import opportunityDetails from './opportunityMain/opportunityDetails';
import involvedPartyRows from './opportunityMain/opportunityDetails/involvedPartyRows';
import opportunityServiceability from './opportunityMain/opportunityDetails/opportunityServiceability';
import opportunityFundingCalculator from './opportunityMain/opportunityDetails/opportunityFundingCalculator';
import opportunityProductFinder from './opportunityMain/opportunityDetails/opportunityProductFinder';
import opportunityCalculationItem from './opportunityMain/opportunityDetails/opportunityCalculationItem';
import opportunityCalculations from './opportunityMain/opportunityCalculations';
import opportunitySidebar from './opportunitySidebar';
import relatedTask from './opportunitySidebar/relatedTask';
import opportunitySummary from './opportunitySidebar/opportunitySummary';
import manageParties from './opportunityMain/manageParties';
import managePartiesBorrower from './opportunityMain/manageParties/borrowers';
import managePartiesGuarantor from './opportunityMain/manageParties/guarantors';
import opportunityNotes from './opportunityMain/opportunityNotes';
import opportunityCommunications from './opportunityMain/opportunityCommunications';
import opportunitySendEmailModal from './opportunityMain/opportunityCommunications/opportunitySendEmailModal';
import opportunitySendSmsModal from './opportunityMain/opportunityCommunications/opportunitySendSmsModal';
import opportunityViewTransactionModal from './opportunityMain/opportunityCommunications/opportunityViewTransactionModal';
import opportunityDocuments from './opportunityMain/opportunityDocuments';
import opportunityNotesModal from './opportunityMain/opportunityNotes/opportunityNotesModal';
import './style.scss';

export default angular
  .module('components.opportunity', [])
  .component('opportunity', opportunity)
  .component('opportunityMain', opportunityMain)
  .component('opportunityDetails', opportunityDetails)
  .component('involvedPartyRows', involvedPartyRows)
  .component('opportunityCalculations', opportunityCalculations)
  .component('opportunitySidebar', opportunitySidebar)
  .component('relatedTask', relatedTask)
  .component('opportunitySummary', opportunitySummary)
  .component('manageParties', manageParties)
  .component('managePartiesBorrower', managePartiesBorrower)
  .component('managePartiesGuarantor', managePartiesGuarantor)
  .component('opportunityNotes', opportunityNotes)
  .component('opportunityCommunications', opportunityCommunications)
  .component('opportunitySendEmailModal', opportunitySendEmailModal)
  .component('opportunitySendSmsModal', opportunitySendSmsModal)
  .component('opportunityViewTransactionModal', opportunityViewTransactionModal)
  .component('opportunityDocuments', opportunityDocuments)
  .component('opportunityNotesModal', opportunityNotesModal)
  .component('opportunityProductFinder', opportunityProductFinder)
  .component('opportunityFundingCalculator', opportunityFundingCalculator)
  .component('opportunityCalculationItem', opportunityCalculationItem)
  .component('opportunityServiceability', opportunityServiceability).name;
