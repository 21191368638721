import { LENDING_CATEGORY } from 'Common/constants/securityValues';

export const LENDER_ID = {
  OTHERS: 64,
  AU_OTHERS: -1,
};

export const LENDER_FILTER_CATEGORY = {
  [LENDING_CATEGORY.PERSONAL_LOAN]: 'Personal Loan',
  [LENDING_CATEGORY.ASSET_FINANCE]: 'Asset Finance',
};

export const PROVIDER_NAME = {
  DEFAULT: 'Provider',
  AU: 'Lender',
};
