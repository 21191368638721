export default class SmsEditor {
  constructor(smsObj = {}) {
    'ngInject';

    this.smsObj = smsObj;
  }

  onSelectRecipient(recipient, numberKey = 'phoneNumber') {
    const isSmsObjects = typeof recipient === 'object';

    if (!(isSmsObjects ? recipient[numberKey] : recipient)) {
      this.isRecipientsInValid = true;
      return;
    }

    if (this.smsObj.recipientsNumbers) {
      this.smsObj.recipientsNumbers = [
        ...this.smsObj.recipientsNumbers,
        recipient,
      ];
    } else {
      this.smsObj.recipientsNumbers = [recipient];
    }
  }

  onRemoveRecipient(recipient) {
    if (!this.smsObj || !this.smsObj.recipientsNumbers) return;

    const indexOfRecipient = this.smsObj.recipientsNumbers.indexOf(recipient);
    this.smsObj.recipientsNumbers = [
      ...this.smsObj.recipientsNumbers.slice(0, indexOfRecipient),
      ...this.smsObj.recipientsNumbers.slice(indexOfRecipient + 1),
    ];
  }

  onUpdateSubject(subject) {
    this.smsObj.subject = subject;
  }

  onUpdateBody(body) {
    this.smsObj.body = body;
  }
}
