import template from './questionYesNo.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    titleValue: '<',
    answer: '<',
    onChange: '&',
  },
};
