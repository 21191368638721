export function factFindHeadingsBuilderForUI(headers) {
  return {
    id: headers.Id,
    name: headers.Value,
  };
}

export function factFindPricingBuilderForUI(data) {
  return {
    brokerNumber: data.BrokerNumber,
    price: data.Price,
  };
}

export function factFindReviewClientBuilderForUI(client) {
  return (
    client &&
    client.data && {
      clientId: client.data.PersonId,
      fullName: `${client.data.FirstName} ${client.data.LastName}`,
      firstName: client.data.FirstName,
      lastName: client.data.LastName,
      isGuarantor: client.data.IsGuarantor,
    }
  );
}

export function reviewFactFindBuilderForUI(review) {
  return {
    clients:
      (review.FactFindLogs &&
        review.FactFindLogs.map(client => {
          return {
            clientId: client.ClientId,
            fullName: client.FullName,
            isExistOnFactFind: client.HasExistingFactFindData,
          };
        })) ||
      [],
    link: review.Link,
  };
}
