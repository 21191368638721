import { uniqBy } from 'lodash';
import { REGEX } from 'Common/constants/addressField';
import { existingAddressBar } from './util/existingAddress';

class ExistingAddressBarCtrl {
  constructor(
    generalService,
    $timeout,
    contactService,
    optionsService,
    currentUserService
  ) {
    'ngInject';

    this.generalService = generalService;
    this.$timeout = $timeout;
    this.contactService = contactService;
    this.optionsService = optionsService;
    this.currentUserService = currentUserService;
    this.countryList = [];
    this.existingAddressBarUtility = existingAddressBar(this);
  }

  getSuggestedAddresses() {
    if (!this.addressModel || !this.addressModel.formatted_address) return;
    this.addressModel.isEditSecurityInfo = true;
    this.$timeout.cancel(this.searchTimeOut);
    this.searchTimeOut = this.$timeout(() => {
      if (!this.addressModel) return;
      if (
        this.addressModel.formatted_address &&
        this.addressModel.formatted_address.length >= 3
      ) {
        this.addressModel.isEditSecurityInfo = true;
        this.generalService
          .placeSearch(this.addressModel.formatted_address)
          .then(response => {
            this.addressSuggestions = response.data;
            this.showSuggestionMenu = true;
          });
      }
    }, 500);
  }

  setCustomAddressFlag() {
    if (!this.addressModel) this.addressModel = {};
    this.addressModel.isCustomAddress = !this.addressModel.isCustomAddress;
  }

  clearManualFields() {
    if (this.addressModel) {
      this.addressModel.street_address = '';
      this.addressModel.locality = '';
      this.addressModel.postal_code = '';
      this.addressModel.stateShort_Name = '';
      this.addressModel.country = '';
      this.addressModel.formatted_address = '';
    }
  }

  showExistingAddressResult(status) {
    this.showSuggestionMenu = false;
    this.showExistingAddresses = !status;
  }

  selectFullAddress(address) {
    const excludeProp = [
      'AddressID',
      'AddressValuationExtendedDetailsId',
      'OwnershipType',
      'OwnershipTypeId',
      'StartDate',
      'Type',
      'TypeId',
      'ContactLivingIn',
    ];

    const newFullAddress = Object.keys(address).reduce((accum, prop) => {
      if (excludeProp.indexOf(prop) === -1) {
        accum[prop] = address[prop];
      }
      return accum;
    }, {});

    this.showSuggestionMenu = false;
    this.addressModel = newFullAddress;
    this.addressModel.isEditSecurityInfo = true;
  }

  closeAddressSuggestions() {
    this.showSuggestionMenu = false;
  }

  assignValuesToManualInputFields(existingAddressList) {
    if (!this.addressModel || !this.addressModel.isCustomAddress) return;

    const existingManualAddress =
      existingAddressList &&
      existingAddressList.find(
        object =>
          object.formatted_address === this.addressModel.formatted_address
      );
    existingManualAddress && this.selectFullAddress(existingManualAddress);
  }
  getExistingAddresses() {
    this.contactService
      .contactAddressGet(this.contactFamilyId)
      .then(response => {
        this.existingAddress = uniqBy(
          response.data,
          address => address.formatted_address
        );
        this.assignValuesToManualInputFields(this.existingAddress);
      });
  }

  $onDestroy() {
    this.$timeout.cancel(this.searchTimeOut);
  }

  $onInit() {
    this.postcodeRegex = REGEX.POST_CODE;
    this.showExistingAddresses = false;
    this.showSuggestionMenu = false;
    this.getExistingAddresses();
    this.existingAddressBarUtility.populateCountryList();
  }
}

export default ExistingAddressBarCtrl;
