export function showFactFindLendingBrandWarning(
  scope,
  loanScenarioId,
  featureFlagVariable
) {
  return (
    scope &&
    scope[featureFlagVariable] &&
    !scope.isBrandingReady &&
    !scope.isLoadingAdviserTheme &&
    loanScenarioId
  );
}

export function showFactFindLendingSection(
  scope,
  loanScenarioId,
  featureFlagVariable
) {
  return (
    scope &&
    scope[featureFlagVariable] &&
    scope.isBrandingReady &&
    !scope.isLoadingAdviserTheme &&
    loanScenarioId
  );
}

export function searchSameLoanFromList(loanItem, loanList) {
  if (!loanItem || !loanList) return;
  return loanList.find(
    inprogLoanObj => loanItem.LoanId === inprogLoanObj.LoanID
  );
}
