import template from './mcButton.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    label: '<',
    isLoading: '<',
    isDisabled: '<',
    customClass: '<',
    onClick: '&',
  },
};
