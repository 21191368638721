class LoanSubmissionEmailContentCtrl {
  toggleContent() {
    this.isShowContent = !this.isShowContent;
  }
  $onChanges() {
    this.updateSubject();
  }
  updateSubject() {
    if (!this.email || !this.email.Subject || !this.lenderSubmissionSet) return;
    const text = this.email.Subject;
    const appendText = '[URGENT]';
    const removeText = /[[]URGENT[\]]/;
    this.email.Subject = this.isUrgent
      ? `${appendText} ${this.email.Subject}`
      : text.replace(removeText, '');
    this.lenderSubmissionSet.CustomEmailSubject = this.email.Subject;
  }
}

export default LoanSubmissionEmailContentCtrl;
