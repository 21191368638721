import { getDesignatedFamilyBrokerInfo } from 'Common/utilities/adviser';

export default class HelloBookPreviewService {
  constructor(
    $timeout,
    $uibModal,
    configService,
    crmConfirmation,
    currentUserService,
    contactModelService,
    actionValidatorService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.configService = configService;
    this.crmConfirmation = crmConfirmation;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.actionValidatorService = actionValidatorService;
  }

  open(props) {
    return this.$uibModal.open({
      template: `<hello-book-preview-generator
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
      ></hello-book-preview-generator>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      keyboard: false,
      backdrop: 'static',
      size: 'lg',
      resolve: {
        props: () => props,
      },
      windowClass: 'hello-book-preview-generator-modal',
    });
  }

  openLoadingModal(loaderProps) {
    return this.$uibModal.open({
      template: `<hello-book-loading-modal
        modal-instance="vm.modalInstance"
        action="vm.props.action"
      ></hello-book-loading-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      keyboard: false,
      backdrop: 'static',
      size: 'lg',
      resolve: {
        props: () => loaderProps,
      },
    });
  }

  openConfirmationModal(confirmCallback) {
    this.crmConfirmation.open({
      type: 'success',
      title:
        'Contact created. Send a Hello pack to your clients to introduce yourself?',
      buttonText: 'Send Hello Pack',
      showCancelButton: true,
      cancelButtonText: 'Not Now',
      cancelButtonClass: 'hello-book-send-cancel-button',
      onConfirm: confirmCallback,
      modalSize: 'md',
    });
  }

  launchOnClientCreateSuccess(scope, familyId, additionalConditions = true) {
    const isValidUser =
      this.currentUserService.isAU || this.currentUserService.isNZ;
    const hasHelloBookAccess =
      this.configService.feature.helloBook &&
      isValidUser &&
      additionalConditions;

    if (hasHelloBookAccess) {
      const launchHelloBook = () => this.loadHelloBookPreview(scope, familyId);
      getDesignatedFamilyBrokerInfo({
        scope,
        familyId,
      })(this.contactModelService);
      this.openConfirmationModal(launchHelloBook);
    }
    return hasHelloBookAccess;
  }

  loadHelloBookPreview(scope, familyId) {
    const isAdviserAsicMissing = this.actionValidatorService.checkAdviserAsic(
      scope.designatedBrokerInfo,
      'send hello book'
    );
    if (isAdviserAsicMissing) return;

    const loaderModalInstance = this.openLoadingModal({
      action: 'transition',
    });

    const helloBookLoaderTimeout = this.$timeout(() => {
      loaderModalInstance.close();

      this.open({ familyId });
    }, 1000);
    scope.$on('$destroy', helloBookLoaderTimeout);
  }
}
