import angular from 'angular';
import mergeContact from './mergeContact';
import clientInsurance from './clientInsurance';
import insuranceAppWorkbench from './insuranceAppWorkbench';
import insuranceCustomerCare from './insuranceCustomerCare';
import incomeCalculator from './incomeCalculator';
import referral from './referral';
import insuranceSummaryOfCover from './insuranceSummaryOfCover';
import helloBook from './helloBook';
import dashboardWidget from './dashboardWidget';
import insuranceFireAndGeneral from './insuranceFireAndGeneral';
import assetFinanceWidgets from './assetFinanceWidgets';
import loanAppSections from './loanAppSections';
import insuranceQuoteOpportunities from './insuranceQuoteOpportunities';

export default angular.module('app.scenes', [
  mergeContact,
  clientInsurance,
  insuranceAppWorkbench,
  insuranceCustomerCare,
  insuranceQuoteOpportunities,
  incomeCalculator,
  referral,
  insuranceSummaryOfCover,
  helloBook,
  dashboardWidget,
  insuranceFireAndGeneral,
  assetFinanceWidgets,
  loanAppSections,
]).name;
