import { BORROWER_PARTY_TYPES } from 'Common/constants/partyTypes';
import {
  appendSupplierFixedData,
  formatAssetSupplierForDisplay,
} from 'Common/utilities/assetSupplier';
import { getFullName } from 'Common/utilities/contact';
import { CLIENT_TYPE_DESC } from 'Common/constants/contactType';

class RelatedPartySupplier {
  constructor(
    $timeout,
    $uibModal,
    utilitiesService,
    loanScenarioService,
    loanScenarioModelService,
    colorService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.utilitiesService = utilitiesService;
    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.colorService = colorService;
  }

  $onInit() {
    this.showSearchPanel = false;
    this.getFullName = getFullName;
  }

  showHideSearchPanel() {
    this.showSearchPanel = !this.showSearchPanel;
  }

  searchParty(searchString) {
    const SEARCH_MIN_LEN = 3;
    this.showSearchResults = true;
    this.searchResult = null;
    this.$timeout.cancel(this.searchTimeout);

    if (searchString.length >= SEARCH_MIN_LEN) {
      this.searchTimeout = this.$timeout(() => {
        this.loanScenarioService
          .scenarioSearchClientGet(
            searchString,
            BORROWER_PARTY_TYPES.PERSON,
            this.loanAppId
          )
          .then(({ data }) => {
            if (!data) return;
            this.searchResult = data || [];
          })
          .catch(() => {
            this.showSearchResults = false;
            this.searchResult = [];
          });
      }, 300);
    }
  }

  selectParty(contact) {
    contact.initials = this.utilitiesService.filterInitial(
      contact.FirstName,
      contact.LastName
    );
    this.clearSearch();
    this.saveScenarioSupplier(contact);
  }

  saveScenarioSupplier(supplier) {
    const supplierSet = appendSupplierFixedData(this.loanAppId, supplier);
    supplierSet.ClientTypeDesc = CLIENT_TYPE_DESC.BORROWER;
    this.loanScenarioService.scenarioApplicantSet(supplierSet).then(
      () => {
        supplierSet.background = this.colorService.getRandomColor();
        this.supplierList = this.supplierList.concat(
          formatAssetSupplierForDisplay(supplierSet)
        );
      },
      () => {
        this.toaster.pop(
          'warning',
          'Unable to Add',
          'Supplier was not added, please try again.'
        );
      }
    );
  }

  addManually() {
    const props = { loanAppId: this.loanAppId };
    const addSupplierModal = this.$uibModal.open({
      template: `<add-asset-supplier-modal
                  modal-instance="vm.modalInstance"
                  loan-app-id="vm.props.loanAppId">
                </add-asset-supplier-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'static',
      windowClass: 'new-loan-structure',
      keyboard: false,
      backdropClass: 'newLoanStructure-modal',
      openedClass: 'modal-w700 modal-open',
      resolve: {
        props,
      },
    });

    addSupplierModal.result.then(newSupplier => {
      if (!newSupplier) return;
      newSupplier.initials = this.utilitiesService.filterInitial(
        newSupplier.FirstName,
        newSupplier.LastName
      );
      newSupplier.background = this.colorService.getRandomColor();
      this.supplierList = this.supplierList.concat(newSupplier);
    });
  }

  removeSupplier(supplier) {
    if (supplier.FamilyId) {
      this.loanScenarioService
        .scenarioClientDelete(this.loanAppId, supplier.PersonId, true)
        .then(() => {
          this.supplierList = this.supplierList.filter(
            element => element.FamilyId !== supplier.FamilyId
          );
        });
    } else {
      this.loanScenarioModelService
        .deleteAssetSupplier(this.loanAppId, supplier.AssetSupplierId)
        .then(() => {
          this.supplierList = this.supplierList.filter(
            element => element.AssetSupplierId !== supplier.AssetSupplierId
          );
        });
    }
  }

  clearSearch() {
    this.searchInput = '';
    this.searchResult = '';
    this.showSearchResults = false;
    this.showSearchPanel = false;
  }

  $onDestroy() {
    this.$timeout.cancel(this.searchTimeout);
  }
}

export default RelatedPartySupplier;
