import moment from 'moment';
import { toastError } from 'Common/utilities/alert';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';

const LIFE_OF_LOAN = {
  VALUE: '0',
  NAME: 'Life of Loan',
};
const MAX_DISCOUNT_MONTH = 60;
const RATE_TYPE = {
  FIXED: 'Fixed',
  VARIABLE: 'Variable',
};
const RATE_STATE = {
  INITIAL: 'InitialRate',
  ONGOING: 'OngoingRate',
  ANOTHER: 'Another',
};
const ONE_YEAR = 12;
class LoanAppDiscountsRateCtrl {
  constructor(
    loanProfilerService,
    loanScenarioService,
    contactService,
    optionsService
  ) {
    'ngInject';

    this.loanProfilerService = loanProfilerService;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.optionsService = optionsService;
  }

  $onInit() {
    this.getLoanTimePeriod();
    this.resetDicountRate();
    this.rateTypes = RATE_TYPE;
    this.rateState = RATE_STATE;
  }

  $onChanges(changes) {
    const {
      productId,
      loanId,
      loanApplicationId,
      brokerEventId,
      numberOfProduct,
      isOnLoanDetailsModal,
      rateTerm,
      rateType,
      interestOnly,
    } = changes;
    if (productId && productId.currentValue) {
      this.productId = productId.currentValue;
      this.getSelectedProductDetails();
      this.getLoanDetails();
    }
    if (
      interestOnly &&
      typeof interestOnly.currentValue !== 'undefined' &&
      this.fullProductDetails &&
      this.productData
    ) {
      this.getShowDiscountInputs();
    }
    if (loanId && loanId.currentValue) {
      this.loanId = loanId.currentValue;
    }
    if (loanApplicationId && loanApplicationId.currentValue) {
      this.loanApplicationId =
        typeof loanApplicationId.currentValue === 'object'
          ? loanApplicationId.currentValue.LoanScenarioID
          : loanApplicationId.currentValue;
      this.getImportantDate();
    }
    if (brokerEventId && brokerEventId.currentValue) {
      this.brokerEventId = brokerEventId.currentValue;
      this.getLoanDetails();
    }
    if (isOnLoanDetailsModal && isOnLoanDetailsModal.currentValue) {
      this.isOnLoanDetailsModal = isOnLoanDetailsModal.currentValue;
    }
    if (numberOfProduct && numberOfProduct.currentValue) {
      this.numberOfProduct = numberOfProduct.currentValue;
      if (!this.isOnLoanDetailsModal) {
        this.isProductNewSet = true;
        this.getLoanDetails();
      }
    }
    if (rateTerm && typeof rateTerm.currentValue === 'number') {
      this.rateTerm = this.rateTerm ? this.rateTerm * ONE_YEAR : this.rateTerm;
      this.rateTerm =
        this.rateTerm > MAX_DISCOUNT_MONTH ? MAX_DISCOUNT_MONTH : this.rateTerm;
      this.updateInitialRateDataPeriod();
      this.updateFixedRateDiscountTerm();
    }
    if (rateType && rateType.currentValue) {
      this.productData && this.setInterestRate();
    }
  }

  updateFixedRateDiscountTerm() {
    const { discountRate } = this.initialRateData;
    if (!discountRate || this.rateType !== RATE_TYPE.FIXED) return;
    this.saveFavouriteProductRates(this.rateState.INITIAL);
  }

  initData() {
    this.isProductNewSet = false;
    this.isAddButtonShow = false;
    this.initialRateData = {
      isAnotherDiscountSet: false,
      isDetailShow: false,
      isEditDetails: false,
      isDeleted: false,
      isLoanPeriodSet: false,
    };
    this.ongoingRateData = {
      isDetailShow: false,
      isEditDetails: false,
      isDeleted: false,
    };
    this.isDefaultRateTermSet = false;
  }

  getSelectedProductDetails() {
    if (!this.productId) return;
    this.initData();
    this.loanProfilerService
      .getProductFullDetails(this.productId)
      .then(({ data }) => {
        if (!data) return;
        this.fullProductDetails = data;
        if (!data.InitialRate)
          this.fullProductDetails.InitialRate = data.IntialRate || 0;
        const defaultRateTerm = this.fullProductDetails.MaxTermFixedRate;
        this.isDefaultRateTermSet = !!defaultRateTerm;
        this.initialRateData.loanPeriod = this.loanTimePeriod.find(
          item => parseInt(item.value, 10) === defaultRateTerm * ONE_YEAR
        );
      });
  }

  getLoanDetails() {
    this.contactService.loanDetailsGet(this.loanId).then(({ data }) => {
      if (
        !data ||
        !data.loan ||
        !data.loan.LoanStructureList ||
        !data.loan.LoanStructureList.length
      )
        return;
      this.loanStructureId = data.loan.LoanStructureList[0].LoanStructureId;
      this.getLoanStructureDetails();
    });
  }

  getProductDetails() {
    if (!this.brokerEventId || !this.productId) return;
    this.loanProfilerService
      .getSelectedProductDetailsGetByLoanStructure(this.brokerEventId)
      .then(({ data }) => {
        if (!data || !data.length) return;
        const { LoanStructureID, SelectedProduct } = data[0];
        this.productLoanStructureId = LoanStructureID;
        const isProductNotSelected =
          SelectedProduct &&
          SelectedProduct.length &&
          SelectedProduct.find(item => item.ProductID === this.productId);
        if (!isProductNotSelected) return this.setProductSelected();
        this.setProductData(SelectedProduct);
      });
  }

  setProductSelected() {
    const postData = {
      brokerEventID: this.brokerEventId,
      ProductID: this.fullProductDetails.ProductID,
      LenderID: this.fullProductDetails.LenderID,
      loanStructureID: this.productLoanStructureId,
      IsFavourite: true,
      IsSeletedInFavourite: false,
    };
    this.loanProfilerService.setFavouriteProduct(postData).then(({ data }) => {
      if (!data) return;
      this.setProductData();
    });
  }

  setProductData(selectedProduct = []) {
    if (!this.productId) return;
    this.productData =
      selectedProduct.find(item => item.ProductID === this.productId) ||
      this.fullProductDetails;
    this.getShowDiscountInputs();
    this.setInterestRate();
    if (this.isProductNewSet && !this.productData) {
      this.productData = selectedProduct && selectedProduct[0];
      this.isProductNewSet = false;
    }
    if (this.productData && this.loanStructureData) {
      this.loanStructureData.LenderDetails = {
        LenderId: this.productData.LenderID,
      };
      this.loanStructureData.ProductDetails = {
        Product: this.productData.ProductName,
        ProductID: this.productData.ProductID,
      };
    }
    const timePeriod =
      this.productData.InterestRateTerm || this.initialRatePeriod;
    if (!this.isDefaultRateTermSet) {
      this.initialRateData.loanPeriod = this.loanTimePeriod.find(
        item => parseInt(item.value, 10) === timePeriod
      );
    }
    this.ratesInit();
  }

  getShowDiscountInputs() {
    const { InterestType } = this.fullProductDetails;
    const { InitialPeriod } = this.productData;
    if (
      !InitialPeriod ||
      !InterestType ||
      typeof this.interestOnly === 'undefined'
    )
      return;
    const isFixedLoanType = RATE_TYPE.FIXED === InterestType;
    this.initialRatePeriod = parseInt(InitialPeriod, 10) || 0;
    this.showInitialRateInput =
      this.initialRatePeriod || isFixedLoanType || this.interestOnly;
  }

  setInterestRate() {
    if (!this.isOnLoanDetailsModal) return;
    const {
      InitialRate: initialRate,
      OngoingRate: ongoingRate,
    } = this.productData;
    if (this.rateType === RATE_TYPE.FIXED) {
      const { actualRate: initialActualRate } = this.initialRateData;
      const baseRate = parseFloat(this.initialRateData.baseRate).toFixed(2);
      const interestRate = initialActualRate || baseRate || initialRate;
      this.getInitialActualRate({ rate: interestRate });
    }
    if (this.rateType === RATE_TYPE.VARIABLE) {
      const { actualRate: initialOngoingRate } = this.ongoingRateData;
      const baseRate = parseFloat(this.ongoingRateData.baseRate).toFixed(2);
      const interestRate = initialOngoingRate || baseRate || ongoingRate;
      this.getInitialActualRate({ rate: interestRate });
    }
  }

  getLoanStructureDetails() {
    if (!this.loanId || !this.loanStructureId) return;
    this.contactService
      .loanStructureDetailsGet(this.loanId, this.loanStructureId)
      .then(response => {
        const { data } = response;
        if (!data) return;
        const { InterestRateType: interestRateType } = data;
        this.rateType =
          interestRateType === RATE_TYPE.FIXED
            ? RATE_TYPE.FIXED
            : RATE_TYPE.VARIABLE;
        this.loanStructureData = response.data;
        this.loanStructureData.LoanId = this.loanId;
        this.loanStructureData.LoanStructureId = this.loanStructureId;
        const rateTerm =
          (this.loanStructureData && this.loanStructureData.RateTerm) ||
          parseInt(LIFE_OF_LOAN.VALUE, 10);
        this.rateTerm = rateTerm ? rateTerm * ONE_YEAR : rateTerm;
        this.getProductDetails();
      });
  }

  getLoanTimePeriod() {
    this.optionsService.getDiscountTimePeriod().then(response => {
      if (!response || !response.length) return;
      this.timePeriod = response;
      this.lifeOfLoanPeriod = response.find(item =>
        this.checkIfLifeOfLoan(item.value)
      );
      this.loanTimePeriod = response;
    });
  }

  getImportantDate() {
    if (!this.loanApplicationId) return;
    this.loanScenarioService
      .importantDateGet(this.loanApplicationId)
      .then(response => {
        if (!response && !response.data && !response.data.length) return;
        const importantDate = response.data.find(
          item => item.TypeOfDateID === LOAN_APP_STATUS_TYPES.SETTLEMENT_DATE_ID
        );
        this.isHasLoanSettlementDate = !!importantDate;
        this.importantDate = importantDate && importantDate.Date;
      });
  }

  ratesInit() {
    if (!this.productData) return;
    const {
      NewInitialRate,
      InitialRate,
      NewOngoingRate,
      OngoingRate,
      NewInitialBaseRate,
      NewOngoingBaseRate,
    } = this.productData;
    if (NewInitialRate) {
      this.initialRateData.isDetailShow = true;
      this.initialRateData.actualRate = NewInitialRate;
      this.initialRateData.discountRate = parseFloat(
        ((NewInitialBaseRate || InitialRate) - NewInitialRate).toFixed(2)
      );
      this.updateInitialRateDataPeriod();
      !this.isAddButtonShow &&
        this.getValidDate(this.initialRateData.loanPeriod);
    } else {
      this.initialRateData.actualRate = NewInitialBaseRate || InitialRate;
    }
    this.initialRateData.baseRate = NewInitialBaseRate || InitialRate;
    if (NewOngoingRate) {
      this.ongoingRateData.isDetailShow = true;
      this.ongoingRateData.actualRate = NewOngoingRate;
      this.ongoingRateData.discountRate = parseFloat(
        ((NewOngoingBaseRate || OngoingRate) - NewOngoingRate).toFixed(2)
      );
    } else {
      this.ongoingRateData.actualRate = NewOngoingBaseRate || OngoingRate;
    }
    this.ongoingRateData.baseRate = NewOngoingBaseRate || OngoingRate;
  }

  updateInitialRateDataPeriod() {
    const timePeriod = this.rateTerm || this.initialRatePeriod;
    if (!this.loanTimePeriod || !this.loanTimePeriod.length) return;
    this.initialRateData.loanPeriod = this.loanTimePeriod.find(
      item => parseInt(item.value, 10) === timePeriod
    );
  }

  saveFavouriteProductRates(rateType, removeDiscount = false) {
    if (!rateType) return;
    let NewRate;
    let BaseRate;
    let DiscountRate;
    let InterestRateTerm;
    if (rateType === this.rateState.INITIAL) {
      const {
        actualRate,
        baseRate,
        discountRate,
        loanPeriod,
      } = this.initialRateData;
      NewRate = actualRate;
      BaseRate = baseRate;
      DiscountRate = discountRate || 0;
      InterestRateTerm = loanPeriod.value || LIFE_OF_LOAN.VALUE;
    }
    if (rateType === this.rateState.ONGOING) {
      const { actualRate, baseRate, discountRate } = this.ongoingRateData;
      NewRate = actualRate;
      BaseRate = baseRate;
      DiscountRate = discountRate || 0;
      InterestRateTerm = LIFE_OF_LOAN.VALUE;
    }
    const { ProductID } = this.productData;
    NewRate = parseFloat(removeDiscount ? LIFE_OF_LOAN.VALUE : NewRate).toFixed(
      2
    );
    InterestRateTerm = removeDiscount ? LIFE_OF_LOAN.VALUE : InterestRateTerm;
    DiscountRate = parseFloat(
      removeDiscount ? LIFE_OF_LOAN.VALUE : DiscountRate
    ).toFixed(2);
    BaseRate = parseFloat(BaseRate).toFixed(2);

    const postData = {
      RateType: rateType,
      NewRate,
      InterestRateTerm,
      DiscountRate,
      BaseRate,
      RemoveDiscount: removeDiscount,
      BrokerEventID: this.brokerEventId,
      LoanStructureID: this.productLoanStructureId,
      ProductID,
    };
    this.setFavouriteProductRates(postData);
    if (this.showInitialRateInput) return;
    this.resendInitialRate(removeDiscount);
  }

  resendInitialRate(removeDiscount = false) {
    const { ProductID } = this.productData;
    const { baseRate, loanPeriod } = this.initialRateData;
    const { discountRate } = this.ongoingRateData;
    const NewRate = parseFloat(
      removeDiscount
        ? LIFE_OF_LOAN.VALUE
        : this.getActualRate(baseRate, discountRate)
    ).toFixed(2);
    const BaseRate = parseFloat(baseRate).toFixed(2);
    const DiscountRate = parseFloat(
      removeDiscount ? LIFE_OF_LOAN.VALUE : discountRate || 0
    ).toFixed(2);
    const InterestRateTerm = removeDiscount
      ? LIFE_OF_LOAN.VALUE
      : loanPeriod.value || LIFE_OF_LOAN.VALUE;

    const resendInitialRateData = {
      RateType: this.rateState.INITIAL,
      NewRate,
      InterestRateTerm,
      DiscountRate,
      BaseRate,
      RemoveDiscount: removeDiscount,
      BrokerEventID: this.brokerEventId,
      LoanStructureID: this.productLoanStructureId,
      ProductID,
    };
    this.setFavouriteProductRates(resendInitialRateData);
  }

  setFavouriteProductRates(data) {
    const { RemoveDiscount, ...postData } = data;
    this.setInterestRate();
    this.loanProfilerService
      .setFavouriteProductRates(postData)
      .then(response => {
        this.disableBaseRateField = false;
        if (!response.data) toastError();
      });
  }

  deleteDiscountRate(section) {
    const removeDiscount = true;
    if (section === this.rateState.INITIAL) {
      this.initialRateData.discountRate = 0;
      this.initialRateData.actualRate = this.getActualRate(
        this.initialRateData.baseRate,
        this.initialRateData.discountRate
      );
      this.saveFavouriteProductRates(section, removeDiscount);
      this.initialRateData.isDetailShow = false;
      this.initialRateData.isEditDetails = false;
      this.initialRateData.actualRate = 0;
      this.initialRateData.isDeleted = true;
      this.isAddButtonShow = false;
    } else {
      this.ongoingRateData.discountRate = 0;
      this.ongoingRateData.actualRate = this.getActualRate(
        this.ongoingRateData.baseRate,
        this.ongoingRateData.discountRate
      );
      this.saveFavouriteProductRates(section, removeDiscount);
      this.ongoingRateData.isDetailShow = false;
      this.ongoingRateData.isEditDetails = false;
      this.ongoingRateData.actualRate = 0;
      this.ongoingRateData.isDeleted = true;
      this.isAddButtonShow = false;
      if (this.ongoingRateData.isDeleted && !this.initialRateData.isDetailShow)
        this.initialRateData.isAnotherDiscountSet = false;
    }
    if (this.ongoingRateData.isDeleted && this.initialRateData.isDeleted) {
      this.initialRateData.isAnotherDiscountSet = false;
    }
  }

  saveDiscountRate(section) {
    const { rateValue, loanPeriod } = this.discountRate;
    if (!rateValue) return;
    const discountRate = parseFloat(rateValue);
    if (section === this.rateState.ONGOING) {
      this.ongoingRateData.discountRate = discountRate;
      this.ongoingRateData.actualRate = this.getActualRate(
        this.ongoingRateData.baseRate,
        this.ongoingRateData.discountRate
      );
      this.ongoingRateData.isDetailShow = true;
      this.ongoingRateData.isDeleted = false;
    } else {
      if (!loanPeriod) return;
      this.initialRateData.discountRate = discountRate;
      this.initialRateData.actualRate = this.getActualRate(
        this.initialRateData.baseRate,
        this.initialRateData.discountRate
      );
      this.initialRateData.isDetailShow = true;
      this.initialRateData.isDeleted = false;
      this.isAddButtonShow = false;
      if (!this.isDefaultRateTermSet) {
        this.initialRateData.loanPeriod = loanPeriod;
      }
      if (!this.isAddButtonShow) this.getValidDate(loanPeriod);
    }
    this.applyDiscount(section);
    const rateType =
      section === this.rateState.ANOTHER ? RATE_STATE.INITIAL : section;
    this.saveFavouriteProductRates(rateType);
  }

  getActualRate(baseRate, DiscountRate) {
    this.actualRateResult = parseFloat((baseRate - DiscountRate).toFixed(2));
    return this.actualRateResult;
  }

  applyDiscount(section) {
    this.resetDicountRate();
    switch (section) {
      case this.rateState.INITIAL:
        this.initialRateData.isInitialEntryShow = this.initialRateData
          .isEditDetails
          ? false
          : !this.initialRateData.isInitialEntryShow;
        this.ongoingRateData.isOngoingEntryShow = false;
        this.initialRateData.isInitialAnotherEntryShow = false;
        this.initialRateData.isDetailShow = this.initialRateData.actualRate;
        break;
      case this.rateState.ONGOING:
        this.ongoingRateData.isOngoingEntryShow = this.ongoingRateData
          .isEditDetails
          ? false
          : !this.ongoingRateData.isOngoingEntryShow;
        this.initialRateData.isInitialEntryShow = false;
        this.initialRateData.isInitialAnotherEntryShow = false;
        break;
      case this.rateState.ANOTHER:
        this.initialRateData.isInitialAnotherEntryShow = !this.initialRateData
          .isInitialAnotherEntryShow;
        this.initialRateData.isInitialEntryShow = false;
        this.ongoingRateData.isOngoingEntryShow = false;
        break;
      default:
        this.resetDicountRate();
    }
    this.initialRateData.isEditDetails = false;
    this.ongoingRateData.isEditDetails = false;
  }

  getValidDate(loanPeriod) {
    if (!loanPeriod || !loanPeriod.value) return;
    const { value } = loanPeriod;
    if (
      this.loanStructureData &&
      this.loanStructureData.InterestRateType === RATE_TYPE.FIXED
    ) {
      this.current =
        (this.loanStructureData && this.loanStructureData.FixedRateBegin) ||
        this.importantDate;
    } else {
      this.current = this.importantDate;
    }
    this.isValidDateShow = !!this.current;

    const days = 'months';
    this.currentDate = moment(this.current);
    this.futureDate = {
      year: moment(this.currentDate)
        .add(value, days)
        .format('YYYY'),
      month: moment(this.currentDate)
        .add(value, days)
        .format('MMMM'),
      date: moment(this.currentDate)
        .add(value, days)
        .format('D'),
    };
  }

  editDiscounts(section) {
    this.resetDicountRate();
    if (section === RATE_STATE.INITIAL) {
      this.initialRateData.isDeleted = false;
      this.initialRateData.isEditDetails = !this.initialRateData.isEditDetails;
      this.discountRate = {
        rateValue: this.initialRateData.discountRate,
        loanPeriod: this.initialRateData.loanPeriod,
      };
    } else {
      this.ongoingRateData.isDeleted = false;
      this.ongoingRateData.isEditDetails = !this.ongoingRateData.isEditDetails;
      this.discountRate = {
        rateValue: this.ongoingRateData.discountRate,
      };
    }
  }

  resetDicountRate() {
    this.discountRate = {
      rateValue: 0,
      loanPeriod:
        this.loanTimePeriod &&
        this.loanTimePeriod.length &&
        this.loanTimePeriod[0],
    };
  }

  showValidDates() {
    return (
      !this.isAddButtonShow &&
      this.isValidDateShow &&
      this.initialRateData.loanPeriod
    );
  }

  checkIfLifeOfLoan(value) {
    this.lifeOfLoanValue = 0;
    return parseInt(value, 10) === this.lifeOfLoanValue;
  }
}

export default LoanAppDiscountsRateCtrl;
