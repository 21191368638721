export const getFilteredExpense = data => {
  const filteredTypes = [];
  data &&
    data.forEach(item => {
      switch (item.Name) {
        case 'Credit Cards':
          break;
        case 'Mortgage Repayments':
          break;
        default:
          filteredTypes.push(item);
          break;
      }
    });

  return filteredTypes;
};

export const getSelectedFamilyData = (listExpense, selectedFamilyId) => {
  const selectedFamily = listExpense.find(o => {
    return o.FamilyId === selectedFamilyId;
  });
  const family = selectedFamily || { FamilyName: '' };
  return family;
};

export const mapFrequencyTypesData = frequencyData => {
  frequencyData &&
    frequencyData.map(o => {
      o.Desc = o.Name;
      o.Value = parseInt(o.Value, 10);

      return o;
    });
  return frequencyData;
};
