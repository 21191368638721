export const DEFAULT_INCOME_TYPES = {
  SALARY: 19,
  BONUS: 14,
  COMMISSION: 16,
  RENTAL: 23,
};

export const OPPORTUNITY_SERVICEABILITY_INCOME_TYPES = {
  SALARY: 12,
  BASE_INCOME: 20,
};
