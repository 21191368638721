export const LIST_OF_QUESTIONS = {
  DEPOSIT_SOURCE: 6,
  SPECIFIC_GOALS: 9,
  BANKRUPT: 10,
  BANKRUPT_NOTE: 11,
  CURRENT_DEBT: 12,
  CURRENT_DEBT_NOTE: 13,
  CIRCUMSTANCE: 14,
  CIRCUMSTANCE_NOTE: 15,
  OTHER_LOAN: 16,
  OTHER_LOAN_NOTE: 17,
  RISK_INSURANCE: 18,
  RISK_INSURANCE_NOTE: 19,
  HOME_INSURANCE: 20,
  HOME_INSURANCE_NOTE: 21,
  DEPOSIT_AMOUNT: 37,
  WHAT_ARE_YOU_WANTING_TO_DO: 43,
  IMPORTANT_DATES: 44,
  IMPORTANT_DATES_NOTE: 45,
  BANKING_WITH: 46,
  HAPPY_WITH_CURRENT_BANK: 47,
  HAPPY_WITH_CURRENT_BANK_NOTE: 48,
  REASON_FOR_TOP_UP: 108,
  BANK_TO_REFENANCE_FROM: 109,
  DIRECTOR_FOR_YEARS: 122,
  DIRECTOR_FOR_YEARS_NOTE: 123,
  RATE_PREFERENCES: 135,
  CHOOSE_RATE_PREFERENCE: 136,
  RATE_DURATION: 137,
  RATE_PREFERENCES_IMPORTANT: 138,
  RATE_IMPORTANT_DETAILS: 139,
  SPECIAL_RATE_PREFERENCES: 146,
  SPECIAL_CHOOSE_RATE_PREFERENCE: 147,
  SPECIAL_RATE_PREFERENCES_IMPORTANT: 148,
  SPECIAL_RATE_IMPORTANT_DETAILS: 149,
  REPAYMENT_PREFERENCE: 150,
  CHOOSE_REPAYMENT_PREFERENCE: 151,
  REPAYMENT_FREQUENCY: 152,
  REPAYMENT_INTEREST_ONLY: 153,
  REPAYMENT_OPTION: 154,
  REPAYMENT_IMPORTANT: 155,
  REPAYMENT_DETAILS: 164,
  REPAYMENT_OPTION_DETAILS: 165,
  CONFLICT_DECLARATION: 166,
  CONFLICT_DECLARATION_DETAILS: 167,
  OTHER_REQUIREMENTS_AND_OBJECTIVES: 168,
  OTHER_REQUIREMENTS_DETAILS: 169,
  REASON_FOR_REFINANCE: 170,
  REASON_FOR_REFINANCE_DETAILS: 171,
  EXISTING_CREDIT_CARD: 178,
  EXISTING_CREDIT_CARD_DETAILS: 179,
  EXPLAINED_CREDIT_CARD: 180,
  DISCUSSED_CREDIT_CARD: 181,
  REDRAW: 188,
  OFFSET_ACCOUNT: 189,
  PRINCIPAL_AND_INTEREST: 190,
  INTEREST_ONLY_REPAYMENTS: 192,
  LINE_OF_CREDIT: 193,
  INTEREST_IN_ADVANCE: 200,
  REDRAW_OTHER: 206,
  OFFSET_OTHER: 207,
  SPECIAL_FEATURE_RISKS: 185,
  RATE_RISKS: 184,
};

export const QUESTIONS_WITH_ASSOCIATED_RISKS = [
  LIST_OF_QUESTIONS.RATE_PREFERENCES,
  LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES,
  LIST_OF_QUESTIONS.REPAYMENT_PREFERENCE,
];

export const QUESTIONS_WITH_TEXTBOX = [
  LIST_OF_QUESTIONS.CONFLICT_DECLARATION_DETAILS,
  LIST_OF_QUESTIONS.OTHER_REQUIREMENTS_DETAILS,
  LIST_OF_QUESTIONS.REASON_FOR_REFINANCE_DETAILS,
  LIST_OF_QUESTIONS.EXISTING_CREDIT_CARD_DETAILS,
];

export const OPTIONAL_QUESTIONS = [
  LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS,
  LIST_OF_QUESTIONS.SPECIAL_RATE_IMPORTANT_DETAILS,
  LIST_OF_QUESTIONS.REPAYMENT_DETAILS,
  LIST_OF_QUESTIONS.REPAYMENT_OPTION_DETAILS,
];

export const DISPLAY_OPTIONS = {
  YES_NO_ONLY: 1,
  TEXT_ONLY: 2,
  TEXTBOX_ON_YES: 3,
  TEXTBOX_ON_NO: 4,
  SINGLE_DROPDOWN: 5,
  MULTI_DROPDOWN: 6,
  SINGLE_DROPDOWN_NO_FRAME: 7,
  CHECK_BOX: 8,
  TEXTBOX_WITH_DROPDOWN: 9,
  TEXT_FLOAT: 10,
  RADIO_BOX_ON_YES: 11,
  RADIO_BOX_ON_NO: 12,
  SINGLE_DROPDOWN_STRING: 14,
};

export const ASSOCIATED_QUESTION = [
  LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE,
  LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE,
  LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE,
];

export const QUESTION_TYPE = {
  FUNDING_DETAILS: {
    name: 'Loan / Funding Details',
    value: 12,
  },
};

export const STRING_OPTION = [
  DISPLAY_OPTIONS.TEXT_ONLY,
  DISPLAY_OPTIONS.TEXTBOX_ON_YES,
  DISPLAY_OPTIONS.TEXTBOX_ON_NO,
  DISPLAY_OPTIONS.SINGLE_DROPDOWN_STRING,
];

export const BOOL_OPTION = [
  DISPLAY_OPTIONS.YES_NO_ONLY,
  DISPLAY_OPTIONS.CHECK_BOX,
  DISPLAY_OPTIONS.RADIO_BOX_ON_YES,
  DISPLAY_OPTIONS.RADIO_BOX_ON_NO,
];

export const INT_OPTION = [
  DISPLAY_OPTIONS.SINGLE_DROPDOWN,
  DISPLAY_OPTIONS.MULTI_DROPDOWN,
  DISPLAY_OPTIONS.SINGLE_DROPDOWN_NO_FRAME,
];

export const DECIMAL_OPTION = [DISPLAY_OPTIONS.TEXT_FLOAT];

export const ANSWER_TYPE = {
  ANSWER_STRING: 'answerString',
  ANSWER_BOOL: 'answerBool',
  ANSWER_INT: 'answerInt',
  ANSWER_DECIMAL: 'answerDecimal',
};

export const RATE_TYPE = {
  FIXED: 'fixed',
  VARIABLE: 'variable',
  SPLIT: 'split',
};

export const RATE_IMPORTANT = {
  RISK: 'Risk',
  POTENTIAL_RATE_DECREASE: 'PotentialRateDecrease',
  RATE_RISK_AND_BENEFITS: 'RateRiskAndBenefits',
  PAYMENT_FLEXIBILITY: 'PaymentFlexibility',
  BUDGETING: 'Budgeting',
  OTHER: 'Other',
};

export const SPECIAL_RATE_IMPORTANT = {
  FAST_REPAYMENTS: 'FastRepayments',
  FAST_ACCESS_TO_FUNDS: 'FastAccessToFunds',
  FAX_PURPOSES: 'TaxPurposes',
  ACCESS_TO_PREPAID: 'AccessToPrepaidFunds',
  OTHER: 'Other',
};

export const REPAYMENT_OPTION = {
  LOAN_PRIOR: '1',
  DOWNSIZING: '2',
  INVESTMENT_INCOME: '3',
  CO_APPLICANT_INCOME: '4',
  OTHER: 'Other',
};

export const RATE_TYPE_IMPORTANT = {
  FIXED: [RATE_IMPORTANT.RISK, RATE_IMPORTANT.BUDGETING, RATE_IMPORTANT.OTHER],
  VARIABLE: [
    RATE_IMPORTANT.POTENTIAL_RATE_DECREASE,
    RATE_IMPORTANT.PAYMENT_FLEXIBILITY,
    RATE_IMPORTANT.OTHER,
  ],
  SPLIT: [
    RATE_IMPORTANT.RATE_RISK_AND_BENEFITS,
    RATE_IMPORTANT.PAYMENT_FLEXIBILITY,
    RATE_IMPORTANT.BUDGETING,
    RATE_IMPORTANT.OTHER,
  ],
};

export const REPAYMENT_PREFERENCE = {
  PRINCIPAL_AND_INTEREST: '1',
  INTEREST_ONLY_REPAYMENTS: '2',
  INTEREST_IN_ADVANCE: '3',
  LINE_OF_CREDIT: '4',
};

export const LIST_PURPOSE = {
  REFINANCE: 11,
};

export const REPAYMENT_IMPORTANT = {
  MINIMISE_INTEREST: '1',
  HIGHER_LENDING: '2',
  LOWER_DEPOSIT: '3',
  BUILD_UP: '4',
  ACCOMMODATE_TEMPORARY: '6',
  ACCOMMODATE_ANTICIPATED: '7',
  VARIABLE_INCOME: '8',
  INDEPENDENT_FINANCIAL: '9',
  ACCOUNTING_REASONS: '10',
  TAX_PURPOSES: '11',
  DISCOUNTS_INTEREST: '12',
  TO_ASSIST: '13',
  ASSIST_INVESTMENT: '14',
  ANTICIPATED_VARIABLE: '15',
  ACCESS_TO_FUNDS: '16',
  FLEXIBILITY_REPAYMENTS: '17',
  NO_NEED: '18',
  ONGOING_NEED: '19',
  OTHER: 'Other',
};

export const REPAYMENT_PREFERENCE_IMPORTANT = {
  PRINCIPAL_AND_INTEREST: [
    REPAYMENT_IMPORTANT.MINIMISE_INTEREST,
    REPAYMENT_IMPORTANT.HIGHER_LENDING,
    REPAYMENT_IMPORTANT.LOWER_DEPOSIT,
    REPAYMENT_IMPORTANT.BUILD_UP,
  ],
  INTEREST_ONLY_REPAYMENTS: [
    REPAYMENT_IMPORTANT.ACCOMMODATE_TEMPORARY,
    REPAYMENT_IMPORTANT.ACCOMMODATE_ANTICIPATED,
    REPAYMENT_IMPORTANT.VARIABLE_INCOME,
    REPAYMENT_IMPORTANT.INDEPENDENT_FINANCIAL,
    REPAYMENT_IMPORTANT.ACCOUNTING_REASONS,
  ],
  INTEREST_IN_ADVANCE: [
    REPAYMENT_IMPORTANT.INDEPENDENT_FINANCIAL,
    REPAYMENT_IMPORTANT.ACCOUNTING_REASONS,
    REPAYMENT_IMPORTANT.TO_ASSIST,
  ],
  LINE_OF_CREDIT: [
    REPAYMENT_IMPORTANT.ASSIST_INVESTMENT,
    REPAYMENT_IMPORTANT.ANTICIPATED_VARIABLE,
    REPAYMENT_IMPORTANT.ACCESS_TO_FUNDS,
    REPAYMENT_IMPORTANT.FLEXIBILITY_REPAYMENTS,
    REPAYMENT_IMPORTANT.NO_NEED,
    REPAYMENT_IMPORTANT.ONGOING_NEED,
  ],
};

export const SPECIAL_RATE_PREFERENCES = {
  OFFSET_ACCOUNT: '1',
  REDRAW: '2',
};

export const SPECIAL_RATE_PREFERENCES_IMPORTANT = {
  OFFSET_ACCOUNT: [
    SPECIAL_RATE_IMPORTANT.FAST_REPAYMENTS,
    SPECIAL_RATE_IMPORTANT.FAST_ACCESS_TO_FUNDS,
    SPECIAL_RATE_IMPORTANT.FAX_PURPOSES,
  ],
  REDRAW: [SPECIAL_RATE_IMPORTANT.ACCESS_TO_PREPAID],
};

export const OPTION_FEATURE = {
  OFFSET_ACCOUNT: 1,
  REDRAW: 2,
};

export const OPTION_QUESTION = {
  [LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT]: {
    [OPTION_FEATURE.OFFSET_ACCOUNT]: LIST_OF_QUESTIONS.OFFSET_ACCOUNT,
    [OPTION_FEATURE.REDRAW]: LIST_OF_QUESTIONS.REDRAW,
  },
  [LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE]: {
    [OPTION_FEATURE.OFFSET_ACCOUNT]: LIST_OF_QUESTIONS.OFFSET_ACCOUNT,
    [OPTION_FEATURE.REDRAW]: LIST_OF_QUESTIONS.REDRAW,
  },
  [LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE]: {
    [REPAYMENT_PREFERENCE.PRINCIPAL_AND_INTEREST]:
      LIST_OF_QUESTIONS.PRINCIPAL_AND_INTEREST,
    [REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS]:
      LIST_OF_QUESTIONS.INTEREST_ONLY_REPAYMENTS,
    [REPAYMENT_PREFERENCE.INTEREST_IN_ADVANCE]:
      LIST_OF_QUESTIONS.INTEREST_IN_ADVANCE,
    [REPAYMENT_PREFERENCE.LINE_OF_CREDIT]: LIST_OF_QUESTIONS.LINE_OF_CREDIT,
  },
};

export const CONFIRMATION_LABEL = {
  [LIST_OF_QUESTIONS.RATE_RISKS]: 'rate preference',
  [LIST_OF_QUESTIONS.REASON_FOR_REFINANCE_DETAILS]: 'repayment preference',
  [LIST_OF_QUESTIONS.SPECIAL_FEATURE_RISKS]: 'special feature preference',
};

export const RISK_CHECK_QUESTIONS = [
  LIST_OF_QUESTIONS.RATE_RISKS,
  LIST_OF_QUESTIONS.REASON_FOR_REFINANCE_DETAILS,
  LIST_OF_QUESTIONS.SPECIAL_FEATURE_RISKS,
];

export const TOP_PURPOSE_LIST = {
  EXISTING_HOME_OWNER: '14',
  FIRST_HOME: '12',
  INVESTMENT: '29',
  TOP_UP: '18',
};

export const APPLICATION_DATE_TYPE = {
  SETTLEMENT_DATE: 12,
  FINANCE_DATE: 2,
  AUCTION_DATE: 10,
};
