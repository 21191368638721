import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { videoNotFoundAlert } from 'Common/utilities/subscription';
import { grammaticallySeparateByComma } from 'Common/utilities/string';

class SubscriptionModalCtrl {
  constructor(
    $timeout,
    $window,
    contactService,
    usersSubscriptionService,
    configService,
    contactModelService,
    optionsService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$window = $window;
    this.contactService = contactService;
    this.usersSubscriptionService = usersSubscriptionService;
    this.videoNotFoundAlert = videoNotFoundAlert;
    this.configService = configService;
    this.contactModelService = contactModelService;
    this.optionsService = optionsService;
  }

  $onInit() {
    const {
      subscriptionProductId,
      productSubDetails,
      subDetailIDs,
    } = this.subscriptionData;
    this.isCheckedTerms = false;
    this.isLoading = true;
    this.totalPrice = 0;
    this.loadingMessage = 'Loading Available Plans';

    this.activeServices = [...subDetailIDs];

    this.isFactFind =
      subscriptionProductId === SUBSCRIPTIONTYPE.ONLINE_FACT_FIND;
    this.isMarketingAutomation =
      subscriptionProductId === SUBSCRIPTIONTYPE.MARKETING_AUTOMATION;
    this.isNurture = subscriptionProductId === SUBSCRIPTIONTYPE.NURTURE;
    this.isBankConnectExpenseAnalysis =
      subscriptionProductId === SUBSCRIPTIONTYPE.BANK_CONNECT_EXPENSES_ANALYSIS;
    this.isBankConnect =
      subscriptionProductId === SUBSCRIPTIONTYPE.BANK_CONNECT;
    this.isCreditCheck =
      subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK;
    this.subscriptionLabels = this.isMarketingAutomation
      ? this.getMarketingServices()
      : productSubDetails;
    this.checkIsShowPreLoader();
  }

  $onDestroy() {
    this.showPreloaderTimeout &&
      this.$timeout.cancel(this.showPreloaderTimeout);
  }

  getMarketingServices() {
    this.optionsService.getMarketingAutomation().then(response => {
      this.checkIsShowPreLoader();
      if (!response) return;
      this.subscriptionLabels = response;
      const totalLabelsPrice = this.subscriptionLabels.reduce(
        (currTotal, label) => currTotal + label.price,
        0
      );
      this.isBeta = totalLabelsPrice === 0;
      this.checkSelectedServices();
    });
  }

  checkSelectedServices() {
    const { subDetailIDs, isActive } = this.subscriptionData;
    const hasSelectedServices = !!(subDetailIDs && subDetailIDs.length);

    this.subscriptionLabels.map(data => {
      data.selected =
        !isActive || (hasSelectedServices && subDetailIDs.includes(data.value));
      return data;
    });
    this.calculateTotal();
  }

  calculateTotal() {
    this.totalPrice = 0;
    this.subscriptionData.subDetailIDs = [];
    this.selectedServices = this.subscriptionLabels.filter(
      value => value.selected
    );
    this.selectedServices.forEach(data => {
      this.subscriptionData.subDetailIDs.push(data.value);
      this.totalPrice += data.price;
    });
    this.checkCampaignChange();
  }

  checkUnsubscribeCampaign() {
    const { subDetailIDs, isActive } = this.subscriptionData;
    if (subDetailIDs && !subDetailIDs.length) {
      this.cancelSubscription();
    } else if (!isActive || !this.campaignUnsubscribe) {
      this.subscribeMarketingAutomation();
    } else {
      this.cancelSubscription();
    }
  }

  cancelSubscription() {
    const props = {
      familyId: this.familyId,
      unsubscribeText: this.unselectedCampaignsText,
      selectedServicesLength: this.selectedServices.length,
      subscriptionData: this.subscriptionData,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-now-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId" 
                  unsubscribe-text="vm.props.unsubscribeText" 
                  selected-services-length="vm.props.selectedServicesLength" 
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-now-modal>`,
      backdrop: 'static',
      windowClass: 'cancel-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(response => {
      this.subscriptionData = response;
      if (this.selectedServices.length) {
        this.subscribeNow();
      } else {
        this.closeModal();
      }
    });
  }

  switchButtonText() {
    const isActive = this.subscriptionData && this.subscriptionData.isActive;
    const defaultText = isActive ? 'Update Subscription' : 'Subscribe Now';
    this.buttonText =
      isActive && !this.subscriptionData.subDetailIDs.length
        ? 'Unsubscribe'
        : defaultText;
  }

  formatUnselectedCampaigns() {
    const unselectedCampaigns = [];
    this.subscriptionLabels.forEach(data => {
      if (this.unselectedCampaigns.includes(data.value)) {
        unselectedCampaigns.push(data.name);
      }
    });
    this.unselectedCampaignsText = grammaticallySeparateByComma(
      unselectedCampaigns
    );
  }

  checkCampaignChange() {
    this.switchButtonText();
    this.campaignUnsubscribe = false;
    this.unselectedCampaigns = [];
    this.activeServices.forEach(item => {
      if (!this.subscriptionData.subDetailIDs.includes(item)) {
        this.unselectedCampaigns.push(item);
        this.campaignUnsubscribe = true;
      }
    });
    this.formatUnselectedCampaigns();
  }

  toggleDiscountTable() {
    this.showDiscountTable = !this.showDiscountTable;
  }

  subscribe() {
    const { subscriptionProductId } = this.subscriptionData;
    if (!this.isCheckedTerms) return;
    this.isLoading = true;
    const checkValidToSubscribe =
      this.subscriptionData &&
      this.subscriptionData.subscriptionProductId &&
      this.orderedById;
    this.loadingMessage = 'Activating Subscription';
    this.subscriptionData.monthlyRecurringFee =
      subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK
        ? 0
        : this.subscriptionData.defaultPrice;
    this.subscriptionData.orderedById = this.orderedById;
    if (!checkValidToSubscribe) return;
    this.contactService
      .subscription(this.subscriptionData, this.familyId)
      .then(response => {
        if (!response) return;
        const { esignSignature } = this.configService.feature;
        const validToCheckSignature =
          esignSignature &&
          this.subscriptionData.subscriptionProductId ===
            SUBSCRIPTIONTYPE.E_SIGN;
        if (validToCheckSignature) {
          this.returnToParent();
        } else {
          this.checkIsShowPreLoader();
          this.isShowSuccess = true;
        }
      });
  }
  subscribeMarketingAutomation() {
    if (!this.isCheckedTerms) return;
    this.isLoading = true;
    const { subscriptionProductId, isActive } = this.subscriptionData;
    const checkValidToSubscribe = subscriptionProductId && this.orderedById;
    this.loadingMessage = isActive
      ? 'Updating Subscription'
      : 'Activating Subscription';
    if (checkValidToSubscribe) {
      const subscibeEndpoint = isActive ? 'updateSubscription' : 'subscription';
      this.subscriptionData.monthlyRecurringFee = this.totalPrice;
      this.subscriptionData.orderedById = this.orderedById;
      this.contactModelService[subscibeEndpoint](
        this.subscriptionData,
        this.familyId
      ).then(response => {
        if (!response) return;
        this.checkIsShowPreLoader();
        this.isShowSuccess = true;
      });
    }
  }

  checkIsShowPreLoader() {
    this.showPreloaderTimeout = this.$timeout(() => {
      this.isLoading = false;
      this.showPreloaderTimeout &&
        this.$timeout.cancel(this.showPreloaderTimeout);
    }, 1500);
  }

  returnToParent() {
    this.modalInstance.close({ getAllSubscribe: true });
  }
  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
  goToLink(link) {
    if (!link) return;
    this.$window.open(link, 'noopener, noreferrer');
  }
}

export default SubscriptionModalCtrl;
