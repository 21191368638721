import {
  DD_MMM_YYYY_FORMAT,
  ALT_DATE_INPUT_FORMATS,
} from 'Common/constants/dateConfigs';
import { isRequired } from 'Common/utilities/importantDates';

class LoanInformationImportantDatesCtrl {
  constructor(loanApplicationServices, currentUserService) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.currentUserService = currentUserService;
  }

  openDate(key) {
    this.isOpenDate[key] = true;
  }
  getLoanImportantDates() {
    this.loanApplicationServices.getLoanImportantDates().then(data => {
      this.importantDateData = data;
    });
  }
  $onInit() {
    this.isRequired = isRequired({ vm: this });
    this.importantDateData = [];
    this.getLoanImportantDates();
    this.importantDateForm = {};
    this.isOpenDate = {};
    this.format = DD_MMM_YYYY_FORMAT;
    this.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    this.dateInputFormats = ALT_DATE_INPUT_FORMATS;
  }
}

export default LoanInformationImportantDatesCtrl;
