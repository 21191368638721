export const INCOME_FREQUENCY = {
  ANNUALLY: 1,
  MONTHLY: 12,
  FORTNIGHTLY: 26,
  WEEKLY: 52,
  DAILY: 365,
  'HOURLY/WEEK': 0,
  'HOURLY/DAY': 0,
};

export const INCOME_FREQUENCY_TEXT = {
  ANNUALLY: 'ANNUALLY',
  MONTHLY: 'MONTHLY',
  FORTNIGHTLY: 'FORTNIGHTLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
  'HOURLY/WEEK': 'HOURLY/WEEK',
  'HOURLY/DAY': 'HOURLY/DAY',
};

// TODO : transfer to api
export const INCOME_TAX = {
  2019: {
    medicareDeductionRate: 2.0,
    lowIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 445,
      minThreshold: 37000,
      maxThreshold: 66667,
    },
    lowAndMiddleIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 530,
      minThreshold: 37000,
      maxThreshold: 66667,
      minYearEffective: 2018,
      maxYearEffective: 2022,
      brackets: [
        { from: 0, to: 37000, multiplier: 0, baseAmount: 200 },
        { from: 37001, to: 47999, multiplier: 3, baseAmount: 200 },
        { from: 48000, to: 90000, multiplier: 0, baseAmount: 530 },
        { from: 90001, to: 125333, multiplier: -1.5, baseAmount: 530 },
      ],
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 37000, multiplier: 19, baseTax: 0 },
      { from: 37001, to: 90000, multiplier: 32.5, baseTax: 3572 },
      { from: 90001, to: 180000, multiplier: 37, baseTax: 20797 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 54097 },
    ],
  },
  2018: {
    medicareDeductionRate: 2.0,
    lowIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 445,
      minThreshold: 37000,
      maxThreshold: 66667,
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 37000, multiplier: 19, baseTax: 0 },
      { from: 37001, to: 87000, multiplier: 32.5, baseTax: 3572 },
      { from: 87001, to: 180000, multiplier: 37, baseTax: 19822 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 54232 },
    ],
  },
};
