import { pluralize } from 'Common/utilities/string';

export function lengthOfTime(start, end) {
  if (!start || !end) return '';

  const years = start.diff(end, 'year');
  end.add(years, 'years');

  const months = start.diff(end, 'months');
  end.add(months, 'months');

  if (years < 0 || months < 0 || Number.isNaN(years) || Number.isNaN(months)) {
    return '0 months';
  } else if (+years === 0) {
    return pluralize(months, 'month');
  }

  return `${pluralize(years, 'year')} - ${pluralize(months, 'month')}`;
}
