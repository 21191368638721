import { displayInfo } from 'Common/utilities/alert';

class loanAmountForAliModal {
  constructor($timeout, $window, aliService, toaster) {
    'ngInject';

    this.$timeout = $timeout;
    this.$window = $window;
    this.aliService = aliService;
    this.toaster = toaster;
    this.aliQuote = {
      loanAmount: 0,
      isLoading: false,
      submitBtnText: 'Create Quote In ALI',
    };
  }

  $onInit() {
    this.isLoading = true;
    this.isShowSucess = false;
    this.isSubmitDisabled = false;
  }

  submitAliQuote() {
    this.aliQuote.isLoading = true;
    const data = {
      LoanAmount: this.aliQuote.loanAmount,
      FamilyId: this.familyId,
      ClientId: this.clientId,
    };

    this.aliService
      .storeAliQuote(data)
      .then(response => {
        const title = `We've got a problem`;
        if (!response.succeeded) return displayInfo(response, title);

        this.$window.open(response.data, '_blank');
        this.modalInstance.close();
      })
      .catch(error => {
        this.toaster.pop('error', 'Error', error.data.Message);
        this.aliQuote.isLoading = false;
      });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
}
export default loanAmountForAliModal;
