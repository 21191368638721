import { mapContactPointForComponent } from 'Common/utilities/providerInfo';
import { splitString } from 'Common/utilities/string';
import { PROVIDER_LOGO } from 'Common/constants/providerLogo';
import { PROVIDER_NAME } from 'Common/constants/lenderConstants';

export default class ProviderInfoCtrl {
  constructor(
    $state,
    providerInformationService,
    providerUploadDocsService,
    currentUserService
  ) {
    'ngInject';

    this.providerId = $state.params.providerId;
    this.providerInformationService = providerInformationService;
    this.providerUploadDocsService = providerUploadDocsService;
    this.currentUserService = currentUserService;
    this.favouritesTab = '';
  }

  $onInit() {
    this.providerName = this.currentUserService.isAU
      ? PROVIDER_NAME.AU
      : PROVIDER_NAME.DEFAULT;
    this.pageHeader = `${this.providerName} Information`;
    this.loadProviderInfo();
  }

  loadProviderInfo() {
    this.isLoading = true;
    this.providerInformationService
      .getProvidersInformation({ providerId: this.providerId })
      .then(response => {
        if (!response || !response.length) return;
        this.providerInfo = response[0];
        const { insurerId, lenderId } = this.providerInfo;
        this.insurerId = insurerId;
        this.providerLogo = this.getProviderLogo();
        this.providerIds = this.insurerId === 0 ? lenderId : insurerId;
        this.loadProviderContactPoints();
        this.getEmailsAndPhoneNumbers();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getProviderLogo() {
    const { countryCode, providerName } = this.providerInfo;

    const providerLogo = PROVIDER_LOGO[providerName] || providerName;
    return `assets/images/lenderLogoLg/${countryCode}/${providerLogo}@2x.png`;
  }

  getEmailsAndPhoneNumbers() {
    this.providerEmails =
      this.providerInfo && this.providerInfo.emailAddress
        ? splitString(this.providerInfo.emailAddress)
        : [];
    this.providerNumbers =
      this.providerInfo && this.providerInfo.phoneNumber
        ? splitString(this.providerInfo.phoneNumber)
        : [];
  }

  loadProviderContactPoints() {
    if (!this.providerInfo.lenderId) return;
    this.providerInformationService
      .getProviderContactPoints({ lenderId: this.providerInfo.lenderId })
      .then(response => {
        const providerContactPoints = response && response[0];
        if (!providerContactPoints) return;
        this.contactPoints = providerContactPoints.contactPoints.map(
          mapContactPointForComponent
        );
      });
  }

  hasContactInfo() {
    if (!this.providerInfo) return false;
    const { website, emailAddr, faxNumber, phoneNumber } = this.providerInfo;
    return website || emailAddr || faxNumber || phoneNumber;
  }

  toggleWidget(widgetType) {
    switch (widgetType) {
      case 'contactPoints':
        this.isContactPointsExpanded = !this.isContactPointsExpanded;
        break;
      case 'quickForms':
        this.isQuickDownloadsExpanded = !this.isQuickDownloadsExpanded;
        break;
      case 'keyContactPoints':
        this.keyContactPoints = !this.keyContactPoints;
        break;
      case 'toolsDownloads':
        this.isToolsDownloadsExpanded = !this.isToolsDownloadsExpanded;
        break;
      default:
    }
  }
}
