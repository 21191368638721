import angular from 'angular';
import _ from 'lodash';
import { FINANCIAL_TYPE } from 'Common/constants/financialFrequency';

angular
  .module('app')
  .controller('FinancialLiabilitiesCtrl', function FinancialLiabilitiesCtrl(
    $rootScope,
    $scope,
    $timeout,
    contactService,
    loanScenarioService,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    optionsService,
    SweetAlert,
    financeService,
    financialsSharedService,
    uiService,
    crmConfirmation
  ) {
    $scope.financialsSharedService = financialsSharedService;
    $scope.listRepaymentFrequency = [];
    optionsService.FinancialFrequency().then(response => {
      _.map(response.data, o => {
        o.Desc = o.Name;
        o.Value = parseInt(o.Value, 10);

        return o;
      });

      $scope.listRepaymentFrequency = response.data;
    });

    $scope.Settings = {
      collapseList: true,
    };

    $scope.$watch('selectedContacts', newValue => {
      if (newValue === 'financials') {
        $scope.liabilitiesInit(true);
      }
    });

    $scope.deleteAllLiabilities = () => {
      crmConfirmation.open({
        type: 'warning',
        title: 'Hold your horses!',
        description: `Are you sure you want to delete all of this client's Liabilities?<br> This action not reversible.`,
        buttonText: `Yes, I'm sure`,
        onConfirm: $scope.deleteConfirm,
        showCloseButton: true,
        showCancelButton: true,
        modalSize: 'md',
        cancelButtonClass: 'colored',
      });
    };

    $scope.deleteConfirm = () => {
      const familyId = $stateParams.familyId;
      contactService
        .deleteInFinancialTab(familyId, 'liability')
        .then(({ data }) => {
          if (data) {
            $scope.Settings.collapseList = true;
            $scope.liabilitiesInit(true);
          }
        });
    };

    // Models
    angular.extend($scope, {
      loanAppId: $stateParams.loanAppId,
      familyId: $stateParams.familyId,
      listLiabilities: [],
      listLender: [],
      listLenderSearch: [],
      liabilityTypes: [],
      displayLiabilitiesForm: false,
      modalType: false,
      //* ************************************/
      //  owners multi Select                /
      //* ************************************/
      localLang: {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: "<div class='buttonLabel'>All Clients</div>", // default-label is deprecated and replaced with this.
      },
      LiabilityTotalAmount: 0,
      showCreditorResults: false,
    });

    // Methods
    angular.extend($scope, {
      getNewLiabilityModel(familyId) {
        return {
          IsRefinance: false,
          AccountName: '',
          AccountNumber: '',
          BSB: '',
          Limit: null,
          CreditorId: 0,
          CreditorName: '',
          InterestTaxDeductible: null,
          RepaymentFrequencyId: 4,
          Repayments: null,
          InterestRate: null,
          IsAddToExpenses: true,
          PriorityId: 0,
          IsAccountDetailsKnown: false,
          Expense: {
            Repayments: null,
            Id: 0,
            LoanScenarioId: $scope.loanAppId,
            FamilyId: familyId,
            FinancialId: 0,
            Type: '',
            Description: '',
            Amount: null,
            CurrencyCode: '',
            Category: '',
            CurrencyCodeId: '',
            Borrowers: [],
            FrequencyID: 3,
            FrequencyName: '',
            Provider: '',
          },
          Id: 0,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: familyId,
          FinancialId: 0,
          Type: null,
          TypeId: 0,
          Description: '',
          Amount: null,
          CurrencyCode: 'USD',
          Category: '',
          CurrencyCodeId: '1',
          Borrowers: [],
          FrequencyID: 3,
          FrequencyName: '',
          Provider: '',
          InArrears: null,
          LoanStartDate: '',
          InterestOnlyEndDate: '',
          DocumentedLoanTerm: '',
          IsRevolvingCredit: false,
        };
      },
      IsBNZRequirement() {
        const vm = this;
        if (
          $rootScope.crmCountry === 'New Zealand' &&
          !financialsSharedService.LiabilitiesSet.IsRefinance &&
          _.find(vm.LiabilitiesLoanBNZRequirements, o => {
            return (
              financialsSharedService.LiabilitiesSet.Type &&
              o === financialsSharedService.LiabilitiesSet.Type.Name
            );
          })
        )
          return true;
        return false;
      },
      LiabilitiesLoanBNZRequirements: [
        'Personal Loan',
        'Home Loan',
        'Business Loan',
      ],
      LiabilitiesDateSettings: {
        format: 'dd MMM yyyy',
        altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
        dateOptions: {
          formatYear: 'yy',
          showWeeks: false,
        },
        datePopUp: {
          StartDate: false,
          EndDate: false,
        },
        openDate(dateField) {
          $scope.LiabilitiesDateSettings.datePopUp[dateField] = !$scope
            .LiabilitiesDateSettings.datePopUp[dateField];
        },
      },
      LiabilityInfoGet() {
        contactService.liabilityInfoGet($scope.familyId).then(response => {
          $scope.LiabilityTotalAmount = 0;
          const { data: liability } = response;
          _.forEach(liability.FinancialInfo, financialInfo => {
            if (!_.isUndefined(financialInfo.RepaymentFrequencyName))
              financialInfo.FrequencyInitial = financialInfo.RepaymentFrequencyName.charAt(
                0
              );
            _.map(financialInfo.Borrowers, borrower => {
              borrower.OwnershipInitials = utilitiesService.filterInitial(
                borrower.FirstName,
                borrower.LastName ? borrower.LastName : ''
              );
              return borrower;
            });
          });

          $scope.LiabilityTotalAmount += liability.TotalAmount;

          $scope.financials.liabilityTotal = $scope.LiabilityTotalAmount;
          $scope.listLiabilities = response.data;

          if (_.size($scope.listLiabilities.FinancialInfo) > 0) {
            $scope.Settings.collapseList = false;
          }

          $scope.SetupConfirmLiabilityRequirements();
        });
      },

      SetupConfirmLiabilityRequirements() {
        financialsSharedService.setLiabilities(
          $scope.listLiabilities.FinancialInfo
        );
        const checkLiabilityItemsExpiration = () => {
          $scope.infoCheckLiability = financialsSharedService.checkExpiredFinancialInfo(
            $scope.listLiabilities.FinancialInfo,
            FINANCIAL_TYPE.LIABILITY
          );
        };
        financialsSharedService.checkLiabilityItemsExpiration = checkLiabilityItemsExpiration;
        checkLiabilityItemsExpiration();
      },

      OnConfirmLiability() {
        financialsSharedService
          .confirmFinancialLiabilities($scope.listLiabilities.FinancialInfo)
          .then(() => $scope.SetupConfirmLiabilityRequirements());
      },

      LiabilityInfoDelete(liabilityId) {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your liability list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          confirm => {
            if (confirm) {
              contactService
                .deleteLiability(liabilityId, $scope.familyId)
                .then(() => {
                  $timeout(() => {
                    $scope.liabilitiesInit(false);
                    financialsSharedService.liabilityDeleteSuccess();
                  }, 100);
                });
            }
          }
        );
      },
      LiabilityDetailsGet(familyId, liabilityId) {
        if (_.isUndefined(liabilityId)) return false;

        contactService
          .getLiabilityDetails(familyId, liabilityId)
          .then(response => {
            // reset the actual model
            $scope.resetLiabilityModel('edit', familyId);

            // patch api data to actual model
            angular.extend(
              financialsSharedService.LiabilitiesSet,
              response.data
            );

            // patch temporary type to actual model
            angular.extend(financialsSharedService.LiabilitiesSet, {
              Type: _.find($scope.liabilityTypes, o => {
                return (
                  parseInt(o.Value, 10) ===
                  parseInt(financialsSharedService.LiabilitiesSet.TypeId, 10)
                );
              }),
              CreditorId: financialsSharedService.LiabilitiesSet.CreditorId
                ? String(financialsSharedService.LiabilitiesSet.CreditorId)
                : '',
              LoanStartDate: new Date(
                financialsSharedService.LiabilitiesSet.LoanStartDate
              ),
              InterestOnlyEndDate: new Date(
                financialsSharedService.LiabilitiesSet.InterestOnlyEndDate
              ),
            });

            // Get clients of particular liability
            $scope.LiabilityClientGet(
              financialsSharedService.LiabilitiesSet.Id,
              familyId,
              angular.copy(financialsSharedService.LiabilitiesSet.Borrowers)
            );

            // Open the edit modal
            $scope.LiabilitiesModal('edit');
          });
      },
      LiabilityClientGet(liabilityIdParam, familyId, borrowers) {
        let liabilityId = liabilityIdParam;
        if (_.isUndefined(liabilityId)) {
          liabilityId = 0;
        }
        financialsSharedService.liabilityOwnersListSelected = [];

        contactService.borrowersDetailsGet(familyId, 0).then(respond => {
          financialsSharedService.liabilityOwnersListSelected = borrowers || [];
          const temp = {
            Percentage: 0,
            BorrowerID: 0,
            FirstName: 'All',
            LastName: 'Clients',
            PreferedName: 'Joint',
            ticked: true,
            disabled: true,
          };
          if (
            financialsSharedService.liabilityOwnersListSelected.length < 1 ||
            financialsSharedService.liabilityOwnersListSelected.length ===
              respond.data.length
          ) {
            const tmp = [];
            respond.data &&
              Object.keys(respond.data).forEach(x => {
                const value = respond.data[x];
                value.PreferedName = `${value.FirstName} ${value.LastName}`;
                tmp.push(value);
              });
            if (tmp.length === 1) {
              tmp[0].ticked = true;
            } else {
              tmp.unshift(temp);
            }
            financialsSharedService.liabilityOwnersList = tmp;
          } else {
            temp.ticked = false;
            const tmp = [];
            // Set default selected from edit
            // @TODO : Edit should match a borrowers array
            if (_.size(borrowers) <= _.size(respond.data)) {
              respond.data &&
                Object.keys(respond.data).forEach(x => {
                  const value = respond.data[x];
                  value.ticked = !!_.find(borrowers, o => {
                    return o.BorrowerID === value.BorrowerID;
                  });
                  value.PreferedName = `${value.FirstName} ${value.LastName}`;
                  tmp.push(value);
                });
            } else {
              respond.data &&
                Object.keys(respond.data).forEach(x => {
                  const value = respond.data[x];
                  value.ticked = value.BorrowerID === -1;
                  value.PreferedName = `${value.FirstName} ${value.LastName}`;
                  tmp.push(value);
                });
            }
            if (respond.data.length > 1) {
              tmp.unshift(temp);
            }

            financialsSharedService.liabilityOwnersList = tmp;
          }
        });
      },
      resetOwnersSelected() {
        financialsSharedService.liabilityOwnersListSelected = _.filter(
          financialsSharedService.liabilityOwnersList,
          obj => obj.ticked
        );
      },
      untickNonJoint() {
        financialsSharedService.liabilityOwnersList = _.map(
          financialsSharedService.liabilityOwnersList,
          obj => {
            if (obj.BorrowerID !== 0) {
              obj.ticked = false;
            }
            return obj;
          }
        );
      },
      untickJoint() {
        financialsSharedService.liabilityOwnersList = _.map(
          financialsSharedService.liabilityOwnersList,
          obj => {
            if (obj.BorrowerID === 0) {
              obj.ticked = false;
            }
            return obj;
          }
        );
      },
      onStudentNoOwnershipSwal() {
        SweetAlert.swal({
          title: 'Ooops',
          text: 'Student Loan should have at least 1 ownership',
          type: 'warning',
          showConfirmButton: true,
        });
      },
      onStudentJointSwal() {
        SweetAlert.swal({
          title: 'Ooops',
          text: 'Student Loan cannot have Joint ownership',
          type: 'warning',
          showConfirmButton: true,
        });
      },
      nzStudentLoanChecker(isStudent) {
        return (
          uiService.isCountry('New Zealand') &&
          financialsSharedService.LiabilitiesSet &&
          financialsSharedService.LiabilitiesSet.Type &&
          (isStudent
            ? financialsSharedService.LiabilitiesSet.Type.Name ===
              'Student Loan'
            : financialsSharedService.LiabilitiesSet.Type.Name !==
              'Student Loan')
        );
      },
      onItemSelect(data) {
        if (
          data.BorrowerID === 0 &&
          data.ticked &&
          $scope.nzStudentLoanChecker(true)
        ) {
          $scope.onStudentJointSwal();
          financialsSharedService.liabilityTickOwnersList(data, false);
        } else if (
          data.BorrowerID === 0 &&
          data.ticked &&
          (!uiService.isCountry('New Zealand') ||
            $scope.nzStudentLoanChecker(false))
        ) {
          $scope.untickNonJoint();
        } else if (data.BorrowerID !== 0 && data.ticked) {
          $scope.untickJoint();

          if ($scope.nzStudentLoanChecker(true)) {
            // count how many owners are selected
            if (financialsSharedService.liabilityCountOwnersSelected() === 2) {
              financialsSharedService.liabilityTickOwnersList(
                data,
                false,
                true
              );
            }
          }
        } else if (data.BorrowerID !== 0 && !data.ticked) {
          if ($scope.nzStudentLoanChecker(true)) {
            // count how many owners are selected
            if (financialsSharedService.liabilityCountOwnersSelected() === 0) {
              $scope.onStudentNoOwnershipSwal();
              financialsSharedService.liabilityTickOwnersList(data, true);
            }
          }
        }

        $scope.resetOwnersSelected();

        if ($scope.nzStudentLoanChecker(true)) {
          financialsSharedService.checkStudentRepayment();
        }
      },
      onSelectAll() {
        const findJoint = _.find(
          financialsSharedService.liabilityOwnersList,
          obj => {
            return obj.BorrowerID === 0;
          }
        );
        // need to check if single owner
        if (!_.isEmpty(findJoint)) {
          financialsSharedService.liabilityOwnersList = _.map(
            financialsSharedService.liabilityOwnersList,
            obj => {
              obj.ticked = obj.BorrowerID === 0;
              return obj;
            }
          );
        } else {
          for (
            let i = 0;
            i < financialsSharedService.liabilityOwnersList.length;
            i++
          ) {
            if (
              financialsSharedService.liabilityOwnersList[i].BorrowerID > -1
            ) {
              financialsSharedService.liabilityOwnersList[i].ticked = false;
            } else {
              financialsSharedService.liabilityOwnersList[i].ticked = true;
            }
          }
        }
      },

      LenderListGet(lenderId) {
        const lenderPlace = 0;
        contactService.lenderListGet(lenderId, lenderPlace).then(response => {
          $scope.listLender = response.data;
        });
      },
      resetLiabilityModel(type, familyIdParam) {
        let familyId = familyIdParam;
        if (type !== 'edit') {
          familyId = $scope.familyId;
        }

        financialsSharedService.LiabilitiesSet = $scope.getNewLiabilityModel(
          familyId
        );
      },
      liabilitiesInit(isFull) {
        if (_.isUndefined($scope.selectedFamily)) {
          $scope.selectedFamily = {
            FamilyId: parseInt($scope.familyId, 10) /* .toString() */,
            initials: '',
          };
        }

        $scope.listLiabilities = [];

        if (isFull) {
          $scope.liabiliTypeTemplates = financialsSharedService.liabilityTypeTemplate();
          financialsSharedService.getLiabilityType($scope);
          $scope.LenderListGet();
        }

        $scope.resetLiabilityModel();
        $scope.LiabilityInfoGet(isFull);
        $scope.LiabilityClientGet(undefined, $state.params.familyId);
      },
      changeLiability() {
        financeService.changeLiabilityType($scope);
      },
      addLiability() {
        const joinFamilyObj = _.find(
          financialsSharedService.liabilityOwnersList,
          obj => {
            return obj.BorrowerID === 0;
          }
        );

        if (joinFamilyObj) {
          if (joinFamilyObj.ticked) {
            financialsSharedService.liabilityOwnersListSelected = _.map(
              financialsSharedService.liabilityOwnersList,
              obj => {
                if (obj.BorrowerID !== 0) {
                  obj.ticked = true;
                  return obj;
                }
              }
            );
          }
        } else {
          financialsSharedService.liabilityOwnersListSelected = _.filter(
            financialsSharedService.liabilityOwnersList,
            obj => {
              return obj.ticked === true;
            }
          );
        }

        financialsSharedService.LiabilitiesSet.Borrowers = [];
        financialsSharedService.liabilityOwnersList.forEach(data => {
          if (data.ticked && data.BorrowerID !== 0) {
            financialsSharedService.LiabilitiesSet.Borrowers.push({
              Percentage: 0,
              BorrowerID: data.BorrowerID,
              IsEntity: data.IsEntity,
            });
          }
        });

        financialsSharedService.LiabilitiesSet.TypeId =
          financialsSharedService.LiabilitiesSet.Type.Value;
        financialsSharedService.LiabilitiesSet.Type =
          financialsSharedService.LiabilitiesSet.Type.Desc;

        // action taken : change the familyIds of the model
        angular.extend(financialsSharedService.LiabilitiesSet, {
          FamilyId: $scope.familyId.toString(),
        });

        angular.extend(financialsSharedService.LiabilitiesSet.Expense, {
          FamilyId: $scope.familyId.toString(),
        });

        financialsSharedService.LiabilitiesSet.Expense = {
          Repayments: financialsSharedService.LiabilitiesSet.Repayments,
          Id: financialsSharedService.LiabilitiesSet.Id,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: $scope.familyId,
          FinancialId: financialsSharedService.LiabilitiesSet.FinancialId,
          Type: financialsSharedService.LiabilitiesSet.Type,
          Description: financialsSharedService.LiabilitiesSet.Description,
          Amount: financialsSharedService.LiabilitiesSet.Amount,
          CurrencyCode: financialsSharedService.LiabilitiesSet.CurrencyCode,
          Category: financialsSharedService.LiabilitiesSet.Category,
          CurrencyCodeId: financialsSharedService.LiabilitiesSet.CurrencyCodeId,
          Borrowers: financialsSharedService.LiabilitiesSet.Borrowers,
          FrequencyID: financialsSharedService.LiabilitiesSet.FrequencyID,
          FrequencyName: financialsSharedService.LiabilitiesSet.FrequencyName,
          Provider: financialsSharedService.LiabilitiesSet.Provider,
        };

        contactService
          .liabilityDetailsSet(financialsSharedService.LiabilitiesSet)
          .then(() => {
            $scope.liabilitiesInit(false);
            $rootScope.$broadcast('UPDATE_EXPENSE');
          });
      },
      LiabilitiesModal(type) {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/contacts/client/financial/modal/liabilities.html',
          controller($uibModalInstance, modalType) {
            $scope.Title = modalType === 'new' ? 'Add' : 'Edit';

            $scope.cancel = () => {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.saveBreakdown = () => {
              $scope.addLiability();
              $scope.cancel();
              $scope.displayLiabilitiesForm = false;
            };
          },
          size: 'md',
          scope: $scope,
          resolve: {
            modalType() {
              return type;
            },
          },
          backdrop: 'static',
          keyboard: false,
        });

        modalInstance.result.then(
          () => {
            $scope.resetLiabilityModel('edit', $scope.familyId);
          },
          () => {
            $scope.resetLiabilityModel('edit', $scope.familyId);
          }
        );
      },
      selectLiability(familyId, liabilityId) {
        $scope.LiabilityDetailsGet(familyId, liabilityId);
      },
      selectLiabilityTemp(liability) {
        angular.extend(financialsSharedService.LiabilitiesSet, liability);
        financialsSharedService.LiabilitiesSet.Type = _.filter(
          $scope.liabilityTypes,
          o => {
            return o.Desc === financialsSharedService.LiabilitiesSet.Type;
          }
        )[0];
        $scope.LiabilitiesModal('edit');
      },
      LiabilityInfoDeleteTemp(liability) {
        _.remove($scope.listLiabilities[0].FinancialInfo, o => {
          return _.isEqual(o, liability);
        });
      },
      cardType() {
        loanScenarioService.subLiabilityTypeGet(0).then(response => {
          $scope.subLiabilityTypeList = response.data;
        });
      },
      LiabilitiesSet: {
        LiabilitySubTypeID: -1,
      },
      clearSearchCreditor() {
        $timeout(() => {
          $scope.showCreditorResults = false;
          $scope.listLenderSearch = [];
        }, 800);
      },
      searchCreditorMethod() {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          $scope.listLenderSearch = _.filter($scope.listLender, lender => {
            const sourceString = _.lowerCase(lender.ProviderName);
            const checkString = _.lowerCase(
              financialsSharedService.LiabilitiesSet.CreditorName
            );

            return financialsSharedService.LiabilitiesSet.CreditorName
              ? sourceString.indexOf(checkString) !== -1
              : false;
          });
        }, 800);

        angular.extend(financialsSharedService.LiabilitiesSet, {
          CreditorId: 0,
        });
      },
      pickedCreditorLookup(lender) {
        angular.extend(financialsSharedService.LiabilitiesSet, {
          CreditorId: lender.ProviderId,
          CreditorName: lender.ProviderName,
        });
      },
    });

    $scope.cardType();

    // Watcher
    $scope.$watch('listLiabilities', () => {
      let length = 0;
      if (_.size($scope.listLiabilities) > 0) {
        _.forEach($scope.listLiabilities, o => {
          length += _.size(o.FinancialInfo);
        });

        $rootScope.$broadcast('updateLiability', {
          length,
          TotalAmount: $scope.LiabilityTotalAmount,
        });
      } else {
        $rootScope.$broadcast('updateLiability', { length: 0, TotalAmount: 0 });
      }
    });

    // Watch for changes in selected family
    $scope.$watch('selectedFamily.FamilyId', nv => {
      $scope.LiabilityClientGet(undefined, nv);
    });

    // caller

    // initialize again this section because there are changes on application section
    $rootScope.$on('REFRESH_APPLICANT_DEPENDENT_SECTIONS', () => {
      $scope.liabilitiesInit(true);
    });

    $rootScope.$on('REFRESH_LIABILITY', () => {
      $scope.liabilitiesInit(true);
    });

    $scope.financialsSharedService.refreshLiabilityDeleted = () => {
      $scope.liabilitiesInit(true);
      $scope.Settings.collapseList = true;
    };
  });
