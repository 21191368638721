import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';

export default angular
  .module('app.services.onboardingDataService', [])
  .factory('onboardingDataService', function onboardingDataService(
    onboardingService,
    corporateService,
    contactService,
    dataService,
    $q
  ) {
    /*
     *   =====================================
     *         FUNCTION DEFINITION(S)
     *   =====================================
     */
    let onboardingCompletedData = {
      entityDetails: {},
      asicAppointment: {},
      principalDetails: {},
      individualDetails: {},
      questionniare: {},
      additionalStaff: {},
      requiredDocuments: {},
    };
    let familyIdVal = 0;
    let clientIdVal = 0;
    let userCurrentEntityType = '';
    let userCurrentBrandingCategory = 0;
    let userCurrentCountryId = 0;
    let isRoleTypeVal = 0;
    let familyIds = [];
    const unsavedChanges = {
      additionalParties: false,
      additionalStaff: false,
    };
    /*
     * SETTER FUNCTION: revolves in parent controller to
     * prevent calling multiple APIS on each controller.
     */
    const setFamilyId = familyId => {
      familyIdVal = familyId;
    };

    const setClientId = clientId => {
      clientIdVal = clientId;
    };

    const setUserCurrentEntityType = entityType => {
      userCurrentEntityType = entityType;
    };

    const setBrandingCategory = brandingCategoryId => {
      userCurrentBrandingCategory = brandingCategoryId;
    };

    const setUserCurrentCountry = countryId => {
      userCurrentCountryId = countryId;
    };

    const setFamilyIds = familyIdArr => {
      familyIds = [...familyIdArr];
    };

    const setUnsavedChanges = (tab, flag = true) => {
      unsavedChanges[tab] = flag;
    };
    /*
     * GETTER FUNCTION: revolves in parent controller to
     * prevent calling multiple APIS on each controller.
     */

    const getFamilyIds = () => {
      return familyIds;
    };

    const getAllOnboardingDataForPreview = () => {
      return onboardingCompletedData;
    };

    const setAllOnboardingDataForPreview = onboardingCompleted => {
      onboardingCompletedData = Object.assign(
        onboardingCompletedData,
        onboardingCompleted
      );
      getAllOnboardingDataForPreview();
    };

    const setIsRoleType = roleId => {
      isRoleTypeVal = roleId;
    };

    /*
     * GETTER FUNCTION: revolves in parent controller to
     * prevent calling multiple APIS on each controller.
     */
    const getClientId = () => {
      return clientIdVal;
    };

    const getFamilyId = () => {
      return familyIdVal;
    };

    const getCountryId = () => {
      return userCurrentCountryId;
    };

    const getUserCurrentEntityType = () => {
      return userCurrentEntityType;
    };
    /*
     * CHECKER FUNCTION
     */
    const isRoleType = roleId => {
      if (roleId === isRoleTypeVal) {
        return true;
      }
      return false;
    };

    const isUserCurrentEntityType = entityType => {
      if (entityType === userCurrentEntityType) {
        return true;
      }
      return false;
    };

    const isOnboardingUserRole = (userRoles, roleType) => {
      const roles = userRoles.split(',').map(item => {
        return parseInt(item.trim(), 10);
      });
      return roles.indexOf(roleType) !== -1;
    };

    const isUserCurrentCountry = countryId => {
      if (userCurrentCountryId === countryId) {
        return true;
      }
      return false;
    };

    const isCountryNewZealand = countryId => {
      if (countryId === COUNTRY_TYPE.NEW_ZEALAND) {
        return true;
      }
      return false;
    };

    const isOwnBrand = ownBrand => {
      if (userCurrentBrandingCategory === ownBrand) {
        return true;
      }
      return false;
    };

    const suggestPreferredEmail = (fname, lname) => {
      const fnameSplit = fname ? fname.split(' ') : '';
      const lnameSplit = lname ? lname.split(' ') : '';
      let suggestion = '';
      if (fname && !lname) {
        suggestion = `${fname.toLowerCase()}`;
      } else if (!fname && lname) {
        suggestion = `${lname.toLowerCase()}`;
      } else {
        suggestion = `${fnameSplit[0].toLowerCase()}.${lnameSplit[0].toLowerCase()}`;
      }
      return suggestion;
    };

    const hasUnsavedChanges = tab => {
      return unsavedChanges[tab];
    };
    /*
     * API CALLS in multiple controller
     */
    const getAdviserOrgDetails = () => {
      const defer = $q.defer();
      onboardingService
        .getOnboardingAdviserOrganisationDetails(getFamilyId(), getClientId())
        .then(
          response => {
            if (response && response.data) {
              setUserCurrentEntityType(response.data.EntityType);
              return defer.resolve(response.data);
            }
          },
          err => {
            return defer.reject(err);
          }
        );
      return defer.promise;
    };

    const getOnboardingResponse = (familyId, clientId) => {
      const defer = $q.defer();
      if (familyId) {
        onboardingService.getRecruitmentContact(familyId, clientId).then(
          response => {
            if (response && response.data) {
              return defer.resolve(response.data[0]);
            }
          },
          err => {
            return defer.reject(err);
          }
        );
      }
      return defer.promise;
    };

    const getFamilyBrandingCategory = () => {
      const defer = $q.defer();
      onboardingService
        .getFamilyBrandingCategory(getFamilyId(), getClientId())
        .then(
          response => {
            if (response && response.data) {
              setBrandingCategory(response.data.BrandingCategoryId);
              return defer.resolve(response.data);
            }
          },
          err => {
            return defer.reject(err);
          }
        );

      return defer.promise;
    };

    const getOnboardingTeamList = countryId => {
      let teamData = [];
      const IMAGE_BASE_URL = 'assets/images/team-member/';
      if (countryId === COUNTRY_TYPE.AUSTRALIA) {
        teamData = [
          {
            picture: `${IMAGE_BASE_URL}logo.png`,
            name: 'Onboarding Team',
            address: 'NSW, ACT, VIC, TAS, QLD',
            email: 'onboarding@loanmarket.com.au',
            phone: '(07) 3231 2167',
            is_image: true,
          },
          {
            picture: `${IMAGE_BASE_URL}holly@2x.png`,
            name: 'Holly Hammond',
            address: 'SA',
            email: 'holly.hammond@loanmarket.com.au',
            phone: '(08) 841 87044',
            is_image: true,
          },
          {
            picture: `${IMAGE_BASE_URL}shellay@2x.png`,
            name: 'Shelley Tetlaw',
            address: 'WA',
            email: 'shelley.tetlaw@loanmarket.com.au',
            phone: '(08) 942 262 200',
            is_image: true,
          },
        ];
      } else {
        teamData = [
          {
            picture: `${IMAGE_BASE_URL}logo.svg`,
            name: 'NZ Financial Services Group',
            address:
              '22 Kings Crescent, Lower Hutt PO Box 31 519, Lower Hutt 5040',
            email: 'adviserservices@nzfsg.co.nz',
            phone: '(0508) 87 87 88',
            is_image: true,
          },
        ];
      }

      return teamData;
    };

    const getTabsRequiredField = (mainElem, activeTab) => {
      const percentages = {};

      // angular.element(`${mainElem}`).each((index, elem) => {
      if (!percentages[activeTab]) {
        percentages[activeTab] = {};
      }
      const tabsElem = angular.element(mainElem);
      percentages[activeTab] = {
        total: 0,
        filled: 0,
        valid: 0,
      };
      angular
        .element(tabsElem[0])
        .find('input, textarea')
        .each((reqIdx, reqElem) => {
          // Set the number of fields
          percentages[activeTab].total++;

          // Validate required fields
          if (angular.element(reqElem).is('[required]')) {
            // Validate here
            if (!angular.element(reqElem).val()) {
              angular.element(reqElem).addClass('is-required');
            } else {
              angular.element(reqElem).removeClass('is-required');
            }

            percentages[activeTab].filled += !!angular.element(reqElem).val();
          } else {
            // Auto increment
            percentages[activeTab].filled++;
          }

          // Validate invalid fields
          if (!angular.element(reqElem).hasClass('invalid')) {
            percentages[activeTab].valid++;
          }

          angular.element(reqElem).on('keyup', () => {
            if (angular.element(reqElem).is('[required]')) {
              // Validate here
              if (angular.element(reqElem).val()) {
                angular.element(reqElem).removeClass('is-required');
              }
            }
          });
        });
      return percentages;
    };

    const removeFieldRequiredClass = mainElem => {
      angular.element(`${mainElem}`).each((index, elem) => {
        const mainElemValue = angular.element(elem);
        angular
          .element(mainElemValue)
          .find('[required]')
          .each((reqIdx, reqElem) => {
            angular.element(reqElem).on('change', () => {
              if (!angular.element(reqElem).val()) {
                angular.element(reqElem).removeClass('is-required');
              }
            });
          });
      });
    };

    const getCurrentTab = familyId => {
      const defer = $q.defer();
      corporateService.currentOnboardingTabGet(familyId).then(
        response => {
          let tabValue = 0;
          if (response && response.data) {
            tabValue = response.data;
          }
          return defer.resolve(tabValue);
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getRelationships = () => {
      return [
        {
          id: ONBOARDING_ROLE_ID.EMPLOYEE,
          name: 'I am an Employee of the Franchisee',
        },
        {
          id: ONBOARDING_ROLE_ID.CONTRACTOR,
          name: 'I am a Contractor to the Franchisee',
        },
      ];
    };

    const getAsicType = () => {
      return [
        {
          name: 'ACL',
          checked: false,
        },
        {
          name: 'CR',
          checked: false,
        },
      ];
    };

    const getEntityType = () => {
      const defer = $q.defer();
      contactService.getEntityType().then(
        response => {
          if (response && response.data) {
            return defer.resolve(response.data);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getOnboardingHelpfulLinks = () => {
      return [
        {
          link: '//abr.gov.au',
          name: 'Applying for an ABN',
          address: 'abr.gov.au',
        },
        {
          link: '//www.ato.gov.au/business/gst/registering-for-gst/',
          name: 'Applying for an ABN',
          address: 'www.ato.gov.au/business/gst/registering-for-gst',
        },
        {
          link: '//www.mycreditfile.com.au/products-services/my-credit-alert',
          name: 'Personal Credit Check',
          address: 'www.mycreditfile.com.au/products-services/my-credit-alert',
        },
        {
          link:
            'http://www.equifax.com.au/businesscreditexpress/reports/company-credit-report',
          name: 'Company Credit Check',
          address:
            'www.equifax.com.au/businesscreditexpress/reports/company-credit-report',
        },
        {
          link: '//www.mfaa.com.au',
          name: 'MFAA',
          address: 'www.mfaa.com.au',
        },
        {
          link: '//www.fbaa.com.au',
          name: 'FBAA',
          address: 'www.fbaa.com.au',
        },
      ];
    };

    const getOnboardingDownloads = isFranchisee => {
      const DOC_BASE_URL = 'assets/uploads/docs/';
      return [
        {
          link: `${DOC_BASE_URL}Before you come onboard_A4_JUN16.pdf`,
          name: 'Before you come onboard',
        },
        {
          link: `${DOC_BASE_URL}CIO Membership Application Form.pdf`,
          name: 'AFCA Application',
        },
        {
          link: `${DOC_BASE_URL}Police Check Form (incl Direct Debit).pdf`,
          name: 'Police Check Application',
        },
        {
          link: `${DOC_BASE_URL}Required Documentation Info Sheet.pdf`,
          name: 'Required Documents Information Sheet',
        },
        {
          link: `${DOC_BASE_URL}MFAA - Checklist to becoming a MFAA Member.pdf`,
          name: 'MFAA - Checklist to becoming a MFAA member',
        },
        {
          link: `${DOC_BASE_URL}MFAA - Checklist to becoming a MFAA Member.pdf`,
          name: 'MFAA - Checklist to becoming a MFAA member',
        },
        {
          link: `${DOC_BASE_URL}PI Option - CGU.pdf`,
          name: 'PI Option',
        },
        {
          link: isFranchisee
            ? `${DOC_BASE_URL}Onboarding Process_Franchisee.pdf`
            : `${DOC_BASE_URL}Onboarding Process_Loan Writer.pdf`,
          name:
            'Process Flowchart - Franchisee & Loan Writer (will need to be redone once new system is finalised)',
        },
      ];
    };

    const getOnbardingTabsIcons = () => {
      return {
        navEntity: '<icon-info-square></icon-info-square>',
        navAsic: '<icon-asic></icon-asic>',
        navIndividual: '<owned-icon></owned-icon>',
        navParties: '<icon-person-mf></icon-person-mf>',
        navStaff: '<icon-add-filled-circle></icon-add-filled-circle>',
        navDocs: '<icon-folder></icon-folder>',
        navQuestionnaire: '<icon-questionnaire></icon-questionnaire>',
        tabEntity: '<entity-icon></entity-icon>',
        tabAsic: '<asic-icon></asic-icon>',
        tabIndividual: '<icon-relationships></icon-relationship>',
        tabParties: '<icon-state-team></icon-state-team>',
        tabStaff: '<additional-staff-icon></additional-staff-icon>',
        tabDocs: '<related-doc-icon></related-doc-icon>',
        tabQuestionnaire: '<questionniare-icon></questionniare-icon>',
      };
    };

    const getOnbardingTabs = icons => {
      const TMPL_BASE_URL = 'assets/views/onboarding/tabs/';
      const defer = $q.defer();
      dataService.getImportantDocuments('Onboarding Manual').then(
        response => {
          const tabDetails = [
            {
              name: 'Entity Details',
              page: `${TMPL_BASE_URL}entityDetails.html`,
              value: 'entityDetails',
              disable: true,
              icon: icons.tabEntity,
              svgfill: icons.navEntity,
              instruction:
                '<p class="text-instruction">To help us prepare your Franchise Agreement, please provide your legal entity details below. <br/> Please note that your entity’s ABN will need to be registered for GST.</p>',
            },
            {
              name: 'ASIC Appointment',
              page: `${TMPL_BASE_URL}asicAppointment.html`,
              value: 'asicAppointment',
              disable: true,
              icon: icons.tabAsic,
              svgfill: icons.navAsic,
              instruction:
                '<p class="text-instruction"> If you have previously held ASIC accreditation through another aggregator or a lender; or you hold your own ACL, please select <i>YES</i> and provide your details. <br/> If you have never been issued with a Credit Representative Number via ASIC, please select <i>NO</i>.</p>',
            },
            {
              name: 'Individual Details',
              page: `${TMPL_BASE_URL}individualDetails.html`,
              value: 'individualDetails',
              disable: true,
              icon: icons.tabIndividual,
              svgfill: icons.navIndividual,
              instruction: 'Please complete your details below.',
            },
            {
              name: 'Additional Parties',
              component:
                '<onboarding-additional-parties></onboarding-additional-parties>',
              value: 'additionalParties',
              disable: true,
              icon: icons.tabParties,
              svgfill: icons.navParties,
              instruction:
                'Please add Directors and Shareholders if applicable',
            },
            {
              name: 'Questionnaire',
              page: `${TMPL_BASE_URL}questioniare.html`,
              value: 'questioniare',
              disable: true,
              icon: icons.tabQuestionnaire,
              svgfill: icons.navQuestionnaire,
              instruction:
                '<p class="text-instruction">Please answer the following questions carefully.</p> <p class="text-danger">If you do not understand what the question is asking please contact the onboarding team before proceeding, as failure to disclose information that comes to light during your onboarding may affect your application.</p> <p class="crm-text">If you answer YES to any of these questions, we will require further information to be provided. </p>',
            },
            {
              name: 'Additional Staff',
              component:
                '<onboarding-additional-staff></onboarding-additional-staff>',
              value: 'additionalStaff',
              disable: true,
              icon: icons.tabStaff,
              svgfill: icons.navStaff,
              instruction: `<p class="text-instruction">
                Please provide support staff details. Additional fees apply depending on the access level required. <br/>
                For current fees, please take a look at the <a href="${
                  response.documentKey
                }" target="_blank" class="crm-link">Loan Market Operations Manual</a> </p>`,
            },
            {
              name: 'Related Documents',
              page: `${TMPL_BASE_URL}requiredDocuments.html`,
              value: 'requiredDocuments',
              disable: true,
              icon: icons.tabDocs,
              svgfill: icons.navDocs,
              instruction:
                '<p class="text-instruction">If you have your supporting documents already available these can be uploaded now. Don’t worry if you don’t have everything to hand immediately, you will be able to login at anytime and add your documents as they become available.</p>',
            },
          ];
          return defer.resolve(tabDetails);
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const checkEmail = (email, callerFamilyId) => {
      const defer = $q.defer();
      onboardingService.getCheckIfEmailExists(email, callerFamilyId).then(
        response => {
          if (response && response.data) {
            return defer.resolve(response.data);
          }
          return defer.resolve(response.data);
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getAddressForBusinessCards = () => {
      return [
        'Office Address',
        'Postal Address',
        'Home Address',
        'No Address',
        'I do not require business cards',
      ];
    };

    const checkFormAndInputIsValid = (formName, inputName) => {
      if (formName[inputName].$invalid) {
        angular.element(`[name="${inputName}"]`).addClass('invalid');
        return false;
      }
      angular.element(`[name="${inputName}"]`).removeClass('invalid');
      return true;
    };

    const onboardingPercentage = 0;

    /*
     *   =====================================
     *           FUNCTION REFERENCE
     *   =====================================
     */
    const service = {
      getAdviserOrgDetails,
      getOnboardingHelpfulLinks,
      getOnboardingDownloads,
      getOnboardingTeamList,
      getOnboardingResponse,
      isOnboardingUserRole,
      getOnbardingTabsIcons,
      getOnbardingTabs,
      getTabsRequiredField,
      removeFieldRequiredClass,
      getAddressForBusinessCards,
      getCurrentTab,
      getRelationships,
      getAsicType,
      isCountryNewZealand,
      isUserCurrentEntityType,
      isUserCurrentCountry,
      isOwnBrand,
      getClientId,
      getFamilyId,
      getCountryId,
      getEntityType,
      isRoleType,
      getFamilyBrandingCategory,
      getUserCurrentEntityType,
      setUserCurrentCountry,
      setUserCurrentEntityType,
      setBrandingCategory,
      setFamilyId,
      setClientId,
      setIsRoleType,
      suggestPreferredEmail,
      checkEmail,
      setAllOnboardingDataForPreview,
      getAllOnboardingDataForPreview,
      checkFormAndInputIsValid,
      setFamilyIds,
      getFamilyIds,
      onboardingPercentage,
      setUnsavedChanges,
      hasUnsavedChanges,
    };
    return service;
  }).name;
