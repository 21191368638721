export function brokerBuilderForUI(data) {
  return {
    brokerId: parseInt(data.BrokerId, 10),
    fullName: data.FullName,
    lastName: data.LastName,
    firstName: data.FirstName,
    middleName: data.MiddleName,
    preferredName: data.PreferredName,
    BrokerFullName: data.FullName,
    BrokerID: parseInt(data.BrokerId, 10),
  };
}

export function postCompletionBuilderForMyCRM(data, type) {
  return {
    CustomerCareType: type,
    SourceTable: data.sourceTable,
    TableRecordID: data.tableRecordID,
    IsCompleted: data.isComplete,
    FamilyID: data.familyID,
  };
}

export function fixedRateExpiryListBuilderForUI(data) {
  return {
    fixedRateExpiryList: data.FixedRateExpiryList.map(obj => {
      return {
        familyID: obj.FamilyID,
        customer: obj.Customer,
        lenderID: obj.LenderID,
        lender: obj.Lender,
        rateType: obj.RateType,
        country: obj.Country,
        expiryDate: obj.ExpiryDate,
        loanAmount: obj.LoanAmount,
        isComplete: obj.isComplete,
        loanID: obj.LoanID,
        email: obj.Email,
        mobile: obj.Mobile,
        loanStructureID: obj.LoanStructureID,
        communicationSent: obj.CommunicationSent,
      };
    }),
    totalCount: data.TotalCount,
  };
}

export function sendFixedRateEmailBuilderForUI(data, notiHeader) {
  return {
    FamilyID: data.familyID,
    FamilyFullname: data.customer,
    LoanID: data.loanID,
    EmailAddress: data.email,
    NotificationHeader: notiHeader,
    LoanStructureID: data.loanStructureID,
  };
}

export function sendMailBuilderForUI(data, notiHeader) {
  if (data.FamilyID) {
    return {
      FamilyID: data.FamilyID,
      FamilyFullname: data.Customer || data.FamilyFullName,
      LoanID: data.LoanID,
      LoanStructureId: data.LoanStructureId,
      EmailAddress: data.Email,
      SourceTable: data.SourceTable,
      TableRecordID: data.TableRecordID,
      NotificationHeader: notiHeader,
    };
  }
  return {
    FamilyID: data.familyID,
    FamilyFullname: data.customer || '',
    LoanID: data.loanID || '',
    LoanStructureId: data.loanStructureId || '',
    EmailAddress: data.email,
    NotificationHeader: notiHeader,
    SourceTable: data.sourceTable || '',
    TableRecordID: data.tableRecordIDCSV || data.tableRecordID,
  };
}

export function sendInsuranceEmailBuilderForUI(data, notiHeader) {
  return {
    FamilyID: data.familyID,
    FamilyFullname: data.customer,
    SourceTable: data.sourceTable,
    TableRecordID: data.tableRecordID,
    EmailAddress: data.email,
    NotificationHeader: notiHeader,
  };
}

export function sendFixedRateSmsBuilderForUI(data, notiHeader, callingCode) {
  return {
    FamilyFullname: data.customer,
    LoanID: data.loanID,
    FamilyID: data.familyID,
    MobileNumber: callingCode + data.mobile,
    NotificationHeader: notiHeader,
  };
}
export function sendFixedRateSmsEmailBuilderForUI(data, callingCode) {
  return {
    EmailAddress: data.EmailAddress,
    FamilyFullname: data.Customer,
    MobileNumber: callingCode + data.Mobile,
  };
}

export function sendInsuranceSmsBuilderForUI(data, notiHeader, callingCode) {
  return {
    FamilyFullname: data.customer,
    SourceTable: data.sourceTable,
    TableRecordID: data.tableRecordID,
    HeaderRecordID: data.headerRecordID,
    FamilyID: data.familyID,
    MobileNumber: callingCode + data.mobile,
    NotificationHeader: notiHeader,
  };
}
export function sendInsuranceSmsEmailBuilderForUI(data, callingCode) {
  return {
    EmailAddress: data.email,
    FamilyFullname: data.customer,
    MobileNumber: callingCode + data.mobile,
  };
}

export function loadingExclusionListBuilderForUI(data) {
  return {
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    benefitTypeID: data.BenefitTypeID,
    benefitType: data.BenefitType,
    coverAmount: data.CoverAmount,
    loadingAmount: data.LoadingAmount,
    loadingPercentage: data.LoadingPercentage,
    reviewDate: data.ReviewDate,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
    policyId: data.PolicyID,
  };
}

export function issuedFollowupListBuilderForUI(data) {
  return {
    headerRecordID: data.HeaderRecordID,
    sourceTable: data.SourceTable,
    tableRecordIDCSV: data.TableRecordIDCSV,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    benefitTypeIDCSV: data.BenefitTypeIDCSV,
    benefitTypeCSV: data.BenefitTypeCSV,
    policyNumber: data.PolicyNumber,
    commencementDate: data.CommencementDate,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
  };
}

export function dateRangeBuilderForUI(data) {
  return {
    id: data.Id,
    title: data.Title,
    type: data.Type,
    value: data.Value,
  };
}

export function inArrearsListBuilderForUI(data) {
  return {
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    policyNumber: data.PolicyNumber,
    commencementDate: data.CommencementDate,
    coverAmount: data.CoverAmount,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
    policyId: data.PolicyID,
  };
}

export function currentClaimListBuilderForUI(data) {
  return {
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    policyNumber: data.PolicyNumber,
    commencementDate: data.CommencementDate,
    coverAmount: data.CoverAmount,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
    policyId: data.PolicyID,
  };
}

export function deferralReviewListBuilderForUI(data) {
  return {
    benefitType: data.BenefitType,
    benefitTypeID: data.BenefitTypeID,
    brokerID: data.BrokerID,
    completedDate: data.CompletedDate,
    country: data.Country,
    coverAmount: data.CoverAmount,
    customer: data.Customer,
    email: data.Email,
    familyID: data.FamilyID,
    insurer: data.Insurer,
    mobile: data.Mobile,
    providerID: data.ProviderID,
    reviewDate: data.ReviewDate,
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    totalCount: data.TotalCount,
    communicationSent: data.CommunicationSent,
  };
}

export function mergeFieldsMapperForUI(data) {
  return (
    data && {
      familyId: data.familyID || data.FamilyID,
      templateContent: data.templateContent,
      templateSubject: data.templateSubject,
      loanStructureId: data.loanStructureID,
      policyId: data.policyId || data.headerRecordID,
      loan: {
        LoanID: data.loanID || data.LoanID,
      },
    }
  );
}
