import { COLOR } from 'Common/constants/colors';

class OnboardingTrackerCtrl {
  constructor(onboardingDataService) {
    'ngInject';

    this.onboardingDataService = onboardingDataService;
    this.percentage = 100;
    this.currentPercentage = 0;
    this.tabs = [];
    this.activeTab = 0;
    this.chartData = [];
    this.chartLabels = [];
    this.chartColors = [];
    this.chartColorValue = '#54478e';
    this.chartColorEmpty = '#ede6ef';
    this.percentageLabel = 0;
  }

  calculateProgress(activeTab, tabs) {
    const calculatedValue = (
      (activeTab / tabs.length) *
      this.percentage
    ).toFixed(0);
    return calculatedValue;
  }

  setDoughnutChartData(activeTab, tabs) {
    this.chartData = [];
    this.chartLabels = [];
    this.chartColors = [];
    this.percentageLabel = 0;
    let percentageValue = 0;

    for (let activeCnt = 0; activeCnt < activeTab; activeCnt++) {
      this.chartData.push(1);
      percentageValue = this.calculateProgress(activeCnt + 1, tabs);
      this.percentageLabel = `${percentageValue.toString()}%`;
      this.chartLabels.push(this.percentageLabel);
      this.chartColors.push({
        backgroundColor: this.chartColorValue,
        pointBackgroundColor: this.chartColorValue,
        hoverBorderColor: COLOR.WHITE,
      });
    }
    const emptyTabCnt = tabs.length - activeTab;
    for (let emptyTabsArr = 0; emptyTabsArr < emptyTabCnt; emptyTabsArr++) {
      this.chartData.push(1);
      this.percentageLabel = '0%';
      this.chartLabels.push(this.percentageLabel);
      this.chartColors.push({
        backgroundColor: this.chartColorEmpty,
        pointBackgroundColor: this.chartColorEmpty,
        hoverBorderColor: COLOR.WHITE,
      });
    }
    this.datasetOverride = {
      hoverBorderColor: this.chartColors.map(() => COLOR.WHITE),
    };
  }

  $onChanges(changes) {
    if (changes && changes.activeTab) {
      this.activeTab = parseInt(changes.activeTab.currentValue, 10);
    }
    if (changes && changes.tabs) {
      this.tabs = changes.tabs.currentValue;
    }

    if ((this.activeTab || this.activeTab === 0) && this.tabs.length) {
      this.currentPercentage = this.calculateProgress(
        this.activeTab,
        this.tabs
      );
      this.onboardingDataService.onboardingPercentage = this.currentPercentage;
      this.setDoughnutChartData(this.activeTab, this.tabs);
    }
  }
}

export default OnboardingTrackerCtrl;
