export const FIXED_RATE_EXPIRES_ID = 1;
export const APPROVAL_EXPIRIES_ID = 2;
export const BIRTHDAYS_ID = 3;
export const ANNUAL_REVIEWS_ID = 4;
export const POST_SETTLEMENT_FOLLOW_UP_ID = 5;
export const FIRE_GENERAL_ID = 26;

export const CUSTOMER_CARE_TYPES = {
  POST_SETTLEMENT_FOLLOW_UP: 'SETTLEMENT',
  FIXED_RATE_EXPIRES: 'FIXED_RATE_EXPIRY',
  APPROVAL_EXPIRIES: 'PRE_APPROVAL_EXPIRY',
  BIRTHDAYS: 'BIRTH_DAY',
  ANNUAL_REVIEWS: 'CLIENT_ANNUAL_REVIEW',
};

export const SERVICE_TAB_INDEX = {
  FIXED_RATE_EXPIRES: 0,
  APPROVAL_EXPIRIES: 1,
  BIRTHDAYS: 2,
  ANNUAL_REVIEWS: 3,
  POST_SETTLEMENT: 4,
};

export const SERVICE_ID = {
  FIXED_RATE_EXPIRES: 4,
  APPROVAL_EXPIRIES: 5,
  BIRTHDAYS: 3,
  ANNUAL_REVIEWS: 1,
  POST_SETTLEMENT: 2,
};

export const ORG_SUBSCRIPTION = {
  NONE: 0,
  SOME: 1,
  ALL: 2,
};

export const SUBSCRIPTION_MESSAGES = {
  NONE: `You have opted in to a subscription where these messages are being sent automatically to your clients. The associated clients have been hidden from this view to prevent accidental duplicate communications.`,
  SOME: `At least one broker has opted in to a subscription where these messages are being sent automatically. Their associated clients have been hidden from this view to prevent accidental duplicate communications.`,
  ALL: `All brokers in your business have opted in to a subscription where these messages are being sent automatically. Their associated clients have been hidden from this view to prevent accidental duplicate communications.`,
};

export const INSURANCE_TABS = [
  {
    id: 0,
    label: 'Loading / Exclusions',
    type: 'LOADING_EXCLUSION',
    dateName: 'reviewDate',
  },
  {
    id: 1,
    label: 'Issued Follow Up',
    type: 'ISSUED_FOLLOWUP',
    dateName: 'reviewDate',
  },
  {
    id: 2,
    label: 'Current Claims',
    type: 'CURRENT_CLAIM',
    dateName: 'reviewDate',
  },
  {
    id: 3,
    label: 'In Arrears',
    type: 'IN_ARREARS',
    dateName: 'reviewDate',
  },
  {
    id: 4,
    label: 'Deferral Reviews',
    type: 'DEFERRAL_REVIEWS',
    dateName: 'reviewDate',
  },
];

export const INSURANCE_TAB_ID = {
  LOADING_EXCLUSIONS: 0,
  ISSUED_FOLLOWUP: 1,
  CURRENT_CLAIMS: 2,
  IN_ARREARS: 3,
  DEFERRAL_REVIEWS: 4,
};

export const SEND_TYPE = {
  ALL: 'all',
  SINGLE: 'single',
};
