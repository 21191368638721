import { AU_SUBURBS } from 'Common/constants/suburbs';
import { DOC_TYPE_ID } from 'Common/constants/document';
import { getDatePickerConfig } from 'Common/utilities/date';
import { capitalizeFirstLetter } from 'Common/utilities/string';
import toastr from 'toastr';

export default class IdentificationModalCtrl {
  constructor(
    passportService,
    identificationService,
    loanScenarioService,
    contactService
  ) {
    'ngInject';

    this.passportService = passportService;
    this.identificationService = identificationService;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
  }

  $onInit() {
    this.isLoanApp = !!this.loanAppId;
    this.title = this.modalType === 'new' ? 'Add' : 'Edit';
    this.AU_SUBURBS = AU_SUBURBS;
    this.isSaveDisabled = true;
    this.dateConfig = getDatePickerConfig();
    this.datePopUp = {
      IssueDate: false,
      ExpiryDate: false,
    };

    if (this.isLoanApp) {
      this.getClients().then(() => {
        this.checkModalValidity();
      });
    } else {
      this.checkModalValidity();
    }
  }

  checkModalValidity() {
    const isClientsListValid = this.clientsList && this.clientsList.length;
    const isDocumentTypeListValid =
      this.documentTypeList && this.documentTypeList.length;

    const isAllDropdownsValid = isClientsListValid && isDocumentTypeListValid;

    if (isAllDropdownsValid) {
      this.isSaveDisabled = false;

      this.documentTypeList = this.passportService.mapPassportValue(
        this.documentTypeList
      );
      this.checkIfDocumentIsPassport = this.passportService.checkIfDocumentIsPassport;

      if (this.modalType === 'new') {
        const firstClientId =
          this.clientsList[0].PersonId || this.clientsList[0].OrganisationId;
        this.identificationSet.ClientEntityId = firstClientId || 0;
        this.onChangeIdentificationClient();
      }
    }
  }

  dismiss() {
    this.modalInstance.dismiss();
  }

  cancel() {
    this.modalInstance.close({
      modalType: this.modalType,
      FamilyId: this.familyId,
    });
  }

  onOpenDate(dateField) {
    this.datePopUp[dateField] = !this.datePopUp[dateField];
  }

  onChangeIdentificationClient() {
    if (!this.identificationSet.ClientEntityId) {
      this.identificationSet.NameOnDoc = '';
      return;
    }

    const clientEntity =
      this.clientsList.find(
        o => +o.PersonId === +this.identificationSet.ClientEntityId
      ) || {};
    const fullName =
      clientEntity.FullName ||
      `${clientEntity.FirstName} ${clientEntity.LastName}`;
    this.identificationSet.NameOnDoc = clientEntity.PersonId
      ? fullName
      : clientEntity.Name;
  }

  getClients() {
    return this.loanScenarioService
      .scenarioApplicantListGet(this.loanAppId)
      .then(({ data }) => {
        const involvedPartyPerson = (data && data.InvolvedPartyPerson) || [];
        const involvedPartyEntity = (data && data.InvolvedPartyEntity) || [];

        const clientsList = [];

        // Clients
        Object.keys(involvedPartyPerson).forEach(key => {
          involvedPartyPerson[key].map(person => {
            if (+person.FamilyId === +this.familyId) {
              person.ClientEntityId = person.PersonId;
              clientsList.push(person);
            }
            return person;
          });
        });

        // Entities
        involvedPartyEntity.map(entity => {
          if (+entity.FamilyId === +this.familyId) {
            entity.ClientEntityId = entity.OrganisationId;
            entity.Name = entity.FamilyFullName;
            clientsList.push(entity);
          }
          return entity;
        });

        this.clientsList = clientsList;
      });
  }
  fieldDateOfIssueRequirements() {
    const parsedDocTypeId = parseInt(this.identificationSet.DocumentTypeId, 10);
    const isDriverLicenceType =
      parsedDocTypeId === DOC_TYPE_ID.DRIVERS_LICENCE_AUSTRALIA ||
      parsedDocTypeId === DOC_TYPE_ID.DRIVERS_LICENCE_INTERNATIONAL;
    const isQLDPlaceOfIssue = this.identificationSet.PlaceOfissue === 'QLD';

    return !(isDriverLicenceType && !isQLDPlaceOfIssue);
  }
  fieldExpiryRequirements() {
    return (
      DOC_TYPE_ID.MARRIAGE_CERTIFICATE !==
      parseInt(this.identificationSet.DocumentTypeId, 10)
    );
  }
  addIdentification(form) {
    this.isSavingIdentification = true;
    if (
      this.identificationService.isIdentificationValid(
        this.identificationSet
      ) &&
      form
    ) {
      const service = this.isLoanApp ? 'loanScenarioService' : 'contactService';
      const method = this.isLoanApp
        ? 'IdentificationSet'
        : 'clientEntityIdentificationSet';

      this[service][method](this.identificationSet).then(() => {
        this.cancel();
        const saveAction = this.modalType === 'new' ? 'added' : 'edited';
        toastr.success(
          `Identification successfully ${saveAction}`,
          capitalizeFirstLetter(saveAction)
        );
      });
    } else {
      this.isSavingIdentification = false;
    }
  }
}
