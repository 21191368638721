import { forEach } from 'lodash';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';
import { EMPLOYMENT_TYPE } from 'Common/constants/employmentType';
import EmploymentData from 'Common/services/employmentData';
import { DEFAULT_ASSETS } from 'Common/constants/financialAssets';
import { employmentModalInstance } from './util/employmentModal';

const DEFAULT_INCOME = {
  TYPE: {
    SALARY_WAGE: 19,
  },
  FREQUENCY: {
    YEARLY: 1,
  },
  VERIFICATION: {
    CURRENT_PAYSLIPS: 1,
  },
};

class EmploymentModalCtrl {
  constructor(
    loanApplicationServices,
    employmentModalService,
    currentUserService,
    employmentService,
    dashboardService,
    contactService,
    uiService
  ) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.employmentModalService = employmentModalService;
    this.currentUserService = currentUserService;
    this.employmentService = employmentService;
    this.dashboardService = dashboardService;
    this.contactService = contactService;
    this.uiService = uiService;
    this.employmentModalUtilityInstance = employmentModalInstance(this);
  }
  getDefaultValuesAU() {
    this.defaultValuesAU = {
      frequency:
        this.uiService.isCountry('Australia') &&
        DEFAULT_INCOME.FREQUENCY.YEARLY,
      verification:
        this.uiService.isCountry('Australia') &&
        DEFAULT_INCOME.VERIFICATION.CURRENT_PAYSLIPS,
      incomeType:
        this.uiService.isCountry('Australia') &&
        DEFAULT_INCOME.TYPE.SALARY_WAGE,
    };
    return this.defaultValuesAU;
  }
  currentEmploymentChanged() {
    const isValidForPoppingIncome =
      !this.newIncomeDetails &&
      !this.employment.IsCurrent &&
      this.employment.Income &&
      this.employment.Income.length === 1 &&
      this.uiService.isCountry('New Zealand');
    if (isValidForPoppingIncome) {
      this.employment.deleteFirstIncomeForNZ();
    }
  }

  addIncome(data = {}) {
    const { TypeId, FrequencyID, Amount, Id } = data;
    const {
      SelectedClient,
      EmployerContactFirstName,
      EmployerContactLastName,
    } = this.employment;

    const isPAYG =
      this.uiService.isCountry('Australia') &&
      parseInt(this.employment.EmploymentTypeId, 10) ===
        parseInt(EMPLOYMENT_TYPE.PAYE, 10);
    const newIncomeType = isPAYG ? DEFAULT_INCOME_TYPES.SALARY : '';

    const setDefaultValueForNZ = fieldType => {
      const isNZUser = this.currentUserService.isNZ;
      switch (fieldType) {
        case 'frequencyType':
          if (isNZUser) {
            return DEFAULT_ASSETS.FORTNIGHTLY;
          }
          return this.getDefaultValuesAU().frequency || FrequencyID || '';
        case 'incomeType':
          if (isNZUser) {
            return DEFAULT_ASSETS.SALARY_WAGE;
          }
          return (
            this.getDefaultValuesAU().incomeType || TypeId || newIncomeType
          );
        case 'payslip':
          if (isNZUser) {
            return DEFAULT_ASSETS.PAYSLIPS;
          }
          return this.getDefaultValuesAU().verification || '';
        default:
          return null;
      }
    };

    const incomeDetail = {
      IsEvidenceOfTenancy: true,
      Id: Id || 0,
      FinancialId: 0,
      Type: '',
      TypeId: setDefaultValueForNZ('incomeType'),
      Description: '',
      DepositId: 0,
      Amount: Amount || 0,
      CurrencyCode: this.uiService.getCrmCurrency(),
      Category: 'string',
      CurrencyCodeId: this.uiService.getCrmCountryID(),
      FrequencyID: setDefaultValueForNZ('frequencyType'),
      FrequencyName: '',
      Provider: '',
      Borrowers: [
        {
          Percentage: 100,
          BorrowerID: SelectedClient,
          FirstName: EmployerContactFirstName,
          LastName: EmployerContactLastName,
          IsInclude: true,
          IsEntity: false,
        },
      ],
      IncomeVerification: [
        {
          IncomeVerificationTypeId: setDefaultValueForNZ('payslip'),
        },
      ],
      IsGross: true,
    };
    this.employment.newIncome(incomeDetail);
  }

  postEmployment(employmentSet) {
    const employementData = this.employmentModalUtilityInstance.appendFields(
      employmentSet
    );
    if (!this.isLoanApp) {
      this.employmentModalUtilityInstance.clientEntityEmploymentSet(
        employementData
      );
    } else {
      this.employmentModalUtilityInstance.setEmployment(employementData);
    }
  }

  saveEmployment(isvalid) {
    if (!isvalid) {
      this.isSavingEmployment = false;
      return;
    }
    this.isSavingEmployment = true;

    forEach(this.employment.Income, val => {
      val.TypeId = parseInt(val.TypeId, 10);
      val.FrequencyID = parseInt(val.FrequencyID, 10);
      if (val.TypeId !== DEFAULT_INCOME_TYPES.SALARY) {
        val.IsGross = true;
      }
    });

    const {
      EmployerContactFirstName,
      EmployerContactLastName,
      EmployerContactTitle,
      EmploymentBasisName,
      EmploymentStatusId,
      EmploymentBasisId,
      EmploymentRollId,
      EmploymentTypeId,
      EmploymentRole,
      EmployerTypeId,
      EmployerNumber,
      EmployerName,
      EmploymentId,
      IsProbation,
      NumberOfYears,
      NumberOfMonths,
      DateStarted,
      DateEnded,
      IsCurrent,
      AddressId,
      ClientId,
      EntityId,
      FamilyId,
      Income,
    } = this.employment;

    Income &&
      Income.forEach(income => {
        income.FamilyId = FamilyId;
      });

    const employmentSet = {
      DateEnded: this.employmentModalService.isPreviousEmployment(
        this.employment
      )
        ? DateEnded
        : null,
      IsCurrent: this.isEmploymentStatusModsShown ? IsCurrent : null,
      NumberOfMonths: NumberOfMonths && parseInt(NumberOfMonths, 10),
      NumberOfYears: NumberOfYears && parseInt(NumberOfYears, 10),
      EmployerContactFirstName,
      EmployerContactLastName,
      EmployerContactTitle,
      EmploymentBasisName,
      EmploymentStatusId,
      EmploymentBasisId,
      EmploymentTypeId,
      EmploymentRollId,
      EmploymentRole,
      EmployerTypeId,
      EmployerNumber,
      EmployerName,
      EmploymentId,
      IsProbation,
      DateStarted,
      AddressId,
      ClientId,
      EntityId,
      Income,
    };

    const isValidEmploymentAddress =
      this.employment &&
      this.employment.Address &&
      !this.employment.Address.formatted_address;
    if (isValidEmploymentAddress) {
      this.postEmployment(employmentSet);
    } else {
      const addressId = 0;
      const isMailing = false;
      const addressValuationExtendedDetailsId = 0;
      const addressDetails = this.employmentModalService.buildEmploymentAddrSetData(
        this.employment.Address,
        addressId,
        isMailing,
        this.employment.FamilyId,
        addressValuationExtendedDetailsId
      );
      this.dashboardService
        .addressDetailsSet(addressDetails)
        .then(postAddressResponse => {
          if (!postAddressResponse) return;
          employmentSet.AddressId = postAddressResponse.data;
          this.postEmployment(employmentSet);
        });
    }
  }

  onLoadIncome() {
    if (!this.newIncomeDetails && this.action === 'Add') {
      this.addIncome();
    } else if (this.newIncomeDetails && this.isFromIncome) {
      const { TypeId, FrequencyID, Amount, Id } = this.newIncomeDetails;
      const incomeData = {
        TypeId: parseInt(TypeId, 10),
        FrequencyID,
        Amount,
        Id,
      };
      this.addIncome(incomeData);
    }
  }

  prepareEmploymentEditData(employmentSet) {
    employmentSet.DateStarted = employmentSet.DateStarted
      ? new Date(employmentSet.DateStarted)
      : null;
    employmentSet.DateEnded = employmentSet.DateEnded
      ? new Date(employmentSet.DateEnded)
      : null;

    if (employmentSet.Address) {
      employmentSet.Address = { formatted_address: employmentSet.Address };
    }

    this.employment.setEmploymentData(employmentSet);
    this.onLoadIncome();
  }

  getEmployment() {
    if (!this.isLoanApp) {
      this.contactService
        .clientEntityEmploymentGet(this.familyId, this.employmentId)
        .then(response => {
          const { data } = response;
          const isValidEmployment = data && data.length;
          if (!isValidEmployment) return;

          const employmentSet = data[0];
          this.prepareEmploymentEditData(
            this.employmentModalUtilityInstance.getEmploymentappendFields(
              employmentSet
            )
          );
        });
    } else {
      this.loanApplicationServices
        .getEmployment(this.loanAppId, this.employmentId)
        .then(response => {
          const { data } = response;
          const isValidEmployment =
            data &&
            data.length &&
            data[0].Employment &&
            data[0].Employment.length;
          if (!isValidEmployment) return;

          const employmentSet = data[0].Employment[0];
          this.prepareEmploymentEditData(
            this.employmentModalUtilityInstance.getEmploymentappendFields(
              employmentSet
            )
          );
        });
    }
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  $onInit() {
    if (!this.action) {
      this.action = 'Add';
    }
    this.isLoanApp = this.familyId && this.loanAppId;
    this.isSavingEmployment = false;
    this.employment = new EmploymentData();

    this.employment.openDate = (dateField = '') => {
      this.employment.datePopUp[dateField] = !this.employment.datePopUp[
        dateField
      ];
    };

    this.employment.setEmploymentData = (data = []) => {
      Object.keys(data).forEach(key => {
        this.employment[key] = data[key];
      });
    };

    this.employment.setSelectedClient = (ClientId = 0) => {
      this.employment.setEmploymentData({ ClientId });
    };

    this.employment.newIncome = (data = {}) => {
      this.employment.Income.push(data);
    };

    this.employment.deleteFirstIncomeForNZ = () => {
      this.employment.Income.pop();
    };

    this.employment.setSelectedClient(this.clientId);
    this.isEmploymentStatusModsShown = this.employmentService.isCurrentEmploymentStatusShown();
    this.isShowEmployerType = this.employmentModalService.isEmployerTypeShown();

    if (this.employmentId) {
      this.getEmployment();
    } else {
      this.onLoadIncome();
    }
  }
}

export default EmploymentModalCtrl;
