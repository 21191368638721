import {
  BORROWER_PARTY_TYPES,
  PERSON_PARTY_TYPES,
  ENTITY_PARTY_TYPES,
  INVOLVED_PARTIES_TYPE,
} from 'Common/constants/partyTypes';

export const getPartyType = (searchtype, selectedPartyType) => {
  const partyType = parseInt(selectedPartyType, 10);
  const isBorrowerSearch = searchtype === 'borrowers';
  const isGuarantor = searchtype === 'guarantors';
  const isSolicitorOrAccountantType =
    partyType === PERSON_PARTY_TYPES.SOLICITOR ||
    partyType === PERSON_PARTY_TYPES.ACCOUNTANT;
  const isGurantorOrDependentType =
    partyType === PERSON_PARTY_TYPES.GUARANTOR ||
    partyType === PERSON_PARTY_TYPES.DEPENDENT;

  if (isGuarantor) return INVOLVED_PARTIES_TYPE.CLIENT_ENTITY;
  if (isBorrowerSearch || isSolicitorOrAccountantType)
    return BORROWER_PARTY_TYPES.PERSON;
  if (isGurantorOrDependentType) return PERSON_PARTY_TYPES.APPLICANT;
};

export const updateClientDescription = (selectedClient, selectedPartyType) => {
  selectedClient.IsApplicant = false;
  selectedClient.IsGuarantor = false;
  selectedClient.IsSolicitor = false;
  selectedClient.IsAccountant = false;
  selectedClient.AddRelatedChildren = false;
  const parsedSelectedPartyType = parseInt(selectedPartyType, 10);
  // Override the same party type but different Desc
  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.APPLICANT) {
    selectedClient.ClientTypeDesc = 'Borrower';
    selectedClient.IsApplicant = true;
    selectedClient.AddRelatedChildren = true;
  }

  if (parsedSelectedPartyType === ENTITY_PARTY_TYPES.COMPANY) {
    selectedClient.ClientTypeDesc = 'Trust';
    selectedClient.IsApplicant = true;
  }

  if (parsedSelectedPartyType === ENTITY_PARTY_TYPES.PARTNERSHIP) {
    selectedClient.ClientTypeDesc = 'Partnership';
    selectedClient.IsClient = false;
    selectedClient.IsApplicant = true;
  }

  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.GUARANTOR) {
    selectedClient.ClientTypeDesc = 'Guarantor';
    selectedClient.IsGuarantor = true;
  }

  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.SOLICITOR) {
    selectedClient.ClientTypeDesc = 'Solicitor';
    selectedClient.IsSolicitor = true;
  }

  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.ACCOUNTANT) {
    selectedClient.ClientTypeDesc = 'Accountant';
    selectedClient.IsAccountant = true;
  }

  return selectedClient;
};

export const getListApplicants = involvedPartyPerson => {
  const filteredListApplicants = Object.keys(involvedPartyPerson)
    .map(key => {
      return involvedPartyPerson[key].filter(people => people.IsApplicant);
    })
    .filter(array => array.length);
  return filteredListApplicants;
};

export const getListGuarantors = involvedPartyPerson => {
  const filteredListGuarantors = Object.keys(involvedPartyPerson)
    .map(key => {
      return involvedPartyPerson[key].filter(people => people.IsGuarantor);
    })
    .filter(array => array.length);
  return filteredListGuarantors;
};
