import { FINANCIAL_FREQUENCY_TEXT } from 'Common/constants/financialFrequency';
import { CALCULATION_TYPE } from 'Common/constants/calculations';
import { emailRecipient } from 'Common/mappers/emailTemplate';
import { parseToInt10 } from 'Common/utilities/parse';
import { getCalculationUniqueLenders } from 'Components/opportunity/opportunityMain/opportunityDetails/util/opportunityCalculation';

export function generalBuilderForUI(data) {
  return {
    adviserId: data.AdviserId,
    pipelineStatusId: data.PipelineStatusId,
    pipelineStatusIdBak: data.PipelineStatusId,
    summaryNotes: data.SummaryNotes,
    title: data.Title,
    assistantFamilyId: data.AssistantFamilyId,
    assistantName: data.AssistantName,
  };
}

export function financialClientBuilderForMyCRM(data) {
  return {
    ClientEntityID: data.clientId,
    FamilyID: data.familyId,
    IsClient: data.isClient,
    DeleteFamilyFinancialRecord: data.isDeleteFamilyRecord,
  };
}

export function notesBuilderForUI(note) {
  return {
    isPrivate: note.IsPrivate,
    isNotification: note.IsNotification,
    notificationSMS: note.NotificationSMS,
    notificationEmail: note.NotificationEmail,
    loanId: note.LoanId,
    adviserId: note.AdviserId,
    adviserName: note.AdviserName,
    isSticky: note.IsSticky,
    isReferrer: note.IsReferrer,
    isOpportunity: note.IsOpportunity,
    familyId: note.FamilyID,
    communicationId: note.CommunicationID,
    activityDate: new Date(note.ActivityDate),
    activityTitle: note.ActivityTitle,
    activityDetails: note.ActivityDetails,
    isSharedWithReferrer: note.IsSharedWithReferrer,
    activityOwner: note.ActivityOwner,
    activityType: note.ActivityType,
    brokerBrandingID: note.BrokerBrandingID,
    referralId: note.ReferralID,
  };
}

export function notesBuilderForMyCRM(note) {
  return {
    IsPrivate: note.isPrivate,
    IsNotification: note.isNotification,
    NotificationSMS: note.notificationSMS,
    NotificationEmail: note.notificationEmail,
    LoanId: note.loanId,
    AdviserId: note.adviserId,
    IsSticky: note.isSticky,
    IsReferrer: note.isReferrer,
    FamilyID: note.familyId,
    ActivityID: note.activityId,
    CommunicationID: note.communicationId,
    ActivityDate: note.activityDate,
    ActivityTitle: note.activityTitle,
    ActivityDetails: note.activityDetails,
    ActivityOwner: note.activityOwner,
    ActivityType: note.activityType,
  };
}

export function fundingCalculationBuilderForUI(calculation) {
  return (
    calculation &&
    calculation.Summary && {
      available: calculation.Summary.TotalFundingAvailable,
      required: calculation.Summary.TotalFundingRequired,
      isSurplus: calculation.Summary.IsSurplus,
      surplus: calculation.Summary.Surplus,
      deficit: calculation.Summary.Deficit,
    }
  );
}

export function lenderCalculationBuilderForUI(lender) {
  return (
    lender && {
      lenderId: parseToInt10(lender.LenderID),
      lenderName: lender.LenderName,
      countryCode: lender.Country,
    }
  );
}

export function productFinderBuilderForUI(calculation) {
  return (
    calculation &&
    calculation.GeneralSettings && {
      loanAmount: calculation.GeneralSettings.loanAmount,
      security: calculation.GeneralSettings.security,
      lvr: calculation.GeneralSettings.LVR,
      lenders:
        getCalculationUniqueLenders(calculation).map(
          lenderCalculationBuilderForUI
        ) || [],
    }
  );
}

export function serviceabilityBuilderForUI(calculation) {
  return (
    calculation && {
      isSelected: calculation.IsSelected,
      lenderId: parseToInt10(calculation.LenderID),
      countryCode: calculation.Country,
      lenderName: calculation.LenderName,
      maxLoanAmount: calculation.MaxLoanAmount,
      maxLVR: calculation.MaxLVR,
    }
  );
}

export function assessmentDetailBuilderForUI(calculation) {
  return (
    calculation && {
      lVR: calculation.LVR,
      loanTerm: calculation.LoanTerm,
      id: calculation.FamilyMembersID,
      noOfSpouse: calculation.NoOfSpouse,
      loanAmount: calculation.LoanAmount,
      noOfVehicles: calculation.NoOfVehicles,
      brokerEventId: calculation.BrokerEventId,
      noOfDependents: calculation.NoOfDependents,
      securityAmount: calculation.SecurityAmount,
      loanScenarioId: calculation.LoanScenarioId,
    }
  );
}

export function assessmentDetailBuilderForMyCRM(calculation) {
  return (
    calculation && {
      LVR: calculation.lVR,
      LoanTerm: calculation.loanTerm,
      FamilyMembersID: calculation.id,
      LoanAmount: calculation.loanAmount,
      NoOfSpouse: calculation.noOfSpouse,
      NoOfVehicles: calculation.noOfVehicles,
      BrokerEventId: calculation.brokerEventId,
      NoOfDependents: calculation.noOfDependents,
      SecurityAmount: calculation.securityAmount,
    }
  );
}

export function calculationsBuilderForUI(calculation) {
  return (
    calculation && {
      brokerEventId: calculation.BrokerEventID,
      dateOfCalculation: calculation.DateOfCalculation,
      formattedCalculationDate: calculation.FormattedCalculationDate,
      typeOfCalculation: calculation.TypeOfCalculation,
      familyId: calculation.FamilyID,
      createdBy: calculation.CreatedBy || '',
      fundingCalculator:
        (calculation.TypeOfCalculation ===
          CALCULATION_TYPE.FUNDING_CALCULATOR &&
          calculation.FundingCalculator &&
          fundingCalculationBuilderForUI(calculation.FundingCalculator)) ||
        {},
      productFinder:
        (calculation.TypeOfCalculation === CALCULATION_TYPE.PRODUCT_FINDER &&
          calculation.ProductFinder &&
          productFinderBuilderForUI(calculation.ProductFinder)) ||
        {},
      serviceability:
        (calculation.Serviceability &&
          Object.keys(calculation.Serviceability).length && {
            highestServiceability:
              (calculation.TypeOfCalculation ===
                CALCULATION_TYPE.SERVICEABILITY &&
                calculation.Serviceability &&
                calculation.Serviceability.HighestServiceabilityLender &&
                serviceabilityBuilderForUI(
                  calculation.Serviceability.HighestServiceabilityLender
                )) ||
              {},
            assessmentDetail:
              (calculation.TypeOfCalculation ===
                CALCULATION_TYPE.SERVICEABILITY &&
                calculation.Serviceability &&
                calculation.Serviceability.AssessmentDetail &&
                assessmentDetailBuilderForUI(
                  calculation.Serviceability.AssessmentDetail
                )) ||
              {},
          }) ||
        {},
    }
  );
}

export function calculationsBuilderForMyCRM(params) {
  return {
    BrokerEventID: params.brokerEventID,
    TypeOfCalculation: params.typeOfCalculation,
    FamilyId: params.familyId,
  };
}

export function totalLoanAmountsBuilderForUI(totalLoanAmount) {
  return {
    statusId: totalLoanAmount.StatusId,
    statusName: totalLoanAmount.StatusName,
    totalLoanAmount: totalLoanAmount.TotalLoanAmount,
  };
}

export function shareReferrerNotesViaSmsBuilderForMyCRM(smsObj) {
  return {
    MobileNumber: smsObj.mobile,
    Message: smsObj.body,
  };
}

export function shareReferrerNotesViaEmailBuilderForMyCRM(emailObj) {
  return {
    Subject: emailObj.subject,
    Content: emailObj.body,
    ReplyTo: emailObj.replyTo,
    SentDate: emailObj.sentDate,
    Recipients: emailObj.recipients,
  };
}

export function sendSmsBuilderForMyCRM(smsObj) {
  return {
    FamilyId: smsObj.familyId,
    MobileNumber: smsObj.phoneNumber,
    Message: smsObj.message,
  };
}

export function communicationDataBuilderForUI(communication) {
  return {
    emailId: communication.EmailID,
    subject: communication.Subject,
    content: communication.Content,
    recipients: communication.Recipients,
    cc: communication.CC,
    sentDate: communication.SentDate,
    from: communication.From,
    type: communication.CType,
  };
}

export function communicationBuilderForUI(data) {
  return data.TotalRecords
    ? {
        communications:
          data.Communications.map(o => communicationDataBuilderForUI(o)) || [],
        totalRecords: data.TotalRecords,
      }
    : {
        communications: [],
        totalRecords: 0,
      };
}

export function sendEmailBuilderForMyCRM(data) {
  return {
    Content: data.body,
    Recipients: data.recipientsEmailAddresses.map(o => emailRecipient(o)) || [],
    CC:
      (data.ccEmailAddresses &&
        data.ccEmailAddresses.map(o => emailRecipient(o))) ||
      [],
    Subject: data.subject,
    ReplyTo: {
      Name: data.replyTo.fullName,
      EmailAddress: data.replyTo.email,
      FamilyId: data.replyTo.familyId,
    },
    WorkbenchLoanId: data.loanId,
  };
}

export function opportunityServiceabilityIncomeBuilderForUI(data) {
  return (
    data && {
      income: data.Amount,
      isClient: data.IsClient,
      id: data.OpportunityIncomeID,
      clientId: data.ClientEntityID,
      displayName: data.DisplayName,
      incomeFrequencyId: data.FrequencyID.toString(),
      serviceabilityIncomeId: data.ServiceabilityIncomeID,
    }
  );
}

export function opportunityServiceabilityIncomeBuilderForMyCRM(data) {
  return (
    data && {
      Amount: parseToInt10(data.income),
      IsClient: data.isClient,
      OpportunityIncomeID: data.id,
      ClientEntityID: data.clientId,
      FrequencyID: parseToInt10(data.incomeFrequencyId),
    }
  );
}

export function opportunityServiceabilityLiabilityBuilderForUI(data) {
  return (
    data && {
      limit: data.Limit,
      balance: data.Balance,
      repayments: data.Repayments,
      id: data.OpportunityLiabilityID,
      liabilityTypeId: data.LiabilityTypeID,
      repaymentFrequencyId: data.RepaymentFrequencyID.toString(),
      serviceabilityLiabilityId: data.ServiceabilityLiabilityID,
    }
  );
}

export function opportunityServiceabilityLiabilityBuilderForMyCRM(data) {
  return (
    data && {
      OpportunityLiabilityID: data.id,
      Limit: data.limit,
      Balance: data.balance,
      LiabilityTypeID: data.liabilityTypeId,
      Repayments: data.repayments,
      RepaymentFrequencyID: parseToInt10(data.repaymentFrequencyId),
    }
  );
}

export function serviceabilityIncomeDetailBuilderForMyCRM(data) {
  return (
    data && {
      OpportunityIncomeID: data.id,
      BrokerEventID: data.brokerEventId,
      ServiceabilityIncomeID: data.serviceabilityIncomeId || 0,
      id: data.serviceabilityIncomeId || 0,
      IncomeAssociatedWith: data.incomeAssociatedWith || 0,
      value: data.income,
      type: data.incomeTypeId,
      frequency: FINANCIAL_FREQUENCY_TEXT[data.incomeFrequencyId] || '',
      ownership: [
        {
          numofclient: data.noOfClient,
          percentage: data.percentage,
          clientid: data.isClient ? data.clientId : null,
          entityid: data.isClient ? null : data.clientId,
        },
      ],
    }
  );
}

export function serviceabilityIncomeDetailBuilderForUI(data) {
  return (
    data && {
      id: data.OpportunityIncomeID,
      brokerEventId: data.BrokerEventID,
      serviceabilityIncomeId: data.ServiceabilityIncomeID,
    }
  );
}

export function serviceabilityLiabilityDetailBuilderForMyCRM(data) {
  return (
    data && {
      InterestRate: 0,
      Limit: data.limit,
      Balance: data.balance,
      IsTaxDeductible: false,
      OpportunityLiabilityID: data.id,
      RepaymentAmount: data.repayments,
      BrokerEventID: data.brokerEventId,
      ServiceabilityLiabilityID: data.serviceabilityLiabilityId || 0,
      id: data.serviceabilityLiabilityId || 0,
      Frequency: FINANCIAL_FREQUENCY_TEXT[data.repaymentFrequencyId] || '',
      type: data.liabilityTypeId,
    }
  );
}

export function serviceabilityLiabilityDetailBuilderForUI(data) {
  return (
    data && {
      id: data.OpportunityLiabilityID,
      brokerEventId: data.BrokerEventID,
      serviceabilityLiabilityId: data.ServiceabilityLiabilityID,
    }
  );
}

export function opportunityServiceabilityBuilderForMyCRM(data) {
  return (
    data && {
      AssessmentDetail:
        data.assessmentDetail &&
        assessmentDetailBuilderForMyCRM(data.assessmentDetail),
      IncomeDetail:
        data.incomeList &&
        data.incomeList.map(serviceabilityIncomeDetailBuilderForMyCRM),
      LiabilityDetail:
        data.liabilityList &&
        data.liabilityList.map(serviceabilityLiabilityDetailBuilderForMyCRM),
    }
  );
}

export function opportunityServiceabilityBuilderForUI(data) {
  return (
    data && {
      assessmentDetail:
        data.AssessmentDetail &&
        assessmentDetailBuilderForUI(data.AssessmentDetail),
      incomeList:
        data.IncomeDetail &&
        data.IncomeDetail.map(serviceabilityIncomeDetailBuilderForUI),
      liabilityList:
        data.LiabilityDetail &&
        data.LiabilityDetail.map(serviceabilityLiabilityDetailBuilderForUI),
    }
  );
}

export function opportunityServiceabilityFinancialsBuilderForUI(data) {
  return (
    (data &&
      data.FamilyFinancials &&
      data.FamilyFinancials.reduce((accum, familyFinancial) => {
        const tempFinancial = {};
        const financial = {
          loanScenarioId: data.LoanScenarioID,
          familyId: familyFinancial.FamilyID,
          liabilities:
            (familyFinancial.Liabilities &&
              familyFinancial.Liabilities.length &&
              familyFinancial.Liabilities.map(
                opportunityServiceabilityLiabilityBuilderForUI
              )) ||
            [],
          income:
            (familyFinancial.Income &&
              familyFinancial.Income.length &&
              familyFinancial.Income.map(
                opportunityServiceabilityIncomeBuilderForUI
              )) ||
            [],
        };
        tempFinancial[familyFinancial.FamilyID] = financial;

        return {
          ...accum,
          ...tempFinancial,
        };
      }, {})) ||
    {}
  );
}
