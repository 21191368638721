import { existingReferrerDetailsBuilderForUI } from 'Common/mappers/existingReferrerDetails';
import { REFERRAL_LABEL } from 'Common/constants/referralType';
import {
  referralNoteBuilderForUI,
  referralBuilderForUI,
} from 'Common/mappers/referral';

class ReferralService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Referral';
  }

  existingReferrerDetails(referrerEmail) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/existing-referrer-details`, { referrerEmail })
      .then(response => response.data.map(existingReferrerDetailsBuilderForUI));
  }

  referralAssetsFinanceSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/asset-finance`, data);
  }

  getReferralNoteList(referralId, pageSize, pageNumber) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${referralId}/notes`, { pageSize, pageNumber })
      .then(({ data }) => {
        const response = {
          noteList:
            (data.Notes && data.Notes.map(referralNoteBuilderForUI)) || [],
          totalCount: data.TotalCount || 0,
        };
        return response;
      });
  }

  getReferralWealthMarket(referralId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReferralWealthMarketGet`, { referralId })
      .then(({ data }) =>
        referralBuilderForUI(data, REFERRAL_LABEL.WEALTH_MARKET)
      );
  }
}

export default ReferralService;
