import angular from 'angular';
import { toastInfo, toastSuccess } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('profilerToQuickQuote', function profilerToQuickQuote(
    $state,
    $timeout,
    toaster,
    insuranceProfilerService,
    insuranceQuoteService,
    insuranceReportService,
    insuranceModalApplicationService,
    crmConfirmation
  ) {
    return {
      scope: {
        familyId: '<',
        profilerData: '=',
        insurerProviderId: '=',
        onConfirm: '&',
      },
      template: `
      <span>
        <loan-tools-icon></loan-tools-icon> Open in Quick Quote
      </span>
    `,
      link: (scope, element) => {
        const quickQuoteSet = profilerData => {
          const quoteId = 0;
          const familyId = 0;
          insuranceQuoteService
            .insuranceQuoteSet(
              quoteId,
              familyId,
              profilerData.NumberOfClients,
              profilerData.NumberOfChildren
            )
            .then(response => {
              if (response.data) {
                const quickQuoteId = response.data;
                $state.go('app.profilerQuickQuoteFamily', {
                  profilerId: scope.profilerData.profilerId,
                  familyId: scope.profilerData.familyId,
                  insQuoteId: quickQuoteId,
                });
              }
            });
        };

        const setQuotesRedirect = () => {
          if (scope.profilerData.familyId) {
            quickQuoteSet(scope.profilerData);
          } else {
            $timeout(() => {
              $state.go('app.profilerQuickQuote', {
                profilerId: scope.profilerData.profilerId,
              });
            });
          }
          scope.onConfirm &&
            typeof scope.onConfirm === 'function' &&
            scope.onConfirm();
        };

        scope.profilerAssignToClient = (familyId, profilerId) => {
          insuranceProfilerService
            .profilerAssignToClient(familyId, profilerId)
            .then(({ status }) => {
              if (status) {
                toastSuccess(`Profiler successfully added to contact.`);
                setQuotesRedirect();
              }
            });
        };

        scope.saveProfilerReport = () => {
          if (!scope.familyId) {
            const modalInstance = insuranceModalApplicationService.addNewClientProfiler(
              scope.profilerData
            );
            modalInstance.result.then(
              () => {
                toastInfo(`Saving profiler to client's tab...`);
                insuranceReportService
                  .confirmCreateClientFromProfiler(scope.profilerData)
                  .result.then(({ familyId, profilerId }) => {
                    if (!familyId) return;
                    scope.familyId = familyId;
                    scope.profilerId = profilerId;
                    scope.profilerAssignToClient(familyId, profilerId);
                  });
              },
              () => {
                setQuotesRedirect();
              }
            );
          } else {
            scope.profilerAssignToClient(
              scope.familyId,
              scope.profilerData.profilerId
            );
          }
        };

        const clickListener = () => {
          const message = !scope.familyId ? `contact ` : `report`;

          if (scope.profilerData.profilerId) {
            const modalInstance = crmConfirmation.open({
              type: 'info',
              title: `Would you like to save this ${message} before proceeding to Quick Quote?`,
              description: ``,
              buttonText: `Yes please!`,
              showCloseButton: true,
              showCancelButton: true,
              modalSize: 'md',
              cancelButtonClass: 'colored',
            });
            modalInstance.result.then(
              () => {
                scope.saveProfilerReport();
              },
              () => {
                setQuotesRedirect();
              }
            );
          } else {
            toaster.pop(
              'warning',
              'Warning',
              'Please calculate first before proceeding to QuickQuote.'
            );
          }
        };

        element.on('click', clickListener);
        scope.$on('destroy', () => {
          element.off('click', clickListener);
        });
      },
    };
  });
