import { countryListBuilderForUI } from 'Common/mappers/options';
import { appendEmptySelect } from 'Common/utilities/options';

export const existingAddressBar = propertyOf => {
  return {
    populateCountryList: () => {
      propertyOf.optionsService.countryListGet().then(({ data }) => {
        const countryList = data.map(countryListBuilderForUI);
        propertyOf.countryList = appendEmptySelect(countryList, {
          name: 'Please select',
          value: 'Please select',
        });
        propertyOf.addressModel.country = 'Please select';
      });
    },
    isCountryNZ: () => {
      const { isNZ } = propertyOf.currentUserService;
      return isNZ;
    },
  };
};
