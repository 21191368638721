import angular from 'angular';
import overView from './overView';
import benefitsNotes from './benefitsNotes';
import insuranceDetails from './insuranceDetails';

export default angular
  .module(
    'insuranceFireAndGeneral.scenes.insuranceFireAndGeneralModal.scenes.components',
    []
  )
  .component('fireGeneralOverView', overView)
  .component('fireGeneralBenefitsNotes', benefitsNotes)
  .component('fireGeneralInsuranceDetails', insuranceDetails).name;
