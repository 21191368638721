import {
  DISPLAY_OPTIONS,
  LIST_OF_QUESTIONS,
  QUESTION_TYPE,
  ASSOCIATED_QUESTION,
  ANSWER_TYPE,
} from 'Common/constants/customerObjectives';
import {
  isQuestionAssociatedRisks,
  isQuestionTextbox,
  isOptionalQuestion,
  getAssociateQuestion,
  getOptions as getUpdatedOptions,
  getOptionQuestionId,
  getQuestion,
} from 'Common/utilities/objectivesQuestion';

import { getPlaceHolderCustomerObj } from 'Common/utilities/placeHolder';

export default class ObjectivesQuestionCtrl {
  constructor($rootScope, loanApplicationServices, objectivesQuestionService) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.objectivesQuestionService = objectivesQuestionService;
    this.$rootScope = $rootScope;
    this.FEATURE_PREFERENCE =
      LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT;
  }

  $onInit() {
    this.associatedRisks = [];
    this.customSubquestion = [];
    this.displayOption = DISPLAY_OPTIONS;
    this.isQuestionDone = false;
  }

  $onChanges(changes) {
    const { questionList, familyId, loanId, hideQuestion } = changes;
    if (questionList && questionList.currentValue) {
      this.questionList = questionList.currentValue;
      this.getQuestionInfo();
    }
    if (loanId && loanId.currentValue) {
      this.loanId = loanId.currentValue;
    }
    if (familyId && familyId.currentValue) {
      this.familyId = familyId.currentValue;
    }
    if (hideQuestion && typeof hideQuestion.currentValue !== 'undefined') {
      this.hideQuestion = hideQuestion.currentValue;
    }
  }

  setMainQuestionHide() {
    return !(!this.questionInfo || !!this.hideQuestion);
  }

  updateObjectivesData(question) {
    this.loanApplicationServices
      .getLoanObjectives(this.loanId)
      .then(({ data }) => {
        if (!data) return;
        this.objectivesQuestionService.setObjectives(data);
        this.getQuestionInfo();
        this.getFeaturePreferenceQuestion(question);
      });
  }

  checkLoanObjectives(obj) {
    const questionType =
      this.questionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE
        ? QUESTION_TYPE.FUNDING_DETAILS.value
        : '';
    const isObjectiveEmpty = !!questionType || !(obj && obj.length);
    if (isObjectiveEmpty) {
      this.loanApplicationServices
        .getLoanObjectives(this.loanId, questionType)
        .then(response => {
          if (!response || !response.data) return;
          this.objectivesQuestionService.setObjectives(response.data);
          this.getAnswer(response.data);
        });
    } else {
      this.getAnswer(obj);
    }
  }

  showQuestionOption() {
    if (!this.questionInfo) return false;
    const { showOption, answerList } = this.questionInfo;
    return (answerList && answerList.length) || showOption;
  }

  showCustomSubquestion() {
    return (
      this.questionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE &&
      (this.questionInfo &&
        this.questionInfo.answerList &&
        this.questionInfo.answerList.length)
    );
  }

  getCustomSubquestion() {
    if (!this.showCustomSubquestion()) return;
    this.questionInfo.answerValue = 1;
    this.loanApplicationServices
      .getFundingDetailsObjectives(this.loanId, this.questionId)
      .then(response => {
        if (!response || !response.length) return;
        this.customSubquestion = response || [];
        this.questionInfo.answerList.map(item => {
          const subQuestionDetails =
            this.customSubquestion.find(o => o.value === item.value) || {};
          item.details = subQuestionDetails.details || '';
          item.fundingRefinanceId = subQuestionDetails.fundingDetailsObjId || 0;
          return item;
        });
      });
  }

  getQuestionInfo() {
    if (!this.questionId || !(this.questionList && this.questionList.length))
      return;
    this.questionInfo = this.questionList.find(
      item => item.QuestionId === this.questionId
    );
    if (!this.questionInfo) return;
    this.questionInfo.showOption = false;
    this.questionInfo.optionList = this.objectivesQuestionService.getOptionList(
      this.questionInfo
    );
    this.questionInfo.placeHolder = getPlaceHolderCustomerObj(this.questionId);
    this.questionInfo.answerType = this.objectivesQuestionService.getQuestionOptionType(
      this.questionInfo.QuestionDisplayTypeId
    );
    this.questionInfo.hasAssociatedRisks = isQuestionAssociatedRisks(
      this.questionId
    );
    const { objectivesData } = this.objectivesQuestionService;
    this.checkLoanObjectives(objectivesData);
  }

  getAnswer(objectivesData) {
    const data = objectivesData.find(
      item => item.QuestionId === this.questionId
    );
    this.questionInfo.answerList = this.objectivesQuestionService.getAnswerList(
      data
    );
    this.questionInfo.answerValue = this.objectivesQuestionService.getAnswer(
      data
    );
    this.getCustomSubquestion();
    this.getSubQuestionsAnswer(objectivesData);
    this.setQuestionStatus();
  }

  getSubQuestionsAnswer(objectivesData) {
    if (
      !this.questionInfo ||
      !this.questionInfo.SubQuestion ||
      !this.questionInfo.SubQuestion.length
    )
      return;
    this.questionInfo.SubQuestion.map(item => {
      const obj = item;
      obj.optionList = this.objectivesQuestionService.getOptionList(item);
      obj.answerType = this.objectivesQuestionService.getQuestionOptionType(
        item.QuestionDisplayTypeId
      );
      obj.placeHolder = getPlaceHolderCustomerObj(item.QuestionId);
      obj.optionalNote = isOptionalQuestion(item.QuestionId);
      obj.withTextFieldQuestion = isQuestionTextbox(item.QuestionId);
      obj.textareaSize = this.objectivesQuestionService.textareaSize(
        item.QuestionId
      );
      obj.hideQuestion = this.objectivesQuestionService.hideSubQuestion(
        item.QuestionId
      );
      obj.showOtherField = this.objectivesQuestionService.showSubQuestionOtherFields(
        item.QuestionId
      );
      obj.otherFieldValue = this.objectivesQuestionService.getOtherFieldValue(
        item.QuestionId
      );
      this.associatedRisksInfo = getAssociateQuestion(
        item,
        this.questionInfo.hasAssociatedRisks
      );
      const data = objectivesData.find(o => o.QuestionId === item.QuestionId);
      obj.answerValue = this.objectivesQuestionService.getAnswer(data);
      obj.answerList = this.objectivesQuestionService.getAnswerList(data);
      this.getAssociatedRisksList(obj);
      this.setSubQuestionStructure(obj);
      this.getFeaturePreferenceQuestion(obj);
      return obj;
    });
  }

  getNestedSubQuestion(question, data) {
    if (!question.SubQuestion) {
      return [];
    }
    const nestedQuestion = getQuestion(question.SubQuestion, data);
    if (!nestedQuestion || !nestedQuestion.SubQuestion) {
      return question.SubQuestion;
    }
    const { objectivesData } = this.objectivesQuestionService;
    nestedQuestion.SubQuestion = nestedQuestion.SubQuestion.map(
      subQuestion => ({
        ...subQuestion,
        optionList: getUpdatedOptions(
          this.objectivesQuestionService.objectivesOption,
          subQuestion.QuestionId
        ),
        answerValue: this.objectivesQuestionService.getAnswer(
          getQuestion(objectivesData, subQuestion.QuestionId)
        ),
      })
    );

    this.setOtherAnswer(nestedQuestion.SubQuestion);
    return nestedQuestion.SubQuestion;
  }

  setSubQuestionStructure(question) {
    const isValidQuestion =
      question.QuestionId === LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE;
    if (!isValidQuestion) {
      return;
    }

    question.optionalQuestion = question.answerList.map(item => ({
      ...item,
      optionList: getUpdatedOptions(
        this.objectivesQuestionService.objectivesOption,
        getOptionQuestionId(item.value, question.QuestionId)
      ),
      subQuestion: this.getNestedSubQuestion(
        question,
        getOptionQuestionId(item.value, question.QuestionId)
      ),
    }));
  }

  updateNestedQuestion(question) {
    const data = {
      ...question,
      answerType: ANSWER_TYPE.ANSWER_STRING,
      answerValue: question.answerValue,
    };
    this.updateObjectives(data, true);
  }

  getFeaturePreferenceQuestion(question) {
    const isRatePreference =
      question.QuestionId === LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE;
    const { objectivesData } = this.objectivesQuestionService;

    if (!isRatePreference) {
      return;
    }
    this.specialFeatureImportant = question.answerList.map(item => ({
      ...item,
      optionList: getUpdatedOptions(
        this.objectivesQuestionService.objectivesOption,
        getOptionQuestionId(item.value, question.QuestionId)
      ),
      answerValue: this.objectivesQuestionService.getAnswer(
        getQuestion(
          objectivesData,
          getOptionQuestionId(item.value, question.QuestionId)
        )
      ),
      other: this.getOtherFeatureQuestion(
        getQuestion(
          objectivesData,
          getOptionQuestionId(item.value, question.QuestionId)
        )
      ),
    }));
  }

  getOtherFeatureQuestion(question) {
    if (!question) {
      return;
    }

    const questionId = question.QuestionId;
    const { objectivesData } = this.objectivesQuestionService;

    switch (questionId) {
      case LIST_OF_QUESTIONS.REDRAW:
        return {
          ...getQuestion(this.questionList, LIST_OF_QUESTIONS.REDRAW_OTHER),
          answerValue: this.objectivesQuestionService.getAnswer(
            getQuestion(objectivesData, LIST_OF_QUESTIONS.REDRAW_OTHER)
          ),
        };
      case LIST_OF_QUESTIONS.OFFSET_ACCOUNT:
        return {
          ...getQuestion(this.questionList, LIST_OF_QUESTIONS.OFFSET_OTHER),
          answerValue: this.objectivesQuestionService.getAnswer(
            getQuestion(objectivesData, LIST_OF_QUESTIONS.OFFSET_OTHER)
          ),
        };
      default:
    }
    return null;
  }

  setOtherAnswer(question) {
    const { objectivesData } = this.objectivesQuestionService;

    question.forEach(item => {
      if (!item.SubQuestion) {
        return;
      }
      item.SubQuestion = item.SubQuestion.map(subQuestion => ({
        ...subQuestion,
        answerValue: this.objectivesQuestionService.getAnswer(
          getQuestion(objectivesData, subQuestion.QuestionId)
        ),
      }));
    });
  }

  updateFeatureQuestion(question, item) {
    const subquestion = getQuestion(
      question.SubQuestion,
      getOptionQuestionId(item.value, question.QuestionId)
    );
    const data = {
      ...subquestion,
      answerType: ANSWER_TYPE.ANSWER_STRING,
      answerValue: item.answerValue,
    };

    this.updateObjectives(data, true);
  }

  addMultipleAnswer(item, subQuestion = false) {
    if (subQuestion) {
      this.questionInfo.SubQuestion.map(data => {
        if (data.QuestionId === item.QuestionId) {
          if (!data.answerList.includes(item.answerValue))
            data.answerList.push(item.answerValue);
          item.answerValue = 1;
        }
        return data;
      });
    } else {
      if (!this.questionInfo.answerList.includes(item.answerValue))
        this.questionInfo.answerList.push(item.answerValue);
      item.answerValue = 1;
    }
    this.updateObjectives(item, subQuestion);
    this.setSubQuestionStructure(item);
    this.getFeaturePreferenceQuestion(item);
  }

  removeMultipleAnswer(question, item, subQuestion = false) {
    this.answerIndex = question.answerList.indexOf(item);
    if (question.answerList.includes(item))
      question.answerList.splice(this.answerIndex, 1);
    if (!subQuestion) question.answerValue = question.answerList.length || null;
    this.updateObjectives(question, subQuestion);
    this.removeCustomSubquestion(item);
    this.setSubQuestionStructure(question);
    this.getFeaturePreferenceQuestion(question);
  }

  updateOtherField(item) {
    const sendData = this.objectivesQuestionService.setOtherFieldAnswer(
      {
        LoanScenarioId: this.loanId,
        FamilyId: this.familyId,
        QuestionSectionId: 0,
      },
      item
    );

    if (!sendData.QuestionId) return;
    this.loanApplicationServices.postLoanObjectives(sendData);
  }

  setQuestionStatus() {
    this.isQuestionDone = this.objectivesQuestionService.checkQuestionIsDone(
      this.questionInfo,
      this.showAssociatedRiskExplanation()
    );
    this.objectivesQuestionService.setFeaturePreferenceStatus(
      this.questionId,
      this.isQuestionDone
    );
    const answerList = this.questionInfo && this.questionInfo.answerList;
    if (
      this.questionInfo.QuestionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE
    ) {
      this.isWarning = !(answerList && answerList.length);
    }
    const data =
      this.questionInfo.QuestionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE
        ? { reasons: answerList }
        : { questionId: this.questionId };
    this.updateQuestion(data);
  }

  updateObjectives(item, subQuestion = false) {
    this.isQuestionDone = false;
    if (
      !item ||
      (item.QuestionDisplayTypeId === DISPLAY_OPTIONS.TEXT_ONLY &&
        !item.answerValue)
    )
      return;
    const sendData = this.objectivesQuestionService.setObjectivesAnswer(
      {
        LoanScenarioId: this.loanId,
        FamilyId: this.familyId,
        QuestionSectionId: 0,
        QuestionId: item.QuestionId,
        DisplayOptionId: item.QuestionDisplayTypeId,
      },
      item
    );
    this.loanApplicationServices
      .postLoanObjectives(sendData)
      .then(({ data }) => {
        if (data && subQuestion)
          this.questionInfo.SubQuestion = this.objectivesQuestionService.updateSubQuestion(
            item,
            this.questionInfo.SubQuestion
          );
        this.setQuestionStatus();
        this.getAssociatedRisksList(item);
        this.updateObjectivesData(item);
      });
  }

  updateCustomSubquestion(item) {
    const postData = {
      LoanApplicationId: this.loanId,
      QuestionId: this.questionId,
      ObjectiveListValue: item.value,
      HelpTextValue: item.details,
    };
    this.loanApplicationServices.postFundingDetailsObjectives(postData);
  }

  removeCustomSubquestion(item) {
    if (!this.showCustomSubquestion()) return;
    this.loanApplicationServices
      .deleteFundingDetailsObjectives({
        fundingDetailsObjId: item.fundingRefinanceId,
      })
      .then(response => {
        if (response && response.data) {
          this.getCustomSubquestion();
        }
      });
  }

  showAssociatedRiskExplanation() {
    return (
      this.questionInfo.hasAssociatedRisks &&
      this.associatedRisks.length &&
      this.questionInfo.answerValue
    );
  }

  hideSubQuestionForAssociatedRisks(question) {
    return (
      this.questionInfo.hasAssociatedRisks &&
      question.QuestionDisplayTypeId === DISPLAY_OPTIONS.CHECK_BOX
    );
  }

  getAssociatedRisksList(item) {
    this.loanApplicationServices.getAssociatedRisks().then(({ data }) => {
      this.associatedRisksDetails = data;
      this.getAssociatedRisks(item);
    });
  }

  getAssociatedRisks(item) {
    if (!ASSOCIATED_QUESTION.includes(item.QuestionId)) return;
    this.associatedRisks = [];
    if (item.QuestionDisplayTypeId === DISPLAY_OPTIONS.MULTI_DROPDOWN) {
      if (!item.answerList || !item.answerList.length) return;
      item.answerList.forEach(answer => {
        const result = this.associatedRisksDetails.find(
          risk =>
            risk.value === answer.value && risk.questionId === item.QuestionId
        );
        if (result) this.associatedRisks.push(result);
      });
    } else {
      const result = this.associatedRisksDetails.find(
        risk =>
          risk.value === item.answerValue && risk.questionId === item.QuestionId
      );
      if (result) this.associatedRisks.push(result);
    }
  }
}
