import {
  AVERAGE_LOAN_SIZE,
  ALL_VALUE,
  WIDGET_OPTION_VAL,
  WIDGET_TITLE,
  LOAN_WEEKLY_DATA,
} from 'Common/constants/dashboardWidget';
import { BAR_LINE_OPTION } from 'Common/config/chart';
import {
  getValueOption,
  parseMultiSelectTicks,
  getCurrentCarouselData,
} from 'Common/utilities/dashboardWidget';
import { RGBA } from 'Common/constants/colors';

export default class AverageLoanSizeCtrl {
  constructor(
    $q,
    corporateService,
    currentUserService,
    optionsService,
    overviewDashboardService,
    dashboardService,
    commonWidgetService
  ) {
    'ngInject';

    this.$q = $q;
    this.corporateService = corporateService;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
    this.commonWidgetService = commonWidgetService;
  }

  $onInit() {
    this.AVERAGE_LOAN_SIZE = AVERAGE_LOAN_SIZE;
    this.widgetTitle = WIDGET_TITLE.AVG_LOAN_SIZE;
    this.widgetSubTitle = '';
    this.showWidgetSettings = true;
    this.timePeriodList = [];
    this.loanStatusList = [];
    this.advisersDropdown = [];
    this.chartOptions = BAR_LINE_OPTION;
    this.loanYears = [];

    this.datasetOverride = [
      {
        label: 'Total Loans',
        yAxisID: 'sub-y-axis',
        borderWidth: 1,
        type: 'line',
        lineTension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: true,
        borderColor: RGBA.GRAY_SUIT_OPAQUE,
        backgroundColor: RGBA.GRAY_SUIT_OPAQUE,
      },
      {
        label: 'Loan Size',
        borderWidth: 0,
        yAxisID: 'main-y-axis',
        backgroundColor: RGBA.GRAY_SUIT,
        type: 'bar',
      },
    ];

    this.getWidgetOptionDropdown();
  }

  goToReport() {
    const params = {
      widgetId: this.myParam.WidgetId,
      widgetUserId: this.myParam.Widget_UserID,
    };
    this.commonWidgetService.goToReport(params);
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.commonWidgetService.getWidgetOptions(AVERAGE_LOAN_SIZE.TIME_PERIOD),
      this.commonWidgetService.getWidgetOptions(AVERAGE_LOAN_SIZE.LOAN_STATUS),
      this.commonWidgetService.getAdviserDropdown(isLoanWriterOnly),
    ];

    this.$q.all(promises).then(responses => {
      if (!responses || !responses.length) return;
      const [timePeriodList, loanStatusList, advisersDropdown] = responses;

      this.loanStatusList = loanStatusList;
      this.timePeriodList = timePeriodList;
      this.advisersDropdown = advisersDropdown;

      this.getWidgetUserOptions(true);
    });
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = this[variableName]
      .filter(item => item.ticked)
      .map(item => ({ PersonId: item.value }));

    const { ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId = AVERAGE_LOAN_SIZE.ADVISERS;

    const customOption = ADVISER.CUSTOM;
    const allOption = ADVISER.ALL;

    const value = isAll ? allOption : customOption;
    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = inputData.map(item => ({
      ...item,
      ticked: item.value === ALL_VALUE,
    }));
    this.setCustomWidgetOption(varName, true);
  }

  onItemSelect(itemClicked, variableName, inputData) {
    if (itemClicked.value === ALL_VALUE) {
      this.selectAll(variableName, inputData);
      return;
    }

    this[variableName] = inputData.map((item, index) => {
      if (item.value === ALL_VALUE && !index) {
        item.ticked = false;
      }
      return item;
    });

    this.setCustomWidgetOption(variableName);
  }

  getWidgetUserOptions(isLoad) {
    return this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        if (!data || !data.length) return;

        const timePeriod = getValueOption(AVERAGE_LOAN_SIZE.TIME_PERIOD, data);
        this.timePeriodSelected = timePeriod;

        const loanStatus = getValueOption(AVERAGE_LOAN_SIZE.LOAN_STATUS, data);
        this.loanStatusSelected = parseInt(loanStatus.Widget_OptionValue, 10);
        this.widgetSubTitle = `Average value and total count of ${loanStatus.DisplayValue.toLowerCase()} loans`;

        const advisers = getValueOption(AVERAGE_LOAN_SIZE.ADVISERS, data);
        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown
        );

        isLoad && this.getAverageLoanSize();
      });
  }

  setWidgetOptions(params) {
    const { widgetOptionId, value, selectedUser, needUpdate } = params;
    const payload = {
      WidgetOptionId: widgetOptionId,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: value || 0,
      SelectedUser: selectedUser || 0,
    };
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      needUpdate && this.getWidgetUserOptions(true);
      !needUpdate && this.getAverageLoanSize();
    });
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = list.findIndex(
      item =>
        item.valueId ===
        (parseInt(currentPeriod.Widget_OptionValue, 10) ||
          parseInt(currentPeriod.valueId, 10))
    );

    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);

    this.setWidgetOptions({
      widgetOptionId: AVERAGE_LOAN_SIZE.TIME_PERIOD,
      value:
        this.timePeriodSelected.Widget_OptionValue ||
        this.timePeriodSelected.valueId,
    });
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  getAverageLoanSize() {
    const timeValueId =
      this.timePeriodSelected.Widget_OptionValue ||
      this.timePeriodSelected.valueId;
    const timePeriod = this.timePeriodSelected && parseInt(timeValueId, 10);
    const isMonthly = LOAN_WEEKLY_DATA.indexOf(timePeriod) === -1;

    this.chartOptions.tooltips = {
      ...this.chartOptions.tooltips,
      callbacks: {
        label: (valuePayload, { datasets }) => {
          const isInvalidData =
            !datasets || !datasets.length || datasets.length < 2;
          if (isInvalidData) return;
          const loanCount = datasets[0].data[valuePayload.index];
          const loanSize = datasets[1].data[valuePayload.index];
          const label = valuePayload.datasetIndex
            ? `Loan Size: ${this.commonWidgetService.roundFigure(loanSize)}`
            : `Total Loan: ${loanCount}`;
          return label;
        },
      },
    };

    const loanAmountAxes = { ...this.chartOptions.scales.yAxes };
    loanAmountAxes[0].ticks = {
      ...loanAmountAxes[0].ticks,
      callback: value => this.commonWidgetService.getShortenAmount(value),
    };
    loanAmountAxes[1].ticks = {
      ...loanAmountAxes[0].ticks,
      callback: value => {
        if (value % 1 === 0) return value;
      },
    };

    this.dashboardService
      .getAverageLoanSize({ userWidgetId: this.myParam.Widget_UserID })
      .then(data => {
        if (!data.length) return;

        const lenderAmounts = data.map(item => item.lenderTotalAmount);
        const lenderCounts = data.map(item => item.lenderTotalCount);
        this.loanYears = data.map(item => item.year);
        this.chartData = [lenderCounts, lenderAmounts];
        this.chartLabels = data.map(item =>
          isMonthly ? item.monthStr : `${item.day}/${item.monthInt}`
        );

        this.chartOptions.tooltips.callbacks = {
          ...this.chartOptions.tooltips.callbacks,
          title: value => {
            if (!value || !value.length) return '';
            const indexYr = value[0].index;
            return `${value[0].xLabel} ${this.loanYears.length &&
              this.loanYears[indexYr]}`;
          },
        };
      });
  }
}
