import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import {
  showCustomTooltip,
  getMonetaryTooltipTemplate,
} from 'Common/utilities/chartTooltip.js';
import { DATA_DOWNY, DATA_BLUE_BELL } from 'Common/constants/chartColors';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';

angular
  .module('app')
  .controller('CommissionCtrl', function CommissionCtrl(
    $timeout,
    $scope,
    commissionService,
    $filter,
    $q,
    $rootScope,
    $uibModal,
    $state,
    $window,
    SweetAlert,
    downloadDataService,
    toaster,
    currentUserService
  ) {
    const date = $filter('date')(new Date(), 'yyyy-MM-dd');
    $scope.totalCommission = {};
    $scope.brokerIds = 0;
    $scope.isToggled = false;
    const totalCommissionDefault = { Amount: 0, Percentage: 0 };
    $scope.totalCommission.year = totalCommissionDefault;
    $scope.totalCommission.month = totalCommissionDefault;
    $scope.totalCommission.week = totalCommissionDefault;
    $scope.totalCommission.day = totalCommissionDefault;
    $scope.totalCommissionOfLender = [];
    $scope.totalCommissionOfLenderPage = [];
    $scope.totalCommissionOfBroker = [];
    $scope.totalCommissionOfBrokerPage = [];
    $scope.slickConfig = {
      event: {
        init: (event, slick) => {
          slick.setPosition();
        },
      },
      method: {},
    };
    $scope.filterBroker = {
      brokerId: 0,
      isNoAmount: false,
      isCallOnce: true,
      hideOption: true,
      holdCommissionValue: 0,
      resetValue: 0,
      targetIndex: 0,
    };
    $scope.commissionDates = [
      {
        value: 0,
        caption: 'This Week',
      },
      {
        value: 1,
        caption: 'Month to Date',
      },
      {
        value: 2,
        caption: 'Year to Date',
      },
    ];
    $scope.calendarSelectedDate = new Date();
    $scope.commissionLenderDateSelection = $scope.commissionDates[2];
    $scope.commissionBrokerDateSelection = $scope.commissionDates[2];
    $scope.commissionAllTypeDateSelection = $scope.commissionDates[2];
    $scope.brokers = [];
    $scope.selected_brokers = [];
    let SelectedBrokersList = [];

    $scope.brokers = [];
    $scope.brokerSelectedList = [];
    $scope.selected_brokers = [];
    $scope.localLang = {
      selectAll: "<span><i class='fa fa-check'></i> Select All</span>",
      selectNone: "<span><i class='fa fa-close'></i> Deselect All </span>",
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel deselect'>Deselect All</div>", // default-label is deprecated and replaced with this.
    };
    $scope.pageSource = PAGE_SOURCE.COMMISSIONS;

    function totalCommissionOfLenderGet() {
      $scope.isLoadingCommissionsByLender = true;
      commissionService
        .totalCommissionOfLenderGet(
          date,
          $scope.commissionLenderDateSelection.value
        )
        .then(response => {
          $scope.totalCommissionOfLender = [];
          $scope.totalCommissionOfLenderList = response.data || [];
          const totalData = $scope.totalCommissionOfLenderList.length;

          const round = Math.floor(totalData / 4) * 4 - 1;
          const modStart = Math.floor(totalData / 4) * 4;
          const mod = Math.floor(totalData % 4);
          for (let i = 0; i < round; i += 4) {
            const data = [];
            data.push($scope.totalCommissionOfLenderList[i]);
            data.push($scope.totalCommissionOfLenderList[i + 1]);
            data.push($scope.totalCommissionOfLenderList[i + 2]);
            data.push($scope.totalCommissionOfLenderList[i + 3]);
            $scope.totalCommissionOfLender.push(data);
          }

          if (mod !== 0) {
            const lastItem = [];
            for (let a = modStart; a < totalData; a++) {
              lastItem.push($scope.totalCommissionOfLenderList[a]);
            }
            $scope.totalCommissionOfLender.push(lastItem);
          }

          $scope.dataLoaded = true;
          $scope.isLoadingCommissionsByLender = false;

          if ($scope.totalCommissionOfLender.length > 0) {
            $timeout(() => {
              $scope.slickConfig.method.slickGoTo(0);
            }, 1000);
          }
        });
    }
    function totalCommissionOfBrokerGet() {
      $scope.isLoadingCommissionsByBroker = true;
      commissionService
        .totalCommissionOfBrokerGet(
          date,
          $scope.commissionBrokerDateSelection.value
        )
        .then(response => {
          $scope.totalCommissionOfBroker = [];
          $scope.totalCommissionOfBrokerList = response.data;
          const totalData = $scope.totalCommissionOfBrokerList.length;
          const round = Math.floor(totalData / 4) * 4 - 1;
          const modStart = Math.floor(totalData / 4) * 4;
          const mod = Math.floor(totalData % 4);
          for (let i = 0; i < round; i += 4) {
            const data = [];
            data.push($scope.totalCommissionOfBrokerList[i]);
            data.push($scope.totalCommissionOfBrokerList[i + 1]);
            data.push($scope.totalCommissionOfBrokerList[i + 2]);
            data.push($scope.totalCommissionOfBrokerList[i + 3]);

            $scope.totalCommissionOfBroker.push(data);
          }
          if (mod !== 0) {
            const lastItem = [];
            for (let a = modStart; a < totalData; a++) {
              lastItem.push($scope.totalCommissionOfBrokerList[a]);
            }
            $scope.totalCommissionOfBroker.push(lastItem);
          }
          $scope.dataLoaded = true;
          $scope.isLoadingCommissionsByBroker = false;

          if ($scope.totalCommissionOfBroker.length > 0) {
            $timeout(() => {
              $scope.slickConfig.method.slickGoTo(0);
            }, 1000);
          }
        });
    }

    function convertByTypeToDonut(data) {
      $scope.byTypeLabel = [];
      $scope.byTypeData = [];
      data.forEach(values => {
        values.TotalCommissions = parseFloat(values.TotalCommissions).toFixed(
          2
        );
        if (values.TotalCommissions > 0) {
          $scope.byTypeLabel.push(values.CommissionTypeDesc);
          $scope.byTypeData.push(values.TotalCommissions);
        }
      });
    }

    function totalCommissionByTypeGet() {
      commissionService
        .totalCommissionByTypeGet(
          date,
          $scope.commissionAllTypeDateSelection.value
        )
        .then(response => {
          $scope.totalCommissionByType = response.data;
          convertByTypeToDonut(response.data);
        });
    }

    $scope.updateCommissionLenderDateSelection = function() {
      totalCommissionOfLenderGet();
    };

    $scope.updateCommissionBrokerDateSelection = function() {
      totalCommissionOfBrokerGet();
    };

    $scope.launchCommissionEnquiry = receivedCommission => {
      $uibModal.open({
        templateUrl:
          'assets/views/commission/modal/commissionEnquiryModal.html',
        controller: 'CommissionEnquiryModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          commission: () => {
            return {
              clientName: receivedCommission.ClientName,
              commissionId: receivedCommission.Commission,
              commissionTypeId: receivedCommission.CommissionType,
              commissionTypeName: receivedCommission.CommissionTypeDesc,
              lenderId: receivedCommission.LenderId,
              lenderName: receivedCommission.LenderName,
              referenceNumber: receivedCommission.ReferenceNo,
              loanAmount: receivedCommission.TransactionAmount,
            };
          },
          familyId: null,
          loanId: null,
        },
      });
    };

    function roundFigure(val) {
      const { brokerRegionalization } = currentUserService;
      return `${brokerRegionalization.currencySign || '$'}${Math.round(
        val
      ).toLocaleString()}`;
    }

    $scope.line_options = {
      responsive: true,
      multiTooltipTemplate(label) {
        return roundFigure(label.value);
      },
      scaleLabel(valuePayload) {
        return roundFigure(parseInt(valuePayload.value, 10));
      },
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0,
          hitRadius: 20,
          hoverRadius: 8,
          hoverBorderWidth: 1,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [1, 6],
              borderDashOffset: 0.5,
              lineWidth: 2,
              drawBorder: false,
              drawTicks: true,
            },
            ticks: {
              autoSkip: false,
              fontColor: '#73647f',
              callback(value, index, values) {
                if (
                  index === 0 ||
                  index === values.length - 1 ||
                  index === Math.floor(values.length / 2)
                ) {
                  return roundFigure(value);
                }

                return '';
              },
              beginAtZero: 0,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              borderDash: [1, 6],
              borderDashOffset: 0.5,
              lineWidth: 2,
              drawBorder: false,
              drawTicks: true,
            },
            ticks: {
              autoSkip: false,
              fontColor: '#73647f',
              callback(value, index, values) {
                if (
                  index === 0 ||
                  index === values.length - 1 ||
                  index === Math.floor(values.length / 2)
                ) {
                  return value;
                }
                return '';
              },
              beginAtZero: 0,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: (value, { datasets }) => {
            const dataVal =
              datasets && datasets.length && datasets[0].data[value.index];
            return roundFigure(dataVal);
          },
        },
      },
    };

    $scope.chartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      segmentShowStroke: false,
      tooltipFontSize: 11,
      tooltipFontStyle: 'light',
      tooltipTemplate: getMonetaryTooltipTemplate(),
      customTooltips: tooltip => {
        showCustomTooltip(tooltip, 'allCommissionTypesTooltip');
      },
      tooltips: {
        callbacks: {
          label: (value, { datasets, labels }) => {
            const dataVal =
              datasets && datasets.length && datasets[0].data[value.index];
            const dataLabel = labels && labels.length && labels[value.index];
            return `${dataLabel}: ${parseFloat(dataVal, 10).toLocaleString(
              'en'
            )}`;
          },
        },
      },
    };

    $scope.isPercentageUp = function(percentage) {
      if (percentage >= 1) {
        return true;
      }

      return false;
    };

    $scope.setCommissionLenderDate = function(selection) {
      $scope.commissionLenderDateSelection = selection;
      totalCommissionOfLenderGet();
    };
    $scope.setCommissionBrokerDate = function(selection) {
      $scope.commissionBrokerDateSelection = selection;
      totalCommissionOfBrokerGet();
    };
    $scope.setCommissionAllTypeDate = function(selection) {
      $scope.commissionAllTypeDateSelection = selection;
      totalCommissionByTypeGet();
    };

    function _setSelectedBrokers(brokerArr) {
      const tmp = [];
      brokerArr &&
        Object.keys(brokerArr).forEach(x => {
          const value = brokerArr[x];
          value.ticked = parseInt(x, 10) === 0;
          tmp.push(value);
        });
      return tmp;
    }

    function getAllBrokers() {
      commissionService.getAllBrokers().then(
        response => {
          $scope.brokers = _setSelectedBrokers(response.data);
          $scope.selected_brokers = _setSelectedBrokers($scope.brokers)[0];
          $scope.brokerIds = $scope.selected_brokers.BrokerID;
          $scope.assignBrokersGet();
        },
        () => {
          SweetAlert.swal({
            title: 'Network error!',
            text: '',
            type: 'error',
          });
        }
      );
    }

    $scope.selectAllBroker = function() {
      $scope.brokers = _setSelectedBrokers($scope.brokers);
      $scope.brokerIds = 0;

      $scope.assignBrokersSet($scope.brokerIds);
      $scope.resetCalendarDate();
    };

    const untickBroker = broker => {
      if (parseInt(broker.BrokerID, 10) === 0) {
        broker.ticked = false;
      }
    };

    $scope.deselectBroker = function() {
      for (let i = 0; i < $scope.brokers.length; i++) {
        untickBroker($scope.brokers[i]);
      }
      $scope.brokerIds = 0;
      $scope.assignBrokersSet($scope.brokerIds);
    };

    $scope.selectBroker = function(data) {
      for (let i = 0; i < $scope.brokers.length; i++) {
        if (parseInt(data.BrokerID, 10) !== 0) {
          untickBroker($scope.brokers[i]);
        } else if (parseInt($scope.brokers[i].BrokerID, 10) !== 0) {
          $scope.brokers[i].ticked = false;
        }
      }
      const selectedBrokerId = parseInt(data.BrokerID, 10);
      $scope.brokerIds =
        $scope.selected_brokers.reduce((ids, broker) => {
          const brokerId = parseInt(broker.BrokerID, 10);
          const isAllBrokers = !brokerId;
          const excludeBroker = selectedBrokerId !== brokerId && isAllBrokers;
          if (excludeBroker) return ids;

          const isFirstItem = !ids || !ids.trim();
          const joinedIds = isFirstItem
            ? `${broker.BrokerID}`
            : `${ids},${broker.BrokerID}`;
          return joinedIds;
        }, '') || 0;

      if (selectedBrokerId === 0) {
        $scope.assignBrokersSet('0');
      } else {
        $scope.assignBrokersSet($scope.brokerIds);
      }
      $scope.resetCalendarDate();
    };

    $scope.resetCalendarDate = () => {
      $rootScope.$broadcast('getStatementForMonth', {});
    };

    $scope.Toggle = function() {
      $scope.isToggled = !$scope.isToggled;
    };

    function getMonthlyProduction() {
      $scope.line_data = [];
      $scope.line_labels = [];
      $scope.isLoadingMonthlyProduction = true;

      const monthOfApril = 3;
      const currDate = moment();
      const isOutOfFinancialYearRange = currDate.month() < monthOfApril;
      const year = isOutOfFinancialYearRange
        ? currDate.year() - 1
        : currDate.year();
      const prevDate = moment({ year, month: monthOfApril, day: 1 });
      const currDateStr = moment(currDate, 'yyyy-MM-dd').format();
      const prevDateStr = moment(prevDate, 'yyyy-MM-dd').format();

      commissionService
        .getMonthlyProduction(prevDateStr, currDateStr)
        .then(response => {
          const datesX = [
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
            'Jan',
            'Feb',
            'Mar',
          ];
          const upfront = [...Array(datesX.length)];
          const trail = [...upfront];
          $scope.commissionsPerMonthList = response.data;

          $scope.commissionsPerMonthList &&
            $scope.commissionsPerMonthList.forEach(commission => {
              const commissionMonth = moment(commission.SplitDate).format(
                'MMM'
              );
              const commissionMonthIndex = datesX.indexOf(commissionMonth);
              upfront[commissionMonthIndex] = commission.UpfrontAmount;
              trail[commissionMonthIndex] = commission.TrailAmount;
            });

          if (upfront && trail) {
            const utmpMaxNumber = Math.max(...upfront);
            const ttmpMaxNumber = Math.max(...trail);
            if (utmpMaxNumber > ttmpMaxNumber) {
              $scope.max_number = utmpMaxNumber;
            } else {
              $scope.max_number = ttmpMaxNumber;
            }
            $scope.line_labels = datesX;
            $scope.line_data.push(upfront, trail);
          }
        })
        .finally(() => {
          $scope.isLoadingMonthlyProduction = false;
        });
    }

    $scope.getMonthSelected = function(month) {
      const monthValue = $scope.months_arr.indexOf(month);
      getMonthlyProduction(monthValue);
    };

    $scope.setCommissionLenderDate = function(selection) {
      $scope.commissionLenderDateSelection = selection;
      totalCommissionOfLenderGet();
    };
    $scope.setCommissionBrokerDate = function(selection) {
      $scope.commissionBrokerDateSelection = selection;
      totalCommissionOfBrokerGet();
    };
    $scope.setCommissionAllTypeDate = function(selection) {
      $scope.commissionAllTypeDateSelection = selection;
      totalCommissionByTypeGet();
    };

    function getTotalCommissionYear() {
      commissionService
        .totalCommissionGet(date, $scope.brokerIds, 0)
        .then(response => {
          $scope.totalCommission.year = response.data;
        });
    }
    function getTotalCommissionMonth() {
      commissionService
        .totalCommissionGet(date, $scope.brokerIds, 1)
        .then(response => {
          $scope.totalCommission.month = response.data;
        });
    }
    function getTotalCommissionWeek() {
      commissionService
        .totalCommissionGet(date, $scope.brokerIds, 2)
        .then(response => {
          $scope.totalCommission.week = response.data;
        });
    }
    function getTotalCommissionDay() {
      commissionService
        .totalCommissionGet(date, $scope.brokerIds, 3)
        .then(response => {
          $scope.totalCommission.day = response.data;
        });
    }

    $scope.getAllTotalCommissions = () => {
      getTotalCommissionYear();
      getTotalCommissionMonth();
      getTotalCommissionWeek();
      getTotalCommissionDay();
    };

    $scope.assignBrokersGet = () => {
      commissionService.assignBrokersGet().then(response => {
        if (response.data && response.data.length) {
          $scope.brokers[0].ticked = false;
          angular.forEach(response.data, item => {
            const filterBrokerList = _.find($scope.brokers, itemGet => {
              if (itemGet.BrokerID === item && itemGet.ticked === false) {
                itemGet.ticked = true;
              }
              return itemGet.BrokerID === item;
            });
            if (filterBrokerList && item) {
              $scope.selected_brokers.push(filterBrokerList);
            }
          });
          if (response.data[0] === 0) {
            $scope.brokerSelectedList = _.filter(
              $scope.brokers,
              item => item.BrokerID !== 0
            );
          } else {
            $scope.brokerSelectedList = _.filter(
              $scope.selected_brokers,
              item => item.BrokerID !== 0
            );
          }

          $scope.brokerIds =
            $scope.brokerSelectedList.length &&
            $scope.brokerSelectedList.reduce((accum, broker, index) => {
              const { BrokerID: brokerId } = broker;
              return index === 0 ? brokerId : `${accum},${brokerId}`;
            }, '');
          $scope.getAllTotalCommissions();
        }
      });
    };

    $scope.changeSelectedDateWeekSlider = function(index, amount) {
      for (let i = 0; i < 7; i++) {
        $scope.weekWithCommissions[i].isSelected = false;
      }
      $scope.checkForNoAmount(amount);
      $scope.selectedDateWeekSlider = $scope.weekWithCommissions[index];
      $scope.weekWithCommissions[index].isSelected = true;
      $scope.filterBroker.isCallOnce = true;
      $scope.getAvailableBrokerOnly();
      $scope.nextIndexTab();
    };

    $scope.changeSelectedDateWeekSliderXs = function(index, amount) {
      for (let i = 0; i < 3; i++) {
        $scope.weekWithCommissions[i].isSelected = false;
      }
      $scope.checkForNoAmount(amount);
      $scope.selectedDateWeekSlider = $scope.weekWithCommissions[index];
      $scope.weekWithCommissions[index].isSelected = true;
      $scope.filterBroker.isCallOnce = true;
      $scope.getAvailableBrokerOnly();
      $scope.nextIndexTab();
    };

    $scope.checkForNoAmount = amount => {
      $scope.filterBroker.isNoAmount = amount === 0;
    };

    $scope.nextIndexTab = () => {
      $scope.weekWithCommissions[
        $scope.filterBroker.targetIndex
      ].totalCommission =
        $scope.weekWithCommissions[
          $scope.filterBroker.targetIndex
        ].totalCommissionPlaceholder;
    };
    $scope.getCommissionByBroker = brokerId => {
      $scope.filterBroker.brokerId = brokerId;
      $scope.validateBroker();

      if (brokerId) {
        $scope.recalCulateByBrokerId(brokerId);
      } else {
        $scope.weekWithCommissions[
          $scope.filterBroker.targetIndex
        ].totalCommission = $scope.filterBroker.holdCommissionValue;
      }
      $scope.filterBroker.isCallOnce = false;
    };

    $scope.validateBroker = () => {
      $scope.weekWithCommissions.forEach((key, index) => {
        if (key.isSelected) {
          $scope.filterBroker.targetIndex = index;
          if ($scope.filterBroker.isCallOnce) {
            $scope.filterBroker.holdCommissionValue = key.totalCommission;
          }
        }
      });
    };

    $scope.recalCulateByBrokerId = brokerId => {
      const filterWeek = $scope.weekWithCommissions.filter(
        x => x.isSelected === true
      );
      const resultByBrokerId = $scope.weekSliderGridData.filter(
        x =>
          x.formattedDate === filterWeek[0].formattedDate &&
          x.BrokerID === parseInt(brokerId, 10)
      );
      if (resultByBrokerId.length) {
        const totalCommission = resultByBrokerId
          .map(item => item.Commission)
          .reduce((prev, next) => prev + next);
        $scope.weekWithCommissions[
          $scope.filterBroker.targetIndex
        ].totalCommission = totalCommission;
      } else {
        $scope.weekWithCommissions[
          $scope.filterBroker.targetIndex
        ].totalCommission = 0;
      }
    };

    function SearchTransactionGet(start, end, isMobile) {
      const numberOfDays = isMobile ? 2 : 6;
      if (start) $scope.startDate = start;
      else {
        $scope.startDate = new Date();
        $scope.startDate.setDate($scope.startDate.getDate() - numberOfDays);
      }

      if (end) $scope.endDate = end;
      else $scope.endDate = new Date();

      const endDateToSend = `${$scope.endDate.getFullYear()}-${$scope.endDate.getMonth() +
        1}-${$scope.endDate.getDate()}`;
      const startDateToSend = `${$scope.startDate.getFullYear()}-${$scope.startDate.getMonth() +
        1}-${$scope.startDate.getDate()}`;

      $scope.weekWithCommissions = [];
      const daysInWeek = numberOfDays + 1;
      for (let j = 0; j < daysInWeek; j++) {
        const weekWithCommission = {};
        const dateToPush = angular.copy($scope.startDate);
        dateToPush.setDate($scope.startDate.getDate() + j);
        weekWithCommission.date = dateToPush;
        weekWithCommission.formattedDate = `${dateToPush.getDate()}${dateToPush.getMonth()}${dateToPush.getFullYear()}`;
        weekWithCommission.totalCommission = 0;
        weekWithCommission.totalCommissionPlaceholder = 0;
        $scope.weekWithCommissions.push(weekWithCommission);
      }

      $scope.weekWithCommissions[numberOfDays].isSelected = true;

      $scope.transactionObj = {
        searchString: '',
        startDate: startDateToSend,
        endDate: endDateToSend,
      };

      $scope.dateswithCommission = [];

      commissionService
        .SearchTransactionGet($scope.transactionObj)
        .then(response => {
          $scope.weekSliderGridData = [];
          for (let i = 0; i < response.data.length; i++) {
            const transactionDate = new Date(response.data[i].TransactionDate);
            const key = `${transactionDate.getDate()}${transactionDate.getMonth()}${transactionDate.getFullYear()}`;
            response.data[i].formattedDate = key;
            $scope.weekSliderGridData.push(response.data[i]);

            for (let k = 0; k < $scope.weekWithCommissions.length; k++) {
              if ($scope.weekWithCommissions[k].formattedDate === key) {
                $scope.weekWithCommissions[
                  k
                ].standardFormat = `${transactionDate.getFullYear()}-${transactionDate.getMonth() +
                  1}-${transactionDate.getDate()}`;
                $scope.weekWithCommissions[k].totalCommission +=
                  response.data[i].Commission;
                $scope.weekWithCommissions[k].totalCommissionPlaceholder +=
                  response.data[i].Commission;
              }
            }
          }
          $scope.getAvailableBrokerOnly();
        });
    }
    $scope.getAvailableBrokerOnly = () => {
      const filterWeek = $scope.weekWithCommissions.filter(
        x => x.isSelected === true
      );
      const formattedDate = moment(filterWeek[0].standardFormat).format(
        'YYYY-MM-DD'
      );
      $scope.dateTransaction = {
        startDate: formattedDate,
        endDate: formattedDate,
      };
      commissionService
        .availableBrokerOnlyGet($scope.dateTransaction)
        .then(response => {
          if (response.data.length > 1) {
            $scope.filterBroker.hideOption = true;
            $scope.selectedBroker = '';
            $scope.brokerSelectedList = [
              { BrokerID: '', BrokerFullName: 'All Brokers' },
              ...response.data,
            ];
          } else {
            $scope.filterBroker.hideOption = !!response.data.length;
            $scope.brokerSelectedList = response.data;
            const defaultBroker =
              $scope.brokerSelectedList && $scope.brokerSelectedList[0];
            $scope.selectedBroker = defaultBroker && defaultBroker.BrokerID;
          }
        });
    };
    function SearchTransactionGetXs(start, end) {
      SearchTransactionGet(start, end, true);
    }
    function AvailableDatesOfStatementsGet(showLoader) {
      const dateToSend = `${$scope.startDate.getFullYear()}-${$scope.startDate.getMonth() +
        1}-${$scope.startDate.getDate()}`;
      $scope.isLoadingCommissionCalendar = showLoader;

      commissionService
        .AvailableDatesOfStatementsGet(dateToSend, 2)
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            const statementDate = new Date(response.data[i]);
            const key = `${statementDate.getDate()}${statementDate.getMonth()}${statementDate.getFullYear()}`;
            for (let j = 0; j < $scope.weekWithCommissions.length; j++) {
              if ($scope.weekWithCommissions[j].formattedDate === key) {
                $scope.weekWithCommissions[j].statementAvailable = true;
              }
            }
          }
          $scope.selectedDateWeekSlider = $scope.weekWithCommissions[6];
        })
        .finally(() => {
          $scope.isLoadingCommissionCalendar = false;
        });
    }

    $scope.weekSlide = (direction, device) => {
      if (direction === 'prev') {
        $scope.startDate.setDate(
          $scope.startDate.getDate() - (device === 'lg' ? 7 : 3)
        );
        $scope.endDate.setDate(
          $scope.endDate.getDate() - (device === 'lg' ? 7 : 3)
        );
        if (device === 'lg') {
          SearchTransactionGet($scope.startDate, $scope.endDate);
        } else {
          SearchTransactionGetXs($scope.startDate, $scope.endDate);
        }
        AvailableDatesOfStatementsGet();
      } else if (direction === 'next') {
        $scope.startDate.setDate(
          $scope.startDate.getDate() + (device === 'lg' ? 7 : 3)
        );
        $scope.endDate.setDate(
          $scope.endDate.getDate() + (device === 'lg' ? 7 : 3)
        );
        if (device === 'lg') {
          SearchTransactionGet($scope.startDate, $scope.endDate);
        } else {
          SearchTransactionGetXs($scope.startDate, $scope.endDate);
        }
        AvailableDatesOfStatementsGet();
        $scope.selectedDateWeekSlider = $scope.weekWithCommissions[2];
      }
    };

    $scope.PDFStatementGet = function() {
      if (
        !$scope.selectedDateWeekSlider ||
        !$scope.selectedDateWeekSlider.statementAvailable
      )
        return;
      if (!$scope.selectedBroker)
        return toaster.pop('error', 'Error', 'Please select a broker');

      $scope.isDownloadingPDFStatement = true;
      const dateOfStatement = `${$scope.selectedDateWeekSlider.date.getFullYear()}-${$scope.selectedDateWeekSlider.date.getMonth() +
        1}-${$scope.selectedDateWeekSlider.date.getDate()}`;
      commissionService
        .PDFStatementGet(dateOfStatement, $scope.selectedBroker)
        .then(response => {
          if (!response || !response.data) return;
          const pdfContent = `data:${response.data.ContentType};base64,${
            response.data.DocumentContent
          }`;
          downloadDataService.download(
            pdfContent,
            `Commission Statement.pdf`,
            response.data.ContentType
          );
        })
        .finally(() => {
          $scope.isDownloadingPDFStatement = false;
        });
    };

    const weekSliderCalendarDatetHandler = $rootScope.$on(
      'weekSliderCalendarDate',
      (event, args) => {
        const passedDate = new Date(args.calendarDatePkt);
        $scope.startDate = passedDate;
        $scope.startDate.setDate(passedDate.getDate() - 6);

        $scope.startDate.setFullYear(passedDate.getFullYear());
        $scope.endDate = new Date(args.calendarDatePkt);
        SearchTransactionGet($scope.startDate, $scope.endDate);
        AvailableDatesOfStatementsGet();
        $scope.selectedDateWeekSlider = $scope.weekWithCommissions[2];
      }
    );

    const weekSliderCalendarDateXsHandler = $rootScope.$on(
      'weekSliderCalendarDateXs',
      (event, args) => {
        const passedDate = new Date(args.calendarDatePkt);
        $scope.startDate = passedDate;
        $scope.startDate.setDate(passedDate.getDate() - 2);
        $scope.startDate.setFullYear(passedDate.getFullYear());
        $scope.endDate.setDate(passedDate.getDate());
        $scope.endDate.setFullYear(passedDate.getFullYear());
        SearchTransactionGetXs($scope.startDate, $scope.endDate);
        AvailableDatesOfStatementsGet();
        $scope.selectedDateWeekSlider = $scope.weekWithCommissions[2];
      }
    );

    $scope.$on('$destroy', weekSliderCalendarDatetHandler);
    $scope.$on('$destroy', weekSliderCalendarDateXsHandler);

    $scope.months_arr = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    $scope.currentMonth = $scope.months_arr[0];

    $scope.assignBrokersSet = data => {
      $scope.selectedBroker = '';
      if (data.toString() === '0') {
        SelectedBrokersList = [0];
        $scope.brokerSelectedList = _.filter(
          $scope.brokers,
          item => item.BrokerID !== 0
        );
      } else {
        $scope.brokerSelectedList = _.filter(
          $scope.selected_brokers,
          item => item.BrokerID !== 0
        );
        SelectedBrokersList = $scope.brokerIds.split(',');
      }

      $timeout.cancel($scope.refreshWidgetsTimeout);
      commissionService.assignBrokersSet(SelectedBrokersList).then(response => {
        if (parseInt(response.data, 10) === 1) {
          $timeout.cancel($scope.refreshWidgetsTimeout);
          $scope.refreshWidgetsTimeout = $timeout(() => {
            $scope.refreshWidgets();
          }, 1000);
        } else {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      });
    };

    $scope.getLenderLogo = lender => {
      const fileName = lender.LenderName.toLowerCase().trim();
      return `assets/images/company/${fileName}.png`;
    };

    $scope.refreshWidgets = () => {
      $scope.getAllTotalCommissions();
      totalCommissionOfLenderGet();
      totalCommissionOfBrokerGet();
      totalCommissionByTypeGet();
      getMonthlyProduction($scope.months_arr.indexOf($scope.currentMonth));
      if ($window.innerWidth <= 800) {
        SearchTransactionGetXs();
        $scope.selectedDateWeekSlider = $scope.weekWithCommissions[2];
      } else {
        SearchTransactionGet();
        AvailableDatesOfStatementsGet(true);
      }
    };

    const initializeCommissions = () => {
      totalCommissionOfLenderGet();
      totalCommissionOfBrokerGet();
      totalCommissionByTypeGet();
      getAllBrokers();

      if ($window.innerWidth <= 800) SearchTransactionGetXs();
      else SearchTransactionGet();
      AvailableDatesOfStatementsGet();

      if ($window.innerWidth <= 800)
        $scope.selectedDateWeekSlider = $scope.weekWithCommissions[2];
      getMonthlyProduction($scope.months_arr.indexOf($scope.currentMonth));

      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.refreshWidgetsTimeout);
      });
    };

    $scope.byTypeLabel = [];
    $scope.byTypeData = [];

    $scope.customColors = {
      colors: [
        '#97BBCD',
        '#DCDCDC',
        '#F7464A',
        '#46BFBD',
        '#FDB45C',
        '#949FB1',
        '#4D5360',
        '#6B4C9A',
        '#958C3D',
        '#3F9852',
        '#FF0080',
        '#00FFFF',
      ],
    };
    $scope.datasetOverride = {
      hoverBackgroundColor: $scope.customColors.colors.map(
        (value, index) => $scope.customColors.colors[index]
      ),
    };
    $scope.colors = [DATA_DOWNY, DATA_BLUE_BELL];

    $scope.openMobileCalendar = function() {
      $uibModal.open({
        templateUrl: 'mobileCalendar.html',
        size: 'sm',
        backdrop: 'static',
        keyboard: false,
        scope: $scope,
        controller($uibModalInstance) {
          $scope.name = 'top';
          $scope.closeMobileCalendar = function() {
            $rootScope.$broadcast('weekSliderCalendarDateXs', {
              calendarDatePkt: $scope.calendarSelectedDate,
            });

            $uibModalInstance.dismiss('cancel');
          };

          function getLastDayOfMonth(dateParam) {
            const dateParamValue = new Date(dateParam);
            const year = dateParamValue.getFullYear();
            const month = dateParamValue.getMonth();
            const temp = new Date(year, month + 1, 0);
            return temp.getDate();
          }

          function isStatement(stDate) {
            if ($scope.availableStatement) {
              return (
                $scope.availableStatement.indexOf(
                  $filter('date')(new Date(stDate), 'yyyy-MM-dd')
                ) !== -1
              );
            }
            return false;
          }

          function getStatementForWeek() {
            commissionService
              .availableStatementGet(
                $filter('date')(
                  new Date($scope.calendarSelectedDate),
                  'yyyy-MM-dd'
                ),
                1
              )
              .then(response => {
                $scope.availableStatement = response.data;
                $scope.haveStatement = isStatement($scope.calendarSelectedDate);
              });
          }

          function setDate() {
            $scope.currentYear = new Date(
              $scope.calendarSelectedDate
            ).getFullYear();
            $scope.currentMonth = new Date(
              $scope.calendarSelectedDate
            ).getMonth();
            $scope.currentMonthName = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ][$scope.currentMonth];
            $scope.currentDay = new Date($scope.calendarSelectedDate).getDate();
            $scope.lastDay = getLastDayOfMonth($scope.calendarSelectedDate);
            getStatementForWeek();
          }

          function nextMonth() {
            const currentMonth = new Date($scope.calendarSelectedDate);
            if (currentMonth.getMonth() === 11) {
              $scope.calendarSelectedDate = new Date(
                currentMonth.getFullYear() + 1,
                0,
                1
              );
              setDate();
            } else {
              $scope.calendarSelectedDate = new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth() + 1,
                1
              );
              setDate();
            }
          }
          function prevMonth() {
            const currentMonth = new Date($scope.calendarSelectedDate);
            if (currentMonth.getMonth() === 0) {
              $scope.calendarSelectedDate = new Date(
                currentMonth.getFullYear() - 1,
                11,
                1
              );
              setDate();
            } else {
              const lastDay = getLastDayOfMonth(
                new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() - 1,
                  1
                )
              );
              $scope.calendarSelectedDate = new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth() - 1,
                lastDay
              );
              setDate();
            }
          }

          function formatDate(fDate, format) {
            return moment(fDate, format).format();
          }

          // Calendar Picker for Mobile
          setDate();
          $scope.increaseDay = function() {
            if ($scope.currentDay < $scope.lastDay) {
              $scope.currentDay++;
              $scope.calendarSelectedDate = formatDate(
                new Date(
                  `${$scope.currentMonth + 1}/${$scope.currentDay}/${
                    $scope.currentYear
                  }`
                ),
                'yyyy-MM-dd'
              );
              setDate();
            } else {
              nextMonth();
            }
          };
          $scope.decreaseDay = function() {
            if ($scope.currentDay > 1) {
              $scope.currentDay--;
              $scope.calendarSelectedDate = formatDate(
                new Date(
                  `${$scope.currentMonth + 1}/${$scope.currentDay}/${
                    $scope.currentYear
                  }`
                ),
                'yyyy-MM-dd'
              );
              setDate();
            } else {
              prevMonth();
            }
          };

          $scope.nextMonthly = function() {
            const currentMonth = new Date($scope.calendarSelectedDate);
            const lastDay = new Date(currentMonth.getFullYear() + 1, 0, 1);
            if (currentMonth.getMonth() === 11) {
              $scope.calendarSelectedDate = formatDate(
                new Date(
                  currentMonth.getFullYear() + 1,
                  0,
                  lastDay < $scope.currentDay ? lastDay : $scope.currentDay
                ),
                'yyyy-MM-dd'
              );
              setDate();
            } else {
              $scope.calendarSelectedDate = formatDate(
                new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() + 1,
                  lastDay < $scope.currentDay ? lastDay : $scope.currentDay
                ),
                'yyyy-MM-dd'
              );
              setDate();
            }
          };
          $scope.prevMonthly = function() {
            const currentMonth = new Date($scope.calendarSelectedDate);
            const lastDay = getLastDayOfMonth(
              new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth() - 1,
                1
              )
            );
            if (currentMonth.getMonth() === 0) {
              $scope.calendarSelectedDate = formatDate(
                new Date(
                  currentMonth.getFullYear() - 1,
                  11,
                  lastDay < $scope.currentDay ? lastDay : $scope.currentDay
                ),
                'yyyy-MM-dd'
              );
              setDate();
            } else {
              $scope.calendarSelectedDate = formatDate(
                new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() - 1,
                  lastDay < $scope.currentDay ? lastDay : $scope.currentDay
                ),
                'yyyy-MM-dd'
              );
              setDate();
            }
          };
          $scope.haveStatement = false;
        },
      });
    };
    $scope.gotoSearchTrans = function() {
      $state.go(
        'app.commission.transaction',
        { keyword: $scope.searchTransaction },
        { reload: true }
      );
    };
    initializeCommissions();
  });

angular.module('app').directive('onErrorSrc', function onErrorSrc() {
  return {
    link(scope, element, attrs) {
      element.bind('error', () => {
        if (attrs.src !== attrs.onErrorSrc) {
          attrs.$set('src', attrs.onErrorSrc);
        }
      });
    },
  };
});

angular.module('app').directive('keyEnter', function keyEnter($timeout) {
  return function(scope, element, attrs) {
    element.bind('keydown keypress', event => {
      if (event.which === 13) {
        $timeout(() => scope.$eval(attrs.keyEnter));
        event.preventDefault();
      }
    });
  };
});
