import {
  toastError,
  displayError,
  toastInfo,
  showActionConfirmation,
} from 'Common/utilities/alert';
import { isBoolean } from 'Common/utilities/objectValidation';

export default class GenerateCqpOptionsCtrl {
  constructor(
    loanScenarioService,
    loanScenarioModelService,
    currentUserService,
    fundingCalculatorService,
    loanProfilerService,
    loanApplicationServices
  ) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.currentUserService = currentUserService;
    this.fundingCalculatorService = fundingCalculatorService;
    this.loanProfilerService = loanProfilerService;
    this.loanApplicationServices = loanApplicationServices;
  }

  $onInit() {
    this.cpaCqpSet.CQP.IsFees = false;
    this.newFee = {};
    this.isAddFee = false;
    this.allFees = [];
    this.productFeesList = [];
    this.isSaving = false;
    this.getCQPFees();
  }

  getCQPFees() {
    this.isFeesLoading = true;
    this.loanScenarioModelService
      .getCqpDynamicFee(this.loanAppId)
      .then(response => {
        this.allFees = response;
        this.isFeesLoading = false;
        this.cpaCqpSet.CQP.IsFees = !!this.allFees.length;
        this.getLoanAppFacilityFees();
      });
  }

  getLoanAppFacilityFees() {
    this.getProductFeesList();
    this.loanScenarioService.loanDetailsGet(this.loanAppId).then(({ data }) => {
      if (!data || !data.LoanFacility) return;

      this.facility = data.LoanFacility;
      if (data.LoanFacility.length > 1) {
        const msg = `Multiple products were selected for this Loan App. Please verify the fees listed.`;
        toastInfo(msg);
      }

      const hasSavedCqpFees = this.allFees && this.allFees.length;
      if (hasSavedCqpFees) {
        this.checkManualCqpState();
      } else {
        this.resetCQPManuallyUpdated();
        this.getAllFacilitiesFeesList(this.facility);
      }
    });
  }

  checkManualCqpState() {
    this.loanScenarioModelService
      .getManualCqpState(this.loanAppId)
      .then(({ data }) => {
        const isCqpFeesUpdated = data && isBoolean(data);
        if (!isCqpFeesUpdated) return;

        this.resetCQPManuallyUpdated();
        showActionConfirmation(
          `Notice`,
          `It appears the product fees have been changed \non the loan application.
            \nDo you wish to reload the CQP fees section? \nPlease be aware that this will remove any additional fees you many have added manually.`,
          confirm => {
            if (!confirm) return;
            this.deleteAllCurrentFees();
            this.getAllFacilitiesFeesList(this.facility);
          }
        );
      });
  }

  resetCQPManuallyUpdated() {
    this.loanScenarioModelService.setManualCqpState(this.loanAppId, false);
  }

  deleteAllCurrentFees() {
    if (!this.allFees || !this.allFees.length) return;
    this.allFees.forEach((fee, idx, arr) => {
      this.deleteFee(fee, idx === arr.length - 1);
    });
  }

  getProductFeesList() {
    this.loanApplicationServices.getProductFeesList().then(({ data }) => {
      this.productFeesList = data || [];
    });
  }

  getAllFacilitiesFeesList(facilities) {
    if (!facilities || !facilities.length) return;

    this.isFeesLoading = true;
    let tempFeesCount = 0;
    facilities.forEach((facility, index) => {
      if (!facility.BrokerEventId) return;

      this.loanProfilerService
        .getSelectedProductDetailsGetByLoanStructure(facility.BrokerEventId)
        .then(({ data }) => {
          const details = data && data.length && data[0];
          if (!details || !details.SelectedProduct.length) return;
          const selectedProduct = details.SelectedProduct.find(product => {
            return product.IsRecommended;
          });

          if (!selectedProduct) return;
          return this.loanScenarioService.LenderFeesGet(
            selectedProduct.ProductID,
            facility.BrokerEventId,
            details.LoanStructureID,
            this.loanAppId
          );
        })
        .then(result => {
          const data = result && result.data;
          const productFees = data && data.productFee;
          const checkedProducts =
            productFees.length && productFees.filter(fee => fee.IsChecked);
          tempFeesCount += (checkedProducts && checkedProducts.length) || 0;
          this.formatProductFees(productFees);

          if (index === facilities.length - 1) {
            this.updateIsFeesBasedOnFacilityFees(tempFeesCount);
            if (!tempFeesCount) this.isFeesLoading = false;
          }
        })
        .catch(displayError);
    });
  }

  formatProductFees(productFees) {
    if (!productFees || !productFees.length) {
      this.isFeesLoading = false;
      return;
    }

    const getPayableWhen = fee => {
      const prodFee = this.productFeesList.find(
        item => item.description === fee.FeesName
      );
      return prodFee && prodFee.payableWhen;
    };

    const formattedProductFees = productFees
      .filter(fee => fee.IsChecked)
      .map(fee => {
        return {
          description: fee.FeesName,
          amount: fee.Amount,
          payableWhen: getPayableWhen(fee),
        };
      });

    this.insertFee(...formattedProductFees);
  }

  refreshFeesList() {
    this.loanScenarioModelService
      .getCqpDynamicFee(this.loanAppId)
      .then(response => {
        this.allFees = response;
        this.isFeesLoading = false;
        if (!this.allFees || !this.allFees.length) {
          const isFeesModelValid = this.cpaCqpSet && this.cpaCqpSet.CQP;
          if (!isFeesModelValid) return;
          this.cpaCqpSet.CQP.IsFees = false;
        }
      });
  }

  insertFee(...data) {
    data.map((item, index) => {
      this.isSaving = true;
      this.loanScenarioModelService
        .addCqpDynamicFee(this.loanAppId, data[index])
        .then(response => {
          if (data.length - 1 === index) {
            if (data.length === 1) {
              this.allFees = response;
              this.isFeesLoading = false;
            } else {
              this.refreshFeesList();
            }

            this.isSaving = false;
            this.newFee = {};
            this.toggleAddFee(false);
            this.cpaCqpSet.CQP.IsFees = true;
          }
        });
      return item;
    });
  }

  addFee() {
    const { description, amount } = this.newFee;
    if (!description || !amount) {
      toastError('Description and amount is required.');
      return;
    }
    this.insertFee(this.newFee);
  }

  toggleAddFee(isAddFee) {
    this.isAddFee = isAddFee;
  }

  editFee(fee) {
    this.toggleAddFee(false);
    fee.edit = true;
  }
  saveFee(fee) {
    const { description, amount } = fee;
    if (!description || !amount) {
      toastError('Description and amount is required.');
      return;
    }
    fee.isEditing = true;
    this.loanScenarioModelService
      .updateCqpDynamicFee(this.loanAppId, fee.feeId, fee)
      .then(() => {
        fee.edit = false;
        fee.isEditing = false;
      });
  }
  deleteFee(fee, refreshFees = false) {
    fee.isDeleting = true;
    this.loanScenarioModelService
      .deleteCqpDynamicFee(this.loanAppId, fee.feeId)
      .then(response => {
        this.allFees = response;
        if (refreshFees) this.refreshFeesList();
      });
  }
  updateCPACQP(reCalculate = true, isAmountChanged = false) {
    const { CQP: cqp, CPA: cpa } = this.cpaCqpSet;
    const { totalProposedLending } = this.calculatorFundsSummary;

    const checkTypeValid = this.cpaCqpSet && this.calculatorFundsSummary;
    if (!checkTypeValid) return;

    if (reCalculate) {
      const isAutoCompute = this.isAssetFinance;

      if (isAutoCompute) {
        const commissions = ['DocumentFee', 'UpfrontCommission'];
        if (!isAmountChanged) {
          commissions.map(item => {
            const amount = (parseFloat(cqp[item]) / 100) * totalProposedLending;
            cqp[`${item}_amt`] = Number.isInteger(Math.floor(amount))
              ? Math.floor(amount)
              : 0;
            return item;
          });
        } else {
          commissions.map(item => {
            const percentage =
              (cqp[`${item}_amt`] / totalProposedLending) * 100;
            cqp[item] = Math.round(percentage * 100) / 100;
            return item;
          });
        }
      } else {
        cqp.TrailCommission_amt = totalProposedLending
          ? cqp.TrailCommission_amt
          : 0;
        cqp.UpfrontCommission_amt = totalProposedLending
          ? cqp.UpfrontCommission_amt
          : 0;
      }
    }

    cpa.IsTemplated = this.isSaveTemplateChecked;
    const cpaCqpSetCopy = { ...this.cpaCqpSet };
    if (cpaCqpSetCopy && cpaCqpSetCopy.CPA && cpaCqpSetCopy.CPA.Content) {
      cpaCqpSetCopy.CPA.Content = cpaCqpSetCopy.CPA.Content.replace(
        /\r\n|\r|\n/g,
        '<br>'
      );
    }
    this.loanScenarioService.RecommendationReportStatus(cpaCqpSetCopy);
  }

  updateIsFeesBasedOnFacilityFees(feesCount) {
    const isCQPModelValid = this.cpaCqpSet && this.cpaCqpSet.CQP;
    if (!isCQPModelValid) return;
    this.cpaCqpSet.CQP.IsFees = !!feesCount;
    this.updateCPACQP();
  }
}
