import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';
import {
  PERSON_PARTY_TYPES,
  ENTITY_PARTY_TYPES,
  CONTACT_SEARCH_PARTY_TYPES,
  COMPANY_TYPES,
} from 'Common/constants/partyTypes';
import { LOAN_APP_ENQUIRY_SOURCE } from 'Common/constants/referralType';
import {
  CLIENT_REFERRAL,
  ADDITIONAL_REFERRAL,
  CATEGORY,
} from 'Common/constants/enquirySource';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { displayField } from 'Common/utilities/displayField';
import { toastSuccess } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('ClientDetailCtrl', function ClientDetailCtrl(
    $scope,
    $q,
    $rootScope,
    contactService,
    $uibModal,
    SweetAlert,
    $timeout,
    mapService,
    toaster,
    dashboardService,
    generalService,
    corporateService,
    userService,
    optionsService,
    utilitiesService,
    $document,
    colorService,
    $window,
    enquirySourceService,
    referencedReferralService,
    contactStatusService,
    contactSharedData,
    referralDetailService,
    clientInfoService,
    currentUserService
  ) {
    $scope.CONTACT_SEARCH_PARTY_TYPES = CONTACT_SEARCH_PARTY_TYPES;
    $scope.COMPANY_TYPES = COMPANY_TYPES;
    $scope.ENTITY_PARTY_TYPES = ENTITY_PARTY_TYPES;
    $scope.isUserNZ = currentUserService.isNZ;
    const referredByAccess = [
      LOAN_APP_ENQUIRY_SOURCE.REFERRAL,
      LOAN_APP_ENQUIRY_SOURCE.RAYWHITE_CALCULATOR,
    ];
    // methods
    function clientEntityPrimarySet(clientEntity) {
      const isEntity = clientEntity.isEntity;
      let businessPersonId = '0';
      let clientPersonId = '0';
      if (isEntity) {
        businessPersonId = clientEntity.PersonId;
      } else {
        clientPersonId = clientEntity.PersonId;
      }

      contactService
        .clientEntityPrimarySet(clientPersonId, businessPersonId)
        .then(() => {
          const isHttpRequest = true;
          $scope.getClientByFamilyId(isHttpRequest);
          toaster.pop('success', 'Primary Client/Enity', 'Successfully Set!');
        });
    }
    angular.extend($scope, {
      clientEntityPrimarySet,
    });

    $scope.referredByHelper = {
      showClientList: false,
      loopCount: 0,
      getReferralOrgList() {
        if ($scope.referredByHelper && $scope.referredByHelper.loopCount < 1) {
          contactService
            .contactFamilyInfoGet($scope.familyId)
            .then(response => {
              const data = response.data;
              if (data) {
                const familyId = data.BrokerID || 0;
                if (familyId > 0) {
                  referencedReferralService.getReferencedReferralList(
                    familyId,
                    $scope
                  );
                }
              }
            });
        }
      },
      searchClient() {
        $scope.clientNotFound = false;
        $scope.referredByHelper.showClientList = true;
        $scope.referredByHelper.getReferralOrgList();
      },
    };

    $scope.referredByHelper.selectClient = client => {
      clientInfoService
        .selectClient(
          $scope.contactInfoSetValue,
          $scope.referredByHelper,
          client
        )
        .then(() => {
          contactService
            .contactFamilyInfoSet($scope.contactInfoSetValue)
            .then(() => {
              return $scope.contactFamilyInfoGet();
            })
            .then(() => {
              $scope.contactFamilyAddress();
              toaster.pop('success', 'Information', 'Successful!');
            });
        });
    };

    $scope.referredByHelper.getReferralOrgList();

    angular.element($window.document).on('click', () => {
      $scope.getClientList = [];
      $scope.referredByHelper.showClientList = false;
    });

    // Relationship
    //------------------------------------------------------------
    $scope.relationship = {};
    $scope.isCorporateUser = false;

    $scope.checkReferrerInput = function() {
      if (
        !_.isUndefined($scope.contactInfoSetValue.ReferrerName) &&
        $scope.contactInfoSetValue.ReferrerName.length < 1
      ) {
        $scope.contactInfoSetValue.ReferrerOrgID = 0;
        $scope.contactInfoSetValue.ReferrerID = 0;
        $scope.enquiryChanged();
      }
    };

    $scope.getRelationship = function() {
      contactService.contactRelationshipGet($scope.familyId).then(response => {
        $scope.relationshipCompany = [];
        $scope.relationshipPro = [];
        angular.forEach(response.data, value => {
          value.background = colorService.getRandomColor();
          if (_.size(value.OrganisationId) > 0) {
            value.initials = utilitiesService.filterInitialOneString(
              value.Name
            );
            $scope.relationshipCompany.push(value);
          } else {
            value.initials = utilitiesService.filterInitialOneString(
              value.Name
            );

            if (value.IsApplicant === true) {
              value.clientType = 'Applicant';
            } else if (value.IsGuarantor === true) {
              value.clientType = 'Guarantor';
              value.partyType = PERSON_PARTY_TYPES.GUARANTOR.toString();
            } else if (value.IsSolicitor === true) {
              value.clientType = 'Solicitor';
              value.partyType = PERSON_PARTY_TYPES.SOLICITOR.toString();
            } else if (value.IsAccountant === true) {
              value.clientType = 'Accountant';
              value.partyType = PERSON_PARTY_TYPES.ACCOUNTANT.toString();
            }

            $scope.relationshipPro.push(value);
          }
        });
      });
    };
    const enquirySourceParam = {
      familyId: $scope.familyId,
    };
    enquirySourceService.getEnquirySourceList($scope, enquirySourceParam);

    const resetReferralDetails = () => {
      $scope.contactInfoSetValue.ReferrerID = 0;
      $scope.contactInfoSetValue.ReferrerAgreementTypeId = 0;
      $scope.contactInfoSetValue.ReferrerName = '';
      $scope.contactInfoSetValue.ReferrerOrgID = 0;
      $scope.contactInfoSetValue.ReferrerOrgName = '';
    };

    // Enquiry is changed
    $scope.enquiryChanged = function() {
      $scope.contactInfoSetValue.ReferrerNameLabel = '';
      $scope.contactInfoSetValue.ClientReferrerFamilyId = 0;
      $scope.contactInfoSetValue.OtherContactReferrerName = '';
      const enquirySource = $scope.contactInfoSetValue.EnquirySource || {
        // default of enquiry source is null
        ReferralCategoryName: '',
        ReferralCategoryId: '',
        ReferralItemName: '',
        ReferralItemId: '',
      };
      $scope.contactInfoSetValue.ReferralCategory =
        enquirySource.ReferralCategoryName;
      $scope.contactInfoSetValue.ReferralCategoryId =
        enquirySource.ReferralCategoryId;
      $scope.contactInfoSetValue.ReferralItem = enquirySource.ReferralItemName;
      $scope.contactInfoSetValue.ReferralItemId = enquirySource.ReferralItemId;

      if (
        referredByAccess.includes(enquirySource.ReferralItemId) &&
        !$scope.isUserNZ
      )
        return;
      resetReferralDetails();
      contactService
        .contactFamilyInfoSet($scope.contactInfoSetValue)
        .then(() => {
          return $scope.contactFamilyInfoGet();
        })
        .then(() => {
          toaster.pop('success', 'Information', 'Successful!');
          $scope.contactFamilyAddress();
        });
    };

    const querySet = function(setter, key, field, arrays) {
      corporateService[setter].apply(null, arrays).then(response => {
        if (response.status === 200) {
          $scope.editMode[field] = false;
          toaster.pop('success', 'Update', 'Successfully Updated!');
        }
      });
    };

    function OnUpdateDetails(e, setterType, obj, key, field) {
      let dataArray = [];
      if (setterType === 'clientUTMDetailsSet') {
        dataArray = [$scope.familyId, 0, obj];
        querySet(setterType, key, field, dataArray);
      }
    }

    $scope.$on('UPDATE_FIELD_DETAIL', OnUpdateDetails);

    $scope.setterType = ['clientUTMDetailsSet'];
    $scope.editMode = {};
    $scope.utmCampaigns = [];

    $scope.homeAutoAddresss = function() {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService.placeSearch($scope.contactAddress.Home).then(respond => {
          $scope.homeAutoAddressDetails = respond.data;
        });
      }, 500);
    };

    $scope.mailAutoAddresss = function() {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService.placeSearch($scope.contactAddress.Mail).then(respond => {
          $scope.mailAutoAddressDetails = respond.data;
        });
      }, 500);
    };

    $scope.selectHomeAddress = function(homeAddress) {
      $scope.contactAddress.Home = homeAddress;
      $scope.closeAutoHomeAddress();
    };

    $scope.closeAutoHomeAddress = function() {
      $scope.homeAutoAddressDetails = [];
    };

    $scope.selectMailAddress = function(mailAddress) {
      $scope.contactAddress.Mail = mailAddress;
      $scope.closeAutoMailAddress();
    };

    $scope.closeAutoMailAddress = function() {
      $scope.mailAutoAddressDetails = [];
    };

    $scope.utmDetails = function() {
      contactSharedData.accessByCorporate($scope.familyId).then(scope => {
        const { isCorporateUser, utmCampaigns } = scope;
        $scope.isCorporateUser = isCorporateUser;
        $scope.utmCampaigns = utmCampaigns;
        if ($scope.isCorporateUser) {
          corporateService
            .corporateContactGet(0, $scope.familyId)
            .then(corporateAdviser => {
              $scope.symmertyIDCorp = corporateAdviser.data;
            });
        }
      });
    };

    $scope.getRelationship();
    $scope.utmDetails();
    $scope.updateEmailAddress = false;
    $scope.updateHomeAddress = false;
    $scope.updateMailingAddress = false;
    $scope.updateReferenceCode = false;
    $scope.enableEditHomeAddress = false;
    $scope.showHomeAddressMap = true;
    $scope.contactHomeAddressID = null;
    $scope.contactMailAddressID = null;

    $scope.removeClientType = function(tag) {
      const index = $scope.contactInfoSetValue.ClientTypes.indexOf(tag.text);
      $scope.contactInfoSetValue.ClientTypes.splice(index, 1);
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
          toaster.pop('success', 'Deleted', 'Client type successfully deleted');
        });
    };

    $scope.removeCategory = function(tag) {
      const index = $scope.contactInfoSetValue.Categories.indexOf(tag.text);
      $scope.contactInfoSetValue.Categories.splice(index, 1);
      $scope.referralChecker('Categories');
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
          toaster.pop('success', 'Deleted', 'Category successfully deleted');
        });
    };

    $scope.removeRating = function(tag) {
      const index = $scope.contactInfoSetValue.Rating.indexOf(tag.text);
      $scope.contactInfoSetValue.Rating.splice(index, 1);
      $scope.referralChecker('Rating');
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
          toaster.pop('success', 'Deleted', 'Rating successfully deleted');
        });
    };

    $scope.removeReferenceCode = function(tag) {
      if ($scope.contactInfoSetValue.ReferenceCode === tag.text) {
        $scope.contactInfoSetValue.ReferenceCode = '';
      }
      $scope.referralChecker('ReferenceCode');
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
          toaster.pop(
            'success',
            'Deleted',
            'Reference code successfully deleted'
          );
        });
    };

    $scope.removeReferralSource = function(tag) {
      const referralSource = tag.text.split(' - ');
      if (referralSource[0] === $scope.contactInfoSetValue.ReferralCategory) {
        $scope.contactInfoSetValue.ReferralCategory = '';
      }
      if (referralSource[1] === $scope.contactInfoSetValue.ReferralItem) {
        $scope.contactInfoSetValue.ReferralItem = '';
      }
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
          toaster.pop(
            'success',
            'Deleted',
            'Referral source successfully deleted'
          );
        });
    };

    $scope.editEmailAddress = function() {
      $scope.updateEmailAddress = true;
    };

    $scope.saveEmailAddress = function() {
      $scope.updateEmailAddress = false;
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
          toaster.pop(
            'success',
            'Updated',
            'Email Address successfully updated'
          );
        });
    };

    $scope.editHomeAddress = function() {
      $scope.updateHomeAddress = true;
    };

    $scope.enableEditHomeAddressFunc = function() {
      $scope.enableEditHomeAddress = !$scope.enableEditHomeAddress;
    };

    function generateContactMap(formattedAddressParam, latitude, longitude) {
      let formattedAddress = formattedAddressParam;
      const toolTipContent = `${'<div class="iw-title">' +
        '<a id="iw-edit-address" onclick="enableEditButtonTrigger()" class="crm-link pull-right margin-right-10 margin-top-5" tooltip-placement="bottom" uib-tooltip="Edit Home Address"><i class="fa fa-edit"></i></a>' +
        '</div>' +
        '<div class="iw-container margin-left-10 margin-right-10 margin-top-10">' +
        '<div>'}${formattedAddress}</div></div>`;

      if (!formattedAddress) formattedAddress = 'Your Address';

      mapService.mapDesign(
        latitude,
        longitude,
        formattedAddress,
        toolTipContent,
        true,
        'contactHomeAddressMap',
        '30'
      );
    }

    $scope.loadMapFirst = function() {
      $scope.showHomeAddressMap = true;
      $scope.enableEditHomeAddress = false;

      if (typeof $scope.contactAddress.Home !== 'undefined') {
        if (
          !$scope.contactAddress.HomeGeocoded &&
          $scope.contactAddress.Home !== ''
        ) {
          $timeout.cancel($scope.searchTimeout);
          $scope.searchTimeout = $timeout(() => {
            if (
              $scope.contactHomeAddressID &&
              $scope.contactHomeAddressID > 0
            ) {
              generalService
                .addressDetailsGet($scope.contactHomeAddressID)
                .then(response => {
                  if (!response || !response.data) return;
                  $scope.contactHomeAddressCountry = response.data.country;
                  generateContactMap(
                    $scope.contactAddress.Home,
                    response.data.latitude,
                    response.data.longitude
                  );
                });
            }
          }, 3000);
        } else if (
          $scope.contactAddress.HomeGeocoded &&
          typeof $scope.contactAddress.HomeLatLng.latitude !== 'undefined' &&
          typeof $scope.contactAddress.HomeLatLng.longitude !== 'undefined' &&
          $scope.contactAddress.Home !== ''
        ) {
          generateContactMap(
            $scope.contactAddress.Home,
            $scope.contactAddress.HomeLatLng.latitude,
            $scope.contactAddress.HomeLatLng.longitude
          );
        }
      }
    };
    $scope.$watch('contactHomeAddressID', newValue => {
      if (!_.isUndefined(newValue) && Number(newValue) > 0) {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService.addressDetailsGet(newValue).then(({ data }) => {
            if (!data) return;
            $scope.contactHomeAddressCountry = data.country;
            generateContactMap(newValue, data.latitude, data.longitude);
          });
        }, 3000);
      }
    });

    $scope.editMailingAddress = function() {
      $scope.updateMailingAddress = true;
    };
    $scope.saveContactAddress = function() {
      $scope.updateHomeAddress = false;
      $scope.updateMailingAddress = false;

      const home = _.filter($scope.contactInfoSetValue.Addresses, o => {
        return o.Type === 'Home';
      });
      if (home[0]) {
        $scope.contactInfoSetValue.HomeAddressValuationExtendedDetailsId =
          home[0].AddressValuationExtendedDetailsId;
      } else {
        $scope.contactInfoSetValue.HomeAddressValuationExtendedDetailsId = '';
      }

      const mail = _.filter($scope.contactInfoSetValue.Addresses, o => {
        return o.Type === 'Mail';
      });
      if (mail[0]) {
        $scope.contactInfoSetValue.MailAddressValuationExtendedDetailsId =
          mail[0].AddressValuationExtendedDetailsId;
      } else {
        $scope.contactInfoSetValue.MailAddressValuationExtendedDetailsId = '';
      }

      $scope.contactInfoSetValue.Addresses = [];

      // Code Home
      generalService.placeSearch($scope.contactAddress.Home).then(response => {
        if (response.data[0].geoCoded) {
          $scope.contactInfoSetValue.Addresses.push({
            Type: 'Home',
            AddressId: '',
            AddressValuationExtendedDetailsId:
              $scope.contactInfoSetValue.HomeAddressValuationExtendedDetailsId,
            formatted_address: $scope.contactAddress.Home,
            latitude: response.data[0].latitude,
            longitude: response.data[0].longitude,
            geoCoded: true,
          });
        } else {
          $scope.contactInfoSetValue.Addresses.push({
            Type: 'Home',
            AddressId: '',
            AddressValuationExtendedDetailsId:
              $scope.contactInfoSetValue.HomeAddressValuationExtendedDetailsId,
            formatted_address: $scope.contactAddress.Home,
            geoCoded: false,
          });
        }

        // Code Mail
        generalService
          .placeSearch($scope.contactAddress.Mail)
          .then(placeResponse => {
            if (placeResponse.data[0].geoCoded) {
              $scope.contactInfoSetValue.Addresses.push({
                Type: 'Mail',
                AddressId: '',
                AddressValuationExtendedDetailsId:
                  $scope.contactInfoSetValue
                    .MailAddressValuationExtendedDetailsId,
                formatted_address: $scope.contactAddress.Mail,
                latitude: placeResponse.data[0].latitude,
                longitude: placeResponse.data[0].longitude,
                geoCoded: true,
              });
            } else {
              $scope.contactInfoSetValue.Addresses.push({
                Type: 'Mail',
                AddressId: '',
                AddressValuationExtendedDetailsId:
                  $scope.contactInfoSetValue
                    .MailAddressValuationExtendedDetailsId,
                formatted_address: $scope.contactAddress.Mail,
                geoCoded: false,
              });
            }

            // Save the home first
            dashboardService
              .addressDetailsSet(
                _.filter($scope.contactInfoSetValue.Addresses, o => {
                  return o.Type === 'Home';
                })[0]
              )
              .then(homeSetResponse => {
                _.map($scope.contactInfoSetValue.Addresses, o => {
                  if (o.Type === 'Home') o.AddressId = homeSetResponse.data;
                  return o;
                });

                // Saves the Mailing Address
                dashboardService
                  .addressDetailsSet(
                    _.filter($scope.contactInfoSetValue.Addresses, o => {
                      return o.Type === 'Mail';
                    })[0]
                  )
                  .then(mailSetResponse => {
                    _.map($scope.contactInfoSetValue.Addresses, o => {
                      if (o.Type === 'Mail') o.AddressId = mailSetResponse.data;
                      return o;
                    });

                    contactService
                      .contactFamilyInfoSet(
                        angular.toJson($scope.contactInfoSetValue)
                      )
                      .then(() => {
                        $scope.contactFamilyInfoGet();
                        toaster.pop(
                          'success',
                          'Updated',
                          'Address successfully updated'
                        );
                      });
                  });
              });
          });
      });
    };

    const processRawInfo = () => {
      $scope.contactInfoSetValue = { ...$scope.contactInfoRaw };
      const {
        ReferralCategory,
        ReferralCategoryId,
        ReferralItemName,
        ReferralItemId,
      } = $scope.contactInfoSetValue;
      $scope.contactInfoSetValue.EnquirySource = {
        ReferralCategory: ReferralCategory || '',
        ReferralCategoryId: ReferralCategoryId || 0,
        ReferralItemName: ReferralItemName || '',
        ReferralItemId: ReferralItemId || 0,
      };
      if (ReferralItemId !== LOAN_APP_ENQUIRY_SOURCE.REFERRAL) {
        resetReferralDetails();
      }
    };

    $scope.referralChecker = key => {
      const tempValue = $scope.contactInfoSetValue[key] || '';
      if ($scope.displayReferralValidation($scope.contactInfoSetValue)) {
        processRawInfo();
        $scope.contactInfoSetValue[key] = tempValue;
      }
    };

    $scope.updateContactStatus = function(contactStatus) {
      if ($scope.displayReferralValidation($scope.contactInfoSetValue)) {
        processRawInfo();
      }
      $scope.ContactStatus = contactStatus.Name;
      $scope.contactInfoSetValue.ContactStatusId = contactStatus.Value;
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          return $scope.contactFamilyInfoGet();
        })
        .then(() => {
          toaster.pop(
            'success',
            'Updated',
            'Contact status successfully updated'
          );
        });
    };

    $scope.editReferenceCode = function(e) {
      if (angular.element(e.target).hasClass('remove-button')) {
        return;
      }
      $scope.updateReferenceCode = true;
    };

    $scope.saveReferenceCode = function() {
      $scope.referralChecker('ReferenceCode');
      $scope.updateReferenceCode = false;
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          return $scope.contactFamilyInfoGet();
        })
        .then(() => {
          toaster.pop('success', 'Updated', 'Address successfully updated');
        });
    };

    $scope.updateReviewMonth = function() {
      $scope.referralChecker('ReviewMonth');
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          return $scope.contactFamilyInfoGet();
        })
        .then(() => {
          toaster.pop(
            'success',
            'Updated',
            'Review month successfully updated'
          );
        });
    };

    $scope.updateContactFamilyInfo = function() {
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
        });
    };

    $scope.deleteRelationship = function(GroupId) {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your relationship list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: true,
        },
        confirm => {
          if (confirm) {
            contactService
              .relationshipDelete($scope.familyId, GroupId)
              .then(() => {
                $scope.getRelationship();
                toaster.pop(
                  'success',
                  'Deleted',
                  'Relationship has been successfully deleted'
                );
              });
          }
        }
      );
    };

    $scope.getClientByFamilyId = function(isHttpRequest) {
      contactService
        .clientInformGet($scope.familyId, null, isHttpRequest)
        .then(response => {
          $scope.numberOfAdults = 0;
          $scope.clientsChildData = [];
          $scope.clientsAdultData = [];
          $scope.clientsDataObject = response.data;
          $scope.$parent.getSummaryData();
          angular.forEach($scope.clientsDataObject, value => {
            if (value.Role !== 'Child') {
              $scope.clientsAdultData.push(value);
            } else {
              const ageDifMs = Date.now() - new Date(value.DOB);
              const ageDate = new Date(ageDifMs); // miliseconds from epoch
              value.Age = Math.abs(ageDate.getUTCFullYear() - 1970);
              value.background = colorService.getRandomColor();
              value.initials = utilitiesService.filterInitial(
                value.FirstName,
                value.LastName
              );
              $scope.clientsChildData.push(value);
            }
          });
          $scope.numberOfAdults = $scope.clientsAdultData.length;

          $scope.clientsData = $scope.clientsDataObject;
          angular.element('.clients-list-box').matchHeight();
        });
    };

    $scope.$watch('selectedContacts', newValue => {
      if (newValue === 'clients') {
        $scope.clientsChildData = [];
        $scope.clientsAdultData = [];
        $scope.clientsData = [];

        $scope.getClientByFamilyId();
        $scope.contactFamilyInfoGet();
      }
    });

    $scope.deleteChildContact = function(clientId) {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your children list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: true,
        },
        confirm => {
          if (confirm) {
            contactService
              .contactClientDelete($scope.familyId, clientId)
              .then(response => {
                if (response.data > 0) {
                  toaster.pop(
                    'success',
                    'Deleted',
                    'Child successfully deleted'
                  );
                  $scope.getClientByFamilyId(true);
                } else {
                  SweetAlert.swal(
                    'Delete Message',
                    'This contact was provided as a corporate lead and cannot be deleted. Please contact customercare@loanmarket.com.au if you need assistance',
                    'error'
                  );
                }
              });
          }
        }
      );
    };
    $scope.deleteContact = function(clientId, clientsAdultData) {
      if (clientsAdultData.length <= 1) {
        toaster.pop(
          'error',
          'Unable to Delete',
          'Sorry, but you cannot have less than 1 Adult in an account'
        );
      } else {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your clients list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          confirm => {
            if (confirm) {
              contactService
                .contactClientDelete($scope.familyId, clientId)
                .then(response => {
                  if (response.data > 0) {
                    toaster.pop(
                      'success',
                      'Deleted',
                      'Client successfully deleted'
                    );
                    $scope.getClientByFamilyId();
                  } else {
                    SweetAlert.swal(
                      'Delete Message',
                      'This contact was provided as a corporate lead and cannot be deleted. Please contact customercare@loanmarket.com.au if you need assistance',
                      'error'
                    );
                  }
                });
            }
          }
        );
      }
    };

    // used everytime/ no need to call every open modal
    optionsService.countryListGet().then(
      response => {
        $scope.countryList = response.data;
      },
      () => {
        $scope.countryList = [];
      }
    );

    // Add Client
    //-------------------------------------------------------------
    $scope.openClientModal = function(clientId, isChild) {
      const appplicantFamilyId = null;
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/add_client_modal_v2.html',
        controller: 'AddClientModalCtrl',
        size: 'lg',
        scope: $scope,
        resolve: {
          countryList() {
            return $scope.countryList;
          },
          isChild() {
            return isChild;
          },
          clientId() {
            return clientId;
          },
          applicantFamilyId() {
            return appplicantFamilyId;
          },
        },
      });
      modalInstance.result.then(() => {
        $scope.getClientByFamilyId();
      });
    };

    optionsService.ContactStatusGet().then(response => {
      $scope.contactStatusList = contactStatusService.populateStatusWithColor(
        response.data
      );
    });

    $scope.getContactFamilyInfo = {};

    let initCount = 0;

    $scope.contactFamilyInfoGet = () => {
      return contactService
        .contactFamilyInfoGet($scope.familyId)
        .then(response => {
          $scope.getContactFamilyInfo = response.data;
          $scope.contactInfoSetValue = response.data;

          if ($scope.contactInfoSetValue) {
            const getContactStatus = _.find($scope.contactStatusList, obj => {
              return (
                parseInt(obj.Value, 10) ===
                parseInt($scope.contactInfoSetValue.ContactStatusId, 10)
              );
            }) || { Name: '', Value: '' };
            $scope.ContactStatus = getContactStatus.Name;
          }

          $scope.contactInfoSetValue.FamilyId = $scope.familyId;
          $scope.optionReferenceCode = [];
          $scope.contactAddress = {};
          $scope.contactAddress.Home = '';
          $scope.contactAddress.HomeLatLng = undefined;
          if ($scope.getContactFamilyInfo.ReferenceCode) {
            $scope.optionReferenceCode = [
              {
                text: $scope.getContactFamilyInfo.ReferenceCode,
              },
            ];
          }

          angular.forEach($scope.getContactFamilyInfo.Addresses, value => {
            const contactValueAddress =
              typeof value.formatted_address !== 'undefined'
                ? value.formatted_address
                : '';
            const contactValueAddressID =
              typeof value.AddressID !== 'undefined' ? value.AddressID : '';
            if (value.Type === 'Home' || value.Type === 'Current Address') {
              $scope.contactHomeAddressID = contactValueAddressID;
              $scope.contactAddress.Home = contactValueAddress;
            } else if (value.Type === 'Mail') {
              $scope.contactAddress.Mail = contactValueAddress;
              $scope.contactMailAddressID = contactValueAddressID;
            }
          });

          $scope.clientTypeTags = [];
          $scope.categoriesTags = [];
          $scope.ratingTags = [];
          $scope.reviewMonthTag = [
            { text: $scope.getContactFamilyInfo.ReviewMonth },
          ];
          $scope.referenceCodeTag = [
            { text: $scope.getContactFamilyInfo.ReferenceCode },
          ];
          if (
            $scope.getContactFamilyInfo.ReferralCategory !== '' &&
            typeof $scope.getContactFamilyInfo.ReferralCategory !==
              'undefined' &&
            ($scope.getContactFamilyInfo.ReferralItem !== '' &&
              typeof $scope.getContactFamilyInfo.ReferralItem !== 'undefined')
          ) {
            $scope.referralTag = [
              {
                text: `${$scope.getContactFamilyInfo.ReferralCategory} - ${
                  $scope.getContactFamilyInfo.ReferralItem
                }`,
              },
            ];
          } else if (
            $scope.getContactFamilyInfo.ReferralCategory !== '' &&
            typeof $scope.getContactFamilyInfo.ReferralCategory !==
              'undefined' &&
            (typeof $scope.getContactFamilyInfo.ReferralItem === 'undefined' ||
              $scope.getContactFamilyInfo.ReferralItem === '')
          ) {
            $scope.referralTag = [
              { text: $scope.getContactFamilyInfo.ReferralCategory },
            ];
          } else {
            $scope.referralTag = [];
          }

          const {
            ClientTypes,
            Categories,
            Rating,
          } = $scope.getContactFamilyInfo;

          ClientTypes.forEach(value => {
            if (value) $scope.clientTypeTags.push({ text: value });
          });

          Categories.forEach(value => {
            if (value) $scope.categoriesTags.push({ text: value });
          });

          Rating.forEach(value => {
            if (value) $scope.ratingTags.push({ text: value });
          });

          $scope.corpLeadPaymentMethod = $scope.getContactFamilyInfo
            .CorpLeadPaidUpfront
            ? 'Upfront on allocation'
            : 'Comm. share on settlement';

          const referral = $scope.contactInfoSetValue;
          if (!referral.ReferralItem)
            referral.ReferralItem = referral.ReferralCategory;

          if (referral.ReferralCategory) {
            referral.EnquirySource = {
              ReferralItemName: referral.ReferralCategory,
            };
            const defaultReferralOption = {
              ReferralCategoryId: null,
              ReferralItemId: null,
            };
            if (referral.ReferralCategory === 'Referral') {
              referral.EnquirySource = {
                ReferralItemName: $scope.getContactFamilyInfo.ReferralItem,
              };
              const referralOption =
                ($scope.EnquirySourceList &&
                  $scope.EnquirySourceList.find(
                    enquirySource =>
                      enquirySource.ReferralItemId ===
                      $scope.getContactFamilyInfo.ReferralItemId
                  )) ||
                defaultReferralOption;
              const { ReferralCategoryId, ReferralItemId } = referralOption;
              if (referralOption && ReferralCategoryId) {
                referral.EnquirySource = {
                  ...referral.EnquirySource,
                  ReferralCategoryId,
                };
              }
              if (referralOption && ReferralItemId) {
                referral.EnquirySource = {
                  ...referral.EnquirySource,
                  ReferralItemId,
                };
              }
              if (
                _.isUndefined($scope.contactInfoSetValue.ReferrerName) &&
                _.isUndefined($scope.contactInfoSetValue.ReferrerOrgName)
              ) {
                $scope.contactInfoSetValue.ReferrerNameLabel = '';
              } else if (
                _.isUndefined($scope.contactInfoSetValue.ReferrerName)
              ) {
                $scope.contactInfoSetValue.ReferrerNameLabel =
                  $scope.contactInfoSetValue.ReferrerOrgName;
              } else if (
                _.isUndefined($scope.contactInfoSetValue.ReferrerOrgName)
              ) {
                $scope.contactInfoSetValue.ReferrerNameLabel =
                  $scope.contactInfoSetValue.ReferrerName;
              } else {
                $scope.contactInfoSetValue.ReferrerNameLabel = `${
                  $scope.contactInfoSetValue.ReferrerName
                } - ${$scope.contactInfoSetValue.ReferrerOrgName}`;
              }
            } else if (referral.ReferralItem) {
              referral.EnquirySource = {
                ReferralItemName: referral.ReferralItem,
                ReferralItemId: referral.ReferralItemId,
              };
            }
          }

          if (initCount < 2) {
            // check if first load
            contactService.getUserInfo().then(userlevel => {
              // Overide condition if Corporate User
              if (parseInt(userlevel.data.AccessType, 10) !== 5) {
                if (
                  referral.ReferralCategory === 'Website - Loan Market' ||
                  referral.ReferralCategory === 'Affiliate - Loan Market'
                ) {
                  $('#options-information')
                    .find('#EnquirySourceDropdown')
                    .attr('disabled', 'disabled'); // disable the dropdown
                }
              }
            });
          }
          $scope.contactInfoRaw = { ...$scope.contactInfoSetValue };
          initCount++;
          $scope.initCount = initCount;

          if (!$scope.contactInfoSetValue.ClientReferrerFamilyId) return;
          $scope.contactInfoSetValue.clientInitial = {
            FamilyID: $scope.contactInfoSetValue.ClientReferrerFamilyId,
            FamilyFullName: $scope.contactInfoSetValue.ClientReferrerName,
          };
          return response;
        });
    };

    $scope.getMonthsOptions = () => {
      optionsService.getReviewMonth().then(({ data }) => {
        $scope.selectReviewMonth = data || [];
      });
    };

    $scope.getMonthsOptions();

    $scope.contactFamilyInfoGet();

    // Client Type
    $scope.openClientTypeModal = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/client_type_modal.html',
        controller: 'ClientTypeModalCtrl',
        size,
        scope: $scope,
      });
    };

    // Category
    $scope.openCategoryModal = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/category_modal.html',
        controller: 'CategoryModalCtrl',
        size,
        scope: $scope,
      });
    };

    // Rating
    $scope.openRatingModal = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/modals/rating_modal.html',
        controller: 'RatingModalCtrl',
        size,
        scope: $scope,
      });
    };

    $scope.getReferralCategoryList = function() {
      referralDetailService.getReferralCategoryList().then(response => {
        $scope.referralCategoryList = _.uniqBy(response, 'ReferralCategoryID');
      });
    };

    $scope.getReferralCategoryList();

    $scope.openReferralModal = function(size) {
      $scope.openReferralModalInstance = contactSharedData.openReferralModal(
        size,
        $scope.getReferralCategoryList,
        $scope.contactFamilyInfoGet,
        $scope.contactInfoSetValue,
        $scope.selectedReferralCategory,
        $scope.referralCategoryList
      );
      $scope.openReferralModalInstance.closed.then(() => {
        enquirySourceService.getEnquirySourceList($scope);
      });
    };

    $scope.showManageClient = false;

    $rootScope.isCorporateUser = false;
    $rootScope.isAdviserUser = false;
    contactService.getUserInfo().then(response => {
      if (response.data) {
        $scope.userInfo = response.data;
        if (parseInt(response.data.AccessType, 10) === ACCESS_TYPE.CORPORATE) {
          $rootScope.isCorporateUser = true;
        } else if (
          parseInt(response.data.AccessType, 10) === ACCESS_TYPE.ADVISER
        ) {
          $rootScope.isAdviserUser = true;
        }
      }
    });

    $document.bind('click', () => {
      const bindClickTimeout = $timeout(() => {
        $scope.selectedPartyType = false;
        $scope.isShowCompaniesSearch = false;
        $scope.isShowProfessionalSearch = false;
        $timeout.cancel(bindClickTimeout);
      });
    });

    $scope.isShowCompaniesSearch = false;
    $scope.isShowProfessionalSearch = false;
    // Remove function below
    $scope.resetSearch = function(selectedPartyTypeParam, searchType, event) {
      const selectedPartyType = String(selectedPartyTypeParam);
      $scope.selectedClientName = '';

      if (selectedPartyType === $scope.selectedPartyType)
        $scope.selectedPartyType = 0;
      // default ~ data should be PARTYTYPES
      else $scope.selectedPartyType = selectedPartyType;

      $scope.selectedClient = null;
      $scope.getClientList = [];

      $timeout(() => {
        const el = $window.document.querySelectorAll('.applicant-search');
        angular.element(el).focus();
      }, 0);
      switch (searchType) {
        case 'CompaniesSearch':
          $scope.isShowCompaniesSearch = !$scope.isShowCompaniesSearch;
          $scope.isShowProfessionalSearch = false;
          break;
        case 'ProfessionalSearch':
          $scope.isShowProfessionalSearch = !$scope.isShowProfessionalSearch;
          $scope.isShowCompaniesSearch = false;
          break;
        default:
          $scope.isShowCompaniesSearch = false;
          $scope.isShowProfessionalSearch = false;
          break;
      }
      if (event) {
        event.stopPropagation();
      }
    };
    $scope.resetSearch(0);

    // Search
    $scope.searchClient = function(searchString) {
      $scope.clientNotFound = false;

      if (searchString && searchString.length > 2) {
        optionsService
          .scenarioSearchClientGet(searchString, $scope.selectedPartyType)
          .then(response => {
            if (!response) return;
            const data = response.data;

            _.map(data, o => {
              o.initials = utilitiesService.filterInitial(
                o.FirstName,
                o.LastName
              );
              return o;
            });

            _.forEach($scope.relationshipCompany, comp => {
              _.remove(data, o => {
                return (
                  parseInt(comp.OrganisationId, 10) ===
                  parseInt(o.ClientEntityId, 10)
                );
              });
            });

            _.forEach($scope.relationshipPro, comp => {
              _.remove(data, o => {
                return (
                  parseInt(comp.PersonId, 10) === parseInt(o.ClientEntityId, 10)
                );
              });
            });
            $scope.getClientList = data;
          });
      } else {
        $scope.getClientList = [];
      }
    };

    // save selected client to loan app
    $scope.saveSelectClient = function() {
      const postData = {
        MainFamilyId: $scope.familyId,
        EntityList: [],
      };

      if (
        parseInt($scope.selectedPartyType, 10) === ENTITY_PARTY_TYPES.COMPANY ||
        parseInt($scope.selectedPartyType, 10) ===
          ENTITY_PARTY_TYPES.PARTNERSHIP ||
        parseInt($scope.selectedPartyType, 10) === ENTITY_PARTY_TYPES.TRUST
      ) {
        postData.EntityList.push({
          OrganisationId: $scope.selectedClient.ClientEntityId,
          IsApplicant: false,
          IsGuarantor: false,
          IsSolicitor: false,
          IsAccountant: false,
        });
      } else {
        postData.EntityList.push({
          PersonId: $scope.selectedClient.ClientEntityId,
          IsApplicant:
            parseInt($scope.selectedPartyType, 10) ===
            PERSON_PARTY_TYPES.APPLICANT,
          IsGuarantor:
            parseInt($scope.selectedPartyType, 10) ===
            PERSON_PARTY_TYPES.GUARANTOR,
          IsSolicitor:
            parseInt($scope.selectedPartyType, 10) ===
            PERSON_PARTY_TYPES.SOLICITOR,
          IsAccountant:
            parseInt($scope.selectedPartyType, 10) ===
            PERSON_PARTY_TYPES.ACCOUNTANT,
        });
      }

      contactService.relationshipSet(postData).then(
        () => {
          const setTimer = $timeout(() => {
            $scope.resetSearch(0);
            toastSuccess('Relationship successfully saved');
            $scope.getRelationship();
            $timeout.cancel(setTimer);
          }, 500);
        },
        () => {
          toaster.pop(
            'warning',
            'Unable to Add',
            'Relationship is not saved, please try again'
          );
        }
      );
    };

    // Select a client or loop through client from family id you've created from the create contact
    $scope.selectClient = function(
      client,
      isFromAddNewModal,
      createdFamilyId,
      partyType
    ) {
      if (!isFromAddNewModal && !createdFamilyId) {
        if (partyType === ENTITY_PARTY_TYPES.COMPANY) {
          $scope.selectedClient = client;
          $scope.selectedPartyType = partyType;
          $scope.saveSelectClient();
          return;
        }

        $scope.selectedClient = client;
        $scope.selectedClientName = `${client.FirstName} ${client.LastName}`;
        $scope.saveSelectClient();
      } else if (isFromAddNewModal && createdFamilyId) {
        // everything except 2 which is a company
        if (
          +partyType !== ENTITY_PARTY_TYPES.COMPANY &&
          +partyType !== ENTITY_PARTY_TYPES.PARTNERSHIP
        ) {
          // Get all the clients you've created from the create contact
          contactService.clientInformGet(createdFamilyId).then(response => {
            _.forEach(response.data, obj => {
              $scope.selectedClient = {
                ClientEntityId: obj.PersonId,
              };
            });

            // PartyType
            $scope.selectedPartyType = partyType;
            $scope.saveSelectClient();
          });
        } else {
          contactService.organisationInfoGet(createdFamilyId).then(response => {
            $scope.selectedClient = {
              ClientEntityId: response.data.OrganisationId,
            };

            // PartyType
            $scope.selectedPartyType = partyType;
            $scope.saveSelectClient();
          });
        }
      } else {
        toaster.pop('error', 'Ooops', 'Something went wrong');
        return false;
      }
    };

    $scope.getNewContactModel = function(role) {
      let country;
      switch ($rootScope.crmCountry) {
        case 'Australia':
          country = 'AU';
          break;
        case 'New Zealand':
          country = 'NZ';
          break;
        default:
          country = '-1';
      }

      return {
        FamilyId: '0',
        PersonId: '0',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        PreferredName: '',
        Title: '',
        Gender: '',
        DOB: '',
        Role: role,
        Employment: [
          {
            Occupation: '',
            OccupationClass: 0,
            Employer: '',
          },
        ],
        SmokerStatus: '',
        Deceased: false,
        DOBReminder: false,
        BestTimeToCall: '',
        IsResident: true,
        Phone: [
          {
            Type: 'Home',
            Number: '',
          },
          {
            Type: 'Work',
            Number: '',
          },
          {
            Type: 'Mobile',
            Number: '',
          },
        ],
        Email: [
          {
            Type: 'Email',
            EmailAddress: '',
          },
        ],
        Notes: '',
        CountryCode: country,
      };
    };

    $scope.Dependants = $scope.getNewContactModel('Child');

    // Add Related Modal

    $scope.personCompanyModal = function(
      type,
      familyId,
      clientEntityId,
      isClient,
      child
    ) {
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/client/rel.add.html',
        controller: 'PersonCompanyModalCtrl',
        scope: $scope,
        size: 'sm',
        resolve: {
          selectedPartyType() {
            return type;
          },
          familyId() {
            return familyId;
          },
          clientEntityId() {
            return clientEntityId;
          },
          isClient() {
            return isClient;
          },
          child() {
            return child;
          },
          userInfo() {
            return $scope.userInfo;
          },
        },
      });

      modalInstance.result.then(
        response => {
          $scope.selectClient({}, true, response.FamilyId, response.PartyType);
        },
        () => {
          $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
        }
      );
    };

    // New Add Company/Trust v2
    $scope.companyTrustModal = (type, entity = null) => {
      const selectedCompanyType =
        type === COMPANY_TYPES.COMPANY_STRING
          ? COMPANY_TYPES.COMPANY
          : COMPANY_TYPES.TRUST;
      const newModalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/loanApplication/clientAssess/modal/addTrust.html',
        controller: 'AddTrustCtrl',
        controllerAs: 'vm',
        size: 'md',
        resolve: {
          selectedPartyType() {
            return parseInt(selectedCompanyType, 10);
          },
          entity() {
            return entity;
          },
        },
        backdrop: 'static',
        keyboard: false,
      });

      newModalInstance.result.then(response => {
        $scope.selectedPartyType = selectedCompanyType;
        $scope.selectClient({}, true, response.FamilyId, response.PartyType);
      });
    };

    // Add Dependants button
    $scope.addDependants = function() {
      $scope.Dependants.FamilyId = $scope.familyId;
      $scope.Dependants.PersonId = '0';

      const age = $scope.Dependants.Age ? $scope.Dependants.Age : 0;
      $scope.Dependants.DOB = `01/01/${parseInt(moment().year(), 10) -
        parseInt(age, 10)}`;

      contactService.familyInfoSet([$scope.Dependants]).then(() => {
        $scope.getClientByFamilyId();
        // reset dependant
        $scope.Dependants = $scope.getNewContactModel('Child');
        $scope.resetSearch(0);
      });
    };
    $scope.displayReferralValidation = obj => {
      if (!obj || !obj.EnquirySource) return false;
      const isReferral = referredByAccess.includes(
        obj.EnquirySource.ReferralItemId
      );
      return isReferral && (!obj.ReferrerID || !obj.ReferrerNameLabel);
    };
    $scope.clientEnquiryList = CLIENT_REFERRAL;
    $scope.additionalEnquiryList = ADDITIONAL_REFERRAL;
    $scope.displayField = displayField;
    $scope.clientSearchApi = userInputString => {
      const defer = $q.defer();
      defer.resolve([]);
      if (!userInputString || !$scope.contactInfoSetValue.BrokerID)
        return defer.promise;
      return contactService.searchFamilyContactType(
        userInputString,
        'Client',
        $scope.contactInfoSetValue.BrokerID
      );
    };
    $scope.updateContactInfo = () => {
      contactService
        .contactFamilyInfoSet($scope.contactInfoSetValue)
        .then(() => {
          $scope.contactFamilyInfoGet();
        });
    };
    $scope.selectedClientReferral = clientObj => {
      $scope.contactInfoSetValue.ClientReferrerFamilyId =
        clientObj && clientObj.originalObject
          ? clientObj.originalObject.FamilyID
          : 0;
      $scope.updateContactInfo();
    };
    $scope.displayReferredBy = obj => {
      if (!obj || !obj.EnquirySource || $scope.isUserNZ) return false;
      return referredByAccess.includes(obj.EnquirySource.ReferralItemId);
    };
    $scope.disableEnquirySource = () => {
      if (
        currentUserService.isCorporate ||
        !$scope.contactInfoSetValue ||
        !$scope.contactInfoSetValue.ReferralCategoryId
      )
        return false;
      const disabledCategory = [CATEGORY.AFFILIATE, CATEGORY.WEBSITE];
      return disabledCategory.includes(
        $scope.contactInfoSetValue.ReferralCategoryId
      );
    };
  });
