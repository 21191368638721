import { isValidEmailAddress } from 'Common/utilities/string';

export default class EmailEditor {
  constructor(emailObj = {}) {
    'ngInject';

    this.emailObj = emailObj;
  }

  onSelectRecipient(recipient) {
    const isEmailObjects = typeof recipient === 'object';

    if (
      !isValidEmailAddress(isEmailObjects ? recipient.emailAddress : recipient)
    ) {
      this.isRecipientsInValid = true;

      this.recipientsValidationMessage = `${recipient.emailAddress ||
        recipient} is not a valid email address.`;
      return;
    }

    this.isRecipientsInValid = false;
    if (this.emailObj.recipientsEmailAddresses) {
      this.emailObj.recipientsEmailAddresses = [
        ...this.emailObj.recipientsEmailAddresses,
        recipient,
      ];
    } else {
      this.emailObj.recipientsEmailAddresses = [recipient];
    }
  }

  onSelectCc(cc) {
    const isEmailObjects = typeof cc === 'object';

    if (!isValidEmailAddress(isEmailObjects ? cc.emailAddress : cc)) {
      this.isCcInValid = true;
      this.ccValidationMessage = `${cc.emailAddress ||
        cc} is not a valid email address.`;
      return;
    }

    this.isCcInValid = false;
    if (this.emailObj.ccEmailAddresses) {
      this.emailObj.ccEmailAddresses = [...this.emailObj.ccEmailAddresses, cc];
    } else {
      this.emailObj.ccEmailAddresses = [cc];
    }
  }

  onRemoveRecipient(recipient) {
    if (!this.emailObj || !this.emailObj.recipientsEmailAddresses) return;

    const indexOfRecipient = this.emailObj.recipientsEmailAddresses.indexOf(
      recipient
    );

    if (indexOfRecipient === -1) return;
    this.emailObj.recipientsEmailAddresses = [
      ...this.emailObj.recipientsEmailAddresses.slice(0, indexOfRecipient),
      ...this.emailObj.recipientsEmailAddresses.slice(indexOfRecipient + 1),
    ];
  }

  onRemoveCc(recipient) {
    if (!this.emailObj || !this.emailObj.ccEmailAddresses) return;

    const indexOfRecipient = this.emailObj.ccEmailAddresses.indexOf(recipient);

    if (indexOfRecipient === -1) return;
    this.emailObj.ccEmailAddresses = [
      ...this.emailObj.ccEmailAddresses.slice(0, indexOfRecipient),
      ...this.emailObj.ccEmailAddresses.slice(indexOfRecipient + 1),
    ];
  }

  onUpdateSubject(subject) {
    this.emailObj.subject = subject;
  }

  onUpdateBody(body) {
    this.emailObj.body = body;
  }
}
