import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import {
  subscriptionBuilderForMyCRM,
  subIncomeTypeBuilderForUI,
  summarySectionBuilderForUI,
  commissionContactsListBuilderForUI,
  commissionAccessBuilderForUI,
  userInfoBuilderForUI,
  contactAdviserThemeBuilderForUI,
  contactSplitBuilderForMyCRM,
  hasAutomationMarketingBuilderForUI,
  subscriptionPopUpBuilderForUI,
} from 'Common/mappers/contact';
import {
  reviewFactFindBuilderForUI,
  factFindPricingBuilderForUI,
} from 'Common/mappers/factFind';

class ContactModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'contacts';
  }

  setGmailSignature(html) {
    return this.httpClient.post(`google/gmail-signature`, html);
  }
  gmailSyncDeactivate(params) {
    return this.httpClient.post(`google/gmail-sync-deactivate`, {}, params);
  }
  getLoanTimeline(id) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${id}/loan-timeline`)
      .then(res => res && collectionCamelizeBuilderForUI(res.data));
  }

  getLoanTimelineCard(familyId, loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/loan-timeline/${loanId}`)
      .then(res => res && collectionCamelizeBuilderForUI(res.data));
  }

  getSubscriptionPopUp(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/subscription-popup/`, params)
      .then(res => res && subscriptionPopUpBuilderForUI(res.data));
  }

  postSubscriptionPopUp(tickedItemId) {
    return this.httpClient.post(`${this.apiBaseUrl}/subscription-popup/`, {
      tickedItemId,
    });
  }

  subscription(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/Subscriptions`,
      subscriptionBuilderForMyCRM(data)
    );
  }

  updateSubscription(data) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/Subscriptions`,
      subscriptionBuilderForMyCRM(data)
    );
  }

  getLoans(id) {
    return this.httpClient.get(`${this.apiBaseUrl}/${id}/loans`);
  }

  sendCreditGuide(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/credit-guide`, data);
  }
  getSubIncomeType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/sub-income-type`)
      .then(
        response =>
          (response.data && response.data.map(subIncomeTypeBuilderForUI)) || []
      );
  }
  taskAdviserListGet(loanWritersOnly = null, taskId = null) {
    return this.httpClient.get(`${this.apiBaseUrl}/TaskAdviserListGet`, {
      loanWritersOnly,
      taskId,
    });
  }
  postMergeFamilyProfile(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/MergeFamilyProfile`,
      {},
      params
    );
  }
  getBrokerCommissionAccess() {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokerCommissionAccess`);
  }
  checkFactFind(clientIds, loanAppId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/fact-find-check/${loanAppId}`, {
        clientIds,
      })
      .then(
        response => response.data && reviewFactFindBuilderForUI(response.data)
      );
  }
  getFactFindPricing() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/online-fact-find-pricing`)
      .then(
        response =>
          response.data && response.data.map(factFindPricingBuilderForUI)
      );
  }
  getSummarySection() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/section`)
      .then(
        response =>
          response.data && response.data.map(summarySectionBuilderForUI)
      );
  }
  updateSummarySection(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/section`, {}, params);
  }
  mailTemplateSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/MailTemplate`, {}, params);
  }
  contactSplitSet(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/contact-split`,
      contactSplitBuilderForMyCRM(params)
    );
  }
  familyListGet(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/FamilyListGet`, params);
  }
  commissionContactsList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/commission-contacts-list`, params)
      .then(
        res => res.data && res.data.map(commissionContactsListBuilderForUI)
      );
  }
  commissionAccess(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/commissionAccess`, { familyId })
      .then(res => res.data && res.data.map(commissionAccessBuilderForUI));
  }
  getFamilyAdviserInfo(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/adviser-info`)
      .then(({ data }) => data && userInfoBuilderForUI(data));
  }
  getFamilyAdviserTheme(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/adviser-theme`)
      .then(({ data }) => data && contactAdviserThemeBuilderForUI(data));
  }
  getHasAutomationMarketing(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/has-automation-marketing`)
      .then(res => res.data && hasAutomationMarketingBuilderForUI(res.data));
  }
  confirmContactAssets(assetIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-assets?assetIds=${assetIds}`
    );
  }
  confirmContactLiabilities(liabilityIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-liabilities?liabilityIds=${liabilityIds}`
    );
  }
  confirmContactIncome(incomeIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-income?incomeIds=${incomeIds}`
    );
  }
  confirmContactExpenses(expenseIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-expenses?expenseIds=${expenseIds}`
    );
  }
}

export default ContactModelService;
