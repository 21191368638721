import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';

export const getDesignatedFamilyBrokerInfo = ({
  scope,
  familyId,
}) => apiService => {
  if (!scope || !familyId || !apiService) return;
  apiService.getFamilyAdviserInfo(familyId).then(response => {
    if (!response) return;
    scope.designatedBrokerInfo = response;
  });
};

export const getAdviserTabOptionValue = configService => {
  const { userSubscription } = configService.feature;
  const showSubscriptionMangement = userSubscription || 0;
  const corporateAccessAdmin = [
    CORPORATE_TEAM.AGREEMENT_AND_ONBOARDING,
    CORPORATE_TEAM.SUPER_ADMIN,
  ];
  return {
    showSubscriptionMangement,
    corporateAccessAdmin,
  };
};
