export const getSelectedAdviser = (data, list) => {
  const filteredList =
    list &&
    list.filter(o => {
      return parseInt(o.FamilyId, 10) === parseInt(data, 10);
    });
  const selectedAdviser = filteredList ? filteredList[0] : { FamilyId: data };
  return selectedAdviser;
};

export const getAdviserName = (selectedAdviser, name) => {
  const adviserName =
    selectedAdviser && selectedAdviser.FullName
      ? selectedAdviser.FullName
      : name;
  return adviserName;
};
