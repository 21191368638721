import { INSURANCE_TAB_ID } from 'Common/constants/customerCareModules';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import moment from 'moment';
import { isDateSentActive } from 'Assets/js/controllers/customerCare/partial/util/customerCareTabsCtrl';

export default class TabContentCtrl {
  constructor(
    $uibModal,
    $localStorage,
    $state,
    configService,
    commonFnService,
    customerCareService,
    customerCareInsuranceService,
    insurancePipelineService,
    currentUserService
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$localStorage = $localStorage;
    this.$state = $state;
    this.configService = configService;
    this.commonFnService = commonFnService;
    this.customerCareService = customerCareService;
    this.customerCareInsuranceService = customerCareInsuranceService;
    this.insurancePipelineService = insurancePipelineService;
    this.userInfo = currentUserService;
    this.INSURANCE_TAB_ID = INSURANCE_TAB_ID;
  }

  $onInit() {
    this.statuses = [];
    this.tableData = [];
    this.customerCareDateRange = [];
    this.customerCareAdviserList = [];
    this.filterUpdateCount = 0;
    this.isIssuedFollowUp = this.tab.id === INSURANCE_TAB_ID.ISSUED_FOLLOWUP;
    this.noTimeFrame =
      this.tab.id === this.INSURANCE_TAB_ID.CURRENT_CLAIMS ||
      this.tab.id === this.INSURANCE_TAB_ID.IN_ARREARS;

    this.dateSentFeature = {
      isActive: isDateSentActive(this.configService),
      actionColumnTitle: isDateSentActive(this.configService)
        ? 'Communication Sent'
        : '',
    };
    this.filterData = {
      brokerId: 0,
      pageSize: 10,
      isCompleted: false,
      dateRange: this.isIssuedFollowUp ? 0 : 60,
      benefitTypeId: '',
    };
    this.getTabBrokers();
    this.getCustomerCareDateRange();
    this.loadInsuranceStatuses();
    this.dateRangeFilter();
  }

  onTableDataLoaded(event) {
    this.tableData = event.tableData;
  }

  onSendModal(event) {
    this.currentItem = event.item;
    this.currentSendType = event.sendVia;
    this[event.sendVia]('lg', event.item, 'single');
  }

  goToContact(event) {
    this.commonFnService.hiddenClientForNz().then(isHidden => {
      if (!event.familyId || isHidden) return;
      this.$state.go('app.contactsSingle', { familyId: event.familyId });
    });
  }

  loadInsuranceStatuses() {
    this.insurancePipelineService
      .getInsurancePipelineStatus(false)
      .then(response => {
        this.statuses = [{ id: '', name: 'All Application' }, ...response];
      });
  }

  dateRangeFilter() {
    const issuedMoment = moment().subtract(
      Number(this.filterData.dateRange),
      'months'
    );
    const nowDate = this.isIssuedFollowUp
      ? issuedMoment.startOf('month').format(D_MMM_YYYY_FORMAT)
      : moment().format(D_MMM_YYYY_FORMAT);
    const upToDate = this.isIssuedFollowUp
      ? issuedMoment.endOf('month').format(D_MMM_YYYY_FORMAT)
      : moment()
          .add(Number(this.filterData.dateRange), 'days')
          .format(D_MMM_YYYY_FORMAT);

    this.filterData.reviewDateStart =
      this.filterData.dateRange >= 0 ? nowDate : upToDate;
    this.filterData.reviewDateEnd =
      this.filterData.dateRange >= 0 ? upToDate : nowDate;

    this.updateFilter();
  }

  updateFilter() {
    this.filterUpdateCount++;
  }

  getTabBrokers() {
    this.customerCareService
      .getBrokers({ loanWritersOnly: true })
      .then(response => {
        this.customerCareAdviserList = response;
      });
  }

  getCustomerCareDateRange() {
    let dateRange;
    if (this.isIssuedFollowUp) {
      dateRange = this.customerCareInsuranceService.getDateRangeFilter();
    } else {
      dateRange = this.customerCareService.customerCareDateRange();
    }
    dateRange.then(data => {
      this.customerCareDateRange = data;
    });
  }

  openSendEmailModal(size, fixedRateObj, source) {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_email_modal.html',
      resolve: {
        fixedRateListTable: () => this.tableData,
        fixedRateObj: () => fixedRateObj,
        source: () => source,
        isInsurance: () => true,
        tab: () => this.tab,
      },
      controller: 'FixedRateSendEmailModalCtrl',
      size,
    });
    this.sendingResult(modalInstance);
  }
  openSendMailModal(size, fixedRateObj, source) {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_mail_modal.html',
      resolve: {
        listTableData: () => this.tableData,
        sendMailObj: () => fixedRateObj,
        source: () => source,
        currentModuleId: () => this.tab.id,
        currentModuleType: () => this.tab.type,
        currentModuleName: () => this.tab.label,
        isInsurance: () => true,
        tab: () => this.tab,
      },
      controller: 'OpenSendMailModalCtrl',
      size,
    });
    this.sendingResult(modalInstance);
  }
  openSMSModal(size, fixedRateObj, source) {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_sms_modal.html',
      resolve: {
        fixedRateListTable: () => this.tableData,
        fixedRateObj: () => fixedRateObj,
        source: () => source,
        isInsurance: () => true,
        tab: () => this.tab,
      },
      controller: 'FixedRateSMSModalCtrl',
      size,
    });

    this.sendingResult(modalInstance);
  }

  sendingResult(modalInstance) {
    modalInstance.result.then(result => {
      if (result.success) this.updateFilter();
    });
  }
}
