import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { ADVISER_TAB } from 'Common/constants/contactTabIndex';

export const openCreditCheck = ({
  $scope,
  $state,
  modalRenderService,
}) => () => {
  const contactForCreditCheck = {
    FamilyID: $scope.familyId,
    ClientFirstName: $scope.adviserDetails.FirstName,
    ClientLastName: $scope.adviserDetails.LastName,
    isAdviser: true,
  };
  const modalInstance = modalRenderService.openCreditCheckModal(
    contactForCreditCheck
  );
  modalInstance.result.then(() => {
    $state.reload();
  });
};

export const displayCreditCheck = currentUserService => () => {
  const corporateAccess = [
    CORPORATE_TEAM.AGREEMENT_AND_ONBOARDING,
    CORPORATE_TEAM.SUPER_ADMIN,
  ];
  return corporateAccess.includes(currentUserService.corporateTeamId);
};

export const isRefreshSection = ({ $scope }) => tabIndex => {
  const tabRefreshSection = [ADVISER_TAB.ONBOARDING, ADVISER_TAB.DOCUMENTS];
  const isRefresh =
    !tabRefreshSection.includes(tabIndex) ||
    $scope.contactModel.selectedContacts === tabIndex;
  return isRefresh;
};
