export const SUBSCRIPTIONTYPE = {
  SMS: 2,
  NPS: 3,
  CAMPAIGN_BREEZE: 4,
  BROKERPEDIA: 5,
  CREDIT_CHECK: 6,
  PRICE_FINDER: 7,
  MARKETING_AUTOMATION: 8,
  E_SIGN: 9,
  BANK_CONNECT: 10,
  BANK_CONNECT_EXPENSES_ANALYSIS: 11,
  NURTURE: 12,
};
