import {
  profilerNoteTemplateBuilderForMyCRM,
  profilerSendReportMailBuilderForMyCRM,
  recipientBuilderForMyCRM,
  profilerNoteTemplateBuilderForUI,
  benefitProfilerNoteTemplatesBuilderForUI,
  allocatedAdviserBuilderForUI,
  summaryOfCoverBuilderForUI,
} from 'Common/mappers/insurance';

class InsuranceProfilerService {
  constructor($q, httpClient) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'InsuranceQuote';
    this.profilerBaseUrl = 'profiler';
  }

  profilerIdGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/ProfilerProfilerIdGet`);
  }

  insuranceClientIdGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceClientIdGet`);
  }

  profilerClientIdGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/ProfilerClientIdGet`);
  }

  profilerClientBenefitsSet(profilerId, data, profilerInput = '') {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerClientBenefitsSet`,
      data,
      { profilerId, profilerInput }
    );
  }

  profilerHealthCoverGet(data, profilerId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerHealthCoverGet`,
      data,
      { profilerId }
    );
  }

  profilerIncomeProtectionGet(data, profilerId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerIncomeProtectionGet`,
      data,
      { profilerId }
    );
  }

  profilerMortgageRepaymentGet(data, profilerId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerMortgageRepaymentGet`,
      data,
      { profilerId }
    );
  }

  profilerTraumaGet(data, profilerId) {
    return this.httpClient.post(`${this.apiBaseUrl}/ProfilerTraumaGet`, data, {
      profilerId,
    });
  }

  profilerTPDProfilerGet(data, profilerId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerTPDProfilerGet`,
      data,
      { profilerId }
    );
  }

  profilerLifeCoverGet(data, profilerId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerLifeCoverGet`,
      data,
      { profilerId }
    );
  }

  profilerBallParkPremiumGet(data, profilerId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerBallParkPremiumGet`,
      data,
      { profilerId }
    );
  }

  profilerIPCoverTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/ProfilerIPCoverTypeGet`);
  }

  insuranceProfileSettingsGet() {
    // TODO: move to generalService
    return this.httpClient.get(`InsuranceProfileSettings`);
  }

  insuranceProfileSettingsSet(data) {
    // TODO: move to generalService
    return this.httpClient.post(`InsuranceProfileSettings`, data);
  }

  getAllocatedAdviser(familyId) {
    return this.httpClient
      .get(`GetBrokerAll`, { familyId })
      .then(({ data }) => {
        return (data && data.map(allocatedAdviserBuilderForUI)) || [];
      });
  }

  insuranceProfileSettingsUpdate(data, id) {
    // TODO: move to generalService
    return this.httpClient.put(`InsuranceProfileSettings/${id}`, data);
  }

  profilerInsuranceProfilePdfRpt(profilerId, familyId = 0) {
    return this.httpClient.get(`${this.profilerBaseUrl}/${profilerId}/report`, {
      familyId,
    });
  }

  profilerOccupationTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/ProfilerOccupationTypeGet`);
  }

  profilerAssignToClient(familyId, profilerId) {
    return this.httpClient.post(`${this.apiBaseUrl}/ProfilerAssignToClient`, {
      familyId,
      profilerId,
    });
  }

  profilerInsuranceProfilerDetailsGet(profilerId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ProfilerInsuranceProfilerDetailsGet`,
      { profilerId }
    );
  }

  profilerInsuranceProfilerFamilyMatch(familyId, profilerId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ProfilerInsuranceProfilerFamilyMatch`,
      { familyId, profilerId }
    );
  }
  lookup() {
    return this.httpClient.get(`${this.apiBaseUrl}/Lookup`);
  }
  profilerInsuranceProfilerFamilyGet(family) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ProfilerInsuranceProfilerFamilyGet`,
      family
    );
  }
  profilerInsuranceProfilerNotesSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProfilerInsuranceProfilerNotesSet`,
      data,
      {}
    );
  }

  profilerNoteTemplatesGet(benefitTypeID = 0) {
    return this.httpClient
      .get(`${this.profilerBaseUrl}/template/${benefitTypeID}/notes`)
      .then(response => {
        const { data } = response;
        return (
          data &&
          data.map(benefitTemplates => {
            const { ProfilerNotes: profilerNotes } = benefitTemplates;
            const mappedBenefitTemplates = benefitProfilerNoteTemplatesBuilderForUI(
              benefitTemplates
            );
            mappedBenefitTemplates.templates = profilerNotes.map(template =>
              profilerNoteTemplateBuilderForUI(template)
            );
            return mappedBenefitTemplates;
          })
        );
      });
  }

  profilerNoteTemplateSet(data) {
    return this.httpClient
      .post(
        `${this.profilerBaseUrl}/notes`,
        profilerNoteTemplateBuilderForMyCRM(data)
      )
      .then(response => profilerNoteTemplateBuilderForUI(response.data));
  }

  profilerSendEmailReport(data) {
    const email = profilerSendReportMailBuilderForMyCRM(data);
    email.Recipients =
      data.recipients && data.recipients.map(recipientBuilderForMyCRM);
    return this.httpClient.post(`${this.profilerBaseUrl}/emails`, email);
  }

  profilerNoteTemplateUpdate(data, templateId) {
    return this.httpClient
      .put(
        `${this.profilerBaseUrl}/notes/${templateId}`,
        profilerNoteTemplateBuilderForMyCRM(data)
      )
      .then(response => profilerNoteTemplateBuilderForUI(response.data));
  }

  getInsuranceSummaryCover(profilerId) {
    return this.httpClient
      .get(`${this.profilerBaseUrl}/${profilerId}/summary`)
      .then(({ data }) => {
        return (data && summaryOfCoverBuilderForUI(data)) || [];
      });
  }

  getFrequencyTypes() {
    const defer = this.$q.defer();
    defer.resolve([
      {
        Name: 'Y',
        id: 1,
      },
      {
        Name: 'M',
        id: 12,
      },
      {
        Name: 'W',
        id: 52,
      },
      {
        Name: 'F',
        id: 26,
      },
    ]);
    return defer.promise;
  }

  profilerNoteUpdateOrderBy(data) {
    return this.httpClient
      .put(`${this.profilerBaseUrl}/template/sorting`, data)
      .then(response => response.data);
  }

  profilerNoteTemplateDelete(templateId) {
    return this.httpClient.delete(
      `${this.profilerBaseUrl}/notes/${templateId}`
    );
  }
}

export default InsuranceProfilerService;
