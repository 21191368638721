import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import {
  yearlySettlementBuilderForUI,
  enquirySourceBuilderForUI,
} from 'Common/mappers/dashboardWidget';

class DashboardService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'dashboard';
  }

  getCategory() {
    return this.httpClient.get(`${this.apiBaseUrl}/GetCategory`);
  }

  getDashboardItem() {
    return this.httpClient.get(`${this.apiBaseUrl}/GetDashboardItem`);
  }

  getAllItemBycategory(Category) {
    return this.httpClient.get(`${this.apiBaseUrl}/GetItemAll`, { Category });
  }

  saveDashboardItem(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/SaveDashboardItem`, data);
  }

  deleteDashboardItem(ActualId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/DeleteDashboardItem`, {
      ActualId,
    });
  }

  getTaggedcases() {
    // TODO: move to generalService
    return this.httpClient.get('Taggedcases');
  }

  getRecentlyViewed() {
    // TODO: move to generalService
    return this.httpClient.get('RecentlyViewed', {}, true);
  }

  getBrokerBasicInfo() {
    // TODO: move to generalService
    return this.httpClient.get('GetBrokerBasicInfo', {}, true, false);
  }

  getGenralSearchResult(searchCriteria) {
    // TODO: move to generalService
    return this.httpClient.get(`GeneralSearch`, { searchCriteria });
  }

  addressDetailsGet(addressId) {
    return this.httpClient.get(`AddressDetailsGet`, { addressId });
  }

  addressDetailsSet(data) {
    // TODO: move to generalService
    return this.httpClient.post('AddressDetailsSet', data);
  }

  OFXConverter(fromCode, toCode, amount = 0) {
    return this.httpClient.get(`OFXConverter`, { fromCode, toCode, amount });
  }

  GetAdvisersList(pFamilyId = 0, pSearchSameAdviserOrg) {
    // TODO: move to generalService
    return this.httpClient.get(`GetAdvisersList`, {
      pFamilyId,
      pSearchSameAdviserOrg,
    });
  }

  generalLoanSearchGet(
    clientName,
    adviserName,
    lenderName,
    loanReferenceNo,
    loanId
  ) {
    // TODO: move to generalService
    return this.httpClient.get(`GeneralLoanSearchGet`, {
      clientName,
      adviserName,
      lenderName,
      loanReferenceNo,
      loanId,
    });
  }

  getWidgetOptions(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lender-splits/widget-options`, params)
      .then(({ data }) => data && data.map(collectionCamelizeBuilderForUI));
  }

  getLenderSplits(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lender-splits`, params)
      .then(({ data }) => data && data.map(collectionCamelizeBuilderForUI));
  }

  getAverageLoanSize(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/average-loan-size`, params)
      .then(
        ({ data }) => (data && data.map(collectionCamelizeBuilderForUI)) || []
      );
  }

  getSettlementVolume(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/settlement-volume`, params)
      .then(
        ({ data }) => (data && data.map(yearlySettlementBuilderForUI)) || []
      );
  }

  getEnquirySource(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/enquiry-source`, params)
      .then(({ data }) => (data && data.map(enquirySourceBuilderForUI)) || []);
  }
}

export default DashboardService;
