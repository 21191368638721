import { PIPELINE_CATEGORIES } from 'Common/constants/pipelineCategories';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { displaySuccess, displayError } from 'Common/utilities/alert';

export function getInvolvedPartyBasicModel(isClient) {
  return {
    ClientEntityID: 0,
    FamilyID: 0,
    DisplayName: '',
    IsClient: isClient,
    AddRelatedChildren: true,
  };
}

export function getLoanOpportunityBasicModel() {
  return {
    AllocatedAdviserID: 0,
    Title: '',
    Borrowers: [],
    Guarantors: [],
  };
}

export function goToOpportunity($state, queryParams) {
  $state.go('app.opportunity', queryParams);
}

export function getOpportunityStatus(pipelineService) {
  return pipelineService.SettingsStatusGet(false, false).then(res => {
    const { data } = res;
    if (!res || !data) return;

    const statusList =
      data.filter(
        obj => obj.PipelineCategoryID === PIPELINE_CATEGORIES.OPPORTUNITY
      ) || [];
    if (statusList.length) {
      statusList.push(
        {
          PipelineStatus: 'Convert to Application',
          PipelineStatusID: PIPELINE_STATUS.APPLICATION,
          IsConversionStatus: true,
        },
        {
          PipelineStatus: 'Not Proceeded',
          PipelineStatusID: PIPELINE_STATUS.NOT_PROCEEDED,
          IsConversionStatus: true,
        }
      );
    }
    return statusList;
  });
}

export function showSwalAfterSending(isSuccessful, type) {
  if (isSuccessful) {
    displaySuccess(`${type} sent successfully`);
  } else {
    displayError(
      `There is a problem sending the ${type} right now. Try again later`
    );
  }
}

export function getRecipientDetails(party) {
  if (!party) return [];
  return party.reduce((accum, current) => {
    const {
      FamilyFullName,
      FirstName,
      LastName,
      OrganisationId,
      PersonId,
      Email,
      Phone,
      FamilyId: familyId,
    } = current;
    const name = OrganisationId
      ? `${FamilyFullName}`
      : `${FirstName} ${LastName}`;
    const findEmail =
      Email && Email.length && Email.find(o => o.Type === 'Email');
    const findMobileNumber =
      Phone && Phone.length && Phone.find(o => o.Type === 'Mobile');

    const emailObject = {
      id: OrganisationId || PersonId,
      name,
      familyId,
      emailAddress: (findEmail && findEmail.EmailAddress) || '',
      phoneNumber: findMobileNumber && findMobileNumber.Number,
      nameWithMobile: `${name} (${findMobileNumber &&
        findMobileNumber.Number})`,
    };
    return accum.concat(emailObject);
  }, []);
}
