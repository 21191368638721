import _ from 'lodash';
import { toastSaveSuccess } from 'Common/utilities/alert';
import { postCompletionBuilderForMyCRM } from 'Common/mappers/customerCare';
import { FIRE_GENERAL_ID } from 'Common/constants/customerCareModules';

export default class LoadingExclusionsCtrl {
  constructor(NgTableParams, customerCareInsuranceService, currentUserService) {
    'ngInject';

    this.NgTableParams = NgTableParams;
    this.userInfo = currentUserService;
    this.toastSaveSuccess = toastSaveSuccess;
    this.customerCareInsuranceService = customerCareInsuranceService;

    this.FIRE_GENERAL_ID = FIRE_GENERAL_ID;
  }

  $onInit() {
    this.isLoading = false;
    this.isFromCompleted = false;
    this.totalRecords = 0;
    this.currentPage = 1;
    this.tableData = [];

    this.defaultTableSettings = {
      page: 1,
      count: 10,
      sorting: {
        ReviewDate: 'asc',
      },
    };

    this.loadTableData();
  }

  $onChanges(changes) {
    if (changes.filterUpdateCount) {
      this.currentPage = 1;
      if (this.defaultTableSettings) this.defaultTableSettings.page = 1;
      this.isFromCompleted = false;
      if (this.tableParams) this.tableParams.reload();
    }
  }

  onActionSelected($event) {
    this.onSendModal({ $event });
  }

  gotoContact(familyId) {
    this.viewContact({ $event: { familyId } });
  }

  setCompletion(item) {
    const postData = [postCompletionBuilderForMyCRM(item, this.tab.type)];
    this.customerCareInsuranceService.postCompletion(postData).then(() => {
      this.toastSaveSuccess();
      this.isFromCompleted = true;
      this.tableParams.reload();
    });
  }

  onPageChange(event) {
    this.defaultTableSettings.page = event.$e.pageNumber;
    this.currentPage = event.$e.pageNumber;
    this.isFromCompleted = false;
    this.tableParams.reload();
  }

  loadTableData() {
    const ctrl = this;
    this.tableParams = new this.NgTableParams(ctrl.defaultTableSettings, {
      counts: [],
      total: 0,
      getData($defer, params) {
        const sortBy = params.orderBy()[0].substring(1);
        const sortOrder = _.values(params.sorting())[0].toUpperCase();
        if (!ctrl.isFromCompleted) ctrl.isLoading = true;

        const newFilter = {
          ...ctrl.filterData,
          sortBy,
          sortOrder,
          pageNumber: ctrl.currentPage,
        };
        ctrl.customerCareInsuranceService
          .getDeferralList(newFilter)
          .then(response => {
            ctrl.isLoading = false;
            ctrl.tableData = response;
            ctrl.tableData.map(item => {
              item.isComplete = newFilter.isCompleted;
              return item;
            });
            ctrl.totalRecords =
              ctrl.tableData.length && ctrl.tableData[0].totalCount;
            ctrl.onTableDataLoaded({ $event: { tableData: ctrl.tableData } });

            if (ctrl.totalRecords) params.total(ctrl.totalRecords);
            else params.total(0);
            $defer.resolve(ctrl.tableData);
          });
      },
    });
  }
}
