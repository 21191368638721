import { getApiResourceBasedOnLocation } from 'Common/utilities/windowLocation';

const configData = {};

export function setConfigData(data) {
  Object.assign(configData, data);
}

export function getConfigData() {
  return { ...configData };
}

export function getHashConfig() {
  // webpack define global variable
  return process.env.COMMIT_HASH || ''; // eslint-disable-line
}

export function getConfigFromEnv() {
  return {
    resource: getApiResourceBasedOnLocation(),
    login: process.env.LOGIN || `${window.location.origin}/login/login.html`, // eslint-disable-line
    googleApiKey: process.env.GOOGLE_API_KEY || '',
    userSnapKey: process.env.USER_SNAP_KEY || '',
    userSnapKeyCorporate: process.env.USER_SNAP_KEY_CORPORATE || '',
    sentry_dsn: process.env.SENTRY_DSN || '',
    sentry_env: process.env.SENTRY_ENV || 'test',
    gaAccount: process.env.GA_ACCOUNT || '',
    debug: process.env.DEBUG || false,
  };
}
