import template from './gamePlanWidget.html';
import controller from './gamePlanWidgetCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanScenarioId: '<',
    clientHeaderName: '<',
  },
};
