import angular from 'angular';
import {
  DEFAULT_VALUES,
  ASSET_TYPE,
  STEPS,
  LENDING_CATEGORY,
} from 'Common/constants/securityValues';
import { AU_STATE_FOR_ASSET } from 'Common/constants/australianState';
import { BORROWER_PARTY_TYPES } from 'Common/constants/partyTypes';
import { assetFinanceIgnore } from 'Common/utilities/security';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { getFullName } from 'Common/utilities/contact';
import { toastError, showActionConfirmation } from 'Common/utilities/alert';
import { assetSupplierContactBuilderForUI } from 'Common/mappers/loanScenario';
import { CLIENT_TYPE_DESC } from 'Common/constants/contactType';

export default class AssetFinanceModalCtrl {
  constructor(
    $timeout,
    toaster,
    optionsService,
    iFinanceAppService,
    generalService,
    loanApplicationServices,
    dashboardService,
    loanAppSharedData,
    loanScenarioService,
    utilitiesService,
    colorService,
    financialsSharedService,
    configService,
    currentUserService,
    uiService,
    loanScenarioModelService,
    contactService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.toaster = toaster;
    this.optionsService = optionsService;
    this.iFinanceAppService = iFinanceAppService;
    this.generalService = generalService;
    this.loanApplicationServices = loanApplicationServices;
    this.dashboardService = dashboardService;
    this.loanAppSharedData = loanAppSharedData;
    this.loanScenarioService = loanScenarioService;
    this.utilitiesService = utilitiesService;
    this.colorService = colorService;
    this.financialsSharedService = financialsSharedService;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.uiService = uiService;
    this.ASSET_TYPE = ASSET_TYPE;
    this.STEPS = STEPS;
    this.australianStates = AU_STATE_FOR_ASSET;
    this.loanScenarioModelService = loanScenarioModelService;
    this.contactService = contactService;
    this.getFullName = getFullName;
  }

  $onInit() {
    this.isLoading = false;
    this.isShowSucess = false;
    this.isSubmitDisabled = false;
    this.conditionMaxLength = 2;
    this.assetStepNumber = 0;
    this.iFinanceParams = {};
    this.showOtherDetails = false;
    this.isDetailsFetching = false;
    this.isManualInput = false;
    this.vehicleModelList = [];
    this.vehicleClassList = [];
    this.selectedAddress = '';
    this.monthFirstSearch = true;
    this.assetSupplier = {};
    this.showAssetSupplierFields = false;
    this.isPersonalLoan =
      this.lendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN;

    this.getApplicants();
    this.getEmptySecurityInfo();
    this.getSecurityOptions();

    this.setDefaultValues();
    this.getYearList();
    this.assetFinanceIgnore = assetFinanceIgnore;

    this.ownersButtonLabels = [{ label: 'PreferedName' }];
    this.ownersItemLabels = [
      { label: 'PreferedName', isEdit: false, isText: true },
    ];

    this.ownersDisableEdit = true;

    if (
      this.configService.feature &&
      this.configService.feature.securityOwnersPercentage
    ) {
      this.ownersButtonLabels.push({ label: 'Percentage', isPercentage: true });
      this.ownersItemLabels.push({
        label: 'Percentage',
        isEdit: true,
        containerClass: 'input-type-percentage',
        inputClass: 'text-input',
        isPercentage: true,
        inputWidth: '60px',
      });
      this.ownersDisableEdit = false;
    }
    this.updateConditions();
    if (this.loanSecurityInfoId) this.getSecurityInfo();
  }

  getYearList() {
    this.iFinanceAppService.getYearManualList().then(response => {
      if (!response) return;
      this.yearManualList = response;
    });
  }

  updateConditions() {
    this.forVehicleOnly =
      this.asset.FinanceAssetTypeId === this.ASSET_TYPE.MOTOR_VEHICLE;
    this.showSteps =
      !this.isManualInput &&
      this.currentUserService.isAU &&
      this.forVehicleOnly;
    this.isVehicleAuto =
      !this.showOtherDetails &&
      this.currentUserService.isAU &&
      !this.isManualInput;

    this.isNotOthersTertiary =
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.TERTIARY_ASSETS &&
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.OTHER;
    this.showMakeManual = this.isNotOthersTertiary;
    this.showModelManual = this.isNotOthersTertiary;
    this.showMake = this.isNotOthersTertiary && this.assetStepNumber > 0;
    this.showModel =
      this.isNotOthersTertiary && this.assetStepNumber > this.STEPS.THIRD;
    this.showFamily =
      this.forVehicleOnly && this.assetStepNumber > this.STEPS.FIRST;
    this.showVehiclePreview = this.showOtherDetails && this.forVehicleOnly;
    this.showManualInputs =
      this.isManualInput ||
      !this.currentUserService.isAU ||
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.MOTOR_VEHICLE;
    this.showOtherVehicleInput =
      this.showOtherDetails ||
      !this.currentUserService.isAU ||
      this.isManualInput ||
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.MOTOR_VEHICLE;
    this.showOdometer =
      this.forVehicleOnly ||
      this.asset.FinanceAssetTypeId === this.ASSET_TYPE.EQUIPMENT;
    this.showSaveBtn =
      !this.showOtherDetails &&
      !this.isManualInput &&
      this.asset.VehicleDetails.Model &&
      this.assetStepNumber === this.STEPS.FIFTH;
    this.showManualLink =
      !this.isManualInput && !this.showOtherDetails && this.forVehicleOnly;
  }

  enterManually(bool = true) {
    this.isManualInput = bool;
    if (!bool) this.resetVehicleValues();
    this.updateConditions();
  }

  assetTypeChanged(financeAssetTypeId) {
    this.conditionMaxLength = 2;
    if (financeAssetTypeId === this.ASSET_TYPE.MOTOR_VEHICLE)
      this.conditionMaxLength = 3;
    this.updateConditions();
  }

  stepChanged(value, stepNumber) {
    this.assetStepNumber =
      value || this.loanSecurityInfoId ? stepNumber : stepNumber - 1;
    this.updateConditions();
  }

  resetVehicleValues() {
    this.asset.VehicleDetails.VehicleClassID = 0;
    this.asset.VehicleDetails.Make = '';
    this.asset.VehicleDetails.Family = '';
    this.asset.VehicleDetails.Month = '';
    this.asset.VehicleDetails.Year = '';
    this.asset.VehicleDetails.RedBookCode = '';
    this.asset.VehicleDetails.Model = '';
    this.assetStepNumber = 0;
  }

  previewVehicle(preview = true) {
    this.showOtherDetails = preview;
    this.updateConditions();
  }

  findVehicle(input, stepNumber, collectionName, dateType = 'month') {
    const vehicleType = input === 1 ? 4 : 3;
    const { VehicleClassID, Make } = this.asset.VehicleDetails;
    this.iFinanceParams = {
      vehicleType: VehicleClassID,
    };

    switch (stepNumber) {
      case STEPS.SECOND:
        this.iFinanceParams.make = input;
        break;
      case STEPS.THIRD:
        this.iFinanceParams.make = Make;
        this.iFinanceParams.family = input;
        this.monthFirstSearch = true;
        break;
      case STEPS.FORTH:
        this.setIFinanceParamsByYear();
        this.iFinanceParams[dateType] = input;
        break;
      default:
        this.iFinanceParams = { vehicleType };
        break;
    }
    this.isDetailsFetching = true;
    this.iFinanceAppService
      .vehicleFinder(this.iFinanceParams)
      .then(res => {
        if (!res || !res.data || !res.data.data) {
          if (
            !this.monthFirstSearch ||
            (stepNumber !== STEPS.FORTH && dateType === 'month')
          )
            this.toaster.pop(
              'info',
              'No result found.',
              'Enter manually instead'
            );
          if (stepNumber === STEPS.FORTH && dateType === 'month')
            this.monthFirstSearch = false;

          this[collectionName] = [];
          this.stepChanged('', stepNumber);
          return;
        }
        if (stepNumber === STEPS.THIRD) {
          this.yearList = res.data.YearList;
          this.monthList = res.data.MonthList;
        } else {
          this[collectionName] = res.data.data;
        }
        this.stepChanged(input, stepNumber);
      })
      .finally(() => {
        this.isDetailsFetching = false;
      });
  }

  setIFinanceParamsByYear() {
    const { Make, Family, Month, Year } = this.asset.VehicleDetails;
    this.iFinanceParams.make = Make;
    this.iFinanceParams.family = Family;
    this.iFinanceParams.month = Month;
    this.iFinanceParams.year = Year;
  }

  changeCondition() {
    this.vehicleConditionList.filter(data => {
      if (data.isChecked) {
        this.asset.VehicleDetails.VehicleConditionID = data.value;
      }
      return data;
    });
  }

  modelChanged(rbc) {
    const { VehicleClassID } = this.asset.VehicleDetails;
    this.vehicleModelList.filter(data => {
      if (data.rbc === rbc) {
        this.asset.VehicleDetails.Model = data.description;
      }
      return data;
    });

    this.vehicleClassList.filter(data => {
      if (data.value === VehicleClassID) {
        this.SelectedVehicleClass = data.name;
      }
      return data;
    });

    this.stepChanged(rbc, STEPS.FIFTH);
  }

  getSecurityOptions() {
    this.optionsService.getAssetFinanceSecurityType().then(response => {
      if (!response || !response.length) return;
      this.assetTypeList = [{ name: 'Please select', value: 0 }, ...response];
    });

    this.optionsService.getAssetFinanceSaleType().then(response => {
      if (!response || !response.length) return;
      this.saleTypeList = [{ name: 'Please select', value: 0 }, ...response];
    });

    this.optionsService.getAssetFinanceVehicleCondition().then(response => {
      if (!response || !response.length) return;
      this.vehicleConditionList = response;
    });

    this.optionsService.getAssetFinanceCategory().then(response => {
      if (!response || !response.length) return;
      this.financeCategoryList = [
        { name: 'Please select', value: 0 },
        ...response,
      ];
    });

    this.optionsService.getAssetFinanceVehicleClass().then(response => {
      if (!response || !response.length) return;
      this.vehicleClassList = [
        { name: 'Please select', value: 0 },
        ...response,
      ];
    });

    const LENDER_PLACE = 1;
    this.optionsService.getLenderList(LENDER_PLACE, true).then(response => {
      if (!response || !response.length) return;
      this.FinanceLenderList = [
        { providerName: 'Please select', providerId: 0 },
        ...response,
      ];
    });

    this.optionsService.getAssetValueEstimatedBasis(true).then(response => {
      if (!response || !response.length) return;
      this.valuationBasisList = [
        { name: 'Please select', value: 0 },
        ...response,
      ];
    });
  }

  getApplicants() {
    this.loanScenarioService
      .scenarioApplicantListGet(this.loanAppId)
      .then(response => {
        if (!response || !response.data) return;
        const { InvolvedPartyPerson, InvolvedPartyEntity } = response.data;

        this.scenarioApplicantList = [];
        let countApplicants = 0;
        countApplicants += Object.keys(InvolvedPartyEntity).length;
        Object.keys(InvolvedPartyPerson).forEach(familyId => {
          countApplicants += InvolvedPartyPerson[familyId].length;
        });

        const percentage = (100 / countApplicants).toFixed(2);

        angular.forEach(InvolvedPartyPerson, (family, familyId) => {
          angular.forEach(family, client => {
            const data = {
              Percentage: percentage,
              BorrowerID: parseInt(client.PersonId, 10),
              familyId,
              ClientID: parseInt(client.PersonId, 10),
              FirstName: client.FirstName,
              ClientType: client.Role,
              LastName: client.LastName,
              PreferedName: `${client.FirstName} ${client.LastName}`,
              IsInclude: true,
              IsTicked: true,
              OwnershipPercentage: percentage,
              ticked: true,
              IsEntity: false,
              IsClient: true,
            };
            data.initials = this.utilitiesService.filterInitialOneString(
              client.FirstName
            );
            this.scenarioApplicantList.push(data);
          });
        });
        angular.forEach(InvolvedPartyEntity, client => {
          const data = {
            Percentage: percentage,
            familyId: client.FamilyId,
            BorrowerID: parseInt(client.OrganisationId, 10),
            EntityID: parseInt(client.OrganisationId, 10),
            ClientType: client.EntityTypeName,
            PreferedName: client.FamilyFullName,
            IsInclude: true,
            IsEntity: true,
            IsTicked: true,
            OwnershipPercentage: percentage,
            ticked: true,
          };
          data.initials = this.utilitiesService.filterInitialOneString(
            client.FirstName
          );
          data.background = this.colorService.getRandomColor();
          this.scenarioApplicantList.push(data);
        });

        let isOneFamily = false;
        if (this.scenarioApplicantList.length === 1) {
          isOneFamily = true;
        } else if (this.scenarioApplicantList.length === 2) {
          isOneFamily =
            this.scenarioApplicantList[0].familyId ===
            this.scenarioApplicantList[1].familyId;
        }

        this.ownerGroupProperty = isOneFamily ? '' : 'familyId';
      });
  }

  getEmptySecurityInfo() {
    this.asset = {
      LoanApplicationId: this.loanAppId,
      SecurityInfoId: '0',
      IsPrimary: true,
      SecurityAssetTypeID: '',
      TransactionId: '',
      Address: [],
      IsRefinance: false,
      PropertyDetails: {
        EstimatedPrice: '',
        EstimatedBasesId: '',
        LoanSecurityId: 0,
        StatusId: '',
        PropertyPrimaryPurposeId: '1',
        HoldingId: '',
        ZoningId: '',
        PropertyTypeId: '',
        CurrentValue: '',
        LandValue: '',
        ContratctPrice: '',
        ContractDate: '',
        LicensedRealEstateAgentContract: true,
        IsPreApproved: true,
        AddressId: 0,
        TitleTypeId: '1',
        TitleId: '',
        PropertyState: '',
        Suburb: '',
        IdentifierList: [
          {
            Security_TitleIdentityId: 0,
            IdentityTypeId: '',
            Value: '',
          },
        ],
        ContactForAccess: {
          ContactForAccess: 0,
          CompanyName: '',
          ContactTitle: '',
          ContactFirstName: '',
          ContactLastName: '',
          PhoneAreaCode: '',
          PhoneNumber: '',
          FaxAreaCode: '',
          FaxNumber: '',
          ContactTypeId: '',
        },
      },
      Ownership: [],
      MortgageList: [],
      VehicleDetails: {},
      FinanceAssetTypeId: 0,
      SaleTypeId: 0,
      FinanceCategoryId: 0,
      FinanceLenderId: 0,
      FinanceAmount: 0,
      IsAssetFinance: true,
    };
  }

  setDefaultValues() {
    this.asset.PropertyDetails.StatusId = DEFAULT_VALUES.ESTABLISHED_STATUSID;
    this.asset.PropertyDetails.PropertyPrimaryPurposeId =
      DEFAULT_VALUES.OWNEROCCUPIED_PRIMARYPURPOSE;
    this.asset.PropertyDetails.HoldingId =
      this.loanAppSharedData.applicantsLength(
        this.loanAppSharedData.listApplicants
      ) === 1
        ? DEFAULT_VALUES.SOLE_HOLDINGID
        : DEFAULT_VALUES.JOINT_HOLDINGID;
    this.asset.PropertyDetails.ZoningId = DEFAULT_VALUES.RESIDENTIAL_ZONINGID;
  }

  saveAddress() {
    this.dashboardService.addressDetailsSet(this.asset.Address).then(res => {
      this.asset.PropertyDetails.AddressId = res.data || 0;
      this.selectSaveStep();
    });
  }

  formatAddress() {
    if (!isValidObjectValue(() => this.asset.PropertyDetails.AddressId)) return;
    this.dashboardService
      .addressDetailsGet(this.asset.PropertyDetails.AddressId)
      .then(res => {
        if (!res || !res.data) return;
        this.asset.Address = res.data;
        this.selectedAddress = res.data.formatted_address;
      });
  }

  formatSupplierAddress(supplierAddressId) {
    if (!isValidObjectValue(() => supplierAddressId)) return;
    this.dashboardService.addressDetailsGet(supplierAddressId).then(res => {
      if (!res || !res.data) return;
      this.assetSupplier.address = res.data;
      this.assetSupplier.selectedAddress = res.data.formatted_address;
    });
  }

  preselectOwners() {
    const collectionBorrowerID = [];

    this.asset.Ownership.forEach(obj => {
      collectionBorrowerID.push(obj.BorrowerID);
    });

    this.scenarioApplicantList = this.scenarioApplicantList.map(obj => {
      if (collectionBorrowerID.indexOf(obj.BorrowerID) !== -1) {
        obj.IsTicked = true;
        obj.ticked = true;
        const findOwner = this.asset.Ownership.find(
          owner => owner.BorrowerID === obj.BorrowerID
        );
        obj.Percentage = findOwner ? findOwner.Percentage : obj.Percentage;
      } else {
        obj.Percentage = 0;
        obj.IsTicked = false;
        obj.ticked = false;
      }
      return obj;
    });
  }

  getSecurityInfo() {
    this.loanApplicationServices
      .getLoanSecurityInfo(this.loanAppId, this.loanSecurityInfoId)
      .then(response => {
        if (!response || !response.data) return;
        this.asset = response.data;
        this.enterManually();
        this.preselectOwners();
        this.assetTypeChanged(this.asset.FinanceAssetTypeId);
        this.formatAddress();

        if (this.asset.AssetSupplierId) {
          this.showAssetSupplier(
            this.asset.AssetSupplierId,
            this.asset.IsContactSupplier
          );
        }
      })
      .catch(() => {
        this.toaster.pop('error', 'Problem retrieving data');
      });
  }

  showAssetSupplier(supplierId, isContactSupplier) {
    if (isContactSupplier) {
      this.getContactDetails(supplierId);
    } else {
      this.getAssetSupplier(supplierId);
    }
  }

  getAssetSupplier(supplierId) {
    this.loanScenarioModelService
      .getAssetSupplier(this.loanAppId, supplierId)
      .then(response => {
        this.assetSupplier = response;
        this.formatSupplierAddress(this.assetSupplier.addressId);
        this.showAssetSupplierFields = true;
        this.asset.IsContactSupplier = true;
        this.disableAssetSupplierfields = true;
      })
      .catch(() => {
        toastError('Problem retrieving data');
      });
  }

  searchParty(searchString) {
    const SEARCH_MIN_LEN = 3;
    this.showSearchResults = true;
    this.searchResult = null;
    this.$timeout.cancel(this.searchTimeout);

    if (searchString.length >= SEARCH_MIN_LEN) {
      this.searchTimeout = this.$timeout(() => {
        this.loanScenarioService
          .scenarioSearchClientGet(
            searchString,
            BORROWER_PARTY_TYPES.PERSON,
            this.loanAppId
          )
          .then(({ data }) => {
            if (!data) return;
            this.searchResult = data || [];
          })
          .catch(() => {
            this.showSearchResults = false;
            this.searchResult = [];
          });
      }, 300);
    }
  }

  selectParty(contact) {
    const {
      ClientEntityId: clientEntityId,
      ClientTypeDesc: clientTypeDesc,
    } = contact;
    const isBorrower = clientTypeDesc === CLIENT_TYPE_DESC.BORROWER;
    const isAssetSupplier = clientTypeDesc === CLIENT_TYPE_DESC.ASSET_SUPPLIER;
    if (this.hasAddedAssetSupplier()) {
      showActionConfirmation(
        'This will overwrite current Asset Supplier',
        'Are you sure you want to proceed?',
        confirm => {
          if (!confirm) return;
          this.clearSearch();
          isBorrower && this.getContactDetails(clientEntityId);
          isAssetSupplier && this.getAssetSupplier(clientEntityId);
        }
      );
    } else {
      this.clearSearch();
      isBorrower && this.getContactDetails(clientEntityId);
      isAssetSupplier && this.getAssetSupplier(clientEntityId);
    }
  }

  getContactDetails(clientId) {
    if (!clientId) return;

    this.contactService.clientInformGet(1, clientId, true).then(({ data }) => {
      if (!data || !data.length) return;
      this.assetSupplier = assetSupplierContactBuilderForUI(data[0]);
      this.assetSupplier.assetSupplierId = clientId;
      this.formatSupplierAddress(this.assetSupplier.addressId);
      this.asset.IsContactSupplier = true;
      this.showAssetSupplierFields = true;
      this.disableAssetSupplierfields = true;
    });
  }

  clearSearch() {
    this.searchInput = '';
    this.searchResult = [];
    this.showSearchResults = false;
    this.showSearchPanel = false;
  }

  addSupplierManually() {
    if (this.hasAddedAssetSupplier()) {
      showActionConfirmation(
        'This will overwrite current Asset Supplier',
        'Are you sure you want to proceed?',
        confirm => {
          if (!confirm) return;
          this.resetSupplierManualFields();
        }
      );
    } else {
      this.resetSupplierManualFields();
    }
  }

  resetSupplierManualFields() {
    this.assetSupplier = {};
    this.showAssetSupplierFields = true;
    this.asset.IsContactSupplier = false;
    this.disableAssetSupplierfields = false;
  }

  hasAddedAssetSupplier() {
    return this.assetSupplier.assetSupplierId;
  }

  saveAssetSupplier() {
    const assetSupplierId =
      this.assetSupplier && this.assetSupplier.assetSupplierId;

    if (!assetSupplierId) {
      this.loanScenarioModelService
        .postAssetSuplier(this.loanAppId, this.assetSupplier)
        .then(response => {
          if (!response || !response.data) return;
          this.proceedAddSecurity(response.data);
        })
        .catch(() => {
          this.toaster.pop('error', 'Problem saving supplier');
        });
      return;
    }

    this.loanScenarioModelService
      .updateAssetSuplier(this.loanAppId, this.assetSupplier)
      .then(response => {
        if (!response || !response.data) return;
        this.proceedAddSecurity(assetSupplierId);
      })
      .catch(() => {
        this.toaster.pop('error', 'Problem saving supplier');
      });
  }

  proceedAddSecurity(assetSupplierId) {
    const { Address, ...securityInfo } = this.asset;
    const newSecurityInfo = {
      ...securityInfo,
      LoanApplicationId: this.loanAppId,
      AssetSupplierId: assetSupplierId,
    };

    this.loanApplicationServices
      .setLoanSecurityInfo(newSecurityInfo)
      .then(() => {
        this.financialsSharedService.LiabilityInfoGet();
        this.financialsSharedService.IncomeInfoGet();
        this.loanAppSharedData.REFRESH_FUNDS_AVAILABLE_SECTION = {
          isNew: true,
        };
        this.loanAppSharedData.refreshLoanDetailsSet = 1;
        this.modalInstance.close();
      })
      .catch(() => {
        this.toaster.pop('error', 'Something went wrong. Please try again.');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  updateSupplierAddress() {
    const supplierAddressChanged =
      this.assetSupplier.address &&
      this.assetSupplier.selectedAddress !==
        this.assetSupplier.address.formatted_address;

    if (supplierAddressChanged) {
      this.dashboardService
        .addressDetailsSet(this.assetSupplier.address)
        .then(response => {
          this.assetSupplier.addressId = response && response.data;
          this.saveAssetSupplier();
        });
      return;
    }
    this.saveAssetSupplier();
  }

  selectSaveStep() {
    if (!this.asset.IsContactSupplier) {
      this.updateSupplierAddress();
    } else {
      this.proceedAddSecurity(this.assetSupplier.assetSupplierId);
    }
  }

  isAddressChanged() {
    return (
      isValidObjectValue(() => this.asset.Address.formatted_address) &&
      this.selectedAddress !== this.asset.Address.formatted_address
    );
  }

  isInvalidAssetSupplier() {
    return (
      !this.isPersonalLoan &&
      (!this.assetSupplier ||
        !this.assetSupplier.firstname ||
        !this.assetSupplier.lastname ||
        !this.assetSupplier.address)
    );
  }

  isInvalidVehicleClass() {
    const { FinanceAssetTypeId, VehicleDetails } = this.asset;
    const { MOTOR_VEHICLE } = this.ASSET_TYPE;
    return (
      MOTOR_VEHICLE === FinanceAssetTypeId && !VehicleDetails.VehicleClassID
    );
  }

  isInvalidVehicleMake() {
    const { FinanceAssetTypeId, VehicleDetails } = this.asset;
    const { MOTOR_VEHICLE, RECREATIONAL, EQUIPMENT } = this.ASSET_TYPE;
    return (
      (MOTOR_VEHICLE === FinanceAssetTypeId ||
        RECREATIONAL === FinanceAssetTypeId ||
        EQUIPMENT === FinanceAssetTypeId) &&
      !VehicleDetails.Make
    );
  }

  isInvalidVehicleModel() {
    const { FinanceAssetTypeId, VehicleDetails } = this.asset;
    const { MOTOR_VEHICLE } = this.ASSET_TYPE;
    return MOTOR_VEHICLE === FinanceAssetTypeId && !VehicleDetails.Model;
  }

  isInvalidVehicleYear() {
    const { FinanceAssetTypeId, VehicleDetails } = this.asset;
    return FinanceAssetTypeId && !VehicleDetails.Year;
  }

  isInvalidVehicleCondition() {
    const { FinanceAssetTypeId, VehicleDetails } = this.asset;
    return FinanceAssetTypeId && !VehicleDetails.VehicleConditionID;
  }

  isInvalidVehicleDescription() {
    const { FinanceAssetTypeId, VehicleDetails } = this.asset;
    const { TERTIARY_ASSETS, OTHER } = this.ASSET_TYPE;
    return (
      (TERTIARY_ASSETS === FinanceAssetTypeId ||
        OTHER === FinanceAssetTypeId) &&
      !VehicleDetails.Description
    );
  }

  addSecurity() {
    const {
      FinanceAssetTypeId,
      SaleTypeId,
      Ownership,
      PropertyDetails,
    } = this.asset;
    const isFormIncomplete =
      !FinanceAssetTypeId ||
      !SaleTypeId ||
      !Ownership.length ||
      !PropertyDetails ||
      !PropertyDetails.EstimatedPrice ||
      !PropertyDetails.EstimatedBasesId ||
      this.isInvalidAssetSupplier() ||
      this.isInvalidVehicleMake() ||
      this.isInvalidVehicleModel() ||
      this.isInvalidVehicleYear() ||
      this.isInvalidVehicleCondition() ||
      this.isInvalidVehicleDescription();
    if (isFormIncomplete) {
      this.toaster.pop('error', 'Please fill all required fields.');
      return;
    }

    this.isLoading = true;
    if (!this.isAddressChanged()) {
      this.selectSaveStep();
      return;
    }
    this.saveAddress();
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  $onDestroy() {
    this.$timeout.cancel(this.searchTimeout);
  }
}
