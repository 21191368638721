import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';
import {
  setLoanAppOnOpportunity,
  mapDataFromOpportunity,
  deleteIncome,
} from './util/serviceabilityCtrl';

angular
  .module('app')
  .controller('ServiceabilityCtrl', function serviceabilityCtrl(
    $scope,
    $uibModal,
    SweetAlert,
    loanProfilerService,
    selectOptionsService,
    loanScenarioService,
    contactService,
    NgTableParams,
    $window,
    $location,
    $state,
    $stateParams,
    toaster,
    cfpLoadingBar,
    $timeout,
    $rootScope,
    $filter,
    lendingScenarioService,
    $localStorage,
    uiService,
    commonFnService,
    productFinderService,
    loanProfilerDetailService,
    serviceabilityService,
    bouncingTooltipService,
    currentUserService,
    opportunityCalculationService,
    loanOpportunityService,
    configService
  ) {
    $scope.brokerEventId = $stateParams.brokerEventId;
    $scope.loanScenarioId = parseInt($stateParams.loanScenarioId, 10);
    $scope.familyId = $state.params.familyId;
    $scope.loanId = $state.params.loanId;
    $scope.banksWithSpreadForNZ = ['1', '13', '14', '16'];
    $scope.isDownloadingSpreadsheetForNZ = false;
    $scope.filtersParamtersList = {};
    $scope.settings = {};
    const COURT_RULED_CHILD_MAINTENANCE = 5;
    const SALARY_WAGE_ID = '20';
    const BASE_INCOME_ID = '12';

    const mapDataFromOpportunityCaller = () => {
      const opportunityCalculationData = opportunityCalculationService.getServiceabilityMappedData();
      mapDataFromOpportunity({
        $scope,
        $state,
        configService,
      })({
        opportunityCalculationData,
        loanOpportunityService,
      });
    };
    setLoanAppOnOpportunity({
      $scope,
      $state,
      configService,
    });
    // Init Function
    $scope.init = function() {
      if ($state.params.familyId) {
        toaster.pop(
          'info',
          '',
          'The current serviceability calculation only supports single family, the multiple families will be coming soon.'
        );
      }

      // Serviceability Matched Lender
      //----------------------------------------------------------------------------
      $scope.loanAmount = 0;
      $scope.lendersSummaryList = {};
      $scope.MultiSelctLenderList = [];
      $scope.loadLendersSummary = function() {
        $scope.lenderSelected = false;
        loanProfilerService
          .getServiceabilityLendersList(
            $scope.brokerEventId,
            $scope.loanScenarioId
          )
          .then(response => {
            $scope.getLendersList(response.data);
          });
      };

      $scope.getFilterData = function() {
        loanProfilerService
          .getServiceabilityFiltersParamters()
          .then(productResponse => {
            $scope.filtersParamtersList = productResponse.data;
          });
      };
      $scope.getFilterData();

      $scope.isLoanLower = maxLoanAmount => {
        return maxLoanAmount < $scope.familyMembersData.LoanAmount;
      };

      // Get Serviceability Lender Data List
      $scope.getLendersList = function(data) {
        $scope.lendersSummaryList = data;
        const lendersSelected = _.find($scope.lendersSummaryList, item => {
          return item.IsSelectedLender === true;
        });
        if (
          typeof lendersSelected !== 'undefined' &&
          lendersSelected !== null
        ) {
          $scope.lenderSelected = true;
          $scope.MultiSelctLenderList = _.pluck(lendersSelected, 'LenderID');
        }
        $scope.countPerPage = 0;
        const lendersSummaryListLength = $scope.lendersSummaryList
          ? $scope.lendersSummaryList.length
          : 0;
        $scope.lenderTableParams = new NgTableParams(
          {
            count: lendersSummaryListLength,
            sorting: {
              MaxLoanAmount: 'desc',
            },
          },
          {
            counts: [],
            total: lendersSummaryListLength,
            getData($defer, params) {
              const filterData = $scope.lendersSummaryList;
              if (
                $scope.loanAmount !== '' &&
                $scope.loanAmount !== 0 &&
                typeof $scope.loanAmount !== 'undefined' &&
                $scope.loanAmount !== '.'
              ) {
                _.map(filterData, item => {
                  if (Number(item.MaxLoanAmount) >= Number($scope.loanAmount))
                    item.isMaxLoanInRange = true;
                  else item.isMaxLoanInRange = false;
                  return item;
                });
              } else {
                _.map(filterData, item => {
                  item.isMaxLoanInRange = undefined;
                  return item;
                });
              }
              const orderedData = params.sorting()
                ? $filter('orderBy')(filterData, params.orderBy())
                : filterData;
              const $lenderMaxLoanAmount = _.maxBy(
                orderedData,
                'MaxLoanAmount'
              );
              if (typeof $lenderMaxLoanAmount !== 'undefined') {
                $scope.lenderMaxLoanAmount =
                  Math.round($lenderMaxLoanAmount.MaxLoanAmount / 1000) * 1000;
                if (
                  $scope.lenderMaxLoanAmount <
                  $lenderMaxLoanAmount.MaxLoanAmount
                ) {
                  $scope.lenderMaxLoanAmount += 1000;
                }
              }
              const $lenderMinLoanAmount = _.minBy(
                orderedData,
                'MaxLoanAmount'
              );
              if (typeof $lenderMinLoanAmount !== 'undefined') {
                $scope.lenderMinLoanAmount =
                  Math.round($lenderMinLoanAmount.MaxLoanAmount / 1000) * 1000;
                if (
                  $scope.lenderMinLoanAmount >
                  $lenderMinLoanAmount.MaxLoanAmount
                ) {
                  $scope.lenderMinLoanAmount -= 1000;
                }
              }
              $defer.resolve(
                orderedData.slice(
                  (params.page() - 1) * params.count(),
                  params.page() * params.count()
                )
              );
              $scope.countPerPage = orderedData.slice(
                (params.page() - 1) * params.count(),
                params.page() * params.count()
              ).length;
            },
          }
        );
        if ($scope.isRefreshLender) {
          $timeout(() => {
            cfpLoadingBar.complete();
          }, 750);
          $scope.isLenderLoaderOverlayDisplay = false;
          $rootScope.isLenderLoaderOverlayDisplay =
            $scope.isLenderLoaderOverlayDisplay;
          toaster.pop(
            'success',
            'Updated',
            'Serviceability has been updated successfully'
          );
        }
      };

      // Serviceability Income List
      //----------------------------------------------------------------------------
      $scope.frequencyTypeList = {};
      $scope.existingIncomeList = [];
      $scope.incomeTypeList = [];
      $scope.clientList = [];

      // Income Type List Data
      $scope.getIncomeTypeForRegion = function() {
        const filters = {
          ClientFamilyID: $scope.familyId,
          LoanId: $scope.loanId,
        };
        serviceabilityService
          .getIncomeOptions($scope.brokerEventId, filters)
          .then(response => {
            if (response) {
              $scope.incomeTypeList = response.incomeTypes;
              $scope.clientList = response.clients;
            }
          });
      };
      $scope.getIncomeTypeForRegion();

      // Frequency Type List Data
      loanProfilerService.getFrequencyType().then(response => {
        $scope.frequencyTypeList = response.data;
      });

      // Serviceability Income List Data
      $scope.getIncomeList = function(isWithoutFamilyId = false) {
        loanProfilerService
          .getServiceabilityIncome(
            $scope.brokerEventId,
            $scope.loanScenarioId,
            isWithoutFamilyId ? 0 : $state.params.familyId,
            $state.params.clientIds || null
          )
          .then(response => {
            $scope.existingIncomeList = response.data;
          });
      };
      $scope.getIncomeList();

      // Serviceability Expenses List
      //----------------------------------------------------------------------------
      $scope.existingExpensesList = [];
      $scope.expensesTypeList = {};

      // Expenses Type List Data
      $scope.getExpenseType = function() {
        loanProfilerService.getExpenseType().then(response => {
          $scope.expensesTypeList = response.data;
        });
      };
      $scope.getExpenseType();

      // Serviceability Expenses List Data
      $scope.getExpensesList = function() {
        loanProfilerService
          .getServiceabilityExpense(
            $scope.brokerEventId,
            $scope.loanScenarioId,
            $state.params.familyId || null,
            $state.params.clientIds || null
          )
          .then(response => {
            $scope.existingExpensesList = response.data;
          });
      };
      $scope.getExpensesList();

      // Serviceability Family Members
      //----------------------------------------------------------------------------
      const { proposedLoanAmount, securityAmount } = $stateParams;
      $scope.familyMembersData = {
        LoanTerm: 30,
        LoanAmount: proposedLoanAmount || null,
        SecurityAmount: securityAmount || null,
      };

      mapDataFromOpportunityCaller();
      // get Family Members
      $scope.getFamilyMembers = function() {
        loanProfilerService
          .getServiceabilityFamilyMembers(
            $scope.brokerEventId,
            $scope.loanScenarioId,
            $state.params.familyId || null,
            $state.params.clientIds || null
          )
          .then(({ data }) => {
            const familyMembersData = (data && data.length && data[0]) || {};

            $scope.familyMembersData = {
              ...$scope.familyMembersData,
              ...familyMembersData,
            };
          });
      };
      $scope.getFamilyMembers();

      // Serviceability Income Details
      //----------------------------------------------------------------------------
      $scope.incomeDetailsData = {};

      // get Income Details
      $scope.getIncomeDetails = function() {
        loanProfilerService
          .getServiceabilityIncomeDetails(
            $scope.brokerEventId,
            $scope.loanScenarioId,
            $state.params.familyId || null,
            $state.params.clientIds || null
          )
          .then(response => {
            $scope.incomeDetailsData = response.data;
          });
      };
      !$scope.isServiceabilityFromLoanAppOnOpportunity &&
        $scope.getIncomeDetails();
    };
    if ($stateParams.tab === 'serviceability') $scope.init();

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    if ($scope.screenSize > 768) {
      $scope.isLenderDisplayInMobile = false;
    }
    const viewport = function() {
      let e = $window;
      let a = 'inner';
      if (!('innerWidth' in $window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function() {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        $scope.screenSize = newValue.w;
        if (newValue.w > 768) {
          $scope.isLenderDisplayInMobile = false;
        }
      },
      true
    );

    // Serviceability Income List
    //----------------------------------------------------------------------------

    $scope.isAddIncomeVisible = false;
    $scope.newIncomeList = [];

    $scope.setIncomeType = newIncome => {
      if (!newIncome || !newIncome.IncomeOwner) return;
      const {
        IncomeType: incomeType,
        IncomeOwner: { ClientNo: clientNo, ClientID: clientId = null },
      } = newIncome;
      const incomeTypeId = `${incomeType},${clientNo}`;
      Object.assign(newIncome, {
        IncomeTypeID: incomeTypeId,
        ClientNo: clientNo,
        ClientID: clientId,
      });
    };

    // Add Serviceability Income Div
    $scope.addIncome = function() {
      const isClientEmpty =
        $scope.familyMembersData && !$scope.familyMembersData.NoOfSpouse;
      if (isClientEmpty) return toastError('Please add Number of Clients');
      if (!$scope.clientList || !$scope.clientList.length)
        return toastError(
          'You must add a client first before adding an income.'
        );

      $scope.isAddIncomeVisible = true;
      const newIncome = {
        IncomeID: Math.random(),
        IncomeTypeID: '',
        FrequencyName: 'Monthly',
        IncomeOwner: $scope.clientList[0],
        IncomeType: '',
        Value: '',
        IsEditMode: false,
      };

      if (currentUserService.isAU) {
        newIncome.IncomeType = SALARY_WAGE_ID;
        newIncome.IncomeTypeID = `${SALARY_WAGE_ID},${
          newIncome.IncomeOwner.ClientNo
        }`;
      } else if (currentUserService.isNZ) {
        newIncome.IncomeType = BASE_INCOME_ID;
        newIncome.IncomeTypeID = `${BASE_INCOME_ID},${
          newIncome.IncomeOwner.ClientNo
        }`;
      }
      $scope.newIncomeList.push(newIncome);
    };

    // Add Or Update Serviceability Income
    $scope.saveIncome = function(income) {
      income.isInvalidValue = false;
      income.isInvalidType = false;
      income.isInvalidClient = false;
      const isInvalidInput = input => {
        return input === 0 || typeof input === 'undefined' || input === '';
      };
      const incomeTypeId = income.IncomeTypeId || income.IncomeTypeID;
      if (isInvalidInput(incomeTypeId)) {
        $timeout(() => {
          income.isInvalidType = true;
        }, 100);
        return;
      }

      if (isInvalidInput(income.Value)) {
        $timeout(() => {
          income.isInvalidValue = true;
        }, 100);
        return;
      }

      if (!income.IncomeOwner || isInvalidInput(income.IncomeOwner.ClientNo)) {
        const invalidClientTimeout = $timeout(() => {
          income.isInvalidClient = true;
        }, 100);

        $scope.$on('$destroy', () => {
          $timeout.cancel(invalidClientTimeout);
        });

        return;
      }

      const postData = {
        frequency: income.FrequencyName,
        type: income.IncomeType,
        id: income.IncomeId,
        value: income.Value,
        clientId: income.IncomeOwner.ClientID,
        ownership: [
          {
            numofclient: income.IncomeOwner.ClientNo,
            percentage: 0,
            clientid: income.IncomeOwner.ClientID,
            entityid: income.IncomeOwner.EntityID,
          },
        ],
      };

      if (!income.IsEditMode) {
        postData.id = 0;
      }

      loanProfilerService
        .setServiceabilityIncome($scope.brokerEventId, postData)
        .then(
          () => {
            if (parseInt(postData.id, 10) === 0) {
              const index = $scope.newIncomeList.indexOf(income);
              if (index > -1) $scope.newIncomeList.splice(index, 1);
            }
            $scope.getIncomeList();
            $scope.getIncomeDetails();

            if (parseInt(postData.id, 10) === 0)
              toaster.pop(
                'success',
                'Added',
                'Income has been Added Successfully'
              );
            else
              toaster.pop(
                'success',
                'Updated',
                'Income has been Updated Successfully'
              );

            income.IsEditMode = false;
            income.IsAnimation = true;
          },
          e => {
            toastError(e);
          }
        );
    };

    $scope.deleteIncome = income => {
      deleteIncome(income, $scope.newIncomeList, loanProfilerService).then(
        () => {
          $scope.getIncomeList();
          $scope.getIncomeDetails();
          toaster.pop('success', 'Deleted', 'Income has been deleted');
        },
        e => {
          toaster.pop('error', 'Yikes! Something is wrong', e.data.Message);
        }
      );
    };

    // Edit Serviceability Income
    $scope.editIncome = function(income) {
      if (!income.IsEditMode) {
        const clientNo =
          income.Ownership.length && income.Ownership[0].numofclient;
        const incomeTypeId = income.IncomeTypeId;

        income.IncomeType = incomeTypeId;
        // set default income owner
        income.IncomeOwner = $scope.clientList.find(client => {
          if (income.ClientID) return client.ClientID === income.ClientID;

          return client.ClientNo === clientNo;
        });
      }

      income.IsEditMode = true;
    };

    // Serviceability Expenses List
    //----------------------------------------------------------------------------
    $scope.isAddExpensesVisible = false;
    $scope.newExpensesList = [];

    // Add Serviceability Expenses Div
    $scope.addExpenses = function() {
      $scope.isAddExpensesVisible = true;
      const newExpenses = {
        ExpenseID: Math.random(),
        ExpenseTypeID: '',
        FrequencyName: 'Monthly',
        Value: '',
        IsEditMode: false,
      };
      $scope.newExpensesList.push(newExpenses);
    };

    // Add Or Update Serviceability Expenses
    $scope.saveExpenses = function(expenses) {
      expenses.isInvalidValue = false;
      expenses.isInvalidType = false;
      if (expenses.ExpenseTypeID === '') {
        $timeout(() => {
          expenses.isInvalidType = true;
        }, 100);
        return;
      } else if (
        expenses.Value === 0 ||
        typeof expenses.Value === 'undefined' ||
        expenses.Value === ''
      ) {
        $timeout(() => {
          expenses.isInvalidValue = true;
        }, 100);
        return;
      }

      const postData = {
        frequency: expenses.FrequencyName,
        value: expenses.Value,
        id: expenses.ExpenseID,
        type: expenses.ExpenseTypeID,
      };

      if (!expenses.IsEditMode) {
        postData.id = 0;
      }

      loanProfilerService
        .setServiceabilityExpense($scope.brokerEventId, postData)
        .then(
          () => {
            if (parseInt(postData.id, 10) === 0) {
              const index = $scope.newExpensesList.indexOf(expenses);
              if (index > -1) $scope.newExpensesList.splice(index, 1);
            }

            if (parseInt(postData.id, 10) === 0)
              toaster.pop(
                'success',
                'Added',
                'Expenses has been Added Successfully'
              );
            else
              toaster.pop(
                'success',
                'Updated',
                'Expenses has been Updated Successfully'
              );
            $scope.getExpensesList();
            $scope.getIncomeDetails();

            expenses.IsEditMode = false;
            expenses.IsAnimation = true;
          },
          e => {
            toaster.pop('error', 'Yikes! Something is wrong', e.data.Message);
          }
        );
    };

    // Delete Serviceability Expenses
    $scope.deleteExpenses = function(expenses) {
      if (Math.floor(expenses.ExpenseID) === 0) {
        const index = $scope.newExpensesList.indexOf(expenses);
        if (index > -1) $scope.newExpensesList.splice(index, 1);
      } else {
        loanProfilerService
          .deleteServiceabilityExpense(expenses.ExpenseID)
          .then(
            () => {
              $scope.getExpensesList();
              $scope.getIncomeDetails();
              toaster.pop('success', 'Deleted', 'Expenses has been deleted');
            },
            e => {
              toaster.pop('error', 'Yikes! Something is wrong', e.data.Message);
            }
          );
      }
    };

    // Set Expense Settings
    $scope.setServiceabilitySettings = function() {
      const postData = {
        brokerEventID: $scope.brokerEventId,
        isUseLenderDefault: $scope.incomeDetailsData.IsUseLenderDefault,
      };

      loanProfilerService.setServiceabilitySettings(postData).then(
        () => {
          $scope.getIncomeDetails();
        },
        e => {
          toaster.pop('error', 'Yikes! Something is wrong', e.data.Message);
        }
      );
    };

    // Serviceability Liabilities List
    //----------------------------------------------------------------------------

    // Liabilities List Data
    $scope.existingLiabilitiesList = [];
    $scope.getLiabilitiesList = function() {
      loanProfilerService
        .getLiabilityList(
          $scope.brokerEventId,
          $scope.loanScenarioId,
          $state.params.familyId || null
        )
        .then(response => {
          $scope.existingLiabilitiesList = response.data;
          _.each($scope.existingLiabilitiesList, liability => {
            liability.isLimit = loanProfilerDetailService.liabilityIsLimit(
              liability.LiabilityTypeID
            );
            liability.isNextProp = loanProfilerDetailService.liabilityIsNextProp(
              liability.LiabilityTypeID
            );
          });
        });
    };

    // Liabilities Type List Data
    $scope.liabilitiesTypeList = [];
    $scope.getLiabilitiesTypeList = function() {
      loanProfilerService.getLiabilityTypeList(0).then(response => {
        $scope.liabilitiesTypeList = $filter('orderBy')(
          response.data,
          'TypeName'
        );
        $scope.getLiabilitiesList();
      });
    };
    if ($stateParams.tab === 'serviceability') $scope.getLiabilitiesTypeList();

    $scope.isAddLiabilitiesVisible = false;
    $scope.newLiabilitiesList = [];

    // Add Serviceability Expenses Div
    $scope.addLiabilities = function() {
      $scope.isAddLiabilitiesVisible = true;
      const newLiabilities = {
        LiabilityID: Math.random(),
        LiabilityTypeID: '',
        FrequencyName: 'Monthly',
        LiabilityRepayment: '',
        LiabilityLimit: '',
        LiabilityBalance: '',
        InterestRate: '',
        IsTaxDeductible: false,
        IsEditMode: false,
      };
      $scope.newLiabilitiesList.push(newLiabilities);
    };

    $scope.editLiability = liability => {
      liability.IsEditMode = true;
      $scope.liabilityEdit = { ...liability };
    };

    // Add Or Update Liabilities
    $scope.saveLiabilities = (Liabilities, isValid) => {
      if (
        isValid ||
        (Liabilities.LiabilityTypeID === COURT_RULED_CHILD_MAINTENANCE &&
          Liabilities.LiabilityRepayment)
      ) {
        Liabilities.isInvalidValue = false;
        Liabilities.isInvalidType = false;
        Liabilities.isInvalidLimit = false;
        Liabilities.isInvalidBalance = false;

        const postData = {
          Frequency: Liabilities.FrequencyName,
          Limit: Liabilities.isLimit ? Liabilities.LiabilityLimit : '',
          RepaymentAmount:
            parseInt(Liabilities.LiabilityTypeID, 10) !== 7
              ? Liabilities.LiabilityRepayment
              : '',
          Balance: !Liabilities.isLimit ? Liabilities.LiabilityBalance : '',
          InterestRate: Number(Liabilities.InterestRate),
          IsTaxDeductible: Liabilities.IsTaxDeductible,
          id: Liabilities.LiabilityID,
          type: Liabilities.LiabilityTypeID,
        };
        if (!Liabilities.IsEditMode) {
          postData.id = 0;
        }

        loanProfilerService.setLiability($scope.brokerEventId, postData).then(
          () => {
            if (parseInt(postData.id, 10) === 0) {
              const index = $scope.newLiabilitiesList.indexOf(Liabilities);
              if (index > -1) {
                $scope.newLiabilitiesList.splice(index, 1);
              }
            }

            if (parseInt(postData.id, 10) === 0) {
              toaster.pop(
                'success',
                'Added',
                'Liability has been Added Successfully'
              );
            } else {
              toaster.pop(
                'success',
                'Updated',
                'Liability has been Updated Successfully'
              );
            }
            $scope.getLiabilitiesList();
            $scope.getIncomeDetails();

            Liabilities.IsEditMode = false;
            Liabilities.IsAnimation = true;
          },
          e => {
            toaster.pop('error', 'Yikes! Something is wrong', e.data.Message);
          }
        );
      } else {
        Liabilities.isInvalidType = Liabilities.LiabilityTypeID === '';
        Liabilities.isInvalidValue =
          !Liabilities.LiabilityRepayment &&
          parseInt(Liabilities.LiabilityTypeID, 10) !== 7;
        Liabilities.isInvalidLimit =
          !Liabilities.LiabilityLimit && Liabilities.isLimit;
        Liabilities.isInvalidBalance =
          !Liabilities.LiabilityBalance &&
          !Liabilities.isLimit &&
          Liabilities.LiabilityTypeID !== COURT_RULED_CHILD_MAINTENANCE;
      }
    };

    // Change Value Of Rate
    $scope.changeValueOfRate = function(liability) {
      if (!liability.IsTaxDeductible) liability.InterestRate = '';
    };

    // Change Value Of Rate
    $scope.changeValueOfLiabilityType = function(liability) {
      liability.IsTaxDeductible = false;
      liability.InterestRate = '';
      liability.isLimit = loanProfilerDetailService.liabilityIsLimit(
        liability.LiabilityTypeID
      );
      liability.isNextProp = loanProfilerDetailService.liabilityIsNextProp(
        liability.LiabilityTypeID
      );
      liability.isInvalidLimit = false;
      liability.isInvalidBalance = false;
    };

    // Delete Liabilities
    $scope.deleteLiabilities = function(Liabilities) {
      if (Math.floor(Liabilities.LiabilityID) === 0) {
        const index = $scope.newLiabilitiesList.indexOf(Liabilities);
        if (index > -1) $scope.newLiabilitiesList.splice(index, 1);
      } else if (Liabilities.IsEditMode) {
        Liabilities.IsEditMode = false;
      } else {
        loanProfilerService.deleteLiability(Liabilities.LiabilityID).then(
          () => {
            $scope.getLiabilitiesList();
            $scope.getIncomeDetails();
            toaster.pop('success', 'Deleted', 'Liability has been deleted');
          },
          e => {
            toaster.pop('error', 'Yikes! Something is wrong', e.data.Message);
          }
        );
      }
    };

    // Serviceability Family Members
    //----------------------------------------------------------------------------

    // Update Serviceability Family Members
    $scope.updateFamilyMembers = function() {
      if (!$scope.familyMembersData) return toastError();
      const existingIncomeList = $scope.existingIncomeList
        ? $scope.existingIncomeList
        : [];
      const dataIncome = existingIncomeList.find(
        item => item.ClientNo > $scope.familyMembersData.NoOfSpouse
      );
      const dataExpense = existingIncomeList.find(
        item => item.ClientNo > $scope.familyMembersData.NoOfSpouse
      );

      if (dataIncome || dataExpense) {
        $scope.familyMembersData.NoOfSpouse++;
        toastError(
          'Please first delete record from the income or expense list'
        );
      } else {
        $scope.familyMembersData.brokerEventID = $scope.brokerEventId;
        loanProfilerService
          .setServiceabilityFamilyMembers($scope.familyMembersData)
          .then(response => {
            $scope.familyMembersData.LVR =
              response.data && response.data.length && response.data[0].LVR
                ? response.data[0].LVR
                : 0;
            if (response.data && response.data.length) {
              $scope.getIncomeTypeForRegion($scope.brokerEventId);
            }
          });
      }
    };

    const {
      LoanAmount: targetLoanAmount,
      SecurityAmount: targetSecurityAmount,
    } = $scope.familyMembersData || {};
    if (targetLoanAmount || targetSecurityAmount) {
      !$scope.isServiceabilityFromLoanAppOnOpportunity &&
        $scope.updateFamilyMembers();
    }

    // Save Serviceability Income, Expense and Liabilities Data on click of Calculate Button
    $scope.SaveAllData = function() {
      $scope.isLenderLoaderOverlayDisplay = true;
      $rootScope.isLenderLoaderOverlayDisplay =
        $scope.isLenderLoaderOverlayDisplay;
      $scope.isRefreshLender = true;
      cfpLoadingBar.start();
      cfpLoadingBar.inc();
      const formData = angular
        .element('.col-fixed-324 .serviceability-div')
        .find('form.income-expense-form .serviceability-save-btn');
      angular.forEach(formData, item => {
        const eventTimeout = $timeout(() => {
          item.click();
          $timeout.cancel(eventTimeout);
        }, 100);
      });
      const formDataLiability = angular
        .element('.col-fixed-324 .serviceability-div')
        .find('form.liability-form .serviceability-save-btn');
      angular.forEach(formDataLiability, item => {
        const eventTimeout = $timeout(() => {
          item.click();
          $timeout.cancel(eventTimeout);
        }, 100);
      });
      $scope.loanAmount = 0;
      angular.element('#loanamounttxt').val(0);
      bouncingTooltipService.setTooltipVisible(true);
    };

    // Refresh Result Of Lender
    //-----------------------------------------------------------------------------
    $scope.refreshResultOfLender = function() {
      const noOfClientChecker =
        !$scope.familyMembersData.NoOfSpouse ||
        $scope.familyMembersData.NoOfSpouse <= 0;
      if (noOfClientChecker) {
        toaster.pop('error', 'Error', 'Please add number of clients');
        return;
      }
      $scope.SaveAllData();
      $scope.loadLendersSummary();
      $scope.isLenderDisplay = true;
      if ($scope.screenSize < 768) {
        $scope.isLenderDisplayInMobile = true;
      }
      $timeout(() => {
        $rootScope.disableSaveContactBtn = false;
      }, 500);
    };

    // Refresh Result Of Lender
    //-----------------------------------------------------------------------------
    $scope.calculateResultOfLender = function() {
      const noOfClientChecker =
        !$scope.familyMembersData.NoOfSpouse ||
        $scope.familyMembersData.NoOfSpouse <= 0;
      if (noOfClientChecker) {
        toaster.pop('error', 'Error', 'Please add number of clients');
        return;
      }
      $scope.SaveAllData();

      $scope.lenderSelected = false;
      loanProfilerService
        .getServiceabilityNextGenAccessment(
          $scope.brokerEventId,
          $scope.loanScenarioId,
          $state.params.familyId || null,
          $state.params.clientIds || null
        )
        .then(
          response => {
            if (response.data === null) return toastError();
            productFinderService
              .returnAddedSpreadsheetToProductList(response.data)
              .then(withSpreadsheet => {
                if (withSpreadsheet) {
                  $scope.getLendersList(withSpreadsheet);
                } else {
                  $scope.getLendersList(response.data);
                }
              });
          },
          () => {
            toaster.pop(
              'error',
              'Error',
              'Yikes! Something is wrong. Please hit Calculate again.'
            );
          }
        );
      $scope.isLenderDisplay = true;
      if ($scope.screenSize < 768) {
        $scope.isLenderDisplayInMobile = true;
      }
      $timeout(() => {
        $rootScope.disableSaveContactBtn = false;
      }, 500);
    };

    // View Product
    $scope.viewProduct = function(productSelectionType, id) {
      $scope.defaultSelection = {};
      loanProfilerService
        .getServiceabilityFilteringProductsGeneralSettings($scope.brokerEventId)
        .then(response => {
          $scope.defaultSelection = response.data;
          if ($scope.defaultSelection.Lender) {
            $scope.defaultSelection.Lender = [];
            if (productSelectionType === 'multiple')
              $scope.defaultSelection.Lender = $scope.MultiSelctLenderList;
            if (productSelectionType === 'single')
              $scope.defaultSelection.Lender = [id];
          }
          if ($scope.familyMembersData) {
            const {
              LoanAmount: loanAmount,
              SecurityAmount: securityAmount,
              LoanTerm: loanTerm,
              LVR: lvr,
            } = $scope.familyMembersData;
            $scope.defaultSelection.loanAmount =
              (loanAmount && parseInt(loanAmount, 10)) || '';
            $scope.defaultSelection.security =
              (securityAmount && parseInt(securityAmount, 10)) || '';
            $scope.defaultSelection.loanTerm =
              (loanTerm && parseInt(loanTerm, 10)) || '';
            if (lvr > 100 || parseFloat(lvr).toFixed(2) === 0.0) {
              $scope.defaultSelection.LVR = 0;
            }
            if (parseInt(lvr, 10) !== 0) {
              $scope.defaultSelection.LVR = `+${parseFloat(lvr).toFixed(2)}`;
            }
          }
          loanProfilerService
            .updateServiceabilityFilteringProductsSettings(
              $scope.defaultSelection.brokerEventID,
              { GeneralSetting: $scope.defaultSelection }
            )
            .then(generalSettingsResponse => {
              if (generalSettingsResponse && generalSettingsResponse.data) {
                $state.go('app.loanProfiler', {
                  brokerEventId: $scope.brokerEventId,
                  loanScenarioId: 0,
                  tab: 'productFinder',
                });
              } else {
                toaster.pop('error', 'Error', 'Yikes! Something is wrong');
              }
            });
        });
    };

    // View Multiple Product on Product Finder
    $scope.viewProductFinder = function() {
      if ($scope.MultiSelctLenderList.length === 0) {
        toaster.pop(
          'warning',
          'No Lender Selected',
          'Please select lender for view in product finder'
        );
      } else {
        $scope.viewProduct('multiple', 0);
      }
    };
    $scope.openSheet = function(lenderId) {
      loanProfilerService
        .LenderInfoSpreadsheetDataGet(lenderId, $scope.brokerEventId)
        .then(response => {
          if (response.data && response.data.DocumentContent) {
            const eventTimeout = $timeout(() => {
              const byteString = $window.atob(response.data.DocumentContent);
              const a = $window.document.createElement('a');

              const ab = new ArrayBuffer(byteString.length);
              const ia = new Uint8Array(ab);
              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }

              const blob = new $window.Blob([ia], {
                type: response.data.ContentType,
              });

              a.href = $window.URL.createObjectURL(blob);
              a.download = response.data.Name;
              a.click();
              $timeout.cancel(eventTimeout);
            });
          }
        });
    };

    // View Single Product on Product Finder
    $scope.viewSingleProduct = function(id) {
      $scope.viewProduct('single', id);
    };

    $scope.lenderSelection = [];
    // Update Lender Selection
    // ----------------------------------------------------------------------------
    $scope.updateLender = function(item) {
      item.IsSelectedLender = !item.IsSelectedLender;
      if (item.IsSelectedLender === true) {
        $scope.MultiSelctLenderList.push(item.LenderID);
      } else {
        const index = $scope.MultiSelctLenderList.indexOf(item.LenderID);
        if (index > -1) $scope.MultiSelctLenderList.splice(index, 1);
      }
    };
    /** NZ Requirements By Elmer Datolayta */
    $scope.hiddenClientForNz = false;
    commonFnService.hiddenClientForNz().then(response => {
      $scope.hiddenClientForNz = response;
    });

    $scope.hasRubikSpreadsheets = lender => {
      return (
        uiService.isCountry('Australia') &&
        lender.spreadSheetsArray &&
        lender.spreadSheetsArray.length
      );
    };

    $scope.isNZ = () => {
      return uiService.isCountry('New Zealand');
    };

    $scope.isAU = () => {
      return uiService.isCountry('Australia');
    };

    $scope.hasNZSpreadsheets = lender => {
      if (!lender) return false;

      return (
        uiService.isCountry('New Zealand') &&
        $scope.banksWithSpreadForNZ.findIndex(
          item => item === lender.LenderID.toString()
        ) !== -1
      );
    };
  });
