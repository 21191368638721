import {
  typeIntBuilderForUI,
  marketingAutomationBuilderForUI,
  typeBuilderForUI,
} from 'Common/mappers/sharedMappers';
import {
  valuationBasisBuilderForUI,
  lenderListBuilderForUI,
  businessTypesBuilderForUI,
  lendingCategoriesBuilderForUI,
  lenderCategoryBuilderForUI,
  frequencyBuilderForUI,
  separationLetterGenerateStatusesBuilderForUI,
  separationLetterContentsBuilderForUI,
  employmentNatureForUI,
} from 'Common/mappers/options';
import { objectivesOptionBuilderForUI } from 'Common/mappers/customerObjectives';
import { discountTimePeriodBuilderForUI } from 'Common/mappers/loanTools';
import { absPurposeListBuilderForUI } from 'Common/mappers/proposedLending';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';

class OptionsService {
  constructor(httpClient, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.$q = $q;
    this.apiBaseUrl = 'options';
    this.defaultOptions = [null, true, false];
  }

  IncomeVerificationType(questionType) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Financial/IncomeVerificationType`,
      { questionType }
    );
  }

  ContactStatusGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/ContactStatusGet`,
      ...this.defaultOptions
    );
  }

  GeneralInsuranceGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/GeneralInsuranceGet`,
      ...this.defaultOptions
    );
  }

  LifestyleInsuranceGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/LifestyleInsuranceGet`,
      ...this.defaultOptions
    );
  }

  RiskInsuranceGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/RiskInsuranceGet`,
      ...this.defaultOptions
    );
  }

  question(questionType) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/Question`, {
      questionType,
    });
  }

  frequency() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/Frequency`,
      ...this.defaultOptions
    );
  }

  FinancialFrequency() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/FinancialFrequency`,
      ...this.defaultOptions
    );
  }

  absPurpose() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/ABSPurpose`,
      ...this.defaultOptions
    );
  }

  loanProductList(providerID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/LoanProductList`,
      { providerID }
    );
  }

  tenureTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/TenureTypeGet`,
      ...this.defaultOptions
    );
  }

  EmploymentCategoryGetResult() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/EmploymentCategoryGet`,
      ...this.defaultOptions
    );
  }

  getEmploymentBasis() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/EmploymentBasisGet`,
      ...this.defaultOptions
    );
  }

  propertyTypeGet(PropertyZoningTypeID) {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/PropertyTypeGet`, {
      PropertyZoningTypeID,
    });
  }

  ownershipTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/OwnershipTypeGet`,
      ...this.defaultOptions
    );
  }

  floorTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/FloorTypeGet`,
      ...this.defaultOptions
    );
  }

  addressTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/AddressTypeGet`,
      ...this.defaultOptions
    );
  }

  titleTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/TitleTypeGet`,
      ...this.defaultOptions
    );
  }

  getSecurityType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Security/SecurityTypeGet`,
      ...this.defaultOptions
    );
  }

  getTransactionType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Security/TransactionTypeGet`,
      ...this.defaultOptions
    );
  }

  getAssetValueEstimatedBasis(toMap = false) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/Property/AssetValueEstimatedBasisGet`,
        ...this.defaultOptions
      )
      .then(response => {
        return toMap ? response.data.map(typeIntBuilderForUI) : response;
      });
  }

  getHoldingType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/HoldingTypeGet`,
      ...this.defaultOptions
    );
  }

  getPrimaryPurposeType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/PrimaryPurposeTypeGet`,
      ...this.defaultOptions
    );
  }

  getStatusType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/StatusTypeGet`,
      ...this.defaultOptions
    );
  }

  getZoningType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/zone-types`,
      ...this.defaultOptions
    );
  }

  getPropertyType(PropertyZoningTypeID, useCache = false) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/PropertyTypeGet`,
      { PropertyZoningTypeID },
      useCache
    );
  }

  getTitleTenureType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/TitleTenureTypeGet`,
      ...this.defaultOptions
    );
  }

  getTitleType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/TitleTypeGet`,
      ...this.defaultOptions
    );
  }

  getTitleIdentificationType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/TitleIdentificationTypeGet`,
      ...this.defaultOptions
    );
  }

  getContactAccessType() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Security/ContactAccessTypeGet`,
      ...this.defaultOptions
    );
  }

  getMortgagePriority() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Financial/MortgagePriorityGet`,
      ...this.defaultOptions
    );
  }

  getLenderList(lenderPlace, toMap = false) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/General/LenderListGet`, { lenderPlace })
      .then(response => {
        return toMap ? response.data.map(lenderListBuilderForUI) : response;
      });
  }

  identificationDocumentsTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/IdentificationDocumentsTypeGet`,
      ...this.defaultOptions
    );
  }

  countryListGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/CountryListGet`,
      ...this.defaultOptions
    );
  }

  employmentCategoryGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/EmploymentCategoryGet`,
      ...this.defaultOptions
    );
  }

  employmentStatusGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/EmploymentStatusGet`,
      ...this.defaultOptions
    );
  }

  employerTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/EmployerTypeGet`,
      ...this.defaultOptions
    );
  }

  employmentRoleGet(criteria) {
    return this.httpClient.get(`${this.apiBaseUrl}/General/EmploymentRoleGet`, {
      criteria,
    });
  }

  loanpurpose() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/Loanpurpose`,
      ...this.defaultOptions
    );
  }

  DepositSource() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/DepositSource`,
      ...this.defaultOptions
    );
  }

  DepositType(lendingCategoryId = 0) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/DepositType`,
      { lendingCategoryId }
    );
  }

  LoanFeatures() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/LoanFeatures`,
      ...this.defaultOptions
    );
  }

  bankBranchSearch(search, lenderId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/BankBranchSearch`,
      { search, lenderId }
    );
  }

  getFrequency() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/Frequency`,
      ...this.defaultOptions
    );
  }

  MortgagePriorityGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Financial/MortgagePriorityGet`,
      ...this.defaultOptions
    );
  }

  AssetValueBasisGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Property/AssetValueEstimatedBasisGet`,
      ...this.defaultOptions
    );
  }

  OFXSupportCurrency() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/OFXSupportCurrency`,
      ...this.defaultOptions
    );
  }

  MaritalStatusGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/MaritalStatusGet`,
      ...this.defaultOptions
    );
  }

  ResidencyStatusGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/ResidencyStatusGet`,
      ...this.defaultOptions
    );
  }

  AssetTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Financial/AssetTypeGet`,
      ...this.defaultOptions
    );
  }

  SubAssetTypeGet(assetTypeId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Financial/SubAssetTypeGet`, {
      assetTypeId,
    });
  }

  LiabilityTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/Financial/LiabilityTypeGet`,
      ...this.defaultOptions
    );
  }

  LoanFeaturesGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/LoanFeaturesGet`,
      ...this.defaultOptions
    );
  }

  scenarioSearchClientGet(searchString, partyType) {
    // TODO: move this to generalService
    return this.httpClient.get(`SearchClientGet`, { searchString, partyType });
  }

  PreferredContactMethodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/PreferredContactMethodGet`,
      ...this.defaultOptions
    );
  }

  getEnquirySourceList(params = {}) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/User/EnquirySourceList`,
      params,
      false,
      false
    );
  }

  getReferencedReferralList(adviserId = 0, searchString = '') {
    return this.httpClient.get(
      `${this.apiBaseUrl}/General/ReferencedReferralList`,
      { adviserId, searchString }
    );
  }

  DocumentTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/DocumentTypeGet`,
      ...this.defaultOptions
    );
  }

  getDueDateTypes() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/tasks/due-date-types`,
      null,
      true,
      false
    );
  }

  NZDocumentTypeGet(lenderId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/NZDocumentTypeGet`,
      { lenderId }
    );
  }

  TopUpTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/TopUpTypeGet`,
      ...this.defaultOptions
    );
  }

  RentalVerificationTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanApplication/RentalVerificationTypeGet`,
      ...this.defaultOptions
    );
  }

  getLoanStatus(includeStatus = '') {
    return this.httpClient.get(`${this.apiBaseUrl}/Loan/LoanStatus`, {
      includeStatus,
    });
  }

  getLmiInsurers(lenderId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LMI/${lenderId}/Insurers`,
      ...this.defaultOptions
    );
  }

  brokerAccessTypes() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/BrokerAccessTypes`,
      ...this.defaultOptions
    );
  }

  adviserAssistantsGet(adviserId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/broker/${adviserId}/assistants`,
      ...this.defaultOptions
    );
  }

  getASBBranchOptions() {
    // TODO: move this to API implementation
    this.asbBranchOptions = [
      {
        Value: '1',
        Name: 'Central',
      },
      {
        Value: '2',
        Name: 'South',
      },
      {
        Value: '3',
        Name: 'Northern',
      },
      {
        Value: '4',
        Name: 'Local',
      },
    ];
    return this.asbBranchOptions;
  }

  getMostRecentLeads() {
    return this.$q.resolve({
      data: [
        {
          from: 0,
          to: 9999,
          days: 'Please select a days',
        },
        {
          from: 0,
          to: 7,
          days: 'Last 7 days',
        },
        {
          from: 8,
          to: 30,
          days: '8 to 30 days',
        },
        {
          from: 31,
          to: 90,
          days: '31 to 90 days',
        },
        {
          from: 90,
          to: 180,
          days: '90 to 180 days',
        },
        {
          from: 181,
          to: 9999,
          days: '> 180 days',
        },
        {
          from: 0,
          to: 0,
          days: 'Never',
        },
      ],
    });
  }

  getTitleList() {
    const defer = this.$q.defer();
    const titleList = [
      { id: 1, value: 'Mr.' },
      { id: 2, value: 'Mrs.' },
      { id: 3, value: 'Ms.' },
      { id: 4, value: 'Dr.' },
      { id: 5, value: 'Prof.' },
      { id: 6, value: 'Hon.' },
    ];
    defer.resolve(titleList);
    return defer.promise;
  }

  getActivityType(familyId) {
    return this.httpClient.get(
      `options/notification/types?familyId=${familyId}`
    );
  }

  getActivityAdvisers(familyId) {
    return this.httpClient.get(
      `options/notification/advisers?familyId=${familyId}`
    );
  }
  getLoanTerms() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/loan/loanterms`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getAdvisers(loanWritersOnly) {
    return this.httpClient
      .get(
        `${
          this.apiBaseUrl
        }/corporate/advisers?loanWritersOnly=${loanWritersOnly}`
      )
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getMarketingAutomation() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/subscription/marketing-automation`)
      .then(response => response.data.map(marketingAutomationBuilderForUI));
  }
  getValuationBasisType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/Security/ValuationBasisTypeGet`)
      .then(response => response.data.map(valuationBasisBuilderForUI));
  }
  getAssetFinanceSaleType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/asset-finance/sale-type`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getAssetFinanceSecurityType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/asset-finance/security-type`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getAssetFinanceVehicleCondition() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/asset-finance/vehicle-condition-type`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getAssetFinanceCategory() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/asset-finance/finance-category-type`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getAssetFinanceLoanTerms() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/asset-finance/loan-term`)
      .then(response => response.data.map(typeBuilderForUI));
  }
  getAssetFinanceVehicleClass() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/asset-finance/vehicle-class-type`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getLendingCategories() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lending-category`)
      .then(response => response.data.map(lendingCategoriesBuilderForUI));
  }
  getDiscountTimePeriod() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/discount/time-period`)
      .then(response => response.data.map(discountTimePeriodBuilderForUI));
  }
  getBusinessTypes() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/referrer/business-types`)
      .then(response => response.data.map(businessTypesBuilderForUI));
  }
  getObjectivesOption() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/objective-options`)
      .then(response => response.data.map(objectivesOptionBuilderForUI));
  }
  getLendingPurposeList() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/proposed-lending-purpose-list`)
      .then(({ data }) => data && data.map(absPurposeListBuilderForUI));
  }
  getAdverseImpactUnits() {
    return this.$q.resolve({
      data: [
        { value: 1, name: 'Weeks' },
        { value: 2, name: 'Months' },
        { value: 3, name: 'Years' },
      ],
    });
  }
  getAdverseImpactOperators() {
    return this.$q.resolve({
      data: [
        { value: 1, name: '<' },
        { value: 2, name: '<=' },
        { value: 3, name: '=' },
        { value: 4, name: '>=' },
        { value: 5, name: '>' },
      ],
    });
  }

  getArrearsType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/asset-finance/arrear-type`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }

  getCategoryLendersList() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/category-lenders-list`)
      .then(
        response =>
          response.data && response.data.map(lenderCategoryBuilderForUI)
      );
  }
  getReviewMonth() {
    return this.httpClient.get(`${this.apiBaseUrl}/review-month`);
  }

  getYearlyBasedFrequency() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/General/YearlyBasedFrequency`)
      .then(({ data }) => data.map(frequencyBuilderForUI));
  }
  getSeparationLetterGenerateStatuses() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/separation-letter-generate-statuses`)
      .then(
        response =>
          response.data &&
          response.data.map(separationLetterGenerateStatusesBuilderForUI)
      );
  }

  getSeparationLetterContents() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/separation-letter-contents`)
      .then(
        response =>
          response.data &&
          response.data.map(separationLetterContentsBuilderForUI)
      );
  }

  getEmploymentNature() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/General/employment-nature`)
      .then(
        response => response.data && response.data.map(employmentNatureForUI)
      );
  }

  getOnboardingLoanWriterRole() {
    const defer = this.$q.defer();
    const titleList = [
      { value: ONBOARDING_ROLE_ID.EMPLOYEE, name: 'Employee' },
      { value: ONBOARDING_ROLE_ID.CONTRACTOR, name: 'Contractor' },
    ];
    defer.resolve(titleList);
    return defer.promise;
  }
}

export default OptionsService;
