import './style.scss';
import template from './gradientBanner.html';
import controller from './gradientBannerCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    iconName: '@',
    text: '@',
    startColor: '@',
    endColor: '@',
    paddingTopBottom: '@',
    paddingLeftRight: '@',
    arrowTop: '@',
    customClass: '@',
    actionLabel: '@',
    actionIconTemplate: '<',
    onAction: '&',
  },
};
