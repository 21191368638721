import swal from 'sweetalert';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';

export default class SubscriptionCancelModalCtrl {
  constructor(contactService) {
    'ngInject';

    this.contactService = contactService;
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
  confirmCancel() {
    if (this.familyId && this.subscriptionData) {
      this.contactService
        .subscriptionCancel(this.subscriptionData, this.familyId)
        .then(subscriptionCancelResponse => {
          if (subscriptionCancelResponse.data) {
            swal({
              title: 'Success',
              text: 'You have been successfully unsubscribed to this service.',
              type: 'success',
            });
            this.modalInstance.close({ getSubscription: true });
          }
        });
    }
  }
  $onInit() {
    const {
      subscriptionProductId,
      isImmediately,
      isTrial,
    } = this.subscriptionData;
    this.subscriptionData.isImmediately =
      !!(subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK) ||
      isImmediately;
    if (isTrial) {
      this.modalMessage = {
        header: `Are you sure you want to cancel you free trial of ${
          this.subscriptionData.title
        }?`,
        paragraph: `Sorry to see you go. You will not be able to re-activate your free trial.  If you want to subscribe to the ${
          this.subscriptionData.title
        } in future, simply head to your profile management page by clicking your name in the top of the screen. There is only one free trial per broker so you will not be able to re-activate your free trial.`,
        cancelButtonText: 'Yes, cancel my free trial',
      };
    } else {
      this.modalMessage = {
        cancelButtonText: 'Yes, cancel my subscription',
      };
    }
  }
}
