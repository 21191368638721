import template from './creditCheckHistory.html';
import controller from './creditCheckHistoryCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    adviserDetails: '<',
  },
};
