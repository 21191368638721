import {
  getLoanTermInMonths,
  getFrequencyText,
} from 'Common/utilities/loanStructure';
import {
  manualIFinanceBuilderForUI,
  manualIFinanceBuilderForMyCRM,
} from 'Common/mappers/loanScenario';
import { assetFinanceFavouriteProductBuilderForUI } from 'Common/mappers/serviceability';
import { toastError } from 'Common/utilities/alert';
import { CLIENT_TYPE } from 'Common/constants/iFinance';
import { ASSET_TYPE } from 'Common/constants/securityValues';
import {
  getCalculatedAmount,
  getCalculatedPercentage,
  validateRange,
} from 'Common/utilities/linkedPercentageFieldUtil';

class assetFinanceLoanFacility {
  constructor(
    $uibModal,
    uiService,
    optionsService,
    loanScenarioService,
    loanProfilerService,
    currentUserService,
    financialsSharedService,
    configService
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.uiService = uiService;
    this.optionsService = optionsService;
    this.loanScenarioService = loanScenarioService;
    this.loanProfilerService = loanProfilerService;
    this.currentUserService = currentUserService;
    this.financialsSharedService = financialsSharedService;
    this.getLoanTermInMonths = getLoanTermInMonths;
    this.getFrequencyText = getFrequencyText;
    this.configService = configService;
  }

  $onInit() {
    this.iFinanceFilters = {};
    this.selectedProducts = [];
    this.productFinderLabel = 'Specify Product Name';
    this.showFrequencyOptions = false;

    this.completedIFinanceFields = false;
    this.isCountryAU = this.currentUserService.isAU;
    this.isActiveProductFinder =
      this.configService.feature.assetFinanceProductFinder && this.isCountryAU;
    this.lenderListFilter = this.currentUserService.isNZ
      ? ''
      : this.lenderListFilter;
    this.getRepaymentFrequencyList();
  }

  $onChanges(changes) {
    const { loanFacility } = changes;

    if (!loanFacility || !loanFacility.currentValue) return;
    this.facility = this.loanFacility.length ? this.loanFacility[0] : {};
    this.manualIFinance = this.facility.AssetFinance
      ? manualIFinanceBuilderForUI(this.facility.AssetFinance)
      : {};
    this.setFacilityFinanceCategoryId();
    this.getShortLoanStructureId(this.facility.BrokerEventId);
    this.getProductFinderFields();
    this.setManualFieldsDisplay();
  }

  updateFacility(loanStructureId) {
    const props = { loanAppId: this.loanAppId, loanStructureId };
    props.loanDetails = this.loanDetails;
    const loanStructureModal = this.$uibModal.open({
      template: `<new-loan-structure
                  loan-details="vm.props.loanDetails"
                  modal-instance="vm.modalInstance"
                  loan-app-id="vm.props.loanAppId"
                  loan-structure-id="vm.props.loanStructureId">
                </new-loan-structure>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'static',
      windowClass: 'new-loan-structure',
      keyboard: false,
      backdropClass: 'newLoanStructure-modal',
      openedClass: 'modal-w700 modal-open',
      resolve: {
        props,
      },
    });

    loanStructureModal.result.then(() => {
      this.onLoanDetailsGet();
    });
  }

  getShortLoanStructureId(brokerEventId) {
    if (!brokerEventId) return;
    this.loanProfilerService
      .getSelectedProductDetailsGetByLoanStructure(brokerEventId)
      .then(response => {
        if (!response || !response.data) return;
        const favouriteProduct = response.data;
        this.facility.shortLoanStructureId =
          favouriteProduct &&
          favouriteProduct.length &&
          favouriteProduct[0].LoanStructureID;
        this.getFacilityFavouriteProductList(
          this.facility.BrokerEventId,
          this.facility.shortLoanStructureId
        );
      });
  }

  setFacilityFinanceCategoryId() {
    this.optionsService.getAssetFinanceCategory().then(response => {
      const financeCategory = response.find(
        element => this.facility.FinanceCategory === element.name
      );
      this.facility.FinanceCategoryId =
        financeCategory && financeCategory.value;
    });
  }

  getProductFinderFields() {
    this.loanScenarioService
      .getLoanSecurity(this.loanAppId)
      .then(({ data }) => {
        if (!data) return;
        const defaultSecurity =
          data.SecurityList && data.SecurityList.length && data.SecurityList[0];

        this.optionsService.getAssetFinanceSecurityType().then(res => {
          if (!res.length) return;
          const financeType = res.find(
            element => defaultSecurity.FinanceAssetType === element.name
          );
          const assetSecurityType = financeType && financeType.value;
          const redbookCode =
            defaultSecurity &&
            defaultSecurity.VehicleDetails &&
            defaultSecurity.VehicleDetails.RedBookCode;

          this.iFinanceFilters = {
            financeCategory: this.facility.FinanceCategory,
            totalLoanAmount: this.facility.TotalLoanAmount,
            loanTerm: this.getLoanTermInMonths(this.facility.LoanTerm, false),
            brokerCommissionPercent: this.facility.BrokerCommissionPercent,
            residualPercent: this.facility.ResidualPercent,
            clientType: this.getClientType(),
            isPropertyOwner: this.hasRealEStateAsset(),
            purchasePrice:
              defaultSecurity &&
              defaultSecurity.PropertyDetails &&
              defaultSecurity.PropertyDetails.EstimatedPrice,
            payoutAmount: defaultSecurity && defaultSecurity.FinanceAmount,
            assetSecurityType,
            redbookCode:
              assetSecurityType === ASSET_TYPE.MOTOR_VEHICLE && redbookCode
                ? redbookCode
                : '',
          };

          this.completedIFinanceFields = this.getIFinanceFieldsStatus();
        });
      });
  }

  setManualFieldsDisplay() {
    const manual = this.manualIFinance;
    const hasManualFieldData =
      manual.accountKeepingFee ||
      manual.assetVariant ||
      manual.establishmentFee ||
      manual.interestRate ||
      manual.lenderName ||
      manual.repaymentAmount ||
      manual.productRepaymentFrequency;
    const hasFavouriteProducts =
      this.favouriteProducts && this.favouriteProducts.length;

    this.showManualFields = !!(hasManualFieldData && !hasFavouriteProducts);
  }

  hasRealEStateAsset() {
    const { listAssets } = this.financialsSharedService;
    const assetsList =
      listAssets &&
      listAssets.length &&
      listAssets[0] &&
      listAssets[0].overview &&
      listAssets[0].overview.FinancialInfo;
    if (!assetsList) return;

    const hasRealEstate = assetsList.filter(asset => asset.CategoryId === 1);
    return !!hasRealEstate;
  }

  getClientType() {
    const applicantGuarantors =
      this.applicantGuarantorList &&
      this.applicantGuarantorList.InvolvedPartyPerson;
    return applicantGuarantors && Object.keys(applicantGuarantors).length
      ? CLIENT_TYPE.PERSONAL
      : CLIENT_TYPE.COMMERCIAL;
  }

  getIFinanceFieldsStatus() {
    const filter = this.iFinanceFilters;
    const completedRequiredLoanFacility = !!(
      filter.financeCategory &&
      filter.totalLoanAmount &&
      filter.loanTerm &&
      filter.residualPercent
    );
    const completedRequiredAssetSecurity = !!(
      filter.assetSecurityType && filter.purchasePrice
    );
    return completedRequiredLoanFacility && completedRequiredAssetSecurity;
  }

  getFacilityFavouriteProductList(brokerEventId, loanStructureId) {
    if (!brokerEventId || !loanStructureId) return;
    this.loanProfilerService
      .getSelectedProductDetailsGetByLoanStructure(brokerEventId)
      .then(({ data }) => {
        if (!data) return;

        const loanStructureSelectedProduct = data.find(
          element => loanStructureId === element.LoanStructureID
        );
        if (!loanStructureSelectedProduct) return;
        this.favouriteProducts = loanStructureSelectedProduct.SelectedProduct.map(
          assetFinanceFavouriteProductBuilderForUI
        );

        if (this.favouriteProducts.length) {
          this.showFavouriteProducts = true;
          this.showManualFields = false;
        }
      });
  }

  getRepaymentFrequencyList() {
    this.optionsService.frequency().then(response => {
      if (!response || !response.data || !response.data.length) return;
      this.repaymentFrequencyList = response.data;

      this.selectedFrequency = this.repaymentFrequencyList.find(
        o =>
          parseInt(o.Value, 10) ===
          this.manualIFinance.productRepaymentFrequency
      );
      if (!this.selectedFrequency) return;
      this.selectedFrequency.initial = this.selectedFrequency.Name.charAt(0);
    });
  }

  addManually() {
    this.showManualFields = true;
    this.productFinderLabel = 'Product Details';
  }

  displayFrequencyOptions(flag) {
    this.showFrequencyOptions = flag;
  }

  selectFrequency(frequency) {
    this.showFrequencyOptions = false;
    this.selectedFrequency = frequency;
    this.selectedFrequency.initial = frequency.Name.charAt(0);
    this.manualIFinance.productRepaymentFrequency = frequency.Value;
    this.saveManualIFinance();
  }

  saveManualIFinance() {
    const manual =
      this.manualIFinance && manualIFinanceBuilderForMyCRM(this.manualIFinance);
    const postData = {
      ...this.facility,
      loanScenarioId: this.loanAppId,
      AssetFinance: manual,
    };

    this.loanScenarioService.loanFacilitySet(postData);
  }

  setBrokerageValue(isPercent) {
    const minPercentage = 0;
    const maxPercentage = 100;
    const { TotalLoanAmount: baseAmount } = this.facility;
    const maxAmount = getCalculatedAmount(baseAmount, maxPercentage);
    const minAmount = getCalculatedAmount(baseAmount, minPercentage);

    if (isPercent) {
      this.manualIFinance.brokerageAmount = validateRange(
        this.manualIFinance.brokerageAmount,
        minAmount,
        maxAmount
      );
      this.manualIFinance.brokeragePercent = getCalculatedPercentage(
        baseAmount,
        this.manualIFinance.brokerageAmount
      ).toFixed(2);
    } else {
      this.manualIFinance.brokeragePercent = validateRange(
        this.manualIFinance.brokeragePercent,
        minPercentage,
        maxPercentage
      );
      this.manualIFinance.brokerageAmount = getCalculatedAmount(
        baseAmount,
        this.manualIFinance.brokeragePercent
      ).toFixed(2);
    }

    this.saveManualIFinance();
  }

  launchQuickProductFinder(facility) {
    if (!this.completedIFinanceFields) return;
    const props = {
      loanFacility: facility,
      ifinanceFilters: this.iFinanceFilters,
      availableFunds: {
        tradeIn: this.getAvailableFunds('Trade-In'),
        deposit: this.getAvailableFunds('Deposit Paid'),
      },
    };

    const quickFinderModal = this.$uibModal.open({
      template: `<asset-finance-quick-product-finder
                  modal-instance="vm.modalInstance"
                  loan-facility="vm.props.loanFacility"
                  ifinance-filters="vm.props.ifinanceFilters"
                  available-funds="vm.props.availableFunds">
                </asset-finance-quick-product-finder>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      size: 'wide',
      backdrop: 'static',
      windowClass: 'asset-finance-quick-product-finder',
      keyboard: false,
      resolve: {
        props,
      },
    });

    quickFinderModal.result.then(() => {
      this.getFacilityFavouriteProductList(
        this.facility.BrokerEventId,
        this.facility.shortLoanStructureId
      );
    });
  }

  getAvailableFunds(type) {
    const funding = this.fundsAvailable.find(
      fund => fund.fundingParticulars === type
    );
    return funding && funding.fundingValue;
  }

  setSelectedFavouriteProduct(product) {
    this.favouriteProducts.forEach(item => {
      item.isRecommended =
        parseInt(item.productId, 10) === parseInt(product.productId, 10)
          ? !product.isRecommended
          : false;
      const postData = {
        BrokerEventID: Number(this.facility.BrokerEventId),
        LoanStructureID: Number(this.facility.shortLoanStructureId),
        ProductID: item.productId,
        IsRecommended: item.isRecommended,
        IsToolTipShown: false,
      };

      this.loanProfilerService
        .setFavouriteProductRecommendation(postData)
        .then(response => {
          if (response.data === 1) {
            if (item.productId === product.productId)
              this.getFacilityFavouriteProductList(
                this.facility.BrokerEventId,
                this.facility.shortLoanStructureId
              );
          } else {
            product.isRecommended = !product.isRecommended;
            toastError();
          }
        });
    });
  }

  deleteFavoriteProduct(product, event) {
    if (event) event.stopPropagation();

    const postData = {
      brokerEventID: this.facility.BrokerEventId,
      ProductID: product.productId,
      LenderID: 0,
      loanStructureID: this.facility.shortLoanStructureId,
      IsFavourite: false,
      assetFinanceProduct: product.assetFinanceProduct,
    };

    this.loanProfilerService.setFavouriteProduct(postData).then(response => {
      if (response.data === 1) {
        this.getFacilityFavouriteProductList(
          this.facility.BrokerEventId,
          this.facility.shortLoanStructureId
        );
      } else {
        toastError();
      }
    });
  }
}

export default assetFinanceLoanFacility;
