export function getValueOption(widgetOptionId, data) {
  return data.find(item => item.WidgetOptionId === widgetOptionId) || {};
}

export function getCurrentCarouselData(isNext, index, list) {
  if (!list || !list.length) return {};
  const newNextIndex = index === list.length - 1 ? 0 : index + 1;
  const newPrevIndex = index === 0 ? list.length - 1 : index - 1;

  return list[isNext ? newNextIndex : newPrevIndex];
}

export function parseMultiSelectTicks(selection, data) {
  const multipleSelectedVal =
    selection &&
    selection.SelectedUerValues &&
    selection.SelectedUerValues.split(',');
  const hasSelection = multipleSelectedVal && multipleSelectedVal.length;
  const parsedSelection = hasSelection
    ? data.map(item => ({
        ...item,
        ticked: multipleSelectedVal.indexOf(`${item.value}`) !== -1 || false,
      }))
    : data;

  return parsedSelection;
}
