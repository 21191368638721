class ContactSplitModal {
  constructor($state, $timeout, $uibModal, contactModelService) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.contactModelService = contactModelService;
  }

  $onInit() {
    this.showWarningSection = true;
    this.showConfirmSection = false;
    this.showSplitDoneSection = false;
    this.showLoader = false;
    this.isCheckedTerms = false;
    this.isCopyToBoth = false;
    this.checkParameters();
  }

  $onDestroy() {
    this.showLoaderTimeout && this.$timeout.cancel(this.showLoaderTimeout);
  }

  checkParameters() {
    if (this.familyId || this.adults || this.adults.length) return;
    this.closeModal();
  }
  toggleIsCopyToBoth() {
    this.isCopyToBoth = !this.isCopyToBoth;
  }

  confirmSplitWarning() {
    if (this.showConfirmSection) return;
    this.showLoader = true;
    this.showLoaderTimeout = this.$timeout(() => {
      this.showLoader = false;
      this.showConfirmSection = true;
      this.showWarningSection = false;
    }, 2000);
  }
  updateSelectedPrimary() {
    if (!this.isPrimary) return;
    this.primaryAdultDetails = this.adults.find(
      client => client.PersonId === this.isPrimary
    );
    this.secondAdultDetails = this.adults.find(
      client => client.PersonId !== this.isPrimary
    );
  }
  splitContact() {
    if (!this.isCheckedTerms || !this.isPrimary) return;
    this.showLoader = true;
    const postData = {
      familyId: this.familyId,
      primaryClientId: this.isPrimary,
      duplicateCommunication: this.isCopyToBoth,
      duplicateTask: this.isCopyToBoth,
    };
    this.contactModelService.contactSplitSet(postData).then(response => {
      if (!response) return;
      const { data } = response;
      const { Data: separatedContact } = data;
      this.separatedContact = separatedContact;
      this.showLoaderTimeout = this.$timeout(() => {
        this.showLoader = false;
        this.showSplitDoneSection = true;
        this.showConfirmSection = false;
      }, 2000);
    });
  }

  redirectSecondary() {
    this.$state.go('app.contactsSingle', {
      familyId: this.separatedContact.NewSeparatedFamilyID,
    });
    this.closeModal();
  }

  redirectContactPage() {
    this.$state.go('app.contacts');
    this.closeModal();
  }

  closeModal(refreshScope = false) {
    this.modalInstance.close(refreshScope);
  }
}
export default ContactSplitModal;
