export const isChangeStatusToRequestReceived = (
  oldCheckedAdviserList,
  newCheckedAdviserList
) => {
  const checks =
    oldCheckedAdviserList &&
    oldCheckedAdviserList.length &&
    (newCheckedAdviserList && newCheckedAdviserList.length);
  if (!checks) return null;

  return oldCheckedAdviserList.length !== newCheckedAdviserList.length;
};
