import template from './fieldsAnticipatedChanges.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    answerObject: '<',
    descriptionList: '<',
    durationList: '<',
    financialImpactList: '<',
    datePickerConfig: '<',
    onAdd: '&',
  },
};
