import { CONTACT_TYPE } from 'Common/constants/contactType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';
import { TEAM_ACCESS_LEVEL } from 'Common/constants/accessTypes';
import { toastSuccess, toastError } from 'Common/utilities/alert';
import {
  getFormattedPartyData,
  getPartyDetailsByClientId,
} from 'Common/utilities/onboarding';
import {
  getConcatenatedRoles,
  formatAssistantData,
} from './util/onboardingAdditionalStaffCtrl';

class OnboardingAdditionalStaff {
  constructor(
    $stateParams,
    commonFnService,
    currentUserService,
    onboardingService,
    onboardingDataService,
    corporateModelService
  ) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.commonFnService = commonFnService;
    this.currentUserService = currentUserService;
    this.onboardingService = onboardingService;
    this.onboardingDataService = onboardingDataService;
    this.corporateModelService = corporateModelService;
    this.CONTACT_TYPE = CONTACT_TYPE;

    this.editing = { status: false, partyType: '', index: -1 };
    this.tabValue = 'additionalStaff';
  }

  setToInt(value) {
    return this.commonFnService.setBooleanToInt(value);
  }

  getNewPartyIndex(partyType) {
    switch (partyType) {
      case this.CONTACT_TYPE.LOAN_WRITER_STR:
        if (!this.loanWritersList) return 1;
        return this.loanWritersList.length
          ? this.loanWritersList.length + 1
          : 1;
      case this.CONTACT_TYPE.ASSISTANT_STR:
        if (!this.assistantsList) return 1;
        return this.assistantsList.length ? this.assistantsList.length + 1 : 1;
      default:
        return 1;
    }
  }

  showParty(type, idx) {
    const { status, partyType, index } = this.editing;
    if (!status) return true;
    const currEditingParty = partyType === type && index === idx;
    return currEditingParty;
  }

  showNewPartyComponent(show) {
    this.showNewParty = show;
    this.hideButtons = show;
  }

  showUnsavedWarning() {
    return this.onboardingDataService.hasUnsavedChanges(this.tabValue);
  }

  isSaveOnlyNoInvite() {
    return this.partyToAdd === CONTACT_TYPE.ASSISTANT_STR;
  }

  getAssistants() {
    this.onboardingService
      .getAdviserAssistants(this.familyId)
      .then(({ data }) => {
        const retrievedData = data || [];
        this.assistantsList = retrievedData;
        this.formattedAssistants = retrievedData.map(formatAssistantData);
      });
  }

  getLoanWriters() {
    this.onboardingService
      .getRecruitmentContact(
        this.familyId,
        this.onboardingDataService.getClientId()
      )
      .then(({ data }) => {
        if (!data || !data.length) return;
        this.loanWritersList = data.filter(contact => {
          const roleIds = contact.RoleId.split(',');
          const loanWriterId = ONBOARDING_ROLE_ID.LOAN_WRITER.toString();
          const onboardingUser = contact.FamilyId.toString() === this.familyId;
          return roleIds.includes(loanWriterId) && !onboardingUser;
        });
        this.formattedLoanWriters = this.loanWritersList.map(
          getFormattedPartyData
        );
      });
  }

  addParty(partyType) {
    this.saveCompleted = false;
    this.partyToAdd = partyType;
    this.showNewPartyComponent(true);
  }

  cancelPartyAddition() {
    this.showNewPartyComponent(false);
    this.onboardingDataService.setUnsavedChanges(this.tabValue, false);
  }

  saveToRecruitment(staff, partyType) {
    const detail = {
      ClientID: 0,
      FamilyId: 0,
      FirstName: staff.firstName,
      LastName: staff.lastName,
      Email: staff.email,
      ContactType: CONTACT_TYPE.ADVISER_STR,
      RoleId: getConcatenatedRoles(staff, partyType),
      AdviserOrganizationId: this.currentUserService.userAdviserOrgId,
      isWriting: this.setToInt(staff.isWritingValue),
    };

    this.onboardingService
      .setRecruitmentContact(this.familyId, detail)
      .then(({ data }) => {
        detail.clientID = data;
        return this.onboardingService.getRecruitmentContact(0, detail.clientID);
      })
      .then(({ data }) => {
        if (!data || !data.length)
          return Promise.reject(new Error(`Error in saving ${partyType}`));
        detail.partnerFamilyId = data[0] && data[0].FamilyId;

        return this.onboardingService.familyPartnershipSet(
          this.familyId,
          detail.partnerFamilyId,
          detail.clientID,
          detail.isWriting,
          this.setToInt(partyType === CONTACT_TYPE.BUSSINESS_PARTNER),
          this.setToInt(partyType === CONTACT_TYPE.DIRECTOR),
          this.setToInt(partyType === CONTACT_TYPE.SHARE_HOLDER),
          1,
          0
        );
      })
      .then(() => {
        toastSuccess(`${partyType} successfully saved`);
        this.getLoanWriters();
        this.saveCompleted = true;
        this.showNewPartyComponent(false);
        this.resetEditStatus();
      })
      .catch(error => {
        toastError(error.message);
        this.saveCompleted = true;
        this.showNewPartyComponent(false);
        this.resetEditStatus();
      });
  }

  saveAssistant(staff, partyType) {
    const detail = {
      ClientID: 0,
      FamilyId: 0,
      FirstName: staff.firstName,
      PreferredName: staff.firstName,
      LastName: staff.lastName,
      Email: staff.email,
      ContactType: CONTACT_TYPE.ASSISTANT_STR,
      ReqdTeamAcessLevel: TEAM_ACCESS_LEVEL.FULL_SYSTEM,
      AdviserOrganizationId: this.currentUserService.userAdviserOrgId,
      IsActive: 0,
    };

    this.onboardingService
      .setRecruitmentContact(this.familyId, detail)
      .then(({ data }) => {
        if (!data) return;
        toastSuccess(`${partyType} successfully saved`);
        this.getAssistants();
        this.saveCompleted = true;
        this.showNewPartyComponent(false);
        this.resetEditStatus();
      });
  }

  saveParty(staff, partyType) {
    if (partyType === CONTACT_TYPE.ASSISTANT_STR) {
      this.saveAssistant(staff, partyType);
      return;
    }
    this.saveToRecruitment(staff, partyType);
  }

  editParty(index, partyType) {
    this.editing = { status: true, partyType, index };
    this.hideButtons = true;
  }

  resetEditStatus() {
    this.editing = { status: false, partyType: '', index: -1 };
    this.hideButtons = false;
    this.onboardingDataService.setUnsavedChanges(this.tabValue, false);
  }

  updateParty(updatedDetails, partyType) {
    this.onboardingService
      .setRecruitmentContact(this.familyId, updatedDetails)
      .then(() => {
        toastSuccess(`${partyType} successfully saved`);
        this.resetEditStatus();
        if (partyType === CONTACT_TYPE.LOAN_WRITER_STR) this.getLoanWriters();
        if (partyType === CONTACT_TYPE.ASSISTANT_STR) this.getAssistants();
      })
      .catch(error => {
        toastError(error.message);
        this.resetEditStatus();
      });
  }

  updateLoanWriter(party, partyType) {
    const partyList = this.loanWritersList;
    const partyDetails = getPartyDetailsByClientId(partyList, party.clientId);
    const updatedDetails = {
      ...partyDetails,
      FirstName: party.firstName,
      MiddleName: party.middleName,
      LastName: party.lastName,
      Mobile: party.mobile,
      Email: party.email,
      RoleId: getConcatenatedRoles(party, partyType),
      isWriting: this.setToInt(party.isWritingValue),
    };
    this.updateParty(updatedDetails, partyType);
  }

  updateAssistant(party, partyType) {
    const assistantDetails = this.assistantsList.find(assistant => {
      return assistant.FamilyID === party.familyId;
    });
    const updatedDetails = {
      ...assistantDetails,
      FirstName: party.firstName,
      LastName: party.lastName,
      Email: party.email,
    };
    this.updateParty(updatedDetails, partyType);
  }

  deletePartyParnership(party, partyType) {
    this.onboardingService
      .familyPartnershipSet(
        this.familyId,
        party.familyId,
        party.clientId,
        party.isWritingValue,
        party.isBusinessPartner,
        party.isCompanyDirector,
        party.isShareHolder,
        0,
        party.familyPartnershipId
      )
      .then(() => {
        toastSuccess(`${partyType} successfully removed!`);
        this.getLoanWriters();
      });
  }

  deleteAssistant(assistant, partyType) {
    this.corporateModelService
      .deleteAdditionalStaff(assistant.familyId)
      .then(() => {
        toastSuccess(`${partyType} successfully removed!`);
        this.getAssistants();
      });
  }

  $onInit() {
    this.familyId = this.$stateParams.familyId;
    this.getLoanWriters();
    this.getAssistants();
  }
}

export default OnboardingAdditionalStaff;
