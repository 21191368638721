import { displayError } from 'Common/utilities/alert';
import {
  getInvolvedPartyBasicModel,
  getLoanOpportunityBasicModel,
} from 'Common/utilities/loanOpportunity';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { CONVERSION_PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import ManagePartiesViewCtrl from '../opportunity/opportunityMain/manageParties/managePartiesViewCtrl';

export default class OpportunityModalCtrl {
  constructor(
    loanOpportunityService,
    userService,
    $uibModal,
    $stateParams,
    contactService,
    pipelineService,
    loanScenarioService,
    corporateService,
    configService,
    optionsService,
    $q
  ) {
    'ngInject';

    this.opportunity = {};
    this.loanOpportunityService = loanOpportunityService;
    this.userService = userService;
    this.uibModal = $uibModal;
    this.$stateParams = $stateParams;
    this.contactService = contactService;
    this.pipelineService = pipelineService;
    this.loanScenarioService = loanScenarioService;
    this.corporateService = corporateService;
    this.configService = configService;
    this.optionsService = optionsService;
    this.$q = $q;
  }

  $onInit() {
    this.title = `Create New Opportunity`;
    this.description = `What would you like to call this Opportunity?`;
    this.inputPlaceholder = `e.g First Home in Sydney`;
    this.buttonLabel = `Next Step`;

    const { CORPORATE, ASSISTANT, ADMIN_ASSISTANT } = ACCESS_TYPE;
    const CORP_AND_ASSISTANT_TYPES = [CORPORATE, ASSISTANT, ADMIN_ASSISTANT];
    this.isAssetFinanceActive =
      this.configService.feature && this.configService.feature.assetFinance;
    this.getLendingCategories();

    this.userService.GetUserInfo().then(res => {
      const { data } = res;
      if (!data) return;

      const { AccessType, FamilyId } = data;
      let allocatedAdviserID = FamilyId;
      const isUserCorporateOrAssistant =
        CORP_AND_ASSISTANT_TYPES.indexOf(AccessType) !== -1;
      const isUserAssistant =
        AccessType === ASSISTANT || AccessType === ADMIN_ASSISTANT;

      this.assistantId = 0;
      if (isUserAssistant) this.assistantId = FamilyId;
      if (isUserCorporateOrAssistant) {
        if (!this.familyId) return;
        this.contactService
          .getContactAssignedAdviser(this.familyId)
          .then(response => {
            const { data: adviserId } = response;
            if (!adviserId) return;
            allocatedAdviserID = adviserId;
            this.assignOpportunityDetails(allocatedAdviserID);
          });
      } else {
        this.assignOpportunityDetails(allocatedAdviserID);
      }
    });
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  getLendingCategories() {
    this.optionsService.getLendingCategories().then(response => {
      if (!response || !response.length) return;
      this.lendingCategoryList = response;
      this.opportunity.LendingCategoryId = this.isAssetFinanceActive
        ? this.lendingCategoryList[0].categoryId
        : null;
    });
  }

  nextStep() {
    this.isSending = true;
    if (!this.opportunity || !this.opportunity.Title) return;

    if (!this.familyId) {
      this.openManagePartyModal();
      return;
    }
    this.postNewOpportunity();
  }

  postNewOpportunity() {
    if (!this.pipelineItemId) {
      this.loanOpportunityService
        .setLoanOpportunity(this.opportunity)
        .then(({ data }) => {
          if (!data) return;
          const { LoanScenarioID: opportunityId, LoanId: loanId } = data.Loan;
          if (!this.opportunity.isFromManageParties) {
            this.setAssistantAdviser(opportunityId, loanId);
            return;
          }
          this.modalInstance.close({ opportunityId, loanId });
        })
        .catch(err => {
          this.isSending = false;
          displayError(err);
        });
    } else {
      this.pipelineService
        .putConvertToOpportunity(this.pipelineItemId, {
          ...this.opportunity,
          ConversionStatusId: CONVERSION_PIPELINE_STATUS.TO_OPPORTUNITY,
        })
        .then(({ data }) => {
          if (!data) return;

          const { LoanScenarioID: opportunityId, LoanId: loanId } = data;
          this.modalInstance.close({ opportunityId, loanId });
        })
        .catch(err => {
          this.isSending = false;
          displayError(err);
        });
    }
  }

  setAssistantAdviser(loanScenarioID, loanId) {
    const { AllocatedAdviserID } = this.opportunity;
    this.corporateService
      .assignAssistantToClientGet(AllocatedAdviserID, this.familyId)
      .then(response => {
        const { data } = response;
        if (!data || !data.length) return;
        const { AssistantFamilyID: assistantFamilyId } = data[0];
        if (!assistantFamilyId) return;
        this.assistantId = assistantFamilyId;
      })
      .finally(() => {
        if (this.assistantId)
          this.loanScenarioService.assignAssistant(loanScenarioID, {
            assistantFamilyId: this.assistantId,
          });
        this.modalInstance.close({ opportunityId: loanScenarioID, loanId });
      });
  }

  assignOpportunityDetails(allocatedAdviserID) {
    this.opportunity = Object.assign({}, getLoanOpportunityBasicModel(), {
      AllocatedAdviserID: allocatedAdviserID,
      ...this.opportunity,
    });

    this.familyId && this.clients && this.addBasicClients(this.clients);
  }

  addBasicClients(clients) {
    const { Borrowers } = this.opportunity;

    clients.forEach(client => {
      const {
        PersonId: ClientEntityID,
        PreferredName: DisplayName,
        isEntity,
      } = client;

      const basicClient = Object.assign(
        {},
        getInvolvedPartyBasicModel(!isEntity),
        {
          ClientEntityID,
          FamilyID: this.familyId,
          DisplayName,
        }
      );

      Borrowers.push(basicClient);
    });
  }

  openManagePartyModal() {
    this.uibModal
      .open({
        template: `<manage-parties
                  modal-instance="vm.modalInstance"
                  opportunity-id="vm.opportunityId"
                  involved-parties="vm.involvedParties"
                  selected-adviser-id="vm.selectedAdviserId"
                  show-select-adviser="vm.showSelectAdviser"
                >
                </manage-parties>`,
        controller: ManagePartiesViewCtrl,
        windowClass: 'manage-parties-modal',
        controllerAs: 'vm',
        resolve: {
          opportunityId: () => null,
          involvedParties: () => null,
          selectedAdviserId: () => null,
          showSelectAdviser: () => true,
        },
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        result => {
          this.opportunity = { ...this.opportunity, ...result };
          this.postNewOpportunity();
        },
        () => {
          this.modalInstance.dismiss('cancel');
        }
      );
  }
}
