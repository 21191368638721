import template from './loanAppPossibleAdverseChanges.html';
import controller from './loanAppPossibleAdverseChangesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isCompleted: '<',
    isLodged: '<',
    loanAppFormWarning: '<',
    onChangeStatus: '&',
  },
};
