import { isCancelled as subscriptionIsCancelled } from 'Common/utilities/subscription';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { openVideoModal } from 'Common/utilities/trainingVideoModal';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';

export const getInvestment = data => {
  const selectedPurpose = data.filter(item => {
    return item.QuestionId === 5;
  });

  if (selectedPurpose.length) {
    if (!selectedPurpose[0].answerInt) return false;
    const checkInvestment = selectedPurpose[0].answerInt.filter(o => {
      return o === 3;
    });
    return checkInvestment.length > 0;
  }
  return false;
};

export const getEsignActiveUser = (esign, data) => {
  const esignSubscription = data.find(
    obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN
  );
  const isActiveEsign =
    esign &&
    esignSubscription &&
    (esignSubscription.isActive || subscriptionIsCancelled(esignSubscription));
  return isActiveEsign;
};

export const getDateType = dateType => {
  const selectOptions = {
    [`Estimated Settlement Date`]: 'settlementDate',
    [`Finance Date`]: 'financeDate',
    [`Auction Date`]: 'auctionDate',
  };
  const type = dateType ? selectOptions[dateType] : '';
  return type;
};

export const getAssignedAdviser = (brokersList, assignedAdviserId) => {
  const assignedAdviser = brokersList.find(object => {
    return object.BrokerId === assignedAdviserId;
  });
  return assignedAdviser;
};

export const openDemoVideoModal = (size, $uibModal, currentUserService) => {
  if (currentUserService.isNZ) {
    openVideoModal($uibModal, PAGE_SOURCE.LOAN_APP_1);
    return true;
  }

  $uibModal.open({
    templateUrl: '/assets/views/partials/demo_multi_video_modal.html',
    controller: 'LoanAppVideoDemoCtrl',
    size,
    windowClass: 'demo-video-modal-window-class multi-demo-video',
  });
  return false;
};
