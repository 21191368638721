import SaveToContactCtrl from 'Components/insurancesSaveToContact/saveToContactCtrl';

export const openSaveContactModal = async ({ scope, callback }) => {
  const saveToContactCtrl = new SaveToContactCtrl();
  saveToContactCtrl.$uibModal = scope.$uibModal;
  saveToContactCtrl.formData = scope.formData;
  saveToContactCtrl.toaster = scope.toaster;
  await callback();
  await saveToContactCtrl.clickListener('save');
  return true;
};

export const reportPdf = async ({ scope }) => {
  if (scope.showSaveToContactButton) {
    await openSaveContactModal({
      scope,
      callback: () => {
        scope.generateReportPDF();
      },
    });
  } else {
    scope.generateReportPDF();
  }
};
