import { CALCULATION_TYPE } from 'Common/constants/calculations';
import { pluralize } from 'Common/utilities/string';
import { getLenderLogoImg } from 'Common/utilities/image';

export default class OpportunityCalculationItem {
  constructor(opportunityCalculationService) {
    'ngInject';

    this.opportunityCalculationService = opportunityCalculationService;
    this.CALCULATION_TYPE = CALCULATION_TYPE;
  }
  $onInit() {
    this.pluralize = pluralize;

    this.isProductComparison = !!(
      this.calculation.typeOfCalculation === CALCULATION_TYPE.PRODUCT_FINDER &&
      this.calculation.productFinder &&
      this.calculation.productFinder.lenders &&
      this.calculation.productFinder.lenders.length
    );

    if (this.isProductComparison) this.setLenderLogoList();
  }

  getTitle() {
    if (this.isProductComparison) {
      return this.opportunityCalculationService.getCalculationTitle(
        CALCULATION_TYPE.PRODUCT_COMPARISON
      );
    }

    return this.opportunityCalculationService.getCalculationTitle(
      this.calculation.typeOfCalculation
    );
  }

  setLenderLogoList() {
    if (!this.isProductComparison) return '';
    const lenders =
      (this.calculation.productFinder &&
        this.calculation.productFinder.lenders) ||
      [];
    this.lenderLogoList =
      lenders.reduce((accum, lender) => {
        return `${accum}${getLenderLogoImg(lender)}`;
      }, '') || '';
  }
}
