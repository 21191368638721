export function mergeList(...lists) {
  if (!lists) return [];
  const mergeListItems =
    lists.reduce((accum, list) => {
      return [...accum, ...list];
    }, []) || [];
  return mergeListItems;
}

export function spliceArray(item, list, howmany) {
  list.splice(item, howmany);
  return true;
}

export function removeFromArray(item, list) {
  const index = list.indexOf(item);
  if (index > -1) {
    spliceArray(index, list, 1);
  }
  return true;
}
