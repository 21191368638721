import angular from 'angular';
import _ from 'lodash';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { REFERRAL_LABEL, REFERRAL_TYPE } from 'Common/constants/referralType';
import { COLOR } from 'Common/constants/colors';
import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import { formatDateFilter } from 'Common/utilities/date';
import {
  getSendReferralFilter,
  getReferralTypeDetails,
} from 'Common/utilities/referral';
import { isMobileView } from 'Common/utilities/clientWindowWatcher';

const FILTER_FIELD = {
  STATUS: 'status',
  TYPE: 'type',
  SEND_TO: 'adviser',
};

angular
  .module('app')
  .controller('SendReferralsCtrl', function SendReferralsCtrl(
    $localStorage,
    $uibModal,
    $timeout,
    $window,
    $filter,
    $state,
    $scope,
    sendReferralService,
    configService,
    NgTableParams,
    userService,
    toaster
  ) {
    // MODELS
    const PAGE_SIZE = 15;
    const isWealthMarketNotesOn = !!configService.feature.wealthMarketNotes;

    angular.extend($scope, {
      filterField: FILTER_FIELD,
      userInfo: {},
      agencyList: [],
      familyAdvList: [],
      adviceTypeList: [],
      filteredList: [],
      sentToList: [],
      sentReferralsList: [],
      _filterShow: false,
      _isClickedSendBtn: false,
      queryFilter: {
        referralTypeId: '0',
        sendTo: '0',
        dateCreated: {},
        dateUpdated: {},
      },
      statusList: [],
      pagination: {
        _page: 0,
        maxSize: 5,
        itemsPerPage: PAGE_SIZE,
        currentPage: 1,
        totalItems: 0,
      },
      searchingContacts: false,
      NOTES_CHAR_LIMIT: 60,
      isFetchingResults: false,
      letterIconColor: COLOR.TRENDY_PINK,
      referralDateFormat: DATE_TEXT_FORMAT,
      showWealthMarketFilters:
        !isMobileView($window.innerWidth) && isWealthMarketNotesOn,
      showWealthMarketMobileFilters:
        isMobileView($window.innerWidth) && isWealthMarketNotesOn,
    });

    const populateSendToOption = () => {
      sendReferralService
        .referralSendToListGet($scope.queryFilter.referralTypeId)
        .then(({ data }) => {
          $scope.sentToList = data || [];
        });
    };

    $scope.getSentReferralList = () => {
      $scope.loadReferralData($scope.pagination.currentPage);
    };

    $scope.loadReferralData = currentPage => {
      $scope.isFetchingResults = true;
      $scope.filteredList = [];
      const filter = {
        ...$scope.queryFilter,
        pageNumber: currentPage,
      };

      sendReferralService
        .referralReferralListGet(filter)
        .then(data => {
          $scope.isFetchingResults = false;

          if (data && data.length) {
            $scope.sentReferralsList = data;
            $scope.pagination.totalItems = data[0].TotalCount;
            $scope.filteredList = $scope.sentReferralsList;

            $scope.tableParams = new NgTableParams(
              {
                page: 1,
                count: $scope.pagination.itemsPerPage, // count per page
                sorting: {
                  Default: 'desc', // initial sorting
                },
              },
              {
                counts: [],
                total: $scope.pagination.totalItems, // length of data
                getData($defer, params) {
                  let filterData = angular.copy($scope.filteredList);

                  filterData = params.sorting()
                    ? $filter('orderBy')(filterData, params.orderBy())
                    : filterData;
                  filterData = filterData.slice(
                    (params.page() - 1) * params.count(),
                    params.page() * params.count()
                  );
                  $defer.resolve(filterData);
                },
              }
            );
          } else {
            $scope.sentReferralsList = [];
            $scope.filteredList = [];
            $scope.pagination.totalItems = 0;
            $scope.tableParams = new NgTableParams();
            toaster.pop('info', 'No record found');
          }
        })
        .catch(() => {
          $scope.isFetchingResults = false;
        });
    };

    const filterResults = (method, filterObj) => {
      const filter = getSendReferralFilter(method, filterObj, PAGE_SIZE);
      $scope.queryFilter = {
        ...$scope.queryFilter,
        ...filter,
      };
      $scope.getSentReferralList();
    };

    const loadDefaultReferralView = () => {
      const { referralTypeId, referralId } = $state.params;
      const referralDetails = getReferralTypeDetails(referralTypeId);
      const isWMReferral =
        referralDetails.ReferralTypeID === REFERRAL_TYPE.WEALTH_MARKET;
      const referral = { ReferralID: referralId, ...referralDetails };

      referralId && isWMReferral && $scope.referralDetails(referral);
    };

    const loadWealthMarketView = referral => {
      if (isMobileView($window.innerWidth)) {
        const { ReferralID: referralId, Contact: contact } = referral;
        $state.go('app.mobileReferralDetails', { referralId, contact });
        return;
      }

      const props = { referral };
      $uibModal.open({
        template: `<referral-modal modal-instance="vm.modalInstance" referral="vm.props.referral"></referral-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        resolve: {
          props: () => props,
        },
        controllerAs: 'vm',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        windowClass: 'referral-modal',
      });
    };

    $scope.referralDetails = referral => {
      if (referral.ReferralType === REFERRAL_LABEL.WEALTH_MARKET) {
        loadWealthMarketView(referral);
        return;
      }

      $uibModal.open({
        templateUrl: '/assets/views/referrals/modal/referral.details.html',
        backdrop: false,
        keyboard: false,
        controller: 'ReferralDetailsModalCtrl',
        resolve: {
          referral: () => referral,
        },
      });
    };

    const initSendReferrals = () => {
      // get list of referral types
      sendReferralService.referralReferralTypeGet().then(response => {
        const agencies = angular.copy(response.data);

        if (agencies && agencies.length > 0) {
          userService.GetUserInfo().then(user => {
            $scope.userInfo = user.data;

            // filter for AU users
            if (parseInt($scope.userInfo.CountryId, 10) === 2) {
              $scope.agencyList = _.filter(agencies, function agency(data) {
                return (
                  parseInt(data.ReferralTypeID, 10) !== 2 &&
                  parseInt(data.ReferralTypeID, 10) !== 3 &&
                  parseInt(data.ReferralTypeID, 10) !== 4
                );
              });
            } else $scope.agencyList = agencies;
          });
        }
      });

      // get list of status names
      sendReferralService.getStatusList().then(response => {
        $scope.statusList = response.data;
      });

      // initial filtering
      filterResults('', {});
      loadDefaultReferralView();
      populateSendToOption();
    };

    // run initializer
    initSendReferrals();

    $scope.setFilters = (method, filterObj) => {
      if (method === $scope.filterField.TYPE) populateSendToOption();
      filterResults(method, filterObj);
    };

    $scope.contactInfo = id => {
      if (!_.isUndefined(id) && id !== 0) {
        $state.go('app.contactsSingle', { familyId: id, activeTab: 'summary' });
      } else
        toaster.pop('info', 'Notice', 'You have selected a non MyCRM client');
    };

    $scope.viewNotes = data => {
      if (!data.WithNote) {
        toaster.pop('info', 'Notice', 'No Notes to view.');
      } else if (data.FamilyID) {
        $state.go('app.contactsLoans', {
          familyId: data.FamilyID,
          activeTab: 'notes',
        });
      } else
        toaster.pop('info', 'Notice', 'You have selected a non MyCRM client');
    };

    $scope.setReferral = refType => {
      $scope.newReferral(refType, $scope.agencyList);
    };

    $scope.newReferral = (refType, agencyList) => {
      $scope.refType = refType;
      $scope.agencyList = agencyList;
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/referrals/modal/referral.main.html',
        backdrop: false,
        keyboard: false,
        windowClass: 'newReferral-modal',
        controller: 'SendReferralModalCtrl',
        scope: $scope,
      });

      modalInstance.result.then(() => {
        $scope._isClickedSendBtn = false;
        const newReferralTimeout = $timeout(() => {
          $scope.pagination.currentPage = 1;
          populateSendToOption();
          $scope.getSentReferralList();
        }, 2000);
        $scope.$on('$destroy', () => {
          $timeout.cancel(newReferralTimeout);
        });
      });
    };

    if ($localStorage.xeAutoSend && $localStorage.xeAutoSend.newReferral) {
      $timeout(() => {
        $scope.xeAutoSend = $localStorage.xeAutoSend;
        $scope.setReferral($scope.xeAutoSend.referralType);

        $localStorage.xeAutoSend = {};
      }, 2000);
    }

    $scope.selectedRange = {
      dateCreated: { isOpen: false, activeRangeIndex: null },
      dateUpdated: { isOpen: false, activeRangeIndex: null },
    };
    $scope.filterRange = PICKER_RANGE;

    $scope.rangeSelected = (dateType, activeRangeIndex) => {
      const isNonRangeIndex = activeRangeIndex !== PICKER_RANGE.CUSTOM;
      const isPickerCleared = !activeRangeIndex && activeRangeIndex !== 0;
      const dateTypeSelected = $scope.selectedRange[dateType];
      dateTypeSelected.activeRangeIndex = activeRangeIndex;

      if (isPickerCleared || isNonRangeIndex) {
        dateTypeSelected.isOpen = false;
      }
    };

    $scope.calendarUpdated = (dateType, filterData) => {
      if (!filterData) return;

      const dateTypeSelected = $scope.selectedRange[dateType];
      const isTodaySelected =
        dateTypeSelected &&
        dateTypeSelected.activeRangeIndex === PICKER_RANGE.TODAY;
      const isCustomSelected =
        dateTypeSelected &&
        dateTypeSelected.activeRangeIndex === PICKER_RANGE.CUSTOM;
      const { DateStart: dateStart, DateEnd: dateEnd } = filterData;
      const displayFilterDate = formatDateFilter(
        dateStart,
        dateEnd,
        isTodaySelected
      );

      if (dateTypeSelected) {
        dateTypeSelected.displayFilterDate = displayFilterDate;
      }

      if ($scope.queryFilter[dateType]) {
        $scope.queryFilter[dateType] = {
          ...$scope.queryFilter[dateType],
          displayFilterDate,
          dateStart,
          dateEnd,
        };
      }

      const isCustomRangeComplete = isCustomSelected && dateStart && dateEnd;
      if (isCustomRangeComplete) {
        $scope.selectedRange[dateType].isOpen = false;
      }

      if (!$scope.isFetchingResults) $scope.getSentReferralList();
    };

    $scope.onDatePickerToggle = () => {
      const isDatePickerClosed = !(
        $scope.selectedRange.dateUpdated.isOpen ||
        $scope.selectedRange.dateCreated.isOpen
      );
      if (isDatePickerClosed) $scope.getSentReferralList();
    };

    $scope.getLogoPath = logo => {
      const path = `assets/images/referrals/referrers/${logo}.png`;
      return path;
    };

    $scope.showNoteModal = (note, event) => {
      const props = {
        note,
      };
      $uibModal.open({
        template: `<note-modal modal-instance="vm.modalInstance" note="vm.props.note" close-modal="() => vm.modalInstance.close()"></note-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        resolve: {
          props: () => props,
        },
        controllerAs: 'vm',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      });
      if (event) event.stopPropagation();
    };
  });
