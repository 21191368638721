import template from './opportunityDetails.html';
import controller from './opportunityDetailsCtrl';

export default {
  template,
  bindings: {
    involvedParties: '<',
    onRefreshDetails: '&',
    opportunityId: '<',
    opportunityTitle: '<',
    summary: '<',
    selectedAdviserId: '<',
    onAdviserChange: '&',
    loanId: '<',
    opportunityGeneral: '<',
    onChangeGeneralInfo: '&',
  },
  controller,
  controllerAs: 'vm',
};
