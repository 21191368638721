export const SMS_AND_EMAIL_PATTERN =
  '([^[]+)(?:\\[smsBody\\]([^[]+))?(?:\\[emailBody\\]([^[]+))?';
export const EMAIL_PATTERN = {
  USERNAME: '^[_A-z0-9.]*$',
};
export const AU_MOBILE_PATTERNS = {
  DEFAULT_FORMAT: /^(\+?61[\s]?|0)4(\d{2})[\s]?(\d{3})[\s]?(\d{3})$/,
};
export const NZ_MOBILE_PATTERNS = {
  FORMAT_START01: /^(\+?64[\s]?|0)1(\d{7})$/,
  FORMAT_START02: /^(\+?64[\s]?|0)2(\d{1})[\s]?(((\d{3,4})[\s]?(\d{3,4}))|((\d{4})[\s]?(\d{4})))$/,
  FORMAT_START020: /^(\+?64[\s]?|0)20(\d{1})[\s]?(((\d{2})[\s]?(\d{3}))|((\d{3})[\s]?(\d{3,4})))$/,
};
