export default class RecipientsListCtrl {
  validateInputs() {
    const hasNamesAndInitials = this.recipientsChoices.filter(
      o => o && o.name && o.initials
    );
    if (!hasNamesAndInitials)
      this.setErrorMessage('Invalid recipients choices.');
  }

  setErrorMessage(msg) {
    if (msg) this.validationMessage += ` ${msg}`;
  }

  $onChanges() {
    this.updateRecipientsList();
  }

  updateRecipientsList() {
    this.recipients = this.selectedRecipients
      ? [...this.selectedRecipients]
      : [];
  }

  onRemove(recipient) {
    this.onRemoveRecipient && this.onRemoveRecipient({ recipient });
    this.updateRecipientsList();
  }
}
