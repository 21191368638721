import { appendEmptySelect } from 'Common/utilities/options';
import { DEFERRED_STATUS_ID } from 'Common/constants/insuranceApplicationPipeline';
import { DD_MM_YYYY_FORMAT, UI_MASK_DATE } from 'Common/constants/dateConfigs';

export default class OverViewCtrl {
  constructor(insuranceFireAndGeneralService) {
    'ngInject';

    this.insuranceFireAndGeneralService = insuranceFireAndGeneralService;
  }

  $onInit() {
    this.loadStatusAndFrequency();
    this.DD_MM_YYYY_FORMAT = DD_MM_YYYY_FORMAT.toLowerCase();
    this.UI_MASK_DATE = UI_MASK_DATE;

    this.DEFERRED_STATUS_ID = DEFERRED_STATUS_ID.V1;
  }

  loadStatusAndFrequency() {
    this.insuranceFireAndGeneralService
      .insuranceTempFrequencyList()
      .then(response => {
        this.tempFrequencyList = response;
      });

    this.insuranceFireAndGeneralService.insuranceStatusGet().then(response => {
      this.statusList = appendEmptySelect(response, {
        statusId: 0,
        statusName: 'Select Status',
      });
    });

    this.loadDefault();
    this.getOverviewValues();
  }

  getOverviewValues() {
    this.overViewForm({ $e: { overViewForm: this.insuranceData } });
  }

  loadDefault() {
    if (!this.insuranceData) {
      this.insuranceData = {
        statusId: 0,
        paymentFrequencyId: 0,
      };
    }
  }
}
