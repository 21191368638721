import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';
import { PRIMARY_PURPOSE_VALUE } from 'Common/constants/securityValues';

export function isEmploymentIncome(incomeId) {
  const defaultEmploymentIncome = [
    DEFAULT_INCOME_TYPES.SALARY,
    DEFAULT_INCOME_TYPES.BONUS,
    DEFAULT_INCOME_TYPES.COMMISSION,
  ];
  return defaultEmploymentIncome.indexOf(parseInt(incomeId, 10)) !== -1;
}

export function checkIfRemoveIncome(params) {
  const { object, isNZ, incomeInfoDeleteFunction } = params;

  if (!object || !incomeInfoDeleteFunction) return;
  const { incomeList, primaryPurposeId, loanAppId } = object;
  const isRemoveIncome =
    primaryPurposeId === PRIMARY_PURPOSE_VALUE.OWNER_OCCUPIED &&
    incomeList.length &&
    !isNZ;
  if (!isRemoveIncome) return false;

  incomeList.forEach(income => {
    incomeInfoDeleteFunction(loanAppId, income.Id);
  });
  return true;
}
