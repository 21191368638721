import { getRoleIdByContactType } from 'Common/utilities/contact';

export const getConcatenatedRoles = (party, partyType) => {
  const contactTypeRole = getRoleIdByContactType(partyType);
  const loanWriterBasisRoleId = (party && party.loanWriterBasisRoleId) || '';
  const roles = [contactTypeRole, loanWriterBasisRoleId];
  return roles.filter(role => role).join();
};

export const formatAssistantData = assistant => {
  return (
    assistant && {
      familyId: assistant.FamilyID,
      firstName: assistant.FirstName,
      middleName: assistant.MiddleName,
      lastName: assistant.LastName,
      email: assistant.Email,
    }
  );
};
