import { values } from 'lodash';
import { sortArray, changeSorting } from 'Common/utilities/sort';
import { toastError } from 'Common/utilities/alert';
import { RESIDENTIAL_STATUS_TYPE } from 'Common/constants/iFinance';

class AssetFinanceQuickProductFinderCtrl {
  constructor(
    NgTableParams,
    iFinanceAppService,
    productFinderService,
    loanProfilerService,
    optionsService,
    toaster,
    loanAppSharedData
  ) {
    'ngInject';

    this.NgTableParams = NgTableParams;
    this.iFinanceAppService = iFinanceAppService;
    this.productFinderService = productFinderService;
    this.loanProfilerService = loanProfilerService;
    this.optionsService = optionsService;
    this.toaster = toaster;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.changeSorting = changeSorting;
    this.productFinder = { currentPage: 1 };
    this.selectedProductCount = 0;
    this.favouriteProductTempList = [];
    this.getFilterData();

    this.formatIFinanceParams(this.ifinanceFilters, this.availableFunds);
  }

  getFilterData() {
    this.loanProfilerService
      .getServiceabilityFiltersParamters()
      .then(({ data }) => {
        if (!data) return;
        this.filtersParametersList = data;
        const {
          securityList,
          fundingSummary,
          LoanApplicationDetails,
        } = this.loanAppSharedData;
        const { AdviserFamilyId: adviserId } = LoanApplicationDetails;
        if (data.Lenders && !data.Lenders.length) return;
        this.loanProviderListFormat(data.Lenders);
        const isValidForSettings =
          !adviserId || !securityList || !securityList.length;
        if (isValidForSettings) return;
        const security = securityList[0];
        const { FinanceAssetTypeId: AssetType } = security;
        const LVR = (fundingSummary.lvr ? '+' : '-') + fundingSummary.lvr;
        const params = {
          GeneralSetting: {
            Lender: ['ALL'],
            loanAmount: fundingSummary.totalProposedLendingUserDefined,
            security: fundingSummary.totalSecurityValue,
            LVR,
            AssetType,
          },
        };
        this.loanProfilerService.updateServiceabilityFilteringProductsSettings(
          adviserId,
          params
        );
      });
  }

  loanProviderListFormat(lenders) {
    this.loanProviderLenderList = lenders
      .filter(
        productFinderItem => productFinderItem.Value !== 'FavouriteLenders'
      )
      .map(lender => {
        const maxLength = 12;
        const name = lender.Name;
        return {
          name,
          shortName:
            name.length > maxLength
              ? `${name.substring(0, maxLength - 3)}...`
              : name,
          value: lender.Value,
          ticked: false,
        };
      });
  }

  formatIFinanceParams(ifinanceFilter, availableFunds) {
    this.optionsService.getAssetFinanceCategory().then(response => {
      const financeTypeList = response;
      const financeType = financeTypeList.find(
        element => ifinanceFilter.financeCategory === element.name
      );

      const formattedParams = {
        purposeType: ifinanceFilter.clientType,
        assetType: ifinanceFilter.assetSecurityType,
        residentialStatusType: ifinanceFilter.isPropertyOwner
          ? RESIDENTIAL_STATUS_TYPE.PROPERTY_OWNER
          : RESIDENTIAL_STATUS_TYPE.NOT_PROPERTY_OWNER,
        quoteType: financeType && financeType.value,
        purchasePrice: ifinanceFilter.purchasePrice,
        term: ifinanceFilter.loanTerm,
        residualPercentage: ifinanceFilter.residualPercent,
        commissionPercentage: ifinanceFilter.brokerCommissionPercent,
        redBookCode: ifinanceFilter.redbookCode,
        deposit: availableFunds.deposit,
        tradeInAmount: availableFunds.tradeIn,
        payOutAmount: ifinanceFilter.payoutAmount,
        rbId: ifinanceFilter.redbookCode,
        assetTypeId: ifinanceFilter.assetSecurityType,
        quoteTypeId: financeType && financeType.value,
        quotePurposeId: ifinanceFilter.clientType,
        residentialStatusId: ifinanceFilter.isPropertyOwner
          ? RESIDENTIAL_STATUS_TYPE.PROPERTY_OWNER
          : RESIDENTIAL_STATUS_TYPE.NOT_PROPERTY_OWNER,
      };

      this.loadProductFinderList(formattedParams);
    });
  }

  loadProductFinderList(parameters) {
    this.showPreloader = true;

    const getProductFinder = ($defer, params, sortColumn, sortType) => {
      this.iFinanceAppService.getQuote(parameters).then(response => {
        if (!response.data || !response.data.data) {
          $defer.resolve([]);
          this.showPreloader = false;
          return;
        }

        const { data } = response.data;
        params.total(data.length);

        this.productFinderService
          .returnAddedSpreadsheetToProductList(data)
          .then(withSpreadsheet => {
            if (!withSpreadsheet) return;

            const sortedData = sortArray(withSpreadsheet, sortColumn, sortType);
            $defer.resolve(sortedData);
            this.showPreloader = false;
            this.getFavouriteProducts();
          });
      });
    };

    const countPerPage = 10;
    const tableParams = {
      page: 1,
      count: countPerPage,
      sorting: { lender: 'asc' },
      defaultSort: 'asc',
    };

    const tableSecondParams = {
      total: 0,
      getData($defer, params) {
        if (!params) return;
        const sortColumn = params.orderBy()[0].substring(1);
        const sortType = values(params.sorting())[0].toUpperCase();
        getProductFinder($defer, params, sortColumn, sortType);
      },
    };

    this.productFinder.tableParams = new this.NgTableParams(
      tableParams,
      tableSecondParams
    );
  }

  getFavouriteProducts() {
    this.loanProfilerService
      .getFavouriteProduct(
        this.loanFacility.BrokerEventId,
        this.loanFacility.shortLoanStructureId
      )
      .then(({ data }) => {
        if (!data) return;
        this.selectedProductCount = 0;

        this.productFinder.tableParams.data.forEach(product => {
          const isFavourite = data.find(
            favourite =>
              parseInt(product.id, 10) === parseInt(favourite.ProductID, 10)
          );
          if (isFavourite) {
            product.isAddedForComparison = true;
            this.selectedProductCount++;
            this.setSaveDisplay();
          }
        });
      });
  }

  selectAllLenders() {
    this.selectedLenders = this.loanProviderLenderList.map(lender => {
      if (lender.value !== 'ALL') {
        lender.ticked = false;
      }
      return lender;
    });
  }

  selectProduct(product, $event) {
    const MAX_SELECTION = 3;
    if ($event) $event.stopPropagation();

    if (
      !product.isAddedForComparison &&
      this.selectedProductCount >= MAX_SELECTION
    ) {
      this.toaster.pop(
        'warning',
        'Product Selection',
        'You can select maximum 3 products'
      );
      return;
    }

    product.isAddedForComparison = !product.isAddedForComparison;
    this.selectedProductCount =
      this.selectedProductCount + (product.isAddedForComparison ? 1 : -1);
    this.setSaveDisplay();

    const postData = {
      brokerEventID: this.loanFacility.BrokerEventId,
      ProductID: product.id,
      LenderID: 0,
      loanStructureID: this.loanFacility.shortLoanStructureId,
      IsFavourite: product.isAddedForComparison,
      ...product,
    };

    this.saveFavouriteProduct(product, postData);
  }

  saveFavouriteProduct(product, postData) {
    this.loanProfilerService.setFavouriteProduct(postData).then(response => {
      const isSetSuccessful =
        response.data && parseInt(response.data, 10) === 1;
      if (isSetSuccessful) {
        const tempProduct = this.favouriteProductTempList.find(
          item => parseInt(item.id, 10) === parseInt(postData.id, 10)
        );
        if (tempProduct) {
          this.favouriteProductTempList.forEach(item => {
            if (parseInt(item.id, 10) === parseInt(postData.id, 10)) {
              item.IsFavourite = postData.IsFavourite;
            }
          });
          return;
        }
        this.favouriteProductTempList = this.favouriteProductTempList.concat(
          postData
        );
      } else {
        toastError();
        product.isAddedForComparison = !product.isAddedForComparison;
        this.selectedProductCount =
          this.selectedProductCount + (product.isAddedForComparison ? 1 : -1);
      }
    });
  }

  setSaveDisplay() {
    const MAX_SELECTION = 3;

    this.productInfoShow =
      this.selectedProductCount && this.selectedProductCount < MAX_SELECTION;
    this.enableSave = this.selectedProductCount === MAX_SELECTION;
  }

  cancelProductSelection() {
    this.favouriteProductTempList.forEach(item => {
      item.IsFavourite = !item.IsFavourite;
      this.loanProfilerService.setFavouriteProduct(item).then(response => {
        const isSetSuccessful =
          response.data && parseInt(response.data, 10) === 1;
        if (!isSetSuccessful) {
          toastError();
        }
      });
    });
    this.modalInstance.dismiss('cancel');
  }

  closeModal() {
    this.modalInstance.close();
  }
}

export default AssetFinanceQuickProductFinderCtrl;
