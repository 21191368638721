import angular from 'angular';
import JsPDF from 'jspdf';

export function downloadResource(url, toFileName) {
  if (!url) return false;

  const anchorTag = angular.element('<a></a>')[0];
  anchorTag.download = toFileName;
  anchorTag.href = url;
  anchorTag.click();
  return true;
}

export function downloadImgAsPDF(imgUrl, fileName, configuration = {}) {
  if (
    imgUrl &&
    fileName &&
    configuration &&
    configuration.orientation &&
    configuration.width &&
    configuration.height
  ) {
    const { orientation, width, height } = configuration;
    const pageDimensions = width && height ? [width, height] : 'a4';
    const doc = new JsPDF(orientation || 'p', 'px', pageDimensions);
    doc.addImage(imgUrl, 'JPEG', 0, 0, width, height);
    doc.save(fileName);
    return true;
  }
  return false;
}

export function validateDocumentData(data) {
  if (data && data.ContentType && data.DocumentContent && data.DocumentKey)
    return data;
  return false;
}
