import angular from 'angular';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import {
  SERVICE_TAB_INDEX,
  SERVICE_ID,
  SUBSCRIPTION_MESSAGES,
  ORG_SUBSCRIPTION,
} from 'Common/constants/customerCareModules';
import { objectLength } from 'Common/utilities/objectValidation';
import { openVideoModal } from 'Common/utilities/trainingVideoModal';

angular
  .module('app')
  .controller('CustomerCareCtrl', function CustomerCareCtrl(
    $scope,
    $uibModal,
    SweetAlert,
    NgTableParams,
    $window,
    $location,
    $state,
    $stateParams,
    toaster,
    cfpLoadingBar,
    $timeout,
    $rootScope,
    $filter,
    $localStorage,
    $document,
    customerCareService,
    contactService,
    subscriptionService,
    commonFnService,
    currentUserService
  ) {
    $scope.currentUserService = currentUserService;
    $rootScope.CustomerCareMainObj = {};
    $rootScope.CustomerCareMainObj.selectedTab = 'fixedRateExpires';

    $scope.customerCareAdviserList = [];

    $scope.serviceId = SERVICE_ID.FIXED_RATE_EXPIRES;
    $scope.getTabBrokers = () => {
      customerCareService
        .getBrokers({ typeId: $scope.serviceId, loanWritersOnly: true })
        .then(response => {
          $scope.customerCareAdviserList = response;
        });
    };

    customerCareService.customerCareDateRange().then(data => {
      if (!data) return;
      $scope.customerCareDateRange = data;
    });

    $scope.selectTab = function(selectedTab) {
      $timeout(() => {
        const el = $document.find(`#${selectedTab} .nav-link`);
        angular.element(el).triggerHandler('click');
      }, 0);
    };

    $scope.userInfo = {};
    $scope.isAdviser = true;
    $scope.someBrokersSubscribed = false;
    $scope.autoMarketingSubscription = {};
    $scope.isAssistantUnlocked = false;
    $scope.showLockScreen = false;
    $scope.autoMarketingMessage = SUBSCRIPTION_MESSAGES.NONE;

    const messageToDisplay = (orgSubscribedType, isSubscribed) => {
      $scope.autoMarketingMessage = isSubscribed
        ? SUBSCRIPTION_MESSAGES.NONE
        : '';
      $scope.isAdviser =
        $scope.userInfo.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER ||
        $scope.userInfo.AccessType === ACCESS_TYPE.ADVISER;

      if (orgSubscribedType === ORG_SUBSCRIPTION.SOME) {
        $scope.autoMarketingMessage = !$scope.isAdviser
          ? SUBSCRIPTION_MESSAGES.SOME
          : $scope.autoMarketingMessage;
        $scope.someBrokersSubscribed = true;
      } else if (orgSubscribedType === ORG_SUBSCRIPTION.ALL) {
        $scope.autoMarketingMessage = !$scope.isAdviser
          ? SUBSCRIPTION_MESSAGES.ALL
          : $scope.autoMarketingMessage;
        $scope.someBrokersSubscribed = false;
      }
      $scope.isAssistantUnlocked =
        !$scope.autoMarketingMessage ||
        (!$scope.isAdviser && $scope.someBrokersSubscribed);
      $scope.showLockScreen =
        ($scope.autoMarketingMessage && $scope.isAdviser) ||
        ($scope.autoMarketingMessage &&
          !$scope.isAdviser &&
          !$scope.someBrokersSubscribed);
      if (!$scope.showLockScreen) $scope.getTabBrokers();
    };

    $scope.checkTabView = () => {
      if (!objectLength($scope.autoMarketingSubscription)) return;
      const {
        subscribe_FixedRateExpiry: subscribeFixedRateExpires,
        subscribe_HappyBirthday: subscribeBirthdays,
        subscribe_PreApprovalExpiry: subscribePreApprovalExpiry,
        subscribe_StayInTouchAnnualReview: subscribeAnnualReview,
        subscribe_StayInTouchSettlement: subscribeSettlement,
        organization_FixedRateExpiry: fixedRateExpires,
        organization_HappyBirthday: birthdays,
        organization_PreApprovalExpiry: preApprovalExpiry,
        organization_StayInTouchAnnualReview: annualReview,
        organization_StayInTouchSettlement: settlement,
      } = $scope.autoMarketingSubscription;

      switch ($scope.activeTab) {
        case SERVICE_TAB_INDEX.APPROVAL_EXPIRIES:
          $scope.serviceId = SERVICE_ID.APPROVAL_EXPIRIES;
          messageToDisplay(preApprovalExpiry, subscribePreApprovalExpiry);
          break;
        case SERVICE_TAB_INDEX.BIRTHDAYS:
          $scope.serviceId = SERVICE_ID.BIRTHDAYS;
          messageToDisplay(birthdays, subscribeBirthdays);
          break;
        case SERVICE_TAB_INDEX.ANNUAL_REVIEWS:
          $scope.serviceId = SERVICE_ID.ANNUAL_REVIEWS;
          messageToDisplay(annualReview, subscribeAnnualReview);
          break;
        case SERVICE_TAB_INDEX.POST_SETTLEMENT:
          $scope.serviceId = SERVICE_ID.POST_SETTLEMENT;
          messageToDisplay(settlement, subscribeSettlement);
          break;
        default:
          $scope.serviceId = SERVICE_ID.FIXED_RATE_EXPIRES;
          messageToDisplay(fixedRateExpires, subscribeFixedRateExpires);
          break;
      }
    };

    $scope.getUserSubscription = () => {
      subscriptionService.marketingAutomation().then(response => {
        if (!response) return;
        $scope.autoMarketingSubscription = response;
        $scope.checkTabView();
      });
    };

    $scope.getUserInfo = () => {
      contactService.getUserInfo().then(response => {
        if (!response || !response.data) return;
        const { data } = response;
        $scope.userInfo = data;
        $scope.getUserSubscription();
      });
    };
    $scope.getUserInfo();

    $scope.selectTabUpdate = function(tab) {
      $rootScope.CustomerCareMainObj.selectedTab = tab;
      $scope.checkTabView();
    };

    if (
      typeof $stateParams.activeTab !== 'undefined' &&
      $stateParams.activeTab !== ''
    ) {
      $scope.activeTab = parseInt($stateParams.activeTab, 10);
    }

    $scope.gotoContact = familyId => {
      commonFnService.hiddenClientForNz().then(isHidden => {
        if (familyId && !isHidden) {
          $state.go('app.contactsSingle', { familyId });
        }
      });
    };

    $scope.openVideoModal = () => {
      openVideoModal($uibModal, PAGE_SOURCE.CUSTOMER_CARE);
    };
  });
