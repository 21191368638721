import angular from 'angular';
import swal from 'sweetalert';
import $ from 'jquery';

angular
  .module('app')
  .controller('RequiredDocumentsCtrl', function RequiredDocumentsCtrl(
    $scope,
    SweetAlert,
    corporateService,
    onboardingService,
    $stateParams,
    contactService,
    configService,
    generalService,
    $window,
    $timeout,
    onboardingDataService
  ) {
    $scope.requiredDocuments = {};
    const familyId = $stateParams.familyId;
    $scope.familyId = familyId;
    $scope.uploadFileTimeout = null;
    const getApplicationChecklist = () => {
      onboardingService
        .getApplicationChecklist(familyId)
        .then(response => {
          if (!response || !response.data) return;
          /* Get each document per application checklists */
          const checklistsData = response.data.map(checkList => {
            /* Mutate object response for adding new key `DocumentName` */
            const checkListObjectNew = { ...checkList };
            if (checkListObjectNew && checkListObjectNew.DocumentID) {
              /* Set new key of Object DocumentName if document found */
              generalService
                .documentGet(checkListObjectNew.DocumentID)
                .then(documentLists => {
                  if (documentLists && documentLists.data) {
                    checkListObjectNew.DocumentName = documentLists.data.Name;
                    checkListObjectNew.ContentType =
                      documentLists.data.ContentType;
                    checkListObjectNew.DocumentContent =
                      documentLists.data.DocumentContent;
                  }
                });
            } else {
              /* Set object key to null if no document found */
              checkListObjectNew.DocumentName = '';
            }
            return checkListObjectNew;
          });
          /* assign mutated data to antoher variable that is accessible publicly */
          $scope.applicationList = checklistsData;
        })
        .then(() => {
          const dataTmp = {
            requiredDocuments: $scope.applicationList,
          };
          onboardingDataService.setAllOnboardingDataForPreview(dataTmp);
        });
    };
    getApplicationChecklist();

    const uploadFileTimeouFn = () => {
      $scope.uploadFileTimeout = $timeout(() => {
        angular
          .element($window.document.getElementById('uploadedfileId'))
          .trigger('click');
      });
    };

    $scope.$on('$destroy', () => {
      $timeout.cancel($scope.uploadFileTimeout);
    });
    $scope.reUpload = function() {
      uploadFileTimeouFn();
    };
    $scope.downloadDocument = function(documentId) {
      generalService.documentGet(documentId).then(response => {
        const data = response.data;
        if (!data || !data.length) return;
        const timeOut = $timeout(() => {
          const element = $window.document.createElement('a');
          element.setAttribute(
            'href',
            `data:${data.ContentType};base64,${data.DocumentContent}`
          );
          element.setAttribute('download', data.Name);
          element.style.display = 'none';
          $window.document.body.appendChild(element);
          element.click();
          $window.document.body.removeChild(element);
        });
        $scope.$on('$destroy', () => {
          $timeout.cancel(timeOut);
        });
      });
    };

    $scope.currentRecruitmentId = 0;
    $scope.initializeUpload = function(recruitmentCheckListID) {
      $scope.currentRecruitmentId = recruitmentCheckListID;
      uploadFileTimeouFn();
    };
    const uploadDocumentListInfo = data => {
      if (data.length > 0) {
        const documentId = data[0].DocumentId;
        $window.document.getElementById('uploadedfileId').value = '';
        onboardingService
          .setApplicationChecklist(
            familyId,
            $scope.currentRecruitmentId,
            documentId
          )
          .then(() => {
            swal('Success', 'Document successfully uploaded.', 'success');
            $scope.currentRecruitmentId = 0;
            getApplicationChecklist();
          });
      }
    };
    const uploadDocument = () => {
      const formData = new $window.FormData($('#form')[0]);
      $.ajax(
        {
          url: `${configService.resource}/contacts/DocumentUpload`, // server script to process data
          type: 'POST',
          // Ajax events
          beforeSend(req) {
            req.setRequestHeader('Authorization', configService.token); // Put Bearer to work in local. 'Bearer ' +
          },
          success(response) {
            uploadDocumentListInfo(response);
          },
          error() {
            SweetAlert(
              'Error',
              'Error encountered, Please reupload again.',
              'error'
            );
          },
          // Form data
          data: formData,
          // Options to tell jQuery not to process data or worry about the content-type
          cache: false,
          contentType: false,
          processData: false,
        },
        'json'
      );
    };
    $scope.fileNameChanged = function() {
      if ($window.document.getElementById('uploadedfileId').files.length > 0) {
        uploadDocument();
      }
    };
  });
