import template from './helloBookPreviewGenerator.html';
import helloBookPreviewGeneratorCtrl from './helloBookPreviewGeneratorCtrl';

export default {
  template,
  controller: helloBookPreviewGeneratorCtrl,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    modalInstance: '<',
  },
};
