import _ from 'lodash';
import { PHONECODE } from 'Common/constants/countryMobileNumberCode';
import {
  AU_MOBILE_PATTERNS,
  NZ_MOBILE_PATTERNS,
} from 'Common/constants/pattern';

export function isMobileValid(value) {
  if (!value) return false;
  const regex = /^(\+6|0)((\d)\d{7,10})$/;
  const match = value.match(regex);
  if (!match || !match.length) return false;
  if (match && match.length === 4) {
    const international = match[1].length === 2;
    const countryIdentifier = match[3];
    if (international) {
      if (
        countryIdentifier === PHONECODE.INTERNATIONAL_AU &&
        value.length === 12
      ) {
        return true;
      }
      if (
        countryIdentifier === PHONECODE.INTERNATIONAL_NZ &&
        _.inRange(value.length, 11, 14)
      ) {
        return true;
      }
    } else {
      if (
        PHONECODE.LOCAL_AU.find(item => item === countryIdentifier) &&
        value.length === 10
      ) {
        return true;
      }
      if (
        countryIdentifier === PHONECODE.LOCAL_NZ &&
        _.inRange(value.length, 9, 12)
      ) {
        return true;
      }
    }
  }
  return false;
}

export function isMobileNumberValid(number) {
  if (!number) return false;

  const validMobilePatterns = {
    ...AU_MOBILE_PATTERNS,
    ...NZ_MOBILE_PATTERNS,
  };
  const hasMatch = Object.keys(validMobilePatterns).some(key =>
    number.match(validMobilePatterns[key])
  );
  return hasMatch;
}
