import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';

export const getImportantDates = ({ $scope }) => loanApplicationServices => {
  $scope.importantDateFieldList = [];
  loanApplicationServices.getLoanImportantDates().then(data => {
    if (!data || !data.length) return;
    $scope.importantDateFieldList = data.reduce((accum, value) => {
      if (!value || !value.length) return accum;
      return [...accum, ...value];
    }, []);
  });
};

export const getImportantDateFields = ({ $scope }) => status => {
  $scope.importantDateField = [];
  if (!status || !status.LoanStatusID) return;
  const statusId = parseInt(status.LoanStatusID, 10);
  $scope.importantDateField = $scope.importantDateFieldList.filter(
    field => field.requiredStatuses && field.requiredStatuses.includes(statusId)
  );
};

export const loanDetail = ({ $scope }) => configService => {
  $scope.additionalLoanImportantDatesFlag =
    configService.feature.additionalLoanImportantDates;
  $scope.isSettled = loan => {
    const isSettled =
      !$scope.additionalLoanImportantDatesFlag || !loan || loan.SettlementDate;
    if (isSettled) return true;
    return !loan.EstimatedSettlementDate;
  };

  $scope.getSettlementTitle = loan => {
    if (!$scope.additionalLoanImportantDatesFlag) return 'Settlement';
    if (!loan || loan.SettlementDate) return 'Settled';
    if (loan.EstimatedSettlementDate) return 'Estimated Settlement';
    return 'Settled';
  };

  $scope.displayDeleteButton = loan => {
    const isValidDate =
      loan && (loan.SettlementDate || loan.EstimatedSettlementDate);
    return isValidDate;
  };

  $scope.getImportantDateType = loan => {
    if (!$scope.additionalLoanImportantDatesFlag && loan.IsEstimated)
      return IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT;
    return $scope.isSettled(loan)
      ? IMPORTANT_DATE_TYPES.DELETE.ACTUAL_SETTLEMENT
      : IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT;
  };
};

export const updateLoanStatusCallback = ({
  $scope,
  loanScenarioModelService,
  status,
  inprogressLoan,
}) => result => {
  if (!result.isOk) return;
  if (result.notProceedingDate) {
    const dateParams = {
      loanApplicationId: 0,
      loanId: inprogressLoan.LoanID,
      dateTypeId: IMPORTANT_DATE_TYPES.DELETE.NOT_PROCEEDED,
      date: result.notProceedingDate,
    };
    loanScenarioModelService.updateImportantDate(dateParams);
  }
  $scope.callLoanStatusSet(
    status,
    inprogressLoan,
    result.shouldSendNurtureCampaigns,
    result.selectedReasonId
  );
};
