import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';

class InterceptBankConnect {
  constructor(
    $uibModal,
    corporateService,
    currentUserService,
    contactModelService
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.corporateService = corporateService;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
  }

  $onInit() {
    if (!this.familyId) return;
    this.getCorporateContactGet();
    this.updateInterceptPopUp();
    this.currentLoggedInUser = this.currentUserService.familyId;
  }

  updateInterceptPopUp() {
    const tickedItemId = TICKABLE_ITEMS.INTERCEPT;
    this.contactModelService.postSubscriptionPopUp(tickedItemId);
  }
  getCorporateContactGet() {
    this.corporateService
      .corporateContactGet(0, this.familyId)
      .then(response => {
        this.adviserInfo = response.data;
      });
  }

  openFactFindModal() {
    this.modalInstance.close(true);
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
}
export default InterceptBankConnect;
