import { LIST_OF_QUESTIONS } from 'Common/constants/possibleAdverseChanges';

export function filterPersonBy(prop, data) {
  if (!prop) return [];
  const filtered = Object.keys(data)
    .map(key => {
      return data[key].filter(people => people[prop]);
    })
    .filter(array => array.length);

  return filtered;
}

export function filterClientList(involvedPerson) {
  if (!involvedPerson) return [];
  const applicants = filterPersonBy('IsApplicant', involvedPerson);
  const guarantors = filterPersonBy('IsGuarantor', involvedPerson);

  const structuredClients = [...applicants, ...guarantors].reduce(
    (accum, person) => accum.concat(person),
    []
  );

  return structuredClients;
}

export function getOptionListByQuestion(id, options) {
  if (!id || !options || !options.length) return [];
  const optionList = options.find(option => option.questionId === id);
  return (optionList && optionList.options) || [];
}

export function stringifyMultipleAnswers(answers) {
  if (!answers) return '';
  const stringifyAnswers = answers
    .map(item => {
      return item.value;
    })
    .join(',');
  return stringifyAnswers;
}

export function getFormattedAnswer(questionId, answerList) {
  if (!questionId || !answerList || !answerList.length) return null;
  const clientAnswer = answerList.find(item => item.questionId === questionId);
  return (clientAnswer && clientAnswer.answer) || null;
}

export function isBinaryYes(data) {
  return !!parseInt(data, 10);
}

export function hasOtherSelected(answer) {
  if (!answer) return false;
  return answer.split(',').includes('Other');
}

export function isCompleteSubQuestion(question) {
  const { SubQuestion: subQuestion } = question;
  let isValidAnswer = true;
  if (!question || !subQuestion) return isValidAnswer;

  switch (question.QuestionId) {
    case LIST_OF_QUESTIONS.APPLICANT_RETIRING:
      subQuestion.forEach(item => {
        if (!item.answer) isValidAnswer = false;
      });
      break;
    default:
  }
  return isValidAnswer;
}

export function getDataPerClient(dataList, clientId) {
  const isValid = dataList && dataList.length && clientId;
  return isValid
    ? dataList.filter(item => `${item.clientId}` === `${clientId}`)
    : [];
}

export function isCompleteField(question) {
  if (!question) return false;
  let isComplete = false;
  const { QuestionId: questionId, answer } = question;
  switch (questionId) {
    case LIST_OF_QUESTIONS.RETIREMENT_AGE:
      isComplete = !!answer;
      break;

    case LIST_OF_QUESTIONS.APPLICANT_RETIRING:
      isComplete =
        (answer && !isBinaryYes(answer)) ||
        (answer && isCompleteSubQuestion(question));
      break;

    case LIST_OF_QUESTIONS.ANTICIPATE_CHANGES:
      isComplete =
        (answer && !isBinaryYes(answer)) ||
        (answer &&
          question.hasPossibleAnticipated &&
          question.hasPossiblePlanOrAnticipate);
      break;

    default:
  }
  return isComplete;
}

export function isCompleteSection(fields) {
  if (!fields || !fields.length) return false;
  let isComplete = true;
  fields.forEach(item => {
    item.adverseQuestions &&
      item.adverseQuestions.forEach(question => {
        if (!question.isCompleteField) isComplete = false;
      });
  });
  return isComplete;
}
