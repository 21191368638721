import angular from 'angular';
import moment from 'moment';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { toastError, toastWarning } from 'Common/utilities/alert';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';

export default angular
  .module('app.services.importantDatesService', [])
  .factory('importantDatesService', function importantDatesService(
    $q,
    loanApplicationServices,
    loanScenarioModelService
  ) {
    const confirmDates = args => {
      const defer = $q.defer();
      const isInvalid =
        !args ||
        !args.loanDetails ||
        !args.importantDateField ||
        !args.importantDateField.length;
      if (isInvalid) {
        defer.reject();
        return defer.promise;
      }

      const promises = args.importantDateField.reduce((accum, value) => {
        const dateValue = args.loanDetails[value.field];
        if (!dateValue) return accum;
        const formatedDate = moment(dateValue).format(TO_MYCRM_DATE_FORMAT);
        const params = {
          loanApplicationId: 0,
          loanId: args.loanDetails.LoanID,
          dateTypeId: value.id,
          date: formatedDate,
        };
        const request = loanScenarioModelService.updateImportantDate(params);
        return [...accum, request];
      }, []);

      if (!promises || !promises.length) {
        defer.resolve({
          isValid: true,
        });
        return defer.promise;
      }
      const form = {
        invalidDate: false,
      };
      return $q
        .all(promises)
        .then(responses => {
          if (!responses || !responses.length) return responses;
          responses.map(res => {
            if (
              form.invalidDate ||
              res.data !== IMPORTANT_DATE_TYPES.API_STATUS.INVALID_DATE
            )
              return res;
            toastWarning('warning', 'Date is invalid');
            form.invalidDate = true;
            return res;
          });
          if (form.invalidDate) return [];
          return responses;
        })
        .catch(error => {
          toastError('Error saving important date.');
          return error;
        });
    };

    const getImportantDateFields = (statusId = 0) => {
      return loanApplicationServices.getLoanImportantDates().then(data => {
        if (!data || !data.length) return [];
        const list = data.reduce((accum, value) => {
          if (!value || !value.length) return accum;
          return [...accum, ...value];
        }, []);
        const importantDatesList = list.filter(
          field =>
            field.requiredStatuses && field.requiredStatuses.includes(statusId)
        );
        return importantDatesList;
      });
    };

    return {
      confirmDates,
      getImportantDateFields,
    };
  }).name;
