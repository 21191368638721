export default class BenefitsNotesCtrl {
  constructor() {
    'ngInject';

    this.getNotesValue();
  }
  getNotesValue() {
    this.getBenefitsNotes({ $e: { benefitsForm: this.insuranceData } });
  }
}
