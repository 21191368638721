import template from './questionMultiSelect.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    titleValue: '<',
    answerList: '<',
    optionList: '<',
    onAdd: '&',
    onRemove: '&',
  },
};
