import { ENTITY_TYPE } from 'Common/constants/entityType';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';

class OnboardingCompletePreviewCtrl {
  constructor(
    onboardingDataService,
    dataService,
    onboardingService,
    crmConfirmation,
    uiService
  ) {
    'ngInject';

    this.isCountryNewZealand = false;
    this.entityTypeIsCompany = false;
    this.entityTypeIsSoleTrader = false;
    this.entityTypeIsPartnership = false;
    this.isOwnBrand = false;
    this.onboardingDataService = onboardingDataService;
    this.dataService = dataService;
    this.onboardingService = onboardingService;
    this.crmConfirmation = crmConfirmation;
    this.uiService = uiService;
    this.currentIndex = 0;
    this.isFranchisee = false;
    this.isContractor = false;
    this.isEmployee = false;
    this.isPartner = false;
    this.isDirector = false;
    this.level_access = [];
  }

  $onInit() {
    this.familyId = this.onboardingDataService.getFamilyId();
    this.isCountryNewZealand = this.onboardingDataService.isUserCurrentCountry(
      COUNTRY_TYPE.NEW_ZEALAND
    );
    this.entityTypeIsCompany =
      this.onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.COMPANY_TRUST
      );
    this.entityTypeIsSoleTrader =
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.SOLE_TRADER
      ) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.SOLE_TRADER_TRUST
      );
    this.entityTypeIsPartnership =
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.PARTNERSHIP
      ) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.PARTNERSHIP_TRUST
      );
    this.level_access = this.dataService.getLevelAccessList();
    this.onboardingDataService
      .getOnboardingResponse(
        this.onboardingDataService.getFamilyId(),
        this.onboardingDataService.getClientId()
      )
      .then(response => {
        if (response) {
          if (response.RoleId) {
            this.isFranchisee = this.onboardingDataService.isOnboardingUserRole(
              response.RoleId,
              ONBOARDING_ROLE_ID.FRANCHISEE
            );
          }
        }
      });
  }

  setQuestionniare(index) {
    this.currentIndex = index;
  }
  modalCallback(response) {
    if (!response) return;
    if (response.isOk) {
      const validTabs = this.onboardingTabs && this.onboardingTabs.length;
      const requiredIndex =
        (validTabs &&
          this.onboardingTabs.findIndex(
            tab => tab.value === 'requiredDocuments'
          )) ||
        0;
      this.setTab({ index: requiredIndex });
    } else if (response.isCustom) {
      this.uiService.logoutUser();
    }
  }
  sendOnboardingApplication() {
    this.isSendingApplication = true;
    this.onboardingService
      .sendProcessCompletedEmailSet(this.familyId)
      .then(({ data }) => {
        this.isSendingApplication = false;
        if (!data) return;
        const successInstance = this.crmConfirmation.open({
          type: 'success',
          title: 'Nice Work!',
          description: `
              <p>We'll review your details and be in touch soon.</p>
            `,
          buttonText: 'OK!',
          modalSize: 'md',
          isButtonMaxWidth: true,
        });
        successInstance.result.then(() => {
          const modalInstance = this.crmConfirmation.open({
            type: 'info',
            title: 'Got all your documents?',
            description: `
                <p>We won't be able to complete your onboarding until all documents have been uploaded and compliance reviewed. You can login at anytime to upload or amend your documents.</p>
              `,
            buttonText: 'Add More Documents',
            modalSize: 'md',
            showCloseButton: true,
            showCustomButton: true,
            customButtonText: 'Logout',
            customButtonClass: 'colored',
            confirmationIcon: `<plus-icon></plus-icon>`,
            isButtonMaxWidth: true,
          });
          modalInstance.result.then(response => {
            this.modalCallback(response);
          });
        });
      });
  }
}

export default OnboardingCompletePreviewCtrl;
