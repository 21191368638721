import { OPPORTUNITY } from 'Common/constants/paramTypes';

export const mapLoanOpportunitiesData = ({
  $scope,
  $state,
  configService,
}) => apiService => {
  if (
    $state.params.paramType !== OPPORTUNITY ||
    !configService.feature.loanAppOnOpportunities
  )
    return;
  apiService
    .getLoanOpportunityDetails($state.params.paramId)
    .then(({ data }) => {
      const validProductType = !!(
        data.CalculatorDetails && data.CalculatorDetails.ProductType
      );
      const validProductTypeValue = !!(
        validProductType && data.CalculatorDetails.ProductType.Value
      );
      const validLoanAmount = !!(
        data.Summary && data.Summary.ProposedLoanAmount
      );
      const validSecurityAmount = !!(
        data.Summary && data.Summary.SecurityAmount
      );
      const loanAmount = validLoanAmount ? data.Summary.ProposedLoanAmount : 0;
      const security = validSecurityAmount ? data.Summary.SecurityAmount : 0;
      const productType = validProductTypeValue
        ? data.CalculatorDetails.ProductType.Value
        : '';

      if ($scope.loanStructureList)
        $scope.loanStructureList.map(loanStructure => {
          loanStructure.getFilterDataList = {
            ...loanStructure.getFilterDataList,
            LoanAmount: loanAmount,
            ProductType: productType,
          };

          return loanStructure;
        });
      $scope.defaultSelection = {
        ...$scope.defaultSelection,
        loanAmount,
        security,
      };
      $scope.setDefaultFilterSelection();
    });
};
