export const DEFAULT_FUND_NAMES = {
  TOTAL_PROPERTY_AMOUNT: 'Total Property Amount',
};

export const FUNDING_PURPOSE_IDS = {
  PURCHASE_OWNER_OCCUPIED: 4,
  PURCHASE_INVESTMENT: 5,
  REFI_EQUITY_OWNER_OCCUPIED: 6,
  REFI_EQUITY_INVESTMENT: 7,
  CONSTRUCTION_INVESTMENT: 8,
  CONSTRUCTION_OWNER_OCCUPIED: 9,
};
