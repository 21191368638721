import angular from 'angular';
import { forEach, isFunction, range } from 'lodash';
import { COLOR } from 'Common/constants/colors';
import { toastError } from 'Common/utilities/alert';

angular
  .module('app')
  .service('pipelineSharedData', function(
    $localStorage,
    $q,
    uiService,
    pipelineService,
    toaster,
    $window,
    tasksService
  ) {
    this.$window = $window;
    this.tasksService = tasksService;

    this.dataStatus = {
      isUpdatePipelineLead: false,
      isUpdatePipelineApp: false,
    };

    this.leadFilterData = {
      adviserId: 0,
      pipelineStatusId: 0,
      labelId: 0,
      probabilityId: 0,
      enquiryId: 0,
      referralId: 0,
      PipelineStatusIDs: [],
    };

    this.appFilterData = {
      adviserId: 0,
      pipelineStatusId: 0,
      labelId: 0,
    };

    this.toFilter = '';
    this.selectedStatusForFilter = {
      PipelineStatus: 'All',
      PipelineStatusID: 0,
    };

    this.updateLabel = (list, labelObj, lblsToUpdate, pickedColorCode) => {
      const lblToUpdate = {};
      if (list.lblObjToEdit) {
        labelObj = list.lblObjToEdit;
        lblToUpdate.LabelID = labelObj.pipelineSettingsLabelID;
        lblToUpdate.LabelName = list.labelTitle;
        if (pickedColorCode !== '') {
          lblToUpdate.ColorName = pickedColorCode;
        } else {
          lblToUpdate.ColorName = labelObj.ColorName;
        }

        lblsToUpdate.push(lblToUpdate);
      } else {
        lblToUpdate.LabelID = 0;
        lblToUpdate.LabelName = list.newLabelTitle;
        if (pickedColorCode !== '') {
          lblToUpdate.ColorName = pickedColorCode;
        } else {
          lblToUpdate.ColorName = COLOR.SALMON;
        }

        lblsToUpdate.push(lblToUpdate);
      }
    };

    this.updateLabels = (card, pickedColorCode) => {
      const defer = $q.defer();
      if (!card) return defer.promise;

      const lblToUpdate = {};
      const lblsToUpdate = [];
      if (card.lblObjToEdit) {
        const labelObj = card.lblObjToEdit;
        labelObj.labelColor = pickedColorCode;
        lblToUpdate.LabelID = labelObj.pipelineSettingsLabelID;
        lblToUpdate.LabelName = card.labelTitle;
        lblToUpdate.ColorName = pickedColorCode;
        lblsToUpdate.push(lblToUpdate);
      } else {
        lblToUpdate.LabelID = 0;
        lblToUpdate.LabelName = card.newLabelTitle;
        lblToUpdate.ColorName = pickedColorCode;
        lblsToUpdate.push(lblToUpdate);
      }

      return pipelineService.PipelineSettingsLabelSet(lblsToUpdate);
    };

    this.editLabel = (lbl, card) => {
      card.editLabelShown = true;
      card.lblObjToEdit = lbl;
      card.labelTitle = lbl.LabelName;

      let labelToTrue;
      switch (lbl.ColorName) {
        case COLOR.SALMON:
          labelToTrue = ['labelColor1'];
          break;
        case COLOR.SEA_BUCKTHORN:
          labelToTrue = ['labelColor2'];
          break;
        case COLOR.TURBO:
          labelToTrue = ['labelColor3'];
          break;
        case COLOR.CANNON_PINK:
          labelToTrue = ['labelColor4'];
          break;
        case COLOR.MULLED_WINE:
          labelToTrue = ['labelColor5'];
          break;
        case COLOR.LOCHMARA:
          labelToTrue = ['labelColor6'];
          break;
        case COLOR.AFFAIR:
          labelToTrue = ['labelColor7'];
          break;
        case COLOR.BRIGHT_TURQUOISE:
          labelToTrue = ['labelColor8', 'labelColor10'];
          break;
        case COLOR.APPLE:
          labelToTrue = ['labelColor9'];
          break;
        default:
          labelToTrue = [];
          break;
      }

      return labelToTrue;
    };

    this.checkItemMove = event => {
      return (
        event &&
        event.dest &&
        event.dest.sortableScope &&
        event.dest.sortableScope.element &&
        event.dest.sortableScope.element[0].id !== 'approve' &&
        event.dest.sortableScope.element[0].id !== 'opportunity' &&
        event.dest.sortableScope.element[0].id !== 'notProceeding'
      );
    };

    this.formatListViewData = (data = [], statusList) => {
      forEach(data, obj => {
        var adviserName = obj.AdvisorName;
        var pipelineStatus = obj.PipelineStatus;
        if (statusList.length) {
          const pipelineStatusObj = statusList.filter(item => {
            if (item.PipelineStatus === pipelineStatus) {
              return item;
            }
            return false;
          });

          if (
            pipelineStatusObj &&
            pipelineStatusObj.length &&
            pipelineStatusObj[0].length
          ) {
            obj.subStatuses = pipelineStatusObj[0].SubStatus;

            if (obj.subStatuses.length) {
              const pipelineSubStatusObj = obj.subStatuses.filter(item => {
                if (item.pipelineSubStatusID === obj.SubStatusID) {
                  return item;
                }
                return false;
              });
              if (pipelineSubStatusObj && pipelineSubStatusObj[0]) {
                obj.subStatusName = pipelineSubStatusObj[0].SubStatusName;
                obj.selectedSubStatus = pipelineSubStatusObj[0];
              } else {
                obj.subStatusName = 'Please Select Status';
              }
            }
          }
        }

        const adviserSplittedNames = adviserName.split(' ');
        let adviserFirstName = '';
        let adviserLastName = '';
        if (adviserSplittedNames.length >= 2) {
          adviserFirstName = adviserSplittedNames[0];
          adviserLastName = adviserSplittedNames[1];
        } else if (adviserSplittedNames.length === 1) {
          adviserFirstName = adviserSplittedNames[0];
          adviserLastName = '';
        }

        obj.AdviserFirstName = adviserFirstName;
        obj.AdviserLastName = adviserLastName;
        obj.clientFamilyId = obj.ClientFamilyID;
        obj.selectedStatus = '';
        obj.LabelsConcat = [];
        const LabelsArr = [];
        var splittedArr = obj.Labels.split(',');
        for (var j = 0; j < splittedArr.length; j++) {
          const splitLabel = splittedArr[j].split(':');
          const label = {};
          label.labelColor = splitLabel[0];
          label.labelName = splitLabel[1];
          label.labelID = splitLabel[2];

          LabelsArr.push(label);
          obj.LabelsConcat.push(label.labelName);
        }
        obj.LabelArray = LabelsArr;
        obj.LabelsConcat.join(',');
        if (!obj.ClientName && obj.ClientFamilyName) {
          obj.ClientName = obj.ClientFamilyName;
        }
      });
      return data;
    };

    this.checkFamilyNameLocalStorage = (familyName, pipelineStatusObj = {}) => {
      if (familyName !== '') {
        $localStorage.pipelineFamilyInput = familyName;
        $localStorage.pipelineStatus = pipelineStatusObj.PipelineStatus;
      } else {
        $localStorage.pipelineFamilyInput = '';
        $localStorage.pipelineStatus = pipelineStatusObj.PipelineStatus;
      }
    };

    this.getMoveSwalTitle = event => {
      let title = '';
      if (
        event &&
        event.dest &&
        event.dest.sortableScope &&
        event.dest.sortableScope.element &&
        event.dest.sortableScope.element[0]
      ) {
        const sourcedata = event.dest.sortableScope.element[0].dataset.source;

        switch (event.dest.sortableScope.element[0].id) {
          case 'approve':
            title = sourcedata === 'application' ? 'Settled' : 'Application';
            break;
          case 'opportunity':
            title = 'Opportunity';
            break;
          default:
            title = 'Not Proceeding';
            break;
        }
      }
      return title;
    };

    this.onDropAreaMouseIn = dropAreaId => {
      let areaDragged;
      switch (dropAreaId) {
        case 'approve':
          areaDragged = 'isSettledDropAreaEnabled';
          break;
        case 'opportunity':
          areaDragged = 'isOpportunityDropAreaEnabled';
          break;
        case 'notProceeding':
          areaDragged = 'isNotProceedingDropAreaEnabled';
          break;
        default:
          areaDragged = null;
          break;
      }
      return areaDragged;
    };

    this.getLabels = labels => {
      if (!labels) return;
      const labelsList = [...labels];
      labelsList.splice(0, 0, {
        pipelineSettingsLabelID: 0,
        LabelName: 'All',
      });

      return labelsList;
    };

    this.getFilterWithLabel = labels => {
      if (!this.leadFilterData) this.leadFilterData = {};
      if (!labels) return this.leadFilterData;

      const labelFilter = this.leadFilterData.labelId
        ? parseInt(this.leadFilterData.labelId, 10)
        : null;
      const isFilterInList =
        labels.findIndex(
          label => parseInt(label.pipelineSettingsLabelID, 10) === labelFilter
        ) !== -1;

      if (!isFilterInList) {
        const defaultLabelFilter = labels[0].pipelineSettingsLabelID;
        this.leadFilterData.labelId = defaultLabelFilter;
      }

      return this.leadFilterData;
    };

    this.validateFilters = () => {
      if (!this.leadFilterData) {
        this.leadFilterData = {};
      }
    };

    this.objectAssignApplicationFilters = filterData => {
      this.leadFilterData.isLeads = false;
      if (!filterData) return;

      const {
        StatusId: PipelineStatusID,
        AdvisorId: adviserId,
        Probability: probability,
        EnquirySource: enquiryId,
        Referral: referralId,
        LabelId: labelId,
        isCompact,
        IsTableView,
        CustomerSort,
        SortingMode: sortingMode,
        TableViewExcludedColumns: TableViewExcludeColumns,
        PipelineStatusIDs,
        StatusIds,
        ConversionStatusIDs,
        isFetchAllPipelineStatusIDs,
      } = filterData;
      this.leadFilterData = Object.assign(this.leadFilterData, {
        PipelineStatusID,
        PipelineStatusIDs,
        adviserId,
        probability,
        enquiryId,
        referralId,
        isCompact,
        IsTableView,
        CustomerSort,
        sortingMode,
        TableViewExcludeColumns,
        isFetchAllPipelineStatusIDs,
      });

      this.leadFilterData.labelId = uiService.isCorporateUser ? 0 : labelId;
      this.leadFilterData.TableViewExcludeColumnsArray = filterData.TableViewExcludeColumns
        ? filterData.TableViewExcludeColumns.split(',')
        : '';

      return this.leadFilterData;
    };

    this.populateApplicationFilters = filterData => {
      this.leadFilterData.isLeads = false;
      if (!filterData) return;

      filterData.PipelineStatusIDs = [];
      const defer = $q.defer();
      if (
        filterData.StatusIds ||
        filterData.ConversionStatusIDs ||
        filterData.isFetchAllPipelineStatusIDs
      ) {
        pipelineService
          .SettingsStatusGet(true, true)
          .then(response => {
            const statusList = response.data;
            let appStatusGroup = [];
            let otherStatusGroup = [];

            let appStatusIds = [];
            let appConversionStatusIDs = [];

            statusList.forEach(obj => {
              if (obj.TypeName === 'Application') {
                appStatusGroup.push(obj);
              } else if (!obj.PipelineCategoryID) {
                otherStatusGroup.push(obj);
              }
            });
            appStatusIds = filterData.StatusIds
              ? filterData.StatusIds.split(',')
              : [];
            appConversionStatusIDs = filterData.ConversionStatusIDs
              ? filterData.ConversionStatusIDs.split(',')
              : [];

            appStatusIds = appStatusIds.map(id => parseInt(id, 10));
            appConversionStatusIDs = appConversionStatusIDs.map(id =>
              parseInt(id, 10)
            );

            filterData.PipelineStatusIDs = [];

            appStatusGroup.forEach(obj => {
              if (
                appStatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
                filterData.isFetchAllPipelineStatusIDs
              ) {
                filterData.PipelineStatusIDs.push({ ...obj });
              }
            });

            otherStatusGroup.forEach(obj => {
              if (
                appConversionStatusIDs.indexOf(obj.PipelineStatusID) !== -1 ||
                filterData.isFetchAllPipelineStatusIDs
              ) {
                filterData.PipelineStatusIDs.push({ ...obj });
              }
            });
            defer.resolve(this.objectAssignApplicationFilters(filterData));
          })
          .catch(() => defer.reject([]));
      } else {
        defer.resolve(this.objectAssignApplicationFilters(filterData));
      }
      return defer.promise;
    };

    this.objectAssignLeadsFilter = filterData => {
      const {
        StatusId: PipelineStatusID,
        SubStatusId: SubStatusID,
        AdvisorId: adviserId,
        Probability: probability,
        EnquirySource: enquiryId,
        LabelId: labelId,
        IsCompact: isCompact,
        IsTableView,
        CustomerSort,
        SortingMode: sortingMode,
        TableViewExcludedColumns: TableViewExcludeColumns,
        PipelineStatusIDs,
        StatusIds,
        ConversionStatusIDs,
        isFetchAllPipelineStatusIDs,
      } = filterData;

      const referralId = parseInt(filterData.Referral, 10);

      this.leadFilterData = Object.assign(this.leadFilterData, {
        PipelineStatusID,
        PipelineStatusIDs,
        SubStatusID,
        adviserId,
        probability,
        enquiryId,
        referralId,
        isCompact,
        IsTableView,
        CustomerSort,
        sortingMode,
        TableViewExcludeColumns,
        isFetchAllPipelineStatusIDs,
      });

      this.leadFilterData.labelId = uiService.isCorporateUser ? 0 : labelId;
      this.leadFilterData.TableViewExcludeColumnsArray = TableViewExcludeColumns
        ? TableViewExcludeColumns.split(',')
        : '';

      return this.leadFilterData;
    };

    this.populateLeadsFilter = filterData => {
      this.leadFilterData.isLeads = true;
      if (!filterData) return;

      filterData.PipelineStatusIDs = [];
      const defer = $q.defer();
      if (
        filterData.StatusIds ||
        filterData.ConversionStatusIDs ||
        filterData.isFetchAllPipelineStatusIDs
      ) {
        pipelineService
          .SettingsStatusGet(false, true)
          .then(response => {
            const statusList = response.data;
            let statusGroup = [];
            let otherStatusGroup = [];

            let StatusIds = [];
            let ConversionStatusIDs = [];

            statusList.forEach(obj => {
              if (obj.TypeName === 'Lead') {
                statusGroup.push(obj);
              } else if (!obj.PipelineCategoryID) {
                otherStatusGroup.push(obj);
              }
            });
            StatusIds = filterData.StatusIds
              ? filterData.StatusIds.split(',')
              : [];
            ConversionStatusIDs = filterData.ConversionStatusIDs
              ? filterData.ConversionStatusIDs.split(',')
              : [];

            StatusIds = StatusIds.map(id => parseInt(id, 10));
            ConversionStatusIDs = ConversionStatusIDs.map(id =>
              parseInt(id, 10)
            );
            filterData.PipelineStatusIDs = [];
            statusGroup.forEach(obj => {
              if (
                StatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
                filterData.isFetchAllPipelineStatusIDs
              ) {
                filterData.PipelineStatusIDs.push({ ...obj });
              }
            });

            otherStatusGroup.forEach(obj => {
              if (
                ConversionStatusIDs.indexOf(obj.PipelineStatusID) !== -1 ||
                filterData.isFetchAllPipelineStatusIDs
              ) {
                filterData.PipelineStatusIDs.push({ ...obj });
              }
            });

            defer.resolve(this.objectAssignLeadsFilter(filterData));
          })
          .catch(() => defer.reject([]));
      } else {
        defer.resolve(this.objectAssignLeadsFilter(filterData));
      }
      return defer.promise;
    };

    this.getAdvisersForFilter = adviserList => {
      const taskAdviserList = adviserList ? [...adviserList] : [];
      if (!this.leadFilterData) {
        this.validateFilters();
        if (taskAdviserList.length) {
          const defaultAdviser = taskAdviserList[0];
          const defaultAdviserId = defaultAdviser
            ? defaultAdviser.AdvisorsID
            : 0;
          this.leadFilterData.adviserId = defaultAdviserId;
        }
      }
      return taskAdviserList;
    };

    this.getAdvisersListData = adviserList => {
      return this.tasksService.taskAdviserListGet().then(respond => {
        const adviserList = respond;
        const allAdviser = adviserList.filter(item => item.brokerId === 0);
        const newAdviserList = adviserList.filter(
          item =>
            item.accessType === 'Principal Adviser' ||
            item.accessType === 'Adviser'
        );
        return [...allAdviser, ...newAdviserList];
      });
    };

    this.selectStatus = (statusObj, listObj, onSaved) => {
      if (!statusObj || !listObj) return;

      listObj.PipelineStatus = statusObj.PipelineStatus;
      const { PipelineStatusID } = statusObj;
      pipelineService
        .PipelineCards(listObj.PipelineCardsID, { PipelineStatusID })
        .then(response => {
          toaster.pop('success', 'Set', 'Stage has been updated successfully');
          const hasResult = response && response.data && response.data.Result;
          if (hasResult) {
            listObj.LastModifiedDate = response.data.LastUpdate;
          }
          if (isFunction(onSaved)) {
            onSaved();
          }
        })
        .catch(toastError);
    };

    this.formatCardLabelsForView = groupedCards => {
      if (!groupedCards) return;

      _.forOwn(groupedCards, (group, key) => {
        if (!group) return;

        group.forEach(card => {
          if (!card) return;
          const labels = card.Labels;
          const labelsArr = [];
          const hasLabels = card.Labels && card.Labels.length;
          if (hasLabels) {
            const splitArr = labels.split(',');
            splitArr.forEach(item => {
              const splitLabel = item.split(':');
              if (splitLabel.length !== 3) return;

              const label = {};
              label.labelColor = splitLabel[0];
              label.labelName = splitLabel[1];
              label.labelID = splitLabel[2];
              labelsArr.push(label);
            });
          }
          card.LabelArray = labelsArr;
        });
      });
    };

    this.formatCustomerSortForView = () => {
      if (!this.leadFilterData) return;

      const customSortStr = this.leadFilterData.CustomerSort
        ? this.leadFilterData.CustomerSort.trim()
        : null;
      const customerSortArray = customSortStr ? customSortStr.split(',') : [];
      if (!customerSortArray.length) {
        this.leadFilterData.CustomerSortObj = {};
        return;
      }

      const customerSortObj = {};
      customerSortArray.forEach(mode => {
        const modeKeyValue = mode.split(':');
        const modeKey = modeKeyValue[0];
        const sortObjHasModeKey = customerSortObj[modeKey];
        if (!sortObjHasModeKey && modeKey) {
          customerSortObj[modeKey] = [];
        }
        if (modeKeyValue.length === 3) {
          const modePipelineItemId = modeKeyValue[1];
          const modePosition = modeKeyValue[2];
          const PipelineItemId = modePipelineItemId
            ? modePipelineItemId.toString()
            : '';
          const position = modePosition ? modePosition.toString() : '';
          customerSortObj[modeKey].push({
            PipelineItemId,
            position,
          });
        }
      });
      this.leadFilterData.CustomerSortObj = customerSortObj;
    };

    this.initCommonFilters = filterData => {
      if (!filterData) return;

      this.initEnquirySourceFilter(filterData);
      this.initReferralFilter(filterData);
      this.initProbabilityFilter(filterData);
    };

    this.shouldSetFilterValueToDefault = filterValue => {
      const isValueValid = filterValue && filterValue.trim();
      const isValueAll = filterValue === 'All';
      const isValueNone = filterValue === 'None';
      return !isValueValid || isValueAll || isValueNone;
    };

    this.initEnquirySourceFilter = filterData => {
      const isEnquirySourceZero = this.shouldSetFilterValueToDefault(
        filterData.EnquirySource
      );
      filterData.EnquirySource = isEnquirySourceZero
        ? 0
        : parseInt(filterData.EnquirySource, 10);
    };

    this.initReferralFilter = filterData => {
      if (this.shouldSetFilterValueToDefault(filterData.Referral)) {
        filterData.Referral = -1;
      }
    };

    this.initProbabilityFilter = filterData => {
      if (this.shouldSetFilterValueToDefault(filterData.Probability)) {
        filterData.Probability = 'All';
      }
    };

    this.getLabelColors = () => {
      const indexArr = range(10);
      const labelColors = indexArr.reduce((accum, index) => {
        accum[`labelColor${index + 1}`] = false;
        return accum;
      }, {});
      return labelColors;
    };

    this.initCardViewStates = () => {
      this.cardViewStates = {
        cardOnDrag: false,
        isShowState: false,
        isDragged: false,
        isSettledDropAreaEnabled: false,
        isOpportunityDropAreaEnabled: false,
        isNotProceedingDropAreaEnabled: false,
        myParam: {
          IsMobileDisplay: false,
        },
      };
    };

    this.handleDragStart = event => {
      if (!event) return;

      this.cardViewStates.cardOnDrag = true;
      this.cardViewStates.isShowState = true;
      this.cardViewStates.isDragged = true;
      if (this.cardViewStates.myParam) {
        this.cardViewStates.myParam.IsMobileDisplay = true;
      }

      event.source.itemScope.element.addClass('cardTilt');
      event.source.itemScope.card.openOptions = false;
      this.cardViewStates.isSettledDropAreaEnabled = false;
      this.cardViewStates.isOpportunityDropAreaEnabled = false;
      this.cardViewStates.isNotProceedingDropAreaEnabled = false;
    };

    this.handleDragEnd = (event, switchSetting) => {
      if (!event) return;

      this.cardViewStates.cardOnDrag = false;
      this.cardViewStates.isShowState =
        switchSetting && switchSetting.isShowStateSwitch;
      this.cardViewStates.isDragged = false;
      if (this.cardViewStates.myParam) {
        this.cardViewStates.myParam.IsMobileDisplay = false;
      }
      event.source.itemScope.element.removeClass('cardTilt');
      this.dragMoveInterval = null;
    };

    /**
     * Action: whenever you move a card left or right with extra columns hidden
     * your board will automatically scroll with your mouse
     */
    this.handleDragMove = (itemPosition, containment, eventObj) => {
      const containmentId =
        containment && containment.length && containment[0].id;
      const clientWidth =
        containment && containment.length && containment[0].clientWidth;
      const right = eventObj.clientX > clientWidth;
      const left = eventObj.clientX < 80;

      if (right || left) {
        if (!this.dragMoveInterval) {
          this.dragMoveInterval = this.$window.setInterval(() => {
            this.$window.document.getElementById(
              containmentId
            ).scrollLeft += right ? 20 : -20;
          }, 25);
        }
      } else {
        this.$window.clearInterval(this.dragMoveInterval);
        this.dragMoveInterval = null;
      }
    };

    this.isLabelInList = (label, labelList) => {
      if (!label || !labelList) return false;

      const indexOfLabelInList = _.findIndex(labelList, item => {
        const itemLabelId = parseInt(item.pipelineSettingsLabelID, 10);
        const labelId = parseInt(label.labelID, 10);
        return itemLabelId === labelId;
      });

      return indexOfLabelInList !== -1;
    };

    this.isLabelInCard = (label, card) => {
      if (!label || !card || !card.labels) return false;

      const indexOfLabel = _.findIndex(card.labels, cardLbl => {
        const cardLblID = parseInt(cardLbl.labelID, 10);
        const lblID = parseInt(label.pipelineSettingsLabelID, 10);
        return cardLblID === lblID;
      });

      return indexOfLabel !== -1;
    };

    this.pipelineTypeGet = pipelineTypes => {
      if (pipelineTypes && pipelineTypes.length) return [];
      pipelineTypes.forEach(o => {
        if (o.TypeName === 'Lead') {
          o.includes = [
            {
              src: '/assets/views/pipeline/leads/index.html',
              classAdded: 'hidden-xs hidden-sm',
            },
            {
              src: '/assets/views/pipeline/leads/cards.html',
              classAdded: '',
            },
            {
              src: '/assets/views/pipeline/leads/list_view.html',
              classAdded: '',
            },
          ];
        } else if (o.TypeName === 'Application') {
          o.includes = [
            {
              src: '/assets/views/pipeline/applications/index.html',
              classAdded: 'hidden-xs hidden-sm',
            },
          ];
        }
      });
      return pipelineTypes;
    };

    this.customerSortObj = data => {
      if (data && data.length) {
        return data;
      } else {
        return [];
      }
    };

    this.sortingModeArray = leadFilterSortingMode => {
      if (leadFilterSortingMode) {
        return leadFilterSortingMode.split(',');
      } else {
        return [];
      }
    };

    this.showNurtureCampaignsQuestion = enquirySource =>
      enquirySource === 'LM Online Member';

    this.getTotalRecords = list => {
      const isEmptyList = !list || !list.length;
      if (isEmptyList) return 0;

      const firstItem = list[0];
      const totalRecords = firstItem ? firstItem.TotalRecords : 0;
      return totalRecords;
    };

    this.reloadTableView = (tableParams, retainPage) => {
      if (!tableParams) return;

      if (tableParams.page() === 1 || retainPage) {
        tableParams.reload();
      } else {
        tableParams.page(1);
      }
    };

    this.updatePipelineItemInList = (item, list, statusList) => {
      if (!item || !list || !statusList) return;

      const updatedItemList = this.formatListViewData([item], statusList);
      const updatedItem =
        updatedItemList && updatedItemList.length ? updatedItemList[0] : null;

      if (updatedItem) {
        const indexOfItem = _.findIndex(
          list,
          obj => obj.PipelineCardsID === item.PipelineCardsID
        );
        if (indexOfItem !== -1) {
          list[indexOfItem] = updatedItem;
        }
      }
    };

    this.removeLabelFromItem = (removedLabel, item) => {
      if (!removedLabel || !item) return;

      const removedLabelId = parseInt(removedLabel.pipelineSettingsLabelID, 10);
      _.remove(item.LabelArray, label => {
        const labelId = parseInt(label.labelID, 10);
        return labelId === removedLabelId;
      });
    };

    this.getApplicationTableColumns = () => {
      return {
        Lender: { field: 'Lender', title: 'Lender', show: true },
        PipelineStatus: { field: 'PipelineStatus', title: 'Stage', show: true },
        EnquirySource: {
          field: 'EnquirySource',
          title: 'Enquiry Source',
          show: true,
        },
        AdvisorName: { field: 'AdvisorName', title: 'Adviser', show: true },
        ReferrerName: { field: 'ReferrerName', title: 'Referrer', show: true },
        LoanAmount: { field: 'LoanAmount', title: 'Amount', show: true },
        SubStatusName: {
          field: 'SubStatusName',
          title: 'Substatus',
          show: true,
        },
        SubStatusUpdated: {
          field: 'SubStatusUpdated',
          title: 'Substatus Updated',
          show: true,
        },
        Label: { field: 'Label', title: 'Label', show: true },
      };
    };

    this.getLeadsTableColumns = () => {
      return {
        Probability: { field: 'Probability', title: 'Probability', show: true },
        PipelineStatus: { field: 'PipelineStatus', title: 'Stage', show: true },
        EnquirySource: {
          field: 'EnquirySource',
          title: 'Enquiry Source',
          show: true,
        },
        AdvisorName: { field: 'AdvisorName', title: 'Adviser', show: true },
        Referrer: { field: 'Referrer', title: 'Referrer Name', show: true },
        Substatus: { field: 'Substatus', title: 'Substatus', show: true },
        SubStatusUpdated: {
          field: 'SubStatusUpdated',
          title: 'Substatus Updated',
          show: true,
        },
        Label: { field: 'Label', title: 'Label', show: true },
        CreateDate: { field: 'CreateDate', title: 'Date Created', show: true },
      };
    };

    this.getSortingForSet = (isLeads = false, isTableView = false) => {
      const {
        SortColumn,
        SortType,
        PipelineStatusID: StatusId,
        adviserId: AdvisorId,
        probability: Probability,
        labelId: LabelId,
        enquiryId: EnquirySource,
        referralId: Referral,
        ReferralItemID: ReferralItemID,
        ReferralCategoryID: ReferralCategoryID,
        sortingMode: SortingMode,
        CustomerSort,
        TableViewExcludeColumns: TableViewExcludedColumns,
        SubStatusID: SubStatusId,
        isCompact,
        PipelineStatusIDs,
        DateCreatedStart,
        DateCreatedEnd,
      } = this.leadFilterData;

      let StatusIds = [];
      let ConversionStatusIDs = [];
      PipelineStatusIDs.forEach(obj => {
        if (obj.IsConversionStatus) {
          ConversionStatusIDs.push(obj.PipelineStatusID);
        } else {
          StatusIds.push(obj.PipelineStatusID);
        }
      });
      StatusIds = StatusIds.join(',');
      ConversionStatusIDs = ConversionStatusIDs.join(',');

      return {
        SortColumn,
        SortType,
        StatusId,
        AdvisorId,
        LabelId,
        Probability,
        EnquirySource,
        ReferralItemID,
        ReferralCategoryID,
        Referral,
        IsLeads: isLeads,
        IsCompact: isCompact === '1',
        IsTableView: isTableView,
        SortingMode,
        CustomerSort,
        TableViewExcludedColumns,
        SubStatusId,
        StatusIds,
        ConversionStatusIDs,
        PipelineStatusIDs,
        DateCreatedStart,
        DateCreatedEnd,
      };
    };

    this.setColumnSorting = column => {
      if (!column) return;
      if (!this.leadFilterData || !this.leadFilterData.sortingModeObj) return;

      const columnSortingMode = this.leadFilterData.sortingModeObj[column.name];
      if (columnSortingMode) {
        column.SelectedFilterStatus = columnSortingMode;
        column.SelectedFilterStatusObjectify = `${
          column.name
        }:${columnSortingMode}`;
      }
    };

    this.convertSortStrToObj = sortingModeArray => {
      if (sortingModeArray && sortingModeArray.length) {
        this.leadFilterData.sortingModeObj = sortingModeArray.reduce(
          (accum, mode) => {
            const keyValue = mode.split(':');
            if (keyValue.length !== 2) return accum;

            const key = keyValue[0];
            const value = keyValue[1];
            if (key && value) {
              accum[key] = value;
            }
            return accum;
          },
          {}
        );
      }
    };

    return this;
  });
