import moment from 'moment';

class AddressHistoryService {
  constructor(
    generalService,
    dashboardService,
    contactService,
    loanScenarioService
  ) {
    'ngInject';

    this.generalService = generalService;
    this.dashboardService = dashboardService;
    this.contactService = contactService;
    this.loanScenarioService = loanScenarioService;
  }

  populateAddressHistorySuggestions(addressHistory) {
    if (!addressHistory || !addressHistory.Address) return;

    this.generalService
      .placeSearch(addressHistory.Address.formatted_address)
      .then(response => {
        addressHistory.addressSuggestions = response.data;
        addressHistory.showSuggestionModal = true;
      });
  }

  setAddressDetails(propertySet, familyId) {
    const { FullAddress, Address } = propertySet;
    let addressDetailsSetObject = FullAddress;
    const isCustomAddress = Address && Address.isCustomAddress;

    if (isCustomAddress) {
      const { AddressID, ...customAddressDetails } = Address;
      addressDetailsSetObject = { ...FullAddress, ...customAddressDetails };
    }

    return this.dashboardService
      .addressDetailsSet(addressDetailsSetObject)
      .then(({ data }) => {
        const AddressID = data || 0;
        const {
          CategoryId,
          FloorTypeId,
          TitleTypeId,
          TenureTypeId,
          OwnershipTypeId,
          TypeId,
          StartDate,
          EndDate,
          ContactLivingIn,
          AddressValuationExtendedDetailsId,
          RentalAmount,
          FrequencyTypeId,
          NumberOfYears,
          NumberOfMonths,
        } = propertySet;

        const addressObj = {
          FamilyId: familyId,
          IsMailing: false,
          CategoryId,
          FloorTypeId,
          TitleTypeId,
          TenureTypeId,
          OwnershipTypeId,
          TypeId,
          StartDate: moment(StartDate).format('DD/MMM/YYYY'),
          EndDate: EndDate ? moment(EndDate).format('DD/MMM/YYYY') : null,
          ContactLivingIn,
          AddressValuationExtendedDetailsId:
            AddressValuationExtendedDetailsId || 0,
          RentalAmount,
          FrequencyTypeId,
          NumberOfYears: NumberOfYears && +NumberOfYears,
          NumberOfMonths: NumberOfMonths && +NumberOfMonths,
          ...propertySet.Address,
          AddressID,
        };

        return addressObj;
      });
  }

  setContactAddress(propertySet, familyId) {
    return this.setAddressDetails(propertySet, familyId).then(
      (addressObj = {}) => {
        return this.contactService.contactAddressSet([addressObj]);
      }
    );
  }

  setLoanAppAddress(propertySet, familyId) {
    return this.setAddressDetails(propertySet, familyId).then(
      (addressObj = {}) => {
        return this.loanScenarioService.setAddress(addressObj);
      }
    );
  }
}

export default AddressHistoryService;
