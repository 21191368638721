import { toastWarning, toastError, toastSuccess } from 'Common/utilities/alert';
import { camelize } from 'Common/utilities/string';

export default class GamePlanWidgetCtrl {
  constructor(configService, gamePlanService, downloadDataService) {
    'ngInject';

    this.configService = configService;
    this.gamePlanService = gamePlanService;
    this.downloadDataService = downloadDataService;
  }

  $onInit() {
    const { cPAToGamePlan } = this.configService.feature;
    this.isGamePlanOn = !!cPAToGamePlan;
    this.generatingGamePlan = false;
  }

  generateGamePlan() {
    if (this.generatingGamePlan) {
      toastWarning('Processing...', 'Still generating Game Plan pdf');
      return;
    }

    this.generatingGamePlan = true;
    toastWarning('Processing...', 'Generating Game Plan pdf');

    this.gamePlanService.generate(this.loanScenarioId).then(response => {
      if (!response || !response.documentContent) {
        this.generatingGamePlan = false;
        toastError();
        return;
      }

      const { contentType, documentContent } = response;
      const clientFileName = camelize(this.clientHeaderName).replace(' ', '');
      this.downloadDataService.download(
        `data:${contentType};base64,${documentContent}`,
        `game_plan_${clientFileName}.pdf`,
        contentType
      );

      this.generatingGamePlan = false;
      toastSuccess({
        messages: ['Game Plan generated successfully!'],
      });
    });
  }
}
