import { forEach } from 'lodash';
import { COMPANY_TYPES, ENTITY_PARTY_TYPES } from 'Common/constants/partyTypes';

class addTrustModalCtrl {
  constructor(
    companyTrustSharedDataService,
    dashboardService,
    contactService,
    toaster
  ) {
    'ngInject';

    this.companyTrustSharedDataService = companyTrustSharedDataService;
    this.dashboardService = dashboardService;
    this.contactService = contactService;
    this.toaster = toaster;
  }

  back() {
    this.modalInstance.dismiss('cancel');
  }

  isSubmitError(errMessage) {
    this.isSubmitted = true;
    this.formHasError = true;
    this.errorMessage = errMessage;
    this.isSaving = false;
  }

  setOrganisation(sendData) {
    if (sendData.Address && sendData.Address.length) {
      sendData.Address[0].Type = 'Home';
    }

    this.contactService.organisationInfoSet(sendData).then(response => {
      this.toaster.pop(
        'success',
        'Saved!',
        'Contact has been Saved Successfully.'
      );
      const { data: FamilyId } = response;
      const PartyType = String(ENTITY_PARTY_TYPES.COMPANY);
      const isEdit = !!this.entity;
      this.modalInstance.close({
        isEdit,
        FamilyId,
        PartyType,
      });
    });
  }

  setAddress(sendData) {
    this.dashboardService
      .addressDetailsSet(sendData.Address[0])
      .then(postAddressResponse => {
        if (!postAddressResponse) return;
        const addressId = postAddressResponse.data;
        sendData.Address[0] = { AddressID: addressId };
        this.setOrganisation(sendData);
      })
      .catch(() => {
        this.isSaving = false;
      });
  }

  isSubmitValid(sendData) {
    if (!sendData) {
      this.isSaving = false;
      this.isSubmitted = true;
      this.formHasError = true;
      this.errorMessage = 'There seems to be an error. Please try again later';
      return false;
    }
    this.isSubmitted = false;
    this.formHasError = false;
    sendData.Email[0].Type = 'Email';
    sendData.Phone[0].Type = 'Work';
    const dataAddressSet = sendData;
    const trimAddress =
      sendData.Address.length &&
      typeof sendData.Address[0].formatted_address !== 'undefined' &&
      sendData.Address[0].formatted_address.trim();
    if (trimAddress) {
      dataAddressSet.Address[0] = { formatted_address: trimAddress };
      this.setAddress(dataAddressSet);
    } else {
      dataAddressSet.Address[0] = { AddressID: 0 };
      this.setOrganisation(sendData);
    }
  }

  submitNewEntity(isValid, entityForm) {
    this.isSaving = true;
    if (!isValid) {
      this.isSaving = false;
      this.isSubmitError('Please check required fields.');
      this.getErrorMessage(entityForm);
      return false;
    }

    if (this.partyType === COMPANY_TYPES.COMPANY) {
      this.submitNewCompany();
    } else {
      this.submitNewTrust();
    }
  }

  getErrorMessage(entityForm) {
    if (entityForm.email.$invalid) {
      this.errorMessage = 'Please fill a valid email address.';
    } else if (
      !entityForm.dateIncorporated &&
      entityForm.dateIncorporated.$invalid
    ) {
      this.errorMessage = 'Please use an appropriate date.';
    } else {
      this.errorMessage = 'Please check required fields.';
    }
  }

  validateTrustBeneficiaries() {
    let isValid = true;
    forEach(this.entityData.TrustBeneficiary, beneficiary => {
      if (!beneficiary.isSubmitted) {
        isValid = false;
        return false;
      }
    });

    return isValid;
  }

  submitNewTrust() {
    const isBenefeciariesValid = this.validateTrustBeneficiaries();
    if (isBenefeciariesValid) {
      this.entityData.OrganisationType = COMPANY_TYPES.TRUST_STRING;
      const trustSubmitData = this.companyTrustSharedDataService.getTrustSubmitModel(
        this.entityData
      );
      this.isSubmitValid(trustSubmitData);
    } else {
      this.isSubmitError('Please submit the beneficiaries.');
    }
  }

  submitNewCompany() {
    this.entityData.OrganisationType = COMPANY_TYPES.COMPANY_STRING;
    const companySubmitData = this.companyTrustSharedDataService.getCompanySubmitModel(
      this.entityData
    );
    this.isSubmitValid(companySubmitData);
  }

  onPartyTypeChange(partyType, entityForm = null) {
    this.partyType = !partyType ? this.partyType : partyType;
    this.addLabel =
      this.partyType === COMPANY_TYPES.TRUST ? 'Trust' : 'Company';

    if (entityForm) {
      entityForm.$setPristine();
      entityForm.$setUntouched();
    }
  }

  getEntityInfo() {
    if (this.entity) {
      this.modalTitle = 'Edit';
      this.buttonLabel = 'Save Changes';
      this.contactService
        .organisationInfoGet(this.entity.FamilyId)
        .then(response => {
          const { data } = response;
          data.FamilyId = this.entity.FamilyId;
          this.entityData = this.companyTrustSharedDataService.getCompanyTrustModel(
            data
          );
        });
    }
  }

  $onInit() {
    this.entityData = this.companyTrustSharedDataService.getCompanyTrustModel();
    this.buttonLabel = 'Add New Company/Trust';
    this.isSaving = false;
    this.modalTitle = 'Add New';
    this.isSubmitted = false;
    this.formHasError = false;
    this.onPartyTypeChange(this.partyType);
    this.getEntityInfo();
  }
}

export default addTrustModalCtrl;
