import moment from 'moment';
import swal from 'sweetalert';
import { objectLength } from 'Common/utilities/objectValidation';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { COLOR } from 'Common/constants/colors';

const MODAL_NAMES = {
  SMS: `subscription-sms-modal`,
  NPS: `subscription-nps-modal`,
  CAMPAIGN_BREEZE: `subscription-campaign-breeze-modal`,
  BROKERPEDIA: `subscription-broker-pedia-modal`,
  CREDIT_CHECK: `subscription-credit-check-modal`,
  PRICE_FINDER: `subscription-price-finder-modal`,
  E_SIGN: `subscription-e-sign-modal`,
  NEW_SUBSCRIPTION_MODAL: 'subscription-modal',
};

const ALERT_PROPERTY = {
  title: 'Not Available',
  text: 'Video not available',
  type: 'info',
  confirmButtonColor: COLOR.TERRACOTTA,
  confirmButtonText: 'Go Back Later',
  closeOnConfirm: true,
};

export function isCancelled(subscription) {
  if (
    !subscription ||
    !objectLength(subscription) ||
    subscription.subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK
  )
    return false;
  const {
    subscriptionEndDate,
    isActive,
    isCancelled: isCancelledSubscription,
  } = subscription;
  const isStillActiveToDate =
    subscriptionEndDate && moment() <= moment(subscriptionEndDate);
  return isActive && isCancelledSubscription && isStillActiveToDate;
}

export function getModalName(subscriptionTypeId, newSubscriptionModal = false) {
  if (!subscriptionTypeId) return;
  if (newSubscriptionModal) return MODAL_NAMES.NEW_SUBSCRIPTION_MODAL;
  switch (subscriptionTypeId) {
    case SUBSCRIPTIONTYPE.SMS:
      return MODAL_NAMES.SMS;
    case SUBSCRIPTIONTYPE.NPS:
      return MODAL_NAMES.NPS;
    case SUBSCRIPTIONTYPE.CAMPAIGN_BREEZE:
      return MODAL_NAMES.CAMPAIGN_BREEZE;
    case SUBSCRIPTIONTYPE.BROKERPEDIA:
      return MODAL_NAMES.BROKERPEDIA;
    case SUBSCRIPTIONTYPE.CREDIT_CHECK:
      return MODAL_NAMES.CREDIT_CHECK;
    case SUBSCRIPTIONTYPE.PRICE_FINDER:
      return MODAL_NAMES.PRICE_FINDER;
    case SUBSCRIPTIONTYPE.E_SIGN:
      return MODAL_NAMES.E_SIGN;
    default:
  }
}

export function videoNotFoundAlert() {
  swal({
    title: ALERT_PROPERTY.title,
    text: ALERT_PROPERTY.text,
    type: ALERT_PROPERTY.type,
    confirmButtonColor: ALERT_PROPERTY.confirmButtonColor,
    confirmButtonText: ALERT_PROPERTY.confirmButtonText,
    closeOnConfirm: ALERT_PROPERTY.closeOnConfirm,
  });
}
