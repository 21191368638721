import { CONTACT_TYPE } from 'Common/constants/contactType';
import { getFullName, filterInitialOneString } from 'Common/utilities/contact';
import { showActionConfirmation } from 'Common/utilities/alert';

class OnboardingAddedParty {
  cancelPartyAddition() {
    this.editMode = false;
    this.cancelEdit();
  }

  saveParty(party, partyType) {
    this.updateParty({ party, partyType });
  }

  edit() {
    this.editMode = true;
    this.saveCompleted = false;
    this.editParty({ index: this.partyIndex - 1, partyType: this.partyType });
  }

  remove() {
    showActionConfirmation(
      'Remove Party',
      'Are you sure you want to remove party?',
      confirm => {
        if (!confirm) return;
        this.deleteParty({
          party: this.partyDetails,
          partyType: this.partyType,
        });
      }
    );
  }

  initializeValues() {
    this.header = `${this.partyType} ${this.partyIndex}`;
    const { firstName, lastName } = this.partyDetails;
    this.fullName = getFullName(firstName, lastName);
    this.initials = filterInitialOneString(this.fullName);

    const isDirector = this.partyType === CONTACT_TYPE.DIRECTOR;
    this.showWritingOption = isDirector;
  }

  $onChanges(changes) {
    const { partyDetails } = changes;

    if (partyDetails && partyDetails.currentValue) {
      this.initializeValues();
      this.saveCompleted = true;
      this.editMode = false;
    }
  }

  $onInit() {
    this.editMode = false;
  }
}

export default OnboardingAddedParty;
