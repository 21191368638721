import { EDITOR_OPTIONS } from 'Common/config/wysiwigEditor';
import { CONFIG_EMAIL } from 'Common/config/email';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import { CLIENT_ROLE } from 'Common/constants/contactType';
import { PHONE_TYPE } from 'Common/constants/phoneMobileFormat';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import {
  helloBookClientBuilderForUI,
  helloBookClientBuilderForMyCRM,
  helloBookSendBuilderForMyCRM,
  helloBookDefaultSettingsForMyCRM,
  helloBookFamilyLoanForUI,
} from 'Common/mappers/helloBook';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import {
  toastError,
  toastSaveSuccess,
  toastSuccess,
} from 'Common/utilities/alert';
import { convertTextToKey, isValidEmailAddress } from 'Common/utilities/string';
import { isMobileNumberValid } from 'Common/utilities/mobile';

const FACT_FIND_TYPE = {
  PARTIAL: 'partial',
  FULL: 'full',
};
const SEND_HELLO_BOOK_TYPE = {
  NOW: 'now',
  PREVIEW: 'preview',
  SAVE: 'save',
};

const SEND_PREVIEW_LABEL = {
  DEFAULT: 'Send Preview',
  SENT: 'Sent',
};

const DEFAULT_LM_LOGO_PATH = './login/img/lm-stacked-blue-reversed.svg';
export default class HelloBookPreviewGenerator {
  constructor(
    $q,
    $state,
    generalService,
    contactService,
    helloBookService,
    currentUserService,
    contactModelService,
    loanScenarioService,
    actionValidatorService,
    loanApplicationServices,
    $timeout
  ) {
    'ngInject';

    this.factFindType = FACT_FIND_TYPE;
    this.sendType = SEND_HELLO_BOOK_TYPE;
    this.editorOptions = EDITOR_OPTIONS;
    this.sendPreviewButtonLabel = SEND_PREVIEW_LABEL.DEFAULT;

    this.$q = $q;
    this.$state = $state;
    this.generalService = generalService;
    this.contactService = contactService;
    this.helloBookService = helloBookService;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.loanScenarioService = loanScenarioService;
    this.actionValidatorService = actionValidatorService;
    this.loanApplicationServices = loanApplicationServices;
    this.$timeout = $timeout;
  }

  $onInit() {
    if (!this.familyId) this.familyId = this.$state.params.familyId;
    this.clientId = null;
    this.client = {};
    this.mobileNumber = {};
    this.email = {};
    this.selectedApplicationId = 0;
    this.factFindSections = [];
    this.familyLoans = [];
    this.onboardingTeam = CONFIG_EMAIL.ONBOARDING_AU;
    this.itSupportEmail = CONFIG_EMAIL.MYCRM_SUPPORT;
    this.factFindEmail = this.currentUserService.isNZ
      ? CONFIG_EMAIL.ADVISER_SERVICES_NZ
      : CONFIG_EMAIL.MARKETING_AU;
    this.model = {
      email: null,
      phone: null,
      calendlyLink: null,
      editorMessage: '',
      factFindType: this.factFindType.FULL,
      sections: {
        onlineFactFind: false,
        helloBook: true,
        creditGuide: false,
        calendly: false,
      },
    };
    this.isEmailEdit = false;
    this.isPhoneEdit = false;
    this.isSaveLoading = false;
    this.isBrandingReady = false;
    this.isSendNowLoading = false;
    this.isSendPreviewLoading = false;
    this.disableFactFindStatusList = [
      LOAN_STATUS.IN_PROGRESS.LODGED,
      LOAN_STATUS.IN_PROGRESS.PRE_APPROVED,
      LOAN_STATUS.EXISTING.SETTLED,
      LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
    ];

    this.setCalendly();
    this.setCreditGuide();

    this.getFamilyClients().then(() => {
      this.clientId =
        (this.clients.length && this.clients[0] && this.clients[0].personId) ||
        null;
      if (!this.clientId) return;

      this.clientSelected();
    });

    const defer = this.$q.defer();
    this.$q
      .all([this.getFamilyAdviserInfo(), this.getFactFindHeadings()])
      .then(() => {
        this.getDefaultSettings();
        return defer.promise;
      });

    this.populateLoanList();

    this.renderFactFindContent = () => {
      if (this.model.sections.onlineFactFind && this.isBrandingReady) {
        const full =
          this.model.factFindType === this.factFindType.FULL &&
          `<p">
            I’ve invited you to complete an online fact find. Filling this out lets me come up with a solution that
            best suits your needs.
            Click the Get Started button below!
          </p>`;
        const partial =
          this.model.factFindType === this.factFindType.PARTIAL &&
          `<div class="fact-find-selected-items">
            <p>I’ve invited you to answer the following questions in the online fact find:</p>
            <ul>
              ${this.factFindSections &&
                this.factFindSections
                  .filter(s => s.selected)
                  .map(
                    s => `
                <li>${s.name}</li>
              `
                  )
                  .join('')}
            </ul>
          </div>`;
        const html = `
          <div class="fact-find-content">
            ${full || ''}
            ${partial || ''}
            <div class="btn-container disabled">
              <div class="button btn-get-started">Get Started</div>
            </div>
          </div>
        `;

        return html;
      }

      return '';
    };

    this.renderHelloBookAndCreditGuideContent = () => {
      const helloBookAndCreditGuide = `<p>
        We’ve prepared your personal Hello Book & Credit Guide. It includes information on who we are,
        how we help and how the loan process works. Take a look!
      </p>`;

      const helloBook = `<p>
        We’ve prepared your personal Hello Book. It includes information on who we are,
        how we help and how the loan process works. Take a look!
      </p>`;

      const creditGuide = `<p>
        We’ve prepared a Credit Guide for you. It includes all you need to know to make an informed
        decision when choosing to work with us. Take a look!
      </p>`;

      const {
        helloBook: isHelloBook,
        creditGuide: isCreditGuide,
      } = this.model.sections;
      const { showCreditGuide: isShowCreditGuide } = this;

      let content;
      if (isHelloBook && isCreditGuide && isShowCreditGuide)
        content = helloBookAndCreditGuide;
      if (isHelloBook && !isCreditGuide) content = helloBook;
      if (!isHelloBook && isCreditGuide) content = creditGuide;

      return content
        ? `<div class="hello-pack-credit section-content">${content}</div>`
        : '';
    };

    this.renderCalendlyContent = () =>
      this.model.sections.calendly && this.showCalendly
        ? `<div class="calendly section-content"><p>Book in a time to meet that suits you.</p></div>`
        : '';

    this.renderInstructionAndFooterContent = () => {
      const { fullName, mobile, email } = this.adviserInfo || {};
      return `<div class="instruction-content">
        <p>I’m here to help you achieve your goals.</p>
        <p>If you have any questions, let me know!</p>
      </div>

      <div class="footer-content">
        <div>${fullName}</div>
        <div>${mobile}</div>
        <div>${email}</div>
      </div>`;
    };

    this.renderSectionContents = () => {
      const html = `
        ${this.renderFactFindContent()}
        ${this.renderHelloBookAndCreditGuideContent()}
        ${this.renderCalendlyContent()}
        ${this.renderInstructionAndFooterContent()}
      `;

      return html;
    };

    this.renderBrandingWarning = () => `<p>
      Your account isn't quite ready to send Online Fact Finds.
      Not to worry - <a href="mailto:${this.factFindEmail.email}">${
      this.factFindEmail.description
    }</a>
      will be able to get this fixed for you.
    </p>`;

    this.renderCalendlyWarning = () => `<p>
      Your account isn't quite ready to turn on Calendly bookings.
      Not to worry - <a href="mailto:${this.itSupportEmail.email}">${
      this.itSupportEmail.description
    }</a>
      will be able to get this fixed for you.
    </p>`;
  }

  getFamilyClients() {
    return this.contactService
      .clientInformGet(this.familyId)
      .then(({ data }) => {
        if (!data) return;
        this.clients = data
          .map(helloBookClientBuilderForUI)
          .filter(client => client.role === CLIENT_ROLE.ADULT);
      });
  }

  getFamilyAdviserInfo() {
    return this.contactModelService
      .getFamilyAdviserInfo(this.familyId)
      .then(response => {
        this.adviserInfo = response;
        this.isBrandingReady =
          this.adviserInfo.brandingCategoryID ===
          BRANDING_CATEGORY_TYPE.LOAN_MARKET;
        this.getFamilyAdviserTheme();
      });
  }

  getFamilyAdviserTheme() {
    this.contactModelService
      .getFamilyAdviserTheme(this.familyId)
      .then(response => {
        this.adviserTheme = response;
        const isDefaultLMBranding =
          !this.adviserTheme.logoDocId &&
          this.adviserInfo.brandingCategoryID ===
            BRANDING_CATEGORY_TYPE.LOAN_MARKET;

        const isBYOB =
          this.adviserInfo.brandingCategoryID ===
          BRANDING_CATEGORY_TYPE.OWN_BRAND;
        this.isBrandingReady =
          !isBYOB ||
          !!(this.adviserInfo.tradingName && this.adviserTheme.logoDocId);

        if (isBYOB && !this.adviserTheme.logoDocId) {
          this.logoPath = '';
          return;
        }

        if (isDefaultLMBranding) {
          this.logoPath = DEFAULT_LM_LOGO_PATH;
          return;
        }

        if (!this.adviserTheme.logoDocId) return;
        this.generalService
          .documentGet(this.adviserTheme.logoDocId)
          .then(({ data }) => {
            this.logoPath = data
              ? `data:${data.ContentType};base64,${data.DocumentContent}`
              : '';
          });
      });
  }

  getFactFindHeadings() {
    return this.loanApplicationServices.getFactFindHeadings().then(response => {
      this.factFindSections =
        response &&
        response.map(section => {
          const selected = false;
          return {
            selected,
            sectionKey: convertTextToKey(section.name).toLowerCase(),
            ...section,
          };
        });
    });
  }

  getDefaultSettings() {
    const adviserId = this.adviserInfo.familyId;
    this.helloBookService.getDefaultSettings(adviserId).then(data => {
      const {
        message,
        calendlyLink,
        factFindType,
        isCreditEnabled,
        isFactFindEnabled,
        isHelloBookEnabled,
        isCalendlyInviteEnabled,
        factFindSections: factFindSectionsDefault,
      } = data;

      const defaults = {
        calendlyLink,
        factFindType,
        sections: {
          onlineFactFind: !!isFactFindEnabled,
          helloBook: !!isHelloBookEnabled,
          creditGuide: !!isCreditEnabled,
          calendly: !!isCalendlyInviteEnabled,
        },
      };

      if (message) {
        defaults.editorMessage = message;
        this.isMessageEdited = true;
        this.setDefaultEditorMessage();
      }

      this.model = {
        ...this.model,
        ...defaults,
      };

      if (this.factFindSections) {
        this.factFindSections = this.factFindSections.map(section => {
          return {
            ...section,
            selected: factFindSectionsDefault[section.sectionKey],
          };
        });
      }

      this.setCalendly();
      this.setCreditGuide();
    });
  }

  setCalendly() {
    this.showCalendly = this.currentUserService.isAU;

    if (this.currentUserService.isNZ || !this.model.calendlyLink) {
      this.model.sections.calendly = false;
    }
  }

  setCreditGuide() {
    if (this.currentUserService.isNZ) {
      this.showCreditGuide = false;
      this.model.sections.creditGuide = false;
    } else {
      this.showCreditGuide = true;
    }
  }

  setOnlineFacFind() {
    if (
      this.adviserInfo.brandingCategoryID ===
        BRANDING_CATEGORY_TYPE.OWN_BRAND &&
      !this.isBrandingReady
    ) {
      this.model.sections.onlineFactFind = false;
    }
  }

  setDefaultEditorMessage() {
    this.model.editorMessage =
      this.isMessageEdited && this.model.currentFirstName
        ? this.model.editorMessage.replace(
            this.model.currentFirstName,
            this.client.firstName
          )
        : `
      Hi ${this.client.firstName}, <br><br>
      <p>It was great to chat and learn about what you're looking to do.</p>
    `;
  }

  editMessage() {
    this.isMessageEdited = true;
  }

  clientSelected() {
    this.client = this.clients.find(
      client => client.personId === this.clientId
    );
    if (!this.client) return;
    this.setDefaultEditorMessage();
    this.model.currentFirstName = this.client.firstName;
    this.model.email = this.getEmailAddress();
    this.model.phone = this.getMobileNumber();
    this.isValid();
  }

  validateEmail() {
    return isValidObjectValue(() => this.client.email.length);
  }

  getEmailAddress() {
    this.setEmailAddress();
    return this.email.email;
  }

  setEmailAddress() {
    const hasEmail = this.validateEmail();

    if (!hasEmail) {
      this.email = { email: '' };
      return;
    }

    this.email =
      hasEmail &&
      this.client.email.find(email => email.type === EMAIL_CTYPES.CRM_EMAIL);

    if (!this.email) {
      this.email = this.client.email[0];
    }
  }

  onUpdateEmailValue(key, value, isEdit) {
    this.isValidEmail = isValidEmailAddress(value);
    this.isEmailEdit = isEdit;
    this.model.email = value;
    this.isValid();
    if (!this.isValidEmail) return;

    if (this.validateEmail()) {
      this.email.email = value;
    } else {
      const newEmail = {
        email: value,
        type: EMAIL_CTYPES.CRM_EMAIL,
      };
      this.client.email = [newEmail];
      this.email = this.client.email[0];
    }
  }

  validateMobile() {
    return isValidObjectValue(() => this.client.phone.length);
  }

  getMobileNumber() {
    this.mobileNumber = { number: '' };
    if (this.validateMobile()) {
      const mobileNumberIndex = this.client.phone.findIndex(
        phone => phone.type === PHONE_TYPE.MOBILE
      );
      if (mobileNumberIndex > -1) {
        this.mobileNumber = this.client.phone[mobileNumberIndex];
      }
    }
    return this.mobileNumber.number;
  }

  onUpdatePhoneValue(key, value, isEdit) {
    this.isValidMobile = isMobileNumberValid(value);
    this.isPhoneEdit = isEdit;
    this.model.phone = value;
    this.isValid();
    if (!this.isValidMobile) return;

    const newMobile = {
      number: value,
      type: PHONE_TYPE.MOBILE,
    };

    if (this.validateMobile()) {
      const mobilePhone = this.client.phone.find(
        phone => phone.type === PHONE_TYPE.MOBILE
      );

      this.mobileNumber = mobilePhone || newMobile;
      this.mobileNumber.number = value;
    } else {
      this.client.phone = [newMobile];
      this.mobileNumber = newMobile;
    }
  }

  onSaveClient() {
    const request = helloBookClientBuilderForMyCRM(this.client);
    return this.contactService
      .addContact([request])
      .then(() => {
        toastSaveSuccess();
        this.getFamilyClients();
      })
      .catch(toastError);
  }

  toggleSection(key) {
    const { sections } = this.model;
    sections[key] = !sections[key];
    this.isValid();
  }

  isValid() {
    this.isValidForm =
      isMobileNumberValid(this.model.phone) &&
      isValidEmailAddress(this.model.email) &&
      !this.isEmailEdit &&
      !this.isPhoneEdit;

    if (
      this.model.sections.onlineFactFind &&
      this.model.factFindType === this.factFindType.PARTIAL
    ) {
      this.isValidForm = this.isFactFindSectionSelected() && this.isValidForm;
    }
  }

  isFactFindSectionSelected() {
    return this.factFindSections.some(section => section.selected);
  }

  send(sendType) {
    this.isValid();
    if (!this.isValidForm) {
      toastError('Form invalid. Please fill out correctly.');
      return;
    }

    this.setCalendly();
    this.setCreditGuide();
    this.setOnlineFacFind();

    const onConfirmSend = () => this.sendHelloPack(sendType);
    const isSendApplicationFactFind =
      this.model.sections.onlineFactFind &&
      this.activeFactFindScenarioIds.length &&
      !this.activeFactFindScenarioIds.includes(this.selectedApplicationId) &&
      SEND_HELLO_BOOK_TYPE.NOW === sendType;

    if (isSendApplicationFactFind) {
      const personName =
        (this.familyLoans.familyList &&
          this.familyLoans.familyList.length &&
          this.familyLoans.familyList[0].DisplayName) ||
        '';

      this.actionValidatorService.confirmSendFactFind(
        this.activeFactFindLoans,
        personName,
        onConfirmSend
      );
      return;
    }

    this.sendHelloPack(sendType);
  }

  sendHelloPack(sendType) {
    this.setButtonLoading(sendType, true);

    const form = {
      client: this.client,
      factFindSections: this.factFindSections.reduce((accum, section) => {
        accum[section.sectionKey.toLowerCase()] = section.selected;
        return accum;
      }, {}),
      model: this.model,
      adviser: this.adviserInfo,
      sendType,
      selectedApplicationId: this.selectedApplicationId,
    };
    const payload = helloBookSendBuilderForMyCRM(form);
    this.helloBookService
      .send(this.familyId, payload)
      .then(response => {
        if (!response) return;
        this.setButtonLoading(sendType, false);
        toastSuccess('Hello Book Sent Sucessfully!');
        if (sendType === SEND_HELLO_BOOK_TYPE.PREVIEW) {
          this.sendPreviewButtonLabel = SEND_PREVIEW_LABEL.SENT;
          const sendPreviewTimeout = this.$timeout(() => {
            this.sendPreviewButtonLabel = SEND_PREVIEW_LABEL.DEFAULT;
            this.$onDestroy = () => this.$timeout.cancel(sendPreviewTimeout);
          }, 3000);
        }
        if (sendType !== SEND_HELLO_BOOK_TYPE.PREVIEW && this.modalInstance)
          this.modalInstance.close({
            sent: true,
          });
      })
      .catch(() => {
        toastError();
        this.setButtonLoading(sendType, false);
      });
  }

  setButtonLoading(sendType, status) {
    switch (sendType) {
      case SEND_HELLO_BOOK_TYPE.NOW:
        this.isSendNowLoading = status;
        break;
      case SEND_HELLO_BOOK_TYPE.PREVIEW:
        this.isSendPreviewLoading = status;
        break;
      case SEND_HELLO_BOOK_TYPE.SAVE:
        this.isSaveLoading = status;
        break;
      default:
    }
  }

  saveDefault() {
    const adviserId = this.adviserInfo.familyId;
    const settings = {
      adviserId,
      message: this.model.editorMessage,
      factFindType: this.model.factFindType,
      ...this.model.sections,
      factFindSections: this.factFindSections.reduce((accum, section) => {
        accum[convertTextToKey(section.name)] = section.selected;
        return accum;
      }, {}),
    };

    const payload = helloBookDefaultSettingsForMyCRM(settings);
    this.setButtonLoading(SEND_HELLO_BOOK_TYPE.SAVE, true);

    this.helloBookService
      .setDefaultSettings(adviserId, payload)
      .then(() => {
        this.setButtonLoading(SEND_HELLO_BOOK_TYPE.SAVE, false);
        toastSuccess('Defaults Saved Sucessfully!');
      })
      .catch(toastError);
  }

  populateLoanList() {
    const loanType = 'Application';
    this.contactModelService.getLoanTimeline(this.familyId).then(response => {
      this.familyLoans =
        (response &&
          response
            .map(helloBookFamilyLoanForUI)
            .filter(
              loan =>
                loan.loanScenarioId &&
                !this.disableFactFindStatusList.includes(loan.loanStatusId) &&
                loan.pipelineCategory === loanType
            )) ||
        [];
      this.familyLoans.splice(0, 0, {
        loanScenarioId: 0,
        loanName: 'New Loan Application',
      });
      this.getLoanApplicationList();
    });
  }

  getLoanApplicationList() {
    this.loanScenarioService
      .scenarioListGet(this.familyId, '')
      .then(({ data }) => {
        if (!data) return;

        this.activeFactFindScenarioIds =
          data
            .filter(loan => loan.IsLockable && !loan.IsLocked)
            .reduce((accum, current) => {
              accum.push(parseInt(current.LoanScenarioId, 10));
              return accum;
            }, []) || [];

        this.activeFactFindLoans = this.familyLoans.filter(loan => {
          const id = parseInt(loan.loanScenarioId, 10);
          return this.activeFactFindScenarioIds.includes(id);
        });
      });
  }
}
