import angular from 'angular';
import possibleAdverseChanges from './possibleAdverseChanges';
import loanSectionStatus from './loanSectionStatus';
import questionTextAndIcon from './questionTextAndIcon';
import questionYesNo from './questionYesNo';
import questionMultiSelect from './questionMultiSelect';
import questionCheckAndIcon from './questionCheckAndIcon';
import fieldsAnticipatedChanges from './fieldsAnticipatedChanges';
import fieldsIntendedRepayment from './fieldsIntendedRepayment';

export default angular
  .module('loanAppSections.components', [])
  .component('possibleAdverseChanges', possibleAdverseChanges)
  .component('loanSectionStatus', loanSectionStatus)
  .component('questionTextAndIcon', questionTextAndIcon)
  .component('questionYesNo', questionYesNo)
  .component('questionMultiSelect', questionMultiSelect)
  .component('questionCheckAndIcon', questionCheckAndIcon)
  .component('fieldsAnticipatedChanges', fieldsAnticipatedChanges)
  .component('fieldsIntendedRepayment', fieldsIntendedRepayment).name;
