import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';

export default class SubscriptionCancelNowModalCtrl {
  constructor(contactService, crmConfirmation) {
    'ngInject';

    this.contactService = contactService;
    this.crmConfirmation = crmConfirmation;
  }

  $onInit() {
    const { cancelMessage, subscriptionProductId } = this.subscriptionData;
    this.isCreditCheck = !!(
      subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK
    );
    this.buttonState = {
      isLoading: false,
      cancelSelected: true,
    };
    this.modalMessage = {
      header: this.selectedServicesLength
        ? `Unsubscribe to ${this.unsubscribeText}`
        : 'Sorry to see you go!',
      paragraph: this.isCreditCheck
        ? 'Are you sure you like to stop using this service?'
        : cancelMessage,
      cancelNowButton: this.isCreditCheck ? 'Unsubscribe' : 'Immediately',
      endMonthButton: 'End of Month',
    };
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  cancelSubscription(isImmediately) {
    const unsubscribeAll =
      this.familyId && this.subscriptionData && !this.selectedServicesLength;
    this.subscriptionData.isImmediately = isImmediately;
    this.buttonState.cancelSelected = isImmediately;
    this.buttonState.isLoading = true;
    if (unsubscribeAll) {
      this.contactService
        .subscriptionCancel(this.subscriptionData, this.familyId)
        .then(response => {
          if (!response.data) return;
          this.crmConfirmation.open({
            type: 'success',
            title: 'Success',
            description:
              'Cancel Subscription Successful. Your subscription info page has been updated.',
            buttonText: 'Okay got it',
          });
          this.buttonState.isLoading = false;
          this.modalInstance.close(this.subscriptionData);
        });
    } else {
      this.modalInstance.close(this.subscriptionData);
    }
  }
}
