import _ from 'lodash';
import { INSURANCE_LOAN_APP } from 'Common/constants/insuranceOptions';

class LoanAppDetailsService {
  constructor(
    loanScenarioService,
    $q,
    corporateService,
    loanProfilerService,
    $state,
    currentUserService,
    configService
  ) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
    this.corporateService = corporateService;
    this.$q = $q;
    this.loadLoanList = false;
    this.loanProfilerService = loanProfilerService;
    this.$state = $state;
    this.currentUserService = currentUserService;
    this.configService = configService;
  }

  getLoanAppDetails(loanAppId, refreshData) {
    const isDifferentLoanApp =
      parseInt(loanAppId, 10) !== parseInt(this.currentLoanAppId, 10);
    if (isDifferentLoanApp) {
      this.clearLoanAppDetails();
      this.currentLoanAppId = loanAppId;
    }

    const isFetchingLoanApp = this.getLoanAppDetailsPromise;
    if (isFetchingLoanApp) return this.getLoanAppDetailsPromise;

    const isEmptyLoanAppDetails = !this.loanAppDetails;
    if (isEmptyLoanAppDetails || refreshData) {
      this.getLoanAppDetailsPromise = this.loanScenarioService
        .scenarioDetailsGet(loanAppId)
        .then(response => {
          this.loanAppDetails = response && response.data;
          this.getLoanAppDetailsPromise = null;
          return response;
        });
      return this.getLoanAppDetailsPromise;
    }

    return this.fetchedLoanAppDetails();
  }

  fetchedLoanAppDetails() {
    const deferred = this.$q.defer();
    if (this.loanAppDetails) {
      deferred.resolve(this.loanAppDetails);
    } else {
      deferred.reject({
        error: `Something went wrong. Service didn't fetch the Loan App Details.`,
      });
    }
    return deferred.promise;
  }

  clearLoanAppDetails() {
    this.loanAppDetails = null;
    this.getLoanAppDetailsPromise = null;
    this.currentLoanAppId = null;
  }

  ownersGet(loanAppId) {
    const scenarioApplicantList = [];
    return this.loanScenarioService
      .scenarioApplicantListGet(loanAppId)
      .then(response => {
        const { InvolvedPartyPerson, InvolvedPartyEntity } = response.data;
        _.forEach(InvolvedPartyPerson, family => {
          family.forEach(client => {
            const data = {
              BorrowerID: parseInt(client.PersonId, 10),
              FirstName: client.FirstName,
              ClientType: client.Role,
              LastName: client.LastName,
              PreferedName: `${client.FirstName} ${client.LastName}`,
              IsEntity: false,
              IsClient: true,
            };
            scenarioApplicantList.push(data);
          });
        });
        _.forEach(InvolvedPartyEntity, client => {
          const data = {
            BorrowerID: parseInt(client.OrganisationId, 10),
            EntityID: parseInt(client.OrganisationId, 10),
            ClientType: client.EntityTypeName,
            PreferedName: client.FamilyFullName,
            IsEntity: true,
          };
          scenarioApplicantList.push(data);
        });
        return scenarioApplicantList;
      });
  }

  setInsuranceDefault(loanAppId, familyId, userFamilyId) {
    this.corporateService
      .familyBrokerSupportGet(userFamilyId, 0)
      .then(response => {
        const { data } = response;
        if (!data) return;
        const insuranceObj = {
          LoanScenarioId: loanAppId,
          FamilyId: familyId,
          DisplayOptionId: INSURANCE_LOAN_APP.DISPLAY_ID.CHECKBOX,
          answerBool: [true],
        };
        if (data.AutoReferralConcierge) {
          const concierge = {
            ...insuranceObj,
            QuestionId: INSURANCE_LOAN_APP.QUESTIONS.CONCIERGE,
          };
          this.loanScenarioService.loanInsuranceSet(concierge);
        }
        if (data.AutoReferralHome && this.isHomeNowEnabled()) {
          const homeNow = {
            ...insuranceObj,
            QuestionId: INSURANCE_LOAN_APP.QUESTIONS.HOMENOW,
          };
          this.loanScenarioService.loanInsuranceSet(homeNow);
        }
      });
  }

  goToLoanProfiler(loanAppId, tab, familyId, clientIds) {
    this.loanProfilerService
      .getServiceabilityNewBrokerEvent()
      .then(response => {
        this.$state.go('app.loanProfiler', {
          brokerEventId: response.data,
          loanScenarioId: loanAppId,
          tab,
          familyId,
          clientIds,
        });
      });
  }

  isHomeNowEnabled() {
    return (
      this.currentUserService.isAU && this.configService.feature.sendToHomeNow
    );
  }
}

export default LoanAppDetailsService;
