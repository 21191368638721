export const FINANCIAL_OBJECTS = {
  GROUP_TYPE: 'Real Estate',
  PRIMARY_PURPOSE_ID: 1,
};

export default { FINANCIAL_OBJECTS };

export const DEFAULT_ASSETS = {
  RESIDENTIALS: 2,
  OWNER_OCCUPIED: 1,
  STD_RESIDENTIAL: 68,
  STD_APARTMENT: 1,
  CROSS_LEASE: 70,
  RESIDENTIAL_LEASEHOLD: 67,
  SALARY_WAGE: 19,
  FORTNIGHTLY: 5,
  PAYSLIPS: 21,
};
