import moment from 'moment';
import { formatPolicyNumbersCSV } from 'Common/utilities/insurancePipeline';
import { healthCoverBuilderForUI } from 'Common/mappers/insuranceQuote';
import {
  NZ_DATE_INPUT_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';

export function insurancePipelineStatusForUI(status) {
  return {
    id: status.StatusID,
    name: status.StatusName,
  };
}

export function insurancePipelineLabelForUI(label) {
  return {
    id: parseInt(label.LabelID, 10),
    name: label.LabelName,
    color: label.ColorName,
  };
}

export function insurancePipelineLabelForMyCRM(label) {
  return {
    LabelID: parseInt(label.id, 10),
    LabelName: label.name,
    ColorName: label.color,
  };
}

export function insurancePipelineCardForUI(card) {
  return {
    id: card.PipelineCardID,
    quoteId: card.QuoteID,
    policyNumber: card.PolicyNumber,
    annualPremium: card.AnnualPremium,
    dateCreated: card.CreatedDate,
    lastDateUpdated: card.LastUpdatedDate,
    nextWorkDate: card.NextWorkDate,
    status: {
      id: card.PipelineStatusID,
      name: card.PipelineStatusName,
    },
    provider: {
      id: card.ProviderID,
      name: card.ProviderName,
    },
    client: {
      id: card.ClientFamilyID,
      name: card.ClientName,
    },
    adviser: {
      id: card.AdviserID,
      name: card.AdviserName,
      avatar: card.AdviserAvatarUrl,
    },
  };
}

export function insurancePipelineCardBenefitForUI(benefit) {
  return {
    type: benefit.BenefitID,
    name: benefit.BenefitName,
  };
}

export function insurancePipelineColumnForUI(column) {
  return {
    id: column.StatusID,
    name: column.StatusName,
    page: column.Page,
    pageSize: column.PageSize,
    totalCards: column.TotalCardCounts,
    totalAnnualPremium: column.TotalAnnualPremium,
    cards:
      column.Cards &&
      column.Cards.map(card => {
        const mappedCard = insurancePipelineCardForUI(card);
        mappedCard.labels =
          card.CardLabels && card.CardLabels.map(insurancePipelineLabelForUI);
        mappedCard.benefits =
          card.Benefits && card.Benefits.map(insurancePipelineCardBenefitForUI);
        mappedCard.policyNumber = formatPolicyNumbersCSV(
          mappedCard.policyNumber
        );
        return mappedCard;
      }),
  };
}

export function insuranceFamilyListForUI(info) {
  return {
    adviser: info.Adviser,
    city: info.City,
    clientContact: info.ClientContact,
    clientFirstName: info.ClientFirstName,
    clientLastName: info.ClientLastName,
    familyFullName: info.FamilyFullName,
    familyID: info.FamilyID,
    familyType: info.FamilyType,
    totalCount: info.TotalCount,
  };
}

export function insuranceQuoteForUI(quote) {
  return {
    dateCreated: moment(quote.DateCreated).format('MMMM Do YYYY, h:mm:ss a'),
    familyFullName: quote.FamilyFullName,
    familyID: quote.FamilyID,
    module: quote.Module,
    noOfClients: quote.NoOfClients,
    peopleEntity: quote.PeopleEntity,
    quoteId: quote.QuoteID,
    pipelineStatusId: quote.InsurancePipelineStatusID,
  };
}

export function insuranceProvidersQuoteForUI(provider) {
  return {
    id: provider.ProviderID,
    quoteId: provider.QuoteID,
    providerName: provider.ProviderName,
    providerLogoUrl: provider.ProviderLogoUrl,
  };
}

export function insuranceProviderForUI(provider) {
  return {
    id: provider.ProviderID,
    name: provider.ProviderName,
  };
}

export function insuranceClientSearchResult(data) {
  return {
    familyFullName: data.ProviderName,
    familyID: data.ResultFamilyID,
  };
}

export function insurancePipelineConversionSummaryForUI(summary) {
  return {
    statusId: summary.StatusID,
    statusCount: summary.StatusCount,
    conversionPercentage: summary.ConversionPercentage,
  };
}

export function insuranceNotProceededReasonForUI(reason) {
  return {
    id: reason.InsurancePipelineReasonID,
    description: reason.Reason,
  };
}

export function insurancePipelineTableForUI(pipelineTable) {
  return {
    totalRecords: pipelineTable.TotalCardCounts,
    pageNumber: pipelineTable.Page,
    pageSize: pipelineTable.PageSize,
  };
}

export function insurancePipelineFilterForUI(filter) {
  return {
    isCompact: filter.IsCompact,
    viewMode: filter.ViewMode,
    sortColumn: filter.SortExpression,
    sortDirection: filter.SortDirection,
    labelFilter: filter.LabelID,
    adviserFilter: filter.AdviserID,
    providerFilter: filter.ProviderID,
    statusFilters: filter.StatusIds,
    nextWorkDateStart:
      filter.FilterNextWorkDate && moment(filter.FilterNextWorkDate).toDate(),
    nextWorkDateEnd:
      filter.FilterNextWorkDateEnd &&
      moment(filter.FilterNextWorkDateEnd).toDate(),
  };
}

export function insurancePipelineStatusSortingForUI(sorting) {
  return {
    statusId: sorting.StatusID,
    field: sorting.SortExpression,
    direction: sorting.SortDirection,
  };
}

export function insurancePipelineFiltersForMyCRM(filters) {
  return {
    IsCompact: filters.isCompact,
    ViewMode: filters.viewMode,
    SortExpression: filters.sortColumn,
    SortDirection: filters.sortDirection,
    LabelID: filters.labelFilter || null,
    AdviserID: filters.adviserFilter || null,
    ProviderID: filters.providerFilter || null,
    StatusIds: filters.statusFilters || null,
    FilterNextWorkDate: filters.nextWorkDateStart || null,
    FilterNextWorkDateEnd: filters.nextWorkDateEnd || null,
  };
}

export function insurancePipelineStatusSortingForMyCRM(sorting) {
  return {
    StatusID: sorting.statusId,
    SortExpression: sorting.field,
    SortDirection: sorting.direction,
  };
}

export function insuranceClientSearchResultForUI(data) {
  return {
    familyFullName: data.ResultName,
    familyID: data.ResultFamilyID,
  };
}

export function insuranceWorbenchClientsDetailsForUI(workBench) {
  return {
    clientId: workBench.ClientID,
    title: workBench.Title,
    firstName: workBench.FirstName,
    preferredName: workBench.PreferredName,
    middleName: workBench.MiddleName,
    lastName: workBench.LastName,
    fullName: workBench.FullName,
    smoker: workBench.Smoker,
    gender: workBench.Gender,
    dateBirth: workBench.DateBirth,
    age: workBench.Age,
    mobilePhone: workBench.MobilePhone,
    homePhone: workBench.HomePhone,
    workPhone: workBench.WorkPhone,
    email: workBench.Email,
    workEmail: workBench.WorkEmail,
  };
}

export function insuranceWorbenchDetailsForUI(workBench) {
  return {
    pipelineStatusId: workBench.PipelineStatusID,
    quoteId: workBench.QuoteID,
    familyId: workBench.FamilyID,
    bestTimeToCall: workBench.BestTimeToCall,
    nextWorkDate: workBench.NextWorkDate,
    clients:
      workBench.Clients &&
      workBench.Clients.map(data => {
        const clientsData = insuranceWorbenchClientsDetailsForUI(data);
        return clientsData;
      }),
  };
}

export function insuranceAppCoverDetailsForUI(coverDetails) {
  return {
    quoteId: coverDetails.QuoteID,
    providerResultId: coverDetails.ProviderResultID,
    insurer: {
      id: coverDetails.ProviderID,
      name: coverDetails.ProviderName,
    },
    dateStarted:
      coverDetails.DateCreated && moment(coverDetails.DateCreated).toDate(),
    dateSubmitted:
      coverDetails.DateSubmitted && moment(coverDetails.DateSubmitted).toDate(),
  };
}

export function insuranceBenefitCoverDetailsForUI(benefitDetails) {
  return {
    id: benefitDetails.ID,
    client: {
      id: benefitDetails.ClientID,
      firstName: benefitDetails.FirstName,
      lastName: benefitDetails.LastName,
    },
    benefit: {
      id: benefitDetails.BenefitID,
      name: benefitDetails.BenefitName,
    },
    coverAmount: benefitDetails.CoverAmount,
    status: benefitDetails.StatusName,
    policyNumber: benefitDetails.PolicyNumber,
    premium: benefitDetails.BenefitTotalPremium,
    frequency: benefitDetails.Frequency,
    deferralReviewDate:
      benefitDetails.DeferralReviewDate &&
      moment(benefitDetails.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
    tempDeferralReviewDate:
      benefitDetails.DeferralReviewDate &&
      moment(benefitDetails.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
  };
}

export function loadingExclusionBuilderForUI(loadingExclusion) {
  return {
    clientId: loadingExclusion.ClientID,
    fullName: loadingExclusion.ClientName,
    loading: loadingExclusion.Loading,
    date:
      loadingExclusion.ReviewDate &&
      moment(loadingExclusion.ReviewDate).format(NZ_DATE_INPUT_FORMAT),
    tempDate:
      loadingExclusion.ReviewDate &&
      moment(loadingExclusion.ReviewDate).format(NZ_DATE_INPUT_FORMAT),
    details: loadingExclusion.Details,
    isStandardRates: loadingExclusion.IsStandardRate,
    isLoadingExclusion: !loadingExclusion.IsStandardRate,
    selectedOptions: !loadingExclusion.IsStandardRate ? 0 : 1,
    isChild: loadingExclusion.IsChild,
  };
}

export function loadingExclusionBuilderForMyCRM(loadingExclusion) {
  return {
    ClientID: loadingExclusion.clientId,
    ClientName: loadingExclusion.fullName,
    Loading: !loadingExclusion.isStandardRates ? loadingExclusion.loading : 0,
    ReviewDate:
      !loadingExclusion.isStandardRates && loadingExclusion.tempDate
        ? moment(loadingExclusion.tempDate, NZ_DATE_INPUT_FORMAT).format(
            TO_MYCRM_DATE_FORMAT
          )
        : '',
    Details: !loadingExclusion.isStandardRates ? loadingExclusion.details : '',
    IsStandardRate: loadingExclusion.isStandardRates,
    IsChild: loadingExclusion.isChild,
  };
}

export function healthCoverBuilderForMyCRM(healthCover) {
  return {
    ClientID: healthCover.clientId,
    ClientName: healthCover.clientName,
    Excess: healthCover.excess,
    BaseCover: healthCover.isBaseCover,
    Specialist: healthCover.isSpecialistsTest,
    GPPrescription: healthCover.isGpPrescriptions,
    DentalOptical: healthCover.isDentalOptical,
  };
}

export function lifeCoverBuilderForUI(lifeCover) {
  return {
    amount: lifeCover.CoverAmount,
    calcPeriod: lifeCover.CalculationPeriod,
    isIndexed: lifeCover.Indexed,
    isFutureInsurability: lifeCover.FutureInsurability,
  };
}

export function lifeCoverBuilderForMyCRM(lifeCover) {
  return {
    CoverAmount: lifeCover.amount,
    CalculationPeriod: lifeCover.calcPeriod,
    Indexed: lifeCover.isIndexed,
    FutureInsurability: lifeCover.isFutureInsurability,
  };
}

export function traumaCoverBuilderForUI(traumaCover) {
  return {
    amount: traumaCover.CoverAmount,
    childAmount: traumaCover.ChildCoverAmount,
    calcPeriod: traumaCover.CalculationPeriod,
    isComprehensive: traumaCover.Comprehensive,
    isStandalone: traumaCover.Standalone,
    isTraumaBuyBack: traumaCover.TraumaBuyBack,
    isEarlyCancer: traumaCover.EarlyCancerUpgrade,
    isEarlyTrauma: traumaCover.EarlyTraumaBenefit,
    isTpdExtension: traumaCover.TPDExtension,
    isTpdOwnOccupation: traumaCover.TPDOwnOccupation,
    isIndexed: traumaCover.Indexed,
  };
}

export function traumaCoverBuilderForMyCRM(traumaCover) {
  return {
    CoverAmount: traumaCover.amount,
    ChildCoverAmount: traumaCover.childAmount,
    CalculationPeriod: traumaCover.calcPeriod,
    Comprehensive: traumaCover.isComprehensive,
    Standalone: traumaCover.isStandalone,
    TraumaBuyBack: traumaCover.isTraumaBuyBack,
    EarlyCancerUpgrade: traumaCover.isEarlyCancer,
    EarlyTraumaBenefit: traumaCover.isEarlyTrauma,
    TPDExtension: traumaCover.isTpdExtension,
    TPDOwnOccupation: traumaCover.isTpdOwnOccupation,
    Indexed: traumaCover.isIndexed,
  };
}

export function mortgageRepaymentBuilderForUI(mortgageRepaymentCover) {
  return {
    amount: mortgageRepaymentCover.CoverAmount,
    benefitPeriod: mortgageRepaymentCover.BenefitPeriod,
    weekWaitPeriod: mortgageRepaymentCover.WaitPeriod,
    calcPeriod: mortgageRepaymentCover.CalculationPeriod,
  };
}

export function mortgageRepaymentBuilderForMyCRM(mortgageRepaymentCover) {
  return {
    CoverAmount: mortgageRepaymentCover.amount,
    BenefitPeriod: mortgageRepaymentCover.benefitPeriod,
    WaitPeriod: mortgageRepaymentCover.weekWaitPeriod,
    CalculationPeriod: mortgageRepaymentCover.calcPeriod,
  };
}

export function mortgageLifeBuilderForUI(mortgageLifeCover) {
  return {
    amount: mortgageLifeCover.CoverAmount,
    calcPeriod: mortgageLifeCover.CalculationPeriod,
    isIndexed: mortgageLifeCover.Indexed,
  };
}

export function mortgageLifeBuilderForMyCRM(mortgageLifeCover) {
  return {
    CoverAmount: mortgageLifeCover.amount,
    CalculationPeriod: mortgageLifeCover.calcPeriod,
    Indexed: mortgageLifeCover.isIndexed,
  };
}

export function mortgageInterruptionBuilderForUI(mortgageInterruptionCover) {
  return {
    coverAmount: mortgageInterruptionCover.CoverAmount,
    repaymentAmount: mortgageInterruptionCover.RepaymentAmount,
    calculationPeriod: mortgageInterruptionCover.CalculationPeriod,
  };
}

export function mortgageInterruptionBuilderForMyCRM(mortgageInterruptionCover) {
  return {
    CoverAmount: mortgageInterruptionCover.coverAmount,
    RepaymentAmount: mortgageInterruptionCover.repaymentAmount,
    CalculationPeriod: mortgageInterruptionCover.calculationPeriod,
  };
}

export function tpdForBuilderUI(tpdCover) {
  return {
    amount: tpdCover.CoverAmount,
    calcPeriod: tpdCover.CalculationPeriod,
    isIndexed: tpdCover.Indexed,
    isStandalone: tpdCover.Standalone,
    isOwnOccupation: tpdCover.OwnOccupation,
  };
}

export function tpdForBuilderMyCRM(tpdCover) {
  return {
    CoverAmount: tpdCover.amount,
    CalculationPeriod: tpdCover.calcPeriod,
    Indexed: tpdCover.isIndexed,
    Standalone: tpdCover.isStandalone,
    OwnOccupation: tpdCover.isOwnOccupation,
  };
}

export function incomeProtectionBuilderForUI(incomeProtection) {
  return {
    amount: incomeProtection.CoverAmount,
    benefitPeriod: incomeProtection.BenefitPeriod,
    weekWaitPeriod: incomeProtection.WaitPeriod,
    calcPeriod: incomeProtection.CalculationPeriod,
    type: incomeProtection.CoverType,
    isIndexed: incomeProtection.Indexed,
    isPremier: incomeProtection.Premiere,
  };
}

export function incomeProtectionBuilderForMyCRM(incomeProtection) {
  return {
    CoverAmount: incomeProtection.amount,
    BenefitPeriod: incomeProtection.benefitPeriod,
    WaitPeriod: incomeProtection.weekWaitPeriod,
    CalculationPeriod: incomeProtection.calcPeriod,
    CoverType: incomeProtection.type,
    Indexed: incomeProtection.isIndexed,
    Premiere: incomeProtection.isPremier,
  };
}

export function redundancyBuilderForUI(redundancyCover) {
  return {
    amount: redundancyCover.CoverAmount,
    calcPeriod: redundancyCover.CalculationPeriod,
    weekWaitPeriod: redundancyCover.WaitPeriod,
  };
}

export function redundancyBuilderForMyCRM(redundancyCover) {
  return {
    CoverAmount: redundancyCover.amount,
    CalculationPeriod: redundancyCover.calcPeriod,
    WaitPeriod: redundancyCover.weekWaitPeriod,
  };
}

export function familyProtectionBuilderForUI(familyProtection) {
  return {
    amount: familyProtection.CoverAmount,
    calcPeriod: familyProtection.CalculationPeriod,
    isIndexed: familyProtection.Indexed,
    benefitPeriodTerm: familyProtection.BenefitPeriodTerm,
    benefitPeriodYear: familyProtection.BenefitPeriodYear,
  };
}

export function familyProtectionBuilderForMyCRM(familyProtection) {
  return {
    CoverAmount: familyProtection.amount,
    CalculationPeriod: familyProtection.calcPeriod,
    Indexed: familyProtection.isIndexed,
    BenefitPeriodTerm: familyProtection.benefitPeriodTerm,
    BenefitPeriodYear: familyProtection.benefitPeriodYear,
  };
}

export function insurancePolicyOwnershipBuilderForUI(policyOwnership) {
  return {
    id: policyOwnership.PolicyOwnerID,
    quoteId: policyOwnership.QuoteID,
    benefitCoverId: policyOwnership.ProviderResultDetailID,
    familyId: policyOwnership.FamilyID,
    quoteClientId: policyOwnership.QuoteClientID,
    clientId: policyOwnership.FamilyClientID,
    fullName:
      policyOwnership.FullName ||
      `${policyOwnership.FirstName} ${policyOwnership.LastName}`,
    isDependent: policyOwnership.IsChild,
    isOwnership: policyOwnership.IsOwnership,
  };
}

export function insurancePolicyOwnershipBuilderForMyCRM(policyOwnership) {
  return {
    PolicyOwnerID: policyOwnership.id,
    QuoteID: policyOwnership.quoteId,
    ProviderResultDetailID: policyOwnership.benefitCoverId,
    FamilyID: policyOwnership.familyId,
    QuoteClientID: policyOwnership.quoteClientId,
    FamilyClientID: policyOwnership.clientId,
    FullName: policyOwnership.fullName,
    IsChild: policyOwnership.isDependent,
    IsOwnership: policyOwnership.isOwnership,
  };
}

export function insuranceBenefitForUI(benefit) {
  return {
    id: benefit.ID,
    oldId: benefit.BenefitTypeID,
    name: benefit.Name,
    groupName: benefit.GroupName,
    wopRequired: benefit.WOPRequired,
    colorCode: benefit.ColorCode,
  };
}

export function benefitCoverBuilderForUI(details) {
  return {
    benefitId: details.ProviderResultDetailID,
    providerResultId: details.ProviderResultID,
    quoteId: details.QuoteID,
    policyNumber: details.PolicyNumber,
    statusId: details.StatusID,
    commencementDate:
      details.CommencementDate &&
      moment(details.CommencementDate).format(NZ_DATE_INPUT_FORMAT),
    tempCommencementDate:
      details.CommencementDate &&
      moment(details.CommencementDate).format(NZ_DATE_INPUT_FORMAT),
    deferralReviewDate:
      details.DeferralReviewDate &&
      moment(details.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
    tempDeferralReviewDate:
      details.DeferralReviewDate &&
      moment(details.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
    paymentFrequency: details.Frequency,
    waivePolicy: details.WaivePolicy,
    isPremiumAutoCalc: details.AutoPremium,
    totalPremium: details.Premium,
    isPolicyFeeAutoCalc: details.AutoPolicyFee,
    policyFee: details.PolicyFee,
    benefitTypeId: details.BenefitID,
    benefitName: details.BenefitName,
    providerId: details.ProviderID,
    providerName: details.ProviderName,
    productId: details.ProductID,
    productName: details.ProductName,
    isCurrentClaim: details.CurrentClaim,
    isInArrears: details.InArrears,
    clientId: details.FamilyClientID,
    clientName: details.InsuredClientName,
    notes: details.Notes,
    policyOwnership:
      details.PolicyOwnerShip &&
      details.PolicyOwnerShip.map(insurancePolicyOwnershipBuilderForUI),
    loadingExclusions:
      details.LoadingExclusion &&
      details.LoadingExclusion.map(loadingExclusionBuilderForUI),
    benefits: {
      healthCover:
        details.HealthCover && details.HealthCover.map(healthCoverBuilderForUI),
      lifeCover: details.LifeCover && lifeCoverBuilderForUI(details.LifeCover),
      traumaCover:
        details.TraumaCover && traumaCoverBuilderForUI(details.TraumaCover),
      mortgageRepaymentCover:
        details.MortgageRepaymentCover &&
        mortgageRepaymentBuilderForUI(details.MortgageRepaymentCover),
      mortgageLifeCover:
        details.MortgageLifeCover &&
        mortgageLifeBuilderForUI(details.MortgageLifeCover),
      mortgageInterruptionCover:
        details.MortgageInterruption &&
        mortgageInterruptionBuilderForUI(details.MortgageInterruption),
      tpdCover:
        details.TotalPermanentDisability &&
        tpdForBuilderUI(details.TotalPermanentDisability),
      incomeProtectionCover:
        details.IncomeProtection &&
        incomeProtectionBuilderForUI(details.IncomeProtection),
      redundancyCover:
        details.RedundancyCover &&
        redundancyBuilderForUI(details.RedundancyCover),
      familyProtection:
        details.FamilyProtection &&
        familyProtectionBuilderForUI(details.FamilyProtection),
    },
  };
}

export function benefitCoverBuilderForMyCRM(details) {
  const { benefits } = details;
  return {
    ProviderResultDetailID: details.benefitId,
    ProviderResultID: details.providerResultId,
    QuoteID: details.quoteId,
    PolicyNumber: details.policyNumber,
    StatusID: details.statusId,
    CommencementDate:
      details.commencementDate &&
      moment(details.commencementDate, NZ_DATE_INPUT_FORMAT).format(
        TO_MYCRM_DATE_FORMAT
      ),
    DeferralReviewDate:
      details.deferralReviewDate &&
      moment(details.deferralReviewDate, NZ_DATE_INPUT_FORMAT).format(
        TO_MYCRM_DATE_FORMAT
      ),
    Frequency: details.paymentFrequency,
    WaivePolicy: details.waivePolicy,
    AutoPremium: details.isPremiumAutoCalc,
    Premium: details.totalPremium,
    AutoPolicyFee: details.isPolicyFeeAutoCalc,
    PolicyFee: details.policyFee,
    BenefitID: details.benefitTypeId,
    BenefitName: details.benefitName,
    ProviderID: details.providerId,
    ProviderName: details.providerName,
    ProductID: details.productId,
    ProductName: details.productName,
    CurrentClaim: details.isCurrentClaim,
    InArrears: details.isInArrears,
    InsuredClientID: details.clientId,
    InsuredClientName: details.clientName,
    Notes: details.notes,
    PolicyOwnerShip:
      details.policyOwnership &&
      details.policyOwnership.map(insurancePolicyOwnershipBuilderForMyCRM),
    LoadingExclusion:
      details.loadingExclusions &&
      details.loadingExclusions.map(loadingExclusionBuilderForMyCRM),
    HealthCover:
      benefits.healthCover &&
      benefits.healthCover.map(healthCoverBuilderForMyCRM),
    LifeCover:
      benefits.lifeCover && lifeCoverBuilderForMyCRM(benefits.lifeCover),
    TraumaCover:
      benefits.traumaCover && traumaCoverBuilderForMyCRM(benefits.traumaCover),
    MortgageRepaymentCover:
      benefits.mortgageRepaymentCover &&
      mortgageRepaymentBuilderForMyCRM(benefits.mortgageRepaymentCover),
    MortgageLifeCover:
      benefits.mortgageLifeCover &&
      mortgageLifeBuilderForMyCRM(benefits.mortgageLifeCover),
    MortgageInterruption:
      benefits.mortgageInterruptionCover &&
      mortgageInterruptionBuilderForMyCRM(benefits.mortgageInterruptionCover),
    TotalPermanentDisability:
      benefits.tpdCover && tpdForBuilderMyCRM(benefits.tpdCover),
    IncomeProtection:
      benefits.incomeProtectionCover &&
      incomeProtectionBuilderForMyCRM(benefits.incomeProtectionCover),
    RedundancyCover:
      benefits.redundancyCover &&
      redundancyBuilderForMyCRM(benefits.redundancyCover),
    FamilyProtection:
      benefits.familyProtection &&
      familyProtectionBuilderForMyCRM(benefits.familyProtection),
  };
}

export function insuranceBenefitsForUI(benefits) {
  return {
    id: benefits.BenefitID,
    name: benefits.BenefitName,
  };
}

export function insuranceOpportunitiesItemtsForUI(opportunities) {
  return {
    adviserId: opportunities.AdviserID,
    adviserAvatar: opportunities.AdviserAvatarUrl,
    adviserName: opportunities.AdviserName,
    ballparkPremium: opportunities.BallparkPremium,
    clientNames: opportunities.ClientNames,
    dependents: opportunities.Dependents,
    familyId: opportunities.FamilyID,
    id: opportunities.ID,
    lastUpdated: opportunities.LastUpdated,
    module: opportunities.Module,
    reportType: opportunities.ReportType,
    isComplete: opportunities.isComplete,
    benefits:
      (opportunities.Benefits &&
        opportunities.Benefits.map(data => {
          const benefitsData = insuranceBenefitsForUI(data);
          return benefitsData;
        })) ||
      [],
  };
}

export function insuranceOpportunitiesForUI(opportunities) {
  return {
    items:
      (opportunities.QuoteProfilerReports &&
        opportunities.QuoteProfilerReports.map(data => {
          const reports = insuranceOpportunitiesItemtsForUI(data);
          return reports;
        })) ||
      [],
    pageNumber: opportunities.PageNumber,
    pageSize: opportunities.PageSize,
    totalCount: opportunities.TotalCount,
  };
}
