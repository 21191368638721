import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular
  .module('app')
  .controller('TasksCtrl', function tasksCtrl(
    $scope,
    $rootScope,
    $http,
    SweetAlert,
    configService,
    $filter,
    dashboardService,
    imageDataURI,
    $timeout,
    $q,
    $templateCache,
    overviewDashboardService,
    $stateParams,
    contactService,
    userService,
    NgTableParams,
    $uibModal,
    $localStorage,
    DEMO_VIDEO,
    $window,
    tasksSharedData,
    $state,
    toaster,
    downloadDataService,
    tasksService,
    contactSharedDataService
  ) {
    let clientHeaderName = '';
    let $multiSelectListGet = [];
    $scope.familyId = $stateParams.familyId;
    $scope.contactFamilyId = $stateParams.familyId;
    $scope.DisplayMode = 'v1';
    $scope.mainObj = {};
    $scope.mainObj.ShowCompleted = false;
    $scope.OverDueCount = 0;
    $scope.isCheckedAll = false;

    const errorHandler = e => {
      const message =
        e && e.data && e.data.Message ? e.data.Message : 'Please try again.';
      displayError(message);
    };
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'TaskVideoDemoCtrl',
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    if (parseInt($scope.familyId, 10) !== 0) {
      contactService.clientInformGet($scope.familyId).then(response => {
        const { data } = response;
        const clientsData = data;
        if (!clientsData) return;

        clientsData.forEach(client => {
          if (client.Role.toLowerCase() === 'adult') {
            // will only display header name for adults
            if (clientHeaderName !== '') {
              clientHeaderName += ` & ${client.FirstName} ${client.LastName}`;
            } else if (clientsData.length > 1) {
              clientHeaderName += client.FirstName;
            } else {
              clientHeaderName += `${client.FirstName} ${client.LastName}`;
            }
          }
        });
        $scope.clientHeaderName = clientHeaderName;
      });
    }

    $scope.taskResult = modalInstance => {
      modalInstance.result.then(isSaved => {
        if (!isSaved) return;
        $scope.triggerFilter();
      });
    };

    $scope.openAddEditTask = function(taskId = 0, hasFilter) {
      const props = {
        taskId,
        familyId: parseInt($scope.familyId, 10),
        hasFilter,
      };
      const modalInstance = $uibModal.open({
        template: `<task-form-modal modal-instance="vm.modalInstance" has-filter="vm.props.hasFilter" family-id="vm.props.familyId" task-id="vm.props.taskId"></task-form-modal>`,
        windowClass: 'edit-task-window',
        size: 'md',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => props,
        },
      });
      $scope.taskResult(modalInstance);
    };

    // Filters
    $scope.assignedToList = [];
    $scope.assignedToListStored = [];
    $scope.assignedToListSet = [];
    $scope.assignedToListSetStored = [];
    $scope.mainObj.AssignedTo = '';
    $scope.loadAssignedToList = function() {
      $scope.getSignedInBroker();
    };

    // On Open Multiselect Adviser Dropdown
    $scope.multiSelectOpen = function(adviserList) {
      $multiSelectListGet = adviserList;
    };

    let searchInputChangedPromise;
    $scope.searchAssignedOnChange = function(data) {
      if (searchInputChangedPromise) {
        $timeout.cancel(searchInputChangedPromise);
      }
      searchInputChangedPromise = $timeout(() => {
        if ($scope.isCorporateUser) {
          $scope.assignedToList = _.filter($scope.assignedToListStored, obj => {
            return (
              obj.name.toLowerCase().indexOf(data.keyword.toLowerCase()) !==
                -1 || obj.ticked
            );
          });

          $scope.assignedToListSet = _.filter(
            $scope.assignedToListSetStored,
            obj => {
              return (
                obj.name.toLowerCase().indexOf(data.keyword.toLowerCase()) !==
                  -1 || obj.ticked
              );
            }
          );
        }
      }, 1000);
      $scope.$on('$destroy', () => {
        $timeout.cancel(searchInputChangedPromise);
      });
    };
    $scope.removeAllFromAssignTo = function() {
      if (parseInt($scope.mainObj.createdByFilter, 10) === 0) {
        $scope.assignedToListStored = _.reject($scope.assignedToListStored, {
          value: 0,
        });
        $scope.assignedToList = [];
        $scope.assignedToListSet = [];
      } else {
        const $data = {
          name: 'ALL',
          shortName: 'ALL',
          value: 0,
          ticked: false,
        };
        const $allData = _.find($scope.assignedToListStored, { value: 0 });
        if (typeof $allData === 'undefined') {
          $scope.assignedToListStored.unshift($data);
          $scope.assignedToList = [];
          $scope.assignedToListSet = [];
        }
      }
    };

    // Update Default Assign To List
    $scope.updateDefaultAdviserListSet = function() {
      const $adviserListData = _.find($multiSelectListGet, item => {
        return parseInt(item.value, 10) === 0;
      });
      const $adviserNewListData = _.find($scope.assignedToListSet, item => {
        return parseInt(item.value, 10) === 0;
      });
      if (
        typeof $adviserListData === 'undefined' &&
        typeof $adviserNewListData !== 'undefined'
      ) {
        angular.forEach($scope.assignedToListSet, item => {
          if (parseInt(item.value, 10) !== 0) {
            _.map($scope.assignedToList, itemGet => {
              if (itemGet.value === item.value) {
                itemGet.ticked = false;
              }
              return itemGet;
            });
          }
        });
        $scope.assignedToListSet = [];
        $scope.assignedToListSet.push($adviserNewListData);
      } else if (
        (typeof $adviserListData !== 'undefined' &&
          typeof $adviserNewListData === 'undefined') ||
        (typeof $adviserListData !== 'undefined' &&
          typeof $adviserNewListData !== 'undefined')
      ) {
        _.map($scope.assignedToList, itemGet => {
          if (parseInt(itemGet.value, 10) === 0) {
            itemGet.ticked = false;
          }
          return itemGet;
        });
        const assignedToListSetValue = _.reject($scope.assignedToListSet, [
          'value',
          0,
        ]);
        $scope.assignedToListSet = assignedToListSetValue;
      }
      $multiSelectListGet = [];
      $multiSelectListGet = $scope.assignedToListSet;
      if ($scope.isCorporateUser) {
        const resultData = _.find($scope.assignedToListSet, { value: 0 });
        if (resultData) {
          $scope.createdByList = _.reject($scope.createdByList, {
            BrokerID: 0,
          });
        } else {
          const $data = {
            DisplayName: 'ALL',
            BrokerID: 0,
            FirstName: '',
            LastName: '',
          };
          const $allData = _.find($scope.createdByList, { BrokerID: 0 });
          if (typeof $allData === 'undefined') {
            $scope.createdByList.unshift($data);
          }
        }
      }
      $scope.setFilterData();
    };

    // Select Default All Assign To
    $scope.selectDefaultAllAdviser = function() {
      $scope.assignedToListSet = [];
      _.map($scope.assignedToList, itemGet => {
        if (itemGet.value === 0) {
          itemGet.ticked = true;
          $scope.assignedToListSet.push(itemGet);
        } else {
          itemGet.ticked = false;
        }
        return itemGet;
      });
      $scope.setFilterData();
    };

    // Select None Assign To
    $scope.selectDefaultNoneAdviser = function() {
      $scope.assignedToListSet = [];
      _.map($scope.assignedToList, itemGet => {
        itemGet.ticked = false;
        return itemGet;
      });
      $scope.assignedToListSet.push({ value: '' });
      $scope.setFilterData();
    };

    $scope.createdByList = [];
    // get the signed in broker
    $scope.getSignedInBroker = function() {
      let tempData;
      const getBrokerBasicInfo = dashboardService.getBrokerBasicInfo();
      const taskAdviserListGet = contactService.taskAdviserListGet();
      const taskFilterGet = tasksService.taskFilterGet();
      $scope.combineResult = $q
        .all([getBrokerBasicInfo, taskAdviserListGet, taskFilterGet])
        .then(resp => {
          if (!resp || !resp.length || !resp[0].data) return;
          const { data } = resp[0];
          if (!data) return;
          $scope.mainObj.AssignedTo = data.BrokerId;
          const { data: taskAdviser } = resp[1];

          $scope.createdByList = taskAdviser || [];
          const defaultFilterData = {
            dueDateType: 'today',
            assignedBrokerId: [],
            createdByBrokerId: 0,
          };
          const taskFilterData = resp[2] || defaultFilterData;
          $scope.mainObj.createdByFilter =
            parseInt($scope.familyId, 10) !== 0 || $scope.isCorporateUser
              ? defaultFilterData.createdByBrokerId
              : taskFilterData.createdByBrokerId;
          $scope.mainObj.dueDate =
            parseInt($scope.familyId, 10) !== 0
              ? 'all'
              : taskFilterData.dueDateType;
          taskAdviser.forEach(item => {
            const length = 22;
            const trimmedString =
              item.DisplayName.length > length
                ? `${item.DisplayName.substring(0, length - 3)}...`
                : item.DisplayName;
            tempData = {
              name: item.DisplayName,
              shortName: trimmedString,
              value: item.BrokerID,
              ticked: false,
            };
            if (
              taskFilterData.assignedBrokerId &&
              taskFilterData.assignedBrokerId.length &&
              !parseInt($scope.familyId, 10)
            ) {
              const isSelectedAssignedBroker =
                parseInt(taskFilterData.assignedBrokerId[0], 10) === 0 &&
                parseInt(tempData.value, 10) === 0
                  ? 'ALL'
                  : _.find(
                      taskFilterData.assignedBrokerId,
                      selectedItem =>
                        parseInt(selectedItem, 10) ===
                        parseInt(tempData.value, 10)
                    );
              tempData.ticked = !!isSelectedAssignedBroker;
            } else {
              tempData.ticked =
                parseInt(tempData.value, 10) ===
                parseInt($scope.mainObj.AssignedTo, 10);
            }
            if (
              !$scope.isCorporateUser ||
              ($scope.isCorporateUser && tempData.ticked)
            ) {
              $scope.assignedToList.push(tempData);
            }
            $scope.assignedToListStored.push(tempData);
            if (tempData.ticked) {
              $scope.assignedToListSet.push(tempData);
              $scope.assignedToListSetStored.push(tempData);
            }
          });
          if ($scope.screenSize > 768) {
            $scope.loadTasksListTable();
          } else {
            $scope.loadTaskListTableInMobile();
          }
        });
    };

    $scope.setFilterData = () => {
      let adviserFilter = $scope.assignedToListSet;
      adviserFilter = _.map(adviserFilter, 'value');
      const postData = {
        dueDateType: $scope.mainObj.dueDate,
        assignedBrokerId: adviserFilter,
        createdByBrokerId: $scope.mainObj.createdByFilter,
      };
      tasksService.taskFilterUpdate(postData).then(res => {
        if (res.data) return;
        $scope.triggerFilter();
      });
    };

    $scope.isCorporateUserMethod = function() {
      userService.GetUserInfo().then(response => {
        const { data } = response;
        if (!data) return;

        $scope.isCorporateUser = data.AccessType === ACCESS_TYPE.CORPORATE;
      });
    };

    // Table Column Data
    $scope.TaskTableColList = [];
    tasksService.taskColumnSettingsGet().then(response => {
      $scope.TaskTableColList = response.data;
      const findCreatedBy = _.find($scope.TaskTableColList, {
        ColumnValue: 'CreatedBy',
      });
      const TaskColData = _.result(findCreatedBy, 'IsShow');

      $scope.mainObj.TaskColData = !!TaskColData;
    });

    $scope.hideShowColumn = function(taskCol) {
      const $postData = {
        ColumnValue: taskCol.ColumnValue,
        IsShow: taskCol.IsShow,
      };
      tasksService.taskColumnSettingsSet($postData).then(response => {
        if (!parseInt(response.data, 10)) return;
        if (taskCol.ColumnValue !== 'CreatedBy') return;
        $scope.mainObj.TaskColData = !!taskCol.IsShow;
        if (typeof $scope.tasksTableParams !== 'undefined') {
          $scope.tasksTableParams.reload();
        }
      });
    };

    // table
    $scope.taskList = [];
    $scope.loadTasksListTable = function() {
      $scope.mainObj.CurrentPage = 1;
      $scope.tasksTableParams = new NgTableParams(
        {
          count: 20,
          sorting: {
            ActivityDate: 'asc',
          },
        },
        {
          counts: [],
          getData($defer, params) {
            let adviserFilter = $scope.assignedToListSet;
            const dateFilter = $scope.mainObj.dueDate;
            const sortColumn = params.orderBy()[0].substring(1);
            const sortType = _.values(params.sorting())[0].toUpperCase();
            adviserFilter = adviserFilter.map(item => item.value).join(',');
            const createdByFilter =
              $scope.mainObj.TaskColData === true
                ? $scope.mainObj.createdByFilter
                : '';

            const getParams = {
              adviserFilter,
              dateFilter,
              familyId: $scope.familyId,
              createdByFilter,
              isShowCompleted: $scope.mainObj.ShowCompleted,
              pageNumber: params.page(),
              pageSize: params.count(),
              sortColumn,
              sortType,
              taskSearch: $scope.mainObj.searchTask,
            };
            tasksService.getTaskList(getParams).then(response => {
              if (!response || !response.data) return;

              const { data } = response;
              const taskList = data.TaskList;
              params.total(data.TotalRecords);
              $scope.tasksList = taskList;
              $defer.resolve($scope.tasksList);
            });
          },
        }
      );
    };

    const checkAllCheckboxes = (tasksList, isCheckedAll) => {
      tasksList.map(object => {
        object.IsCompleted = isCheckedAll;
        return object;
      });
    };
    const batchCompleteCheckedTask = tasksList => {
      const completedTaskList = [];

      tasksList.forEach(object => {
        const {
          ActivityID: TaskID,
          FamilyID: FamilyId,
          IsCompleted: IsComplete,
        } = object;
        if (!IsComplete) return;

        completedTaskList.push({ TaskID, FamilyId, IsComplete });
      });
      tasksService.taskBatchCompletion(completedTaskList).then(() => {
        $scope.tasksTableParams.reload();
      });
    };

    const completeAllTaskSwal = tasksList => {
      $scope.isCheckedAll = true;
      const taskListNumber = tasksList.length;
      swal(
        {
          title: `${taskListNumber} ${
            taskListNumber === 1 ? 'task is' : 'tasks are'
          } about to be marked as completed`,
          text: 'Please click confirm button if you want to continue',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#5B5084',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true,
        },
        confirm => {
          if (confirm) {
            batchCompleteCheckedTask(tasksList);
          }
          $scope.isCheckedAll = false;
          checkAllCheckboxes(tasksList, $scope.isCheckedAll);
        }
      );
    };

    $scope.changeCreatedByFilter = () => {
      $scope.setFilterData();
      if ($scope.isCorporateUser) {
        $scope.removeAllFromAssignTo();
      }
    };

    $scope.completeAllProcedure = (tasksList, isCheckedAll) => {
      if (!tasksList || !tasksList.length) return;

      checkAllCheckboxes(tasksList, isCheckedAll);
      completeAllTaskSwal(tasksList, isCheckedAll);
    };
    // Load Task In Mobile
    $scope.tasksListTable = [];
    $scope.loadTaskListTableInMobile = function() {
      let adviserFilter = $scope.assignedToListSet;
      const dateFilter = $scope.mainObj.dueDate;
      adviserFilter = adviserFilter.map(item => item.value);

      const getParams = {
        adviserFilter: adviserFilter.toString(),
        dateFilter,
        familyId: $scope.familyId,
        createdByFilter: $scope.mainObj.createdByFilter,
        isShowCompleted: $scope.mainObj.ShowCompleted,
        taskSearch: $scope.mainObj.searchTask,
      };
      tasksService.getTaskList(getParams).then(response => {
        if (!response || !response.data) return;
        $scope.tasksListTable = response.data.TaskList;
      });
    };

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    if ($scope.screenSize >= 992 && $scope.screenSize <= 1524) {
      $scope.isResponsiveTaskFilter = true;
    } else {
      $scope.isResponsiveTaskFilter = false;
    }
    const viewport = function() {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function() {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        $scope.screenSize = newValue.w;
        if (newValue.w >= 992 && newValue.w <= 1524) {
          $scope.isResponsiveTaskFilter = true;
        } else {
          $scope.isResponsiveTaskFilter = false;
        }
      },
      true
    );

    /**   INITIALIZE API CALL   * */
    $scope.initTask = function() {
      $scope.isCorporateUserMethod();
    };
    $scope.initTask();
    $scope.loadAssignedToList();
    /**   filter   * */
    $scope.triggerFilter = function() {
      if (typeof $scope.tasksTableParams !== 'undefined')
        $scope.tasksTableParams.reload();
    };

    // Delete Main Task
    $scope.deleteMainTask = function(familyId, activityId) {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your task list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            tasksService.taskDelete(familyId, activityId).then(response => {
              if (parseInt(response.data, 10)) {
                $scope.triggerFilter();
                swal('Deleted', 'task has been deleted.', 'success');
              } else {
                errorHandler();
              }
            }, errorHandler);
          }
        }
      );
    };

    $scope.cancelFamilyFilter = function() {
      $scope.familyId = 0;
      $scope.triggerFilter();
    };

    const taskCompletionSet = task => {
      tasksService
        .taskSingleCompletion(task.ActivityID, task.FamilyID, task.IsCompleted)
        .then(() => {
          $scope.triggerFilter();
        });
    };

    $scope.toggleOneComplete = task => {
      if (!task.IsCompleted) {
        taskCompletionSet(task);
        return;
      }

      tasksSharedData.completionConfirmation(
        () => {
          taskCompletionSet(task);
        },
        () => {
          task.IsCompleted = false;
        }
      );
    };

    $scope.generateTaskPdf = () => {
      const {
        dueDate,
        searchTask,
        TaskColData,
        createdByFilter: mainCreatedByFilter,
        ShowCompleted,
      } = $scope.mainObj;

      const adviserFilter = $scope.assignedToListSet.map(
        object => object.value
      );
      const dateFilter = dueDate;
      const createdByFilter = TaskColData ? mainCreatedByFilter : 0;

      const familyId = $scope.familyId;
      const isShowCompleted = ShowCompleted;

      const orderByValue =
        ($scope.tasksTableParams && $scope.tasksTableParams.orderBy()) || [];
      const sortColumn = orderByValue.length
        ? orderByValue[0].substring(1)
        : null;

      const sortingValue = $scope.tasksTableParams.sorting();
      const sortingFirstValue = Object.keys(sortingValue).length
        ? sortingValue[Object.keys(sortingValue)[0]]
        : null;
      const sortType = sortingFirstValue.toUpperCase();
      const taskSearch = searchTask;

      tasksService
        .taskPdf(
          adviserFilter,
          dateFilter,
          createdByFilter,
          familyId,
          isShowCompleted,
          sortColumn,
          sortType,
          taskSearch
        )
        .then(
          taskPdfResponse => {
            if (!taskPdfResponse) return;

            const { contentType, documentContent, name } = taskPdfResponse;
            downloadDataService.download(
              `data:${contentType};base64,${documentContent}`,
              `${name}`,
              contentType
            );

            toaster.pop(
              'success',
              'PDF Ready',
              'Successfully generated task PDF'
            );
          },
          () => {
            toaster.pop('error', 'No Task', 'There are 0 task to print.');
          }
        );
    };
    /**   TASK IS CLICKED   * */
    $scope.viewTaskModal = function(id) {
      $uibModal.open({
        templateUrl: 'assets/views/tasks/modals/tasks-view-task.html',
        controller: 'TaskViewTaskModalCtrl',
        resolve: {
          id() {
            return id;
          },
          tasksListTable() {
            return $scope.tasksListTable;
          },
        },
        windowClass: 'view-task-window',
      });
    };

    // Filters
    $scope.filterInitial = function(...args) {
      let i;
      let initials = '';
      if (!args || !args[0]) {
        return;
      }
      for (i = 0; i < args.length; i++) {
        if (!_.isNil(args[i]) && _.isString(args[i])) {
          initials = `${initials}${args[i].charAt(0).toUpperCase()}`;
        }
      }
      return initials;
    };

    // Open Modal For Add Task Template
    $scope.openModalForAddTaskTemplate = function(taskId = 0) {
      const props = {
        taskId,
        familyId: parseInt($scope.familyId, 10),
      };

      const modalInstance = $uibModal.open({
        template: `<task-templated modal-instance="vm.modalInstance" family-id="vm.props.familyId" task-id="vm.props.taskId">`,
        size: 'lg',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        windowClass: 'view-task-window',
        resolve: {
          props: () => props,
        },
      });
      $scope.taskResult(modalInstance);
    };

    // Open Modal For Add Task Template
    $scope.openModalSelectAddTaskModal = function(id) {
      $scope.template_task_id = id;
      $uibModal.open({
        templateUrl: 'assets/views/tasks/modals/mobile-select-modal.html',
        controller: 'MobileSelectModalCtrl',
        scope: $scope,
        windowClass: 'add-task-select-modal-window',
      });
    };

    // Open Modal For Family Filter in Mobile
    $scope.openModalForFamilyFilterMobile = function() {
      $uibModal.open({
        templateUrl:
          'assets/views/tasks/modals/mobile-family-filter-modal.html',
        controller: 'MobileFamilyFilterModalCtrl',
        scope: $scope,
        windowClass: 'add-task-select-modal-window',
      });
    };

    $scope.redirectToContactDetails = familyId => {
      if (!familyId) return;
      contactSharedDataService.redirectToContactDetails(familyId, 'summary');
    };

    $scope.viewContact = data => {
      const clientId = 0;
      const { FamilyID: familyId, IsClient, ContactType } = data;
      let state = '';

      if (ContactType) {
        switch (ContactType) {
          case 'Adviser':
          case 'Principal Adviser':
          case 'Corporate User':
            state = 'app.contactsAdviser';
            break;
          case 'Administrator':
          case 'Super Administrator':
            state = 'app.contactsAssistant';
            break;
          case 'Referrer':
            state = 'app.contactsReferrer';
            break;
          default:
            state = IsClient ? 'app.contactsSingle' : 'app.BusinessAccount';
            break;
        }

        $state.go(state, { clientId, familyId });
      }
    };
  });
