import angular from 'angular';
import _ from 'lodash';

const ACTIVITY_FEED = {
  DEFAULT_FILTER_LIMIT: 5,
  DEFAULT_ACTIVITY_BY_SHOW: false,
  DEFAULT_CONTACT_BY_SHOW: false,
};

angular
  .module('app')
  .controller('ActivitySidebarCtrl', function ActivitySidebarCtrl(
    $window,
    $scope,
    $http,
    $timeout,
    $interval,
    $uibModal,
    $stateParams,
    loanToolsService,
    configService,
    dashboardService,
    notificationSidebarService,
    $rootScope,
    toaster,
    SweetAlert,
    corporateModelService,
    uiService,
    $state,
    commonFnService,
    currentUserService
  ) {
    $scope.isUserNZ = currentUserService.isNZ;
    angular.extend($scope, {
      feedInit: 0,
      notifLimit: 10,
      activityFeed: {
        Setting: {},
      },
    });
    angular.extend($scope.activityFeed, {
      getAll: () => {
        notificationSidebarService
          .getNotificationList($scope.notifLimit)
          .then(response => {
            const { data: feeds } = response;
            if (!feeds || !feeds.length) {
              $scope.activityFeeds = null;
              return;
            }
            feeds.forEach(f => {
              switch (f.NotificationTypeId) {
                case 8:
                  f.Title = `${f.Title} for ${f.ClientFamilyName}.`;
                  break;
                default:
                  break;
              }
            });

            $scope.activityFeeds = feeds;
            $scope.activityFeed.assignIcons($scope.activityFeeds);
          });
      },

      redirectFeed: feed => {
        if (feed.ReferralId && feed.ReferralTypeId) {
          $scope.activityFeed.openReferralView(feed);
          return;
        }

        $scope.activityFeed.openContactSummary(feed);
      },

      openReferralView: feed => {
        $state.go('app.sendReferrals', {
          referralId: feed.ReferralId,
          referralTypeId: feed.ReferralTypeId,
        });
      },

      openContactSummary: feed => {
        commonFnService.hiddenClientForNz().then(isHidden => {
          if (!isHidden) {
            $scope.notificationSidebarObj.showSlided = !$scope
              .notificationSidebarObj.showSlided;
            $scope.notificationSidebarObj.lastClickedItem = 'none';
            if (
              feed.ContactType &&
              (feed.ContactType === 'Adviser' ||
                feed.ContactType === 'Assistant' ||
                feed.ContactType === 'Referrer')
            )
              $state.go(`app.contacts${feed.ContactType}`, {
                clientId: feed.ClientID,
                familyId: feed.ClientFamilyID,
              });
            else
              $state.go('app.contactsSingle', {
                familyId: feed.ClientFamilyID,
                activeTab: 'Summary',
              });
          }
        });
      },

      assignIcons: notifications => {
        angular.forEach(notifications, val => {
          switch (val.NotificationTypeId) {
            case 1: // Lending
              val.icon = '/assets/images/sidebar/icon-lending.svg';
              break;
            case 2: // Pipeline
            case 12: // Pipeline Sub Status
              val.icon = '/assets/images/sidebar/notif-pipeline.svg';
              break;
            case 3: // Loan
            case 10: // NextGenBCM
              val.icon = '/assets/images/sidebar/icon-loan.svg';
              break;
            case 4: // Loan App
              val.icon = '/assets/images/sidebar/icon-new-loan.svg';
              break;
            case 5: // Message
              val.icon = '/assets/images/sidebar/notif-email.svg';
              break;
            case 6: // Contact
              val.icon = '/assets/images/sidebar/notif-email.svg';
              break;
            case 7: // Contact
              val.icon = '/assets/images/sidebar/notif-contact.svg';
              break;
            case 8: // Contact
              val.icon = '/assets/images/sidebar/notif-contact.svg';
              break;
            case 9: // Contact
              val.icon = '/assets/images/sidebar/notif-task.svg';
              break;
            default:
              break;
          }
        });
      },

      markAllAsRead: () => {
        angular.forEach($scope.activityFeeds, val => {
          if (!val.IsRead) {
            $scope.activityFeed.read(val.CommunicationID);
          }
        });
      },

      tickSettings: () => {
        $scope.activityFeed.showSettings = !$scope.activityFeed.showSettings;
        $scope.activityFeed.isActivityByClicked = false;
        $scope.activityFeed.isContactOwnedByClicked = false;
        if ($scope.activityFeed.showSettings === true) {
          $scope.activityFeed.getRecentActivityFeedSetting();
        } else {
          $scope.activityFeed.getAll();
        }
      },

      changeSettings: () => {
        $scope.activityFeed.setRecentActivityFeedSetting();
      },

      getRecentActivityFeedSetting: () => {
        if ($scope.feedInit === 0) {
          let settings;
          notificationSidebarService
            .getRecentActivityFeedSetting()
            .then(response => {
              if (!_.isEmpty(response.data)) {
                settings = response.data;
              } else {
                settings = {
                  PipeLineNotification:
                    $scope.activityFeed.Setting.PipeLineNotification,
                  PipeLineActivityFeed:
                    $scope.activityFeed.Setting.PipeLineActivityFeed,
                  LendingNotification:
                    $scope.activityFeed.Setting.LendingNotification,
                  LendingActivityFeed:
                    $scope.activityFeed.Setting.LendingActivityFeed,
                  LoanNotification:
                    $scope.activityFeed.Setting.LoanNotification,
                  LoanActivityFeed:
                    $scope.activityFeed.Setting.LoanActivityFeed,
                  NewLoanNotification:
                    $scope.activityFeed.Setting.NewLoanNotification,
                  NewLoanActivityFeed:
                    $scope.activityFeed.Setting.NewLoanActivityFeed,
                  MessageNotification:
                    $scope.activityFeed.Setting.MessageNotification,
                  MessageActivityFeed:
                    $scope.activityFeed.Setting.MessageActivityFeed,
                  ContactNotification:
                    $scope.activityFeed.Setting.ContactNotification,
                  ContactActivityFeed:
                    $scope.activityFeed.Setting.ContactActivityFeed,
                  PipeLineSubStatusNotification:
                    $scope.activityFeed.Setting.PipeLineSubStatusNotification,
                  PipeLineSubStatusActivityFeed:
                    $scope.activityFeed.Setting.PipeLineSubStatusActivityFeed,
                  NextGenNotification:
                    $scope.activityFeed.Setting.NextGenNotification,
                  NextGenActivityFeed:
                    $scope.activityFeed.Setting.NextGenActivityFeed,
                };
              }

              $scope.activityFeed.Setting = settings;

              $scope.feedInit++;
            });
        }
      },

      setRecentActivityFeedSetting: () => {
        $scope.activityFeed.isActivityByClicked = false;
        $scope.activityFeed.isContactOwnedByClicked = false;

        const isValidUserActivityByToBeAdded =
          $scope.activityFeed.userSelectedActivityBy &&
          $scope.activityFeed.userSelectedActivityBy.familyID;
        if (isValidUserActivityByToBeAdded) {
          const { userSelectedActivityBy } = $scope.activityFeed;
          $scope.activityFeed.Setting.ActivityByUsers = [
            ...$scope.activityFeed.Setting.ActivityByUsers,
            userSelectedActivityBy,
          ];
        }

        const isValidUserContactOwnedToBeAdded =
          $scope.activityFeed.userSelectedContactOwned &&
          $scope.activityFeed.userSelectedContactOwned.familyID;
        if (isValidUserContactOwnedToBeAdded) {
          const { userSelectedContactOwned } = $scope.activityFeed;
          $scope.activityFeed.Setting.ContactOwnedByUsers = [
            ...$scope.activityFeed.Setting.ContactOwnedByUsers,
            userSelectedContactOwned,
          ];
        }

        notificationSidebarService
          .setRecentActivityFeedSetting($scope.brokerInfo.BrokerId, {
            LendingNotification:
              $scope.activityFeed.Setting.LendingNotification,
            LendingActivityFeed:
              $scope.activityFeed.Setting.LendingActivityFeed,
            PipeLineNotification:
              $scope.activityFeed.Setting.PipeLineNotification,
            PipeLineActivityFeed:
              $scope.activityFeed.Setting.PipeLineActivityFeed,
            LoanNotification: $scope.activityFeed.Setting.LoanNotification,
            LoanActivityFeed: $scope.activityFeed.Setting.LoanActivityFeed,
            NewLoanNotification:
              $scope.activityFeed.Setting.NewLoanNotification,
            NewLoanActivityFeed:
              $scope.activityFeed.Setting.NewLoanActivityFeed,
            MessageNotification:
              $scope.activityFeed.Setting.MessageNotification,
            MessageActivityFeed:
              $scope.activityFeed.Setting.MessageActivityFeed,
            ContactNotification:
              $scope.activityFeed.Setting.ContactNotification,
            ContactActivityFeed:
              $scope.activityFeed.Setting.ContactActivityFeed,
            PipeLineSubStatusNotification:
              $scope.activityFeed.Setting.PipeLineSubStatusNotification,
            PipeLineSubStatusActivityFeed:
              $scope.activityFeed.Setting.PipeLineSubStatusActivityFeed,
            NextGenNotification:
              $scope.activityFeed.Setting.NextGenNotification,
            NextGenActivityFeed:
              $scope.activityFeed.Setting.NextGenActivityFeed,
            ActivityByUsers: $scope.activityFeed.Setting.ActivityByUsers,
            ContactOwnedByUsers:
              $scope.activityFeed.Setting.ContactOwnedByUsers,
          })
          .then(() => {
            $scope.activityFeed.getUpdatedRecentActivityFeedSettings();
            $scope.activityFeed.removeSelectedPerson();
          });
      },

      read: () => {},

      countUnread: () => {
        /**
         * Need to run only once
         * */
        notificationSidebarService.countUnReadNotification().then(response => {
          if (!response || !response.data) return;
          $rootScope.countNotification = response.data;
        });
      },

      getUpdatedRecentActivityFeedSettings() {
        notificationSidebarService
          .getRecentActivityFeedSetting()
          .then(({ data }) => {
            if (data) $scope.activityFeed.Setting = data;
            $scope.activityFeed.removeSelectedPerson();
          });
      },

      isCountryImpacted() {
        return (
          uiService.isCountry('Australia') ||
          uiService.isCountry('New Zealand') ||
          uiService.isCountry('Indonesia')
        );
      },

      searchUser: (searchName, isActivityBy) => {
        corporateModelService
          .contactsByUserOrganizationGet(searchName)
          .then(response => {
            if (response) {
              $scope.usersFilterActivity = isActivityBy ? response : null;
              $scope.usersFilterContactOwned = isActivityBy ? null : response;
            }
          });
      },
      toggleSelectPerson: isActivityBy => {
        $scope.activityFeed.userSelectedActivityBy = [];
        $scope.activityFeed.userSelectedContactOwned = [];
        if (isActivityBy) {
          $scope.activityFeed.isActivityByClicked = isActivityBy;
          $scope.activityFeed.isContactOwnedByClicked = !isActivityBy;
        } else {
          $scope.activityFeed.isContactOwnedByClicked = true;
          $scope.activityFeed.isActivityByClicked = !$scope.activityFeed
            .isContactOwnedByClicked;
        }
      },
      selectActivityByUser: data => {
        $scope.activityFeed.userSelectedActivityBy = data;
        $scope.usersFilterActivity = [];
      },

      selectContactOwnedByResult: data => {
        $scope.activityFeed.userSelectedContactOwned = data;
        $scope.usersFilterContactOwned = null;
      },

      closeSearchFilter() {
        $scope.activityFeed.isActivityByClicked = false;
        $scope.activityFeed.isContactOwnedByClicked = false;
        $scope.usersFilterActivity = null;
        $scope.usersFilterContactOwned = null;
        $scope.activityFeed.removeSelectedPerson();
      },

      isValidUserActivityBy() {
        return $scope.activityFeed.userSelectedActivityBy
          ? $scope.activityFeed.userSelectedActivityBy.familyID
          : false;
      },

      isValidUserContactOwned() {
        return $scope.activityFeed.userSelectedContactOwned
          ? $scope.activityFeed.userSelectedContactOwned.familyID
          : false;
      },

      searchActivityByHasResults() {
        return (
          !!$scope.usersFilterActivity && !!$scope.usersFilterActivity.length
        );
      },

      searchContactOwnedByHasResults() {
        return (
          !!$scope.usersFilterContactOwned &&
          !!$scope.usersFilterContactOwned.length
        );
      },

      removeSelectedPerson() {
        const {
          userSelectedActivityBy,
          ...removedSelectedActivity
        } = $scope.activityFeed;
        $scope.activityFeed = removedSelectedActivity;
        const {
          userSelectedContactOwned,
          ...removedSelectedContactOwned
        } = $scope.activityFeed;
        $scope.activityFeed = removedSelectedContactOwned;
      },

      removePersonByActivity: data => {
        const removed = $scope.activityFeed.Setting.ActivityByUsers;
        $scope.activityFeed.Setting.ActivityByUsers = removed.filter(
          person => person.FamilyID !== data.FamilyID
        );
        $scope.activityFeed.changeSettings();
      },

      removePersonByContactOwned: data => {
        const removed = $scope.activityFeed.Setting.ContactOwnedByUsers;
        $scope.activityFeed.Setting.ContactOwnedByUsers = removed.filter(
          person => person.FamilyID !== data.FamilyID
        );
        $scope.activityFeed.changeSettings();
      },

      getRangeFilter(data, showMore) {
        if (!data) return;
        const length = data.length;
        const isShowMoreThanLimit =
          $scope.activityFeed.filterLimit > length || showMore;
        if (isShowMoreThanLimit) return length % 2 ? _.range(1) : _.range(2);
        return $scope.activityFeed.filterLimit % 2 ? _.range(1) : _.range(2);
      },

      getShowString(isShowMoreActive) {
        return isShowMoreActive ? 'less' : 'more';
      },

      isAlreadySelectedActivityBy: data => {
        if (!data) return;
        const duplicatesActivityBy = $scope.activityFeed.Setting.ActivityByUsers.filter(
          person => person.FamilyID === data.familyID
        );
        return (duplicatesActivityBy && duplicatesActivityBy.length) || 0;
      },

      isAlreadySelectedContactOwnedBy: data => {
        if (!data) return;
        const duplicatesContactOwnedBy = $scope.activityFeed.Setting.ContactOwnedByUsers.filter(
          person => person.FamilyID === data.familyID
        );
        return (
          (duplicatesContactOwnedBy && duplicatesContactOwnedBy.length) || 0
        );
      },

      seeMoreUpdates: () => {
        /**
         * compare if unread is greater than limit.
         * unread should always be greater than limit
         * if fails, limit goes back to 10
         * */
        // if($scope.notifLimit <= $rootScope.countNotification) {
        $scope.notifLimit += 10;
        $scope.activityFeed.getAll();
        if ($scope.notifLimit >= 20) {
          $scope.sidebarHeight = angular.element('.crm-page').height();
        } else {
          $scope.sidebarHeight = angular
            .element('.sidebar-scroll')
            .removeAttr('style');
        }
        // $scope.sidebarHeight = angular.element('.activity-feeds').removeAttr("style");
        // $scope.activityFeed.changeSidebarHeight();

        /* }else{

                 $scope.notifLimit = 10;
                 $scope.sidebarHeight = angular.element('.activity-feeds').removeAttr("style");
                 $scope.activityFeed.changeSidebarHeight();

                 } */
      },
      init: () => {
        $scope.activityFeed.showSettings = false;

        $scope.activityFeed.countUnread();

        $scope.activityFeed.userSelectedActivityBy = [];
        $scope.activityFeed.userSelectedContactOwned = [];
        $scope.activityFeed.filterLimit = ACTIVITY_FEED.DEFAULT_FILTER_LIMIT;
        $scope.activityFeed.activityByShowMore =
          ACTIVITY_FEED.DEFAULT_ACTIVITY_BY_SHOW;
        $scope.activityFeed.contactOwnedByShowMore =
          ACTIVITY_FEED.DEFAULT_CONTACT_BY_SHOW;
      },
    });

    $scope.activityFeed.init();
  });
