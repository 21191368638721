import swal from 'sweetalert';
import { responseBuilderForUI } from 'Common/mappers/response';
import {
  validCustomAPIResponse,
  showCurrentError,
} from 'Common/utilities/response';

import { constructConfigRequest } from 'Common/utilities/request';
import { httpHeaders } from 'Common/config/http';

class HttpClient {
  constructor(
    $http,
    configService,
    $window,
    $timeout,
    sessionService,
    $q,
    $location,
    $cacheFactory
  ) {
    'ngInject';

    this.$http = $http;
    this.configService = configService;
    this.$window = $window;
    this.$timeout = $timeout;
    this.sessionService = sessionService;
    this.$q = $q;
    this.$location = $location;
    this.$cacheFactory = $cacheFactory;
    this.$http.defaults.headers.common = httpHeaders && httpHeaders.common;
    this.cache =
      this.$cacheFactory.get('httpRequest') ||
      this.$cacheFactory('httpRequest');

    this.handleError = this.handleError.bind(this);
  }

  updateSessionTime(isSessionAlreadyUpdated) {
    this.sessionService.resetSessionTime(isSessionAlreadyUpdated);
  }

  updateSessionToken() {
    this.sessionService.updateSession();
  }

  errorCallback(e) {
    const errorStatus = [404, 400, 500];
    const message = e.message || 'Please contact support.';
    if (e.status === 401) return this.updateSessionToken();
    if (validCustomAPIResponse(e)) return showCurrentError(e);
    if (errorStatus.indexOf(e.status) !== -1)
      swal('Yikes! Something is wrong', message, 'error');
  }

  handleError(e) {
    const response = responseBuilderForUI(e);
    this.errorCallback(response);
    return response;
  }

  getResourceUrl(url) {
    let urlValue = url;
    const splitUrl = url.split('?');

    if (
      typeof this.configService.exclude !== 'undefined' &&
      this.configService.exclude.indexOf(splitUrl[0]) !== -1
    ) {
      urlValue = `${this.$location.protocol()}//${this.$location.host()}/json/${url.replace(
        /\?/,
        '.json?'
      )}`;
    } else {
      urlValue = `${this.configService.resource}/${url}`;
    }

    return urlValue;
  }

  removeCache(removeCache, url) {
    if (removeCache) {
      const timeoutResponse = this.$timeout(() => {
        this.cache.remove(url);
        this.$timeout.cancel(timeoutResponse);
      }, 5000);
    }
  }

  get(
    url,
    params = {},
    cache = false,
    removeCache = true,
    useDefaultErrorCallback = true
  ) {
    const apiUrl = this.getResourceUrl(url);
    this.updateSessionTime();
    if (cache) {
      this.removeCache(removeCache, url);
      let cachePromise = this.cache.get(url);
      if (cachePromise) {
        cachePromise = this.cache.get(url).then(response => {
          const validArrayResponse =
            response && response.data && Array.isArray(response.data);
          if (validArrayResponse) {
            return { data: [...response.data] };
          }
          return response;
        });
      }
      return (
        cachePromise ||
        this.cache.put(
          url,
          this.$http
            .get(apiUrl, constructConfigRequest({ params }))
            .then(response => responseBuilderForUI(response))
            .catch(this.handleError)
        )
      );
    }

    return this.$http
      .get(apiUrl, constructConfigRequest({ params }))
      .then(response => responseBuilderForUI(response))
      .catch(e => {
        const response = responseBuilderForUI(e);
        if (useDefaultErrorCallback) this.errorCallback(response);
        return response;
      });
  }

  post(url, data = {}, params = {}, useDefaultErrorCallback = true) {
    const apiUrl = this.getResourceUrl(url);
    this.updateSessionTime();
    return this.$http
      .post(apiUrl, data, constructConfigRequest({ params }))
      .then(response => responseBuilderForUI(response))
      .catch(e => {
        const response = responseBuilderForUI(e);
        if (useDefaultErrorCallback) this.errorCallback(response);
        return response;
      });
  }

  put(url, data = {}, params = {}) {
    const apiUrl = this.getResourceUrl(url);
    this.updateSessionTime();
    return this.$http
      .put(apiUrl, data, constructConfigRequest({ params }))
      .then(response => responseBuilderForUI(response))
      .catch(this.handleError);
  }

  patch(url, data = {}, params = {}) {
    const apiUrl = this.getResourceUrl(url);
    this.updateSessionTime();
    return this.$http
      .patch(apiUrl, data, constructConfigRequest({ params }))
      .then(response => responseBuilderForUI(response))
      .catch(this.handleError);
  }

  delete(url, params = {}) {
    const apiUrl = this.getResourceUrl(url);
    this.updateSessionTime();
    return this.$http
      .delete(apiUrl, constructConfigRequest({ params }))
      .then(response => responseBuilderForUI(response))
      .catch(this.handleError);
  }
}

export default HttpClient;
