import { LIST_OF_QUESTIONS } from 'Common/constants/customerObjectives';

export const CUSTOMER_OBJECTIVES = {
  [LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE]: 'Select Preference',
  [LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT]: 'Choose reason',
  [LIST_OF_QUESTIONS.RATE_DURATION]: 'Select duration',
  [LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS]: 'Enter Details',
  [LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE]: 'Add Preference',
  [LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT]: 'Choose reason',
  [LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE]: 'Add Preference',
  [LIST_OF_QUESTIONS.REPAYMENT_FREQUENCY]: 'Select frequency',
  [LIST_OF_QUESTIONS.REPAYMENT_INTEREST_ONLY]: 'Choose period',
  [LIST_OF_QUESTIONS.REPAYMENT_OPTION]: 'Choose option',
  [LIST_OF_QUESTIONS.REPAYMENT_IMPORTANT]: 'Choose reason',
  [LIST_OF_QUESTIONS.CONFLICT_DECLARATION_DETAILS]:
    'Set priorities and reasons',
  [LIST_OF_QUESTIONS.OTHER_REQUIREMENTS_DETAILS]: 'Enter details',
  [LIST_OF_QUESTIONS.REASON_FOR_REFINANCE]: 'Add Reason',
  [LIST_OF_QUESTIONS.EXISTING_CREDIT_CARD_DETAILS]: 'Enter details',
};
