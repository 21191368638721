import { showActionConfirmation, displaySuccess } from 'Common/utilities/alert';
import { getRecipientDetails } from 'Common/utilities/loanOpportunity';

export default class OpportunityCommunicationsCtrl {
  constructor(
    $uibModal,
    $timeout,
    loanScenarioModelService,
    utilitiesService,
    colorService,
    loanOpportunityService,
    currentUserService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.loanScenarioModelService = loanScenarioModelService;
    this.loanOpportunityService = loanOpportunityService;
    this.currentUserService = currentUserService;
    this.utilitiesService = utilitiesService;
    this.colorService = colorService;

    this.onDeleteTransaction = this.onDeleteTransaction.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
  }

  $onInit() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.totalRecords = 0;
    this.getCommunications();
  }

  $onDestroy() {
    this.timer && this.$timeout.cancel(this.timer);
  }

  openCommunicationList(bool) {
    this.isCommunicationListOpen = bool;
  }

  getCommunications(pageNumber = 1) {
    this.pageNumber = pageNumber;
    this.isLoadingEmails = true;
    const params = {
      familyId: this.familyId,
      loanId: this.loanId,
      pageSize: this.pageSize,
      gmail: false,
      email: true,
      phone: true,
      searchCriteria: '',
    };
    const { pageSize } = params;

    const args = {
      pageNumber,
      pageSize,
    };

    this.loanOpportunityService
      .getCommunicationsList(this.loanId, args)
      .then(({ communications, totalRecords }) => {
        this.isLoadingEmails = false;
        this.communications = communications || [];
        this.totalRecords = totalRecords || 0;
      });
  }

  getRecipientsChoices() {
    const { InvolvedPartyPerson, InvolvedPartyEntity } =
      this.involvedParties || {};

    const personRecipients = getRecipientDetails(InvolvedPartyPerson);
    const entityRecipients = getRecipientDetails(InvolvedPartyEntity);

    return this.addColorAndInitials([...personRecipients, ...entityRecipients]);
  }

  addColorAndInitials(recipientList) {
    if (!recipientList) return [];
    return recipientList.reduce((accum, elem) => {
      return accum.concat({
        ...elem,
        initials: this.utilitiesService.filterInitialOneString(elem.name),
        backgroundColor: this.colorService.getRandomColor(),
      });
    }, []);
  }

  openEmailSmsModal(isEmail, obj) {
    const recipientsChoices = this.getRecipientsChoices();
    const props = {
      obj,
      familyId: this.familyId,
      loanId: this.loanId,
      recipientsChoices,
    };

    const component = isEmail ? 'email' : 'sms';
    this.$uibModal
      .open({
        template: `<opportunity-send-${component}-modal
                  modal-instance="vm.modalInstance"
                  ${component}="vm.props.obj"
                  family-id="vm.props.familyId"
                  loan-id="vm.props.loanId"
                  recipients-choices="vm.props.recipientsChoices"
                ></opportunity-send-${component}-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: `opportunity-send-${component}-modal`,
        size: 'lg',
        resolve: {
          props: () => props,
        },
      })
      .result.then(res => {
        if (res.isRefresh) {
          this.timer = this.$timeout(() => {
            this.$timeout.cancel(this.timer);
            this.getCommunications();
          }, 2000);
        }
      });
  }

  onDeleteTransaction(transaction) {
    const transactionType =
      transaction.type !== 'Email' ? 'SMS' : transaction.type;
    showActionConfirmation(
      `Please confirm action`,
      `Are you sure you want to delete this ${transactionType}? This action cannot be undone.`,
      confirm => (confirm ? this.deleteTransaction(transaction) : null)
    );
  }

  deleteTransaction(transaction) {
    const { emailId: communicationId } = transaction;
    if (!communicationId) return;
    const params = {
      workbenchLogID: 0,
      communicationId,
      loanId: this.loanId,
    };

    this.loanScenarioModelService.deleteCommunication(params).then(() => {
      if (transaction.modalInstance) transaction.modalInstance.dismiss('');
      displaySuccess(`Transaction deleted successfully`);
      this.timer = this.$timeout(() => {
        this.getCommunications();
        this.$timeout.cancel(this.timer);
      }, 2000);
    });
  }

  viewEmailSmsModal(isEmail, obj) {
    const props = {
      transaction: obj,
      isEmail,
      onDeleteTransaction: this.onDeleteTransaction,
    };

    this.$uibModal.open({
      template: `<opportunity-view-transaction-modal
        transaction="vm.props.transaction"
        is-email="vm.props.isEmail"
        on-delete-transaction="vm.props.onDeleteTransaction($e.transaction)"
        modal-instance="vm.modalInstance"
      >
      </opportunity-view-transaction-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      windowClass: `opportunity-view-transaction-modal`,
      size: 'lg',
      resolve: {
        props: () => props,
      },
    });
  }
}
