import { pdfBuilderForUI } from 'Common/mappers/document';
import { helloBookDefaultSettingsForUI } from 'Common/mappers/helloBook';

export default class HelloBookService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'hello-book';
  }

  generate(familyId) {
    return this.httpClient
      .get(`${this.baseUrl}/${familyId}`)
      .then(({ data }) => pdfBuilderForUI(data));
  }

  send(familyId, model) {
    return this.httpClient
      .post(`${this.baseUrl}/${familyId}/send`, model)
      .then(({ data }) => data);
  }

  setDefaultSettings(adviserId, model) {
    return this.httpClient
      .post(`${this.baseUrl}/adviser/${adviserId}/settings`, model)
      .then(({ data }) => data);
  }

  getDefaultSettings(adviserId) {
    return this.httpClient
      .get(`${this.baseUrl}/adviser/${adviserId}/settings`)
      .then(({ data }) => data && helloBookDefaultSettingsForUI(data));
  }
}
