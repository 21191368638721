import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';

export const checkIfValidPageSource = pageSource => {
  return !!PAGE_SOURCE[pageSource];
};

export const openVideoModal = ($uibModal, pageSource) => {
  if (!checkIfValidPageSource(pageSource)) return false;

  const props = {
    pageSource,
  };
  return $uibModal.open({
    template: `<video-modal-for-youtube
      page-source='vm.props.pageSource'
      modal-instance='vm.modalInstance'
      >
      </video-modal-for-youtube>`,
    controller: 'CommonModalPlaceholderCtrl',
    controllerAs: 'vm',
    resolve: {
      props: () => props,
    },
    windowClass: 'demo-video-modal-window-class',
  });
};
