import { capitalizeFirstLetter } from 'Common/utilities/string';
import { isDateSentActive } from 'Assets/js/controllers/customerCare/partial/util/customerCareTabsCtrl';

export default class CustomerCareActionCtrl {
  constructor(configService) {
    'ngInject';

    this.configService = configService;
  }

  $onInit() {
    this.isDateSentActive = isDateSentActive(this.configService);

    this.autoText('email');
    this.autoText('mail');
    this.autoText('sms');

    this.dateSentVia =
      this.item.CommunicationSent || this.item.communicationSent;
    this.sentDate = this.dateSentVia && this.dateSentVia.substr(0, 11);
    this.sentVia =
      this.dateSentVia && capitalizeFirstLetter(this.dateSentVia.substr(16));
  }

  sendNow(sendVia) {
    this.onActionSelected({ $event: { item: this.item, sendVia } });
  }

  autoText(type) {
    this.autoText[type] = `This customer has communications sent <br>
    via marketing automation. Manual sending <br>
    of ${type} is disabled.`;
  }
}
