export const SOURCE_PLATFORM = {
  MY_CRM: {
    ID: 0,
    DESCRIPTION: 'MyCRM',
  },
  CONSUMER_APP: {
    ID: 1,
    DESCRIPTION: 'ConsumerApp',
  },
};
