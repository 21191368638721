import {
  insuranceBenefitDetailsGetForUI,
  insuranceBenefitTypesGetForUI,
  insuranceInsurerListGetForUI,
  insuranceProductListGetForUI,
  insuranceStatusListGetForUI,
  insuranceDetailsSetForAPI,
} from 'Common/mappers/insuranceFireAndGeneral';
import { TEMP_FREQUENCY_LIST } from 'Common/constants/fireAndGeneral';

export default class InsuranceFireAndGeneralService {
  constructor($q, httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'InsuranceQuote';
    this.$q = $q;
  }

  insuranceBenefitTypesGet(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/InsuranceBenefitTypesGet`, { familyId })
      .then(({ data }) => data && data.map(insuranceBenefitTypesGetForUI));
  }

  insuranceInsurerGet(benefitTypeId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/InsuranceExistingProviderGet`, { benefitTypeId })
      .then(({ data }) => data && data.map(insuranceInsurerListGetForUI));
  }

  insuranceProductGet(benefitTypeId) {
    const providerId = 0;
    return this.httpClient
      .get(`${this.apiBaseUrl}/InsuranceProductGet`, {
        benefitTypeId,
        providerId,
      })
      .then(({ data }) => data && data.map(insuranceProductListGetForUI));
  }

  insuranceStatusGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/InsuranceStatusGet`)
      .then(({ data }) => data && data.map(insuranceStatusListGetForUI));
  }

  insuranceDetailsSet(data) {
    const setInsuranceData = insuranceDetailsSetForAPI(data);
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceDetailsSet`,
      setInsuranceData
    );
  }

  insuranceDetailsGet(familyId, benefitId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/InsuranceDetailsGet`, {
        familyId,
        benefitId,
      })
      .then(({ data }) => data && insuranceBenefitDetailsGetForUI(data));
  }

  insuranceTempFrequencyList() {
    return this.$q.resolve(TEMP_FREQUENCY_LIST);
  }
}
