import { COLOR } from 'Common/constants/colors';

const toolBarElements = [
  {
    items: ['Bold'],
  },
  {
    items: ['BulletedList'],
  },
  {
    items: ['Link'],
  },
  {
    items: ['Image'],
  },
  {
    items: ['Table'],
  },
  {
    items: ['Paste'],
  },
];

export const EDITOR_OPTIONS = {
  language: 'en',
  allowedContent: true,
  uiColor: COLOR.CK_EDITOR_BUTTONS,
  height: 200,
  resize_enabled: false,
  removePlugins: 'elementspath',
  toolbar: toolBarElements,
};
