import angular from 'angular';
import _ from 'lodash';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';

angular
  .module('app')
  .controller('LoanAppFinancialCtrl', function LoanAppFinancialCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    financialsSharedService,
    insuranceFinancialsSharedService,
    loanAppSharedData,
    uiService,
    configService
  ) {
    let clickCounter = 0;
    $scope.financialsSharedService.loanAppId = $stateParams.loanAppId || 0;
    $scope.uiService = uiService;
    const isAssetFinanceFeatureOn = configService.feature.assetFinance;

    const financialObjectTOShowInSection = (familyId, financialList) => {
      let toReturnObject = {};

      financialList.forEach(object => {
        if (parseInt(object.FamilyID, 10) === parseInt(familyId, 10)) {
          toReturnObject = object;
        }
      });

      return toReturnObject;
    };
    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    /* end of listeners */
    loanApplicationServices.loanSummaryGet($state.params.loanAppId).then(() => {
      const {
        ApplicantGuarantorList,
        ApplicantsNetworth,
        LoanDetails,
      } = loanAppSharedData.loanAppSummary;
      const isAssetFinance =
        LoanDetails &&
        (LoanDetails.LendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
          LoanDetails.LendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN);
      const hasPersonApplicant =
        ApplicantGuarantorList &&
        ApplicantGuarantorList.InvolvedPartyPerson &&
        Object.keys(ApplicantGuarantorList.InvolvedPartyPerson).length > 0;
      const hasEntityApplicant =
        ApplicantGuarantorList &&
        ApplicantGuarantorList.InvolvedPartyEntity &&
        ApplicantGuarantorList.InvolvedPartyEntity.length > 0;
      const isCommercialAssetLoan =
        isAssetFinance && hasEntityApplicant && !hasPersonApplicant;
      $scope.showNoFinancialsQuestions =
        isAssetFinanceFeatureOn && isCommercialAssetLoan;

      const isStartedUnopenFinancials = () => {
        if (
          ApplicantGuarantorList &&
          Object.keys(ApplicantGuarantorList).length
        ) {
          const firstFamilyKey = Object.keys(
            ApplicantGuarantorList.InvolvedPartyPerson
          )[0];
          $scope.familyId =
            ApplicantGuarantorList.InvolvedPartyPerson[
              firstFamilyKey
            ][0].FamilyId;
          if (
            Object.keys(
              ApplicantGuarantorList.InvolvedPartyPerson[firstFamilyKey]
            ).length
          ) {
            const sectionFinancialObject = financialObjectTOShowInSection(
              $scope.familyId,
              ApplicantsNetworth
            );
            const hasNetworth =
              typeof sectionFinancialObject.NetWorth !== 'undefined' &&
              sectionFinancialObject.NetWorth !== null;
            const hasTotalIncome =
              typeof sectionFinancialObject.TotalIncome !== 'undefined' &&
              sectionFinancialObject.TotalIncome !== null;
            const toReturnFinancialObject = {
              NetWorth: sectionFinancialObject.NetWorth,
              TotalIncome: sectionFinancialObject.TotalIncome,
              isBothFilled:
                sectionFinancialObject.NetWorth > 0 &&
                sectionFinancialObject.TotalIncome > 0,
              isStarted: hasNetworth || hasTotalIncome,
            };
            return toReturnFinancialObject;
          }
        }
      };
      $scope.processFinancialSection = () => {
        if (clickCounter) {
          $scope.validateAppplicantsList();
          return;
        }

        clickCounter++;

        $scope.callerApp = '';

        if ($stateParams.insAppId) {
          $scope.callerApp = 'insurance';
        }

        $scope.financialsSharedService =
          $scope.callerApp !== 'insurance'
            ? financialsSharedService
            : insuranceFinancialsSharedService;
        $scope.financialsSharedService.updateFinancialApplicantList();
        $scope.financialsSharedService.loanAppId = $stateParams.loanAppId || 0;
        $scope.financialsSharedService.familyId = $stateParams.familyId;

        $scope.loanAppId = $stateParams.loanAppId;
        $scope.familyId = $stateParams.familyId;

        const financialsSharedServiceWatcher = $scope.$watch(
          'financialsSharedService',
          nv => {
            $scope.listApplicants = nv.listApplicants;

            $scope.assetLength = nv.assetLength;
            $scope.liabilityLength = nv.liabilityLength;
            $scope.incomeLength = nv.incomeLength;
            $scope.expenseLength = nv.expenseLength;

            $scope.assetTotal = nv.assetTotal;
            $scope.NonREATotalAmount = nv.NonREATotalAmount;
            $scope.RealEstateTotalAmount = nv.RealEstateTotalAmount;
            $scope.liabilityTotal = nv.liabilityTotal;
            $scope.incomeTotal = nv.incomeTotal;
            $scope.expenseTotal = nv.expenseTotal;

            $scope.netWorth = $scope.assetTotal - $scope.liabilityTotal;

            $scope.isValidFinancials = nv.isValidFinancials;
            $scope.isStartedFinancials = nv.isStartedFinancials;

            const sectionObject = loanAppSharedData.getSectionObjectById(
              loanAppSharedData.completionListObject,
              SECTION_ID.FINANCIAL_POSITION
            );
            loanAppSharedData.completionStatusSet(
              SECTION_ID.FINANCIAL_POSITION,
              sectionObject.IsCompleted,
              $scope.isValidFinancials
            );

            $scope.countValids($scope.isValidFinancials, 'financials');
          },
          true
        );
        $scope.$on('$destroy', () => {
          if (financialsSharedServiceWatcher) financialsSharedServiceWatcher();
        });

        const getLoanApplicationNotesList = () => {
          loanScenarioService
            .brokerNotesGet($scope.loanAppId)
            .then(response => {
              $scope.assess = {
                financials: {},
              };

              angular.forEach(response.data, value => {
                switch (value.SubsectionId) {
                  case 7:
                    $scope.assess.financials.realEstateNotes =
                      value.BrokerNotes;
                    break;
                  case 8:
                    $scope.assess.financials.assetsNotes = value.BrokerNotes;
                    break;
                  case 9:
                    $scope.assess.financials.liabilitiesNotes =
                      value.BrokerNotes;
                    break;
                  case 10:
                    $scope.assess.financials.incomeNotes = value.BrokerNotes;
                    break;
                  case 11:
                    $scope.assess.financials.expensesNotes = value.BrokerNotes;
                    break;
                  default:
                    break;
                }
              });
            });
        };

        $scope.noteListInit = () => {
          if ($scope.callerApp !== 'insurance') {
            getLoanApplicationNotesList();
          }
        };

        $scope.noteListInit();

        if (_.isUndefined($scope.financialsSharedService.selectedFamily)) {
          $scope.financialsSharedService.selectedFamily = {
            FamilyId: $scope.familyId.toString(),
            initials: '',
          };
        }

        $scope.changeSelectedFamily = () => {
          let family = _.find($scope.listApplicants, o => {
            return _.isEqual(
              o.FamilyId,
              $scope.financialsSharedService.selectedFamily.FamilyId
            );
          });
          if (!family) {
            family = {
              FamilyName: '',
            };
          }
          angular.extend($scope.financialsSharedService.selectedFamily, {
            initials: utilitiesService.filterInitialOneString(
              family.FamilyName
            ),
            background: $scope.getBGcolor(family.FamilyId),
            FamilyName: family.FamilyName,
          });
        };
      };
      $scope.validateAppplicantsList = () => {
        loanAppSharedData
          .getLoanAppApplicants($scope.financialsSharedService.loanAppId)
          .then(response => {
            if (response && response.data) {
              $scope.financialsSharedService.FinancialApplicantList(
                response.data
              );
            }
          });
      };

      const sectionObject = loanAppSharedData.getSectionObjectById(
        loanAppSharedData.completionListObject,
        SECTION_ID.FINANCIAL_POSITION
      );
      $scope.countValids(sectionObject.IsCompleted, 'financials');

      if (!sectionObject.IsCompleted) {
        $scope.processFinancialSection();
      } else {
        $scope.isValidFinancials = sectionObject.IsCompleted;
        $scope.isStartedFinancials = isStartedUnopenFinancials().isStarted;

        if ($scope.isStartedFinancials) {
          const financialSectionObject = isStartedUnopenFinancials();
          $scope.netWorth = financialSectionObject.NetWorth;
          $scope.incomeTotal = financialSectionObject.TotalIncome;
        }
      }
    });
  });
