import { IMAGE_TYPE } from 'Common/constants/emailSignatureImageType';

import {
  VALID_IMAGE_FORMAT,
  INVALID_FORMAT_WARNING,
} from 'Common/constants/imageFormat';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

class ProfileImagesCtrl {
  constructor(
    configService,
    toaster,
    contactService,
    corporateModelService,
    corporateService
  ) {
    'ngInject';

    this.configService = configService;
    this.toaster = toaster;
    this.contactService = contactService;
    this.corporateModelService = corporateModelService;
    this.corporateService = corporateService;
  }

  $onInit() {
    const {
      usersProfileDigitalAssets,
      usersProfileBio,
    } = this.configService.feature;
    this.isUploadMultiple = false;
    this.validFileExtensions = VALID_IMAGE_FORMAT;
    this.invalidFileInfo = INVALID_FORMAT_WARNING;
    this.imageType = IMAGE_TYPE;
    this.editMode = [];
    this.showUsersProfileBio = !!usersProfileBio;
    this.usersProfileDigitalAssets = !!(
      this.isUsersProfile && !usersProfileDigitalAssets
    );
    this.getAdviserProfile();
    this.isAdviser = !!(
      this.accessType === ACCESS_TYPE.PRINCIPAL_ADVISER ||
      this.accessType === ACCESS_TYPE.ADVISER
    );
  }

  $onChanges(changes) {
    this.familyId =
      changes && changes.familyId
        ? changes.familyId.currentValue
        : this.familyId;
    this.uploadImageUrl = this.familyId
      ? `${this.configService.resource}/contacts/${
          this.familyId
        }/profile-picture`
      : ``;
    this.allReadOnly =
      changes && changes.allReadOnly ? changes.allReadOnly.currentValue : 0;
    if (!this.secondLoad && this.familyId) {
      this.getAdviserProfilePicture(IMAGE_TYPE.HEAD_SHOT);
      this.getAdviserProfilePicture(IMAGE_TYPE.HALF_BODY);
      this.getAdviserProfilePicture(IMAGE_TYPE.FULL_BODY);
      this.secondLoad = true;
    }
  }

  isHeadShotActive() {
    return this.profileHeadShotInfo && this.profileHeadShotInfo.downloadLink;
  }

  isHalfBodyActive() {
    return this.profileHalfBodyInfo && this.profileHalfBodyInfo.downloadLink;
  }

  isFullBodyctive() {
    return this.profileFullBodyInfo && this.profileFullBodyInfo.downloadLink;
  }

  uploadImageSuccess(response) {
    if (!response || !response.length) return;
    this.showToaster(`success`, `Updated`, `successfully updated!`);
    const { ImageUploadType: imageType } = response[0];
    this.getAdviserProfilePicture(imageType);
  }

  getAdviserProfilePicture(type) {
    if (!this.familyId || !type) return;
    this.contactService
      .getEmailSignatureImage(this.familyId, type)
      .then(response => {
        if (!response || !response.length) {
          this.updateProfileImage(type, {});
        } else {
          this.updateProfileImage(type, response[0]);
        }
      });
  }
  updateProfileImage(type, imageData) {
    if (!type || !imageData) return;
    if (type === IMAGE_TYPE.HEAD_SHOT) {
      this.profileHeadShotInfo = imageData;
    }
    if (type === IMAGE_TYPE.HALF_BODY) {
      this.profileHalfBodyInfo = imageData;
    }
    if (type === IMAGE_TYPE.FULL_BODY) {
      this.profileFullBodyInfo = imageData;
    }
  }

  deleteSignatureImage(type, documentId) {
    if (!this.familyId || !documentId || !type) return;
    this.contactService
      .deleteSignatureImage(this.familyId, documentId)
      .then(response => {
        if (!response || !response.data) return;
        this.showToaster(`success`, `Updated`, `successfully deleted!`);
        this.getAdviserProfilePicture(type);
      });
  }
  showToaster(type, title, message) {
    const invalidToaster = !type || !title || !message;
    if (invalidToaster) return;
    this.toaster.pop(type, title, message);
  }
  setEditMode(field) {
    this.editMode[field] = !this.editMode[field];
  }
  cancelUpdate(field) {
    if (!this.personalInformation) return;
    this.personalInformation[field] =
      this.personalInformation[`${field}Raw`] || '';
    this.setEditMode(field);
  }
  getAdviserProfile() {
    if (!this.familyId) return;
    this.corporateModelService.getAdviserProfile(this.familyId).then(data => {
      this.personalInformation = { ...data, bioRaw: data.bio || '' };
    });
  }
  setAdviserProfile(field) {
    if (!this.familyId) return;
    this.personalInformation.adviserId = this.familyId;
    this.corporateModelService
      .setAdviserProfile(this.personalInformation)
      .then(data => {
        if (!data) return;
        this.showToaster(
          `success`,
          `Updated`,
          `Information successfully update`
        );
        this.setEditMode(field);
      });
  }
}

export default ProfileImagesCtrl;
