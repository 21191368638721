import {
  assetSupplierBuilderForMyCRM,
  assetSupplierBuilderForUI,
  cqpDynamicFeeBuilderForMyCRM,
  cqpDynamicFeeBuilderForUI,
  documentZipBuilderForUI,
} from 'Common/mappers/loanScenario';

export default class LoanScenarioModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'LoanScenario';
  }

  cpaDocument(param) {
    return this.httpClient.get(`${this.apiBaseUrl}/cpa-document`, param);
  }

  postAssetSuplier(loanApplicationId, assetSupplier) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      assetSupplierBuilderForMyCRM(assetSupplier)
    );
  }

  getAssetSupplier(loanApplicationId, assetSupplierId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`, {
        assetSupplierId,
      })
      .then(response => assetSupplierBuilderForUI(response.data));
  }

  updateAssetSuplier(loanApplicationId, assetSupplier) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      assetSupplierBuilderForMyCRM(assetSupplier)
    );
  }

  deleteAssetSupplier(loanApplicationId, assetSupplierId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      { assetSupplierId }
    );
  }

  getApplicantEmployment(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicantEmployment`, {
      loanApplicationId,
    });
  }

  deleteCommunication(params) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/WorkbenchRequirementLog`,
      params
    );
  }

  addCqpDynamicFee(loanApplicationId, params) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/${loanApplicationId}/cqp-dynamic-fee`,
        cqpDynamicFeeBuilderForMyCRM(params)
      )
      .then(
        ({ data }) => data && data.map(res => cqpDynamicFeeBuilderForUI(res))
      );
  }

  getCqpDynamicFee(loanApplicationId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanApplicationId}/cqp-dynamic-fee`)
      .then(
        ({ data }) => data && data.map(res => cqpDynamicFeeBuilderForUI(res))
      );
  }

  deleteCqpDynamicFee(loanApplicationId, feeId) {
    return this.httpClient
      .delete(
        `${this.apiBaseUrl}/${loanApplicationId}/cqp-dynamic-fee/${feeId}`
      )
      .then(
        ({ data }) => data && data.map(res => cqpDynamicFeeBuilderForUI(res))
      );
  }

  updateCqpDynamicFee(loanApplicationId, feeId, params) {
    return this.httpClient
      .put(
        `${this.apiBaseUrl}/${loanApplicationId}/cqp-dynamic-fee/${feeId}`,
        cqpDynamicFeeBuilderForMyCRM(params)
      )
      .then(
        ({ data }) => data && data.map(res => cqpDynamicFeeBuilderForUI(res))
      );
  }

  postDocumentSorting(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/DocumentSortingSet`,
      params
    );
  }

  getDocumentsZip(loanScenarioId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/DocumentsZip`, { loanScenarioId })
      .then(({ data }) => data && documentZipBuilderForUI(data));
  }

  updateImportantDate(params) {
    return this.httpClient.put(`${this.apiBaseUrl}/ImportantDate`, {}, params);
  }

  setManualCqpState(loanApplicationId, CQPManuallyUpdated) {
    return this.httpClient.post(`${this.apiBaseUrl}/cqp-manual`, {
      loanApplicationId,
      CQPManuallyUpdated,
    });
  }

  getManualCqpState(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/cqp-manual`, {
      loanApplicationId,
    });
  }
}
