import {
  SUBSCRIPTION_MESSAGES,
  ORG_SUBSCRIPTION,
} from 'Common/constants/customerCareModules';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';

export default class InsuranceCustomerCareCtrl {
  constructor(
    $state,
    customerCareInsuranceService,
    currentUserService,
    $uibModal
  ) {
    'ngInject';

    this.$state = $state;
    this.customerCareInsuranceService = customerCareInsuranceService;
    this.currentUserService = currentUserService;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.tabs = [];
    this.activeTab = 0;

    this.isAdviser =
      this.currentUserService.isAdviser ||
      this.currentUserService.isPrincipalAdviser;
    this.someBrokersSubscribed = false;
    this.automationData = {};
    this.isAssistantUnlocked = false;
    this.showLockScreen = false;
    this.pageSource = PAGE_SOURCE.CUSTOMER_CARE_INSURANCE;

    this.autoMarketingMessage = SUBSCRIPTION_MESSAGES.NONE;

    this.getInsuranceTabs();
  }

  messageToDisplay(orgSubscribedType, isSubscribed) {
    this.autoMarketingMessage = isSubscribed ? SUBSCRIPTION_MESSAGES.NONE : '';

    if (orgSubscribedType === ORG_SUBSCRIPTION.SOME) {
      this.autoMarketingMessage = !this.isAdviser
        ? SUBSCRIPTION_MESSAGES.SOME
        : this.autoMarketingMessage;
      this.someBrokersSubscribed = true;
    } else if (orgSubscribedType === ORG_SUBSCRIPTION.ALL) {
      this.autoMarketingMessage = !this.isAdviser
        ? SUBSCRIPTION_MESSAGES.ALL
        : this.autoMarketingMessage;
      this.someBrokersSubscribed = false;
    }
    this.isAssistantUnlocked =
      !this.autoMarketingMessage ||
      (!this.isAdviser && this.someBrokersSubscribed);
    this.showLockScreen =
      (this.autoMarketingMessage && this.isAdviser) ||
      (this.autoMarketingMessage &&
        !this.isAdviser &&
        !this.someBrokersSubscribed);

    this.automationData = {
      showLockScreen: this.showLockScreen,
      isAssistantUnlocked: this.isAssistantUnlocked,
      autoMarketingMessage: this.autoMarketingMessage,
    };
  }

  selectTab() {
    // TODO: load marketing automation for insurance customer care - checking automation subscription message placeholder
    this.messageToDisplay(0, false);
  }

  getInsuranceTabs() {
    this.customerCareInsuranceService
      .getCustomerCareInsuranceTabs()
      .then(res => {
        const tabIndex = parseInt(this.$state.params.activeTab, 10);
        this.tabs = res;
        this.activeTab = this.tabs.length > tabIndex ? tabIndex : 0;
      });
  }
}
