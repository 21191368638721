import {
  LENDER_SPLIT_SMALL,
  LENDER_COLOR_CODES,
  LENDER_MAX_INDEX,
  WIDGET_OPTION_VAL,
  ALL_VALUE,
  WIDGET_TITLE,
  LENDER_SPLIT_ON,
} from 'Common/constants/dashboardWidget';
import { BASIC_PIE_OPTION } from 'Common/config/chart';
import {
  getValueOption,
  getCurrentCarouselData,
  parseMultiSelectTicks,
} from 'Common/utilities/dashboardWidget';

export default class LenderSplitV2Ctrl {
  constructor(
    $q,
    corporateService,
    currentUserService,
    optionsService,
    overviewDashboardService,
    dashboardService,
    commonWidgetService
  ) {
    'ngInject';

    this.$q = $q;
    this.corporateService = corporateService;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
    this.commonWidgetService = commonWidgetService;
  }

  $onInit() {
    this.LENDER_SPLIT_SMALL = LENDER_SPLIT_SMALL;
    this.chartOptions = BASIC_PIE_OPTION;
    this.timePeriodSelected = {};
    this.splitOnList = [];
    this.timePeriodList = [];
    this.advisersDropdown = [];
    this.lenderCategoryList = [];
    this.showWidgetSettings = true;
    this.widgetTitle = WIDGET_TITLE.LENDER_SPLIT;
    this.widgetSubtitle = '';
    this.hideLenderCategory =
      this.currentUserService.isNZ || this.currentUserService.isID;

    this.getWidgetOptionDropdown();
  }

  goToReport() {
    const params = {
      widgetId: this.myParam.WidgetId,
      widgetUserId: this.myParam.Widget_UserID,
    };
    this.commonWidgetService.goToReport(params);
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.commonWidgetService.getWidgetOptions(LENDER_SPLIT_SMALL.SPLIT_ON),
      this.commonWidgetService.getWidgetOptions(LENDER_SPLIT_SMALL.TIME_PERIOD),
      this.commonWidgetService.getAdviserDropdown(isLoanWriterOnly),
      this.commonWidgetService.getCategoryLendersList(),
    ];

    this.$q.all(promises).then(responses => {
      if (!responses || !responses.length) return;
      const [
        splitOnList,
        timePeriodList,
        advisersDropdown,
        lenderCategoryList,
      ] = responses;

      this.splitOnList = splitOnList;
      this.timePeriodList = timePeriodList;
      this.advisersDropdown = advisersDropdown;
      this.lenderCategoryList = lenderCategoryList;
      this.getWidgetUserOptions(true);
    });
  }

  getWidgetUserOptions(isLoadSplits) {
    return this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        isLoadSplits && this.getLenderSplit();
        if (!data || !data.length) return;

        const splitOn = getValueOption(LENDER_SPLIT_SMALL.SPLIT_ON, data);
        this.selectedSplitOn = parseInt(splitOn.Widget_OptionValue, 10);

        this.widgetSubtitle =
          splitOn && `Based on settled ${splitOn.DisplayValue}`;

        const timePeriod = getValueOption(LENDER_SPLIT_SMALL.TIME_PERIOD, data);
        this.timePeriodSelected = timePeriod;

        const advisers = getValueOption(LENDER_SPLIT_SMALL.ADVISERS, data);
        const lenderCategory = getValueOption(
          LENDER_SPLIT_SMALL.LENDER_CATEGORY,
          data
        );

        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown
        );
        this.lenderCategoryList = parseMultiSelectTicks(
          lenderCategory,
          this.lenderCategoryList
        );

        const categoryString = this.lenderCategoryList
          .filter(item => item.ticked)
          .map(category => category.name)
          .join(', ');
        this.widgetTitle = `${WIDGET_TITLE.LENDER_SPLIT} (${categoryString})`;
      });
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = list.findIndex(
      item =>
        item.valueId ===
        (+currentPeriod.Widget_OptionValue || +currentPeriod.valueId)
    );

    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);

    this.setWidgetOptions({
      widgetOptionId: LENDER_SPLIT_SMALL.TIME_PERIOD,
      value:
        this.timePeriodSelected.Widget_OptionValue ||
        this.timePeriodSelected.valueId,
    });
  }

  setWidgetOptions(params) {
    const { widgetOptionId, value, selectedUser, needUpdate } = params;
    const payload = {
      WidgetOptionId: widgetOptionId,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: value || 0,
      SelectedUser: selectedUser || 0,
    };
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      needUpdate && this.getWidgetUserOptions(true);
      !needUpdate && this.getLenderSplit();
    });
  }

  onItemSelect(itemClicked, variableName, inputData) {
    if (itemClicked.value === ALL_VALUE) {
      this.selectAll(variableName, inputData);
      return;
    }

    this[variableName] = inputData.map((item, index) => {
      if (item.value === ALL_VALUE && !index) {
        item.ticked = false;
      }
      return item;
    });

    this.setCustomWidgetOption(variableName);
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = this[variableName]
      .filter(item => item.ticked)
      .map(item => ({ PersonId: item.value }));

    const { LENDER_CATEGORY, ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId =
      variableName === LENDER_CATEGORY.VAR_NAME
        ? LENDER_SPLIT_SMALL.LENDER_CATEGORY
        : LENDER_SPLIT_SMALL.ADVISERS;

    const customOption =
      variableName === LENDER_CATEGORY.VAR_NAME
        ? LENDER_CATEGORY.CUSTOM
        : ADVISER.CUSTOM;
    const allOption =
      variableName === LENDER_CATEGORY.VAR_NAME
        ? LENDER_CATEGORY.ALL
        : ADVISER.ALL;

    const value = isAll ? allOption : customOption;
    const needUpdate = variableName === LENDER_CATEGORY.VAR_NAME;
    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
      needUpdate,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = inputData.map(item => ({
      ...item,
      ticked: item.value === ALL_VALUE,
    }));
    this.setCustomWidgetOption(varName, true);
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  getLenderSplit() {
    this.dashboardService
      .getLenderSplits({ userWidgetId: this.myParam.Widget_UserID })
      .then(data => {
        this.chartData = [];
        this.chartColors = [];
        this.chartLegend = [];
        this.chartLabels = [];
        if (!data || !data.length) return;

        const colorSplit = LENDER_COLOR_CODES.length + 1;

        data.forEach((item, index) => {
          const piePiece =
            item[
              this.selectedSplitOn === LENDER_SPLIT_ON.COUNT
                ? 'lenderCountSplitPercent'
                : 'lenderAmountSplitPercent'
            ];
          this.chartData = [...this.chartData, piePiece && piePiece.toFixed(2)];
          this.chartColors = [
            ...this.chartColors,
            LENDER_COLOR_CODES[index % colorSplit],
          ];
          this.chartLegend = [
            ...this.chartLegend,
            {
              label: item.lenderShortName,
              color: LENDER_COLOR_CODES[index % colorSplit],
            },
          ];
          this.chartLabels = [...this.chartLabels, item.lenderShortName];
        });

        this.chartLegend =
          this.chartLegend.length &&
          this.chartLegend.slice(0, LENDER_MAX_INDEX);

        const { brokerRegionalization } = this.currentUserService;
        this.chartOptions = {
          ...BASIC_PIE_OPTION,
          tooltips: {
            displayColors: false,
            callbacks: {
              label: value => {
                const piePercent = this.chartData[value.index];
                const splitAmt = data[value.index].lenderTotalAmount.toFixed(2);
                const splitCount = data[value.index].lenderTotalCount;
                const lender = data[value.index].lenderShortName;
                const { currencySign } = brokerRegionalization;
                return [
                  `${lender}: ${piePercent}%`,
                  `${currencySign}${Math.round(splitAmt).toLocaleString()}`,
                  `${splitCount}`,
                ];
              },
            },
          },
        };
      });
  }
}
