export const EMPLOYMENT = {
  PREVIOUS_EMPLOYMENT: 'Previous Employment',
  VALID_YEAR_LENGTH: 3,
};

export const NZBN_PROPERTY = {
  SELF_EMPLOYED: 2,
  REGISTERED_COMPANY: '1',
  SOLE_TRADER: '2',
  NUMERICAL_LENGTH: 13,
  FIELD_STATE: '0',
  OPTION_STATE: false,
};
