import { IMAGE_TYPE } from 'Common/constants/emailSignatureImageType';

import {
  VALID_IMAGE_FORMAT,
  INVALID_FORMAT_WARNING,
} from 'Common/constants/imageFormat';

import { ACCESS_TYPE } from 'Common/constants/accessTypes';

const ELITE_AWARDS = {
  CHAIRMAN: '6',
  PLATINUM: '7',
  ELITE: '8',
  EMERGING_ELITE: '9',
  DEVELOPING: '10',
};

class UsersEmailSignatureCtrl {
  constructor(
    $window,
    $document,
    toaster,
    contactService,
    configService,
    generalService,
    dashboardService,
    contactModelService
  ) {
    'ngInject';

    this.window = $window;
    this.document = $document[0];
    this.toaster = toaster;
    this.contactService = contactService;
    this.configService = configService;
    this.generalService = generalService;
    this.dashboardService = dashboardService;
    this.contactModelService = contactModelService;
  }

  $onInit() {
    this.getUserInfo();
    this.getUsersBrokerId();
    this.authGoogleFunction();
    this.adviserAwardImage = [];
    this.adviserEmailSettings = {};
    this.validFileExtensions = VALID_IMAGE_FORMAT;
    this.invalidFileInfo = INVALID_FORMAT_WARNING;
    this.imageType = IMAGE_TYPE;
    this.initialRun = false;
    this.editMode = {
      disclaimerText: false,
      footerImageLink1: false,
      footerImageLink2: false,
    };
    this.editModeKeys = {
      disclaimerText: 'disclaimerText',
      footerImageLink1: 'footerImageLink1',
      footerImageLink2: 'footerImageLink2',
    };
    this.baseURL = this.window.location.origin;
    this.isOpenAuthToGmail = false;
    this.isPreviewAddedToGmail = false;
    this.eliteAwards = ELITE_AWARDS;
  }

  $onChanges(changes) {
    const { familyId, refreshCount } = changes;
    if (familyId) {
      this.familyId = familyId.currentValue;
      const sourcePath = `${this.configService.resource}/contacts/${
        this.familyId
      }`;
      this.footerUploadImageUrl1 = this.familyId
        ? `${sourcePath}/footer-image-1`
        : ``;
      this.footerUploadImageUrl2 = this.familyId
        ? `${sourcePath}/footer-image-2`
        : ``;
      this.awardUploadImageUrl = this.familyId
        ? `${sourcePath}/awards-accreditation`
        : ``;
      this.getAdviserFooterImage(IMAGE_TYPE.FOOTER_IMAGE_1);
      this.getAdviserFooterImage(IMAGE_TYPE.FOOTER_IMAGE_2);
      this.getAdviserAwards();
      this.getEmailSignatureSettings();
      this.getEmailSignaturePreviewDetails();
    }
    if (!refreshCount.currentValue) return;
    this.getUserInfo();
  }
  getUserInfo() {
    this.contactService.getUserInfo().then(({ data }) => {
      if (!data) return;
      this.adviserInfo = data;
    });
  }

  getUsersBrokerId() {
    this.dashboardService.getBrokerBasicInfo().then(response => {
      if (!response || !response.data || !response.data.BrokerId) return;
      this.userBrokerId = response.data.BrokerId;
    });
  }

  applyToSendEmail() {
    const finalSignatureForm = this.htmlLivePreview;
    const data = {
      familyId: this.userBrokerId,
      emailSignature: finalSignatureForm,
    };
    this.generalService.setEmailSignature(data);
  }

  authGoogleFunction() {
    this.window.authGoogle = code => {
      this.contactService.authGoogle(code).then(() => {
        this.isOpenAuthToGmail = true;
        this.addToGmail();
      });
    };
  }

  addToGmail() {
    if (this.isPreviewAddedToGmail) return;
    this.isPreviewAddedToGmail = true;
    this.htmlLivePreview = this.document.querySelector(
      '#email-preview'
    ).innerHTML;
    this.contactModelService
      .setGmailSignature({ html: this.htmlLivePreview })
      .then(response => {
        if (!response || !response.data) return;
        this.isPreviewAddedToGmail = false;

        if (response.data.ActionSuccess && response.data.AuthSuccess) {
          this.applyToSendEmail();
          this.toaster.pop(
            'success',
            'Success!',
            'Email signature syncing succeeded.'
          );
          return;
        }

        if (this.isOpenAuthToGmail) return;
        this.window.open(response.data.UserAuthUrl);
      });
  }

  makeEditActive(key) {
    if (!key || !this.editMode || typeof this.editMode[key] === 'undefined')
      return;
    this.editMode[key] = !this.editMode[key];
  }

  updateEmailSignatureSettings(key = 'switch') {
    if (
      !key ||
      !this.adviserEmailSettings ||
      !Object.keys(this.adviserEmailSettings).length ||
      !this.familyId
    )
      return;
    this.makeEditActive(key);
    const { footerImageLink1, footerImageLink2 } = this.adviserEmailSettings;
    this.adviserEmailSettings.footerImageLink1 = footerImageLink1
      ? footerImageLink1.trim()
      : '';
    this.adviserEmailSettings.footerImageLink2 = footerImageLink2
      ? footerImageLink2.trim()
      : '';
    this.contactService
      .postEmailSignatureSettings(this.adviserEmailSettings, this.familyId)
      .then(response => {
        if (!response || !response.data) return;
        this.toaster.pop('success', 'Success!', 'Updated successfully.');
        this.getEmailSignatureSettings();
      });
  }

  getAdviserFooterImage(type) {
    if (!this.familyId || !type) return;
    this.contactService
      .getEmailSignatureImage(this.familyId, type)
      .then(response => {
        this.adviserFooterImage1 =
          type === IMAGE_TYPE.FOOTER_IMAGE_1 ? {} : this.adviserFooterImage1;
        this.adviserFooterImage2 =
          type === IMAGE_TYPE.FOOTER_IMAGE_2 ? {} : this.adviserFooterImage2;
        if (!response || !response.length) {
          this.initialRun && this.getEmailSignaturePreviewDetails();
          return;
        }
        this.adviserFooterImage1 =
          type === IMAGE_TYPE.FOOTER_IMAGE_1
            ? response[0]
            : this.adviserFooterImage1;
        this.adviserFooterImage2 =
          type === IMAGE_TYPE.FOOTER_IMAGE_2
            ? response[0]
            : this.adviserFooterImage2;
        this.initialRun && this.getEmailSignaturePreviewDetails();
      });
  }

  getAdviserAwards() {
    if (!this.familyId) return;
    this.contactService
      .getEmailSignatureImage(this.familyId, IMAGE_TYPE.AWARDS)
      .then(response => {
        if (!response || !response.length) return;
        this.adviserAwardImage = response;
        this.initialRun && this.getEmailSignaturePreviewDetails();
      });
  }

  getEmailSignatureSettings() {
    if (!this.familyId) return;
    this.contactService
      .getEmailSignatureSettings(this.familyId)
      .then(response => {
        if (!response || !Object.keys(response).length) return;
        this.adviserEmailSettings = response;
        this.initialRun && this.getEmailSignaturePreviewDetails();
      });
  }

  getEmailSignaturePreviewDetails() {
    if (!this.familyId) return;
    this.contactService
      .getEmailSignaturePreviewDetails(this.familyId)
      .then(response => {
        if (
          !response ||
          !Object.keys(response).length ||
          !response.emailSignatureContent ||
          !response.awardsAccreditation
        )
          return;
        this.initialRun = true;
        this.previewContent = response.emailSignatureContent;
        this.previewAward = response.awardsAccreditation;
      });
  }

  deleteAdviserImage(documentId, type) {
    if (!documentId || !type || !this.familyId) return;
    this.contactService
      .deleteSignatureImage(this.familyId, documentId)
      .then(response => {
        if (!response || !response.data) return;
        this.toaster.pop('success', 'Deleted!', 'File deleted successfully.');
        if (
          type === IMAGE_TYPE.FOOTER_IMAGE_1 ||
          type === IMAGE_TYPE.FOOTER_IMAGE_2
        ) {
          this.getAdviserFooterImage(type);
        } else if (type === IMAGE_TYPE.AWARDS) {
          this.getAdviserAwards();
        }
      });
  }

  uploadFooterImageSuccess(response) {
    if (!response || !response.length) return;
    this.toaster.pop('success', 'Saved!', 'File uploaded Successfully.');
    this.getAdviserFooterImage(response[0].ImageUploadType);
  }

  uploadAwardImageSuccess(response) {
    if (!response || !response.length) return;
    this.toaster.pop('success', 'Saved!', 'File uploaded Successfully.');
    this.getAdviserAwards();
  }

  showFooter1WithoutLink() {
    return (
      this.previewContent &&
      !this.previewContent.footerImageLink1 &&
      this.adviserFooterImage1 &&
      this.adviserFooterImage1.downloadLink
    );
  }

  showFooter2WithoutLink() {
    return (
      this.previewContent &&
      !this.previewContent.footerImageLink2 &&
      this.adviserFooterImage2 &&
      this.adviserFooterImage2.downloadLink
    );
  }

  showFooter1WithLink() {
    return (
      this.previewContent &&
      this.previewContent.footerImageLink1 &&
      this.adviserFooterImage1 &&
      this.adviserFooterImage1.downloadLink
    );
  }

  showFooter2WithLink() {
    return (
      this.previewContent &&
      this.previewContent.footerImageLink2 &&
      this.adviserFooterImage2 &&
      this.adviserFooterImage2.downloadLink
    );
  }

  showAdviserPhoto() {
    return (
      this.previewContent &&
      this.previewContent.adviserPhoto &&
      this.adviserEmailSettings &&
      this.adviserEmailSettings.adviserPhoto
    );
  }

  isAssistant() {
    const { AccessType: accessType } = this.adviserInfo;
    return (
      ACCESS_TYPE.ADMIN_ASSISTANT === accessType ||
      ACCESS_TYPE.ADMIN_ASSISTANT.ASSISTANT === accessType
    );
  }
}

export default UsersEmailSignatureCtrl;
