import { toastError, toastSuccess } from 'Common/utilities/alert';
import { INSURANCE_PIPELINE_STATUS } from 'Common/constants/insuranceOptions';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import {
  WORKBENCH_DATES,
  DEFERRED_STATUS_ID,
} from 'Common/constants/insuranceApplicationPipeline';
import moment from 'moment';

export default class workBenchDetailsCtrl {
  constructor(insurancePipelineService, modalRenderService) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.insurancePipelineService = insurancePipelineService;
  }

  $onInit() {
    this.populateWorkBenchData();
    this.pipelineStatus = [];
    this.workBenchDetails = {};
    this.clientDetails = [];
    this.nextWorkDate = {
      Date: null,
    };
    this.selectedType = 0;
    this.dateFormat = WORKBENCH_DATES.FORMAT;
    this.allInputFormats = WORKBENCH_DATES.ALL_FORMAT;
    this.dateOptions = WORKBENCH_DATES.DATE_OPTIONS;
    this.nextWorkDate.isOpen = false;
    this.nextWorkDate.openDate = () => {
      this.nextWorkDate.isOpen = !this.nextWorkDate.isOpen;
    };
  }

  $onChanges(changes) {
    if (changes && changes.workBenchDetails) {
      this.workBenchDetails = changes.workBenchDetails.currentValue;
      this.clientDetails = this.workBenchDetails.clients;
      this.insuranceStatusId = this.workBenchDetails.pipelineStatusId;
      this.taskDueDate =
        this.workBenchDetails.nextWorkDate &&
        moment(this.workBenchDetails.nextWorkDate).format(D_MMM_YYYY_FORMAT);
    }
  }
  getWorkBenchDetails() {
    this.insurancePipelineService
      .getClientWorkBenchDetails(this.quoteId)
      .then(res => {
        this.workBenchDetails = res;
        this.clientDetails = this.workBenchDetails.clients;
        this.insuranceStatusId = this.workBenchDetails.pipelineStatusId;
        this.nextWorkDate.Date =
          this.workBenchDetails.nextWorkDate &&
          moment(this.workBenchDetails.nextWorkDate).toDate();
      });
  }

  getInsurancePipelineStatus() {
    this.insurancePipelineService
      .getInsurancePipelineStatus(null, false)
      .then(res => {
        this.pipelineStatus = res.filter(
          item => item.id !== DEFERRED_STATUS_ID.V3
        );
      });
  }

  loadNotProceededStatuses() {
    this.insurancePipelineService.getNotProceededReasons().then(response => {
      this.notProceedingReasons = response.map(reason => {
        return {
          PipelineSubStatusID: reason.id,
          SubStatusName: reason.description,
        };
      });
    });
  }

  setWorkBenchDetails() {
    const data = {
      quoteId: this.quoteId,
      nextWorkDate: moment(this.nextWorkDate.Date).format('YYYY-MM-DD'),
      description: 'Updated Next Work Date',
    };
    this.insurancePipelineService.workbenchNextWorkDate(data).then(() => {
      this.onNewWorkDateChange && this.onNewWorkDateChange();
    });
  }

  convertApplication(statusId) {
    if (this.workBenchDetails.pipelineStatusId === statusId) return;
    const inForce = INSURANCE_PIPELINE_STATUS.IN_FORCE === statusId;
    this.promptToContinueConversion(inForce).result.then(response => {
      if (!response.isOk) {
        this.insuranceStatusId = this.workBenchDetails.pipelineStatusId;
        return;
      }
      this.changeWorkBenchPipelineStatus(statusId, response.selectedReasonId);
    });
  }

  promptToContinueConversion(isProceeding) {
    return this.modalRenderService.renderPipelineStatusMoveConfirmationModal({
      content: `This application will now be moved to ${
        isProceeding ? 'In Force' : 'Not Proceeding'
      }`,
      reasonList: isProceeding ? null : this.notProceedingReasons,
      showNurtureCampaignsQuestion: false,
    });
  }

  onSelectStatus(statusId) {
    if (this.workBenchDetails.pipelineStatusId === statusId) return;
    const inforceORproceeding =
      INSURANCE_PIPELINE_STATUS.IN_FORCE === statusId ||
      INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED === statusId;
    if (inforceORproceeding) {
      this.convertApplication(statusId);
    } else {
      this.changeWorkBenchPipelineStatus(statusId);
    }
  }

  changeWorkBenchPipelineStatus(statusId, reasonForNotProceedingID) {
    this.insurancePipelineService
      .movePipelineCardToStatus({
        fromStatusId: this.workBenchDetails.pipelineStatusId,
        toStatusId: statusId,
        quoteId: this.quoteId,
        reasonForNotProceedingID,
      })
      .then(data => {
        if (data.status === 200) {
          toastSuccess('Status change successfully');
          this.onStatusChange && this.onStatusChange();
        }
      })
      .catch(e => toastError(e));
  }

  populateWorkBenchData() {
    this.getWorkBenchDetails();
    this.loadNotProceededStatuses();
    this.getInsurancePipelineStatus();
  }
}
