import {
  QUESTIONS_WITH_ASSOCIATED_RISKS,
  QUESTIONS_WITH_TEXTBOX,
  OPTIONAL_QUESTIONS,
  DISPLAY_OPTIONS,
  SPECIAL_RATE_PREFERENCES,
  SPECIAL_RATE_PREFERENCES_IMPORTANT,
  SPECIAL_RATE_IMPORTANT,
  REPAYMENT_PREFERENCE,
  REPAYMENT_PREFERENCE_IMPORTANT,
  REPAYMENT_IMPORTANT,
  OPTION_QUESTION,
} from 'Common/constants/customerObjectives';

export function isQuestionAssociatedRisks(questionId) {
  return QUESTIONS_WITH_ASSOCIATED_RISKS.includes(questionId);
}

export function isQuestionTextbox(questionId) {
  return QUESTIONS_WITH_TEXTBOX.includes(questionId);
}

export function isOptionalQuestion(questionId) {
  return OPTIONAL_QUESTIONS.includes(questionId);
}

export function getAssociateQuestion(question, isAssociated) {
  const isQuestionCheckbox =
    question.QuestionDisplayTypeId === DISPLAY_OPTIONS.CHECK_BOX;
  if (!isAssociated || !isQuestionCheckbox) return null;
  if (isQuestionCheckbox) {
    return question;
  }
}

export function getSpecialImportantOption(answerList, importantOption) {
  const specialImportantOption = [];
  let filteredData = [];
  answerList.forEach(obj => {
    const answer = obj.value || `${obj}`;
    switch (answer) {
      case SPECIAL_RATE_PREFERENCES.OFFSET_ACCOUNT:
        filteredData = importantOption.filter(item =>
          SPECIAL_RATE_PREFERENCES_IMPORTANT.OFFSET_ACCOUNT.includes(item.value)
        );
        specialImportantOption.push(...filteredData);
        break;

      case SPECIAL_RATE_PREFERENCES.REDRAW:
        filteredData = importantOption.filter(item =>
          SPECIAL_RATE_PREFERENCES_IMPORTANT.REDRAW.includes(item.value)
        );
        specialImportantOption.push(...filteredData);
        break;

      default:
    }
  });

  filteredData = importantOption.filter(item => {
    return item.value === SPECIAL_RATE_IMPORTANT.OTHER;
  });
  specialImportantOption.push(...filteredData);
  return specialImportantOption;
}

export function getRepaymentOption(repaymentAnswers, importantOption) {
  const repaymentImportantOption = [];
  let filteredData = [];

  repaymentAnswers.forEach(obj => {
    const answer = obj.value || `${obj}`;
    switch (answer) {
      case REPAYMENT_PREFERENCE.PRINCIPAL_AND_INTEREST:
        filteredData = importantOption.filter(item =>
          REPAYMENT_PREFERENCE_IMPORTANT.PRINCIPAL_AND_INTEREST.includes(
            item.value
          )
        );
        repaymentImportantOption.push(...filteredData);
        break;

      case REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS:
        filteredData = importantOption.filter(item =>
          REPAYMENT_PREFERENCE_IMPORTANT.INTEREST_ONLY_REPAYMENTS.includes(
            item.value
          )
        );
        repaymentImportantOption.push(...filteredData);
        break;

      case REPAYMENT_PREFERENCE.INTEREST_IN_ADVANCE:
        filteredData = importantOption.filter(item =>
          REPAYMENT_PREFERENCE_IMPORTANT.INTEREST_IN_ADVANCE.includes(
            item.value
          )
        );
        repaymentImportantOption.push(...filteredData);
        break;

      case REPAYMENT_PREFERENCE.LINE_OF_CREDIT:
        filteredData = importantOption.filter(item =>
          REPAYMENT_PREFERENCE_IMPORTANT.LINE_OF_CREDIT.includes(item.value)
        );
        repaymentImportantOption.push(...filteredData);
        break;

      default:
    }
  });

  filteredData = importantOption.filter(item => {
    return item.value === REPAYMENT_IMPORTANT.OTHER;
  });
  repaymentImportantOption.push(...filteredData);

  return repaymentImportantOption;
}

export function getOptions(optionList, questionId) {
  const optionData =
    optionList.find(option => option.questionId === questionId) || {};
  return optionData.options || null;
}

export function getOptionQuestionId(key, questionKey) {
  return (
    (OPTION_QUESTION[questionKey] && OPTION_QUESTION[questionKey][key]) || null
  );
}

export function getQuestion(questions, questionId) {
  const question =
    questions.find(item => item.QuestionId === questionId) || null;
  return question;
}

export function isAnsweredAllNested(questions) {
  if (!questions || !questions.length) return false;
  const findUnansweredValue = questions
    .map(
      question =>
        question.subQuestion &&
        question.subQuestion.some(item => !item.answerValue)
    )
    .some(nestedItem => nestedItem);
  return !findUnansweredValue;
}
