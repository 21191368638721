import { getToken, removeToken, setToken } from 'Common/utilities/token';
import { MYCRM_GLOBAL } from 'Common/constants/mycrmGlobal';
import { constructConfigRequest } from 'Common/utilities/request';
import swal from 'sweetalert';

app.factory('sessionService', function(
  $timeout,
  $interval,
  $window,
  configService,
  $http,
  Idle,
  Keepalive,
  $q
) {
  const { COMMON_TASK_SCHEDULE } = MYCRM_GLOBAL;
  const NUMBER_OF_FAILED_ATTEMPTS = 3;
  const idleWatcher = $interval(
    () => !Idle.running() && Idle.watch(),
    COMMON_TASK_SCHEDULE
  );
  const tokenValidatorWatcher = $interval(() => {
    if (getToken()) return;
  }, COMMON_TASK_SCHEDULE);
  let vm = {};
  let sessionUpdate;
  let runningSession;
  let isRunningSession;

  vm.failedSessionCall = null;
  vm.failedUpdateSession = 0;
  vm.sessionTime = null;
  vm.autoLogout = null;
  vm.resetSessionTime = function(isSessionAlreadyUpdated) {
    vm.sessionUpdateTimer(false, isSessionAlreadyUpdated);
  };

  vm.sessionExpired = function() {
    if (typeof dataLayer !== 'undefined')
      dataLayer.push({ event: 'error:session_expiry' });
    swal(
      {
        type: 'error',
        title: 'Oops! Your session has expired.',
        text:
          "You've either logged in from another device or been inactive for 2 hours. We logged you out just in case!",
        showConfirmButton: true,
        confirmButtonColor: '#30C1C4',
        confirmButtonText: 'Login again',
      },
      () => vm.logoutUser()
    );
  };

  vm.logoutUser = function(silent) {
    removeToken();
    $interval.cancel(idleWatcher);
    $interval.cancel(tokenValidatorWatcher);
    Idle.unwatch();
    Keepalive.stop();
    $timeout(() => {
      if (configService.login) {
        $http
          .post(
            `${configService.resource}/Logout`,
            null,
            constructConfigRequest()
          )
          .finally(() => window.location.assign(configService.login));
      } else if (!silent) {
        vm.sessionExpired();
      }
    }, 1000);
  };

  vm.keepAlive = function() {
    $timeout.cancel(sessionUpdate);
    if (!isRunningSession) {
      isRunningSession = true;
      runningSession = $timeout(() => {
        isRunningSession = false;
        vm.updateSession();
        $timeout.cancel(runningSession);
      }, vm.sessionTime);
    }
  };

  vm.checkJwt = function(token, jwttype) {
    let defer = $q.defer();
    if (!token || !jwttype) return false;
    const requestConfig = {
      url: `${configService.resource}/CheckJWT?jwttype=${jwttype}`,
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    $http(constructConfigRequest(requestConfig)).then(
      response => {
        return defer.resolve(response);
      },
      err => {
        return defer.reject(err);
      }
    );
    return defer.promise;
  };

  vm.updateSession = function() {
    if (!getToken()) return;
    if (vm.failedSessionCall) $timeout.cancel(vm.failedSessionCall);
    const requestConfig = {
      method: 'POST',
      url: `${configService.resource}/UpdateSession`,
      headers: {
        Authorization: getToken(),
      },
    };
    $http(constructConfigRequest(requestConfig))
      .then(response => {
        if (!response || !response.data) return;
        setToken(`Bearer ${response.data}`);
        configService.token = `Bearer ${response.data}`;
        vm.failedUpdateSession = 0;
        $http.defaults.headers.common.Authorization = configService.token;
      })
      .catch(e => {
        if (vm.failedUpdateSession < NUMBER_OF_FAILED_ATTEMPTS) {
          vm.failedUpdateSession += 1;
          vm.failedSessionCall = $timeout(
            () => vm.updateSession(),
            vm.sessionTime
          );
        } else {
          vm.sessionExpired();
        }
      });
  };

  // will run every 30 minutes to check for the session of the user
  vm.sessionUpdateTimer = function(isSessionUpdate, isSessionAlreadyUpdated) {
    vm.keepAlive();
    if (isSessionUpdate) {
      if (
        typeof isSessionAlreadyUpdated === 'undefined' ||
        !isSessionAlreadyUpdated
      ) {
        vm.updateSession();
      }
      $timeout.cancel(runningSession);
      isRunningSession = false;
    }
    sessionUpdate = $timeout(() => {
      vm.keepAlive();
    }, vm.sessionTime);
  };
  return vm;
});
