import { appendEmptySelect } from 'Common/utilities/options';
import { DEFAULT_ASSETS } from 'Common/constants/financialAssets';

export const populatePropertyType = ({
  $scope,
  financeService,
  currentUserService,
}) => (apiService, propertyType, fieldName = 'default') => {
  if (!apiService.getPropertyType) return;
  if (!propertyType) return;
  const defaultReaValuesAU = financeService.getDefaultREAAU();
  apiService.getPropertyType(propertyType).then(response => {
    if (!response || !response.data) return;

    const types = [...response.data].map(o => {
      o.Name = o.AddressPropertyType;
      o.Value = o.AddressPropertyTypeID;
      return o;
    });
    const moveToplistproperties = propertylList => {
      const topPropertyList = [
        DEFAULT_ASSETS.STD_RESIDENTIAL,
        DEFAULT_ASSETS.CROSS_LEASE,
        DEFAULT_ASSETS.RESIDENTIAL_LEASEHOLD,
        DEFAULT_ASSETS.STD_APARTMENT,
      ];
      const resultList = propertylList.filter(f =>
        topPropertyList.includes(f.Value)
      );
      const copyOriginal = propertylList.filter(
        f => !topPropertyList.includes(f.Value)
      );
      const sortPurpose = (a, b) => {
        return (
          topPropertyList.indexOf(a.Value) - topPropertyList.indexOf(b.Value)
        );
      };
      return [...resultList.sort(sortPurpose), ...copyOriginal];
    };
    const propertyLists = currentUserService.isNZ
      ? moveToplistproperties(types)
      : types;

    $scope.ListPropertyType = appendEmptySelect(propertyLists, {
      Name: 'Please select',
      Value: null,
    });

    $scope.AssetsSet.PropertyTypeId = null;

    if (
      $scope.ListPropertyType.find(o => {
        return (
          parseInt(o.AddressPropertyTypeID, 10) ===
          parseInt($scope.AssetsSet.PropertyTypeId_original, 10)
        );
      })
    ) {
      $scope.AssetsSet.PropertyTypeId =
        $scope.AssetsSet.PropertyTypeId_original;
    }

    if (
      propertyType === defaultReaValuesAU.zoning &&
      currentUserService.isAU &&
      !$scope.AssetsSet.PropertyTypeId
    ) {
      $scope.setDefaultValuesMethod('propertyTypeId');
    }

    if (fieldName === 'all') {
      $scope.AssetsSet.ZoningId = DEFAULT_ASSETS.RESIDENTIALS;
      $scope.AssetsSet.PropertyTypeId = DEFAULT_ASSETS.STD_RESIDENTIAL;
      $scope.AssetsSet.PrimaryPurposeId = DEFAULT_ASSETS.OWNER_OCCUPIED;
    }
  });
};

export const fileterAssetList = (assetList, familyId) => {
  const filteredList = assetList.find(obj => {
    return obj.FamilyId === parseInt(familyId, 10);
  }) || { overview: { FinancialInfo: [] } };
  return filteredList;
};
