import template from './loanAppDiscountsRate.html';
import LoanAppDiscountsRateCtrl from './loanAppDiscountsRateCtrl';
import './style.scss';

export default {
  template,
  controller: LoanAppDiscountsRateCtrl,
  controllerAs: 'vm',
  bindings: {
    productId: '<',
    loanId: '<',
    loanApplicationId: '<',
    brokerEventId: '<',
    numberOfProduct: '<',
    isOnLoanDetailsModal: '<',
    getInitialActualRate: '&',
    rateType: '<',
    rateTerm: '<',
    interestOnly: '<',
  },
};
