export const employmentModalInstance = propertyOf => {
  return {
    appendFields: employmentSet => {
      const {
        employmentNZBN = '',
        employmentNature = '',
      } = propertyOf.employment;
      return {
        NZBN: employmentNZBN.toString() || '',
        EmploymentNatureID: employmentNature,
        ...employmentSet,
      };
    },
    getEmploymentappendFields: employmentSet => {
      const { NZBN = '', EmploymentNatureID = '' } = employmentSet;
      return {
        employmentNZBN: parseInt(NZBN, 10),
        employmentNature: EmploymentNatureID.toString(),
        ...employmentSet,
      };
    },
    clientEntityEmploymentSet: employementData => {
      propertyOf.contactService
        .clientEntityEmploymentSet(employementData)
        .then(() => {
          propertyOf.isSavingEmployment = false;
          propertyOf.modalInstance.close({ isRefreshEmployment: true });
        });
    },
    setEmployment: employementData => {
      propertyOf.loanApplicationServices
        .setEmployment(employementData)
        .then(() => {
          propertyOf.isSavingEmployment = false;
          propertyOf.modalInstance.close({
            isRefreshIncome: true,
            FamilyId: propertyOf.employment.FamilyId,
          });
        });
    },
  };
};
