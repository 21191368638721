import moment from 'moment';
import { PROVIDER_IDS } from 'Common/constants/fireAndGeneral';
import {
  NZ_DATE_INPUT_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';

export function insuranceBenefitTypesGetForUI(benefits) {
  return {
    benefitId: benefits.BenefitTypeID,
    benefitName: benefits.BenefitTypeName,
    insuranceType: benefits.InsuranceType,
    qPRId: benefits.QPRBenefitID,
  };
}

export function insuranceInsurerListGetForUI(insurer) {
  return {
    informDescription: insurer.InformDescription,
    providerId: insurer.ProviderId,
    providerName: insurer.ProviderName,
    qPRCode: insurer.QprCode,
  };
}

export function insuranceProductListGetForUI(product) {
  return {
    productId: product.ProductID,
    productName: product.ProductName,
  };
}

export function insuranceStatusListGetForUI(status) {
  return {
    description: status.Description,
    isExisting: status.IsExisting,
    statusId: status.StatusID,
    statusName: status.StatusName,
  };
}

export function insuranceClientInfoGetForUI(client) {
  return {
    personId: client.PersonId,
    lastName: client.LastName,
    firstName: client.FirstName,
    fullName: client.FullName,
    preferredName: client.PreferredName,
    title: client.Title,
    gender: client.Gender,
    genderIntValue: client.GenderIntValue,
    smokerStatus: client.SmokerStatus,
    isSmoker: client.SmokerStatusBoolValue,
    isDeceased: client.Deceased,
    isfaceToFaceIdentityCheck: client.FaceToFaceIdentityCheck,
    isExistingCustomer: client.IsExistingCustomer,
    allocatedAdviserId: client.AllocatedAdviserID,
    allocatedAdviserName: client.AllocatedAdviserName,
    isAllocatedAdviserALIQuoteEnabled: client.IsAllocatedAdviserALIQuoteEnabled,
    role: client.Role,
    hasRole: client.RoleBoolValue,
    phone: client.Phone,
    address: client.Address,
    email: client.Email,
    sourceFamilyId: client.SourceFamilyID,
    isEntity: client.isEntity,
    isPrimary: client.isPrimary,
  };
}

export function insurancePolicySetForAPI(policy) {
  return {
    POID: policy.pOId,
    ClientID: policy.clientId,
    IsDependent: policy.isDependent,
    IsOwnership: policy.isOwnership,
    FullName: policy.fullName,
    IsInsured: policy.isInsured,
    Other: policy.other,
  };
}

export function policyOwnershipDetails(details) {
  return {
    pOId: details.POID,
    clientId: details.ClientID,
    fullName: details.FullName,
    isOwnership: details.IsOwnership,
    isInsured: details.IsInsured,
    other: details.Other,
  };
}

export function insuranceDetails(details) {
  return {
    policyId: details.PolicyID,
    benefitTypeId: details.BenefitTypeID,
    qPRBenefitId: details.QPRBenefitID,
    providerId: details.ProviderID,
    productId: details.ProductID,
    clientId: details.ClientID,
    currentClaim: details.CurrentClaim,
    inArears: details.InArears,
    policyNumber: details.PolicyNumber,
    statusId: details.StatusID,
    commencementDate:
      details.CommencementDate &&
      moment(details.CommencementDate).format(NZ_DATE_INPUT_FORMAT),
    tempCommencementDate:
      details.CommencementDate &&
      moment(details.CommencementDate).format(NZ_DATE_INPUT_FORMAT),
    deferralReviewDate:
      details.CommencementDate &&
      moment(details.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
    tempDeferralReviewDate:
      details.DeferralReviewDate &&
      moment(details.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
    paymentFrequencyId: details.PaymentFrequencyID,
    waivePolicy: details.WaivePolicy,
    autoPremium: details.AutoPremium,
    autoPolicyFee: details.AutoPolicyFee,
    totalPremium: details.TotalPremium,
    policyFee: details.PolicyFee,
    benefitNotes: details.BenefitNotes,
    qMErrorMessage: details.QMErrorMessage,
    policyOwnership:
      details.PolicyOwnership &&
      details.PolicyOwnership.map(policyOwnershipDetails),
    loadingExclusions: details.LoadingExclusions,
    productName: details.ProductName,
  };
}

export function benefitCoverDetails(details) {
  return {
    indexed: details.Indexed,
    futureInsurability: details.FutureInsurability,
    coverAmount: details.CoverAmount,
    calcPeriod: details.CalcPeriod,
  };
}

export function clientBenefits(details) {
  return {
    healthCover: details.HealthCover,
    LifeCover: benefitCoverDetails(details.LifeCover),
    familyProtection: benefitCoverDetails(details.FamilyProtection),
    traumaCover: benefitCoverDetails(details.TraumaCover),
    totalAndPermanentDisability: benefitCoverDetails(
      details.TotalAndPermanentDisability
    ),
    incomeProtection: benefitCoverDetails(details.IncomeProtection),
    mortgageRepaymentCover: benefitCoverDetails(details.MortgageRepaymentCover),
    redundancyCover: benefitCoverDetails(details.RedundancyCover),
    mortgageLifeCover: benefitCoverDetails(details.MortgageLifeCover),
    fireAndGeneralCover: benefitCoverDetails(details.FireAndGeneralCover),
  };
}

export function insuranceBenefitDetailsGetForUI(details) {
  return {
    benefitId: details.BenefitID,
    familyId: details.FamilyID,
    insuranceDetails: insuranceDetails(details.InsuranceDetails),
    clientBenefits: clientBenefits(details.ClientBenefits),
  };
}

export function insuranceDetailsSetForAPI(info) {
  return {
    BenefitID: info.benefitId,
    FamilyID: info.familyId,
    InsuranceDetails: {
      PolicyID: info.policyId || 0,
      BenefitTypeID: PROVIDER_IDS.benefitTypeId,
      QPRBenefitID: PROVIDER_IDS.qPRBenefitId,
      ProviderID: info.providerId,
      ProductID: info.productId,
      ClientID: info.clientId,
      CurrentClaim: false,
      InArears: false,
      PolicyNumber: info.policyNumber,
      StatusID: info.statusId,
      CommencementDate:
        info.tempCommencementDate &&
        moment(info.tempCommencementDate, NZ_DATE_INPUT_FORMAT).format(
          TO_MYCRM_DATE_FORMAT
        ),
      DeferralReviewDate:
        info.tempDeferralReviewDate &&
        moment(info.tempDeferralReviewDate, NZ_DATE_INPUT_FORMAT).format(
          TO_MYCRM_DATE_FORMAT
        ),
      PaymentFrequencyID: info.paymentFrequencyId,
      WaivePolicy: false,
      AutoPremium: false,
      AutoPolicyFee: false,
      TotalPremium: info.totalPremium,
      PolicyFee: 0,
      BenefitNotes: info.benefitNotes,
      QMErrorMessage: null,
      PolicyOwnership:
        info.ownerShipInput &&
        info.ownerShipInput.map(insurancePolicySetForAPI),
      LoadingExclusions: [],
    },
    ClientBenefits: {
      FireAndGeneralCover: {
        CoverAmount: info.coverAmount,
      },
    },
  };
}
