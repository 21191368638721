import { getCountryName, getCountryCode } from 'Common/utilities/user';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { ADVISER_STATUS } from 'Common/constants/adviserStatus';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';

export function convertContentHtmlToRtfBuilderForUI(parameter) {
  return (
    parameter && {
      contentType: parameter.ContentType,
      documentContent: parameter.DocumentContent,
      name: parameter.Name,
    }
  );
}

export function brokerRegionalizationBuilderForUI(brokerRegionalization) {
  return {
    code: brokerRegionalization.Code,
    countryID: brokerRegionalization.CountryID,
    currencySign: brokerRegionalization.CurrencySign,
    name: brokerRegionalization.Name,
  };
}

export function userInfoBuilderForUI(user) {
  return {
    aLIQuoteEnabled: user.ALIQuoteEnabled,
    accessType: user.AccessType,
    brandingCategory: user.BrandingCategory,
    brandingCategoryID: user.BrandingCategoryID,
    brokerStatusId: user.BrokerStatusID,
    campaignBreezeEnabled: user.CampaignBreezeEnabled,
    corpAccessTypeID: user.CorpAccessTypeID,
    corporateTeam: user.CorporateTeam,
    corporateTeamId: user.CorporateTeamId,
    countryId: user.CountryId,
    displayTitle: user.DisplayTitle,
    familyId: user.FamilyId,
    firstName: user.FirstName,
    lastName: user.LastName,
    fullName: user.FullName,
    email: user.Email,
    mobile: user.MobileNumber,
    gotItTicked: user.GotItTicked,
    isAllowCustomizedTheme: user.IsAllowCustomizedTheme,
    myCRMActive: user.MyCRMActive,
    nPSEnabled: user.NPSEnabled,
    preferredFullName: user.PreferredFullName,
    sMSEnabled: user.SMSEnabled,
    sMSEnabledDate: user.SMSEnabledDate,
    crmCountry: getCountryName(user.CountryId),
    isNZ: user.CountryId === COUNTRY_TYPE.NEW_ZEALAND,
    isAU: user.CountryId === COUNTRY_TYPE.AUSTRALIA,
    isID: user.CountryId === COUNTRY_TYPE.INDONESIA,
    reportingStateName: user.ReportingStateName,
    countryCode: getCountryCode(user.CountryId),
    isPrincipalAdviser: user.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER,
    isAdviser: user.AccessType === ACCESS_TYPE.ADVISER,
    isAdminAssistant: user.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT,
    isAssistant: user.AccessType === ACCESS_TYPE.ASSISTANT,
    isCorporate: user.AccessType === ACCESS_TYPE.CORPORATE,
    isReferrer: user.AccessType === ACCESS_TYPE.REFERRER,
    brokerRegionalization:
      user.BrokerRegionalization &&
      brokerRegionalizationBuilderForUI(user.BrokerRegionalization),
    isOnboarding: user.BrokerStatusID === ADVISER_STATUS.ONBOARDING,
    isOnboardingActive: user.OnlineOnboardingActive,
    isNzCorp:
      user.CountryId === COUNTRY_TYPE.NEW_ZEALAND &&
      user.AccessType === ACCESS_TYPE.CORPORATE,
    tradingName: user.TradingName,
    asicId: user.ASICID,
    isLoanWriter: user.IsLoanWriter || false,
    userAdviserOrgId: user.AdviserOrgID || '',
  };
}

export function subscriptionBuilderForMyCRM(subscription) {
  return {
    BrokerID: subscription.brokerId,
    Description: subscription.description,
    FranchiseBillingDate: subscription.franchiseBillingDate,
    IsActive: subscription.isActive,
    IsCancelled: subscription.isCancelled,
    IsTrial: subscription.isTrial,
    MonthlyRecurringFee: subscription.monthlyRecurringFee,
    NextRenewDate: subscription.nextRenewDate,
    OrderedBy: subscription.orderedBy,
    OrderedById: subscription.orderedById,
    SubscriptionId: subscription.subscriptionId,
    SubscriptionProductId: subscription.subscriptionProductId,
    SubscriptionStartDate: subscription.subscriptionStartDate,
    Title: subscription.title,
    TermsAndConditionsLink: subscription.termsAndConditionsLink,
    SubDetailIDs: subscription.subDetailIDs,
    SubscriptionEndDate: subscription.subscriptionEndDate,
    IsImmediately: subscription.isImmediately,
    SubscriptionHasTrial: subscription.hasTrial,
    IsPromoted: subscription.isPromoted,
    DefaultPrice: subscription.defaultPrice,
    AdditionalCostDescription: subscription.additionalCostDescription,
  };
}

export function mergeFamilyProfileBuilderForUI(data, familyId) {
  return {
    succeeded: data,
    data: { familyId },
  };
}

export function subscriptionPopUpBuilderForUI(data) {
  return {
    isSubscribed: data.IsSubscribed,
    shouldPopup: data.ShouldPopup,
    showPopUp: !!(data.ShouldPopup && !data.IsSubscribed),
  };
}

export function subIncomeTypeBuilderForUI(subIncomeTypeList) {
  return {
    rentalTypeId: subIncomeTypeList.RentalTypeId,
    rentalIncomeName: subIncomeTypeList.RentalIncomeName,
  };
}

export function summarySectionBuilderForUI(data) {
  return {
    sectionID: data.SectionID,
    sectionName: data.SectionName,
    isSelected: data.IsSelected,
    orderBy: data.OrderBy,
  };
}

export function commissionContactsListBuilderForUI(contact) {
  return {
    familyId: contact.FamilyId,
    familyFullname: contact.FamilyFullname,
  };
}

export function commissionAccessBuilderForUI(data) {
  return (
    data && {
      access: data.Access,
      brokerFullName: data.BrokerFullName,
      familyId: data.FamilyID,
      hasCommissionAccess: data.HasCommissionAccess,
    }
  );
}
export function contactEmailBuilderForUI(email) {
  return (
    email && {
      type: email.Type,
      email: email.EmailAddress,
    }
  );
}

export function contactEmailBuilderForMyCRM(email) {
  return (
    email && {
      Type: email.type,
      EmailAddress: email.email,
    }
  );
}

export function contactPhoneBuilderForUI(phone) {
  return (
    phone && {
      type: phone.Type,
      number: phone.Number,
    }
  );
}

export function contactPhoneBuilderForMyCRM(phone) {
  return (
    phone && {
      Type: phone.type,
      Number: phone.number,
    }
  );
}

export function contactAdviserThemeBuilderForUI(theme) {
  return (
    theme && {
      bgColor: theme.BGColour,
      logoDocId: theme.CRM_LogoDocId,
      sidebarBgColor: theme.SideBarBGColour,
      sidebarFontColor: theme.SideBarFontColour,
      fontColour: theme.ThemeFontColour,
      tradingName: theme.TradingName,
      brandingCategory: theme.BrandingCategory,
      brandingCategoryID: theme.BrandingCategoryId,
      isBYOB: theme.BrandingCategoryId === BRANDING_CATEGORY_TYPE.OWN_BRAND,
    }
  );
}

export function contactSplitBuilderForMyCRM(data) {
  return {
    DuplicateCommunication: data.duplicateCommunication,
    DuplicateTask: data.duplicateTask,
    FamilyId: data.familyId,
    PrimaryClientId: data.primaryClientId,
  };
}

export function hasAutomationMarketingBuilderForUI(data) {
  return {
    hasAutomationMarketing: data,
  };
}
